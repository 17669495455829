<template>
  <v-container fluid>
    <ApiList
      ref="list"
      :url="url"
      :customParams="getParams()"
      :title="$vuetify.t('Chirurgické výkony')"
      :actions="[
        {
          text: $vuetify.t('Odstrániť chirurgický výkon'),
          icon: 'delete',
          handler: removeChirurgickyVykon,
        },
      ]"
      @addItem="addItem"
      @editItem="editItem"
    >
      <template v-slot:items="{ item }">
        <ChirurgickyVykonRow :chirurgickyVykon="item" />
      </template>
    </ApiList>
    <BaseDialog
      :dialogTitle="$vuetify.t(dialogTitle)"
      ref="dialog"
      @save="$refs.form.save()"
    >
      <FormChirurgickyVykon
        ref="form"
        :pacientId="id"
        @saved="fetchData"
      ></FormChirurgickyVykon>
    </BaseDialog>
  </v-container>
</template>

<script>
import ApiList from "../../components/ApiList.vue"
import BaseDialog from "../../components/BaseDialog.vue"
import FormChirurgickyVykon from "../../components/forms/FormChirurgickyVykon.vue"
import thingMixin from "@/components/mixins/thing.js"
import ChirurgickyVykonRow from "@/components/rows/ChirurgickyVykonRow.vue"

export default {
  name: "ChirurgickeVykony",
  mixins: [thingMixin],
  components: {
    ApiList,
    BaseDialog,
    FormChirurgickyVykon,
    ChirurgickyVykonRow,
  },
  props: ["id"],
  data: function () {
    return {
      dialogTitle: "Chirurgický výkon",
    }
  },
  computed: {
    url() {
      return "/v1/proto.ThingService/ListThings"
    },
    pacient: function () {
      return this.$store.state.pacient !== null ? this.$store.state.pacient : {}
    },
  },
  methods: {
    getParams: function () {
      return {
        patient_id: this.id,
        chirurgicky_vykon: {},
      }
    },
    readPacient: function () {
      this.$store.commit("setReloadPacient", true)
    },
    fetchData: function () {
      this.$refs.list.reload()
      this.readPacient()
    },
    addItem: function () {
      this.$refs.form.new()
    },
    editItem: function (item) {
      this.$refs.form.edit(item)
    },
    removeChirurgickyVykon: async function (vykon) {
      var result = await this._deleteThing(vykon) // from thing mixin
      if (result) {
        this.fetchData()
      }
    },
  },
}
</script>
