<template>
  <v-form>
    <v-container fluid>
      <v-layout row wrap>
        <v-flex xs12 md6>
          <selectable-api-table
            ref="vykonyTable"
            :headers="[
              {
                text: 'Kód',
                value: 'Kod',
                sortable: false,
              },
              {
                text: 'Popis',
                value: 'Popis',
                sortable: false,
              },
            ]"
            :url="vykonyUrl"
            :title="$vuetify.t('Výkony')"
            :full-toolbar="false"
            selectable
            @input="model.Vykony = $event"
          >
            <template v-slot:selectedItems="{ item }">
              <span
                :title="item.Popis"
                class="text-truncate"
                style="max-width: 200px"
                >{{ item.Kod }} - {{ item.Popis }}</span
              >
            </template>
          </selectable-api-table>
        </v-flex>
        <v-flex xs12 md6>
          <selectable-api-table
            ref="diagnozyTable"
            :headers="[
              {
                text: 'Skratka',
                value: 'Skratka',
                sortable: false,
              },
              {
                text: 'Názov',
                value: 'DisplayName',
                sortable: false,
              },
            ]"
            :url="diagnozyUrl"
            :title="$vuetify.t('Diagnózy')"
            :full-toolbar="false"
            selectable
            @input="model.Diagnozy = $event"
          >
            <template v-slot:selectedItems="{ item }">
              {{ item.Skratka }} - {{ item.DisplayName }}
            </template>
          </selectable-api-table>
        </v-flex>
        <v-flex xs7>
          <v-radio-group v-model="model.JeCena" row>
            <template v-slot:label>
              <span style="padding-right: 5px">{{
                $vuetify.t("Jednotka")
              }}</span>
            </template>
            <v-radio label="Cena" :value="true"></v-radio>
            <v-radio label="Bod" :value="false"></v-radio>
            <v-radio label="Bez hodnoty" :value="null"></v-radio>
          </v-radio-group>
        </v-flex>
        <v-flex xs5>
          <TextField
            v-model="model.Hodnota"
            type="number"
            :label="$vuetify.t('Hodnota')"
            name="hodnota"
            v-validate="{ decimal: true }"
          ></TextField>
        </v-flex>
        <v-flex xs12>
          <v-autocomplete
            v-model="model.CennikID"
            :label="$vuetify.t('Cenník')"
            :items="cenniky"
            item-text="TypHodnoty"
            item-value="ID"
            hide-no-data
            clearable
          >
          </v-autocomplete>
        </v-flex>
        <v-flex xs12>
          <v-textarea
            v-model="model.Popis"
            :label="$vuetify.t('Popis')"
          ></v-textarea>
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>
<script>
import TextField from "./../TextField.vue"
import SelectableApiTable from "./../SelectableApiTable.vue"
import * as Miscella from "../../miscella.js"

export default {
  $_veeValidate: {
    validator: "new",
  },
  components: {
    TextField,
    SelectableApiTable,
  },
  props: {
    poistovna: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      model: null,
      cenniky: [],
      vykonySearch: null,
      diagnozySearch: null,
      vykonyUrl: "/api/codebook/vykony",
      diagnozyUrl: "/api/codebook/25",
    }
  },

  methods: {
    init: function () {
      this.model = {
        ID: "",
        Vykony: [],
        Diagnozy: [],
        CennikID: null,
        JeCena: false,
        Hodnota: null,
        Popis: "",
        Poistovna: this.poistovna,
      }
      this.cenniky = []
      this.$validator.reset()
    },
    diagnozaItemText: function (item) {
      return item.Skratka + " " + item.DisplayName
    },
    vykonItemText: function (item) {
      return item.Kod + " " + item.Popis
    },
    cennikyUrl() {
      return "/api/poistovna/" + this.poistovna + "/cenniky"
    },
    new: function () {
      this.init()
      this.fetchData()
      this.$refs.vykonyTable.init(this.model.Vykony)
      this.$refs.diagnozyTable.init(this.model.Diagnozy)
    },
    edit: function (item) {
      this.init()
      this.fetchData()
      this.model = Miscella.cloneObject(item)
      this.$refs.vykonyTable.init(this.model.Vykony)
      this.$refs.diagnozyTable.init(this.model.Diagnozy)
    },
    fetchData: async function () {
      await this.fetchCenniky()
    },
    fetchCenniky: async function () {
      this.cenniky = await this.$api.get(this.cennikyUrl())
    },
    save: async function () {
      var valid = await this.$validator.validateAll()
      if (!valid) {
        return
      }
      var params = Miscella.cloneObject(this.model)
      params.Vykony = this.model.Vykony.map((v) => v.ID)
      params.Diagnozy = this.model.Diagnozy.map((d) => d.ID)
      await this.$api.post("/api/poistovnaCennikVykonSave", params)
      this.$store.commit(
        "setSuccess",
        this.$vuetify.t("Cenník bol úspešne uložený")
      )
      this.$emit("saved", this.model)
    },
  },
  mounted: function () {
    this.fetchData()
  },
  created: function () {
    this.init()
  },
}
</script>
