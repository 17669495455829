<template>
  <v-layout column>
    <ApiTable
      ref="table"
      :headers="headers"
      url="/twirp/proto.EHealth/SmartcardLogs"
      :title="$vuetify.t('')"
      defaultSortBy=""
      :customParams="{}"
      @reload="fetchItems"
    >
      <template slot="items" slot-scope="props">
        <tr @click="itemClicked(props.item)">
          <td class="text-md-left">{{ props.item.ExtIDString }}</td>
          <td class="text-md-left">{{ props.item.StavString }}</td>
          <td class="text-md-left">{{ props.item.UpdatedString }}</td>
        </tr>
      </template>
    </ApiTable>
    <!-- <BaseDialog
      :dialogTitle="$vuetify.t('Detail logu')"
      ref="dialog"
      :showSaveButton="false"
    >
      <h4>Request:</h4>
      <p>{{ chosen.Request }}</p>
      <h4>Response:</h4>
      <p>{{ chosen.Response }}</p>
    </BaseDialog> -->
  </v-layout>
</template>

<script>
import ApiTable from "@/components/ApiTable.vue"
// import BaseDialog from "@/components/BaseDialog.vue"
import Vue from "vue"

export default {
  components: {
    ApiTable,
    // BaseDialog,
  },
  data: function () {
    return {
      chosen: {},
      headers: [
        { text: "ExtID", sortable: true, value: "ExtIDString" },
        { text: "Stav", sortable: true, value: "StavString" },
        { text: "Aktualizované", sortable: true, value: "UpdatedString" },
      ],
    }
  },
  computed: {},
  methods: {
    getClass: function (item) {
      return {
        "success--text": item.EVysetrenie.Stav === "SUCCESS",
        "error--text": item.EVysetrenie.Stav === "ERROR",
        "warning--text": item.EVysetrenie.Stav === "WAITING",
      }
    },
    viewItem: function (item) {
      this.chosen = item
      this.$router.push({
        name: "vysetrenie",
        params: { id: item.EVysetrenie.PatientID, vysetrenieID: item.ID },
      })
    },
    fetchItems: function () {
      let url = "/api/eVysetrenia"
      let t = this.$refs.baseTable
      var params = t.customParams
      t.loading = true
      return this.$api
        .get(url, params)
        .then((data) => {
          console.log("data", data)
          data.forEach((item) => {
            item.UpdatedString = this.$options.filters.formatDatumACas(
              item.EVysetrenie.Updated,
              "YYYY.MM.DD HH:mm:ss"
            )
            item.StavString = this.$vuetify.t(
              "$vuetify.enum.evysetrenie.stav." + item.EVysetrenie.Stav
            )
            item.ExtIDString = item.EVysetrenie.ExtID
          })
          t.setItems(data)
        })
        .catch((error) => {
          console.log("xErr:", error)
        })
        .finally(() => {
          Vue.nextTick(() => {
            t.loading = false
          })
        })
    },
  },
  // mounted: function () {
  //   this.$refs.baseTable.reload()
  // },
}
</script>

<style scoped></style>
