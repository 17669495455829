<template>
  <CiselnikTemplate
    ref="cisTemplate"
    :cisItemsUrl="$api.codebookService.listCodebookItemsUrl"
    title=""
    :titles="allCodebooks"
  ></CiselnikTemplate>
</template>

<script>
import CiselnikTemplate from "../../components/ciselnikComponents/CiselnikTemplate"

export default {
  components: { CiselnikTemplate },
  data: function () {
    return {
      allCodebooks: [],
    }
  },
  methods: {
    fetchData: async function () {
      try {
        let data = await this.$api.codebookService.listCodebooks({})
        this.allCodebooks = data.items
        this.allCodebooks.sort(function (a, b) {
          if (a.name < b.name) {
            return -1
          }
          if (a.name > b.name) {
            return 1
          }
          return 0
        })
        if (this.allCodebooks.length > 0) {
          this.$refs.cisTemplate.titleChange(this.allCodebooks[0])
        }
      } catch (err) {
        this.processError(err)
      }
    },
  },
  mounted: function () {
    this.fetchData()
  },
}
</script>

<style scoped></style>
