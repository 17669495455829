<template>
  <v-card class="ma-2 elevation-5">
    <v-toolbar flat dense card>
      <v-toolbar-title>
        {{ eventDate | formatDatumACas("dddd DD.MM.YYYY") | firstToUpper }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip top>
        <v-btn flat icon slot="activator" @click="showDay">
          <v-icon>calendar_view_day</v-icon>
        </v-btn>
        <span>{{ $vuetify.t("Denný prehľad") }}</span>
      </v-tooltip>
      <v-tooltip top>
        <v-btn flat icon slot="activator" @click="showMonth">
          <v-icon>calendar_today</v-icon>
        </v-btn>
        <span>{{ $vuetify.t("Mesačný prehľad") }}</span>
      </v-tooltip>
      <v-tooltip top>
        <v-btn flat icon slot="activator" @click="addEvent">
          <v-icon>add</v-icon>
        </v-btn>
        <span>{{ $vuetify.t("Pridať") }}</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text v-if="calendarType === 'MONTH'">
      <v-flex xs12>
        <v-date-picker
          v-model="eventDate"
          :events="getColorOfEvent"
          full-width
          @update:pickerDate="changedMonth"
          locale="sk"
          :first-day-of-week="1"
          :day-format="dayFormat"
          :no-title="true"
        ></v-date-picker>
      </v-flex>
      <v-flex xs12>
        <v-list v-if="eventy.length > 0" subheader dense>
          <template v-for="(event, index) in eventy">
            <v-list-tile :key="'list' + index" @click="editEvent(event)">
              <v-list-tile-content>
                <v-container fluid>
                  <v-layout row>
                    <v-flex xs1 px-1>
                      <div
                        class="legend-color"
                        :style="{
                          'background-color': getColorOfEventDay(event.Typ),
                        }"
                      ></div>
                    </v-flex>
                    <v-flex xs2 px-1>
                      <v-tooltip top v-if="!isKontrola(event)">
                        <span slot="activator">{{ event.CasOd }} </span>
                        <span>{{ $vuetify.t("Čas od") }}</span>
                      </v-tooltip>
                    </v-flex>
                    <v-flex xs9 px-1>
                      <v-tooltip top>
                        <div slot="activator" class="text-truncate desc">
                          <router-link
                            v-if="
                              event.PatientId !== '' && event.PatientName !== ''
                            "
                            tag="a"
                            :to="{
                              name: 'pacient',
                              params: { id: event.PatientId },
                            }"
                            class="px-1"
                          >
                            {{ event.PatientName }}
                          </router-link>
                          {{ event.Desc }}
                        </div>
                        <span>{{ event.Desc }}</span>
                      </v-tooltip>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-list-tile-content>
              <v-list-tile-action>
                <v-tooltip left v-if="event.Typ !== 'OBJEDNANIE_ORDERS'">
                  <v-icon slot="activator" @click.stop="removeEvent(event.ID)"
                    >delete</v-icon
                  >
                  <span>{{ $vuetify.t("Odstrániť") }}</span>
                </v-tooltip>
              </v-list-tile-action>
            </v-list-tile>
            <v-divider
              :key="'divider' + index"
              v-if="index !== eventy.length - 1"
            ></v-divider>
          </template>
        </v-list>
        <v-card-text v-else>
          Žiadne udalosti ani objednaní pacienti
        </v-card-text>
      </v-flex>
    </v-card-text>
    <v-card-text v-if="calendarType === 'DAY'">
      <v-calendar
        v-model="eventDate"
        color="primary"
        type="day"
        locale="sk"
        :first-interval="range[0]"
        :interval-count="range[1] - range[0]"
        :hide-header="true"
        @click:time="addEventWithDateTime"
      >
        <template v-slot:dayBody="{ date, timeToY, minutesToPixels }">
          <!--
                  color: 'black',
                'background-color': getColorOfEventDay(event.Typ),
                'border-color': getColorOfEventDay(event.Typ)
-->
          <template v-for="event in eventy">
            <calendar-event
              :key="event.ID"
              :event="event"
              :height="minutesToPixels(event.Duration) + 'px'"
              :position="timeToY(event.CasOd) + 'px'"
              @click="editEvent"
              @click:remove="removeEvent"
            />
          </template>
        </template>
      </v-calendar>
    </v-card-text>
    <BaseDialog
      :dialogTitle="$vuetify.t('Udalosť')"
      ref="dialogEvent"
      @save="$refs.formEvent.save()"
    >
      <FormEvent ref="formEvent" @saved="savedEvent($refs.dialogEvent)" />
    </BaseDialog>
  </v-card>
</template>

<script>
import BaseDialog from "./../../components/BaseDialog.vue"
import FormEvent from "./../../components/forms/FormEvent.vue"
import CalendarEvent from "./../../components/CalendarEvent.vue"
import calendarMixin from "@/components/mixins/calendar.js"
import * as Miscella from "./../../miscella.js"
import * as enums from "@/plugins/enums.js"
import moment from "moment"

const DATE_FORMAT = "YYYY-MM-DD"

export default {
  mixins: [calendarMixin],
  components: {
    BaseDialog,
    FormEvent,
    CalendarEvent,
  },
  data() {
    return {
      dateFormat: DATE_FORMAT,
      eventDate: moment().format(DATE_FORMAT),
      daysWithEventsNoPacient: [],
      daysWithEventsPacient: [],
      daysWithEventsOrders: [],
      eventy: [],
      eventLoading: false,
      calendarType: Miscella.getLocalStorageItem("calendarType", "MONTH"),
    }
  },
  computed: {
    eventsMonthUrl: function () {
      return "/api/ambulance/events/month"
    },
    eventsOrdersMonthUrl: function () {
      return "/api/ambulance/eventsOrders/month"
    },
    eventsDayUrl: function () {
      return "/api/ambulance/events/" + this.eventDate
    },
    range: function () {
      return this.$store.state.calendar.range
    },
    ambulanceId: function () {
      let id = null
      if (Miscella.isSet(this.$store.state.me.ambulance)) {
        id = this.$store.state.me.ambulance.id
      }
      return id
    },
  },
  watch: {
    eventDate: function () {
      this.fetchEventsForDate()
    },
    ambulanceId: function () {
      this.fetchData()
    },
  },
  methods: {
    fetchDaysWithEvents: function (url, local) {
      let params = {
        datumOd: moment(this.eventDate, DATE_FORMAT)
          .startOf("month")
          .format(DATE_FORMAT),
        datumDo: moment(this.eventDate, DATE_FORMAT)
          .endOf("month")
          .format(DATE_FORMAT),
      }
      return this.$api.get(url, params).then((data) => {
        if (local) {
          this.daysWithEventsNoPacient = data.EventyNoPacient
          this.daysWithEventsPacient = data.EventyPacient
        } else {
          this.daysWithEventsOrders = data
        }
      })
    },
    fetchEventsForDate: function () {
      this.eventLoading = true
      return this.$api
        .get(this.eventsDayUrl)
        .then((data) => {
          this.eventy = data
          function compare(a, b) {
            let ret = 0
            if (a.CasOd < b.CasOd) {
              ret = -1
            } else if (a.CasOd > b.CasOd) {
              ret = 1
            }
            return ret
          }
          let sorted = this.eventy.slice()
          sorted.sort(compare)
          //sorted.reverse()
          this.eventy = sorted
        })
        .finally(() => {
          this.eventLoading = false
        })
    },
    fetchData: function () {
      this.daysWithEventsNoPacient = []
      this.daysWithEventsPacient = []
      this.daysWithEventsOrders = []
      Promise.all([
        this.fetchDaysWithEvents(this.eventsMonthUrl, true),
        this.fetchDaysWithEvents(this.eventsOrdersMonthUrl, false),
        this.fetchEventsForDate(),
      ])
    },
    addEventWithDateTime: function (dateTime) {
      var time = `${moment(dateTime.hour, "H", true).format("HH")}:${
        dateTime.minute > 30 ? "30" : "00"
      }`
      this.$refs.formEvent.new(dateTime.date, time)
      this.$refs.dialogEvent.open()
    },
    addEvent: function () {
      this.$refs.formEvent.new(this.eventDate)
      this.$refs.dialogEvent.open()
    },
    editEvent: function (aEvent) {
      if (aEvent.Typ !== enums.TypEventu.TypEventuObjednanieOrders) {
        this.$refs.formEvent.edit(aEvent)
        this.$refs.dialogEvent.open()
      }
    },
    removeEvent: function (eventId) {
      return this.$confirm(
        this.$vuetify.t("Naozaj chcete odstrániť udalosť?")
      ).then((res) => {
        if (res) {
          return this.$api.delete("/api/event/" + eventId).then(() => {
            this.fetchData()
          })
        }
      })
    },
    savedEvent: function (aDialog) {
      //this.fetchEventsForDate()
      this.fetchData()
      aDialog.close()
    },
    changedMonth: function (value) {
      let d = moment(value, "YYYY-MM")
      let e = moment(this.eventDate, DATE_FORMAT)
      if (d.month() !== e.month()) {
        //naozaj sa zmenil mesiac
        console.log("changedMonth:" + value, this.eventDate)
        if (d.month() === moment().month()) {
          this.eventDate = moment().format(DATE_FORMAT)
        } else {
          this.eventDate = value + "-01"
        }
        this.fetchData()
      }
    },
    dayFormat: function (value) {
      var d = moment(value, DATE_FORMAT)
      return d.date()
    },
    getColorOfEvent: function (date) {
      let arr = []
      if (Miscella.isSet(this.daysWithEventsNoPacient)) {
        if (this.daysWithEventsNoPacient.includes(date)) {
          arr.push(this.getColorOfEventDay(enums.TypEventu.TypEventuKalendar))
        }
      }
      if (Miscella.isSet(this.daysWithEventsPacient)) {
        if (this.daysWithEventsPacient.includes(date)) {
          arr.push(this.getColorOfEventDay(enums.TypEventu.TypEventuObjednanie))
        }
      }
      if (Miscella.isSet(this.daysWithEventsOrders)) {
        if (this.daysWithEventsOrders.includes(date)) {
          arr.push(
            this.getColorOfEventDay(enums.TypEventu.TypEventuObjednanieOrders)
          )
        }
      }
      if (arr.length > 0) {
        return arr
      } else {
        return false
      }
    },
    showMonth: function () {
      this.calendarType = "MONTH"
      localStorage.setItem("calendarType", this.calendarType)
    },
    showDay: function () {
      this.calendarType = "DAY"
      localStorage.setItem("calendarType", this.calendarType)
    },
  },
  mounted: function () {
    this.fetchData()
  },
}
</script>
<style scoped>
.desc {
  display: inline;
}
</style>
