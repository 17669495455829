<template>
  <v-form>
    <v-container fluid>
      <template v-for="(value, key) in model">
        <v-layout :key="key" row fluid wrap>
          <v-flex xs12 md2>
            <v-checkbox
              v-model="value.JePlatna"
              :label="getPoistovnaLabel(value.Kod)"
            ></v-checkbox>
          </v-flex>
          <v-flex xs12 md2>
            <text-field
              disabled
              label="Kód"
              v-bind:value="key"
              name="kod"
            ></text-field>
          </v-flex>
          <v-flex xs12 md3>
            <text-field
              :disabled="!value.JePlatna"
              label="Kód pobočky"
              v-model="value.KodPobocky"
              :name="`kodPobocky${value.Kod}`"
              v-validate="'required|max:2|numeric'"
            ></text-field>
          </v-flex>
          <v-flex xs12 md4>
            <text-field
              :disabled="!value.JePlatna"
              label="Číslo zmluvy"
              v-model="value.CisloZmluvy"
              :name="`cisloZmluvy${value.Kod}`"
              v-validate="'required'"
            ></text-field>
          </v-flex>
          <v-flex xs12 md1>
            <v-tooltip top>
              <v-btn
                fab
                small
                :disabled="!value.JePlatna"
                @click.stop="showCennik(value)"
                slot="activator"
              >
                <v-icon>euro_symbol</v-icon>
              </v-btn>
              <span>{{ $vuetify.t("Cenník") }}</span>
            </v-tooltip>
          </v-flex>
        </v-layout>
      </template>
    </v-container>
    <v-btn @click="save">{{ $vuetify.t("Uložiť") }}</v-btn>
  </v-form>
</template>

<script>
import TextField from "./../../components/TextField.vue"

export default {
  $_veeValidate: {
    validator: "new", // give me my own validator scope.
  },
  components: {
    TextField,
  },
  methods: {
    getPoistovnaLabel: function (option) {
      return this.$vuetify.t("$vuetify.enum.poistovna." + option)
    },
    init: function () {
      this.model = {}
    },
    fetchData: async function () {
      this.init()
      this.model = await this.$api.get("/api/poistovnaZmluvy")
    },
    save: async function () {
      try {
        var valid = await this.$validator.validateAll()
        if (!valid) {
          return
        }
        await this.$api.post("/api/poistovnaZmluvySave", this.model)
        this.$store.commit(
          "setSuccess",
          this.$vuetify.t("Zmluvy bol úspešne uložené.")
        )
      } catch (e) {
        this.$store.commit(
          "setError",
          this.$vuetify.t("Pri ukladaní zmlúv vznikla chyba." + e)
        )
      }
    },
    showCennik: function (item) {
      let newUrl = "/poistovne/" + item.Kod + "/cenniky"
      console.log("forward to ", newUrl)
      this.$router.push(newUrl)
    },
  },
  data: function () {
    return {
      model: null,
    }
  },
  created: function () {
    this.fetchData()
  },
}
</script>
