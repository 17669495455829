/* eslint-disable */
module.exports = {
  app: {
    boolean: {
      true: "Áno",
      false: "Nie",
    },
    button: {
      close: "Zatvoriť",
      delete: "Odstrániť",
      print: "Tlačiť",
      save: "Uložiť",
      send: "Odoslať do Ezdravia",
      storno: "Storno",
    },
    nezadane: "<span class='nezadane'>Nezadané</span>",
  },
  dashboard: {
    vysetrenia: {
      obdobie: {
        dnes: "Dnes",
        mesiac: "Posledných 30 dní",
        starsie: "Staršie",
        tyzden: "Posledných 7 dní",
        vcera: "Včera",
      },
    },
  },
  dataIterator: {
    nextPage: "Ďalšia stránka",
    noResultsText: "Nenašiel sa žiadny záznam",
    pageText: "{0}-{1} z {2}",
    prevPage: "Predchádzajúca stránka",
    rowsPerPageAll: "Všetky",
    rowsPerPageText: "Záznamov na stránke:",
  },
  dataTable: {
    rowsPerPageText: "Záznamov na stránke:",
  },
  enum: {
    cennikKod: {
      CBD: "Cena bodu",
      CBEU: "CB občan EU",
      CBN: "CB nekapitovaný",
      CBNM: "Návšteva mimo návštevných hodín",
      CBNP: "Návšteva v pracovných hodinách",
      CBO1: "CB očkovania I",
      CBO2: "CB očkovania II",
      CBP1: "CB prevencia I",
      CBP2: "CB prevencia II",
      CBS1: "CB SVaLZ I",
      CBS2: "CB SVaLZ II",
    },
    charakterDavky: {
      ADITIVNA: "Aditívna",
      NOVA: "Nová",
      OPRAVNA: "Opravná",
    },
    charakterPoistenca: {
      "": "",
      BE: "Bezdomovec",
      CU: "Cudzinec",
      EU: "EÚ",
      SR: "SR",
    },
    evysetrenie: {
      stav: {
        "": "Neodoslané",
        ERROR: "Chyba",
        NEW: "Neodoslané",
        SENT: "Odosiela sa",
        SUCCESS: "Úspešne uložené",
        WAITING: "Čakajúce na odoslanie",
        undefined: "",
      },
      typ: {
        ZAZNAM_O_ODBORNOM_VYSETRENI: "Odborné vyšetrenie",
        ZAZNAM_O_ZOBRAZOVACOM_VYSETRENI: "Zobrazovacie vyšetrenie",
        ZAZNAM_PREPUSTACIA_SPRAVA: "Prepúšťacia správa",
        undefined: "",
      },
    },
    ezdravieZP: {
      "00020001392": "VŠZP",
      "00020005740": "Union",
      "00020006599": "Dôvera",
      "00020006755": "NCZI",
      "00020007191": "Duna",
    },
    ehealthRecord: {
      status: {
        // EHEALTH_RECORD_STATUS_NA:
        0: {
          text: "",
          icon: "",
          color: "",
        },
        // EhealthRecordStatusWaiting:
        1: {
          text: "Čaká na odoslanie",
          icon: "hourglass_empty",
          color: "orange",
        },
        // EHEALTH_RECORD_STATUS_SAVING:
        2: {
          text: "Prebieha odoslanie",
          icon: "fas fa-spinner fa-pulse",
          color: "orange",
        },
        // EHEALTH_RECORD_STATUS_SAVE_ERROR:
        3: {
          text: "Chyba pri odosielaní",
          icon: "far fa-heart",
          color: "red",
        },
        // EHEALTH_RECORD_STATUS_SAVED:
        4: {
          text: "Úspešne odoslané",
          icon: "far fa-heart",
          color: "green",
        },
      },
      patientIdentification: {
        status: {
          // EHEALTH_RECORD_STATUS_NA:
          0: {
            text: "",
            icon: "",
            color: "",
          },
          // EhealthRecordStatusWaiting:
          1: {
            text: "Čaká na odoslanie",
            icon: "hourglass_empty",
            color: "orange",
          },
          // EHEALTH_RECORD_STATUS_SAVING:
          2: {
            text: "Prebieha identifikácia pacienta",
            icon: "fas fa-spinner fa-pulse",
            color: "orange",
          },
          // EHEALTH_RECORD_STATUS_SAVE_ERROR:
          3: {
            text: "Chyba pri identifikácii pacienta",
            icon: "far fa-heart",
            color: "red",
          },
          // EHEALTH_RECORD_STATUS_SAVED:
          4: {
            text: "Pacient úspešne identifikovaný",
            icon: "far fa-heart",
            color: "green",
          },
        },
      },
      versionStatus: {
        // NA
        0: {
          status: {
            // EHEALTH_RECORD_STATUS_NA:
            0: {
              text: "",
              icon: "",
              color: "",
            },
            // EhealthRecordStatusWaiting:
            1: {
              text: "Čaká na odoslanie",
              icon: "hourglass_empty",
              color: "orange",
            },
            // EHEALTH_RECORD_STATUS_SAVING:
            2: {
              text: "Prebieha odosielanie",
              icon: "fas fa-spinner fa-pulse",
              color: "orange",
            },
            // EHEALTH_RECORD_STATUS_SAVE_ERROR:
            3: {
              text: "Chyba pri odosielaní",
              icon: "far fa-heart",
              color: "red",
            },
            // EHEALTH_RECORD_STATUS_SAVED:
            4: {
              text: "Odoslané",
              icon: "far fa-heart",
              color: "green",
            },
          },
        },
        // DRAFT
        1: {
          status: {
            // EHEALTH_RECORD_STATUS_NA:
            0: {
              text: "",
              icon: "",
              color: "",
            },
            // EhealthRecordStatusWaiting:
            1: {
              text: "Čaká na odoslanie",
              icon: "hourglass_empty",
              color: "orange",
            },
            // EHEALTH_RECORD_STATUS_SAVING:
            2: {
              text: "Prebieha ukladanie draftu",
              icon: "fas fa-spinner fa-pulse",
              color: "orange",
            },
            // EHEALTH_RECORD_STATUS_SAVE_ERROR:
            3: {
              text: "Chyba pri ukladaní draftu",
              icon: "far fa-heart",
              color: "red",
            },
            // EHEALTH_RECORD_STATUS_SAVED:
            4: {
              text: "Uložený draft",
              icon: "far fa-heart",
              color: "green",
            },
          },
        },
        // FINAL
        2: {
          status: {
            // EHEALTH_RECORD_STATUS_NA:
            0: {
              text: "",
              icon: "",
              color: "",
            },
            // EhealthRecordStatusWaiting:
            1: {
              text: "Čaká na odoslanie",
              icon: "hourglass_empty",
              color: "orange",
            },
            // EHEALTH_RECORD_STATUS_SAVING:
            2: {
              text: "Prebieha ukladanie",
              icon: "fas fa-spinner fa-pulse",
              color: "orange",
            },
            // EHEALTH_RECORD_STATUS_SAVE_ERROR:
            3: {
              text: "Chyba pri ukladaní",
              icon: "far fa-heart",
              color: "red",
            },
            // EHEALTH_RECORD_STATUS_SAVED:
            4: {
              text: "Uložené",
              icon: "far fa-heart",
              color: "green",
            },
          },
        },
        // DELETED
        3: {
          status: {
            // EHEALTH_RECORD_STATUS_NA:
            0: {
              text: "",
              icon: "",
              color: "",
            },
            // EhealthRecordStatusWaiting:
            1: {
              text: "Čaká na odoslanie",
              icon: "hourglass_empty",
              color: "orange",
            },
            // EHEALTH_RECORD_STATUS_SAVING:
            2: {
              text: "Prebieha stornovanie",
              icon: "fas fa-spinner fa-pulse",
              color: "orange",
            },
            // EHEALTH_RECORD_STATUS_SAVE_ERROR:
            3: {
              text: "Chyba pri stornovaní",
              icon: "far fa-heart",
              color: "red",
            },
            // EHEALTH_RECORD_STATUS_SAVED:
            4: {
              text: "Stornované",
              icon: "far fa-heart",
              color: "green",
            },
          },
        },
      },
      type: {
        ZAPIS_ZAZNAM_O_VYSETRENI_V7: {
          text: "Zápis vyšetrenia",
          goToObject: "Prejsť na vyšetrenie",
        },
        STORNUJ_ZAZNAM_O_VYSETRENI_V7: {
          text: "Storno vyšetrenia",
          goToObject: "Prejsť na vyšetrenie",
        },
        DAJ_JRUZ_IDENTIFIKATOR_GW: {
          text: "Identifikácia pacienta",
          goToObject: "",
        },
        DAJ_PACIENT_INFO: {
          text: "Identifikácia pacienta s eID",
          goToObject: "",
        },
        ULOZ_PRESKRIPCNY_ZAZNAM: {
          text: "Zápis receptu",
          goToObject: "",
        },
        STORNUJ_PRESKRIPCNY_ZAZNAM: {
          text: "Stornovanie receptu",
          goToObject: "",
        },
        ZNEPLATNI_PRESKRIPCNY_ZAZNAM: {
          text: "Zneplatnenie receptu",
          goToObject: "",
        },
        ZAPIS_POTVRDENIE_DPN: {
          text: "Zapíš potvrdenie DPN",
          goToObject: "Zobraziť EDPN",
        },
      },
    },
    nahrada: {
      "01": "úraz, alebo ochorenie zavinené inou osobou",
      "02": "pracovný úraz",
      "03": "ošetrenie, alebo vyšetrenie v dôsledku profesionálneho poškodenia zdravia – choroba z povolania",
      "04": "ošetrenie akútnych následkov požitia alkoholu alebo omamných látok",
      "05": "sociálny dôvod hospitalizácie",
      "06": "porušenie liečebného režimu",
      "07": "ochranná liečba",
      "08": "iný dôvod"
    },
    pripocitatelnaPolozkaKod: {
      "SNIMK": "bez rozlíšenia rozmerov",
      "FOTO01": "rozmer 3 x 4",
      "FOTO02": "rozmer 15 x 30",
      "FOTO03": "rozmer 18 x 24",
      "FOTO04": "rozmer 24 x 30",
      "FOTO05": "rozmer 35 x 35",
      "FOTO06": "rozmer 30 x 40",
      "FOTO07": "rozmer 35 x 43",
      "FOTO08": "rozmer 24 x 30 (mamografie)",
      "FOTO09": "rozmer 18 x 24 (mamografie)",
      "FOTO10": "rozmer A4",
      "FOTO11": "rozmer 14 x 17 inch laser",
      "FOTO12": "rozmer 8 x 10 inch laser",
      "FOTO13": "rozmer A3",
      "FOTO14": "rozmer 13 x 18",
      "FOTO15": "rozmer 15 x 40",
      "FOTO16": "rozmer 5 x 7",
      "FOTO17": "rozmer 18 x 43",
      "FOTO18": "rozmer 20 x 40",
      "PACS": "bez rozlíšenia",
    },
    poistovna: {
      DOVERA: "Dôvera",
      UNION: "Union",
      VSZP: "VŠZP",
      undefined: "",
    },
    sex: {
      UNKNOWN: "Neznáme",
      MALE: "Mužské",
      FEMALE: "Ženské",
      OTHER: "Iné",
      skratka: {
        UNKNOWN: "?",
        MALE: "M",
        FEMALE: "Ž",
        OTHER: "I",
      },
      undefined: "",
    },
    stavObdobia: {
      NA_ODOSLANIE: "Na odoslanie",
      NOVE: "Nové",
      ODOSIELANIE: "Odosiela sa",
      ODOSLANIE_CHYBA: "Chyba pri odosielaní",
      ODOSLANIE_OK: "Odoslané",
      VYSLEDOK_CHYBA: "Výsledok s chybami",
      VYSLEDOK_OK: "Výsledok OK",
      ZISKAVANIE_VYSLEDKU: "Získava sa výsledok",
      ZISKAVANIE_VYSLEDKU_CHYBA: "Získavanie výsledku chyba",
    },
    stavOckovanie: {
      PLANOVANE: "Plánované",
      VYKONANE: "Vykonané",
      ZRUSENE: "Zrušené",
    },
    typDokladuPoistenca: {
      EPZP: "EPZP/AHIC",
      EU: "EÚ (PD S1)",
      NEUDANY: "Neudaný",
    },
    typOdosielatela: {
      A: "Neodkladná ZS",
      D: "Dispenzárna ZS",
      K: "Kontrola",
      O: "Oddelenie alebo lekár v ambulancii",
      P: "Priame poskytnutie ZS",
    },
    typPoistenca: {
      A: "dialýzy a peritoneálne dialýzy u ambulantných pacientov",
      C: "dispenzárna starostlivosť",
      D: "peritoneálne dialýzy vykonané v domácnosti",
      H: "hospitalizovaný poistenec",
      N: "neodkladné výkony za akútne ošetrených poistencov, vrátane kapitovaných poistencov",
      P: "poistenec, ktorému je podaný liek v ambulancii nakúpený centrálnym nákupom v ZP",
      S: "spoluúčasť poistenca na úhrade celkovej ceny pri stomatologických náhradách a čeľustno-ortopedických aparátoch",
      U: "dialýzy a peritoneálne dialýzy u hospitalizovaných pacientov",
    },
    typPoistencaDoklad: {
      BE: "Ambulatné doklady - poistenci bezdomovci",
      CU: "Ambulatné doklady - poistenci cudzinci",
      EU: "Ambulatné doklady - poistenci EÚ",
      SR: "Ambulatné doklady - poistenci SR",
    },
    typPoistencaZahranicny: {
      DP: "DP",
      EU: "EÚ",
    },
    typSubjektu: {
      INY: "Iný",
      LAB: "LAB",
      NCZI: "NCZI",
      SP: "SP",
      VUC: "VÚC",
      ZP: "ZP",
    },
    typZariadenia: {
      A: "do ambulantného zariadenia, ADOS, stacionárov a na dialýzy",
      I: "do iného zariadenia špecializovanej ambulantnej starostlivosti",
      O: "do odborného liečebného ústavu, prírodných liečebných kúpeľov",
      S: "na vyšetrenie/ošetrenie do spoločných vyšetrovacích a liečebných zložiek – SVLZ",
      U: "do ústavnej zdravotnej starostlivosti, LDCH, geriatrických centier psychiatrických liečební, vysoko špecializovaných odborných ústavov protidrogových centier",
      X: "uvedie čeľustný ortopéd v prípade bezdôvodne prerušenej čeľustno-ortopedickej liečby na viac ako 6 mesiacov (kód „X053“)",
      Z: "uvedie zubný lekár v prípade, že ošetrenie poistenca vykonal na základe žiadosti a odoslania iným zubným lekárom",
    },
    eDPNFilterItems: {
      Ukoncene: "Ukončené",
      Stornovane: "Stornované",
      Vsetky: "Všetky",
    }
  },
  error: {
    INVALID_USERNAME_PASSWORD: "Neznáme prihlasovacie údaje!",
    ALREADY_EXISTS: "Položka už existuje v databáze!",
    EMAIL_ALREADY_USED: "Používateľ s touto e-mailovou adresou už existuje!",
  },
  ezdravie: {
    cardStatus: {
      CARD_STATUS_CONNECTED: "Kartička lekára je pripojená",
      CARD_STATUS_DISCONNECTED: "Kartička lekára nie je pripojená",
      CARD_STATUS_UNKNOWN: "Stav kartičky lekára nedostupný",
      undefined: "Stav kartičky lekára nedostupný",
    },
    enabled: {
      false: "Modul ezdravie nie je aktivovaný",
      true: "Modul ezdravie je aktivovaný",
    },
    moduleConnected: {
      false: "Komunikačný modul nedostupný",
      true: "Komunikačný modul pripojený",
      undefined: "",
    },
    pagination: "Zobrazené záznamy {0} až {1}",
    serverStatus: {
      false: "NCZI server offline",
      true: "NCZI server online",
      undefined: "Stav NCZI servera nedostupný",
    },
    hasPatientJruzID: {
      false: "Pacient nie je identifikovaný v ezdraví",
      true: "Pacient je identifikovaný v ezdraví",
    },
  },
  form: {
    addItems: "Pridávanie položiek",
    ambulancia: {
      title: {
        add: "Vytvorenie ambulancie",
        edit: "Úprava ambulancie",
      },
    },
    delete: "Odstrániť",
    desc: "Popis",
    hint: "Vysvetľujúci text",
    item: {
      buttongroup: "Zoznam tlačítok",
      checkbox: "Zaškrtávacie políčka",
      combobox: "Vyberač zo zoznamu",
      number: "Číselné políčko",
      radio: "Prepínače",
      separator: "Oddelovač",
      slider: "Posuvník",
      subheader: "Podnadpis",
      text: "Textové políčko",
      textarea: "Textové pole",
    },
    label: "Názov",
    param: {
      add: "Pridať hodnotu",
      delete: "Odstrániť hodnotu",
      float: "Počet desatinných miest",
      lines: "Počet riadkov",
      mask: "Maska",
      max: "Max",
      maxlength: "Max. počet znakov",
      min: "Min",
      minlength: "Min. počet znakov",
      multi: "Viacnásobný",
      slider: {
        aj: "Aj s posuvníkom",
        bez: "Bez posuvníka",
        iba: "Iba posuvník",
        text: "Posuvník",
      },
      text: "Text",
      type: {
        buttongroup: "Zoznam tlačítok",
        checkbox: "Zaškrtávacie políčka",
        combo: "Vyberač zo zoznamu",
        radio: "Prepínače",
        text: "Typ",
      },
      valueText: "Hodnota",
      values: "Hodnoty",
    },
    required: "Povinná položka",
    subjekt: {
      title: {
        add: "Vytvorenie subjektu",
        edit: "Úprava subjektu",
      },
    },
    tags: "Kľúčové slová",
    text: "Formulár",
    title: "Názov",
    tree: "Strom",
  },
  helpdesk: {
    notAllowed:
      "Funkcia Help Desk je prístupná len užívateľom PZS overených na serveri.\n Overenie sa nachádza v menu Admiistrácia, položka PZS.",
  },
  noDataText: "Žiadne údaje",
  pacient: {
    cakaren: {
      false: "Pacient nie je v čakárni",
      true: "Pacient je v čakárni",
    },
    jeKapitovany: "Pacient je kapitovaný",
    nieJeKapitovany: "Pacient nie je kapitovaný",
  },
  required: {
    title: "Názov je povinný.",
  },
  thing: {
    type: {
      ThingNeziaducaReakcia: {
        icon: "fas fa-allergies",
        text: "Nežiadúca reakcia",
        delete: {
          confirm: "Skutočne chcete odstrániť nežiadúcu reakciu?",
          success: "Nežiadúca reakcia bola úspešne odstránená",
          error: "Pri odstraňovaní nežiadúcej reakcie nastala chyba",
        },
      },
      ThingKrvnyTlak: {
        icon: "fas fa-heartbeat",
        text: "Meranie Krvného Tlaku",
        delete: {
          confirm: "Skutočne chcete odstrániť meranie krvného tlaku?",
          success: "Meranie krvého tlaku bolo úspešne odstránené",
          error: "Pri odstraňovaní merania krvného tlaku nastala chyba",
        },
      },
      ThingKrvnaSkupina: {
        icon: "fas fa-vial",
        text: "Vyšetrenie Krvnej Skupiny",
        delete: {
          confirm: "Skutočne chcete odstrániť vyšetrenie krvnej skupiny?",
          success: "Vyšetrenie krvnej skupiny bolo úspešne odstránené",
          error: "Pri odstraňovaní vyšetrenie krvnej skupiny nastala chyba",
        },
      },
      ThingVitAntropHodnoty: {
        icon: "fas fa-weight",
        text: "Meranie Vitálnych a Antropometrických Hodnôt",
        delete: {
          confirm:
            "Skutočne chcete odstrániť meranie vitálnych a antropometrických hodnôt?",
          success:
            "Meranie vitálych a antropometrických hodnôt bolo úspešne odstránené",
          error:
            "Pri odstraňovaní merania vitálnych a antropometrických hodnôt nastala chyba",
        },
      },
      ThingDiagnoza: {
        icon: "fas fa-diagnoses",
        text: "Diagnóza",
        delete: {
          confirm: "Skutočne chcete odstrániť diagnózu?",
          success: "Diagnóza bola úspešne odstránená",
          error: "Pri odstraňovaní diagnózy nastala chyba",
        },
      },
      ThingOckovanie: {
        icon: "fas fa-syringe",
        text: "Očkovanie",
        delete: {
          confirm: "Skutočne chcete odstrániť očkovanie?",
          success: "Očkovanie bolo úspešne odstránené",
          error: "Pri odstraňovaní očkovania nastala chyba",
        },
      },
      ThingOdporucanie: {
        icon: "fas fa-user-md",
        text: "Výmenný lístok",
        delete: {
          confirm: "Skutočne chcete odstrániť výmenný lístok?",
          success: "Výmenný lístok bol úspešne odstránený",
          error: "Pri odstraňovaní výmenného lístka nastala chyba",
        },
      },
      ThingRecept: {
        icon: "fas fa-pills",
        text: "Recept",
        delete: {
          confirm: "Skutočne chcete odstrániť recept?",
          success: "Recept bol úspešne odstránený",
          error: "Pri odstraňovaní receptu nastala chyba",
        },
      },
      ThingMedikacia: {
        icon: "fas fa-pills",
        text: "Medikácia",
        delete: {
          confirm: "Skutočne chcete odstrániť medikáciu?",
          success: "Medikácia bola úspešne odstránená",
          error: "Pri odstraňovaní medikácie nastala chyba",
        },
      },
      ThingZdravotnickaPomocka: {
        icon: "fas fa-crutch",
        text: "Zdravotnícka pomôcka",
        delete: {
          confirm: "Skutočne chcete odstrániť zdravotnícku pomôcku?",
          success: "Zdravotnícka pomôcka bola úspešne odstránená",
          error: "Pri odstraňovaní zdravotníckej pomôcky nastala chyba",
        },
      },

      ThingVykon: {
        icon: "fas fa-band-aid",
        text: "Zdravotný Výkon",
        delete: {
          confirm: "Skutočne chcete odstrániť zdravotný výkon?",
          success: "Zdravotný výkon bol úspešne odstránený",
          error: "Pri odstraňovaní zdravotného výkonu nastala chyba",
        },
      },
      ThingVysetrenie: {
        icon: "fas fa-stethoscope",
        text: "Vyšetrenie",
        delete: {
          confirm:
            "Naozaj si želáte odstrániť vyšetrenie so všetkými súvisiacimi dátami?",
          success: "Vyšetrenie bola úspešne odstránené",
          error: "Pri odstraňovaní vyšetrenia nastala chyba",
        },
      },
      ThingChirurgickyVykon: {
        icon: "fas fa-user-injured",
        text: "Chirurgický výkon",
        delete: {
          confirm: "Skutočne chcete odstrániť chirurgický výkon?",
          success: "Chirurgický výkon bol úspešne odstránený",
          error: "Pri odstraňovaní chirurgického výkonu nastala chyba",
        },
      },
      ThingBinary: {
        icon: "fas fa-file",
        text: "Súbor ",
        delete: {
          confirm: "Skutočne chcete odstrániť súbor?",
          success: "Súbor bol úspešne odstránený",
          error: "Pri odstraňovaní súboru nastala chyba",
        },
      },
      ThingRestrikcia: {
        icon: "fas fa-lock",
        text: "Reštrikcia",
        delete: {
          confirm: "Skutočne chcete zmazať reštrikciu záznamu?",
          success: "Reštrikcia bola odstránená",
          error: "Pri zmazaní reštrikcie nastala chyba",
        },
      },
      ThingForm: {
        icon: "fas fa-file-alt",
        text: "Formulár ",
        delete: {
          confirm: "Skutočne chcete odstrániť formulár?",
          success: "Formulár bol úspešne odstránený",
          error: "Pri odstraňovaní formuláru nastala chyba",
        },
      },
      unknown: "",

      undefined: "",
    },
  },
  user: {
    save: {
      error: {
        email: "Neplatná emailová adresa",
        name: "Meno je moc krátke",
        phone: "Telefónne číslo môže obsahovať len čísla",
      },
    },
  },
}
