<template>
  <v-container grid-list-sm class="pa-0">
    <v-layout row>
      <v-flex>
        <v-list dense>
          <v-list-tile v-if="kontrola.ID" @click="deleteKontrola">
            <v-list-tile-title>Žiadny termín</v-list-tile-title>
          </v-list-tile>
          <v-list-tile @click="setKontrolaMonths(1)">
            <v-list-tile-title>O mesiac</v-list-tile-title>
          </v-list-tile>
          <v-list-tile @click="setKontrolaMonths(3)">
            <v-list-tile-title>O 3 mesiace</v-list-tile-title>
          </v-list-tile>
          <v-list-tile @click="setKontrolaMonths(6)">
            <v-list-tile-title>O pol roka</v-list-tile-title>
          </v-list-tile>
          <v-list-tile @click="setKontrolaMonths(12)">
            <v-list-tile-title>O rok</v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-flex>
      <v-flex>
        <v-date-picker
          :value="initialDate"
          @input="save"
          locale="sk"
          :first-day-of-week="1"
          type="month"
          :min="minDate"
          no-title
        ></v-date-picker>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import * as Miscella from "./../../miscella.js"
import * as enums from "@/plugins/enums.js"
import moment from "moment"

export default {
  props: {
    pacientId: {
      type: String,
      required: true,
    },
    kontrola: {
      type: Object,
      required: true,
    },
  },
  computed: {
    initialDate: function () {
      var date = moment()
      if (this.kontrola && this.kontrola.Datum) {
        date = moment(this.kontrola.Datum)
      }
      return date.format("YYYY-MM-DD")
    },
    minDate: function () {
      return moment().format("YYYY-MM")
    },
    kontrolaDisplay: function () {
      return Miscella.isSet(this.kontrola)
        ? Miscella.kontrolaFormat(this.kontrola.Datum)
        : ""
    },
  },
  methods: {
    save: async function (kontrolaDatum) {
      let params = {
        // ID: this.id,
        Datum: moment(kontrolaDatum).format("YYYY-MM-DD"),
        CasOd: "00:00",
        Desc: "Kontrola",
        // AmbulanceId: localStorage.getItem("ambId"),
        PatientId: Miscella.isSet(this.pacientId) ? this.pacientId : "",
        Duration: 0,
        Typ: enums.TypEventu.TypEventuKontrola,
      }
      try {
        var result = await this.$api.post(
          "/api/ambulance/patient/kontrola",
          params
        )
        this.$store.commit(
          "setSuccess",
          this.$vuetify.t("Termín bol úspešne uložený.")
        )
        this.$emit("saved", result)
      } catch (err) {
        this.$store.commit(
          "setError",
          this.$vuetify.t("Pri ukladaní termínu vznikla chyba ({message}).", {
            message: err,
          })
        )
      }
    },
    deleteKontrola: function () {
      let kontrolaId = ""
      if (Miscella.isSet(this.kontrola)) {
        kontrolaId = this.kontrola.ID
      }
      return this.$api
        .delete(
          `api/ambulance/patient/kontrola/${this.pacientId}/${kontrolaId}`
        )
        .then(() => {
          this.$store.commit(
            "setSuccess",
            this.$vuetify.t("Termín bol úspešne vymazaný.")
          )
          this.$emit("saved")
        })
        .catch((err) => {
          this.$store.commit(
            "setError",
            this.$vuetify.t("Pri mazaní termínu vznikla chyba ({message}).", {
              message: err,
            })
          )
        })
    },
    setKontrolaMonths: function (addMonths) {
      this.save(moment().add(addMonths, "months").format("YYYY-MM-DD"))
    },
  },
}
</script>
