<template>
  <v-container fluid px-0>
    <v-layout row>
      <v-flex xs2>
        <v-tooltip top>
          <span slot="activator">{{ vykon.date | formatDatum }}</span>
          <span>{{ $vuetify.t("Dátum") }}</span>
        </v-tooltip>
      </v-flex>
      <v-flex xs2>
        <v-tooltip top>
          <span slot="activator">{{
            vykon.vykon.vykon_codebook !== null
              ? vykon.vykon.vykon_codebook.skratka
              : vykon.vykon.kod_vykonu !== null
              ? vykon.vykon.kod_vykonu
              : ""
          }}</span>
          <span>{{ $vuetify.t("Kód") }}</span>
        </v-tooltip>
      </v-flex>
      <v-flex xs2>
        <v-tooltip top>
          <span slot="activator">{{
            vykon.vykon.diagnoza !== null ? vykon.vykon.diagnoza.skratka : ""
          }}</span>
          <span>{{ $vuetify.t("Diagnóza") }}</span>
        </v-tooltip>
      </v-flex>
      <v-flex xs2>
        <v-tooltip top>
          <span slot="activator">{{
            vykon.vykon.pocet !== null ? vykon.vykon.pocet : 0
          }}</span>
          <span>{{ $vuetify.t("Početnosť výkonu") }}</span>
        </v-tooltip>
      </v-flex>
      <v-flex xs2>
        <v-tooltip top>
          <span slot="activator">{{
            vykon.vykon.body !== null ? vykon.vykon.body : 0
          }}</span>
          <span>{{ $vuetify.t("Počet bodov") }}</span>
        </v-tooltip>
      </v-flex>
      <v-flex xs2>
        <v-tooltip top>
          <span slot="activator">{{
            vykon.vykon.pohyb_poistenca !== null
              ? vykon.vykon.pohyb_poistenca
              : ""
          }}</span>
          <span>{{ $vuetify.t("Pohyb poistenca") }}</span>
        </v-tooltip>
        <v-tooltip top>
          <span slot="activator">{{
            vykon.vykon.pohyb_poistenca_odbornost !== null
              ? vykon.vykon.pohyb_poistenca_odbornost.skratka
              : ""
          }}</span>
          <span>{{ $vuetify.t("Pohyb poistenca") }}</span>
        </v-tooltip>
      </v-flex>
      <v-flex xs2>
        <v-tooltip top>
          <span slot="activator">{{
            vykon.vykon.nahrada !== null ? vykon.vykon.nahrada : 0
          }}</span>
          <span>{{ $vuetify.t("Náhrada") }}</span>
        </v-tooltip>
      </v-flex>
      <v-flex xs2>
        <v-tooltip top>
          <span slot="activator">{{
            vykon.vykon.pripocitatelne_polozky !== null
              ? getLengthPolozky(vykon.vykon.pripocitatelne_polozky)
              : 0
          }}</span>
          <span>{{ $vuetify.t("Počet pipočítateľných položiek") }}</span>
        </v-tooltip>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  name: "VykonRow",
  props: {
    vykon: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getLengthPolozky: function (polozky) {
      return polozky.length
    },
  },
}
</script>
