<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    full-width
    max-width="290"
    :data-vv-as="label"
    :disabled="disabled"
    nudge-left="250"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :prepend-inner-icon="prependInnerIcon"
        :value="displayValue"
        :clearable="!isRequired"
        :label="label"
        :error-messages="errors.collect(name)"
        @click:clear="clearValue"
        @blur="updateValueOnBlur"
        :disabled="disabled"
        mask="##.##.####"
        placeholder="dd.mm.rrrr"
        ref="textField"
      >
        <template v-slot:append>
          <v-icon v-on="on"> event </v-icon>
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      :value="internalValue"
      @change="changeValue"
      no-title
      :type="type"
      locale="sk"
      :first-day-of-week="1"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import * as Miscella from "./../miscella.js"
import moment from "moment"

export default {
  inject: ["$validator"],
  props: {
    name: {
      type: String,
      default: "field",
    },
    label: {
      type: String,
      default: "Dátum",
    },
    value: {
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    format: {
      type: String,
      default: moment.defaultFormat,
    },
    displayFormat: {
      type: String,
      default: "DD.MM.YYYY",
    },
    type: {
      type: String,
      default: "date",
    },
    prependInnerIcon: {
      type: String,
      default: "",
    },
  },
  data: function () {
    return {
      menu: false,
    }
  },
  computed: {
    isRequired: function () {
      var field = this.$validator.fields.find({ name: this.name })
      if (field) {
        return field.isRequired
      }
      return false
    },
    internalFormat: function () {
      if (this.type === "month") {
        return "YYYY-MM"
      } else {
        return "YYYY-MM-DD"
      }
    },
    displayValue: function () {
      moment.locale("sk")
      return !Miscella.isEmpty(this.value)
        ? moment(this.value, this.format).format(this.displayFormat)
        : undefined
    },
    internalValue: function () {
      return !Miscella.isEmpty(this.value)
        ? moment(this.value, this.format).format(this.internalFormat)
        : undefined
    },
  },
  methods: {
    isValueDate: function (value) {
      let ret = false
      if (this.type !== "date") {
        ret = true
      } else {
        if (Miscella.isSet(value)) {
          const regex = RegExp("^[0-9]+[.][0-9]+[.][0-9][0-9][0-9][0-9]$")
          if (regex.test(value)) {
            moment.locale("sk")
            if (moment(value, this.displayFormat).isValid()) {
              ret = true
            }
          }
        }
      }
      //console.log("isValueDate", ret)
      return ret
    },
    updateValueOnBlur: function (evt) {
      //console.log("updateValueOnBlur", evt)
      if (!this.menu) {
        if (this.isValueDate(evt.target.value)) {
          this.updateValue(evt.target.value)
        } else {
          this.clearValue()
        }
      }
    },
    changeValue: function (value) {
      //console.log("changeValue", value)
      this.updateValue(value)
    },
    clearValue: function () {
      //console.log("clearValue", evt)
      //this.$refs.textField.value = ""
      //this.value = null
      this.updateValue(null)
    },
    updateValue: function (value) {
      if (this.menu) {
        //console.log("updateValue", value, this.format)
        this.$emit(
          "input",
          Miscella.isSet(value) ? moment(value).format(this.format) : value
        )
        this.menu = false
      } else {
        //console.log("updateValue", value, this.displayFormat)
        this.$emit(
          "input",
          Miscella.isSet(value)
            ? moment(value, this.displayFormat).format(this.format)
            : value
        )
      }
    },
  },
}
</script>
