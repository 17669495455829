import BaseApiService from "./baseApiService.js"

export default class EhealthService extends BaseApiService {
  constructor(api) {
    super(
      api,
      "/v1/proto.EHealth/SendToEhealth",
      "/v1/proto.EHealth/SmartcardStatus",
      "/v1/proto.EHealth/SmartcardLogs",
      "/v1/proto.EHealth/EhealthRecords",
      "/v1/proto.EHealth/DajZpPrZs",
      "/v1/proto.EHealth/VyhladajVL",
      "/v1/proto.EHealth/VyhladajZaznamyOVysetreniach",
      "/v1/proto.EHealth/DajZaznamOVysetreni",
      "/v1/proto.EHealth/ZapisSuhlasOsobyPrePzs",
      "/v1/proto.EHealth/PrevezmiVymennyListok",
      "/v1/proto.EHealth/OverVerziuCiselnikov",
      "/v1/proto.EHealth/DajPacientskySumar",
      "/v1/proto.EHealth/DajPacientskySumarEds",
      "/v1/proto.EHealth/DajPacientskySumarKontaktneUdaje",
      "/v1/proto.EHealth/ZrusKontaktneUdajePacientskehoSumaru",
      "/v1/proto.EHealth/ZapisPacientskehoSumaruKontaktneUdaje",
      "/v1/proto.EHealth/DajPSKontaktneUdajeSNaplnenimPacienta",
      "/v1/proto.EHealth/OverPoistenca",
      "/v1/proto.EHealth/NajdiGenerika",
      "/v1/proto.EHealth/DajLiekovuKartu",
      "/v1/proto.EHealth/OverPoistenca",
      "/v1/proto.EHealth/OverInterakciePacienta",
      "/v1/proto.EHealth/UlozPreskripcnyZaznam",
      "/v1/proto.EHealth/StornujPreskripcnyZaznam",
      "/v1/proto.EHealth/ZneplatniPreskripcnyZaznam",
      "/v1/proto.EHealth/ZapisPotvrdenieDPN",
      "/v1/proto.EHealth/StornujPotvrdenieDPN",
      "/v1/proto.EHealth/VyhladajPotvrdenieDPN",
      "/v1/proto.EHealth/DajPotvrdenieDPN",
      "/v1/proto.EHealth/VyhladajHistoriuPotvrdeniaDPN",
      "/v1/proto.EHealth/VyhladajPoisteniaSP"
    )
  }
}
