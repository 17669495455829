<template>
  <v-container fluid>
    <v-layout row>
      <v-flex class="text-xs-right" xs12>
        <v-btn color="primary" flat @click="addItem" align-self-end>{{
          $vuetify.t("Pridať eDPN")
        }}</v-btn>
      </v-flex>
    </v-layout>
    <v-layout row wrap v-if="items1.length > 0">
      <v-flex xs12 class="py-2">
        <v-btn-toggle v-model="filterStav">
          <v-btn flat :value="enums.eDPNFilterItems.Ukoncene">
            {{ $vuetify.t("$vuetify.enum.eDPNFilterItems.Ukoncene") }}
          </v-btn>
          <v-btn flat :value="enums.eDPNFilterItems.Stornovane">
            {{ $vuetify.t("$vuetify.enum.eDPNFilterItems.Stornovane") }}
          </v-btn>
          <v-btn flat :value="enums.eDPNFilterItems.Vsetky">
            {{ $vuetify.t("$vuetify.enum.eDPNFilterItems.Vsetky") }}
          </v-btn>
        </v-btn-toggle>
      </v-flex>
      <v-flex xs12 class="mb-3">
        <v-card>
          <EDPNList
            ref="list1"
            :items="displayItems"
            :verzie="verzie"
            :showHistory="true"
            :title="$vuetify.t('Zoznam potvrdení o DPN')"
            @addItem="addItem"
            @editItem="editItem"
            @showVerzie="showVerzie"
            @predlzPN="predlzPN"
          />
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex class="text-xs-center" xs12>
        <v-btn color="primary" flat @click="fetchData1" align-self-end>{{
          $vuetify.t("Načítať potvrdenia o dočasnej PN z EZdravia")
        }}</v-btn>
      </v-flex>
    </v-layout>
    <BaseDialog
      ref="dialog"
      width="90%"
      :dialogTitle="$vuetify.t('Elektronické potvrdenie o dočasnej PN')"
      @save="$refs.form.saveForm()"
    >
      <FormEDpn ref="form" :patientId="id" @saved="savedItem"></FormEDpn>
      <template slot="buttons">
        <v-btn flat @click.native="$refs.form.print()">{{
          $vuetify.t("$vuetify.app.button.print")
        }}</v-btn>

        <v-btn
          flat
          :disabled="$refs.form?.stav === 0 || $refs.form?.stav === 3"
          @click.native="$refs.form.storno()"
          >{{ $vuetify.t("$vuetify.app.button.storno") }}</v-btn
        >

        <v-btn
          flat
          :disabled="$refs.form?.stav > 1"
          @click.native="$refs.dialog.save()"
          >{{ $vuetify.t("$vuetify.app.button.send") }}</v-btn
        >
        <v-btn flat @click.native="$refs.dialog.close()">{{
          $vuetify.t("$vuetify.app.button.close")
        }}</v-btn>
      </template>
    </BaseDialog>
  </v-container>
</template>

<script>
import * as enums from "@/plugins/enums.js"
import BaseDialog from "@/components/BaseDialog.vue"
import FormEDpn from "@/components/forms/FormEDpn.vue"
import EDPNList from "@/components/EDPNList.vue"
import moment from "moment"
import { mapState } from "vuex"

export default {
  components: {
    BaseDialog,
    FormEDpn,
    EDPNList,
  },
  props: ["id"],
  data: function () {
    return {
      showAllItems1: false,
      filterStav: enums.eDPNFilterItems.Vsetky,
      items1: [],
      displayItems: [],
      activeTab: 0,
      verzie: {},
    }
  },
  computed: {
    ...mapState({
      ambulanceID: (state) => state.me?.ambulance?.id,
    }),
    enums: function () {
      return enums
    },
  },
  watch: {
    filterStav: function (newValue) {
      if (newValue !== null) {
        this.filterItems()
      }
    },
  },
  methods: {
    itemsContainsPlatnaPN: function (items) {
      let found = false
      for (let i = 0; i < items.length; i++) {
        const item = items[i]
        if (item.schopny_prace_od !== "") {
          //je ukoncena
        } else {
          if (item.header?.version_status === "VER04") {
            //je storno
          } else {
            found = true
          }
        }
      }
      return found
    },
    addItem: function () {
      let found = this.itemsContainsPlatnaPN(this.items1)
      if (found) {
        this.processError("Nie je možné vytvoriť novú eDPN, existuje platná.")
        return
      }
      let datumTeraz = moment().toDate()
      this.$refs.form.new(datumTeraz)
      this.$refs.dialog.open()
    },
    editItem: async function (item, parentID) {
      console.log("EDPN.VUE, editItem", item, parentID)
      let formDisabled = false
      if (parentID !== "") {
        formDisabled = true
        if (item?.header?.rc_id === parentID) {
          formDisabled = false
        }
      }
      await this.$refs.form.edit(item?.header?.rc_id, formDisabled)
      this.$refs.dialog.open()
    },
    predlzPN: function (item) {
      this.$refs.form.predlzPN(item)
      this.$refs.dialog.open()
    },
    savedItem: function () {
      // this.$refs.dialog.close()
      this.fetchData1()
    },
    fetchData1: async function () {
      console.log("EDPN.VUE, fetchData1")
      this.items1 = []
      try {
        this.startAppLoading("Prebieha komunikácia s EZdravím.")
        let res = await this.$api.ehealthService.vyhladajPotvrdenieDPN({
          patient_id: this.id,
          ambulance_id: this.ambulanceID,
          aj_stornovane_zaznamy: true,
          bez_konca_platnosti: false,
          strankovanie: {
            velkost_stranky: "500",
            index: "1",
          },
        })
        console.log("EDPN.VUE, fetchData1 res", res)
        this.items1 = res.potvrdenia_dpn
        this.filterStav = "V"
        this.filterItems()
      } catch (error) {
        this.processError("Pri komunikácii s EZdravím vznikla chyba")
        console.log("Pri komunikácii s EZdravím vznikla chyba", error)
      } finally {
        this.stopAppLoading()
      }
    },
    filterItems: function () {
      console.log("EDPN.VUE, filterItems START")
      this.displayItems = []
      for (let i = 0; i < this.items1.length; i++) {
        const item = this.items1[i]
        let copy = false
        if (this.filterStav === enums.eDPNFilterItems.Vsetky) {
          copy = true
        }
        if (this.filterStav === enums.eDPNFilterItems.Stornovane) {
          console.log(
            "EDPN.VUE, filterItems STORNO " + item.header?.version_status
          )
          copy = item.header?.version_status === "VER04"
        }
        if (this.filterStav === enums.eDPNFilterItems.Ukoncene) {
          console.log("EDPN.VUE, filterItems UKONCENE " + item.schopny_prace_od)
          copy = item.schopny_prace_od !== null && item.schopny_prace_od !== ""
        }
        if (copy) {
          this.displayItems.push(item)
        }
      }
      console.log("EDPN.VUE, filterItems END " + this.displayItems.length)
    },
    showVerzie: async function (item) {
      console.log("EDPN.VUE, showVerzie", item)
      this.items = []
      try {
        this.startAppLoading("Prebieha komunikácia s EZdravím.")
        let res = await this.$api.ehealthService.vyhladajHistoriuPotvrdeniaDPN({
          patient_id: this.id,
          ambulance_id: this.ambulanceID,
          id_potvrdenia_dpn: item.header.rc_id,
          strankovanie: {
            velkost_stranky: "500",
            index: "1",
          },
          triedenie: {
            pole: "DatumVytvorenia",
            typ: "DESC",
          },
        })
        console.log("EDPN.VUE, showVerzie res", res)
        //this.verzie[item.header.rc_id] = res.potvrdenia_dpn
        this.$set(this.verzie, item.header.rc_id, res.potvrdenia_dpn)
        console.log("EDPN.VUE, showVerzie", this.verzie)
      } catch (error) {
        console.error("Chyba pri nacitani historie EDPN", error)
      }
      this.stopAppLoading()
    },
  },
  mounted: function () {
    //this.fetchData1()
  },
}
</script>
