<template>
  <v-form>
    <v-container fluid>
      <v-layout row>
        <v-flex xs12 md6>
          <DatePicker
            v-model="model.date"
            :label="$vuetify.t('Dátum platnosti od')"
            name="datum"
            v-validate="'required'"
          ></DatePicker>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs12 md6>
          <DatePicker
            v-model="model.restrikcia.datum_ukoncenia"
            :label="$vuetify.t('Dátum platnosti do')"
            name="datumUkoncenia"
          ></DatePicker>
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
import BaseForm from "./../BaseForm.vue"
import moment from "moment"
import * as Miscella from "./../../miscella.js"
import DatePicker from "./../DatePicker.vue"

export default {
  name: "FormRestrikcia",
  extends: BaseForm,
  components: {
    DatePicker,
  },
  props: {
    pacientId: {
      type: String,
      required: true,
    },
  },
  $_veeValidate: {
    validator: "new", // give me my own validator scope.
  },
  data: function () {
    return {
      model: null,
    }
  },
  computed: {
    ambulanceId: function () {
      let id = null
      if (Miscella.isSet(this.$store.state.me.ambulance)) {
        id = this.$store.state.me.ambulance.id
      }
      return id
    },
  },
  watch: {},
  methods: {
    new: function () {
      this.init()
      this.$emit("activated")
    },
    edit: function (item) {
      this.init()
      this.model = Miscella.cloneObject(item)
      this.$emit("activated")
    },
    init: function () {
      console.log("init")
      this.model = {
        id: "",
        patient_id: this.pacientId,
        ambulance_id: this.ambulanceId,
        date: moment().format(),
        parent_id: "",
        restrikcia: {
          datum_ukoncenia: null,
        },
      }

      this.$validator.reset()
      this.errors.clear()
    },
    save: async function () {
      try {
        var params = { thing: Miscella.cloneObject(this.model) }
        var result = await this.$api.thingService.saveThing(params)
        this.notifySuccess("Reštrikcia bola úspešne uložená")
        this.$emit("saved", result.thing)
        this.model = result.thing
      } catch (err) {
        this.processError(err, "Pri ukladaní reštrikcie vznikla chyba")
      }
    },
  },
  mounted: function () {},
  created: function () {
    this.init()
  },
}
</script>
