<template>
  <v-form>
    <v-container fluid>
      <v-layout row wrap>
        <v-flex xs12>
          <TextField
            v-model="model.identCislo"
            :label="$vuetify.t('Identifikačné číslo')"
            name="identCislo"
            v-validate="'required'"
          ></TextField>
        </v-flex>
        <v-flex xs12>
          <DatePicker
            v-model="model.datumOd"
            :label="$vuetify.t('Dátum od')"
            name="datumOd"
            v-validate="'required'"
          ></DatePicker>
        </v-flex>
        <v-flex xs12>
          <DatePicker
            v-model="model.datumDo"
            :label="$vuetify.t('Dátum do')"
            name="datumDo"
          ></DatePicker>
        </v-flex>
        <v-flex xs12>
          <v-select
            v-model="model.typDokladu"
            :label="$vuetify.t('Typ dokladu')"
            :items="typyDokladu"
            v-validate="'required'"
            data-vv-name="typDokladu"
            :data-vv-as="$vuetify.t('Typ dokladu')"
            :error-messages="errors.collect('typDokladu')"
            return-object
            item-value="ID"
            :item-text="typDokladuLabel"
          >
          </v-select>
        </v-flex>
        <v-flex xs12>
          <v-select
            v-model="model.typPoistenca"
            :label="$vuetify.t('Typ poistenca')"
            :items="typyPoistenca"
            v-validate="'required'"
            data-vv-name="typPoistenca"
            :item-text="typPoistencaLabel"
            :data-vv-as="$vuetify.t('Typ poistenca')"
            :error-messages="errors.collect('typPoistenca')"
          >
          </v-select>
        </v-flex>
        <v-flex xs12>
          <v-autocomplete
            v-model="model.stat"
            :label="$vuetify.t('Štát')"
            :items="staty"
            return-object
            :search-input.sync="statyFilter"
            no-filter
            item-text="DisplayName"
          >
          </v-autocomplete>
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>
<script>
import TextField from "./../TextField.vue"
import DatePicker from "./../DatePicker.vue"
import * as Miscella from "./../../miscella.js"

export default {
  $_veeValidate: {
    validator: "new",
  },
  components: {
    TextField,
    DatePicker,
  },
  watch: {
    statyFilter: function (newValue) {
      if (newValue !== null) {
        Miscella.myDelay(
          function () {
            this.fetchStaty()
          }.bind(this),
          400
        )
      }
    },
  },
  data: function () {
    return {
      loading: true,
      staty: [],
      typyDokladu: [],
      typyPoistenca: [],
      statyFilter: "",
      model: {},
    }
  },
  props: {
    patientId: String,
  },
  computed: {
    savePoistenieZahranicneUrl: function () {
      return "/api/patient/" + this.patientId + "/poistenieZahr"
    },
  },
  methods: {
    initForm: function () {
      this.fetchData()
      this.model = {
        id: "",
        identCislo: "",
        datumOd: null,
        datumDo: null,
        typPoistenca: null,
        typDokladu: null,
        stat: "",
      }
      this.statyFilter = ""
      this.$validator.reset()
    },
    fetchTypyDokladuPoistenca: async function () {
      this.typyDokladu = await this.$api.get("/api/typDokladuPoistenca", null)
    },
    fetchTypyPoistencaZahranicne: async function () {
      this.typyPoistenca = await this.$api.get("/api/typPoistencaZahr", null)
    },
    fetchStaty: async function () {
      let cisUrl = "/api/codebook/51"
      if (Miscella.isSet(this.statyFilter)) {
        cisUrl += "?filter=" + this.statyFilter
      }
      let data = await this.$api.get(cisUrl, null)
      this.staty = data.tableData
    },
    fetchData: function () {
      Promise.all([
        this.fetchTypyDokladuPoistenca(),
        this.fetchTypyPoistencaZahranicne(),
        this.fetchStaty(),
      ])
    },
    typPoistencaLabel: function (option) {
      console.log("typPoistencaLabel", option)
      return this.$vuetify.t("$vuetify.enum.typPoistencaZahranicny." + option)
    },
    typDokladuLabel: function (option) {
      if (Miscella.isSet(option)) {
        return this.$vuetify.t(
          "$vuetify.enum.typDokladuPoistenca." + option.Name
        )
      }
      return ""
    },
    newPoistenie: function () {
      this.initForm()
    },
    changeStat: function (newValue) {
      this.stat = newValue
    },
    editPoistenie: function (poistenie) {
      this.initForm()
      this.model.id = poistenie.ID
      this.model.identCislo = poistenie.IdentCislo
      this.model.datumOd = poistenie.DatumOd
      this.model.datumDo = poistenie.DatumDo
      this.model.typPoistenca = poistenie.TypPoistencaZahr
      this.model.typDokladu = poistenie.TypDokladuPoistenca
      this.model.stat = poistenie.Stat
    },
    saveForm: async function () {
      var valid = await this.$validator.validateAll()
      if (!valid) {
        return
      }
      this.save()
    },
    save: async function () {
      var params = {
        ID: this.model.id,
        IdentCislo: this.model.identCislo,
        DatumOd: this.model.datumOd,
        DatumDo: this.model.datumDo,
        TypPoistencaZahr: this.model.typPoistenca,
        TypDokladuPoistenca: this.model.typDokladu.ID,
        Stat: this.model.stat.ID,
      }

      try {
        await this.$api.post(this.savePoistenieZahranicneUrl, params)
        this.saveOK()
      } catch (e) {
        this.saveError(e)
      }
    },
    saveOK: function () {
      this.$emit("saved")
      this.$store.commit(
        "setSuccess",
        this.$vuetify.t("Zahraničné poistenie bolo úspešne uložené.")
      )
    },
    saveError: function () {
      this.$store.commit(
        "setError",
        "Pri ukladaní zahraničného poistenia vznikla chyba."
      )
    },
  },
}
</script>
