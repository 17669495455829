<template>
  <v-dialog
    v-model="dialog"
    :width="width"
    :fullscreen="fullscreen"
    @keydown.esc="cancel"
    @keydown.enter="save"
    persistent
    scrollable
    transition="fade-transition"
  >
    <!-- <v-card :height="height"> -->
    <v-card>
      <template v-if="showTitle">
        <v-system-bar window class="elevation-1">
          <v-card-title>
            <span class="subheading">{{ dialogTitle }}</span>
          </v-card-title>
          <v-spacer></v-spacer>

          <slot name="toolbar-actions"></slot>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <div v-on="on" @click="fullscreen = !fullscreen" class="clicker">
                <v-icon v-show="fullscreen === true">filter_none</v-icon>
                <v-icon v-show="fullscreen === false"
                  >check_box_outline_blank</v-icon
                >
              </div>
            </template>
            <span v-show="fullscreen === true">{{
              $vuetify.t("Obnoviť")
            }}</span>
            <span v-show="fullscreen === false">{{
              $vuetify.t("Maximalizovať")
            }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" @click="cancel">close</v-icon>
            </template>
            <span>{{ $vuetify.t("$vuetify.app.button.close") }}</span>
          </v-tooltip>
        </v-system-bar>
      </template>
      <v-card-text :style="fillHeightStyle">
        <slot :close="close"></slot>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions style="flex: 0 1 auto">
        <v-spacer></v-spacer>
        <slot name="buttons">
          <v-btn flat @click.native="cancel">
            {{ $vuetify.t("$vuetify.app.button.close") }}
          </v-btn>
          <slot name="otherButtons">
            <v-btn
              color="primary"
              flat
              @click="save"
              v-if="showSaveButton && !readonly"
              :loading="$store.state.loading.save"
              >{{ $vuetify.t(saveText) }}</v-btn
            >
          </slot>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as Miscella from "../miscella.js"

export default {
  name: "baseDialog",
  components: {},
  data: function () {
    return {
      forms: [],
      dialog: false,
      fullscreen: false,
      readonly: false,
    }
  },
  props: {
    dialogTitle: {
      type: String,
      required: true,
    },
    saveText: {
      type: String,
      default: "Uložiť",
    },
    showSaveButton: {
      type: Boolean,
      default: true,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    width: {
      type: String,
      default: "50%",
    },
    height: {
      type: String,
      default: "50%",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    fillHeight: {
      type: Boolean,
      default: false,
    },
    closeOnSave: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    fillHeightStyle: function () {
      let res = {}
      if (this.fillHeight) {
        res.display = "flex"
        res.flex = "1 1 auto"
      }
      return res
    },
  },
  watch: {
    readonly: function () {
      if (Miscella.isSet(this.forms)) {
        for (let f of this.forms) {
          f.readonly = this.readonly
        }
      }
    },
  },
  methods: {
    initForms: function () {
      let vnodes = this.$scopedSlots.default()
      for (let v of vnodes) {
        let c = v.componentInstance
        if (
          c &&
          Miscella.isSet(c.$options.extends) &&
          c.$options.extends.name === "BaseForm"
        ) {
          c.$on("activated", () => {
            this.open()
          })
          c.$on("saved", () => {
            if (this.closeOnSave) {
              this.close()
            }
          })
          this.forms.push(c)
        }
      }
    },
    save: async function () {
      console.log("BaseDialog SAVE")
      var calledSave = false
      if (Miscella.isSet(this.forms)) {
        // najprv validate
        var valid = true
        for (let f of this.forms) {
          if ((await f.validate()) === false) {
            valid = false
          }
        }
        if (!valid) {
          return
        }
        for (let f of this.forms) {
          await f.saveFunc(f)
          calledSave = true
          f.isDirty = false
        }
      }
      // TODO ochrana proti viacnasobnemu save formularov, ked sa zrusi externe volanie save na zaklade eventu, tak sa to moze dat prec
      if (!calledSave) {
        this.$emit("save")
      }
    },
    close: function () {
      if (this.dialog === true) {
        this.$emit("update:loading", false)
        this.$store.commit("stopLoading", "save")
        this.$emit("close")
        this.dialog = false
      }
    },
    open: function (readonly = false) {
      this.readonly = readonly
      this.dialog = true
      this.$nextTick(() => {
        for (let f of this.forms) {
          // radsej dva nextTicky, lebo napr. FormDiagnoza inicializuje model pri editacii v tiez v nextTicku kvoli zivot ohrozujucej diagnoze, potom toto nefunguje
          this.$nextTick(() => {
            f.isDirty = false
          })
        }
      })
    },
    cancel: async function () {
      var isDirty = false
      for (let f of this.forms) {
        if (f.isDirty) {
          isDirty = true
          break
        }
      }
      if (isDirty) {
        var doCancel = await this.$confirm(
          this.$vuetify.t(
            "Formulár obsahuje neuložené zmeny, skutočne zavrieť?"
          )
        )
        if (!doCancel) {
          return
        }
      }
      this.close()
    },
  },
  mounted: function () {
    this.initForms()
  },
}
</script>
