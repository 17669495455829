<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" flat icon @click="$emit('prev')">
          <v-icon dark> keyboard_arrow_left </v-icon>
        </v-btn>
      </template>
      <span>{{ $vuetify.t("Predchádzajúci") }}</span>
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" flat icon @click="$emit('next')">
          <v-icon dark> keyboard_arrow_right </v-icon>
        </v-btn>
      </template>
      <span>{{ $vuetify.t("Nasledujúci") }}</span>
    </v-tooltip>
    <template
      v-for="item in [
        { text: 'Mesiac', value: 'month' },
        { text: 'Týždeň', value: 'week' },
        { text: 'Deň', value: 'day' },
      ]"
    >
      <v-tooltip top :key="item.value">
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            flat
            @click="$emit('input', item.value)"
            :color="value === item.value ? 'primary' : ''"
          >
            {{ $vuetify.t(item.text) }}
          </v-btn>
        </template>
        <span>{{ $vuetify.t(item.text) }}</span>
      </v-tooltip>
    </template>
  </div>
</template>

<script>
export default {
  name: "calendarNavigation",
  props: {
    value: {
      type: String,
      required: true,
    },
  },
}
</script>
