var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-layout',{staticClass:"ockovania",attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"mb-3",attrs:{"xs12":""}},[(_vm.title)?_c('v-subheader',[_vm._v(_vm._s(_vm.title))]):_vm._e(),_c('v-list',{attrs:{"subheader":"","dense":""}},[_vm._l((_vm.items),function(item,index){return [_c('v-card',{key:'list' + index,staticClass:"mb-2"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-list-tile',{staticClass:"ockovanie-list-row",on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-list-tile-content',[_c('OckovanieRow',{attrs:{"item":item}})],1),_c('v-list-tile-action',{staticStyle:{"flex-direction":"row","justify-content":"flex-end","width":"66px"},attrs:{"d-flex":""}},[_c('v-tooltip',{directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],attrs:{"left":""}},[(_vm.isVykonane(item))?_c('v-icon',{attrs:{"slot":"activator"},on:{"click":function($event){$event.stopPropagation();return _vm.addReakciaNaOckovanie(item)}},slot:"activator"},[_vm._v("add")]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.$vuetify.t("Pridať reakciu na očkovanie")))])],1),_c('v-tooltip',{directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],attrs:{"left":""}},[_c('v-icon',{attrs:{"slot":"activator","disabled":!_vm.isEditable(item)},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(item)}},slot:"activator"},[_vm._v("delete")]),_c('span',[_vm._v(_vm._s(_vm.$vuetify.t("Odstrániť")))])],1),_c('v-menu',{directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],attrs:{"open-on-hover":"","bottom":"","offset-y":""}},[_c('v-icon',{attrs:{"slot":"activator"},slot:"activator"},[_vm._v("favorite_border")]),_c('v-card',[_c('v-list',{attrs:{"dense":""}},[_vm._l(([
                          {
                            title: 'Uložiť do ezdravia',
                            icon: 'cloud_upload',
                            handler: _vm.sendToEhealth,
                            disabled: !_vm.canSendToEhealth(item),
                          },
                          /*{
                          title: 'Storno v ezdraví',
                          icon: 'delete',
                          handler: deleteInEhealth,
                          disabled: !canDeleteInEhealth,
                        },*/
                        ]),function(menuitem){return [_c('v-list-tile',{key:menuitem.title,attrs:{"disabled":menuitem.disabled},on:{"click":function($event){return menuitem.handler(item)}}},[_c('v-list-tile-content',[_c('v-list-tile-title',[_vm._v(_vm._s(menuitem.title))])],1)],1)]})],2)],1)],1)],1)],1)]}}],null,true)}),(
              item.ockovanie.neziaduce_reakcie &&
              item.ockovanie.neziaduce_reakcie.length > 0
            )?_c('v-subheader',{key:'reakcia-title' + index,staticClass:"reakcia-list-row pt-1 pl-2"},[_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$vuetify.t("Nežiadúce reakcie")))])]):_vm._e(),_vm._l((item.ockovanie
              .neziaduce_reakcie),function(reakcia,reakciaIndex){return [_c('v-hover',{key:'reakcia-list-row' + index + reakciaIndex,scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-list-tile',{staticClass:"reakcia-list-row",on:{"click":function($event){return _vm.editReakciaNaOckovanie(reakcia)}}},[_c('v-list-tile-content',[_c('NeziaducaReakciaRow',{attrs:{"reakcia":reakcia}})],1),_c('v-list-tile-action',{staticStyle:{"flex-direction":"row","justify-content":"flex-end","width":"66px"},attrs:{"d-flex":""}},[_c('v-tooltip',{directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],attrs:{"left":""}},[_c('v-icon',{attrs:{"slot":"activator","disabled":!_vm.isEditable(reakcia)},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('deleteReakciaNaOckovanie', reakcia)}},slot:"activator"},[_vm._v("delete")]),_c('span',[_vm._v(_vm._s(_vm.$vuetify.t("Odstrániť")))])],1),_c('v-menu',{directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],attrs:{"open-on-hover":"","bottom":"","offset-y":""}},[_c('v-icon',{attrs:{"slot":"activator"},slot:"activator"},[_vm._v("favorite_border")]),_c('v-card',[_c('v-list',{attrs:{"dense":""}},[_vm._l(([
                            {
                              title: 'Uložiť do ezdravia',
                              icon: 'cloud_upload',
                              handler: _vm.sendToEhealth,
                              disabled: !_vm.canSendReakciaToEhealth(
                                item,
                                reakcia
                              ),
                            },
                            /*{
                          title: 'Storno v ezdraví',
                          icon: 'delete',
                          handler: deleteInEhealth,
                          disabled: !canDeleteInEhealth,
                        },*/
                          ]),function(menuitem){return [_c('v-list-tile',{key:menuitem.title,attrs:{"disabled":menuitem.disabled},on:{"click":function($event){return menuitem.handler(reakcia)}}},[_c('v-list-tile-content',[_c('v-list-tile-title',[_vm._v(_vm._s(menuitem.title))])],1)],1)]})],2)],1)],1)],1)],1)]}}],null,true)})]})],2)]})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }