<template>
  <BaseDialog
    :dialogTitle="$vuetify.t('Úprava používateľa')"
    ref="dialog"
    @save="save"
  >
    <v-container fluid>
      <v-form>
        <v-layout row wrap>
          <v-flex xs12>
            <v-text-field
              v-model="user.name"
              :label="$vuetify.t('Meno a priezvisko')"
              maxlength="100"
              required
              v-validate="'required|min:5'"
              :error-messages="errors.collect('name')"
              data-vv-name="name"
              :data-vv-as="$vuetify.t('Meno a priezvisko')"
            ></v-text-field>
            <v-text-field
              v-model="user.email"
              label="Email"
              maxlength="100"
              required
              v-validate="'required|email'"
              :error-messages="errors.collect('email')"
              data-vv-name="email"
              data-vv-as="Email"
            ></v-text-field>
            <v-text-field
              v-model="user.phone"
              :label="$vuetify.t('Telefónne číslo')"
              maxlength="50"
              required
              v-validate="'required|max:50'"
              :error-messages="errors.collect('phone')"
              data-vv-name="phone"
              data-vv-as="Telefónne číslo"
            ></v-text-field>
            <v-text-field
              :label="$vuetify.t('Kód zdravotníckeho pracovníka')"
              v-model="user.code"
              maxlength="20"
              v-validate="{
                regex: /^([A-GIN]{1}[0-9]{8})$/,
              }"
              :error-messages="errors.collect('code')"
              data-vv-name="code"
              :data-vv-as="$vuetify.t('Kód zdravotníckeho pracovníka')"
              clearable
            ></v-text-field>
            <v-text-field
              v-model="user.jruzId"
              :label="$vuetify.t('JRUZID')"
              maxlength="100"
            ></v-text-field>
          </v-flex>
        </v-layout>
      </v-form>
    </v-container>
  </BaseDialog>
</template>

<script>
import * as Miscella from "./../../miscella.js"
import BaseDialog from "./../BaseDialog.vue"

export default {
  inject: ["$validator"],
  components: {
    BaseDialog,
  },
  data: function () {
    return {
      user: {},
    }
  },
  props: {},
  computed: {},
  watch: {},
  methods: {
    dummy: function () {},
    show: function (user) {
      this.user = Miscella.cloneObject(user)
      this.$validator.reset()
      this.$refs.dialog.open()
    },
    add: function () {
      this.user = {}
      this.$validator.reset()
      this.$refs.dialog.open()
    },
    save: function () {
      this.$validator.validateAll().then(
        function () {
          let isOK = true
          if (this.errors.any() || !isOK) {
            event.stopPropagation()
          } else {
            this.$api
              .post("/api/user", this.user)
              .then(
                function () {
                  this.$store.commit(
                    "setSuccess",
                    this.$vuetify.t("Používateľ bol úspešne uložený.")
                  )
                  this.$emit("save")
                }.bind(this)
              )
              .catch(this.processError)
          }
        }.bind(this)
      )
    },
    fetchData: function () {},
  },
  mounted: function () {
    this.fetchData()
  },
}
</script>

<style scoped></style>
