<template>
  <v-card class="dashboard-panel ma-2 elevation-5">
    <v-toolbar flat dense card>
      <v-toolbar-title>{{ $vuetify.t("Vyšetrenia") }} </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="filter"
        class="dense mx-0"
        flat
        hide-details
        prepend-inner-icon="search"
        solo-inverted
        clearable
      >
      </v-text-field>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <span v-on="on" class="ml-3 font-weight-bold error--text">
            {{
              vysetrenia != null ? vysetrenia.pocet_neodoslanych_vysetreni : 0
            }}</span
          >
        </template>
        <span>{{
          $vuetify.t(
            "Počet neodoslaných vyšetrení do ezdravia za posledných 7 dní"
          )
        }}</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text>
      <!-- <v-card-text class="text-xs-right"> -->
      <!--   {{ -->
      <!--     $vuetify.t( -->
      <!--       "Počet neodoslaných vyšetrení do ezdravia za posledných 7 dní" -->
      <!--     ) -->
      <!--   }}: -->
      <!--   {{ vysetrenia != null ? vysetrenia.PocetNeodoslanychVysetreni : 0 }} -->
      <!-- </v-card-text> -->
      <template
        v-for="obdobie in ['dnes', 'vcera', 'tyzden', 'mesiac', 'starsie']"
      >
        <template
          v-if="
            vysetrenia !== null &&
            vysetrenia.mapa_vysetreni !== undefined &&
            vysetrenia.mapa_vysetreni[obdobie] !== undefined
          "
        >
          <v-toolbar flat dense card :dark="isThemeDark" :key="obdobie">
            {{ $vuetify.t("$vuetify.dashboard.vysetrenia.obdobie." + obdobie) }}
          </v-toolbar>
          <template
            v-for="(vysetrenie, index) in vysetrenia.mapa_vysetreni[obdobie]"
          >
            <v-card :key="'list' + obdobie + index" class="highlight-on-hover">
              <v-container
                fluid
                @click="clickedVysetrenie(vysetrenie)"
                class="px-3 py-2 clicker"
              >
                <v-layout row wrap>
                  <v-flex xs12 md6>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <span v-on="on" class="font-weight-light">
                          {{
                            vysetrenie.datum | formatDatumACas("D.M.YYYY HH:mm")
                          }}</span
                        >
                      </template>
                      <span>{{ $vuetify.t("Dátum vyšetrenia") }}</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <span v-on="on" class="font-weight-medium">
                          {{ vysetrenie.meno_pacienta }}</span
                        >
                      </template>
                      <span>{{ $vuetify.t("Pacient") }}</span>
                    </v-tooltip>
                  </v-flex>
                  <v-flex xs12 md6 class="text-md-right">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <span
                          v-on="on"
                          class="font-weight-regular"
                          :class="{
                            'error--text':
                              vysetrenie.stav_odoslania !== 'SUCCESS',
                            'success--text':
                              vysetrenie.stav_odoslania === 'SUCCESS',
                          }"
                        >
                          {{
                            $vuetify.t(
                              "$vuetify.enum.evysetrenie.stav." +
                                vysetrenie.stav_odoslania
                            )
                          }}</span
                        >
                      </template>
                      <span>{{ $vuetify.t("Stav odoslania") }}</span>
                    </v-tooltip>
                  </v-flex>
                  <v-flex
                    xs12
                    v-if="vysetrenie.hlavna_diagnoza_skratka !== ''"
                    class="mb-2"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <span v-on="on" class="font-weight-medium">
                          {{ vysetrenie.hlavna_diagnoza_skratka }}
                        </span>
                        <span v-on="on" class="font-weight-light">
                          {{ vysetrenie.hlavna_diagnoza_display_name }}
                        </span>
                      </template>
                      <span>{{ $vuetify.t("Hlavná diagnóza") }}</span>
                    </v-tooltip>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card>
            <v-divider
              :key="'divider' + obdobie + index"
              v-if="index !== vysetrenia.mapa_vysetreni[obdobie].length - 1"
            ></v-divider>
          </template>
        </template>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import * as Miscella from "./../../miscella.js"
import { mapGetters } from "vuex"

export default {
  components: {},
  data() {
    return {
      filter: "",
      vysetrenia: null,
    }
  },
  computed: {
    ...mapGetters(["isThemeDark"]),
    ambulanceId: function () {
      let id = null
      if (Miscella.isSet(this.$store.state.me.ambulance)) {
        id = this.$store.state.me.ambulance.id
      }
      return id
    },
  },
  watch: {
    filter: function () {
      Miscella.myDelay(this.fetchData, 1000)
    },
    ambulanceId: function () {
      this.fetchData()
    },
  },
  methods: {
    fetchData: async function () {
      let params = {
        limit: 30,
      }
      if (!Miscella.isEmpty(this.filter)) {
        params.filter = this.filter
      }
      this.vysetrenia = await this.$api.get("/api/ambulance/vysetrenia", params)
    },
    clickedVysetrenie: function (item) {
      this.$router.push({
        name: "vysetrenie",
        params: { id: item.pacient_id, vysetrenieID: item.id },
      })
    },
  },
  mounted: function () {
    this.fetchData()
  },
}
</script>
