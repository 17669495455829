<template>
  <v-content app>
    <v-container>
      <v-layout justify-center>
        <v-card class="dashboard-panel ma-2 elevation-5" width="80%">
          <v-toolbar flat dense card>
            <v-toolbar-title
              >{{ $vuetify.t("Výber aktuálneho PZS a ambulancie") }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-container class="pb-0 grey--text">
            {{
              $vuetify.t(
                "Kliknutím si zvoľte ambulanciu, v ktorej chcete pracovať. Aktuálne zvolený PZS a ambulancia sú zvýraznené."
              )
            }}
          </v-container>
          <v-container
            v-for="clinic of clinics"
            :key="clinic.id"
            fluid
            grid-list-md
          >
            <v-layout row wrap>
              <v-flex xs12>
                <h5
                  class="headline"
                  :class="{ 'primary--text': isCurrentClinic(clinic) }"
                  v-text="clinic.name"
                ></h5>
              </v-flex>

              <template
                v-if="clinic.ambulances && clinic.ambulances.length > 0"
              >
                <v-flex
                  xs12
                  sm6
                  md4
                  lg3
                  v-for="ambulance of clinic.ambulances"
                  :key="ambulance.id"
                >
                  <v-hover>
                    <v-card
                      slot-scope="{ hover }"
                      :class="{
                        'elevation-12': hover,
                        'elevation-4': !hover,
                        'primary--text': isCurrent(clinic, ambulance),
                        clicker: hover,
                      }"
                      @click="
                        save(clinic, ambulance)
                        $router.push('/home')
                      "
                    >
                      <v-card-title class="pa-4">
                        <!-- <div class="caption grey--text pb-2"> -->
                        <!--   {{ $vuetify.t("Ambulancia") }} -->
                        <!-- </div> -->
                        <div>
                          <h1 class="headline">{{ ambulance.name }}</h1>
                          <span class="grey--text">{{
                            ambulance.zameranie
                          }}</span>
                        </div>
                      </v-card-title>
                    </v-card>
                  </v-hover>
                </v-flex>
              </template>
              <v-flex v-else>
                {{
                  $vuetify.t("Nie ste členom žiadnej ambulancie v tomto PZS.")
                }}
                <div v-if="clinic.user_role === 'USER_ROLE_ADMIN'">
                  {{
                    $vuetify.t(
                      "Ako administrátor tohto PZS máte možnosť v menu"
                    )
                  }}
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        small
                        color="primary"
                        @click="
                          save(clinic)
                          $router.push('/admin/ambulances')
                        "
                        >{{ $vuetify.t("Administrácia") }}</v-btn
                      >
                    </template>
                    <span
                      >{{
                        $vuetify.t(
                          "Kliknutím zvolíte PZS a prejdete do administrácie ambulancií"
                        )
                      }}
                    </span>
                  </v-tooltip>
                  {{
                    $vuetify.t(
                      "vytvárať a meniť ambulancie a taktiež priraďovať používateľov do jednotlivých ambulancií."
                    )
                  }}
                </div>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
import { mapState, mapGetters } from "vuex"

export default {
  name: "DashboardUser",
  components: {},
  data: function () {
    return {
      clinics: [],
    }
  },

  computed: {
    ...mapState({
      clinicId: (state) => state.me?.clinic?.id,
      ambulanceId: (state) => state.me?.ambulance?.id,
    }),
    ...mapGetters(["isThemeDark"]),
  },
  mounted: function () {
    this.fetchData()
    console.log("DashboardUser mounted")
  },

  methods: {
    save: function (myChosenClinic, myChosenAmbulance) {
      this.$store.commit("setClinic", myChosenClinic)
      this.$store.commit("setAmbulance", myChosenAmbulance)
      this.$store.dispatch("reloadMe", this.$api)
    },
    fetchData: async function () {
      let meInfo = await this.$api.userService.me()
      this.clinics = meInfo.clinics
    },
    isCurrentClinic: function (clinic) {
      return clinic.id === this.clinicId
    },
    isCurrent: function (clinic, ambulance) {
      return this.isCurrentClinic(clinic) && ambulance.id === this.ambulanceId
    },
  },
  mounted: function () {
    this.fetchData()
  },
}
</script>

<style scoped></style>
