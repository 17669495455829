<template>
  <v-app id="inspire" :dark="isThemeDark" :light="!isThemeDark">
    <message-bar></message-bar>
    <v-content>
      <v-container fluid fill-height>
        <router-view></router-view>
      </v-container>
    </v-content>
    <v-footer app class="pa-3">
      <v-layout row>
        <v-flex xs2>
          <span>
            {{ $vuetify.t("Ordinácia") }} &copy;
            {{ new Date().getFullYear() }},&nbsp;verzia 1.0
          </span>
        </v-flex>
        <v-flex xs2>
          <v-tooltip top>
            <span slot="activator">{{ version }}</span>
            <span>{{ $vuetify.t("Verzia") }}</span>
          </v-tooltip>
        </v-flex>
      </v-layout>
    </v-footer>
  </v-app>
</template>

<script>
import MessageBar from "./../components/MessageBar.vue"
import { mapState, mapGetters } from "vuex"

export default {
  components: {
    MessageBar,
  },
  data: function () {
    return {
      drawer: null,
    }
  },
  computed: {
    ...mapState(["version"]),
    ...mapGetters(["isThemeDark"]),
  },
}
</script>
