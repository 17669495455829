export const Poistovna = {
  VSZP: "VSZP",
  DOVERA: "DOVERA",
  UNION: "UNION",
}

export const CodebookIDDiagnoza = 25
export const CodebookIDVykon = 26
export const CodebookIDOdbornost = 34
export const CodebookIDDruhOckovania = 35
export const CodebookIDTypOckovania = 36
export const CodebookIDDruhOdbornejCinnosti = 37
export const CodebookIDOdborneZameranie = 39
export const CodebookIDCity = 45
export const CodebookIDPsc = 150
export const CodebookIDCounty = 51
export const CodebookIDPribuzenskyStav = 54
export const CodebookIDDruhyNeziaducichReakcii = 58
export const CodebookIDKrvnaSkupina = 66
export const CodebookIDATC = 83
export const CodebookIDMerneJednotky = 87
export const CodebookIDDovodNevykonaniaOckovania = 116
export const CodebookIDAlergen = 158
export const CodebookIDPrejavyNeziaducichReakcii = 159
export const CodebookIDUrgentnostVysetrenia = 123
export const CodebookIDDruhZdravotnickychPomocok = 126
export const CodebookIDModalitaZobrazovaciehoVysetrenia = 256
export const CodebookIDDovodPN = 265

export const CodebookIDLiek = 100001
export const CodebookIDZdravotnickaPomocka = 100002
export const CodebookIDZdravotnickyPracovnikKod = 100003
export const CodebookIDOUPZS = 100004
export const CodebookIDZdravotnaPoistovna = 100005

export function getPoistovnaKod(key) {
  let ret = ""
  switch (key) {
    case Poistovna.VSZP:
      ret = "2400"
      break
    case Poistovna.DOVERA:
      ret = "2500"
      break
    case Poistovna.UNION:
      ret = "2700"
      break
    default:
      break
  }
  return ret
}

export const TypEventu = {
  TypEventuKalendar: "KALENDAR",
  TypEventuKontrola: "KONTROLA",
  TypEventuObjednanie: "OBJEDNANIE",
  TypEventuObjednanieOrders: "OBJEDNANIE_ORDERS",
}

export const StavOckovania = {
  Planovane: "PLANOVANE",
  Vykonane: "VYKONANE",
  Zrusene: "ZRUSENE",
}

export const EhealthRecordStatus = {
  NA: 0,
  Waiting: 1,
  Sending: 2,
  Error: 3,
  Success: 4,
}

export const EhrVersionStatus = {
  NA: 0,
  Draft: 1,
  Final: 2,
  Deleted: 3,
}

export const CSTypDavkovania = {
  CSTypDavkovaniaPEV: "PEV",
  CSTypDavkovaniaVAR: "VAR",
  CSTypDavkovaniaAMM: "AMM",
  CSTypDavkovaniaDOP: "DOP",
  CSTypDavkovaniaROZ: "ROZ",
  CSTypDavkovaniaRAD: "RAD",
}

export const CSPeriodaDavkovania = {
  CSPeriodaDavkovaniaD: "D",
  CSPeriodaDavkovaniaD2: "D2",
  CSPeriodaDavkovaniaOB: "OB",
  CSPeriodaDavkovania3D: "3D",
  CSPeriodaDavkovania1T: "1T",
  CSPeriodaDavkovania2T: "2T",
  CSPeriodaDavkovania3T: "3T",
  CSPeriodaDavkovaniaM: "M",
  CSPeriodaDavkovaniaJ: "J",
  CSPeriodaDavkovaniaP: "P",
}

export const CSJednotkaDavkovania = {
  CSJednotkaDavkovaniaNULL: "(NULL)",
  CSJednotkaDavkovaniaAMP: "AMP",
  CSJednotkaDavkovaniaAPL: "APL",
  CSJednotkaDavkovaniaCAP: "CAP",
  CSJednotkaDavkovaniaDIE: "DIE",
  CSJednotkaDavkovaniaDRG: "DRG",
  CSJednotkaDavkovaniaGLOB: "GLOB",
  CSJednotkaDavkovaniaINF: "INF",
  CSJednotkaDavkovaniaINH: "INH",
  CSJednotkaDavkovaniaINJE: "INJE",
  CSJednotkaDavkovaniaJED: "JED",
  CSJednotkaDavkovaniaKAP: "KAP",
  CSJednotkaDavkovaniaKAV: "KAV",
  CSJednotkaDavkovaniaKVA: "KVA",
  CSJednotkaDavkovaniaLYZ: "LYZ",
  CSJednotkaDavkovaniaMG: "MG",
  CSJednotkaDavkovaniaML: "ML",
  CSJednotkaDavkovaniaNAP: "NAP",
  CSJednotkaDavkovaniaOBV: "OBV",
  CSJednotkaDavkovaniaODM: "ODM",
  CSJednotkaDavkovaniaSTR: "STR",
  CSJednotkaDavkovaniaTAB: "TAB",
  CSJednotkaDavkovaniaVDY: "VDY",
  CSJednotkaDavkovaniaVRE: "VRE",
}

export const IPLCenoveKategorie = [
  { id: "M01000", name: "do 1,00 EUR vrátane" },
  { id: "M01001", name: "od 1,01 do 2,00 EUR vrátane" },
  { id: "M01002", name: "od 2,01 do 4,00 EUR vrátane" },
  { id: "M01003", name: "od 4,01 do 10,00 EUR vrátane" },
  { id: "M01004", name: "od 10,01 do 20,00 EUR vrátane" },
  { id: "M01005", name: "viac ako 20,01 EUR" },
]

export const ATCReRxOPL = [
  "A04AD10",
  "A07DA01",
  "A07DA02",
  "A07DA04",
  "A07DA52",
  "A08AA01",
  "A08AA03",
  "A08AA05",
  "A08AA06",
  "A08AA07",
  "A08AA09",
  "M03BX07",
  "N01AH01",
  "N01AH02",
  "N01AH03",
  "N01AH04",
  "N01AH05",
  "N01AH06",
  "N01AH51",
  "N01AX03",
  "N01BC01",
  "N02AA01",
  "N02AA02",
  "N02AA03",
  "N02AA04",
  "N02AA05",
  "N02AA08",
  "N02AA10",
  "N02AA51",
  "N02AA53",
  "N02AA55",
  "N02AA56",
  "N02AA58",
  "N02AA59",
  "N02AA79",
  "N02AB01",
  "N02AB02",
  "N02AB03",
  "N02AB52",
  "N02AB72",
  "N02AC01",
  "N02AC02",
  "N02AC03",
  "N02AC04",
  "N02AC05",
  "N02AC52",
  "N02AC54",
  "N02AC74",
  "N02AD01",
  "N02AD02",
  "N02AE01",
  "N02AG01",
  "N02AG03",
  "N02AG04",
  "N02AJ01",
  "N02AJ02",
  "N02AJ03",
  "N02AJ06",
  "N02AJ07",
  "N02AJ08",
  "N02AJ09",
  "N02AJ17",
  "N02AJ18",
  "N02AJ19",
  "N02AX01",
  "N02AX06",
  "N03AA01",
  "N03AA02",
  "N03AE01",
  "N05BA01",
  "N05BA02",
  "N05BA03",
  "N05BA04",
  "N05BA05",
  "N05BA06",
  "N05BA07",
  "N05BA08",
  "N05BA09",
  "N05BA10",
  "N05BA11",
  "N05BA12",
  "N05BA13",
  "N05BA14",
  "N05BA15",
  "N05BA16",
  "N05BA17",
  "N05BA18",
  "N05BA19",
  "N05BA21",
  "N05BA22",
  "N05BA23",
  "N05BA24",
  "N05BA56",
  "N05BC01",
  "N05BC51",
  "N05CA01",
  "N05CA02",
  "N05CA03",
  "N05CA04",
  "N05CA05",
  "N05CA06",
  "N05CA07",
  "N05CA08",
  "N05CA09",
  "N05CA10",
  "N05CA11",
  "N05CA12",
  "N05CA15",
  "N05CA16",
  "N05CA19",
  "N05CA20",
  "N05CA21",
  "N05CA22",
  "N05CB01",
  "N05CB02",
  "N05CD01",
  "N05CD02",
  "N05CD03",
  "N05CD04",
  "N05CD05",
  "N05CD06",
  "N05CD07",
  "N05CD08",
  "N05CD09",
  "N05CD10",
  "N05CD11",
  "N05CD12",
  "N05CD13",
  "N05CE01",
  "N05CE02",
  "N05CE03",
  "N05CF02",
  "N05CG01",
  "N05CM01",
  "N05CM14",
  "N05CX01",
  "N05CX02",
  "N06AA19",
  "N06BA01",
  "N06BA02",
  "N06BA03",
  "N06BA04",
  "N06BA05",
  "N06BA06",
  "N06BA10",
  "N06BB01",
  "N06BB02",
  "N06BB03",
  "N06BD01",
  "N06BX15",
  "N07BC01",
  "N07BC02",
  "N07BC03",
  "N07BC05",
  "N07BC51",
  "R02AD03",
  "R05DA01",
  "R05DA03",
  "R05DA04",
  "R05DA06",
  "R05DA08",
  "R05DA10",
  "R05DA12",
  "R05DB15",
  "S01HA01",
  "S01XA06",
  "S02DA02",
]
export const ATCReRxATB = [
  "A02BD",
  "A02BD",
  "A07A",
  "A07A",
  "D01AA01",
  "D06A",
  "D06A",
  "D06B",
  "D06B",
  "D06C",
  "D07C",
  "D10AF",
  "D10AF",
  "G01AA",
  "G01AA",
  "G01AE",
  "G01BA",
  "G01BE",
  "J01A",
  "J01A",
  "J01B",
  "J01C",
  "J01C",
  "J01D",
  "J01D",
  "J01E",
  "J01E",
  "J01F",
  "J01F",
  "J01G",
  "J01G",
  "J01M",
  "J01M",
  "J01R",
  "J01X",
  "J01X",
  "J04AB",
  "J04AB",
  "J04BA",
  "J04BA",
  "R02AB",
  "S01AA",
  "S01AA",
  "S01AB",
  "S01AB",
  "S01AE",
  "S01AX",
  "S01AX",
  "S01CA",
  "S02AA",
  "S02CA",
  "S03AA",
]

export const nahrada = {
  nahrada01: "01",
  nahrada02: "02",
  nahrada03: "03",
  nahrada04: "04",
  nahrada05: "05",
  nahrada06: "06",
  nahrada07: "07",
  nahrada08: "08",
}

export const pohybPoistenca = {
  pohybA: "A",
  pohybU: "U",
  pohybO: "0",
  pohybI: "I",
  pohybS: "S",
  pohybZ: "Z",
  pohybX: "X",
}

export const eDPNFilterItems = {
  Ukoncene: "U",
  Stornovane: "S",
  Vsetky: "V",
}

export function getName(cs, key) {
  //console.log("Enums, getName()", cs, key)
  for (let item of cs) {
    if (item.id === key) {
      return item.name
    }
  }
  return ""
}
