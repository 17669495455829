<template>
  <v-card class="pa-0 mb-0 elevation-0 clicker" v-if="file" @click="open">
    <v-card-text class="pa-0">
      <v-list dense two-line class="pa-0">
        <v-list-tile>
          <v-list-tile-action>
            <v-icon>attachment</v-icon>
          </v-list-tile-action>

          <v-list-tile-content>
            <v-list-tile-title>{{ file.name }}</v-list-tile-title>
            <v-list-tile-sub-title>{{
              file.content_type
            }}</v-list-tile-sub-title>
          </v-list-tile-content>

          <v-list-tile-action>
            <v-tooltip top v-if="!isUpload">
              <template v-slot:activator="{ on }">
                <v-btn
                  small
                  flat
                  icon
                  v-on="on"
                  @click.stop="
                    downloadSubor(
                      file.name,
                      $api.storageService.storageUrl(file.id)
                    )
                  "
                  ><v-icon small>download</v-icon></v-btn
                >
              </template>
              <span>{{ $vuetify.t("Download") }}</span>
            </v-tooltip>
            <v-tooltip top v-if="isUpload">
              <template v-slot:activator="{ on }">
                <v-btn small flat icon v-on="on" @click="$emit('remove')"
                  ><v-icon small>cancel</v-icon></v-btn
                >
              </template>
              <span>{{ $vuetify.t("Zrušiť") }}</span>
            </v-tooltip>
          </v-list-tile-action>
        </v-list-tile>
      </v-list>
      <v-img :src="fileUrl" v-if="fileUrl && isImage" />
    </v-card-text>
  </v-card>
</template>

<script>
import * as subory from "@/components/mixins/subory.js"

export default {
  name: "Attachment",
  mixins: [subory.mixins],
  props: {
    value: {
      required: true,
    },
  },
  data: function () {
    return {
      fileUrl: "",
      isUpload: false,
    }
  },
  computed: {
    file: function () {
      return this.value
    },
    isImage: function () {
      return (
        this.file &&
        this.file.content_type &&
        this.file.content_type.split("/")[0] === "image"
      )
    },
  },
  methods: {
    loadImage: async function () {
      if (this.file && !this.fileUrl) {
        var blob = await this.$api.storageService.storageGet(
          this.$api.storageService.storageUrl(this.file.id)
        )
        this.fileUrl = window.URL.createObjectURL(blob)
      }
    },
    open: function () {
      if (this.file) {
        if (this.file.contents) {
          this.openBlob(
            this.base64toBlob(this.file.contents, this.file.content_type)
          )
        } else if (this.file.id) {
          this.openWindow(this.$api.storageService.storageUrl(this.file.id))
        }
      }
    },
    upload: function (file) {
      if (file) {
        console.log("FILE:", file.name, file.size, file.type)
        const fr = new FileReader()
        fr.readAsDataURL(file)
        fr.addEventListener("load", () => {
          this.isUpload = true
          this.fileUrl = fr.result
          var value = {
            id: this.ulid(),
            name: file.name,
            size: file.size,
            content_type: file.type,
          }
          var base64 = this.fileUrl.toString().replace(/^data:(.*,)?/, "")
          // pad base64 correctly
          if (base64.length % 4 > 0) {
            base64 += "=".repeat(4 - (base64.length % 4))
          }
          value.contents = base64
          this.$emit("input", value)
        })
      } else {
        this.isUpload = false
        this.fileUrl = ""
        this.$emit("input", "")
      }
    },
  },
  mounted: function () {
    this.loadImage()
  },
}
</script>
