export default class StorageService {
  constructor(api) {
    this.api = api
  }

  storageUrl(filePath) {
    return "/v1/storage/" + filePath
  }

  storageTmpUrl(filePath) {
    return "/v1/storage-tmp/" + filePath
  }

  storageGet(url) {
    return this.api.getBinary(url)
  }

  storagePost(url, binary) {
    return this.api.postBinary(url, binary)
  }
}
