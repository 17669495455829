<template>
  <v-container fluid px-0>
    <v-layout row>
      <v-flex xs1>
        <v-tooltip
          top
          v-if="item.ockovanie.stav === enums.StavOckovania.Vykonane"
        >
          <span slot="activator">{{
            item.ockovanie.datum_vykonane | formatDatum
          }}</span>
          <span>{{ $vuetify.t("Dátum očkovania") }}</span>
        </v-tooltip>
        <v-tooltip
          top
          v-if="item.ockovanie.stav === enums.StavOckovania.Planovane"
        >
          <span slot="activator">{{
            item.ockovanie.datum_plan | formatDatum
          }}</span>
          <span>{{ $vuetify.t("Dátum plánovaného očkovania") }}</span>
        </v-tooltip>
        <v-tooltip
          top
          v-if="item.ockovanie.stav === enums.StavOckovania.Zrusene"
        >
          <span slot="activator">{{
            item.ockovanie.datum_plan | formatDatum
          }}</span>
          <span>{{ $vuetify.t("Dátum plánovaného očkovania") }}</span>
        </v-tooltip>
      </v-flex>
      <v-flex xs2>
        <template v-for="(d, index) in item.ockovanie.druhy_ockovania">
          <v-tooltip top :key="'druh-ockovania' + index">
            <span slot="activator">
              {{
                d.display_name +
                (index !== item.ockovanie.druhy_ockovania.length - 1 ? "," : "")
              }}
            </span>
            <span>{{ $vuetify.t("Druh očkovania") }}</span>
          </v-tooltip>
        </template>
      </v-flex>
      <v-flex xs7>
        <v-tooltip top>
          <span slot="activator">{{
            item.ockovanie.typ_ockovania != null
              ? item.ockovanie.typ_ockovania.display_name
              : ""
          }}</span>
          <span>{{ $vuetify.t("Typ očkovania") }}</span>
        </v-tooltip>
      </v-flex>
      <v-flex xs2>
        <EhealthRecord
          v-model="item.ehealth_record"
          :objectID="item.id"
          :statusTextPrefix="`$vuetify.enum.ehealthRecord.versionStatus.${
            item.ehealth_record ? item.ehealth_record.version_status : ''
          }`"
          table
          compact
        ></EhealthRecord>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import * as enums from "@/plugins/enums.js"
import EhealthRecord from "@/components/EhealthRecord.vue"

export default {
  name: "OckovanieRow",
  components: { EhealthRecord },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      enums: enums,
    }
  },
}
</script>
