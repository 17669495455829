<template>
  <v-btn
    :id="id"
    ref="eventBtn"
    block
    small
    @click="$emit('click', $event)"
    :color="getColorOfEventDay(event.Typ, active)"
    class="event mt-0 mb-1 elevation-0"
    :class="{ 'event-active': active, 'elevation-6': active }"
    :depressed="!active"
    :style="styles"
  >
    <span
      class="caption text-lowercase text-truncate"
      style="line-height: inherit !important"
    >
      <span v-if="!isKontrola(event)">{{ event.CasOd }}</span>
      <b v-if="isObjednanie(event) || isKontrola(event)">
        {{ event.PatientName }}</b
      >
      {{ event.Desc }}
    </span>
    <v-tooltip top v-if="!isObjednanieOrders(event)">
      <v-icon
        slot="activator"
        small
        @click.stop="$emit('click:remove', event.ID)"
        >close</v-icon
      >
      <span>{{ $vuetify.t("Odstrániť") }}</span>
    </v-tooltip>
  </v-btn>
</template>

<script>
import calendarMixin from "@/components/mixins/calendar.js"

export default {
  name: "calendar-event",
  mixins: [calendarMixin],
  props: {
    event: {
      type: Object,
      required: true,
    },
    height: {
      type: String,
      required: false,
      default: "24px",
    },
    position: {
      type: String,
      required: false,
    },
    active: {
      type: Boolean,
      required: false,
    },
    // HTML ID
    id: {
      type: String,
      required: false,
    },
  },
  data: function () {
    return {}
  },
  computed: {
    styles: function () {
      var styles = {
        height: this.height,
      }
      if (this.position) {
        styles.position = "absolute"
        styles.top = this.position
      }
      if (this.active) {
        styles["z-index"] = 1
      }
      return styles
    },
  },
}
</script>

<style lang="stylus">
button.event
  flex-direction: column
  justify-content: flex-start
  align-items: stretch
  min-height: 24px
  padding: 4px 2px

  .v-btn__content
    color: black
    flex-direction: row
    justify-content: space-between
    align-items: flex-start

  &.event-active
    .v-btn__content
      color: white
</style>
