<template>
  <v-layout row wrap>
    <v-flex xs12 md4>
      <v-text-field
        :label="$vuetify.t('$vuetify.form.param.minlength')"
        type="number"
        v-model="params.minlength"
      ></v-text-field>
    </v-flex>
    <v-flex xs12 md4>
      <v-text-field
        :label="$vuetify.t('$vuetify.form.param.maxlength')"
        type="number"
        v-model="params.maxlength"
      ></v-text-field>
    </v-flex>
    <v-flex xs12>
      <v-text-field
        :label="$vuetify.t('$vuetify.form.param.mask')"
        v-model="params.mask"
      ></v-text-field>
    </v-flex>
    <v-flex xs12>
      <v-checkbox label="Email" v-model="params.email"></v-checkbox>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  components: {},
  data: function () {
    return {}
  },
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
  methods: {
    defaultParams: function () {
      return {
        minlength: 0,
        maxlength: 250,
        mask: "",
        email: false,
      }
    },
  },
}
</script>
