<template>
  <v-layout align-center justify-center>
    <v-flex xs12 sm8 md4>
      <v-card class="elevation-12">
        <v-toolbar flat dense card>
          <v-toolbar-title>{{ $vuetify.t("Obnova hesla") }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <div v-if="sent">
            <div v-if="success" class="text-md-left px-4">
              <h3 class="headline">
                {{ $vuetify.t("Vaše heslo bolo úspešne zmenené") }}
              </h3>
              <p>
                {{
                  $vuetify.t("Prosím, pokračujte prihlásením sa do aplikácie")
                }}
              </p>
            </div>
            <v-alert v-else type="error" :value="true">
              {{ $vuetify.t("Nastala chyba! Vaše heslo nebolo zmenené.") }}
            </v-alert>
          </div>
          <div v-else>
            <v-container grid-list-md class="pa-0">
              <v-layout row wrap>
                <v-flex xs12 md6>
                  <text-field-password
                    v-model="form.password"
                    name="form.password"
                    v-validate="'required|min:12|max:255'"
                    :error-messages="errors.collect('password1')"
                    data-vv-name="password1"
                    :data-vv-as="$vuetify.t('heslo')"
                    :label="$vuetify.t('Nové heslo')"
                    :hint="$vuetify.t('Heslo musí mať aspoň 12 znakov')"
                    ref="password1"
                    counter="255"
                    autocomplete="new-password"
                    :keyUpEnter="send"
                  ></text-field-password>
                </v-flex>
                <v-flex xs12 md6>
                  <text-field-password
                    v-model="form.password2"
                    name="form.password2"
                    v-validate="'required|confirmed:password1'"
                    :error-messages="errors.collect('password2')"
                    data-vv-name="password2"
                    :data-vv-as="$vuetify.t('heslo')"
                    :label="$vuetify.t('Overenie hesla')"
                    :hint="$vuetify.t('Pre overenie zadajte heslo ešte raz')"
                    counter
                    autocomplete="new-password"
                    :keyUpEnter="send"
                  ></text-field-password>
                </v-flex>
              </v-layout>
            </v-container>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn flat @click="goToLogin">{{
            $vuetify.t("Späť na prihlásenie")
          }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn flat v-if="!sent" @click="send" color="primary">{{
            $vuetify.t("Odoslať")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import textFieldPassword from "@/components/TextFieldPassword.vue"

export default {
  name: "PasswordChange",
  $_veeValidate: {
    validator: "new",
  },
  components: { textFieldPassword },
  props: {
    token: {
      type: String,
      required: false,
    },
  },
  data: function () {
    return {
      sent: false,
      success: false,
      form: {
        password: "",
        password2: "",
      },
    }
  },
  methods: {
    send: async function () {
      var valid = await this.$validator.validateAll()
      if (!valid) {
        return
      }
      try {
        await this.$api.post("/v1/proto.Registration/PasswordChange", {
          token: this.token,
          password: this.form.password,
        })
        this.success = true
      } catch (err) {
      } finally {
        this.sent = true
      }
    },
    goToLogin: function () {
      this.$router.replace({ name: "login" })
    },
  },
  mounted: function () {
    this.$refs.password1.focus()
  },
}
</script>
