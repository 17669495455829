<template>
  <v-layout row wrap>
    <v-flex xs12 md4>
      <v-text-field
        :label="$vuetify.t('$vuetify.form.param.min')"
        type="number"
        v-model="params.min"
      ></v-text-field>
    </v-flex>
    <v-flex xs12 md4>
      <v-text-field
        :label="$vuetify.t('$vuetify.form.param.max')"
        type="number"
        v-model="params.max"
      ></v-text-field>
    </v-flex>
    <v-flex xs12 md4>
      <v-text-field
        :label="$vuetify.t('$vuetify.form.param.float')"
        type="number"
        v-model="params.float"
      ></v-text-field>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  components: {},
  data: function () {
    return {}
  },
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
  methods: {
    defaultParams: function () {
      return {
        min: 0,
        max: null,
        float: null,
      }
    },
  },
}
</script>
