<template>
  <v-form>
    <v-container fluid>
      <v-layout row wrap>
        <v-flex xs12>
          <DatePicker
            v-model="model.datum"
            :label="$vuetify.t('Dátum dohody od')"
            name="datum"
            v-validate="'required'"
          ></DatePicker>
        </v-flex>
        <v-flex xs12>
          <DatePicker
            v-model="model.datumDo"
            :label="$vuetify.t('Dátum dohody do')"
            name="datumDo"
          ></DatePicker>
        </v-flex>
        <v-flex xs12>
          <v-textarea
            v-model="model.poznamka"
            label="Poznámka"
            name="poznamka"
            auto-grow
          ></v-textarea>
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>
<script>
//import * as Miscella from "../../miscella.js"
//import moment from "moment"
import DatePicker from "./../DatePicker.vue"

export default {
  $_veeValidate: {
    validator: "new",
  },
  components: {
    DatePicker,
  },
  data: function () {
    return {
      loading: true,

      model: {
        id: "",
        datum: "",
        datumDo: "",
        prirastok: false,
        poznamka: "",
      },
      ambulanciaKod: "",
    }
  },
  props: {
    patientId: String,
  },
  computed: {
    saveKapitaciaUrl: function () {
      return "/api/patient/" + this.patientId + "/kapitacia"
    },
  },

  methods: {
    newKapitacia: function () {
      this.initForm()
    },
    editKapitacia: function (kapitacia) {
      this.initForm()
      this.model.id = kapitacia.ID
      this.model.datum = kapitacia.DatumDohody
      this.model.datumDo =
        kapitacia.DatumDohodyDo !== "0001-01-01T00:00:00Z"
          ? kapitacia.DatumDohodyDo
          : null
      this.model.poznamka = kapitacia.Poznamka
      console.log("Ambulance : ", kapitacia.Ambulancia)
      this.model.ambulanciaKod = kapitacia.Ambulancia.Code
    },
    initForm: function () {
      this.$validator.reset()
      this.getAmbulanciaKod()
      this.model.id = ""
      this.model.datum = null
      this.model.datumDo = null
      this.model.poznamka = ""
    },

    saveForm: async function () {
      var valid = await this.$validator.validateAll()
      if (!valid) {
        return
      }
      this.save()
    },
    save: async function () {
      var params = {
        ID: this.model.id,
        DatumDohody: this.model.datum,
        DatumDohodyDo: this.model.datumDo,
        Poznamka: this.model.poznamka,
        AmbulanciaID: localStorage.getItem("ambId"),
      }
      await this.$api.post(
        this.saveKapitaciaUrl,
        params,
        this.saveOK,
        this.saveErr
      )
      this.saveOK()
    },
    saveOK: function () {
      this.$emit("saved")
      this.$store.commit(
        "setSuccess",
        this.$vuetify.t("Kapitácia bola úspešne uložená.")
      )
      this.$store.commit("setReloadPacient", true)
    },
    saveErr: function (error) {
      let ret = this.$vuetify.t(error)
      this.$store.commit(
        "setError",
        "Pri ukladaní kapitácie vznikla chyba ({message}).",
        { message: ret }
      )
    },
    getAmbulanciaKod: function () {
      this.$api.get("api/ambulanceById", null, this.setAmbKod, this.handleError)
    },
    setAmbKod: function (kod) {
      this.ambulanciaKod = kod
    },
    handleError: function (error) {
      console.log("Error:", error)
    },
  },
}
</script>
