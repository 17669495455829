<template>
  <div>
    <v-subheader v-if="title">{{ title }}</v-subheader>
    <template v-for="(item, index) in items">
      <v-list
        subheader
        dense
        :key="'list' + index"
        :class="{ 'secondary lighten-4': showHighlighted }"
      >
        <v-list-tile
          @click="editItem(item, parentID)"
          v-if="showAllItems ? true : index < 10"
        >
          <v-list-tile-content>
            <v-container fluid>
              <v-layout row>
                <!--v-flex xs2>
                  <v-tooltip top>
                    <span slot="activator"
                      >{{ item.poznamka }}
                      {{ /* item.dovod_pn.dovod.popis item.header.rc_id */}}
                    </span>
                    <span>Dôvod PN</span>
                  </v-tooltip>
                </v-flex-->
                <v-flex xs2>
                  <v-tooltip top>
                    <span slot="activator">
                      {{ item.neschopny_prace_od | formatDatum }}
                      {{ /* item.header.version_set_id */}}
                    </span>
                    <span>Neschopný práce OD</span>
                  </v-tooltip>
                </v-flex>
                <v-flex xs2>
                  <v-tooltip top>
                    <span slot="activator">
                      {{ item.schopny_prace_od | formatDatum }}
                      {{ /* item.header.previous_version */}}
                    </span>
                    <span>Dátum schopný práce od</span>
                  </v-tooltip>
                </v-flex>
                <v-flex xs6>
                  <v-tooltip top>
                    <span slot="activator"
                      >{{ item.diagnoza?.diagnoza?.popis }}
                    </span>
                    <span>Diagnóza</span>
                  </v-tooltip>
                </v-flex>
                <v-flex xs1>
                  {{ item.header?.version_status === "VER04" ? "STORNO" : "" }}
                </v-flex>
                <v-flex xs1>
                  <v-tooltip left v-if="showHistory">
                    <v-icon slot="activator" @click.stop="showVerzie(item)"
                      >update</v-icon
                    >
                    <span>Zobraziť históriu EDPN</span>
                  </v-tooltip>
                  <v-tooltip left v-if="showPredlzenieFunc(item)">
                    <v-icon slot="activator" @click.stop="predlzPN(item)"
                      >arrow_forward</v-icon
                    >
                    <span>Predĺžiť EDPN</span>
                  </v-tooltip>
                </v-flex>
              </v-layout>
            </v-container>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
      <EDPNList
        v-if="(showAllItems ? true : index < 10) && verzie[item.header?.rc_id]"
        :ref="'listVerzie' + item.header?.rc_id"
        :key="'listVerzie' + index"
        :items="verzie[item.header?.rc_id]"
        :showHistory="false"
        :showHighlighted="true"
        :parentID="item.header?.rc_id"
        @addItem="addItem"
        @editItem="editItem"
        @predlzPN="predlzPN"
      />
      <v-divider
        :key="'divider' + index"
        v-if="(showAllItems ? true : index < 10) && index !== items.length - 1"
      ></v-divider>
    </template>
    <v-list subheader dense v-if="!showAllItems && items.length > 10">
      <v-list-tile>
        <v-list-tile-content>
          <v-container fluid>
            <v-layout row>
              <v-flex xs12 class="text-xs-right">
                <v-btn
                  color="primary"
                  flat
                  @click.prevent="showAllItems = !showAllItems"
                  align-self-end
                  >{{ $vuetify.t("Zobraziť všetky") }}(+{{
                    items.length - 10
                  }})</v-btn
                >
              </v-flex>
            </v-layout>
          </v-container>
        </v-list-tile-content>
      </v-list-tile>
    </v-list>
  </div>
</template>

<script>
import EDPNList from "@/components/EDPNList.vue"
import moment from "moment"

export default {
  name: "EDPNList",
  components: { EDPNList },
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    items: {
      type: Array,
      required: true,
    },
    verzie: {
      type: Object,
      required: false,
      default: function () {
        return {}
      },
    },
    showHistory: {
      type: Boolean,
      required: false,
      default: false,
    },
    showPredlzenie: {
      type: Boolean,
      required: false,
      default: true,
    },
    showHighlighted: {
      type: Boolean,
      required: false,
      default: false,
    },
    parentID: {
      type: String,
      required: false,
      default: "",
    },
  },
  data: function () {
    return {
      showAllItems: false,
    }
  },
  computed: {},
  methods: {
    addItem: function () {
      this.$emit("addItem")
    },
    editItem: function (item, parentID) {
      this.$emit("editItem", item, parentID)
    },
    showVerzie: function (item) {
      this.$emit("showVerzie", item)
    },
    predlzPN: function (item) {
      this.$emit("predlzPN", item)
    },
    showPredlzenieFunc: function (item) {
      let ret = false
      if (!this.showPredlzenie) {
        return false
      }
      try {
        if (item.schopny_prace_od !== "") {
          let today = moment().startOf("day")
          let ukoncenie = moment(item.schopny_prace_od).startOf("day")
          let diff = ukoncenie.diff(today, "days")
          if (diff > -4 && diff < 2) {
            //v rozsahu 3 dni do minulosti a max. 1 den do buducnosti
            ret = true
          }
        }
      } catch (error) {}
      return ret
    },
  },
  mounted: function () {},
}
</script>
