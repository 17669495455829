<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    full-width
    min-width="300"
    :data-vv-as="label"
    :disabled="disabled"
  >
    <template v-slot:activator="{ on }">
      <slot name="activator" :on="on">
        <v-text-field
          v-on="on"
          :prepend-inner-icon="prependInnerIcon"
          :value="displayValue"
          :clearable="!isRequired"
          :label="label"
          readonly
          :error-messages="errors.collect(name)"
          @input="updateValue"
          :disabled="disabled"
        ></v-text-field>
      </slot>
    </template>

    <v-sheet>
      <v-container class="pa-0">
        <v-layout row>
          <v-list dense>
            <template
              v-for="(item, index) in [
                { title: 'Žiadny termín', value: null },
                { title: 'O týždeň', value: getDays(7) },
                { title: 'O mesiac', value: getMonths(1) },
                { title: 'O tri mesiace', value: getMonths(3) },
                { title: 'O šesť mesiacov', value: getMonths(6) },
                { title: 'O rok', value: getMonths(12) },
              ]"
            >
              <v-list-tile :key="index" @click="updateValue(item.value)">
                <v-list-tile-title>{{
                  $vuetify.t(item.title)
                }}</v-list-tile-title>
              </v-list-tile>
            </template>
          </v-list>
          <v-date-picker
            :value="value"
            @input="updateValue"
            locale="sk"
            :first-day-of-week="1"
            type="month"
            :min="minDate"
            no-title
          ></v-date-picker>
        </v-layout>
      </v-container>
    </v-sheet>
  </v-menu>
</template>

<script>
import * as Miscella from "./../miscella.js"
import moment from "moment"

export default {
  inject: ["$validator"],
  props: {
    name: {
      type: String,
      default: "field",
    },
    label: {
      type: String,
      default: "Dátum",
    },
    value: {
      default: null,
    },
    initialDate: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    format: {
      type: String,
      default: moment.defaultFormat,
    },
    type: {
      type: String,
      default: "date",
    },
    prependInnerIcon: {
      type: String,
      default: "",
    },
  },
  data: function () {
    return {
      menu: false,
    }
  },
  computed: {
    isRequired: function () {
      var field = this.$validator.fields.find({ name: this.name })
      if (field) {
        return field.isRequired
      }
      return false
    },
    displayValue: function () {
      moment.locale("sk")
      return !Miscella.isEmpty(this.value)
        ? Miscella.kontrolaFormat(this.value, this.initialDate)
        : undefined
    },
    minDate: function () {
      return moment().format("YYYY-MM")
    },
  },
  methods: {
    updateValue: function (value) {
      this.$emit(
        "input",
        Miscella.isSet(value) ? moment(value).format(this.format) : value
      )
      this.menu = false
    },
    getDays: function (addDays) {
      return moment(this.initialDate).add(addDays, "days")
    },
    getMonths: function (addMonths) {
      return moment(this.initialDate).add(addMonths, "months")
    },
  },
}
</script>
