<template>
  <div>
    <router-view></router-view>
    <v-dialog v-model="isApp" fullscreen full-width persistent>
      <v-container
        fluid
        fill-height
        style="background-color: rgba(255, 255, 255, 0.8)"
      >
        <v-layout justify-center align-center column>
          <h3 class="mb-4">{{ message }}</h3>
          <v-progress-circular indeterminate color="primary">
          </v-progress-circular>
        </v-layout>
      </v-container>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  computed: {
    ...mapState({
      isApp: (state) => state.loading?.app,
      message: (state) => state.loading?.message,
    }),
  },
  mounted: function () {
    this.$store.dispatch("fetchVersion", this.$api)
  },
}
</script>
