<template>
  <div>
    <v-container fluid grid-list-xs class="pa-0">
      <v-layout row wrap>
        <v-flex xs12>
          <v-data-table
            :headers="[
              {
                text: 'Diagnóza',
                value: 'nazovDiagnozy',
                sortable: false,
              },
            ]"
            :items="odporucanie.Diagnoza"
            class="elevation-4"
            hide-actions
          >
            <template v-slot:items="{ item }">
              <tr class="noclicker">
                <td>{{ item.DisplayName }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
        <v-flex xs12 md6>
          <titled-text
            title="Urgentnosť výmenného lístku"
            :text="odporucanie.UrgentnostVymennehoListku.DisplayName"
          />
        </v-flex>
        <v-flex xs12 md6>
          <titled-text
            title="Potreba sedácie"
            :text="$vuetify.t(odporucanie.PotrebaSedacie.value ? 'Áno' : 'Nie')"
          />
        </v-flex>
        <v-flex xs12 md6>
          <titled-text
            title="Druh špecializovaného útvaru"
            :text="odporucanie.DruhSpecializovanehoUtvaru.DisplayName"
          />
        </v-flex>
        <v-flex xs12 md6>
          <titled-text
            title="Odborné zameranie"
            :text="odporucanie.OdborneZameranie.DisplayName"
          />
        </v-flex>
        <v-flex xs12 v-if="hasVysetrovanyOrgan || hasVysetrovanyOrganCV">
          <titled-text
            v-if="hasVysetrovanyOrgan"
            title="Vyšetrovaný orgán"
            :text="odporucanie.VysetrovanyOrgan.originalText"
          />
          <titled-text
            v-if="hasVysetrovanyOrganCV"
            title="Vyšetrovaný orgán"
            :text="odporucanie.VysetrovanyOrgan.DisplayName"
          />
        </v-flex>
        <v-flex xs12 v-if="hasModalitaOld || hasModalitaNew">
          <titled-text
            v-if="hasModalitaNew"
            title="Modalita zobrazovacieho vyšetrenia"
            :text="odporucanie.ModalitaZobrazovaciehoVysetreniaCV.DisplayName"
          />
          <titled-text
            v-if="hasModalitaOld"
            title="Modalita zobrazovacieho vyšetrenia"
            :text="odporucanie.ModalitaZobrazovaciehoVysetrenia.originalText"
          />
        </v-flex>
        <v-flex xs12>
          <titled-text
            title="Požadované vyšetrenie"
            :text="odporucanie.PozadovaneVysetrenie.originalText"
          />
        </v-flex>
        <v-flex xs12>
          <titled-text
            title="Informácia pre pacienta"
            :text="
              odporucanie.InformaciaPrePacienta !== null
                ? odporucanie.InformaciaPrePacienta.originalText
                : ''
            "
          />
        </v-flex>
        <v-flex xs12 v-if="odporucanie.Extract && odporucanie.Extract !== null">
          <v-subheader class="caption">
            {{ $vuetify.t("Súvisiace vyšetrenia (extrakt)") }}
          </v-subheader>
          <v-data-table
            :headers="[
              {
                text: 'ID',
                value: 'id',
                sortable: false,
              },
              {
                text: 'Typ vyšetrenia',
                value: 'typ',
                sortable: false,
              },
              {
                text: '',
                value: '',
                sortable: false,
              },
            ]"
            :items="odporucanie.Extract.ZdravotnyZaznam"
            class="elevation-4"
            hide-actions
          >
            <template v-slot:items="{ item }">
              <tr class="noclicker">
                <td>
                  {{ item.extension }}
                </td>
                <td>
                  {{
                    $vuetify.t(
                      "$vuetify.enum.evysetrenie.typ." + getTyp(item.root.oid)
                    )
                  }}
                </td>
                <td class="text-xs-right">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        flat
                        icon
                        v-on="on"
                        @click="
                          nacitajDetailExtraktu(
                            item.extension,
                            getTyp(item.root.oid)
                          )
                        "
                        ><v-icon>text_snippet</v-icon></v-btn
                      >
                    </template>
                    <span>{{ $vuetify.t("Načítať vyšetrenie") }}</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
    <BaseDialog
      :dialogTitle="$vuetify.t('ezdravie: vyšetrenie')"
      width="75%"
      ref="ezdravieVysetrenieDialog"
    >
      <EZdravieVysetrenie
        ref="ezdravieVysetrenieForm"
        :pacientId="pacientId"
        :vysetrenie="vysetrenie"
        @prevzatieVymennehoListka="$emit('prevezmiVymennyListok')"
      ></EZdravieVysetrenie>
      <template v-slot:buttons>
        <v-btn flat @click.native="$refs.ezdravieVysetrenieDialog.close()">{{
          $vuetify.t("$vuetify.app.button.close")
        }}</v-btn>
        <v-btn
          flat
          @click="
            dajDetailVysetrenia(
              vysetrenie.RcIdExtension,
              getTyp(vysetrenie.RcIdOid)
            )
          "
          :loading="$store.state.loading.save"
          >{{ $vuetify.t("Načítať kompletné vyšetrenie") }}</v-btn
        >
        <!-- <v-btn -->
        <!--   color="primary" -->
        <!--   flat -->
        <!--   @click="$refs.ezdravieVysetrenieForm.save()" -->
        <!--   :loading="$store.state.loading.save" -->
        <!--   >{{ $vuetify.t("Uložiť") }}</v-btn -->
        <!-- > -->
      </template>
    </BaseDialog>
  </div>
</template>

<script>
import TitledText from "./TitledText.vue"
import BaseDialog from "@/components/BaseDialog.vue"
import { mapState, mapGetters, mapMutations } from "vuex"
import * as Miscella from "./../../miscella.js"

export default {
  name: "EZdravieOdporucanie",
  props: {
    pacientId: {
      type: String,
      required: false,
    },
    odporucanie: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      vysetrenie: null,
    }
  },
  components: {
    TitledText,
    BaseDialog,
    // see https://stackoverflow.com/a/58875919
    EZdravieVysetrenie: () => import("./EZdravieVysetrenie.vue"),
  },
  computed: {
    hasModalitaOld: function () {
      return this.odporucanie.ModalitaZobrazovaciehoVysetrenia != null
    },
    hasModalitaNew: function () {
      return this.odporucanie.ModalitaZobrazovaciehoVysetreniaCV != null
    },
    hasVysetrovanyOrgan: function () {
      return this.odporucanie.VysetrovanyOrgan != null
    },
    hasVysetrovanyOrganCV: function () {
      return this.odporucanie.VysetrovanyOrganCV != null
    },
    ...mapState({
      ambulanceID: (state) => state.me?.ambulance?.id,
    }),
    ...mapGetters(["isEhealthReady"]),
  },
  methods: {
    // TODO presunut na jedno miesto, napr. mixin
    getTyp: function (oid) {
      switch (oid) {
        case "1.3.158.00165387.100.60.90":
          return "ZAZNAM_O_ODBORNOM_VYSETRENI"
          break
        case "1.3.158.00165387.100.60.80":
          return "ZAZNAM_O_ZOBRAZOVACOM_VYSETRENI"
          break
        case "1.3.158.00165387.100.60.100":
          return "ZAZNAM_PREPUSTACIA_SPRAVA"
          break
      }
      return "ZAZNAM_O_ODBORNOM_VYSETRENI"
      //throw "Neznámy typ vyšetrenia"
    },
    // TODO presunut na jedno miesto, napr. mixin
    dajDetailVysetrenia: async function (rcID, typ) {
      this.startAppLoading("Načítava sa vyšetrenie")
      var online = await this.checkEhealthStatus()
      if (!online) {
        this.stopAppLoading()
        return
      }
      try {
        var response = await this.$api.ehealthService.dajZaznamOVysetreni({
          patient_id: this.pacientId,
          ambulance_id: this.ambulanceID,
          id_zaznamu_vysetrenia: rcID,
          typ_zaznamu_vysetrenia: typ,
        })
        var data = JSON.parse(response.data)
        console.log(data.Data.AllCompositions)
        this.vysetrenie = data.Data.AllCompositions
      } catch (error) {
        this.processEhealthError(
          error,
          "Pri načítavaní vyšetrenia vznikla chyba"
        )
      } finally {
        this.stopAppLoading()
      }
    },
    nacitajDetailExtraktu: async function (rcID, typ) {
      await this.dajDetailVysetrenia(rcID, typ)
      if (Miscella.isSet(this.vysetrenie)) {
        this.$refs.ezdravieVysetrenieDialog.open()
      }
    },
    // TODO dat na jedno miesto, napr. mixin
    checkEhealthStatus: async function () {
      if (!this.isEhealthReady) {
        notifyError("Ezdravie je momentálne nedostupné.")
        return false
      }
      return true
    },
  },
}
</script>
