import Http from "./http.js"
import RegistrationService from "./api/registrationService.js"
import PatientService from "./api/patientService.js"
import ThingService from "./api/thingService.js"
import CodebookService from "./api/codebookService.js"
import AmbulanceService from "./api/ambulanceService.js"
import AuthService from "./api/authService.js"
import UserService from "./api/userService.js"
import EhealthService from "./api/ehealthService.js"
import EhealthMockService from "./api/mock/ehealthMockService.js"
import StorageService from "./api/storageService.js"
import EventService from "./api/eventService.js"
import FormService from "./api/formService.js"
import config from "./../../app.config.js"

export default {
  install: function (Vue, options) {
    var Api = {
      router: null, // Vue-router - povinny
      store: null, // vuex - povinny
      tokenName: "token", // nazov tokenu v localStorage - povinny
      request(method, aUrl, params, { headers = {}, ...rest } = {}) {
        var init = Object.assign(rest, {
          headers: new Headers(headers),
        })
        init.headers.append("Content-Type", "application/json")
        init.headers.append(
          "Authorization",
          "Bearer " + localStorage.getItem(this.tokenName)
        )
        return Http.request(method, aUrl, params, init)
          .then((res) => res.json())
          .then((res) => {
            return res
          })
          .catch((res) => {
            if (res !== undefined && res.status === 401) {
              localStorage.removeItem(this.tokenName)
              this.router.push("/")
            }
            return Promise.reject(res)
          })
      },
      get(aUrl, params = {}, options = {}) {
        return this.request("get", aUrl, params, options)
      },
      post(aUrl, params, options = {}) {
        if (params === null) {
          params = {}
        }
        return this.request("post", aUrl, params, options)
      },
      delete(aUrl) {
        return this.request("delete", aUrl, {})
      },
      postBinary(aUrl, params) {
        var init = {
          method: "POST",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem(this.tokenName),
          }),
          body: params,
        }
        return (
          fetch(new Request(aUrl, init))
            //.then((res) => res.json())
            .then((res) => {
              return res
            })
        )
      },
      getBinary(aUrl) {
        var init = {
          method: "GET",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem(this.tokenName),
          }),
        }
        return fetch(new Request(aUrl, init))
          .then((res) => res.blob())
          .then((res) => {
            return res
          })
      },
      deleteBinary(aUrl, params = {}) {
        var init = {
          method: "DELETE",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem(this.tokenName),
          }),
          body: params,
        }
        return (
          fetch(new Request(aUrl, init))
            //.then((res) => res.json())
            .then((res) => {
              return res
            })
        )
      },
    }

    if (options != null) {
      if (options.router != null) {
        Api.router = options.router
      }
      if (options.tokenName != null) {
        Api.tokenName = options.tokenName
      }
      if (options.store != null) {
        Api.store = options.store
      }
    }

    // api services
    Api.registrationService = new RegistrationService(Api)
    Api.authService = new AuthService(Api)
    Api.userService = new UserService(Api)
    Api.patientService = new PatientService(Api)
    Api.thingService = new ThingService(Api)
    Api.codebookService = new CodebookService(Api)
    Api.ambulanceService = new AmbulanceService(Api)
    Api.ehealthService = Api.store.state.ehealth.demo
      ? new EhealthMockService(Api)
      : new EhealthService(Api)
    Api.storageService = new StorageService(Api)
    Api.eventService = new EventService(Api)
    Api.formService = new FormService(Api)

    Vue.api = Api
    Vue.prototype.$api = Api
  },
}
