<template>
  <v-container fluid>
    <ApiTable
      ref="table"
      :headers="headers"
      :url="cennikyUrl"
      :hasFilter="false"
      :title="this.cennikTitle()"
    >
      <template slot="toolbarItems">
        <v-tooltip top>
          <v-btn icon slot="activator" @click="addCennik">
            <v-icon>add</v-icon>
          </v-btn>
          <span>{{ $vuetify.t("Pridať") }}</span>
        </v-tooltip>
      </template>
      <template slot="items" slot-scope="props">
        <tr @click="editCennik(props.item)">
          <td class="text-md-left">
            {{ odbornostiText(props.item) }}
          </td>
          <td class="text-md-left">
            {{ typHodnotyText(props.item.TypHodnoty) }}
          </td>
          <td class="text-md-left">{{ props.item.Hodnota }}</td>
          <td class="text-md-left">{{ props.item.VekOd }}</td>
          <td class="text-md-left">{{ props.item.VekDo }}</td>
          <td>
            <v-tooltip left>
              <v-icon slot="activator" @click.stop="deleteCennik(props.item)"
                >delete</v-icon
              >
              <span>{{ $vuetify.t("Odstrániť") }}</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </ApiTable>
    <ApiTable
      ref="tableCennikVykony"
      :headers="headersVykony"
      :url="cennikyVykonovUrl"
      :hasFilter="false"
      :title="this.cennikVykonovTitle()"
    >
      <template slot="toolbarItems">
        <v-tooltip top>
          <v-btn icon slot="activator" @click="addCennikVykon">
            <v-icon>add</v-icon>
          </v-btn>
          <span>{{ $vuetify.t("Pridať") }}</span>
        </v-tooltip>
      </template>
      <template slot="items" slot-scope="props">
        <tr @click="editCennikVykon(props.item)">
          <td class="text-md-left">
            {{ vykonyText(props.item) }}
          </td>
          <td class="text-md-left">
            {{ diagnozyText(props.item) }}
          </td>
          <td class="text-md-left">{{ jednotkaText(props.item.JeCena) }}</td>
          <td class="text-md-left">{{ props.item.Hodnota }}</td>
          <td class="text-md-left">{{ typHodnotyText(props.item.Cennik) }}</td>
          <td>
            <v-tooltip left>
              <v-icon
                slot="activator"
                @click.stop="deleteCennikVykon(props.item)"
                >delete</v-icon
              >
              <span>{{ $vuetify.t("Odstrániť") }}</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </ApiTable>
    <BaseDialog
      ref="dialog"
      :dialogTitle="dialogTitle"
      @save="$refs.form.save()"
    >
      <FormPoistovnaCennik
        ref="form"
        :poistovna="poistovna"
        @saved="savedCennik"
      ></FormPoistovnaCennik>
    </BaseDialog>
    <BaseDialog
      fullscreen
      ref="dialogCennikVykon"
      :dialogTitle="dialogTitle"
      @save="$refs.formCennikVykon.save()"
    >
      <FormPoistovnaCennikVykon
        ref="formCennikVykon"
        :poistovna="poistovna"
        @saved="savedCennikVykon"
      ></FormPoistovnaCennikVykon>
    </BaseDialog>
  </v-container>
</template>

<script>
import ApiTable from "../../components/ApiTable.vue"
import BaseDialog from "../../components/BaseDialog.vue"
import FormPoistovnaCennik from "../../components/forms/FormPoistovnaCennik.vue"
import FormPoistovnaCennikVykon from "../../components/forms/FormPoistovnaCennikVykon.vue"

export default {
  components: {
    ApiTable,
    BaseDialog,
    FormPoistovnaCennik,
    FormPoistovnaCennikVykon,
  },
  computed: {
    cennikyUrl() {
      return "/api/poistovna/" + this.poistovna + "/cenniky"
    },
    cennikyVykonovUrl() {
      return "/api/poistovna/" + this.poistovna + "/cennikyVykonov"
    },
  },
  props: ["poistovna"],
  data() {
    return {
      dialogTitle: "",
      headers: [
        {
          text: "Odbornosti",
          value: "odbornosti",
          sortable: false,
        },
        {
          text: "Typ hodnoty",
          value: "typHodnoty",
          sortable: false,
        },
        {
          text: "Hodnota",
          value: "hodnota",
          sortable: false,
        },
        {
          text: "Vek Od",
          value: "vekOd",
          sortable: false,
        },
        {
          text: "Vek do",
          value: "vekDo",
          sortable: false,
        },
        {
          text: "",
          value: "",
          sortable: false,
        },
      ],
      headersVykony: [
        {
          text: "Výkony",
          value: "vykony",
          sortable: false,
        },
        {
          text: "Diagnózy",
          value: "diagnozy",
          sortable: false,
        },
        {
          text: "Jednotka",
          value: "jeCena",
          sortable: false,
        },
        {
          text: "Hodnota",
          value: "hodnota",
          sortable: false,
        },
        {
          text: "Cenník",
          value: "cennik",
          sortable: false,
        },
        {
          text: "",
          value: "",
          sortable: false,
        },
      ],
    }
  },
  methods: {
    cennikTitle: function () {
      return (
        this.$vuetify.t("Cenník") +
        " " +
        this.$vuetify.t("$vuetify.enum.poistovna." + this.poistovna)
      )
    },
    cennikVykonovTitle: function () {
      return (
        this.$vuetify.t("Cenník výkonov") +
        " " +
        this.$vuetify.t("$vuetify.enum.poistovna." + this.poistovna)
      )
    },
    odbornostiText: function (item) {
      return item.Odbornosti
        ? item.Odbornosti.map((item) => item.DisplayName).join(", ")
        : ""
    },
    typHodnotyText: function (typHodnoty) {
      return typHodnoty
        ? this.$vuetify.t("$vuetify.enum.cennikKod." + typHodnoty)
        : ""
    },
    vykonyText: function (item) {
      return item.Vykony ? item.Vykony.map((item) => item.Kod).join(", ") : ""
    },
    diagnozyText: function (item) {
      return item.Diagnozy
        ? item.Diagnozy.map((item) => item.Skratka).join(", ")
        : ""
    },
    jednotkaText: function (item) {
      return item == null ? "Bez hodnoty" : item ? "Cena" : "Bod"
    },
    addCennik: function () {
      this.$refs.form.new()
      this.dialogTitle = "Nový cenník"
      this.$refs.dialog.open()
    },
    editCennik: async function (item) {
      let cennik = await this.$api.get(
        "/api/poistovna/" + this.poistovna + "/cennik/" + item.ID
      )
      this.$refs.form.edit(cennik)
      this.dialogTitle = "Úprava cenníka"
      this.$refs.dialog.open()
    },
    deleteCennik: async function (cennik) {
      return this.$confirm(
        this.$vuetify.t("Naozaj chcete odstrániť cenník?")
      ).then((res) => {
        if (res) {
          var page = this
          this.$api
            .delete("/api/poistovna/" + this.poistovna + "/cennik/" + cennik.ID)
            .then(this.$refs.table.reload())
            .catch(function (err) {
              page.$store.commit(
                "setError",
                page.$vuetify.t("Pri vymazaní cenníka vznikla chyba " + err)
              )
            })
        }
      })
    },

    savedCennik: async function () {
      await Promise.all([
        this.$refs.table.reload(),
        this.$refs.tableCennikVykony.reload(),
      ])
      this.$refs.dialog.close()
    },
    itemClicked: function (item) {
      this.$router.push({ name: "cennik", params: { id: item.id } })
    },
    addCennikVykon: function () {
      this.$refs.formCennikVykon.new()
      this.dialogTitle = "Nový cenník výkonov"
      this.$refs.dialogCennikVykon.open()
    },
    editCennikVykon: async function (item) {
      this.$refs.formCennikVykon.edit(item)
      this.dialogTitle = "Úprava cenníka výkonov"
      this.$refs.dialogCennikVykon.open()
    },
    deleteCennikVykon: async function (cennik) {
      return this.$confirm(
        this.$vuetify.t("Naozaj chcete odstrániť cenník výkonov?")
      ).then((res) => {
        if (res) {
          var page = this
          this.$api
            .delete(
              "/api/poistovna/" + this.poistovna + "/cennikVykon/" + cennik.ID
            )
            .then(this.$refs.tableCennikVykony.reload())
            .catch(function (err) {
              page.$store.commit(
                "setError",
                page.$vuetify.t(
                  "Pri vymazaní cenníka výkonov vznikla chyba " + err
                )
              )
            })
        }
      })
    },
    savedCennikVykon: function () {
      this.$refs.dialogCennikVykon.close()
      this.$refs.tableCennikVykony.reload()
    },
  },
}
</script>
