<template>
  <v-layout column>
    <base-table
      :headers="headers"
      :title="$vuetify.t('Zmluvné subjekty')"
      ref="baseTable"
      :hasFilter="true"
      defaultSortBy="kod"
      @reload="fetchItems"
    >
      <template slot="toolbarItems">
        <v-tooltip top>
          <v-btn icon slot="activator" @click="addSubject">
            <v-icon>add</v-icon>
          </v-btn>
          <span>{{ $vuetify.t("Pridať") }}</span>
        </v-tooltip>
      </template>
      <template slot="items" slot-scope="props">
        <tr @click="viewItem(props.item)">
          <slot name="item" :item="props.item">
            <td>{{ props.item.kod }}</td>
            <td>{{ props.item.nazov }}</td>
            <td>{{ props.item.ico }}</td>
            <td>{{ props.item.menoKontakt }}</td>
            <td>{{ props.item.elpodatelna === true ? "Áno" : "Nie" }}</td>
            <td>
              <v-tooltip top v-if="props.item.typSubjektu === 0">
                <v-btn
                  fab
                  small
                  @click.stop="showCennik(props.item)"
                  slot="activator"
                >
                  <v-icon>euro_symbol</v-icon>
                </v-btn>
                <span>{{ $vuetify.t("Cenník") }}</span>
              </v-tooltip>
            </td>
          </slot>
        </tr>
      </template>
    </base-table>
    <SubjektDialog ref="subjektDialog" @save="reloadTable" />
  </v-layout>
</template>

<script>
import BaseTable from "./../../components/BaseTable.vue"
import SubjektDialog from "./../../components/dialogs/SubjektDialog.vue"
import Vue from "vue"

export default {
  components: {
    SubjektDialog,
    BaseTable,
  },
  data: function () {
    return {
      headers: [
        { text: "Kód", sortable: true, value: "kod" },
        { text: "Názov", sortable: true, value: "nazov" },
        { text: "IČO", sortable: true, value: "ico" },
        { text: "Kontakt", sortable: true, value: "menoKontakt" },
        {
          text: "Elektronická podateľňa",
          sortable: true,
          value: "elpodatelna",
        },
        { text: "", sortable: false },
      ],
    }
  },
  methods: {
    viewItem: function (item) {
      this.$refs.subjektDialog.show(item)
    },
    showCennik: function (item) {
      let newUrl = "/admin/zmluvnesubjekty/" + item.id + "/cenniky"
      console.log("forward to ", newUrl)
      this.$router.push(newUrl)
    },
    addSubject: function () {
      this.$refs.subjektDialog.add()
    },
    fetchItems: function () {
      let url = "/api/subjects"
      let t = this.$refs.baseTable
      var params = t.customParams
      t.loading = true
      return this.$api
        .get(url, params)
        .then((data) => {
          t.setItems(data)
        })
        .catch((error) => {
          console.log("xErr:", error)
        })
        .finally(() => {
          Vue.nextTick(() => {
            t.loading = false
          })
        })
    },
    reloadTable: function () {
      this.$refs.baseTable.reload()
    },
  },
  mounted: function () {
    this.reloadTable()
  },
}
</script>

<style scoped></style>
