<template>
  <v-form autocomplete="_away">
    <v-container fluid>
      <v-layout row wrap>
        <v-flex xs12>
          <codebook-autocomplete
            :codebook-id="enums.CodebookIDZdravotnickyPracovnikKod"
            name="zdravotnicky_pracovnik"
            v-model="model.zdravotnicky_pracovnik"
            :label="$vuetify.t('Zdravotnícky pracovník')"
          >
            <template slot="selection" slot-scope="{ item }">
              <span>
                {{ item.code }} -
                {{ item.display_name }}
              </span>
            </template>
            <template slot="item" slot-scope="{ item }">
              <v-list-tile-content>
                <v-list-tile-title>
                  {{ item.display_name }}
                </v-list-tile-title>
                <v-list-tile-sub-title>
                  {{ item.code }}
                </v-list-tile-sub-title>
              </v-list-tile-content>
            </template>
          </codebook-autocomplete>
        </v-flex>
        <v-flex xs12 md6>
          <TextField
            v-model="model.name"
            :label="$vuetify.t('Meno')"
            name="name"
            v-validate="'required'"
          ></TextField>
        </v-flex>
        <v-flex xs12 md6>
          <TextField
            v-model="model.surname"
            :label="$vuetify.t('Priezvisko')"
            name="surname"
            v-validate="'required'"
          ></TextField>
        </v-flex>
        <v-flex xs12 md6>
          <TextField
            v-model="model.phone"
            :label="$vuetify.t('Telefón')"
            name="phone"
          ></TextField>
        </v-flex>
        <v-flex xs12 md6>
          <TextField
            v-model="model.email"
            :label="$vuetify.t('Email')"
            name="email"
            v-validate="'email'"
          ></TextField>
        </v-flex>
        <v-flex xs12 md6>
          <TextField
            v-model="model.address.street"
            :label="$vuetify.t('Ulica')"
            name="street"
          ></TextField>
        </v-flex>
        <v-flex xs12 md3>
          <TextField
            v-model="model.address.street_number"
            :label="$vuetify.t('Číslo domu')"
            name="street_number"
          ></TextField>
        </v-flex>
        <v-flex xs12 md3>
          <codebook-autocomplete
            v-if="isSlovak"
            codebook-id="150"
            v-model="model.address.postal_code_cb"
            name="postal_code_cb"
            :label="$vuetify.t('PSČ')"
          ></codebook-autocomplete>
          <TextField
            v-else
            v-model="model.address.postal_code"
            :label="$vuetify.t('PSČ')"
            name="psc"
          ></TextField>
        </v-flex>
        <v-flex xs12 md6 v-if="isSlovak">
          <codebook-autocomplete
            codebook-id="45"
            name="city"
            v-model="model.address.city_cb"
            :label="$vuetify.t('Mesto')"
          ></codebook-autocomplete>
        </v-flex>
        <v-flex xs12 md6 v-else>
          <TextField
            v-model="model.address.city"
            :label="$vuetify.t('Mesto')"
            name="city"
          ></TextField>
        </v-flex>
        <v-flex xs12 md6>
          <codebook-autocomplete
            ref="countryField"
            codebook-id="51"
            v-model="model.address.country_cb"
            name="country"
            :label="$vuetify.t('Krajina')"
            :prepended-items-params="{
              in_filter: {
                attr: 'Skratka',
                values: ['703', '203', '804', '348', '040', '616'],
              },
            }"
            :prepended-items-process-func="
              (items) => {
                const sortBy = ['703', '203', '804', '348', '040', '616']
                items.sort(
                  (a, b) =>
                    sortBy.indexOf(a.skratka) - sortBy.indexOf(b.skratka)
                )
              }
            "
          ></codebook-autocomplete>
        </v-flex>
        <v-flex xs12>
          <v-textarea
            v-model="model.note"
            :label="$vuetify.t('Poznámka')"
            name="note"
            auto-grow
            rows="2"
            :counter="1000"
            v-validate="'max:1000'"
            data-vv-name="note"
            :data-vv-as="$vuetify.t('Poznámka')"
          ></v-textarea>
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
import BaseForm from "./../BaseForm.vue"
import * as Miscella from "./../../miscella.js"
import TextField from "./../TextField.vue"
import CodebookAutocomplete from "./../ciselnikComponents/CodebookAutocomplete.vue"
import * as enums from "@/plugins/enums.js"

export default {
  name: "FormPacientPreferovanyZdravotnickyPracovnik",
  extends: BaseForm,
  props: {
    pacient: {
      type: Object,
      required: true,
    },
  },
  $_veeValidate: {
    validator: "new", // give me my own validator scope.
  },
  components: {
    TextField,
    CodebookAutocomplete,
  },
  data: function () {
    return {
      model: null,
      osobaIdx: -1,
      enums: enums,
    }
  },
  computed: {
    vek: function () {
      return this.getAge(this.pacient.birthdate)
    },
    isSlovak: function () {
      if (!Miscella.isSet(this.pacient.address.country_cb)) {
        return true
      }
      return this.pacient.address.country_cb.skratka === "703"
    },
  },
  watch: {
    "model.zdravotnicky_pracovnik": function (zp) {
      if (
        Miscella.isSet(zp) &&
        Miscella.isEmpty(this.model.name) &&
        Miscella.isEmpty(this.model.surname)
      ) {
        this.model.name = zp.zdravotnicky_pracovnik_kod.name
        this.model.surname = zp.zdravotnicky_pracovnik_kod.surname
      }
    },
  },
  methods: {
    init: function () {
      this.model = {
        name: "",
        surname: "",
        phone: "",
        email: "",
        note: "",
        zdravotnicky_pracovnik: null,
        address: {
          street: null,
          street_number: null,
          city: "",
          city_cb: null,
          postal_code: null,
          postal_code_cb: null,
          country_cb: null,
        },
      }
      this.osobaIdx = -1
      this.$validator.reset()
    },
    new: function () {
      this.init()
    },
    edit: function (pacient, oIdx) {
      this.osobaIdx = oIdx
      this.pacient = pacient
      this.model = Miscella.cloneObject(
        this.pacient.preferovani_zdravotnicki_pracovnici[this.osobaIdx]
      )
      this.$validator.reset()
    },
    save: function () {
      return this.$validator.validateAll().then(
        function () {
          if (this.errors.any()) {
            return
          }
          if (this.isSlovak) {
            this.model.address.city = ""
            this.model.address.postal_code = ""
          } else {
            this.model.address.city_cb = null
            this.model.address.postal_code_cb = null
          }
          //this.osoba.affinity = this.affinity.display_name
          if (this.osobaIdx < 0) {
            this.pacient.preferovani_zdravotnicki_pracovnici.push(this.model)
          } else {
            this.pacient.preferovani_zdravotnicki_pracovnici[this.osobaIdx] =
              this.model
          }
          return this.$api
            .post("/v1/proto.PatientService/UpdatePatient", {
              patient: this.pacient,
            })
            .then(
              function (result) {
                this.$store.commit(
                  "setSuccess",
                  this.$vuetify.t("Pacient bol úspešne uložený")
                )
                this.$emit("saved", result)
              }.bind(this)
            )
            .catch(
              function (err) {
                this.$store.commit(
                  "setError",
                  this.$vuetify.t("Pri ukladaní pacienta vznikla chyba ") +
                    " (" +
                    err +
                    ")."
                )
              }.bind(this)
            )
        }.bind(this)
      )
    },
  },
  mounted: function () {},
  created: function () {
    this.init()
  },
}
</script>
