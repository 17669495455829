<template>
  <v-layout column>
    <base-table
      :headers="headers"
      :customParams="{ clinic_id: clinic ? clinic.id : null }"
      :title="$vuetify.t('Zoznam ambulancií')"
      ref="baseTable"
      :hasFilter="false"
      defaultSortBy="kod_ambulancie"
      @reload="fetchItems"
    >
      <template slot="toolbarItems">
        <v-tooltip top>
          <v-btn icon slot="activator" @click="addAmbulance">
            <v-icon>add</v-icon>
          </v-btn>
          <span>{{ $vuetify.t("Pridať") }}</span>
        </v-tooltip>
      </template>
      <template slot="items" slot-scope="props">
        <tr @click="viewItem(props.item)">
          <slot name="item" :item="props.item">
            <td>{{ props.item.name }}</td>
            <td>{{ props.item.zameranie.display_name }}</td>
            <td>{{ props.item.kod_ambulancie }}</td>
            <td>
              {{ $vuetify.t("$vuetify.app.boolean." + props.item.public) }}
            </td>
          </slot>
        </tr>
      </template>
    </base-table>
    <BaseDialog :dialogTitle="$vuetify.t(dialogTitle)" ref="ambulanceDialog">
      <FormAmbulance ref="ambulanceForm" @saved="reloadTable" />
    </BaseDialog>
  </v-layout>
</template>

<script>
import * as Miscella from "./../../miscella.js"
import BaseDialog from "@/components/BaseDialog.vue"
import BaseTable from "./../../components/BaseTable.vue"
import FormAmbulance from "./../../components/forms/FormAmbulance.vue"
import Vue from "vue"

export default {
  components: {
    FormAmbulance,
    BaseTable,
    BaseDialog,
  },
  data: function () {
    return {
      headers: [
        { text: "Názov", value: "name", sortable: true },
        {
          text: "Zameranie",
          value: "zameranie.display_name",
          sortable: true,
        },
        { text: "Kód", value: "kod_ambulancie", sortable: true },
        { text: "Verejne dostupná", value: "public", sortable: false },
      ],
      dialogTitle: "",
    }
  },
  computed: {
    clinic: function () {
      return this.$store.state.me.clinic
    },
  },
  methods: {
    viewItem: function (item) {
      this.$refs.ambulanceForm.edit(item)
      this.dialogTitle = "Úprava ambulancie"
      this.$refs.ambulanceDialog.open()
    },
    addAmbulance: function () {
      this.$refs.ambulanceForm.new()
      this.dialogTitle = "Nová ambulancia"
      this.$refs.ambulanceDialog.open()
    },
    reloadTable: function () {
      this.$refs.baseTable.reload()
    },
    fetchItems: function () {
      let url = "/v1/proto.AmbulanceService/ListAmbulances"
      let t = this.$refs.baseTable
      var params = t.customParams
      t.loading = true
      return this.$api
        .post(url, params)
        .then((data) => {
          t.setItems(data.ambulances)
        })
        .catch((error) => {
          console.log("xErr:", error)
        })
        .finally(() => {
          Vue.nextTick(() => {
            t.loading = false
          })
        })
    },
  },
  mounted: function () {
    this.reloadTable()
  },
}
</script>

<style scoped></style>
