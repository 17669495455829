<script>
export default {
  name: "BaseForm",
  components: {},
  props: {
    // function which calls save() on this form by default, but can be replaced with another function
    // this function is called by BaseDialog after clicking on Save button, with form reference as parameter
    saveFunc: {
      type: Function,
      required: false,
      default: function (form) {
        return this.save()
      },
    },
  },
  data: function () {
    return {
      isDirty: false,
      readonly: false,
    }
  },
  watch: {
    model: {
      deep: true,
      handler() {
        this.isDirty = true
      },
    },
  },
  methods: {
    validate: function () {
      return true
    },
    save: function () {},
  },
}
</script>
