<template>
  <div>
    <v-card class="text-editor text-editor__editor-wrapper">
      <v-toolbar flat dense card>
        <v-toolbar-title>
          <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
            <div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    icon
                    flat
                    :class="{ 'v-btn--active': isActive.bold() }"
                  >
                    <v-icon @click="commands.bold">format_bold</v-icon>
                  </v-btn>
                </template>
                <span>{{ $vuetify.t("Tučné") }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    icon
                    flat
                    :class="{ 'v-btn--active': isActive.italic() }"
                  >
                    <v-icon @click="commands.italic">format_italic</v-icon>
                  </v-btn>
                </template>
                <span>{{ $vuetify.t("Kurzíva") }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    icon
                    flat
                    :class="{ 'v-btn--active': isActive.underline() }"
                  >
                    <v-icon @click="commands.underline"
                      >format_underline</v-icon
                    >
                  </v-btn>
                </template>
                <span>{{ $vuetify.t("Podčiarknuté") }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    icon
                    flat
                    :class="{ 'v-btn--active': isActive.strike() }"
                  >
                    <v-icon @click="commands.strike"
                      >format_strikethrough</v-icon
                    >
                  </v-btn>
                </template>
                <span>{{ $vuetify.t("Preškrtnuté") }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon flat>
                    <v-icon @click="commands.undo">undo</v-icon>
                  </v-btn>
                </template>
                <span>{{ $vuetify.t("Späť") }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon flat>
                    <v-icon @click="commands.redo">redo</v-icon>
                  </v-btn>
                </template>
                <span>{{ $vuetify.t("Znova") }}</span>
              </v-tooltip>
            </div>
          </editor-menu-bar>
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <editor-content :editor="editor" class="text-editor__editor" />
    </v-card>
  </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from "tiptap"
import {
  Bold,
  Italic,
  Underline,
  Strike,
  History,
  Placeholder,
} from "tiptap-extensions"

export default {
  inject: ["$validator"],
  name: "TextEditor",
  components: {
    EditorContent,
    EditorMenuBar,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: function () {
        return this.$vuetify.t("Prosím, zadajte text ...")
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      //internalValue: "",
      //isDirty: false,
      editor: null,
      /*editor: new Editor({
        extensions: [
          new Bold(),
          new Italic(),
          new Underline(),
          new Strike(),
          new History(),
          placeholderNode,
        ],
        onUpdate: ({ getHTML }) => {
          this.internalValue = getHTML()
          this.isDirty = true
        },
      }),*/
    }
  },
  watch: {
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value

      // JSON
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

      if (isSame) {
        return
      }

      this.editor.setContent(value)
    },
    disabled: function () {
      this.editor.setOptions({
        editable: !this.disabled,
      })
    },
  },
  methods: {
    focus: function () {
      this.editor.focus("end")
    },
  },
  beforeDestroy() {
    this.editor.destroy()
  },
  mounted() {
    let placeholderNode = new Placeholder({
      emptyNodeClass: "is-empty",
      emptyNodeText: this.placeholder,
      showOnlyWhenEditable: false,
    })

    this.editor = new Editor({
      content: this.value,
      extensions: [
        new Bold(),
        new Italic(),
        new Underline(),
        new Strike(),
        new History(),
        placeholderNode,
      ],
      onUpdate: () => {
        // HTML
        this.$emit("input", this.editor.getHTML())

        // JSON
        // this.$emit('input', this.editor.getJSON())
      },
    })
  },
}
</script>

<style lang="stylus">
.text-editor {
  .v-toolbar__content {
    padding: 0 0px;
  }

  &.v-sheet {
    display: flex;
    flex-direction: column;
  }

  &.text-editor__disabled .text-editor__viewer:hover {
    cursor: not-allowed;
  }

  &__editor-wrapper {
    min-height: 200px;
  }

  .text-editor__editor {
    flex-grow: 1;
    display: flex;
    flex-direction: row;

    .ProseMirror {
      flex-grow: 1;
    }
  }

  .ProseMirror {
    padding: 1em;
    outline: none !important;
  }

  // styles for placeholder
  p.is-empty:first-child::before {
    content: attr(data-empty-text);
    float: left;
    pointer-events: none;
    height: 0;
    font-style: italic;
  }
}
</style>
