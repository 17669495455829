<template>
  <span>{{ nazovPoskytovatela }}</span>
</template>

<script>
import * as Miscella from "./../../miscella.js"
import * as enums from "@/plugins/enums.js"

export default {
  props: {
    ii: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      poskytovatel: null,
    }
  },
  computed: {
    nazovPoskytovatela: function () {
      return this.poskytovatel?.display_name
      //return `${this.poskytovatel.Nazov}, ${this.poskytovatel.UlicaCisloOU}, ${this.poskytovatel.PscOU} ${this.poskytovatel.ObecOU}`
    },
  },
  watch: {
    ii: function () {
      this.fetchOUPZS()
    },
  },
  methods: {
    fetchOUPZS: async function () {
      if (Miscella.isSet(this.ii)) {
        var params = {}
        params.codebook_ids = [enums.CodebookIDOUPZS]
        params.table_request = {
          limit: 1,
        }
        params.code = this.ii.extension
        var data = await this.$api.codebookService.listCodebookItems(params)
        if (Miscella.isNotSet(data.items) || data.items.length == 0) {
          throw "Poskytovatel ZS not found!"
        }
        this.poskytovatel = data.items[0]
      }
    },
  },
  mounted: function () {
    this.fetchOUPZS()
  },
}
</script>
