<template>
  <!-- <div class="dekurz" v-scroll="onScroll"> -->
  <div class="dekurz">
    <v-container v-if="!loading && dekurzy.length === 0">
      Žiadne záznamy
    </v-container>
    <!-- <v-fade-transition group> -->
    <v-card
      :id="$options.filters.formatDatumACas(dekurz.date, 'D.M.YYYY-HH:mm')"
      v-for="dekurz in dekurzy"
      :key="dekurz.id"
      class="dekurz__item mb-2 elevation-4"
    >
      <v-toolbar flat dense card color="grey darken-2" dark>
        <v-toolbar-title>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <span v-on="on" class="subheading">
                <v-icon small>{{
                  $vuetify.t(
                    `$vuetify.thing.type.${_getThingType(dekurz)}.icon`
                  )
                }}</v-icon>
                <span class="font-weight-medium pl-1">
                  {{
                    $vuetify.t(
                      `$vuetify.thing.type.${_getThingType(dekurz)}.text`
                    )
                  }}</span
                >
                <span class="font-weight-thin pl-2"
                  >(
                  <span class="font-weight-regular">{{
                    dekurz.date | formatDatumACas("D.M.YYYY HH:mm")
                  }}</span>
                  <span class="font-weight-light">
                    {{ dekurz.author.name }}
                  </span>
                  )</span
                >
              </span>
            </template>
            <span>{{ $vuetify.t("Typ záznamu ( dátum a autor )") }}</span>
          </v-tooltip>
        </v-toolbar-title>
        <EhealthRecord
          toolbar
          compact
          v-model="dekurz.ehealth_record"
          :objectID="dekurz.id"
          titleText="evyšetrenie"
          :statusTextPrefix="`$vuetify.enum.ehealthRecord.versionStatus.${
            dekurz.ehealth_record ? dekurz.ehealth_record.version_status : ''
          }`"
        ></EhealthRecord>
        <v-spacer></v-spacer>
        <v-tooltip top v-if="dekurz.vysetrenie !== undefined">
          <template v-slot:activator="{ on }">
            <v-btn flat icon v-on="on" @click="itemClicked(dekurz)"
              ><v-icon>edit</v-icon></v-btn
            >
          </template>
          <span>{{ $vuetify.t("Upraviť") }}</span>
        </v-tooltip>
        <v-tooltip top v-if="dekurz.vysetrenie !== undefined">
          <template v-slot:activator="{ on }">
            <v-btn flat icon v-on="on" @click="printVysetrenie(dekurz)"
              ><v-icon>print</v-icon></v-btn
            >
          </template>
          <span>{{ $vuetify.t("Tlačiť") }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn flat icon v-on="on" @click="deleteThing(dekurz)"
              ><v-icon>delete</v-icon></v-btn
            >
          </template>
          <span>{{ $vuetify.t("Odstrániť") }}</span>
        </v-tooltip>
      </v-toolbar>
      <v-card-text>
        <limited-height :id="dekurz.id" maxHeight="500px">
          <vue-markdown>{{ dekurz.formatted_text }}</vue-markdown>
        </limited-height>
      </v-card-text>
    </v-card>
    <!-- </v-fade-transition> -->
    <v-layout v-if="dekurzy.length > 0" row justify-center>
      <v-btn color="primary" flat @click="nextPage" :disabled="!showNextPage">{{
        $vuetify.t("Načítať ďalšie")
      }}</v-btn>
    </v-layout>
    <loading-container :value="loading"> </loading-container>
  </div>
</template>

<script>
import moment from "moment"
import VueMarkdown from "vue-markdown-v2"
import * as Miscella from "./../../miscella.js"
import thingMixin from "@/components/mixins/thing.js"
import EhealthRecord from "@/components/EhealthRecord.vue"
import LimitedHeight from "@/components/LimitedHeight.vue"
import LoadingContainer from "@/components/LoadingContainer.vue"

export default {
  mixins: [thingMixin],
  props: {
    patientID: {
      type: String,
      required: true,
    },
    search: {
      type: String,
      required: false,
      default: "",
    },
    dateFrom: {
      type: Date,
      required: false,
      default: null,
    },
    limit: {
      type: Number,
      required: false,
      default: 10,
    },
  },
  components: {
    VueMarkdown,
    EhealthRecord,
    LimitedHeight,
    LoadingContainer,
  },
  data: function () {
    return {
      dekurzy: [],
      loading: false,
      dateTo: null,
      showNextPage: false,
    }
  },
  watch: {
    search: function (val) {
      this.myDelay(() => {
        this.init()
      }, 1000)
    },
    /*dateTo: function (val) {
      console.log("DATETO changed", val)
      this.init()
    },*/
  },
  methods: {
    init: function (dateTo) {
      this.dateTo = dateTo
      this.dekurzy = []
      this.showNextPage = false
      this.fetchData()
    },
    nextPage: function () {
      if (!this.loading) {
        this.fetchData(this.dekurzy.length)
      }
    },
    fetchData: async function (offset = 0) {
      try {
        this.loading = true
        await this.sleep(500) // pol sekundy sleep aby sme videli aspoň nejake nacitavacie koliesko
        var params = {
          patient_id: this.patientID,
          table_request: {
            offset: offset,
            limit: this.limit,
            sortBy: "Date",
            sortDesc: true,
          },
          parent_request: {
            parent_id: "",
          },
        }
        if (!Miscella.isEmpty(this.search)) {
          params.table_request.fulltext_search = this.search
        }
        if (Miscella.isSet(this.dateFrom)) {
          params.date_from = moment(this.dateFrom).format()
        }
        if (Miscella.isSet(this.dateTo)) {
          params.date_to = moment(this.dateTo).format()
        }
        var data = await this.$api.thingService.listThings(params)
        var previousLen = this.dekurzy.length
        this.dekurzy = offset > 0 ? this.dekurzy.concat(data.items) : data.items
        this.showNextPage = this.dekurzy.length > previousLen
      } catch (err) {
        this.processError(err)
      } finally {
        this.loading = false
      }
    },
    itemClicked: function (item) {
      if (!Miscella.isEmpty(item.id)) {
        this.$router.push({
          name: "vysetrenie",
          params: { id: this.patientID, vysetrenieID: item.id },
        })
      }
    },
    deleteThing: async function (thing) {
      var result = await this._deleteThing(thing) // from thing mixin
      if (result) {
        this.dekurzy.splice(this.dekurzy.indexOf(thing), 1)
        this.$emit("changed")
      }
    },
    /*deleteThing: async function (thing) {
      var type = this.getType(thing)
      var res = await this.$confirm(
        this.$vuetify.t(`$vuetify.thing.type.${type}.delete.confirm`)
      )
      if (!res) {
        return
      }
      try {
        var result = await this.$api.thingService.deleteThing({
          thing_id: thing.id,
        })
        this.notifySuccess(`$vuetify.thing.type.${type}.delete.success`)
        this.dekurzy.splice(this.dekurzy.indexOf(thing), 1)
        this.$emit("changed")
      } catch (err) {
        this.processError(err, `$vuetify.thing.type.${type}.delete.error`)
      }
    },*/
    printVysetrenie: function (item) {
      let route = this.$router.resolve({
        path: "/print/vysetrenie",
        query: {
          pacientID: this.patientID,
          vysetrenieID: item.id,
          clinicID: this.$store.state.me.clinic.id,
        },
      })
      window.open(route.href, "_blank")
    },
    onScroll: function (e) {
      var doc = e.target.documentElement
      if (doc.scrollHeight - Math.round(doc.scrollTop) === doc.clientHeight) {
        this.nextPage()
      }
    },
  },
}
</script>

<style scoped>
/*.fade-transition-enter-active,
.fade-transition-leave-active {
  transition-duration: 1s;
}*/
</style>
