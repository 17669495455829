import EhealthService from "../ehealthService.js"
import * as dajZpPrZsJson from "./dajZpPrZs.json"
import * as vyhladajVLJson from "./vyhladajVL.json"
import * as vyhladajVysetreniaJson from "./vyhladajVysetrenia.json"
import * as vyhladajPotvrdenieDPNJson from "./vyhladajPotvrdenieDPN.json"
import * as vyhladajEmptyJson from "./vyhladajEmpty.json"
import * as dajZaznamOVysetreniJson from "./dajZaznamOVysetreni.json"
import * as zapisSuhlasOsobyPrePzsJson from "./zapisSuhlasOsobyPrePzs.json"
import * as prevezmiVymennyListokJson from "./prevezmiVymennyListok.json"
import * as overVerziuCiselnikovJson from "./overVerziuCiselnikov.json"
import * as dajPacientskySumarEDSJson from "./dajPacientskySumarEDS.json"
import * as dajPacientskySumarKontaktneUdajeJson from "./dajPacientskySumarKontaktneUdaje.json"
import * as dajPotvrdenieDPNJson from "./dajPotvrdenieDPN.json"
import * as zrusKontaktneUdajePacientskehoSumaruJson from "./zrusKontaktneUdajePacientskehoSumaru.json"
import * as zapisPacientskehoSumaruKontaktneUdajeJson from "./zapisPacientskehoSumaruKontaktneUdaje.json"
import * as Miscella from "./../../../miscella.js"

export default class EhealthMockService extends EhealthService {
  constructor(api) {
    super(api)
    this.createMockMethod("dajZpPrZs", dajZpPrZsJson.default)
    //this.createMockMethod("vyhladajEmpty", vyhladajEmptyJson.default)
    this["vyhladajZaznamyOVysetreniach"] = function (params) {
      if (params.nazov_vysetrenia === "empty") {
        return Promise.resolve({
          data: JSON.stringify(vyhladajEmptyJson.default),
        })
      }
      return Promise.resolve({
        data: JSON.stringify(vyhladajVysetreniaJson.default),
      })
    }
    this["vyhladajVL"] = function (params) {
      if (params.id_vl === "emptyVL") {
        return Promise.resolve({
          data: JSON.stringify(vyhladajEmptyJson.default),
        })
      }
      return Promise.resolve({
        data: JSON.stringify(vyhladajVLJson.default),
      })
    }
    //this.createMockMethod("vyhladajVL", vyhladajVLJson.default)
    this["vyhladajPotvrdenieDPN"] = function () {
      return Promise.resolve(vyhladajPotvrdenieDPNJson.default)
    }
    this["dajPotvrdenieDPN"] = function () {
      return Promise.resolve(dajPotvrdenieDPNJson.default)
    }
    this.createMockMethod(
      "dajZaznamOVysetreni",
      dajZaznamOVysetreniJson.default
    )
    this.createMockMethod(
      "zapisSuhlasOsobyPrePzs",
      zapisSuhlasOsobyPrePzsJson.default
    )
    this.createMockMethod(
      "prevezmiVymennyListok",
      prevezmiVymennyListokJson.default
    )
    this.createMockMethod(
      "overVerziuCiselnikov",
      overVerziuCiselnikovJson.default
    )
    this.createMockMethod(
      "dajPacientskySumarEds",
      dajPacientskySumarEDSJson.default
    )
    this.createMockMethod(
      "dajPacientskySumarKontaktneUdaje",
      dajPacientskySumarKontaktneUdajeJson.default
    )
    this.createMockMethod(
      "zrusKontaktneUdajePacientskehoSumaru",
      zrusKontaktneUdajePacientskehoSumaruJson.default
    )
    this.createMockMethod(
      "zapisPacientskehoSumaruKontaktneUdaje",
      zapisPacientskehoSumaruKontaktneUdajeJson.default
    )
    this["sendToEhealth"] = function (params) {
      console.log("LAUNCHED MOCK API SERVICE METHOD ", "sendToEhealth", params)
      // posli event ze sa odosiela
      api.store.commit("addEvent", {
        event: api.store.getters.getUserEventTopic(
          "ehealth:ehealthRecord:changed"
        ),
        data: {
          object_id: params.object_id,
          object_type: params.object_type,
          created_at: "2022-04-01T10:01:01Z",
          updated_at: "2022-04-01T13:44:06Z",
          rc_id: "039000000122000001044",
          status: 2,
          version_status: params.version_status ? params.version_status : 0,
        },
      })
      switch (params.object_type) {
        case "DAJ_PACIENT_INFO":
          Miscella.myDelay(() => {
            api.store.commit("addEvent", {
              event: api.store.getters.getUserEventTopic(
                "ehealth:patientInfoReceived"
              ),
              data: {
                patient_id: params.patient_id,
                person_unique_id: "7105316218",
                jruz_id: "00074540915",
                person_computer_number: "9714462",
                person_meaningless_identifier: "4992593265",
              },
            })
          }, 5000)
          break
        case "DAJ_JRUZ_IDENTIFIKATOR_GW":
          Miscella.myDelay(() => {
            api.store.commit("addEvent", {
              event: api.store.getters.getUserEventTopic(
                "ehealth:patientJruzIDReceived"
              ),
              data: {
                patient_id: params.patient_id,
                jruz_id: "00020017638",
              },
            })
          }, 5000)
          break
        case "ZAPIS_ZAZNAM_O_VYSETRENI_V7":
        case "STORNUJ_ZAZNAM_O_VYSETRENI_V7":
          break
        default:
      }
      // event ze sa to prebehlo uspesne alebo s chybou
      Miscella.myDelay(() => {
        api.store.commit("addEvent", {
          event: api.store.getters.getUserEventTopic(
            "ehealth:ehealthRecord:changed"
          ),
          data: {
            object_id: params.object_id,
            created_at: "2022-04-01T10:01:01Z",
            updated_at: "2022-04-01T13:44:06Z",
            rc_id: "039000000122000001044",
            status: this.getRndInteger(3, 4),
            version_status: params.version_status ? params.version_status : 0,
          },
        })
      }, 5000)
    }
  }

  createMockMethod(name, mockJson) {
    this[name] = function (params = {}, options = {}) {
      console.log("LAUNCHED MOCK API SERVICE METHOD ", name, params, options)
      return Promise.resolve({
        data: JSON.stringify(mockJson),
      })
    }
  }

  getRndInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min
  }
}
