const pathLib = require("path")

export default class BaseApiService {
  constructor(api, ...paths) {
    this.api = api
    for (const path of paths) {
      let pathName = pathLib.basename(path)
      pathName = pathName.charAt(0).toLowerCase() + pathName.slice(1) // lower case first letter
      this[`${pathName}Url`] = path
      this[pathName] = function (params = {}, options = {}) {
        console.log("LAUNCHED API SERVICE METHOD ", pathName)
        return this.api.post(path, params, options)
      }
    }
  }
}
