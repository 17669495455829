<template>
  <v-container fluid px-0>
    <v-layout row>
      <v-flex xs2>
        <v-tooltip top>
          <span slot="activator">{{
            vitAntropHodnoty.date | formatDatum
          }}</span>
          <span>{{ $vuetify.t("Dátum merania") }}</span>
        </v-tooltip>
      </v-flex>
      <v-flex xs2>
        <v-tooltip
          top
          v-if="vitAntropHodnoty.vit_antrop_hodnoty.hmotnost_hodnota !== null"
        >
          <span slot="activator">{{
            vitAntropHodnoty.vit_antrop_hodnoty.hmotnost_hodnota
          }}</span>
          <span>{{ $vuetify.t("Hmotnosť") }}</span>
        </v-tooltip>
        <v-tooltip
          top
          v-if="vitAntropHodnoty.vit_antrop_hodnoty.hmotnost_jednotka !== null"
        >
          <span slot="activator">{{
            vitAntropHodnoty.vit_antrop_hodnoty.hmotnost_jednotka.display_name
          }}</span>
          <span>{{ $vuetify.t("Hmotnosť") }}</span>
        </v-tooltip>
      </v-flex>
      <v-flex xs2>
        <v-tooltip
          top
          v-if="vitAntropHodnoty.vit_antrop_hodnoty.vyska_hodnota !== null"
        >
          <span slot="activator">{{
            vitAntropHodnoty.vit_antrop_hodnoty.vyska_hodnota
          }}</span>
          <span>{{ $vuetify.t("Výška") }}</span>
        </v-tooltip>
        <v-tooltip
          top
          v-if="vitAntropHodnoty.vit_antrop_hodnoty.vyska_jednotka !== null"
        >
          <span slot="activator">{{
            vitAntropHodnoty.vit_antrop_hodnoty.vyska_jednotka.display_name
          }}</span>
          <span>{{ $vuetify.t("Výška") }}</span>
        </v-tooltip>
      </v-flex>
      <v-flex xs2 class="text-truncate">
        <v-tooltip top>
          <span slot="activator">{{
            vitAntropHodnoty.vit_antrop_hodnoty.poznamka
          }}</span>
          <span>{{ $vuetify.t("Poznámka") }}</span>
        </v-tooltip>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  name: "VitAntropHodnotyRow",
  props: {
    vitAntropHodnoty: {
      type: Object,
      required: true,
    },
  },
}
</script>
