<template>
  <v-snackbar
    top
    v-model="snackbarShow"
    :color="snackbarColor"
    :timeout="30000"
    auto-height
  >
    {{ snackbarText }}
    <v-btn dark flat @click="snackbarShow = false">
      {{ $vuetify.t("Zatvoriť") }}
    </v-btn>
  </v-snackbar>
</template>

<script>
import * as Miscella from "./../miscella.js"
import Vue from "vue"

export default {
  data: function () {
    return {
      snackbarText: "",
      snackbarColor: "",
      snackbarShow: false,
    }
  },
  watch: {
    "$store.state.message": function (message) {
      if (Miscella.isSet(message)) {
        this.snackbarText = message.text
        this.snackbarColor = message.type
        this.snackbarShow = true
      }
    },
    snackbarShow: function () {
      if (!this.snackbarShow) {
        this.snackbarText = ""
        Vue.nextTick(() => {
          this.$store.commit("nextMessage")
        })
      }
    },
  },
}
</script>

<style scoped></style>
