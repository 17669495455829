<template>
  <v-container fluid>
    <ApiTable
      :headers="headers"
      ref="table"
      url="/api/poistovne/obdobia"
      :title="$vuetify.t('Prehľad spracovaných období')"
    >
      <template slot="toolbarItems">
        <v-tooltip top>
          <v-btn icon slot="activator" @click="addDavka">
            <v-icon>add</v-icon>
          </v-btn>
          <span>{{ $vuetify.t("Pridať") }}</span>
        </v-tooltip>
      </template>
      <template slot="items" slot-scope="props">
        <tr @click="itemClicked(props.item)">
          <td class="text-md-left">
            {{ obdobieLabel(props.item.Obdobie) }}
          </td>
          <td class="text-md-left">{{ props.item.PoistovnaKod }}</td>
          <td class="text-md-left">
            {{
              $vuetify.t("$vuetify.enum.poistovna." + props.item.PoistovnaNazov)
            }}
          </td>
          <td class="text-md-left">{{ props.item.Zmluva }}</td>
          <td class="text-md-left">
            {{ props.item.Created | formatDatumACas }}
          </td>
          <td class="text-md-left">
            {{
              $vuetify.t("$vuetify.enum.stavObdobia." + props.item.StavObdobia)
            }}
          </td>
          <td>
            <v-tooltip left v-if="props.item.StavObdobia === 'NOVE'">
              <v-icon slot="activator" @click.stop="sendObdobie(props.item)"
                >send</v-icon
              >
              <span>{{ $vuetify.t("Odoslať") }}</span>
            </v-tooltip>
            <v-tooltip left>
              <v-icon
                v-if="props.item.VysledokOdoslania !== ''"
                slot="activator"
                @click.stop="zobrazitVysledok(props.item)"
                >info</v-icon
              >
              <span>{{ $vuetify.t("Zobraziť výsledok odoslania") }}</span>
            </v-tooltip>

            <v-tooltip
              left
              v-if="
                props.item.StavObdobia === 'NOVE' ||
                props.item.StavObdobia === 'VYSLEDOK_CHYBA'
              "
            >
              <v-icon slot="activator" @click.stop="deleteObdobie(props.item)"
                >delete</v-icon
              >
              <span>{{ $vuetify.t("Odstrániť") }}</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </ApiTable>
    <BaseDialog
      ref="dialog"
      :dialogTitle="dialogTitle"
      @save="$refs.form.saveForm()"
    >
      <FormDavkaPreZP ref="form" @saved="savedDavka"></FormDavkaPreZP>
    </BaseDialog>
    <v-dialog v-model="showInfo" max-width="800">
      <v-card>
        <v-card-title class="headline">{{
          $vuetify.t("Výsledok odoslania")
        }}</v-card-title>

        <v-card-text v-html="chosen.VysledokOdoslania"> </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn flat="flat" @click="showInfo = false">
            {{ $vuetify.t("Zavrieť") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import ApiTable from "../../components/ApiTable.vue"
import BaseDialog from "./../../components/BaseDialog.vue"
import FormDavkaPreZP from "../../components/forms/FormDavkaPreZP.vue"
import moment from "moment"
import * as Miscella from "../../miscella.js"

export default {
  components: {
    ApiTable,
    BaseDialog,
    FormDavkaPreZP,
  },
  methods: {
    addDavka: function () {
      this.$refs.form.newDavka()
      this.dialogTitle = "Nová dávka"
      this.$refs.dialog.open()
    },
    savedDavka: function () {
      this.$refs.dialog.close()
      this.$refs.table.reload()
    },
    itemClicked: function (item) {
      this.chosen = item
      let newUrl = "/poistovne/exportdavok/" + this.chosen.ID + "/davky"
      console.log("forward to ", newUrl)
      this.$router.push(newUrl)
    },
    sendObdobie: async function (item) {
      await this.$api.post("/api/poistovne/" + item.ID + "/send")

      this.$store.commit(
        "setSuccess",
        this.$vuetify.t("Obdobie bolo označené na odoslanie.")
      )
      this.$refs.table.reload()
    },
    zobrazitVysledok: function (item) {
      this.chosen = item
      this.showInfo = true
    },

    deleteObdobie: function (item) {
      return this.$confirm("Naozaj chcete odstrániť obdobie?").then((res) => {
        if (res) {
          return this.$api
            .delete("/api/poistovne/obdobie/" + item.ID)
            .then(() => {
              this.$store.commit(
                "setSuccess",
                this.$vuetify.t("Obdobie bolo vymazané.")
              )
              this.$refs.table.reload()
            })
            .catch(
              function (error) {
                console.log("Chyba pri vymaze obdobia", error)
                this.$store.commit(
                  "setError",
                  this.$vuetify.t("Nastala chyba pri výmaze obdobia")
                )
              }.bind(this)
            )
        }
      })
    },
    obdobieLabel: function (obdobie) {
      moment.locale("sk")
      return !Miscella.isEmpty(obdobie)
        ? moment(obdobie, "YYYYMM").format("MMM YYYY")
        : ""
    },
  },
  data: function () {
    return {
      dialogTitle: "",
      showInfo: false,
      headers: [
        {
          text: "Obdobie",
          value: "Obdobie",
          sortable: false,
        },
        {
          text: "Kód pobočky",
          value: "PoistovnaKod",
          sortable: false,
        },
        {
          text: "Názov ZP",
          value: "PoistovnaNazov",
          sortable: false,
        },
        {
          text: "Zmluva",
          value: "Zmluva",
          sortable: false,
        },
        {
          text: "Vytvorené",
          value: "Created",
          sortable: false,
        },
        {
          text: "Stav",
          value: "StavObdobia",
          sortable: false,
        },
        {
          text: "",
          value: "",
          sortable: false,
        },
      ],
      chosen: "",
    }
  },
}
</script>
