import BaseApiService from "./baseApiService.js"

export default class FormService extends BaseApiService {
  constructor(api) {
    super(
      api,
      "/v1/proto.FormService/GetForm",
      "/v1/proto.FormService/GetForms",
      "/v1/proto.FormService/GetFormTree",
      "/v1/proto.FormService/SaveForm",
      "/v1/proto.FormService/SaveFormData"
    )
  }
}
