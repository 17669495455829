<template>
  <BaseDialog
    :dialogTitle="$vuetify.t('Liek')"
    ref="dialog"
    :showSaveButton="false"
    @close="closeDialog"
  >
    <div class="text-xs-center" v-if="loading">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <v-container v-else grid-list-md>
      <v-layout column wrap>
        <v-text-field
          readonly
          label="Názov"
          v-model="chosen.liek.nazov"
        ></v-text-field>
        <v-text-field
          readonly
          label="Kód ŠÚKL"
          v-model="chosen.liek.kod_sukl"
        ></v-text-field>
        <v-text-field
          readonly
          label="Doplnok Názvu"
          v-model="chosen.liek.doplnok_nazvu"
        ></v-text-field>
        <v-text-field
          readonly
          label="ATC Skupina"
          v-model="this.atcText"
        ></v-text-field>
      </v-layout>
    </v-container>
  </BaseDialog>
</template>

<script>
import BaseDialog from "./../BaseDialog.vue"

export default {
  components: {
    BaseDialog,
  },
  data: function () {
    return {
      loading: false,
      chosen: null,
    }
  },
  methods: {
    init: function () {
      this.chosen = {
        nazov: "",
        liek: {},
      }
    },
    show: function (item) {
      this.chosen = item
      this.$refs.dialog.open()
    },
    closeDialog: function () {
      this.$emit("close", this.chosen)
    },
  },
  computed: {
    atcText: function () {
      return this.chosen.liek.atc + " - " + this.chosen.liek.atc_text
    },
  },
  created: function () {
    this.init()
  },
}
</script>

<style scoped></style>
