<template>
  <v-container fluid>
    <v-layout row wrap>
      <v-flex class="text-xs-right" xs12>
        <v-btn color="primary" flat @click="addItem" align-self-end>{{
          $vuetify.t("Pridať")
        }}</v-btn>
      </v-flex>
      <v-flex xs12 class="mb-3" v-if="itemsPlanned && itemsPlanned.length > 0">
        <ockovania-list
          :items="itemsPlanned"
          title="Plánované očkovania"
          @editItem="editItem"
          @deleteItem="deleteItem"
        />
      </v-flex>
      <v-flex
        xs12
        class="mb-3"
        v-if="itemsVykonane && itemsVykonane.length > 0"
      >
        <ockovania-list
          :items="itemsVykonane"
          title="Vykonané očkovania"
          @editItem="editItem"
          @deleteItem="deleteItem"
          @addReakciaNaOckovanie="addReakciaNaOckovanie"
          @reakcia:edit="editReakciaNaOckovanie"
          @deleteReakciaNaOckovanie="deleteItem"
        />
      </v-flex>
      <v-flex xs12 class="mb-3" v-if="itemsZrusene && itemsZrusene.length > 0">
        <ockovania-list
          :items="itemsZrusene"
          title="Zrušené očkovania"
          @editItem="editItem"
          @deleteItem="deleteItem"
        />
      </v-flex>
    </v-layout>
    <BaseDialog :dialogTitle="$vuetify.t('Očkovanie')" ref="dialog">
      <FormOckovanie
        ref="form"
        :pacientId="id"
        @saved="fetchData"
      ></FormOckovanie>
    </BaseDialog>
    <BaseDialog
      :dialogTitle="$vuetify.t('Reakcia na očkovanie')"
      ref="dialogReakciaNaOckovanie"
    >
      <FormNeziaducaReakcia
        ref="formReakciaNaOckovanie"
        :pacientId="id"
        @saved="fetchData"
      ></FormNeziaducaReakcia>
    </BaseDialog>
  </v-container>
</template>

<script>
import * as Miscella from "../../miscella.js"
import BaseDialog from "./../../components/BaseDialog.vue"
import FormOckovanie from "./../../components/forms/FormOckovanie.vue"
import FormNeziaducaReakcia from "./../../components/forms/FormNeziaducaReakcia.vue"
import OckovaniaList from "./../../components/OckovaniaList.vue"
//mport moment from "moment"
import * as enums from "@/plugins/enums.js"
import thingMixin from "@/components/mixins/thing.js"

export default {
  components: {
    BaseDialog,
    FormOckovanie,
    FormNeziaducaReakcia,
    OckovaniaList,
  },
  mixins: [thingMixin],
  props: ["id"],
  data: function () {
    return {
      loading: true,
      itemsPlanned: [],
      itemsVykonane: [],
      itemsZrusene: [],
    }
  },
  computed: {},
  methods: {
    fetchData: async function () {
      try {
        var data = await this.$api.thingService.listThings({
          patient_id: this.id,
          ockovanie: {},
        })
        this.setItems(data)
      } catch (e) {
        this.processError(e, "Pri načítaní očkovaní vznikla chyba")
      }
    },
    addItem: function () {
      this.$refs.form.new()
    },
    editItem: function ({ ockovanie, readonly }) {
      console.log("OCKOVANIE readonly", readonly)
      this.$refs.form.edit(ockovanie, readonly)
    },
    addReakciaNaOckovanie: function (ockovanie) {
      this.$refs.formReakciaNaOckovanie.new()
      this.$refs.formReakciaNaOckovanie.model.parent_id = ockovanie.id
    },
    editReakciaNaOckovanie: function ({ reakcia, readonly }) {
      console.log("REAKCIA readonly", readonly)
      this.$refs.formReakciaNaOckovanie.edit(reakcia, readonly)
    },
    deleteItem: async function (item) {
      var result = await this._deleteThing(item) // from thing mixin
      if (result) {
        this.fetchData()
      }
    },
    setItems: function (data) {
      // reset items on fetch
      this.itemsPlanned = []
      this.itemsVykonane = []
      this.itemsZrusene = []

      if (!Miscella.isSet(data) || !Miscella.isSet(data.items)) {
        return
      }

      data.items.forEach((item) => {
        if (item.ockovanie.stav === enums.StavOckovania.Planovane) {
          this.itemsPlanned.push(item)
        }
        if (item.ockovanie.stav === enums.StavOckovania.Vykonane) {
          this.itemsVykonane.push(item)
        }
        if (item.ockovanie.stav === enums.StavOckovania.Zrusene) {
          this.itemsZrusene.push(item)
        }
      })

      this.sortItems()
    },
    sortItems: function () {
      function compare(a, b) {
        let aDatum
        let bDatum
        if (a.ockovanie.stav === enums.StavOckovania.Planovane) {
          aDatum = a.ockovanie.datum_plan
          bDatum = b.ockovanie.datum_plan
        }
        if (a.ockovanie.stav === enums.StavOckovania.Vykonane) {
          aDatum = a.ockovanie.datum_vykonane
          bDatum = b.ockovanie.datum_vykonane
        }
        if (a.ockovanie.stav === enums.StavOckovania.Zrusene) {
          aDatum = a.ockovanie.datum_plan
          bDatum = b.ockovanie.datum_plan
        }
        if (aDatum < bDatum) {
          return -1
        } else if (aDatum > bDatum) {
          return 1
        }
        return 0
      }
      this.itemsPlanned.sort(compare).reverse()
      this.itemsVykonane.sort(compare).reverse()
      this.itemsZrusene.sort(compare).reverse()
    },
  },
  mounted: function () {
    this.fetchData()
  },
}
</script>
