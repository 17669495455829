export default class Http {
  static toUpper(str) {
    return str ? str.toUpperCase() : ""
  }

  static request(method, aUrl, params, { headers = {}, ...rest } = {}) {
    var url = new URL(aUrl, window.location.origin)
    var init = Object.assign(rest, {
      method: Http.toUpper(method || "GET"),
      headers: new Headers(headers),
    })
    if (init.method === "GET" || init.method === "HEAD") {
      if (params !== undefined && params !== null) {
        Object.keys(params).forEach((key) =>
          url.searchParams.append(key, params[key])
        )
      }
    } else {
      if (!init.headers.has("Content-Type")) {
        init.headers.append("Content-Type", "application/json")
      }
      if (init.headers.get("Content-Type") === "application/json") {
        init.body = JSON.stringify(params)
      } else {
        init.body = params
      }
    }
    // console.log('HTTP.JS', url, init, init.headers)
    return fetch(new Request(url, init)).then((res) => {
      if (res.ok) {
        return res
      }
      console.log(
        "HTTP.JS ERROR RESPONSE: ",
        res,
        ", FOR url: ",
        aUrl,
        ", params: ",
        params
      )
      return Promise.reject(res)
    })
  }

  static get(aUrl, params, options = {}) {
    return this.request("GET", aUrl, params, options)
  }

  static post(aUrl, params, options = {}) {
    return this.request("POST", aUrl, params, options)
  }
}
