<template>
  <v-flex grow>
    <v-calendar
      ref="calendar"
      v-model="eventDate"
      :type="type"
      color="primary"
      locale="sk"
      :first-interval="range[0]"
      :interval-count="range[1] - range[0]"
      :interval-format="intervalFormat"
      :weekdays="[1, 2, 3, 4, 5, 6, 0]"
      @click:day="addEvent"
      @click:time="addEvent"
    >
      <template v-slot:day="{ date }">
        <calendar-event
          id="newEvent"
          v-if="`${type}-${date}` === activeEventID"
          :event="$refs.formEvent.event"
          :active="true"
        />
        <template
          v-for="event in eventsMap[date] ? eventsMap[date].slice(0, 3) : []"
        >
          <calendar-event
            :id="event.ID"
            :key="event.ID"
            :event="event"
            @click.stop="editEvent(event)"
            @click:remove="removeEvent"
            :active="activeEventID === event.ID"
          />
        </template>
        <v-menu
          v-if="
            eventsMap[date] &&
            eventsMap[date].length > 3 &&
            `${type}-${date}` !== activeEventID
          "
          v-model="morePopup[date]"
          bottom
          offset-y
          :close-on-content-click="false"
          :close-on-click="false"
        >
          <template v-slot:activator="{ on }">
            <v-btn block small flat v-on="on" @click.stop class="event ma-0">
              <span class="caption text-lowercase">{{ moreLabel(date) }}</span>
            </v-btn>
          </template>
          <v-card width="20vw">
            <v-card-title class="text-xs-center">
              <span class="headline">{{ morePopupDateLabel(date) }}</span>
            </v-card-title>
            <v-card-text>
              <template v-for="(event, index) in eventsMap[date]">
                <calendar-event
                  v-if="index >= 3"
                  :id="event.ID"
                  :key="event.ID"
                  :event="event"
                  @click.stop="editEvent(event)"
                  @click:remove="removeEvent"
                  :active="activeEventID === event.ID"
                />
              </template>
            </v-card-text>
            <v-btn
              flat
              icon
              small
              @click.stop="morePopup[date] = false"
              style="position: absolute; right: 0px; top: 0px"
              ><v-icon>close</v-icon></v-btn
            >
          </v-card>
        </v-menu>
      </template>
      <template v-slot:dayBody="{ date, timeToY, minutesToPixels }">
        <calendar-event
          id="newEvent"
          v-if="`${type}-${date}` === activeEventID"
          :event="$refs.formEvent.event"
          :active="true"
          :height="minutesToPixels($refs.formEvent.event.Duration) + 'px'"
          :position="timeToY($refs.formEvent.event.CasOd) + 'px'"
        />
        <template v-for="event in eventsMap[date]">
          <calendar-event
            :id="event.ID"
            :key="event.ID"
            :event="event"
            :height="minutesToPixels(event.Duration) + 'px'"
            :position="timeToY(event.CasOd) + 'px'"
            @click.stop="editEvent(event)"
            @click:remove="removeEvent"
            :active="activeEventID === event.ID"
          />
        </template>
      </template>
    </v-calendar>
    <v-menu
      v-model="popupActive"
      absolute
      :position-x="popupPositionX"
      :position-y="popupPositionY"
      :close-on-content-click="false"
      :close-on-click="true"
    >
      <v-card
        :style="{
          width: eventPopupWidth + 'px',
          'max-width': eventPopupWidth + 'px',
        }"
      >
        <v-tooltip bottom style="position: absolute; right: 4px; top: 4px">
          <v-icon slot="activator" @click.stop="popupActive = false"
            >close</v-icon
          >
          <span>{{ $vuetify.t("Zavrieť") }}</span>
        </v-tooltip>
        <v-card-text class="py-1 px-3">
          <form-event
            ref="formEvent"
            :pacientId="pacientId"
            :pacientName="pacientName"
            @saved="
              popupActive = false
              fetchData()
            "
          />
        </v-card-text>
        <v-card-actions class="pt-0 pb-1">
          <v-spacer></v-spacer>
          <v-btn flat @click.native="popupActive = false">
            {{ $vuetify.t("$vuetify.app.button.close") }}
          </v-btn>
          <v-btn color="primary" flat @click="$refs.formEvent.save()">{{
            $vuetify.t("Uložiť")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </v-flex>
</template>

<script>
import FormEvent from "./forms/FormEvent.vue"
import CalendarEvent from "./CalendarEvent.vue"
import * as enums from "@/plugins/enums.js"
import moment from "moment"
import * as Miscella from "./../miscella.js"
import calendarMixin from "./mixins/calendar.js"

const DATE_FORMAT = "YYYY-MM-DD"
const CURRENT_DATE_LABEL_FORMAT = "MMMM YYYY"
const TIME_FORMAT = "HH:mm"

export default {
  name: "calendar",
  mixins: [calendarMixin],
  components: { FormEvent, CalendarEvent },
  props: {
    value: {
      type: String,
      required: false,
      default: moment().format(DATE_FORMAT),
    },
    type: {
      type: String,
      required: false,
      default: "month",
    },
    pacientId: {
      type: String,
      required: false,
    },
    pacientName: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      eventDate: moment().format(DATE_FORMAT),
      navigation: true,
      events: null,
      morePopup: {},
      popupActive: false,
      popupPositionX: 0,
      popupPositionY: 0,
      activeEventID: "",
    }
  },
  computed: {
    currentDateLabel: function () {
      return moment(this.eventDate).format(CURRENT_DATE_LABEL_FORMAT)
    },
    eventsMap() {
      const map = {}
      if (Miscella.isSet(this.events)) {
        this.events.forEach((e) => (map[e.Datum] = map[e.Datum] || []).push(e))
        for (let datum in map) {
          map[datum].sort((a, b) => {
            return moment(a.CasOd, TIME_FORMAT, true).isBefore(
              moment(b.CasOd, TIME_FORMAT, true)
            )
              ? -1
              : 1
          })
        }
      }
      return map
    },
    range: function () {
      return this.$store.state.calendar.range
    },
  },
  watch: {
    value: function (val) {
      if (val && val !== this.eventDate) {
        this.eventDate = moment(val).format(DATE_FORMAT)
      }
    },
    eventDate: function () {
      this.fetchData()
    },
    popupActive: function (val) {
      if (val === false) {
        this.activeEventID = ""
      }
    },
  },
  methods: {
    dayFormat: function (value) {
      var d = moment(value, DATE_FORMAT)
      return d.date()
    },
    intervalFormat: function (interval) {
      return interval.time
    },
    morePopupDateLabel: function (date) {
      return moment(date, DATE_FORMAT).format("ddd Do")
    },
    moreLabel: function (date) {
      var moreCount = this.eventsMap[date].length - 3
      var moreWord = "ďalších"
      switch (moreCount) {
        case 1:
          moreWord = "ďalšia"
          break
        case 2:
        case 3:
        case 4:
          moreWord = "ďalšie"
          break
      }
      return `${moreCount} ${this.$vuetify.t(moreWord)}...`
    },
    eventColor: function (date) {
      let arr = []
      if (this.eventsMap[date]) {
        for (let event of this.eventsMap[date]) {
          if (event.Typ === "OBJEDNANIE_ORDERS") {
            arr.push(
              this.getColorOfEventDay(enums.TypEventu.TypEventuObjednanieOrders)
            )
          } else if (!Miscella.isEmpty(event.PatientId)) {
            arr.push(
              this.getColorOfEventDay(enums.TypEventu.TypEventuObjednanie)
            )
          } else {
            arr.push(this.getColorOfEventDay(enums.TypEventu.TypEventuKalendar))
          }
        }
      }
      // vrati pole s bez duplicit
      return Array.from(new Set(arr))
    },
    fetchData: async function () {
      let params = {
        datumOd: moment(this.eventDate, DATE_FORMAT)
          .startOf("month")
          .format(DATE_FORMAT),
        datumDo: moment(this.eventDate, DATE_FORMAT)
          .endOf("month")
          .format(DATE_FORMAT),
      }
      this.events = await this.$api.get("/api/ambulance/events", params)
    },
    setPopupPositionByElementID: function (elementID) {
      var el = document.getElementById(elementID)
      var rect = el.getBoundingClientRect()
      this.popupPositionY = Math.round(rect.top)
      var x = Math.round(rect.right) + 2
      if (this.viewportWidth - x > this.eventPopupWidth) {
        this.popupPositionX = x
      } else {
        this.popupPositionX = Math.round(rect.left) - 2 - this.eventPopupWidth
      }
    },
    addEvent: function (dateTime) {
      if (moment(this.eventDate).month() + 1 === dateTime.month) {
        this.activeEventID = `${this.type}-${dateTime.date}`
        var time = `${moment(dateTime.hour, "H", true).format("HH")}:${
          dateTime.minute > 30 ? "30" : "00"
        }`
        this.$refs.formEvent.new(dateTime.date, time)
        this.$nextTick(() => {
          setTimeout(() => {
            this.setPopupPositionByElementID("newEvent")
            this.popupActive = true
          }, 0)
        })
      }
    },
    editEvent: function (aEvent) {
      if (aEvent.Typ !== enums.TypEventu.TypEventuObjednanieOrders) {
        this.activeEventID = aEvent.ID
        this.$refs.formEvent.edit(aEvent)
        this.setPopupPositionByElementID(aEvent.ID)
        this.popupActive = true
      }
    },
    removeEvent: function (eventId) {
      return this.$confirm(
        this.$vuetify.t("Naozaj chcete odstrániť udalosť?")
      ).then((res) => {
        if (res) {
          return this.$api.delete("/api/event/" + eventId).then(() => {
            this.fetchData()
          })
        }
      })
    },
    prev: function () {
      this.$refs.calendar.prev()
    },
    next: function () {
      this.$refs.calendar.next()
    },
  },
  mounted: function () {
    this.fetchData()
  },
}
</script>
