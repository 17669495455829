<template>
  <v-form>
    <v-container fluid>
      <v-layout row wrap>
        <v-flex xs12 md6>
          <DatePicker
            v-model="model.date"
            :label="$vuetify.t('Dátum implantácie')"
            name="datum"
            v-validate="'required'"
          ></DatePicker>
        </v-flex>
        <v-flex xs12 md6>
          <codebook-autocomplete
            codebook-id="126"
            name="druh"
            v-model="model.zdravotnicka_pomocka.druh"
            :label="$vuetify.t('Druh')"
            v-validate="'required'"
            :limit="0"
            :hint="
              $vuetify.t(
                'Ak nie je možné dohľadať druh z číselníka, zadajte \'neznáma hodnota\' a vyplňte políčko Popis'
              )
            "
          >
          </codebook-autocomplete>
        </v-flex>
        <v-flex xs12>
          <v-textarea
            v-model="model.zdravotnicka_pomocka.popis"
            :label="$vuetify.t('Popis')"
            v-validate="'max:512'"
            data-vv-name="popis"
            :data-vv-as="$vuetify.t('Popis')"
            auto-grow
            rows="2"
            :counter="512"
          ></v-textarea>
        </v-flex>
        <v-flex xs12>
          <codebook-autocomplete
            codebook-id="100002"
            name="obchodny_nazov"
            v-model="model.zdravotnicka_pomocka.obchodny_nazov"
            :label="$vuetify.t('Obchodný názov')"
          >
            <template v-slot:selection="{ item }"
              ><span
                >{{ item.zdravotnicka_pomocka.kod }} -
                {{ item.display_name }}</span
              ></template
            >
            <template v-slot:item="{ item }">
              <v-list-tile-content>
                <v-list-tile-title
                  v-html="item.zdravotnicka_pomocka.kod"
                ></v-list-tile-title>
                <v-list-tile-sub-title
                  v-html="item.display_name"
                ></v-list-tile-sub-title>
              </v-list-tile-content>
            </template>
          </codebook-autocomplete>
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
import BaseForm from "./../BaseForm.vue"
import * as Miscella from "./../../miscella.js"
import DatePicker from "./../DatePicker.vue"
import moment from "moment"
import CodebookAutocomplete from "./../ciselnikComponents/CodebookAutocomplete.vue"

export default {
  name: "FormOdporucanie",
  extends: BaseForm,
  $_veeValidate: {
    validator: "new",
  },
  components: {
    DatePicker,
    CodebookAutocomplete,
  },
  props: {
    pacientId: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      model: null,
    }
  },
  computed: {
    ambulanceId: function () {
      let id = null
      if (Miscella.isSet(this.$store.state.me.ambulance)) {
        id = this.$store.state.me.ambulance.id
      }
      return id
    },
  },
  methods: {
    init: function () {
      this.model = {
        id: "",
        patient_id: this.pacientId,
        ambulance_id: this.ambulanceId,
        date: moment().format(),
        parent_id: "",
        zdravotnicka_pomocka: {
          druh: null,
          popis: "",
          obchodny_nazov: null,
        },
      }
      this.$validator.reset()
    },
    new: function () {
      this.init()
      this.$emit("activated")
    },
    edit: function (item) {
      this.init()
      this.model = Miscella.cloneObject(item)
      this.$emit("activated")
    },
    validate: function () {
      return this.$validator.validateAll()
    },
    save: async function () {
      try {
        var params = { thing: Miscella.cloneObject(this.model) }
        var result = await this.$api.thingService.saveThing(params)
        this.notifySuccess("Zdravotnícka pomôcka bola úspešne uložená")
        this.$emit("saved", result.thing)
        this.model = result.thing
      } catch (err) {
        this.processError(
          err,
          "Pri ukladaní zdravotníckej pomôcky vznikla chyba"
        )
      }
    },
  },
  created: function () {
    this.init()
  },
}
</script>
