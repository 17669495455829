<template>
  <v-form>
    <v-container fluid>
      <v-layout row wrap>
        <v-flex xs12>
          <DatePicker
            v-model="model.obdobie"
            :label="$vuetify.t('Obdobie')"
            name="obdobie"
            type="month"
            format="YYYYMM"
            displayFormat="MMM YYYY"
            v-validate="'required'"
          ></DatePicker>
        </v-flex>
        <v-flex xs12>
          <v-select
            v-model="model.poistovna"
            :label="$vuetify.t('Poisťovňa')"
            :items="poistovne"
            item-value="Kod"
            return-object
            v-validate="'required'"
            data-vv-name="poistovna"
            :data-vv-as="$vuetify.t('Poisťovňa')"
            :error-messages="errors.collect('poistovna')"
          >
            <template slot="selection" slot-scope="{ item }"
              ><span
                >{{ item.KodSkrateny }} -
                {{ $vuetify.t("$vuetify.enum.poistovna." + item.Kod) }}</span
              ></template
            >
            <template slot="item" slot-scope="{ item }">
              <v-list-tile-content>
                <span
                  >{{ item.KodSkrateny }} -
                  {{ $vuetify.t("$vuetify.enum.poistovna." + item.Kod) }}</span
                >
              </v-list-tile-content>
            </template>
          </v-select>
        </v-flex>
        <v-flex xs12>
          <v-subheader>
            {{ $vuetify.t("Kapitácie") }}
          </v-subheader>
          <v-divider></v-divider>
        </v-flex>
        <v-flex xs4>
          <v-checkbox
            v-model="model.pocet"
            :label="$vuetify.t('Počet')"
          ></v-checkbox>
        </v-flex>
        <v-flex xs4>
          <v-checkbox
            v-model="model.prirastky"
            :label="$vuetify.t('Prírastky')"
          ></v-checkbox>
        </v-flex>
        <v-flex xs4>
          <v-checkbox
            v-model="model.ubytky"
            :label="$vuetify.t('Úbytky')"
          ></v-checkbox>
        </v-flex>
        <v-flex xs12>
          <v-subheader>
            {{ $vuetify.t("Výkony") }}
          </v-subheader>
          <v-divider></v-divider>
        </v-flex>

        <template v-for="(typPoistenca, index) in vsetkyTypyPoistenca">
          <v-flex
            v-if="isTypPoistencaVisible(typPoistenca)"
            xs6
            :key="'typ' + index"
          >
            <v-checkbox
              v-model="model.typyPoistenca"
              :value="typPoistenca"
              :label="
                $vuetify.t('$vuetify.enum.typPoistencaDoklad.' + typPoistenca)
              "
            ></v-checkbox>
          </v-flex>
        </template>
      </v-layout>
    </v-container>
  </v-form>
</template>
<script>
import * as Miscella from "../../miscella.js"
import moment from "moment"
import DatePicker from "./../DatePicker.vue"

export default {
  $_veeValidate: {
    validator: "new",
  },
  components: { DatePicker },
  mixins: [],
  data: function () {
    return {
      model: null,
      poistovne: [],
      charakteryDavky: ["NOVA", "OPRAVNA", "ADITIVNA"],
      formAction: "",
      loading: true,
      vsetkyTypyPoistenca: ["SR", "EU", "CU", "BE"],
    }
  },

  computed: {
    modalTitle: function () {
      return this.$vuetify.t("page.poistovne.export.davok." + this.formAction)
    },
    saveDavkaUrl: function () {
      return "api/poistovne/saveDavka"
    },
    isVsZP: function () {
      return (
        Miscella.isSet(this.model.poistovna) &&
        this.model.poistovna.KodSkrateny === "25"
      )
    },
    poistovnaLabel: function (option) {
      return (
        option.KodSkrateny +
        " - " +
        this.$vuetify.t("$vuetify.enum.poistovna." + option.Kod)
      )
    },
    charakterDavkyLabel: function (option) {
      return this.$vuetify.t("$vuetify.enum.charakterDavky." + option)
    },
  },
  methods: {
    isTypPoistencaVisible: function (typ) {
      return this.isVsZP || (typ !== "CU" && typ !== "BE")
    },
    init: function () {
      this.model = {
        obdobie: moment().format("YYYYMM"),
        poistovna: "",
        typyPoistenca: ["SR"],
        charakteryDavky: { SR: "NOVA", EU: "NOVA", CU: "NOVA", BE: "NOVA" },
        pocet: false,
        prirastky: false,
        ubytky: false,
        protokol: false,
      }
      this.$validator.reset()
    },
    newDavka: function () {
      this.formAction = "new"
      this.init()
      this.fetchData()
    },
    fetchData: function () {
      this.fetchPoistovne()
    },
    fetchPoistovne: async function () {
      this.poistovne = await this.$api.get("/api/poistovnaZmluvy/platne", null)
    },

    saveForm: function () {
      this.$validator.validateAll().then(
        function () {
          console.log("Custom validation")
          // miesto na dalsie custom validacie
          let isOK = true
          let now = Number(moment().format("YYYYMM"))
          let obdobie = Number(this.model.obdobie)
          if (now < obdobie) {
            this.errors.add({
              field: "obdobie",
              msg: this.$vuetify.t(
                "Dávky je možné vytvárať iba za predchádzajúce obdobia."
              ),
            })
          }
          if (this.errors.any() || !isOK) {
            return
          }
          this.save()
        }.bind(this),
        function () {
          console.log("errors", this.errors)
        }.bind(this)
      )
    },
    save: function () {
      var params = {
        Obdobie: this.model.obdobie,
        KodZP:
          this.model.poistovna.KodSkrateny + this.model.poistovna.KodPobocky,
        TypyPoistenca: this.model.typyPoistenca,
        Pocet: this.model.pocet,
        Prirastky: this.model.prirastky,
        Ubytky: this.model.ubytky,
        Protokol: this.model.protokol,
        AmbulanciaID: localStorage.getItem("ambId"),
        Poistovna: this.model.poistovna,
      }
      if (!this.isVsZP) {
        params.TypyPoistenca = this.model.typyPoistenca.filter(
          (typ) => typ !== "CU" && typ !== "BE"
        )
      }
      this.$api
        .post(this.saveDavkaUrl, params)
        .then(this.saveOK)
        .catch(this.saveErr)
    },
    saveOK: function () {
      this.$store.commit(
        "setSuccess",
        this.$vuetify.t("Dávka bola úspešne vytvorená")
      )
      this.$emit("saved", this.model)
    },
    saveErr: function () {
      this.$store.commit(
        "setError",
        this.$vuetify.t("Pri vytváraní dávky vznikla chyba.")
      )
    },
  },
  created: function () {
    this.init()
  },
}
</script>
