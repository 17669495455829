<template>
  <v-container fluid grid-list-xs class="pa-0">
    <v-layout row wrap>
      <v-flex xs12>
        <titled-text
          title="Anamnéza"
          :text="vysetrenie.Anamneza"
          v-if="vysetrenie.Anamneza !== null && vysetrenie.Anamneza !== ''"
        />
      </v-flex>
      <v-flex xs12>
        <titled-text
          title="Výmenný lístok"
          :text="vysetrenie.Odporucanie"
          v-if="
            vysetrenie.Odporucanie !== null && vysetrenie.Odporucanie !== ''
          "
        />
      </v-flex>
      <v-flex xs12>
        <titled-text
          title="Dispenzarizácia"
          :text="$vuetify.t(vysetrenie.Dispenzarizacia ? 'Áno' : 'Nie')"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import TitledText from "./TitledText.vue"

export default {
  props: {
    vysetrenie: {
      type: Object,
      required: true,
    },
  },
  components: {
    TitledText,
  },
  data: function () {
    return {
      tab: 0,
    }
  },
}
</script>
