<template>
  <v-layout row wrap>
    <slot name="toolbar" v-if="showToolbar">
      <v-flex class="text-xs-right" xs12>
        <v-btn color="primary" flat @click="addItem" align-self-end>{{
          $vuetify.t("Pridať")
        }}</v-btn>
      </v-flex>
    </slot>
    <v-flex xs12 class="mb-3">
      <v-card>
        <v-list two-line subheader dense>
          <v-subheader v-if="title">{{ title }}</v-subheader>
          <template v-for="(item, index) in items">
            <v-list-tile :key="'list' + index" @click="editItem(item)">
              <v-list-tile-content>
                <slot name="items" :item="item"> </slot>
              </v-list-tile-content>
              <slot name="actions" :item="item">
                <v-list-tile-action
                  :key="'action' + index"
                  d-flex
                  style="
                    flex-direction: row;
                    justify-content: flex-end;
                    align-items: center;
                    width: 66px;
                  "
                >
                  <template v-for="(action, index) in actions">
                    <v-tooltip :key="'action' + index" left>
                      <v-icon
                        slot="activator"
                        @click.stop="action.handler(item)"
                        >{{ action.icon }}</v-icon
                      >
                      <span>{{ action.text }}</span>
                    </v-tooltip>
                  </template>
                </v-list-tile-action>
              </slot>
            </v-list-tile>
            <v-divider
              :key="'divider' + index"
              v-if="index !== items.length - 1"
            ></v-divider>
          </template>
        </v-list>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import * as Miscella from "../miscella.js"
import Vue from "vue"

export default {
  props: {
    responseItems: {
      type: String,
      required: false,
      default: "items",
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
    showToolbar: {
      type: Boolean,
      required: false,
      default: true,
    },
    url: {
      type: String,
      required: true,
    },
    customParams: {
      type: Object,
      required: false,
      default: function () {
        return {}
      },
    },
    /* Example of action object:
      {
        text: 'Delete',
        icon: 'delete',
        handler: 'onDelete'
      }
     */
    actions: {
      type: Array,
      required: false,
      default: function () {
        return []
      },
    },
  },
  data: function () {
    return {
      loading: false,
      items: [],
    }
  },
  methods: {
    reload: function () {
      this.loading = true
      return this.$api
        .post(this.url, this.customParams)
        .then(this.setItems)
        .catch((error) => {
          console.log("xErr:", error)
        })
        .finally(() => {
          this.$emit("reloaded")
          Vue.nextTick(() => {
            this.loading = false
          })
        })
    },
    setItems: function (data) {
      this.items = data[this.responseItems]
    },
    addItem: function () {
      this.$emit("addItem")
    },
    editItem: function (item) {
      this.$emit("editItem", item)
    },
  },
  mounted: function () {
    this.reload()
  },
}
</script>
