<template>
  <div>
    <div :ref="'limitedContainer_' + id" v-bind:style="classObject">
      <div :ref="'innerLimitedContainer_' + id">
        <slot></slot>
      </div>
    </div>
    <span class="showAll" :ref="'showAll_' + id" @click="showAll">
      Zobraziť celý text
    </span>
  </div>
</template>

<script>
import * as Miscella from "../miscella.js"

export default {
  name: "limitedHeight",
  components: {},
  data: function () {
    return {}
  },
  props: {
    id: {
      type: String,
      required: false,
      default: Miscella.uuid(),
    },
    maxHeight: {
      type: String,
      required: false,
      default: 400 + "px",
    },
  },
  computed: {
    classObject: function () {
      return {
        "max-height": this.maxHeight,
        overflow: "hidden",
      }
    },
    isLimited: function () {
      let el = this.$refs["limitedContainer_" + this.id]
      let iel = this.$refs["innerLimitedContainer_" + this.id]
      let diff = iel.clientHeight - el.clientHeight
      //console.log("LimitedHeight.vue, isLimited", diff)
      return diff > 0
    },
  },
  watch: {},
  methods: {
    showAll: function () {
      let el = this.$refs["limitedContainer_" + this.id]
      el.style.maxHeight = "none"
      let span = this.$refs["showAll_" + this.id]
      span.style.display = "none"
    },
  },
  mounted: function () {
    let span = this.$refs["showAll_" + this.id]
    if (!this.isLimited) {
      span.style.display = "none"
    }
  },
}
</script>
<style scoped>
span.showAll {
  font-size: small;
  text-decoration: underline;
  position: absolute;
  right: 5px;
  bottom: 1px;
  cursor: pointer;
}
</style>
