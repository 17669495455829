var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('ApiList',{ref:"listAktivne",attrs:{"url":_vm.url,"customParams":_vm.getParams(true),"title":_vm.$vuetify.t('Aktívne diagnózy'),"actions":[
      {
        text: _vm.$vuetify.t('Ukončiť diagnózu'),
        icon: 'update',
        handler: _vm.endDiagnoza,
      },
      {
        text: _vm.$vuetify.t('Odstrániť diagnózu'),
        icon: 'delete',
        handler: _vm.deleteDiagnoza,
      },
    ]},on:{"addItem":_vm.addDiagnoza,"editItem":_vm.editDiagnoza},scopedSlots:_vm._u([{key:"items",fn:function({ item }){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"xs2":""}},[_c('v-tooltip',{attrs:{"top":""}},[_c('span',{attrs:{"slot":"activator"},slot:"activator"},[_vm._v(_vm._s(_vm._f("formatDatum")(item.date)))]),_c('span',[_vm._v(_vm._s(_vm.$vuetify.t("Dátum")))])])],1),_c('v-flex',{attrs:{"xs2":""}},[_c('v-tooltip',{attrs:{"top":""}},[_c('span',{attrs:{"slot":"activator"},slot:"activator"},[_vm._v(_vm._s(item.diagnoza.diagnoza.skratka))]),_c('span',[_vm._v(_vm._s(_vm.$vuetify.t("Skratka")))])])],1),_c('v-flex',{attrs:{"xs8":""}},[_c('v-tooltip',{attrs:{"top":""}},[_c('span',{attrs:{"slot":"activator"},slot:"activator"},[_vm._v(_vm._s(item.diagnoza.diagnoza.display_name)),_c('br'),_vm._v(_vm._s(item.diagnoza.poznamka))]),_c('span',[_vm._v(_vm._s(_vm.$vuetify.t("Popis")))])])],1)],1)],1)]}}])}),_c('ApiList',{ref:"listNeaktivne",attrs:{"showToolbar":false,"url":_vm.url,"customParams":_vm.getParams(false),"title":_vm.$vuetify.t('Neaktívne diagnózy'),"actions":[
      {
        text: _vm.$vuetify.t('Odstrániť diagnózu'),
        icon: 'delete',
        handler: _vm.deleteDiagnoza,
      },
    ]},on:{"addItem":_vm.addDiagnoza,"editItem":_vm.editDiagnoza},scopedSlots:_vm._u([{key:"items",fn:function({ item }){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"xs2":""}},[_c('v-tooltip',{attrs:{"top":""}},[(item.diagnoza !== null)?_c('span',{attrs:{"slot":"activator"},slot:"activator"},[_vm._v(_vm._s(_vm._f("formatDatum")(item.date))+" - "+_vm._s(_vm._f("formatDatum")(item.diagnoza.datum_ukoncenia)))]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.$vuetify.t("Dátum od do")))])])],1),_c('v-flex',{attrs:{"xs2":""}},[_c('v-tooltip',{attrs:{"top":""}},[_c('span',{attrs:{"slot":"activator"},slot:"activator"},[_vm._v(_vm._s(item.diagnoza.diagnoza.skratka))]),_c('span',[_vm._v(_vm._s(_vm.$vuetify.t("Skratka")))])])],1),_c('v-flex',{attrs:{"xs8":""}},[_c('v-tooltip',{attrs:{"top":""}},[_c('span',{attrs:{"slot":"activator"},slot:"activator"},[_vm._v(_vm._s(item.diagnoza.diagnoza.display_name)),_c('br'),_vm._v(_vm._s(item.diagnoza.poznamka))]),_c('span',[_vm._v(_vm._s(_vm.$vuetify.t("Popis")))])])],1)],1)],1)]}}])}),_c('BaseDialog',{ref:"diagnozaDialog",attrs:{"dialogTitle":_vm.$vuetify.t(_vm.diagnozaDialogTitle)},on:{"save":function($event){return _vm.$refs.diagnozaForm.save()}}},[_c('form-diagnoza',{ref:"diagnozaForm",attrs:{"pacientId":_vm.id},on:{"saved":_vm.savedDiagnoza}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }