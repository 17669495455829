import BaseApiService from "./baseApiService.js"

export default class EventService extends BaseApiService {
  constructor(api) {
    super(
      api,
      "/v1/proto.EventService/CreateInstance",
      "/v1/proto.EventService/Subscribe",
      "/v1/proto.EventService/Unsubscribe",
      "/v1/proto.EventService/Listen"
    )
  }
}
