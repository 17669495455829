import * as Miscella from "./miscella.js"

export default {
  state: () => ({
    queue: null,
    fetching: false,
    instanceId: "",
    ambulanceTopics: [],
    ehealthTopics: [],
  }),
  mutations: {
    addEvent(state, value) {
      console.log("added event: ", JSON.stringify(value))
      state.queue = value
    },
    setInstanceId(state, value) {
      state.instanceId = value
    },
    setFetching(state, value) {
      state.fetching = value
    },
    setAmbulanceTopics(state, ambId) {
      state.ambulanceTopics = []
      if (!Miscella.isEmpty(ambId)) {
        state.ambulanceTopics = [
          `${ambId}:chat:messageSent`,
          `${ambId}:chat:chatUpdated`,
          `${ambId}:ambulanceUpdated`,
          `${ambId}:thingUpdated`,
        ]
      }
    },
    setEhealthTopics(state, userId) {
      if (!Miscella.isEmpty(userId)) {
        state.ehealthTopics = [
          `${userId}:ehealth:error`,
          `${userId}:ehealth:cardStatus`,
          `${userId}:ehealth:asyncQueue:sentItem`,
          `${userId}:ehealth:ehealthRecord:created`,
          `${userId}:ehealth:ehealthRecord:changed`,
          `${userId}:ehealth:patientJruzIDReceived`,
          `${userId}:ehealth:patientInfoReceived`,
          `${userId}:ehealth:ehresult`,
        ]
      }
    },
  },
  getters: {
    getUserEventTopic: (state, getters, rootState) => (topic) => {
      return `${rootState.me.user.id}:${topic}`
    },
    getAmbulanceEventTopic: (state, getters, rootState) => (topic) => {
      return `${rootState.me.ambulance.id}:${topic}`
    },
  },
  actions: {
    async updateAmbulanceEvents({ commit, state, rootState }, api) {
      try {
        await api.eventService.unsubscribe({
          instance_id: state.instanceId,
          topics: state.ambulanceTopics,
        })
        commit("setAmbulanceTopics", rootState.me.ambulance?.id)
        await api.eventService.subscribe({
          instance_id: state.instanceId,
          topics: state.ambulanceTopics,
        })
      } catch (e) {
        console.log("Error updating ambulance events: ", e)
      }
    },
    async fetchEvents({ commit, state, getters, dispatch, rootState }, api) {
      console.log("Starting fetchEvents with instanceId: ", state.instanceId)
      if (state.fetching !== true) {
        commit("setFetching", true)
        try {
          if (Miscella.isEmpty(state.instanceId)) {
            let instance = await api.eventService.createInstance({})
            commit("setInstanceId", instance.instance_id)
            if (Miscella.isSet(rootState.me.ambulance)) {
              commit("setAmbulanceTopics", rootState.me.ambulance.id)
            }

            // TODO pocuvat iba ked je ehealth enabled?
            commit("setEhealthTopics", rootState.me.user.id)

            let topics = []
            topics.push(...state.ehealthTopics, ...state.ambulanceTopics)
            await api.eventService.subscribe({
              instance_id: state.instanceId,
              topics: topics,
            })
          }
          //commit("setAmbulanceEventListenController", new AbortController())
          //const signal = state.ambulanceEventListenController.signal
          let evt = await api.eventService.listen(
            {
              instance_id: state.instanceId,
            }
            //{ signal }
          )
          if (!Miscella.isEmpty(evt.data)) {
            evt.data = JSON.parse(evt.data)
          }
          commit("addEvent", evt)
          console.log("received event: ", JSON.stringify(evt))

          // TODO toto spracovanie ehealth eventov upratat inam?
          if (evt.event === getters.getUserEventTopic("ehealth:cardStatus")) {
            commit("setEhealth", { status: evt.data })
          }
          if (
            evt.event ===
            getters.getUserEventTopic("ehealth:patientInfoReceived")
          ) {
            if (
              Miscella.isSet(rootState.pacient) &&
              rootState.pacient.id === evt.data.patient_id
            ) {
              commit("setPacientInfo", evt.data)
            }
          }
          if (
            evt.event ===
            getters.getUserEventTopic("ehealth:patientJruzIDReceived")
          ) {
            if (
              Miscella.isSet(rootState.pacient) &&
              rootState.pacient.id === evt.data.patient_id
            ) {
              commit("setPacientJruzID", evt.data.jruz_id)
            }
          }
          if (evt.event === getters.getAmbulanceEventTopic("thingUpdated")) {
            commit("setThing", evt.data)
          }
          //pouzite napr. pri zmene v cakarni
          if (
            evt.event === getters.getAmbulanceEventTopic("ambulanceUpdated")
          ) {
            commit("setAmbulance", evt.data)
          }
          commit("setFetching", false)
          await dispatch("fetchEvents", api) // reconnect
        } catch (e) {
          if (e && e.status && (e.status === 408 || e.status === 502)) {
            console.log("Events listen timeout, reconnecting ...", e)
            commit("setFetching", false)
            await dispatch("fetchEvents", api) // reconnect
          } else {
            commit("setInstanceId", "")
            commit("setFetching", false)
            console.log("Error fetching events: ", e)
            return
          }
        }
      } else {
        console.log("FetchEvents in progress, duplicate call suspended.")
      }
    },
  },
}
