<template>
  <v-container fluid px-0>
    <v-layout row>
      <v-flex xs6>
        <v-tooltip top>
          <span slot="activator">{{ restrikcia.date | formatDatum }}</span>
          <span>{{ $vuetify.t("Dátum platnosti od") }}</span>
        </v-tooltip>
      </v-flex>
      <v-flex xs6>
        <v-tooltip top>
          <span slot="activator">{{
            restrikcia.restrikcia.datum_ukoncenia | formatDatum
          }}</span>
          <span>{{ $vuetify.t("Dátum platnosti od") }}</span>
        </v-tooltip>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  name: "RestrikciaRow",
  props: {
    restrikcia: {
      type: Object,
      required: true,
    },
  },
}
</script>
