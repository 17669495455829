export var mixins = {
  methods: {
    downloadSubor: function (fileName, aUrl) {
      console.log("downloadSubor", fileName, aUrl)
      this.$api.storageService
        .storageGet(aUrl)
        .then((blob) => {
          console.log("downloadSubor", blob)
          let objectUrl = window.URL.createObjectURL(blob)
          console.log("downloadSubor", objectUrl)
          var anchor = document.createElement("a")
          anchor.href = objectUrl
          anchor.download = fileName
          anchor.click()
          //let win = window.open('_blank')
          //win.location = objectUrl
          window.URL.revokeObjectURL(objectUrl)
        })
        .catch((err) => {
          this.console.error("Nastala chyba pri downloade súboru", err)
        })
    },
    openPrintWindow: function (aUrl) {
      console.log("openPrintWindow", aUrl)
      this.$api
        .getBinary(aUrl)
        .then((blob) => {
          this.openBlob(blob).print()
        })
        .catch((err) => {
          console.error("Nastala chyba pri downloade súboru", err)
        })
    },
    openWindow: function (aUrl) {
      console.log("openWindow", aUrl)
      this.$api
        .getBinary(aUrl)
        .then((blob) => {
          this.openBlob(blob)
        })
        .catch((err) => {
          console.error("Nastala chyba pri otváraní súboru", err)
        })
    },
    openBlob: function (blob) {
      let objectUrl = window.URL.createObjectURL(blob)
      console.log("openWindow", objectUrl)
      let win = window.open(objectUrl)
      window.URL.revokeObjectURL(objectUrl)
      return win
    },
    base64toBlob: function (base64Data, contentType = "", sliceSize = 512) {
      const byteCharacters = atob(base64Data)
      const byteArrays = []

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize)

        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers)
        byteArrays.push(byteArray)
      }

      const blob = new Blob(byteArrays, { type: contentType })
      return blob
    },
  },
}
