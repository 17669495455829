<template>
  <div>
    <v-btn
      v-if="
        form.model.id !== '' &&
        form.isOpakRecept &&
        form.model.recept.blo_ciarovy_kod != undefined &&
        form.model.recept.blo_ciarovy_kod != ''
      "
      flat
      @click="form.zneplatnitReceptOFFLINE"
      >{{ $vuetify.t("Zneplatniť op.recept") }}</v-btn
    >
    <v-btn v-if="form.model.id !== ''" flat @click="form.tlacit">
      {{ $vuetify.t("Tlačiť recept") }}
    </v-btn>
    <v-btn
      v-if="
        form.model.recept.blo_ciarovy_kod != undefined &&
        form.model.recept.blo_ciarovy_kod != ''
      "
      flat
      @click="form.stornoReceptOFFLINE"
    >
      {{ $vuetify.t("Stornovať recept") }}
    </v-btn>
    <v-btn flat @click.native="$emit('close')">
      {{ $vuetify.t("$vuetify.app.button.close") }}
    </v-btn>
    <!--v-btn
      v-if="!form.formDisabled"
      color="primary"
      flat
      @click="form.save"
      :loading="form.saveSpinning"
      >{{ $vuetify.t("Uložiť") }}</!--v-btn
    -->
    <v-btn
      v-if="!form.formDisabled"
      color="primary"
      flat
      @click.native="$emit('form-save')"
      :loading="form.saveSpinning"
      >{{ $vuetify.t("Uložiť") }}</v-btn
    >
  </div>
</template>

<script>
export default {
  props: {
    form: {
      type: Object,
      default: function () {
        let myForm = {}
        myForm.model = {}
        myForm.zneplatnitReceptOFFLINE = function () {}
        myForm.tlacit = function () {}
        myForm.stornoReceptOFFLINE = function () {}
        myForm.save = function () {}
        return myForm
      },
    },
  },
  data: function () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted: function () {},
  created: function () {},
}
</script>
