<template>
  <v-layout align-center justify-center>
    <v-flex xs12 sm8 md4>
      <v-card class="elevation-12">
        <v-toolbar flat dense card>
          <v-toolbar-title>{{
            $vuetify.t("Overenie používateľského konta")
          }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <div v-if="verified">
            <div v-if="success" class="text-md-left px-4">
              <h3 class="headline">
                {{ $vuetify.t("Vitajte v aplikácii Ordinácia") }}
              </h3>
              <p>
                {{
                  $vuetify.t(
                    "Vaše konto bolo úspešne overené. Prosím, pokračujte prihlásením sa do aplikácie"
                  )
                }}
              </p>
            </div>
            <v-alert v-else type="error" :value="true">
              {{
                $vuetify.t("Nastala chyba! Vaše konto nebolo korektne overené.")
              }}
            </v-alert>
          </div>
          <div v-else>
            <v-container grid-list-md class="pa-0">
              <v-layout row wrap>
                <v-flex xs12 md6>
                  <TextField
                    ref="nameField"
                    v-model="form.name"
                    name="form.name"
                    v-validate="'required'"
                    :maxlength="255"
                    :label="$vuetify.t('Meno')"
                  ></TextField>
                </v-flex>
                <!-- <v-flex xs12 md6> -->
                <!--   <TextField -->
                <!--     v-model="form.email" -->
                <!--     name="form.email" -->
                <!--     v-validate="'required|email'" -->
                <!--     maxlength="128" -->
                <!--     :label="$vuetify.t('E-mail')" -->
                <!--     autocomplete="username" -->
                <!--   ></TextField> -->
                <!-- </v-flex> -->
                <v-flex xs12 md6>
                  <TextField
                    v-model="form.phone"
                    name="form.phone"
                    v-validate="{ regex: '^([+]?[0-9]+)$' }"
                    :maxlength="255"
                    :label="$vuetify.t('Telefónne č.')"
                  ></TextField>
                </v-flex>
                <v-flex xs12 md6>
                  <text-field-password
                    v-model="form.password"
                    name="form.password"
                    v-validate="'required|min:12|max:255'"
                    :error-messages="errors.collect('password1')"
                    data-vv-name="password1"
                    :data-vv-as="$vuetify.t('heslo')"
                    :label="$vuetify.t('Heslo')"
                    :hint="$vuetify.t('Heslo musí mať aspoň 12 znakov')"
                    ref="password1"
                    counter="255"
                    autocomplete="new-password"
                  ></text-field-password>
                </v-flex>
                <v-flex xs12 md6>
                  <text-field-password
                    v-model="form.password2"
                    name="form.password2"
                    v-validate="'required|confirmed:password1'"
                    :error-messages="errors.collect('password2')"
                    data-vv-name="password2"
                    :data-vv-as="$vuetify.t('heslo')"
                    :label="$vuetify.t('Overenie hesla')"
                    :hint="$vuetify.t('Pre overenie zadajte heslo ešte raz')"
                    counter
                    autocomplete="new-password"
                  ></text-field-password>
                </v-flex>
              </v-layout>
            </v-container>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn flat @click="goToLogin">{{
            $vuetify.t("Späť na prihlásenie")
          }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn flat v-if="!verified" @click="verify" color="primary">{{
            $vuetify.t("Odoslať")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import TextField from "@/components/TextField.vue"
import textFieldPassword from "@/components/TextFieldPassword.vue"

export default {
  name: "UserAccountVerification",
  $_veeValidate: {
    validator: "new",
  },
  components: { TextField, textFieldPassword },
  props: {
    token: {
      type: String,
      required: false,
    },
  },
  data: function () {
    return {
      verified: false,
      success: false,
      form: {
        name: "",
        password: "",
        password2: "",
        phone: "",
      },
    }
  },
  methods: {
    verify: async function () {
      var valid = await this.$validator.validateAll()
      if (!valid) {
        return
      }
      try {
        await this.$api.post("/v1/proto.Registration/InviteUserVerify", {
          token: this.token,
          name: this.form.name,
          password: this.form.password,
          phone: this.form.phone,
        })
        this.success = true
      } catch (err) {
      } finally {
        this.verified = true
      }
    },
    goToLogin: function () {
      this.$router.replace({ name: "login" })
    },
  },
  mounted: function () {
    this.$refs.nameField.focus()
  },
}
</script>
