<template>
  <v-card class="api-table mx-3 my-3 pb-3 elevation-5">
    <v-toolbar dense flat card>
      <v-toolbar-title>
        <slot name="title">
          {{ title }}
        </slot>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom v-if="hasFilter">
        <v-text-field
          v-if="hasFilter"
          v-model="filter"
          class="dense mx-1"
          flat
          hide-details
          prepend-inner-icon="search"
          solo-inverted
          clearable
          slot="activator"
        >
        </v-text-field>
        <span v-html="filterTooltip"></span>
      </v-tooltip>
      <v-flex shrink>
        <v-tooltip bottom>
          <v-select
            v-model="pageSize"
            :items="[5, 10, 15, 20, 50]"
            label=""
            @input="reload"
            class="page-size dense"
            flat
            solo-inverted
            hide-details
            slot="activator"
          ></v-select>
          <span>{{ $vuetify.t("Počet riadkov na stránku") }}</span>
        </v-tooltip>
      </v-flex>
      <v-tooltip top>
        <v-btn icon slot="activator" @click="refresh">
          <v-icon>refresh</v-icon>
        </v-btn>
        <span>{{ $vuetify.t("Obnoviť") }}</span>
      </v-tooltip>
      <slot name="toolbarItems"> </slot>
    </v-toolbar>
    <slot name="beforeTable"> </slot>
    <v-data-table
      :headers="headers"
      :items="items"
      :pagination.sync="pagination"
      :total-items="pageSize"
      :loading="loading"
      must-sort
      hide-actions
      @update:pagination="reload"
    >
      <!-- <v-progress-linear slot="progress" indeterminate></v-progress-linear> -->
      <template slot="items" slot-scope="props">
        <v-hover>
          <template v-slot="{ hover }">
            <slot name="items" :props="props" :item="props.item" :hover="hover">
              <tr
                @click="$emit('click:item', props.item)"
                style="{cursor: hover ? 'pointer' : ''}"
              >
                <td class="text-md-left">{{ props.item.id }}</td>
                <td class="text-md-left">
                  {{ props.item.created_at | formatDatumACas }}
                </td>
              </tr>
            </slot>
          </template>
        </v-hover>
      </template>
    </v-data-table>
    <table-pagination
      :offset="offset"
      :itemsCount="itemsCount"
      :itemsVisibleCount="itemsVisibleCount"
      :loading="loading"
      :pageSize="pageSize"
      @reload="reload"
      @setOffset="(os) => (offset = os)"
    ></table-pagination>
  </v-card>
</template>

<script>
import * as Miscella from "../miscella.js"
import TablePagination from "@/components/TablePagination.vue"
import Vue from "vue"

export default {
  components: { TablePagination },
  props: {
    defaultSortBy: {
      type: String,
      required: false,
      default: "id",
    },
    defaultSortDesc: {
      type: Boolean,
      required: false,
      default: false,
    },
    url: {
      type: String,
      required: true,
    },
    responseItems: {
      type: String,
      required: false,
      default: "items",
    },
    hasFilter: {
      type: Boolean,
      required: false,
      default: true,
    },
    filterTooltip: {
      type: String,
      required: false,
      default: "V zozname sa zobrazia len položky obsahujúce zadaný výraz",
    },
    customParams: {
      type: Object,
      required: false,
      default: function () {
        return {}
      },
    },
    headers: {
      type: Array,
      required: false,
      default: function () {
        return [
          {
            text: "ID",
            value: "ID",
            align: "left",
            sortable: true,
          },
          {
            text: "Dátum vytvorenia",
            value: "Created",
            align: "left",
            sortable: true,
          },
        ]
      },
    },
    title: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      loading: false,
      offset: 0,
      pageSize: 10,
      items: [],
      itemsVisibleCount: 0,
      itemsCount: 0,
      filter: "",
      pagination: {
        sortBy: this.defaultSortBy,
        descending: this.defaultSortDesc,
      },
      counter: 0,
    }
  },
  computed: {},
  watch: {
    filter: function () {
      Miscella.myDelay(() => {
        this.offset = 0
        this.reload()
      }, 500)
    },
    customParams: {
      deep: true,
      handler() {
        this.reload()
      },
    },
  },
  methods: {
    refresh: function () {
      this.offset = 0
      this.filter = ""
      this.pagination = {
        sortBy: this.defaultSortBy,
        descending: this.defaultSortDesc,
      }
      this.reload()
    },
    reload: async function () {
      this.counter++
      var params = this.customParams
      params.table_request = {
        offset: this.offset,
        limit: this.pageSize + 1,
        sort_by: this.pagination.sortBy,
        sort_desc: this.pagination.descending,
        fulltext_search: Miscella.isEmpty(this.filter) ? "" : this.filter,
      }
      if (this.hasPagination) {
        params.table_request.page = this.tableInfo.page
      }
      this.loading = true
      try {
        let counter = Miscella.deepCloneObject(this.counter)
        let res = await this.$api.post(this.url, params)
        this.setItems(res, counter)
      } catch (error) {
        console.log("xErr:", error)
      }
      Vue.nextTick(() => {
        this.loading = false
      })
    },
    setItems: function (data, counter) {
      console.log("APITABLE, setItems", data)
      if (this.counter === counter) {
        let items = data[this.responseItems]
        this.itemsCount = items.length
        this.items = items.splice(0, this.pageSize)
        this.itemsVisibleCount = this.items.length
      }
    },
  },
}
</script>

<style>
.api-table .page-size .v-select__selections input {
  width: 0;
}
</style>
