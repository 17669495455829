var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"ma-2 elevation-5"},[_c('v-toolbar',{attrs:{"flat":"","dense":"","card":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm._f("firstToUpper")(_vm._f("formatDatumACas")(_vm.eventDate,"dddd DD.MM.YYYY")))+" ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""}},[_c('v-btn',{attrs:{"slot":"activator","flat":"","icon":""},on:{"click":_vm.showDay},slot:"activator"},[_c('v-icon',[_vm._v("calendar_view_day")])],1),_c('span',[_vm._v(_vm._s(_vm.$vuetify.t("Denný prehľad")))])],1),_c('v-tooltip',{attrs:{"top":""}},[_c('v-btn',{attrs:{"slot":"activator","flat":"","icon":""},on:{"click":_vm.showMonth},slot:"activator"},[_c('v-icon',[_vm._v("calendar_today")])],1),_c('span',[_vm._v(_vm._s(_vm.$vuetify.t("Mesačný prehľad")))])],1),_c('v-tooltip',{attrs:{"top":""}},[_c('v-btn',{attrs:{"slot":"activator","flat":"","icon":""},on:{"click":_vm.addEvent},slot:"activator"},[_c('v-icon',[_vm._v("add")])],1),_c('span',[_vm._v(_vm._s(_vm.$vuetify.t("Pridať")))])],1)],1),(_vm.calendarType === 'MONTH')?_c('v-card-text',[_c('v-flex',{attrs:{"xs12":""}},[_c('v-date-picker',{attrs:{"events":_vm.getColorOfEvent,"full-width":"","locale":"sk","first-day-of-week":1,"day-format":_vm.dayFormat,"no-title":true},on:{"update:pickerDate":_vm.changedMonth},model:{value:(_vm.eventDate),callback:function ($$v) {_vm.eventDate=$$v},expression:"eventDate"}})],1),_c('v-flex',{attrs:{"xs12":""}},[(_vm.eventy.length > 0)?_c('v-list',{attrs:{"subheader":"","dense":""}},[_vm._l((_vm.eventy),function(event,index){return [_c('v-list-tile',{key:'list' + index,on:{"click":function($event){return _vm.editEvent(event)}}},[_c('v-list-tile-content',[_c('v-container',{attrs:{"fluid":""}},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"xs1":"","px-1":""}},[_c('div',{staticClass:"legend-color",style:({
                          'background-color': _vm.getColorOfEventDay(event.Typ),
                        })})]),_c('v-flex',{attrs:{"xs2":"","px-1":""}},[(!_vm.isKontrola(event))?_c('v-tooltip',{attrs:{"top":""}},[_c('span',{attrs:{"slot":"activator"},slot:"activator"},[_vm._v(_vm._s(event.CasOd)+" ")]),_c('span',[_vm._v(_vm._s(_vm.$vuetify.t("Čas od")))])]):_vm._e()],1),_c('v-flex',{attrs:{"xs9":"","px-1":""}},[_c('v-tooltip',{attrs:{"top":""}},[_c('div',{staticClass:"text-truncate desc",attrs:{"slot":"activator"},slot:"activator"},[(
                              event.PatientId !== '' && event.PatientName !== ''
                            )?_c('router-link',{staticClass:"px-1",attrs:{"tag":"a","to":{
                              name: 'pacient',
                              params: { id: event.PatientId },
                            }}},[_vm._v(" "+_vm._s(event.PatientName)+" ")]):_vm._e(),_vm._v(" "+_vm._s(event.Desc)+" ")],1),_c('span',[_vm._v(_vm._s(event.Desc))])])],1)],1)],1)],1),_c('v-list-tile-action',[(event.Typ !== 'OBJEDNANIE_ORDERS')?_c('v-tooltip',{attrs:{"left":""}},[_c('v-icon',{attrs:{"slot":"activator"},on:{"click":function($event){$event.stopPropagation();return _vm.removeEvent(event.ID)}},slot:"activator"},[_vm._v("delete")]),_c('span',[_vm._v(_vm._s(_vm.$vuetify.t("Odstrániť")))])],1):_vm._e()],1)],1),(index !== _vm.eventy.length - 1)?_c('v-divider',{key:'divider' + index}):_vm._e()]})],2):_c('v-card-text',[_vm._v(" Žiadne udalosti ani objednaní pacienti ")])],1)],1):_vm._e(),(_vm.calendarType === 'DAY')?_c('v-card-text',[_c('v-calendar',{attrs:{"color":"primary","type":"day","locale":"sk","first-interval":_vm.range[0],"interval-count":_vm.range[1] - _vm.range[0],"hide-header":true},on:{"click:time":_vm.addEventWithDateTime},scopedSlots:_vm._u([{key:"dayBody",fn:function({ date, timeToY, minutesToPixels }){return [_vm._l((_vm.eventy),function(event){return [_c('calendar-event',{key:event.ID,attrs:{"event":event,"height":minutesToPixels(event.Duration) + 'px',"position":timeToY(event.CasOd) + 'px'},on:{"click":_vm.editEvent,"click:remove":_vm.removeEvent}})]})]}}],null,false,668941533),model:{value:(_vm.eventDate),callback:function ($$v) {_vm.eventDate=$$v},expression:"eventDate"}})],1):_vm._e(),_c('BaseDialog',{ref:"dialogEvent",attrs:{"dialogTitle":_vm.$vuetify.t('Udalosť')},on:{"save":function($event){return _vm.$refs.formEvent.save()}}},[_c('FormEvent',{ref:"formEvent",on:{"saved":function($event){return _vm.savedEvent(_vm.$refs.dialogEvent)}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }