<template>
  <v-form>
    <v-container fluid>
      <v-layout row wrap>
        <v-flex xs12 md6>
          <DatePicker
            v-model="model.date"
            :label="$vuetify.t('Dátum')"
            name="datum"
            v-validate="'required'"
            :disabled="readonly"
          ></DatePicker>
        </v-flex>
        <v-flex xs12 md6>
          <DatePicker
            v-model="model.neziaduca_reakcia.datum_ukoncenia"
            :label="$vuetify.t('Dátum ukončenia')"
            name="datumUkoncenia"
            :disabled="readonly"
          ></DatePicker>
        </v-flex>
        <v-flex xs12 md6>
          <codebook-autocomplete
            codebook-id="83"
            name="liecivo"
            v-model="model.neziaduca_reakcia.liecivo"
            :label="$vuetify.t('Liečivo')"
            :disabled="readonly"
          >
          </codebook-autocomplete>
        </v-flex>
        <v-flex xs12 md6>
          <codebook-autocomplete
            codebook-id="158"
            name="alergen"
            v-model="model.neziaduca_reakcia.alergen"
            :label="$vuetify.t('Alergén')"
            :disabled="readonly"
          >
          </codebook-autocomplete>
        </v-flex>
        <v-flex xs12>
          <v-textarea
            v-model="model.neziaduca_reakcia.poznamka"
            :label="$vuetify.t('Poznámka')"
            :disabled="readonly"
          ></v-textarea>
        </v-flex>
        <v-flex xs12 md6>
          <codebook-autocomplete
            codebook-id="58"
            name="typ_reakcie"
            v-model="model.neziaduca_reakcia.typ_reakcie"
            :label="$vuetify.t('Typ reakcie')"
            :disabled="readonly"
          >
          </codebook-autocomplete>
        </v-flex>
        <v-flex xs12 md6>
          <codebook-autocomplete
            codebook-id="159"
            name="typ_alergie"
            v-model="model.neziaduca_reakcia.typ_alergie"
            :label="$vuetify.t('Typ alergie')"
            :disabled="readonly"
          >
          </codebook-autocomplete>
        </v-flex>
        <v-flex xs12>
          <codebook-autocomplete
            codebook-id="25"
            name="diagnoza"
            v-model="model.neziaduca_reakcia.diagnoza"
            :label="$vuetify.t('Diagnóza')"
            :disabled="readonly"
          >
          </codebook-autocomplete>
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
import BaseForm from "./../BaseForm.vue"
import DatePicker from "./../DatePicker.vue"
import CodebookAutocomplete from "./../ciselnikComponents/CodebookAutocomplete.vue"
import * as Miscella from "./../../miscella.js"
import moment from "moment"

export default {
  name: "FormNeziaducaReakcia",
  extends: BaseForm,
  $_veeValidate: {
    validator: "new",
  },
  components: {
    DatePicker,
    CodebookAutocomplete,
  },
  props: {
    pacientId: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      model: null,
      readonly: false,
    }
  },
  computed: {
    ambulanceId: function () {
      let id = null
      if (Miscella.isSet(this.$store.state.me.ambulance)) {
        id = this.$store.state.me.ambulance.id
      }
      return id
    },
  },
  methods: {
    init: function () {
      this.readonly = false
      this.model = {
        id: "",
        patient_id: this.pacientId,
        ambulance_id: this.ambulanceId,
        date: moment().format(),
        parent_id: "",
        neziaduca_reakcia: {
          datum_ukoncenia: null,
          alergen: null,
          liecivo: null,
          poznamka: "",
          typ_reakcie: null,
          typ_alergie: null,
          diagnoza: null,
        },
      }
      this.$validator.reset()
      this.errors.clear()
    },
    new: function () {
      this.init()
      this.$emit("activated")
    },
    edit: function (item, readonly) {
      this.init()
      this.readonly = readonly
      this.model = Miscella.cloneObject(item)
      this.$emit("activated")
    },
    validate: async function () {
      var valid = await this.$validator.validateAll()
      if (Miscella.isSet(this.model.neziaduca_reakcia.datum_ukoncenia)) {
        let datumVytvorenia = moment(this.model.date)
        let datumUkoncenia = moment(
          this.model.neziaduca_reakcia.datum_ukoncenia
        )
        if (datumUkoncenia.isBefore(datumVytvorenia, "day")) {
          valid = false
          this.errors.add({
            field: "datumUkoncenia",
            msg: this.$vuetify.t(
              "Dátum ukončenia nemôže predchádzať dátumu vytvorenia."
            ),
          })
        }
      }

      if (
        !Miscella.isSet(this.model.neziaduca_reakcia.liecivo) &&
        !Miscella.isSet(this.model.neziaduca_reakcia.alergen)
      ) {
        valid = false
        var msg = this.$vuetify.t(
          "Aspoň jedna z položiek Liečivo alebo Alergén musí byť vyplnená."
        )
        this.errors.add({
          field: "alergen",
          msg: msg,
        })
        this.errors.add({
          field: "liecivo",
          msg: msg,
        })
      }
      return valid
    },
    save: async function () {
      try {
        var params = { thing: Miscella.cloneObject(this.model) }
        var result = await this.$api.thingService.saveThing(params)
        this.notifySuccess("Nežiadúca reakcia bola úspešne uložená")
        this.$emit("saved", result.thing)
        this.model = result.thing
      } catch (err) {
        this.processError(err, "Pri ukladaní nežiadúcej reakcie vznikla chyba")
      }
    },
  },
  created: function () {
    this.init()
  },
}
</script>
