<template>
  <div :data-vv-as="label">
    <v-text-field
      ref="textField"
      :type="type"
      :label="label"
      :clearable="!disabled"
      :prepend-inner-icon="prependInnerIcon"
      :prepend-icon="prependIcon"
      :disabled="disabled"
      :maxlength="maxlength"
      :counter="maxlength"
      :error-messages="errorMessages || errors.collect(name)"
      :success-messages="successMessages"
      :id="name"
      :name="autocomplete || 'new-' + name"
      :value="value"
      @input="updateValue"
      @keyup.enter="keyUpEnter"
      :hint="hint"
      :mask="mask"
      :placeholder="placeholder"
      :browser-autocomplete="autocomplete || 'new-' + name"
      :box="design === 'box'"
      :solo="design === 'solo'"
      :outline="design === 'outline'"
      :success="success"
    ></v-text-field>
  </div>
</template>

<script type="text/babel">
export default {
  inject: ["$validator"],
  props: {
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "text",
    },
    label: {
      type: String,
      default: "",
    },
    value: {
      default: null,
    },
    maxlength: {
      type: Number,
      default: 100,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: [String, Array],
      required: false,
    },
    successMessages: {
      type: [String, Array],
      required: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    prependInnerIcon: {
      type: String,
      default: "",
    },
    prependIcon: {
      type: String,
      default: "",
    },
    keyUpEnter: {
      type: Function,
      default: function () {},
    },
    hint: {
      type: String,
      default: undefined,
    },
    mask: {
      type: String,
      default: undefined,
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    autocomplete: {
      type: String,
      default: undefined,
    },
    // box, solo or outline
    design: {
      type: String,
      default: "",
    },
  },
  methods: {
    focus: function () {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.textField.$refs.input.focus()
        }, 0)
      })
    },
    updateValue: function (value) {
      if (this.type === "number") {
        value = Number(value)
      }
      this.$emit("input", value)
    },
  },
}
</script>
