<template>
  <v-container fluid>
    <v-layout column>
      <!--v-flex>
        <v-btn @click="showSource = !showSource">Show source</v-btn>
        <pre v-show="showSource">
          active: {{ active }}
          forms: {{ forms || json }}
        </pre>
      </v-flex-->
      <v-flex v-if="forms.length > 0">
        <v-treeview
          activatable
          :active.sync="active"
          :items="forms"
          open-on-click
          transition
        >
          <template v-slot:prepend="{ item, active }">
            <v-icon
              v-if="
                item.children === undefined ||
                item.children === null ||
                item.children.length === 0
              "
              :color="active ? 'primary' : ''"
            >
              notes
            </v-icon>
          </template>
          <span slot="label" slot-scope="{ item }">
            <span class="font-weight-bold">{{ item.name }}</span>
            <span class="font-weight-light">{{
              item.description ? " - " + item.description : ""
            }}</span>
            <!-- bude sa pouzivat na cloude -->
            <!-- <span v-if="!item.children" class="px-2"> -->
            <!--   <span class="autor" v-if="showAuthor" -->
            <!--     >Autor: {{ item.author.name }}</span -->
            <!--   > -->
            <!--   <span class="stars pl-3" v-if="showStars"> -->
            <!--     {{ item.cloud_stars }} -->
            <!--     <v-icon color="#cebc23" size="10px">fa-star</v-icon> -->
            <!--   </span> -->
            <!-- </span> -->
          </span>
        </v-treeview>
      </v-flex>
      <v-flex v-else> Nemáte vytvorené žiadne vlastné formuláre. </v-flex>
      <v-flex>
        <v-divider />
        <!--
        <v-btn color="primary" @click="choose" :disabled="active.length === 0"
          >Vybrať</v-btn
        >
        <v-btn color="secondary" @click="close">Zatvoriť</v-btn>
        -->
        <slot name="otherButtons" :active="active"></slot>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  components: {},
  data: function () {
    return {
      showSource: false,
      active: [],
    }
  },
  props: {
    forms: {
      type: Array,
      required: true,
    },
    // bude sa pouzivat na cloude
    /*    showAuthor: {
      type: Boolean,
      default: true
    },
    showStars: {
      type: Boolean,
      default: true
    }*/
  },
  methods: {},
}
</script>

<style scoped>
.description {
  font-size: 60%;
}
.autor {
  font-size: 60%;
}
.stars {
  font-size: 60%;
}
.stars .v-icon {
  vertical-align: baseline;
}
</style>
