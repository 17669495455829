<template>
  <v-form>
    <v-container fluid>
      <v-layout row wrap>
        <v-flex xs12>
          <DatePicker
            v-model="model.datumOd"
            :label="$vuetify.t('Dátum od')"
            name="datumOd"
            v-validate="'required'"
          ></DatePicker>
        </v-flex>
        <v-flex xs12>
          <DatePicker
            v-model="model.datumDo"
            :label="$vuetify.t('Dátum do')"
            name="datumDo"
          ></DatePicker>
        </v-flex>
        <v-flex xs12>
          <v-select
            v-model="model.poistovna"
            :label="$vuetify.t('Poisťovňa')"
            :items="poistovne"
            v-validate="'required'"
            data-vv-name="poistovna"
            :data-vv-as="$vuetify.t('Poisťovňa')"
            :error-messages="errors.collect('poistovna')"
          >
            <template slot="selection" slot-scope="{ item }"
              ><span>
                {{ $vuetify.t("$vuetify.enum.poistovna." + item) }}</span
              ></template
            >
            <template slot="item" slot-scope="{ item }">
              <v-list-tile-content>
                <span>
                  {{ $vuetify.t("$vuetify.enum.poistovna." + item) }}</span
                >
              </v-list-tile-content>
            </template>
          </v-select>
        </v-flex>
        <v-flex xs12>
          <v-select
            v-model="model.charakterPoistenca"
            :label="$vuetify.t('Charakter poistenca')"
            :items="charakteryPoistenca"
          >
            <template slot="selection" slot-scope="{ item }"
              ><span>
                {{
                  $vuetify.t("$vuetify.enum.charakterPoistenca." + item)
                }}</span
              ></template
            >
            <template slot="item" slot-scope="{ item }">
              <v-list-tile-content>
                <span>
                  {{
                    $vuetify.t("$vuetify.enum.charakterPoistenca." + item)
                  }}</span
                >
              </v-list-tile-content>
            </template>
          </v-select>
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>
<script>
import DatePicker from "./../DatePicker.vue"

export default {
  $_veeValidate: {
    validator: "new",
  },
  components: {
    DatePicker,
  },
  data: function () {
    return {
      loading: true,
      poistovne: [],
      charakteryPoistenca: [],
      model: {},
    }
  },
  props: {
    patientId: String,
  },
  computed: {
    savePoistovnaUrl: function () {
      return "/api/patient/" + this.patientId + "/poistenie"
    },
    isVSZP: function () {
      return this.model.poistovna === "VSZP"
    },
  },
  watch: {
    "model.poistovna": function () {
      if (
        !this.isVSZP &&
        (this.model.charakterPoistenca === "BE" ||
          this.model.charakterPoistenca === "CU")
      ) {
        this.model.charakterPoistenca = "SR"
      }
    },
  },

  methods: {
    initForm: function () {
      this.fetchData()
      this.model = {
        id: "",
        datumOd: null,
        datumDo: null,
        poistovna: "",
        poznamka: null,
        charakterPoistenca: "",
      }
      this.$validator.reset()
    },
    fetchPoistovne: async function () {
      this.poistovne = await this.$api.get("/api/poistovneEnum", null)
    },
    fetchCharakteryPoistenca: async function () {
      this.charakteryPoistenca = await this.$api.get(
        "/api/charakteryPoistencaEnum",
        null
      )
    },
    fetchData: function () {
      this.fetchPoistovne()
      this.fetchCharakteryPoistenca()
    },
    newPoistenie: function () {
      this.initForm()
    },
    editPoistenie: function (poistenie) {
      this.initForm()
      this.model.id = poistenie.ID
      this.model.datumOd = poistenie.DatumOd
      this.model.datumDo = poistenie.DatumDo
      this.model.poistovna = poistenie.PoistovnaSR
      this.model.charakterPoistenca = poistenie.CharakterPoistenca
    },
    saveForm: async function () {
      var valid = await this.$validator.validateAll()
      if (!valid) {
        return
      }
      this.save()
    },
    save: async function () {
      var params = {
        ID: this.model.id,
        DatumOd: this.model.datumOd,
        DatumDo: this.model.datumDo,
        PoistovnaSR: this.model.poistovna,
        CharakterPoistenca: this.model.charakterPoistenca,
      }
      try {
        await this.$api.post(this.savePoistovnaUrl, params)
        this.saveOK()
      } catch (e) {
        this.saveError(e)
      }
    },
    saveOK: function () {
      this.$emit("saved")
      this.$store.commit(
        "setSuccess",
        this.$vuetify.t("Poistenie bolo úspešne uložené.")
      )
    },
    saveError: function () {
      this.$store.commit("setError", "Pri ukladaní poistenia vznikla chyba.")
    },
  },
}
</script>
