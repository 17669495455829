<template>
  <v-list-tile :to="link" active-class="primary--text">
    <v-list-tile-content>
      <v-list-tile-title>{{ $vuetify.t(text) }}</v-list-tile-title>
    </v-list-tile-content>
  </v-list-tile>
</template>
<script>
export default {
  props: ["text", "link"],
}
</script>
