<template>
  <form-viewer
    :formDefinition="formDefinition"
    :formData="model.form_data.form_data"
    :showButtons="false"
    @save="save"
  ></form-viewer>
</template>

<script>
import BaseForm from "./../BaseForm.vue"
import FormViewer from "@/components/axonforms/FormViewer.vue"
import moment from "moment"
import * as Miscella from "../../miscella.js"
import { mapState } from "vuex"

export default {
  name: "FormThingForm",
  extends: BaseForm,
  components: {
    FormViewer,
  },
  props: {
    pacientId: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      model: null,
      formDefinition: {},
    }
  },
  computed: {
    ...mapState({
      ambulanceId: (state) =>
        Miscella.isSet(state.me.ambulance) ? state.me.ambulance.id : "",
    }),
  },
  methods: {
    init: function () {
      this.model = {
        id: "",
        date: moment().format(),
        form_data: {
          form_data: {
            form_id: "",
            polozky: {},
          },
        },
      }
    },
    new: function (formDefinition) {
      this.init()
      this.formDefinition = formDefinition
      this.model.form_data.form_data.form_id = formDefinition.id
    },
    edit: function (thingForm) {
      this.init()
      this.formDefinition = thingForm.form_data.form
      this.model = Miscella.cloneObject(thingForm)
      let fd = this.model?.form_data?.form_data
      for (let i in fd.polozky) {
        let polozka = fd.polozky[i]
        if (polozka.indexOf(",") > 0) {
          fd.polozky[i] = polozka.split(",")
        }
      }
    },
    save: async function () {
      try {
        await this.saveFormData()
        await this.saveThing()
        this.$emit("saved", this.model)
        this.isDirty = false
      } catch (error) {
        console.log("pri ukladani formularu vznikla chyba", error)
      }
    },
    saveFormData: async function () {
      try {
        console.log("FormThingForm.saveFormData", this.model)
        var data = Miscella.cloneObject(this.model)
        delete data.form_data.form
        delete data.formatted_text
        let fd = data.form_data.form_data
        for (let i in fd.polozky) {
          let polozka = fd.polozky[i]
          if (Array.isArray(polozka)) {
            fd.polozky[i] = polozka.join(",")
          }
          if (fd.polozky[i] === null) {
            fd.polozky[i] = "null"
          } else {
            fd.polozky[i] = polozka.toString()
          }
        }
        var params = { form_data: fd }
        console.log("saveFormData", params)
        return this.$api.formService
          .saveFormData(params)
          .then((result) => {
            this.notifySuccess("Formulár bol úspešne uložený")
            this.model.form_data.form_data = result.form_data
            //this.$emit("saved", this.model)
            //this.isDirty = false
          })
          .catch((error) => {
            console.error(error)
            this.processError(error, "Pri ukladaní formulára vznikla chyba")
          })
      } catch (err) {
        console.error(err)
        this.$store.commit(
          "setError",
          this.$vuetify.t("Pri ukladaní formulára vznikla chyba ") +
            " (" +
            err +
            ")."
        )
      }
    },
    saveThing: async function () {
      try {
        console.log("FormThingForm.saveThing", this.model)
        var data = Miscella.cloneObject(this.model)
        //delete data.form
        delete data.form_data
        //delete data.formDataFormattedText
        data.patient_id = this.pacientId
        data.ambulance_id = this.ambulanceId
        data.form_data = { form_data_id: this.model.form_data.form_data.id }
        var params = { thing: data }
        console.log("saveFormData", params)
        return this.$api.thingService
          .saveThing(params)
          .then((result) => {
            this.notifySuccess("Formulár bol úspešne uložený")
            this.model = result.thing
            this.$emit("saved", this.model)
            this.isDirty = false
          })
          .catch((error) => {
            console.error(error)
            this.processError(error, "Pri ukladaní formulára vznikla chyba")
          })
      } catch (err) {
        this.$store.commit(
          "setError",
          this.$vuetify.t("Pri ukladaní formulára vznikla chyba ") +
            " (" +
            err +
            ")."
        )
      }
    },
  },
  created: function () {
    this.init()
  },
}
</script>
