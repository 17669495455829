<template>
  <v-form>
    <v-container fluid>
      <v-layout row wrap>
        <v-flex xs12 md6>
          <DatePicker
            v-model="model.date"
            :label="$vuetify.t('Dátum')"
            name="datum"
            v-validate="'required'"
          ></DatePicker>
        </v-flex>
        <v-flex xs12>
          <v-textarea
            v-model="model.medikacia.popis"
            :label="$vuetify.t('Popis medikácie')"
            v-validate="'required|max:5000'"
            data-vv-name="popis"
            :data-vv-as="$vuetify.t('Popis medikácie')"
            :error-messages="errors.collect('popis')"
            auto-grow
            rows="2"
            :counter="5000"
          ></v-textarea>
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
import BaseForm from "./../BaseForm.vue"
import * as Miscella from "./../../miscella.js"
import DatePicker from "./../DatePicker.vue"
import moment from "moment"

export default {
  name: "FormMedikacia",
  extends: BaseForm,
  $_veeValidate: {
    validator: "new",
  },
  components: {
    DatePicker,
  },
  props: {
    pacientId: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      model: null,
    }
  },
  computed: {
    ambulanceId: function () {
      let id = null
      if (Miscella.isSet(this.$store.state.me.ambulance)) {
        id = this.$store.state.me.ambulance.id
      }
      return id
    },
  },
  methods: {
    init: function () {
      this.model = {
        id: "",
        patient_id: this.pacientId,
        ambulance_id: this.ambulanceId,
        date: moment().format(),
        parent_id: "",
        medikacia: {
          popis: "",
        },
      }
      this.$validator.reset()
    },
    new: function () {
      this.init()
      this.$emit("activated")
    },
    edit: function (item) {
      this.init()
      this.model = Miscella.cloneObject(item)
      this.$emit("activated")
    },
    validate: function () {
      return this.$validator.validateAll()
    },
    save: async function () {
      try {
        var params = { thing: Miscella.cloneObject(this.model) }
        var result = await this.$api.thingService.saveThing(params)
        this.notifySuccess("Medikácia bola úspešne uložená")
        this.$emit("saved", result.thing)
        this.model = result.thing
      } catch (err) {
        this.processError(err, "Pri ukladaní medikácie vznikla chyba")
      }
    },
  },
  created: function () {
    this.init()
  },
}
</script>
