var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('ApiList',{ref:"list",attrs:{"url":"/v1/proto.ThingService/ListThings","customParams":{
      patient_id: _vm.id,
      odporucanie: {},
    },"title":_vm.$vuetify.t('Odporúčania'),"actions":[
      {
        text: _vm.$vuetify.t('Odstrániť odporúčanie'),
        icon: 'delete',
        handler: _vm.removeItem,
      },
    ]},on:{"addItem":_vm.addItem,"editItem":_vm.editItem},scopedSlots:_vm._u([{key:"items",fn:function({ item }){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"xs1":""}},[_c('v-tooltip',{attrs:{"top":""}},[_c('span',{attrs:{"slot":"activator"},slot:"activator"},[_vm._v(_vm._s(item.odporucanie.diagnoza.skratka))]),_c('span',[_vm._v(_vm._s(_vm.$vuetify.t("Diagnóza")))])])],1),_c('v-flex',{attrs:{"xs4":""}},[_c('v-tooltip',{attrs:{"top":""}},[_c('span',{attrs:{"slot":"activator"},slot:"activator"},[_vm._v(_vm._s(item.odporucanie.druh_specializovaneho_utvaru.display_name))]),_c('span',[_vm._v(_vm._s(_vm.$vuetify.t("Druh špecializované útvaru")))])])],1),_c('v-flex',{attrs:{"xs4":""}},[_c('v-tooltip',{attrs:{"top":""}},[_c('span',{attrs:{"slot":"activator"},slot:"activator"},[_vm._v(_vm._s(item.odporucanie.odborne_zameranie.display_name))]),_c('span',[_vm._v(_vm._s(_vm.$vuetify.t("Odborné zameranie")))])])],1)],1)],1)]}}])}),_c('BaseDialog',{ref:"dialog",attrs:{"dialogTitle":_vm.$vuetify.t('Výmenný lístok')}},[_c('FormOdporucanie',{ref:"form",attrs:{"pacientId":_vm.id},on:{"saved":_vm.fetchData}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }