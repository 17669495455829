<template>
  <v-card class="mb-0 elevation-4">
    <v-card-text class="py-2">
      <div>
        <div class="subheading font-weight-medium">
          {{ $vuetify.t(title) }}
        </div>
        <slot>
          <span>{{ text }}&nbsp;</span>
        </slot>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: false,
    },
  },
}
</script>
