<template>
  <v-card class="base-table mx-3 my-3 pb-3 elevation-5">
    <v-toolbar dense flat card>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom v-if="hasFilter">
        <v-text-field
          v-if="hasFilter"
          v-model="filter"
          class="dense mx-1"
          flat
          hide-details
          prepend-inner-icon="search"
          solo-inverted
          clearable
          slot="activator"
        >
        </v-text-field>
        <span v-html="filterTooltip"></span>
      </v-tooltip>
      <v-flex shrink>
        <v-tooltip bottom>
          <v-select
            v-model="pagination.rowsPerPage"
            :items="[5, 10, 15, 20, 50]"
            label=""
            @input="reload"
            class="page-size dense"
            flat
            solo-inverted
            hide-details
            slot="activator"
          ></v-select>
          <span>{{ $vuetify.t("Počet riadkov na stránku") }}</span>
        </v-tooltip>
      </v-flex>
      <slot name="toolbarItems">
        <!--v-btn flat>Link One</v-btn-->
      </slot>
    </v-toolbar>
    <v-data-table
      ref="dataTable"
      :headers="headers"
      :items="data.tableData"
      :loading="loading"
      :search="filter"
      :pagination.sync="pagination"
      :hide-actions="true"
      @update:pagination="updatePagination"
      must-sort
    >
      <v-progress-linear slot="progress" indeterminate></v-progress-linear>
      <template slot="items" slot-scope="props">
        <v-hover>
          <template v-slot="{ hover }">
            <slot name="items" :props="props" :item="props.item" :hover="hover">
              <tr
                @click="$emit('click:item', props.item)"
                style="{cursor: hover ? 'pointer' : ''}"
              >
                <td class="text-md-left">{{ props.item.ID }}</td>
                <td class="text-md-left">
                  {{ props.item.Created | formatDatumACas }}
                </td>
              </tr>
            </slot>
          </template>
        </v-hover>
      </template>
    </v-data-table>
    <table-pagination
      :offset="offset"
      :itemsVisibleCount="itemsVisibleCount"
      :itemsCount="itemsCount"
      :loading="loading"
      :pageSize="pagination.rowsPerPage"
      @reload="dummy"
      @setOffset="setOffset"
    ></table-pagination>
    <!--v-btn @click="clickMe">Click Me!</v-btn-->
    <!--pagination: {{ pagination }} itemsVisibleCount: {{ itemsVisibleCount }}-->
  </v-card>
</template>

<script>
import * as Miscella from "../miscella.js"
import TablePagination from "@/components/TablePagination.vue"

export default {
  components: { TablePagination },
  props: {
    defaultSortBy: {
      type: String,
      required: false,
      default: "ID",
    },
    defaultSortDesc: {
      type: Boolean,
      required: false,
      default: true,
    },
    hasFilter: {
      type: Boolean,
      required: false,
      default: true,
    },
    filterTooltip: {
      type: String,
      required: false,
      default: "V zozname sa zobrazia len položky obsahujúce zadaný výraz",
    },
    customParams: {
      type: Object,
      required: false,
      default: function () {
        return {}
      },
    },
    headers: {
      type: Array,
      required: false,
      default: function () {
        return [
          {
            text: "ID",
            value: "ID",
            align: "left",
            sortable: true,
          },
          {
            text: "Dátum vytvorenia",
            value: "Created",
            align: "left",
            sortable: true,
          },
        ]
      },
    },
    title: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      loading: false,
      offset: 0,
      data: {
        tableInfo: null,
        tableData: [],
      },
      itemsVisibleCount: 0,
      itemsCount: 0,
      filter: "",
      pagination: {
        sortBy: this.defaultSortBy,
        descending: this.defaultSortDesc,
        rowsPerPage: 10,
      },
    }
  },
  computed: {},
  watch: {
    customParams: function () {
      this.reload()
    },
  },
  methods: {
    reload: function () {
      this.$emit("reload")
    },
    setOffset: function (os) {
      console.log(
        "setOffset, this.pagination.rowsPerPage",
        this.pagination.rowsPerPage
      )
      console.log(
        "setOffset, this.pagination.totalItems",
        this.pagination.totalItems
      )
      this.offset = os
      let page = Math.floor(os / this.pagination.rowsPerPage) + 1
      console.log("setOffset, page", page)
      let visCount =
        this.pagination.totalItems - (page - 1) * this.pagination.rowsPerPage
      this.itemsCount = visCount
      this.pagination.page = page
      this.itemsVisibleCount = Math.min(visCount, this.pagination.rowsPerPage)
      console.log("setOffset, this.itemsVisibleCount", this.itemsVisibleCount)
    },
    setItems: function (data) {
      if (Miscella.isSet(data.tableInfo)) {
        this.data = data
      } else {
        this.data.tableData = data
      }
      this.itemsCount = data.length
      this.pagination.totalItems = data.length
      this.itemsVisibleCount = Math.min(
        data.length,
        this.pagination.rowsPerPage
      )
      console.log("setItems, this.itemsVisibleCount", this.itemsVisibleCount)
    },
    updatePagination: function (aPagination) {
      console.log("BaseTable, PaginationUpdated...", aPagination)
    },
    dummy: function () {
      console.log("Dummy")
    },
  },
}
</script>
<style>
.base-table .page-size .v-select__selections input {
  width: 0;
}
</style>
