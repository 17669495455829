<template>
  <v-form>
    <v-container fluid grid-list-md>
      <!-- TODO zatial zakomentovane -->
      <!-- <v-layout row wrap> -->
      <!--   <v-flex> -->
      <!--     <v-btn-toggle v-model="model.ockovanie.stav" mandatory> -->
      <!--       <v-btn flat value="PLANOVANE"> -->
      <!--         {{ $vuetify.t("$vuetify.enum.stavOckovanie.PLANOVANE") }} -->
      <!--       </v-btn> -->
      <!--       <v-btn flat value="VYKONANE"> -->
      <!--         {{ $vuetify.t("$vuetify.enum.stavOckovanie.VYKONANE") }} -->
      <!--       </v-btn> -->
      <!--       <v-btn flat value="ZRUSENE"> -->
      <!--         {{ $vuetify.t("$vuetify.enum.stavOckovanie.ZRUSENE") }} -->
      <!--       </v-btn> -->
      <!--     </v-btn-toggle> -->
      <!--   </v-flex> -->
      <!-- </v-layout> -->
      <v-layout row wrap>
        <v-expand-transition>
          <v-flex xs12 sm6 v-show="isPlanned || isZrusene">
            <DatePicker
              v-model="model.ockovanie.datum_plan"
              :label="$vuetify.t('Dátum plánovaného očkovania')"
              name="datum_plan"
              format="YYYY-MM-DD"
              :disabled="readonly"
            ></DatePicker>
          </v-flex>
        </v-expand-transition>
        <v-expand-transition>
          <v-flex xs12 sm6 v-show="isVykonane">
            <DatePicker
              v-model="model.ockovanie.datum_vykonane"
              :label="$vuetify.t('Dátum vykonania')"
              name="datum_vykonane"
              format="YYYY-MM-DD"
              v-validate="'dummy'"
              :disabled="readonly"
            ></DatePicker>
          </v-flex>
        </v-expand-transition>
      </v-layout>
      <v-layout row wrap align-end>
        <v-expand-transition>
          <v-flex xs12 sm6 v-show="isVykonane || isPlanned">
            <codebook-autocomplete
              :codebook-id="enums.CodebookIDDruhOckovania"
              name="druhy_ockovania"
              v-model="model.ockovanie.druhy_ockovania"
              :label="$vuetify.t('Druh očkovania')"
              :disabled="readonly"
              :multiple="true"
              :limit="0"
            >
            </codebook-autocomplete>
          </v-flex>
        </v-expand-transition>
        <v-expand-transition>
          <v-flex xs12 sm6 v-show="isVykonane || isPlanned">
            <codebook-autocomplete
              :codebook-id="enums.CodebookIDTypOckovania"
              name="typ_ockovania"
              v-model="model.ockovanie.typ_ockovania"
              :label="$vuetify.t('Typ očkovania')"
              :disabled="readonly"
            >
            </codebook-autocomplete>
          </v-flex>
        </v-expand-transition>
        <v-expand-transition>
          <v-flex xs12>
            <v-textarea
              v-model="model.ockovanie.poznamka"
              :label="$vuetify.t('Poznámka')"
              v-validate="'max:2000'"
              data-vv-name="poznamka"
              :data-vv-as="$vuetify.t('Poznámka')"
              rows="1"
              auto-grow
              :disabled="readonly"
              :counter="2000"
            ></v-textarea>
          </v-flex>
        </v-expand-transition>

        <!-- Ockovacia davka -->
        <v-expand-transition>
          <v-flex xs12 v-show="isVykonane">
            <v-card flat>
              <v-system-bar>
                <v-card-title>
                  <span class="subheading">{{
                    $vuetify.t("Očkovacia dávka")
                  }}</span>
                </v-card-title>
              </v-system-bar>
              <v-card-text class="px-2">
                <v-container fluid class="pa-0" grid-list-md>
                  <v-layout row wrap>
                    <v-flex xs12 sm4 v-show="isVykonane">
                      <TextField
                        v-model="model.ockovanie.pocet_davok"
                        type="number"
                        :label="$vuetify.t('Počet dávok')"
                        name="pocet_davok"
                        v-validate="'numeric|max_value:999'"
                        :disabled="readonly"
                        :maxlength="3"
                      ></TextField>
                    </v-flex>
                    <v-flex xs12 sm4 v-show="isVykonane">
                      <TextField
                        v-model="model.ockovanie.poradie_davky"
                        type="number"
                        :label="$vuetify.t('Poradie dávky')"
                        name="poradie"
                        v-validate="'numeric|max_value:999'"
                        :disabled="readonly"
                        :maxlength="3"
                      ></TextField>
                    </v-flex>
                    <v-flex xs12 sm4 v-show="isVykonane">
                      <v-checkbox
                        v-model="model.ockovanie.posledna_davka"
                        :label="$vuetify.t('Posledná dávka')"
                        :disabled="readonly"
                      ></v-checkbox>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-expand-transition>

        <!-- Vakcina -->
        <v-expand-transition>
          <v-flex xs12 v-show="isVykonane">
            <v-card flat>
              <v-system-bar>
                <v-card-title>
                  <span class="subheading">{{ $vuetify.t("Vakcína") }}</span>
                </v-card-title>
              </v-system-bar>
              <v-card-text class="px-2">
                <v-container fluid class="pa-0" grid-list-md>
                  <v-layout row wrap>
                    <v-flex xs12 v-show="isVykonane">
                      <codebook-autocomplete
                        :codebook-id="enums.CodebookIDLiek"
                        name="ockovacia_latka"
                        v-model="model.ockovanie.ockovacia_latka"
                        :label="$vuetify.t('Očkovacia látka')"
                        :disabled="readonly"
                        :custom-params="{
                          liek_filter: {
                            ockovacia_latka: true,
                          },
                        }"
                      >
                        <template slot="selection" slot-scope="{ item }">
                          <span>
                            {{ item.display_name }}
                            {{ item.popis }}
                          </span>
                        </template>
                        <template slot="item" slot-scope="{ item }">
                          <v-list-tile-content>
                            <v-list-tile-title>
                              <span>{{ item.display_name }}</span>
                            </v-list-tile-title>
                            <v-list-tile-sub-title>
                              <span class="text-truncate">
                                {{ item.popis }}
                              </span>
                            </v-list-tile-sub-title>
                          </v-list-tile-content>
                        </template>
                      </codebook-autocomplete>
                    </v-flex>
                    <v-flex xs12 v-show="isVykonane">
                      <v-textarea
                        v-model="model.ockovanie.ockovacia_latka_poznamka"
                        :label="$vuetify.t('Poznámka k očkovacej látke')"
                        v-validate="'max:2000'"
                        data-vv-name="ockovacia_latka_poznamka"
                        :data-vv-as="$vuetify.t('Poznámka k očkovacej látke')"
                        rows="1"
                        auto-grow
                        :disabled="readonly"
                        :counter="2000"
                      ></v-textarea>
                    </v-flex>
                    <v-flex xs12 v-show="isVykonane">
                      <v-textarea
                        v-model="model.ockovanie.sarza"
                        :label="$vuetify.t('Šarža')"
                        v-validate="'max:2000'"
                        data-vv-name="sarza"
                        :data-vv-as="$vuetify.t('Šarža')"
                        rows="1"
                        auto-grow
                        :disabled="readonly"
                        :counter="2000"
                      ></v-textarea>
                    </v-flex>
                    <v-flex xs12 sm3 v-show="isVykonane">
                      <TextField
                        v-model="model.ockovanie.mnozstvo"
                        type="number"
                        name="mnozstvo"
                        :label="$vuetify.t('Množstvo')"
                        :disabled="readonly"
                      ></TextField>
                    </v-flex>
                    <v-flex xs12 sm3 v-show="isVykonane">
                      <codebook-autocomplete
                        :codebook-id="enums.CodebookIDMerneJednotky"
                        name="merna_jednotka"
                        v-model="model.ockovanie.merna_jednotka"
                        :label="$vuetify.t('Meracia jednotka')"
                        :disabled="readonly"
                      >
                      </codebook-autocomplete>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-expand-transition>

        <!-- TODO naco tu je diagnoza?? -->
        <!-- <v-expand-transition> -->
        <!--   <v-flex xs12 sm6 v-show="isVykonane"> -->
        <!--     <codebook-autocomplete -->
        <!--       :codebook-id="enums.CodebookIDDiagnoza" -->
        <!--       name="diagnoza" -->
        <!--       v-model="model.ockovanie.diagnoza" -->
        <!--       :label="$vuetify.t('Diagnóza')" -->
        <!--       :disabled="readonly" -->
        <!--     > -->
        <!--       <template v-slot:selection="{ item }" -->
        <!--         ><span -->
        <!--           >{{ item.skratka }} - {{ item.display_name }}</span -->
        <!--         ></template -->
        <!--       > -->
        <!--       <template v-slot:item="{ item }"> -->
        <!--         <v-list-tile-content> -->
        <!--           <v-list-tile-title v-html="item.skratka"></v-list-tile-title> -->
        <!--           <v-list-tile-sub-title -->
        <!--             v-html="item.display_name" -->
        <!--           ></v-list-tile-sub-title> -->
        <!--         </v-list-tile-content> -->
        <!--       </template> -->
        <!--     </codebook-autocomplete> -->
        <!--   </v-flex> -->
        <!-- </v-expand-transition> -->

        <!-- TODO vykon zatial zakomentovany -->
        <!-- <v-expand-transition> -->
        <!--   <v-flex xs12 sm6 v-show="isVykonane"> -->
        <!--     <codebook-autocomplete -->
        <!--       :codebook-id="enums.CodebookIDVykon" -->
        <!--       name="vykon" -->
        <!--       v-model="model.ockovanie.vykon" -->
        <!--       :label="$vuetify.t('Výkon')" -->
        <!--       :disabled="readonly" -->
        <!--     > -->
        <!--       <template slot="selection" slot-scope="{ item }"> -->
        <!--         <span>{{ item.code }} - {{ item.popis }}</span> -->
        <!--       </template> -->
        <!--       <template slot="item" slot-scope="{ item }"> -->
        <!--         <v-list-tile-content> -->
        <!--           <v-list-tile-title v-html="item.code"></v-list-tile-title> -->
        <!--           <v-list-tile-sub-title -->
        <!--             v-html="item.popis" -->
        <!--           ></v-list-tile-sub-title> -->
        <!--         </v-list-tile-content> -->
        <!--       </template> -->
        <!--     </codebook-autocomplete> -->
        <!--   </v-flex> -->
        <!-- </v-expand-transition> -->

        <v-expand-transition>
          <v-flex xs12 v-show="isZrusene">
            <codebook-autocomplete
              :codebook-id="enums.CodebookIDDovodNevykonaniaOckovania"
              name="dovod_zrusenia"
              v-model="model.ockovanie.dovod_zrusenia"
              :label="$vuetify.t('Dôvod zrušenia')"
              :disabled="readonly"
              :limit="0"
            >
            </codebook-autocomplete>
          </v-flex>
        </v-expand-transition>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
import BaseForm from "./../BaseForm.vue"
import DatePicker from "./../DatePicker.vue"
import * as Miscella from "./../../miscella.js"
import moment from "moment"
import TextField from "./../TextField.vue"
import * as enums from "@/plugins/enums.js"
import CodebookAutocomplete from "./../ciselnikComponents/CodebookAutocomplete.vue"

export default {
  name: "FormOckovanie",
  extends: BaseForm,
  $_veeValidate: {
    validator: "new", // give me my own validator scope.
  },
  props: {
    pacientId: {
      type: String,
      required: true,
    },
  },
  components: {
    TextField,
    DatePicker,
    CodebookAutocomplete,
  },
  data: function () {
    return {
      readonly: false,
      model: null,
    }
  },
  computed: {
    isPlanned: function () {
      return this.model.ockovanie.stav === enums.StavOckovania.Planovane
    },
    isVykonane: function () {
      return this.model.ockovanie.stav === enums.StavOckovania.Vykonane
    },
    isZrusene: function () {
      return this.model.ockovanie.stav === enums.StavOckovania.Zrusene
    },
    ambulanceId: function () {
      let id = null
      if (Miscella.isSet(this.$store.state.me.ambulance)) {
        id = this.$store.state.me.ambulance.id
      }
      return id
    },
    enums: function () {
      return enums
    },
  },
  methods: {
    new: async function () {
      this.init()
      this.$emit("activated")
    },
    edit: async function (thing, readonly = false) {
      this.init()
      this.readonly = readonly
      this.model = Miscella.cloneObject(thing)
      this.$emit("activated")
    },
    init: function () {
      this.readonly = false
      this.model = {
        id: "",
        patient_id: this.pacientId,
        ambulance_id: this.ambulanceId,
        date: moment().format(),
        parent_id: "",
        ockovanie: {
          stav: enums.StavOckovania.Vykonane,
          datum_vykonane: null,
          datum_plan: null,
          druhy_ockovania: null,
          poznamka: "",
          typ_ockovania: null,
          pocet_davok: 0,
          poradie_davky: 0,
          posledna_davka: false,
          ockovacia_latka: null,
          ockovacia_latka_poznamka: "",
          sarza: "",
          dovod_zrusenia: null,
          vykon: null,
          diagnoza: null,
          mnozstvo: 0,
          merna_jednotka: null,
        },
      }
      this.$validator.reset()
      this.errors.clear()
    },
    validate: async function () {
      this.errors.clear()
      await this.$validator.validateAll()
      if (
        this.isPlanned &&
        !moment(this.model.ockovanie.datum_plan).isValid()
      ) {
        this.errors.add({
          field: "datum_plan",
          msg: this.$vuetify.t("Pri plánovanom očkovaní je dátum povinný."),
        })
      }
      if (
        this.isZrusene &&
        !moment(this.model.ockovanie.datum_plan).isValid()
      ) {
        this.errors.add({
          field: "datum_plan",
          msg: this.$vuetify.t(
            "Pri zrušení plánovaného očkovania je dátum povinný." //ma sa posielat do eHealthu!
          ),
        })
      }
      if (
        this.isVykonane &&
        !moment(this.model.ockovanie.datum_vykonane).isValid()
      ) {
        this.errors.add({
          field: "datum_vykonane",
          msg: this.$vuetify.t("Pri vykonanom očkovaní je dátum povinný."),
        })
      }
      if (
        this.model.ockovanie.vykon !== null &&
        this.model.ockovanie.diagnoza === null
      ) {
        this.errors.add({
          field: "diagnoza",
          msg: this.$vuetify.t("Ak je zadaný výkon, diagnóza je povinná."),
        })
      }
      return !this.errors.any()
    },
    save: async function () {
      var params = { thing: Miscella.cloneObject(this.model) }
      if (moment(this.model.ockovanie.datum_vykonane).isValid()) {
        params.thing.ockovanie.datum_vykonane = moment(
          this.model.ockovanie.datum_vykonane
        ).format()
      }
      if (moment(this.model.ockovanie.datum_plan).isValid()) {
        params.thing.ockovanie.datum_plan = moment(
          this.model.ockovanie.datum_plan
        ).format()
      }
      try {
        var result = await this.$api.thingService.saveThing(params)
        this.notifySuccess("Očkovanie bolo úspešne uložené")
        this.$emit("saved", result.thing)
        this.model = result.thing
      } catch (err) {
        this.processError(err, "Pri ukladaní očkovania vznikla chyba")
      }
    },
  },
  mounted: function () {},
  created: function () {
    this.init()
  },
}
</script>
