<template>
  <div :data-vv-as="label">
    <v-autocomplete
      ref="field"
      :label="label"
      :value="value"
      @input="updateValue"
      :id="name"
      :name="`new-${name}`"
      :error-messages="errorMessages || errors.collect(name)"
      :items="items"
      :search-input.sync="search"
      hide-no-data
      :filter="myFilter"
      :clearable="!noEmptyValue"
      return-object
      :item-text="itemText"
      :item-value="itemValue"
      :disabled="disabled"
      :hint="hint"
      persistent-hint
      :multiple="multiple"
      auto-select-first
      :browser-autocomplete="`new-${name}`"
      :prepend-inner-icon="prependInnerIcon"
    >
      <template v-slot:prepend-item v-if="showPrependedItems">
        <template v-for="item in prependedItems">
          <v-list-tile
            ripple
            :key="'prepended-item-' + item.id"
            @click="
              items = [item]
              $emit('input', item)
              $refs.field.isMenuActive = false
            "
          >
            <v-list-tile-content>
              <v-list-tile-title>{{ item.display_name }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </template>
        <v-divider class="mt-2"></v-divider>
      </template>
      <template v-slot:selection="{ item, selected }">
        <slot name="selection" :item="item">
          <v-chip
            v-if="multiple"
            :selected="selected"
            close
            class="chip--select-multi"
            @input="remove(item)"
          >
            {{ item.display_name }}
          </v-chip>
          <span v-else>{{ item.display_name }}</span>
        </slot>
      </template>
      <template v-slot:item="{ item }" v-if="!multiple">
        <!-- TODO multiple -->
        <slot name="item" :item="item">
          <v-list-tile-content>
            <v-list-tile-title v-html="item.display_name"></v-list-tile-title>
          </v-list-tile-content>
        </slot>
      </template>
    </v-autocomplete>
  </div>
</template>

<script type="text/babel">
import * as Miscella from "./../../miscella.js"

export default {
  name: "CodebookAutocomplete",
  inject: ["$validator"],
  props: {
    codebookId: {
      type: [String, Number],
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    value: {
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: [String, Array],
      required: false,
    },
    hint: {
      type: String,
      default: undefined,
    },
    limit: {
      type: Number,
      default: 100,
    },
    multiple: {
      //ak je multiple true tak treba nastavit limit na 0
      type: Boolean,
      default: false,
    },
    // parametre, ktore sa aplikuju pri vsetkych requestoch
    customParams: {
      type: Object,
      required: false,
      default: function () {
        return {}
      },
    },
    // parametre, ktore sa aplikuju pre ziskanie prioritnych hodnot
    prependedItemsParams: {
      type: Object,
      required: false,
      default: null,
    },
    // funkcia, ktora umozni spracovat prioritne hodnoty lubovolnym sposobom, napr. zotriedit, vola sa po ziskani hodnot zo servera
    prependedItemsProcessFunc: {
      type: Function,
      default: function () {},
    },
    itemValue: {
      type: String,
      default: "id",
    },
    itemText: {
      type: String,
      default: "display_name",
    },
    noEmptyValue: {
      type: Boolean,
      default: false,
    },
    prependInnerIcon: {
      type: String,
      default: undefined,
    },
    // fetch values when search field is empty?
    fetchOnEmpty: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      items: [],
      search: "",
      prependedItems: [],
    }
  },
  computed: {
    showPrependedItems: function () {
      return (
        this.prependedItems !== null &&
        this.prependedItems.length > 0 &&
        Miscella.isEmpty(this.value) &&
        Miscella.isEmpty(this.search)
      )
    },
  },
  watch: {
    value: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (!Miscella.isSet(newVal)) {
          this.search = ""
        } else {
          if (this.limit > 0) {
            if (!this.multiple) {
              this.myDelay(() => {
                this.items = [newVal]
              }, 500)
            }
            // TODO vyriesit multiple
          }
        }
      }
    },
    search: function (newVal, oldVal) {
      if (
        this.search !== null &&
        ((this.fetchOnEmpty && Miscella.isEmpty(this.search)) ||
          this.search.length > 2)
      ) {
        this.myDelay(() => {
          this.fetchData()
        }, 500)
      }
    },
  },
  methods: {
    myFilter: function (item, queryText, itemText) {
      if (queryText.length < 3) {
        return (
          itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1
        )
      }
      return true
    },
    focus: function () {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.field.$refs.input.focus()
        }, 0)
      })
    },
    updateValue: function (value) {
      this.$emit("input", value)
    },
    fetchPrependedItems: async function () {
      if (!this.prependedItemsParams) {
        return
      }
      try {
        this.items = []
        var params = this.prependedItemsParams
        params.codebook_ids = [this.codebookId]
        params.table_request = {
          limit: 50,
        }
        var data = await this.$api.codebookService.listCodebookItems(params)
        this.prependedItems = data.items
        this.prependedItemsProcessFunc(this.prependedItems)
      } catch (err) {
        this.processError(err)
      }
    },
    fetchData: async function () {
      try {
        this.items = []
        var params = this.customParams
        params.codebook_ids = [this.codebookId]
        params.table_request = {
          fulltext_search: Miscella.isEmpty(this.search) ? "" : this.search,
          limit: this.limit,
          sort_by: "displayName",
        }
        var data = await this.$api.codebookService.listCodebookItems(params)
        //console.log("CODEBOOK_AUTOCOMPLETE, fetchData", this.value)
        this.items = data.items
        if (Miscella.isSet(this.value)) {
          const found = this.items.find(
            (element) => element.id === this.value.id
          )
          if (!found) {
            //console.log("CODEBOOK_AUTOCOMPLETE, add value to items", this.value)
            this.items.push(this.value)
          }
        }
        if (
          this.noEmptyValue &&
          this.items.length > 0 &&
          !Miscella.isSet(this.value)
        ) {
          this.updateValue(this.items[0])
        }
      } catch (err) {
        this.processError(err)
      }
    },
    remove: function (item) {
      //const index = this.value.indexOf(item)
      const index = this.value.map((itm) => itm.id).indexOf(item.id)
      if (index >= 0) this.value.splice(index, 1)
      this.updateValue(this.value)
    },
  },
  mounted: function () {
    //console.log("CODEBOOK_AUTOCOMPLETE, mounted", this.value)
    if (this.fetchOnEmpty) {
      this.myDelay(() => {
        this.fetchPrependedItems()
        this.fetchData()
      }, 500)
    }
  },
}
</script>
