<template>
  <v-form>
    <v-container fluid v-if="model != null">
      <v-layout row>
        <v-flex xs6>
          <TextField
            v-model="model.MenoAPriezvisko.KrstneMeno"
            name="meno"
            :label="$vuetify.t('Meno')"
            :disabled="readonly"
          ></TextField>
        </v-flex>
        <v-flex xs6>
          <TextField
            v-model="model.MenoAPriezvisko.Priezvisko"
            name="priezvisko"
            :label="$vuetify.t('Priezvisko')"
            :disabled="readonly"
          ></TextField>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs6>
          <TextField
            v-model="model.Adresa.Ulica"
            name="ulica"
            :label="$vuetify.t('Ulica')"
            :disabled="readonly"
          ></TextField>
        </v-flex>
        <v-flex xs6>
          <TextField
            v-model="model.Adresa.CisloPopisne"
            name="cisloPopisne"
            :label="$vuetify.t('Popisné číslo')"
            :disabled="readonly"
          ></TextField>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs6>
          <TextField
            v-model="model.Adresa.PSC.IdPolozkaCiselnika.displayName"
            name="psc"
            :label="$vuetify.t('PSČ')"
            :disabled="readonly"
          ></TextField>
        </v-flex>
        <v-flex xs6>
          <TextField
            v-model="model.Adresa.Mesto.IdPolozkaCiselnika.displayName"
            name="mesto"
            :label="$vuetify.t('Mesto')"
            :disabled="readonly"
          ></TextField>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs6>
          <TextField
            v-model="model.Adresa.Krajina.IdPolozkaCiselnika.displayName"
            name="krajina"
            :label="$vuetify.t('Krajina')"
            :disabled="readonly"
          ></TextField>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs6>
          <TextField
            v-model="model.Telefon"
            name="telefon"
            :label="$vuetify.t('Telefón')"
            :disabled="readonly"
          ></TextField>
        </v-flex>
        <v-flex xs6>
          <TextField
            v-model="model.Email"
            name="email"
            :label="$vuetify.t('Email')"
            :disabled="readonly"
          ></TextField>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs12>
          <TextField
            v-model="model.Poznamka"
            name="poznamka"
            :label="$vuetify.t('Poznámka')"
            :disabled="readonly"
          ></TextField>
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
import BaseForm from "../BaseForm.vue"
import * as Miscella from "../../miscella.js"
import TextField from "../TextField.vue"

export default {
  name: "FormPacSumarKontUdajePrefZdrPracovnik",
  extends: BaseForm,
  $_veeValidate: {
    validator: "new", // give me my own validator scope.
  },
  props: {},
  components: {
    TextField,
  },
  data: function () {
    return {
      model: null,
    }
  },
  computed: {},
  watch: {},
  methods: {
    new: async function () {
      this.init()
      this.fetchData()
      this.$emit("activated")
    },
    edit: async function (thing) {
      this.init()
      this.model = Miscella.cloneObject(thing)
      this.$emit("activated")
    },
    init: function () {
      this.model = {
        IdZaznamu: 0,
        MenoAPriezvisko: {
          KrstneMeno: "",
          Priezvisko: "",
        },
        Adresa: {
          Ulica: "",
          CisloPopisne: "",
          PSC: {
            IdPolozkaCiselnika: {
              codeValue: "",
              displayName: "",
            },
          },
          Mesto: {
            IdPolozkaCiselnika: {
              codeValue: "",
              displayName: "",
            },
          },
          Krajina: {
            IdPolozkaCiselnika: {
              codeValue: "",
              displayName: "",
            },
          },
        },
        Telefon: "",
        Poznamka: "",
        Email: "",
      }
      this.$validator.reset()
      this.errors.clear()
    },
    validate: async function () {
      await this.$validator.validateAll()
      return !this.errors.any()
    },
    save: async function () {
      /*
      var params = { thing: Miscella.cloneObject(this.model) }
      try {
        var result = await this.$api.thingService.saveThing(params)
        this.notifySuccess("Očkovanie bolo úspešne uložená")
        this.$emit("saved", result.thing)
        this.model = result.thing
      } catch (err) {
        this.processError(err, "Pri ukladaní očkovania vznikla chyba")
      }
      */
    },
    fetchData: function () {},
  },
  mounted: function () {},
  created: function () {
    this.init()
  },
}
</script>
