<template>
  <div>
    <div v-if="!compIsInput">
      <div v-if="formItemDefinition.typ_polozka === 'subheader'">
        <v-subheader class="subtitle-2 afSubheader">{{
          formItemDefinition.label
        }}</v-subheader>
      </div>
      <div v-if="formItemDefinition.typ_polozka === 'separator'">
        <v-divider />
      </div>
    </div>
    <div v-if="compIsInput">
      <component
        v-if="compTyp !== null"
        :is="compTyp"
        :value="valueOrDefault"
        @input="updateValue"
        :label="formItemDefinition.label"
        :hint="formItemDefinition.hint"
        :rows="lines"
        :type="formItemDefinition.typ_polozka === 'number' ? 'number' : 'text'"
        persistent-hint
        auto-grow
        :maxlength="maxlength"
        :counter="maxlength"
        :rules="compRules"
        clearable
        :mask="mask"
        v-on:change="onChangeScript"
        ref="comp"
      ></component>
      <template v-if="formItemDefinition.typ_polozka === 'slider'">
        <v-slider
          :value="valueOrDefault"
          @input="updateValue"
          :label="formItemDefinition.label"
          :hint="formItemDefinition.hint"
          persistent-hint
          :max="max"
          :min="min"
          thumb-label="always"
          :rules="compRules"
        ></v-slider>
      </template>
      <v-select
        v-if="formItemDefinition.typ_polozka === 'combobox'"
        :value="valueOrDefault"
        @input="updateValue"
        :label="formItemDefinition.label"
        :hint="formItemDefinition.hint"
        persistent-hint
        clearable
        :rules="selRules"
        :items="values"
        item-text="text"
        item-value="value"
        :return-object="false"
      ></v-select>
      <div
        v-if="compTyp === null && formItemDefinition.typ_polozka !== 'combobox'"
      >
        <div style="position: relative">
          <v-radio-group
            v-if="formItemDefinition.typ_polozka === 'radio'"
            :mandatory="false"
            :value="valueOrDefault"
            @change="updateValue"
            :rules="selRules"
            :label="formItemDefinition.label"
            :hint="formItemDefinition.hint"
            persistent-hint
            class="mt-0"
          >
            <v-radio
              v-for="val in values"
              :key="val.value"
              :label="val.text"
              :value="val.value"
              hide-details
            ></v-radio>
          </v-radio-group>
          <v-icon
            v-if="formItemDefinition.typ_polozka === 'radio'"
            class="clearIcon"
            @click="clearValue()"
            >clear</v-icon
          >
        </div>
        <div v-if="formItemDefinition.typ_polozka === 'checkbox'">
          <v-subheader class="afLabel">{{
            formItemDefinition.label
          }}</v-subheader>
          <v-checkbox
            v-for="val in values"
            :key="val.value"
            :label="val.text"
            :value="val.value"
            :input-value="valueOrDefault"
            @change="updateValue"
            class="ma-0 pa-0"
            :rules="selRules"
            hide-details
          ></v-checkbox>
          <v-subheader class="afHint">{{
            formItemDefinition.hint
          }}</v-subheader>
        </div>
        <div v-if="formItemDefinition.typ_polozka === 'buttongroup'">
          <v-subheader class="afLabel">{{
            formItemDefinition.label
          }}</v-subheader>
          <v-btn-toggle
            :value="valueOrDefault"
            @change="updateValue"
            multiple
            :mandatory="false"
            :rules="selRules"
          >
            <v-btn
              v-for="val in values"
              :key="val.value"
              :value="val.value"
              flat
              >{{ val.text }}</v-btn
            >
          </v-btn-toggle>
          <v-subheader class="afHint">{{
            formItemDefinition.hint
          }}</v-subheader>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VTextField, VTextarea } from "vuetify/lib"
export default {
  components: {},
  data: function () {
    return {
      valid: false,
      showSource: false,
      innerValue: null,
    }
  },
  props: {
    formItemDefinition: {
      type: Object,
      required: true,
    },
    value: {
      default: null,
      required: false,
    },
  },
  watch: {},
  computed: {
    defaultValue: function () {
      switch (this.formItemDefinition.typ_polozka) {
        case "checkbox":
        case "buttongroup":
          return []
      }
      return null
    },
    valueOrDefault: function () {
      if (!this.isSet(this.value)) {
        return this.defaultValue
      }
      return this.value
    },
    multi: function () {
      return (
        this.formItemDefinition.typ_polozka === "checkbox" ||
        this.formItemDefinition.typ_polozka === "buttongroup"
      )
    },
    compIsInput: function () {
      return (
        this.formItemDefinition.typ_polozka !== "subheader" &&
        this.formItemDefinition.typ_polozka !== "separator"
      )
    },
    compTyp: function () {
      switch (this.formItemDefinition.typ_polozka) {
        case "text":
        case "number":
          return VTextField
        case "textarea":
          return VTextarea
      }
      return null
    },
    compRules: function () {
      let rules = []
      if (this.formItemDefinition.required) {
        rules.push(function (v) {
          console.log("validation required", v)
          return !!v || "Položka je povinná"
        })
      }
      if (this.minlength && this.minlength > 0) {
        rules.push(
          function (v) {
            return (
              (v && v.length >= this.minlength) ||
              "Položka musí mať aspoň " + this.minlength + " znakov"
            )
          }.bind(this)
        )
      }
      if (this.min) {
        rules.push(
          function (v) {
            return (
              (v && Number(v) >= this.min) ||
              "Položka musí byť minimálne " + this.min
            )
          }.bind(this)
        )
      }
      if (this.max !== "null" && this.max) {
        rules.push(
          function (v) {
            return (
              (v && Number(v) <= this.max) ||
              "Položka musí byť maximálne " + this.max
            )
          }.bind(this)
        )
      }
      // if (this.maxlength) {
      //   rules.push(function (v) { return v && v.length <= this.maxlength || 'Položka musí mať maximálne ' + this.maxlength + ' znakov' }.bind(this))
      // }
      if (this.email === "true" || this.email === true) {
        if (this.valueOrDefault) {
          rules.push(function (v) {
            return (
              /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
              "Email má zlý formát"
            )
          })
        }
      }
      if (rules.length > 0) {
        return rules
      } else {
        return undefined
      }
    },
    selRules: function () {
      let rules = []
      if (this.formItemDefinition.required) {
        rules.push(
          function (v) {
            let ok = false
            console.log("validation required", typeof v)
            if (this.multi) {
              if (this.isSet(v) && v.length > 0) {
                ok = true
              }
            } else {
              ok = this.isSet(v)
            }
            return ok || "Položka je povinná"
          }.bind(this)
        )
      }
      if (rules.length > 0) {
        return rules
      } else {
        return undefined
      }
    },
    // TEXT
    lines: function () {
      return this.getParam("lines", 0)
    },
    maxlength: function () {
      return this.getParam("maxlength", null)
    },
    minlength: function () {
      return this.getParam("minlength", null)
    },
    mask: function () {
      return this.getParam("mask", null)
    },
    email: function () {
      return this.getParam("email", false)
    },
    // NUMBER
    min: function () {
      return this.getParam("min", null)
    },
    max: function () {
      return this.getParam("max", null)
    },
    slider: function () {
      return this.getParam("slider", null)
    },
    float: function () {
      return this.getParam("float", null)
    },
    // CHOICE
    typ: function () {
      return this.getParam("typ", null)
    },
    values: function () {
      console.log("getting values", this)
      let m = this.formItemDefinition.values
      /*
      let m = this.getParam("values", null)
      let a = Object.entries(m).map(function(e) {
        return {id: e[0], text: e[1]}
      })*/
      return m
    },
    // SCHEMA
    img: function () {
      return this.getParam("img", null)
    },
    width: function () {
      return this.getParam("width", null)
    },
    height: function () {
      return this.getParam("height", null)
    },

    /*
| _text_      |  _lines_      | Počet riadkov formulára                                        | 
|             | _maxlength_   | Maximálny počet znakov                                         |
|             | _minlength_   | Minimálny počet znakov                                         |
|             | _mask_        | Maska pre zobrazenie                                           |
|             | _email_       | (1) Kontrola či je to email                                    |
| _number_    | _min_         | Minimálna hodnota                                              |
|             | _max_         | Maximálna hodnota                                              |
|             | _slider_      | 0 - bez slideru, 1 - aj slider, 2 - iba slider                 |
|             | _float_       | počet desatinných miest                                        | 
| _choice_    | _typ_         | Typ zobrazenia: _combo, radio, checkbox, buttongroup_          |
|             | _values_      | Zoznam zobrazených hodnôt vždy ako pár hodnôt _value_ a _text_ |
|             | _multi_       | Možnosť výberu z viacerých hodnôt                              |
| _schema_    | _img_         | Binárny zápis obrázku v png                                    |
|             | _width_       | Šírka                                                          |
|             | _height_      | Výška                                                          |

*/
  },
  methods: {
    updateValue: function (value) {
      /*
      if (this.type === "number") {
        value = Number(value)
      }
      */
      console.log("updateValue", value, this)
      this.$emit("input", value)
    },
    clearValue: function () {
      this.updateValue(this.defaultValue)
    },
    getParam: function (paramText, paramDefault) {
      let ret = paramDefault
      if (this.isSet(this.formItemDefinition.params)) {
        if (this.isSet(this.formItemDefinition.params[paramText])) {
          ret = this.formItemDefinition.params[paramText]
        }
      }
      return ret
    },
    onChangeScript: function () {
      if (this.formItemDefinition.typ_polozka === "number" && this.float > 0) {
        if (!isNaN(this.value)) {
          this.$refs.comp.value = parseFloat(this.value).toFixed(this.float)
        }
      }
    },
    isSet: function (v) {
      return v !== undefined && v !== null
    },
  },
}
</script>

<style scoped>
.clearIcon {
  position: absolute;
  right: 16px;
  top: 0;
}
.v-subheader.afLabel {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 8px;
  padding-left: 0;
  height: 24px;
  font-size: 16px;
  font-weight: 400;
}
.v-subheader.afHint {
  padding-top: 5px;
  padding-right: 0;
  padding-bottom: 8px;
  padding-left: 0;
  height: 24px;
  font-size: 12px;
  font-weight: 400;
}
.afSubheader {
  padding: 0;
}
</style>
