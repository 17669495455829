<template>
  <v-layout row wrap>
    <v-flex xs12>
      <v-subheader class="pa-0">{{
        $vuetify.t("$vuetify.form.param.values")
      }}</v-subheader>
    </v-flex>
    <template v-for="(item, index) in values">
      <v-flex xs10 :key="'text' + index">
        <v-text-field
          :label="$vuetify.t('$vuetify.form.param.text')"
          v-model="item.text"
        ></v-text-field>
      </v-flex>
      <v-flex xs2 align-self-baseline :key="'delete' + index">
        <v-tooltip top>
          <v-btn
            flat
            top
            icon
            small
            slot="activator"
            @click="values.splice(index, 1)"
          >
            <v-icon>delete</v-icon>
          </v-btn>
          <span>{{ $vuetify.t("$vuetify.form.param.delete") }}</span>
        </v-tooltip>
      </v-flex>
    </template>
    <v-flex xs12 class="text-xs-right">
      <v-tooltip top>
        <v-btn
          color="primary"
          fab
          top
          icon
          small
          slot="activator"
          @click="addValue"
        >
          <v-icon>add</v-icon>
        </v-btn>
        <span>{{ this.$vuetify.t("$vuetify.form.param.add") }}</span>
      </v-tooltip>
    </v-flex>
  </v-layout>
</template>

<script>
import { ulid } from "ulid"

export default {
  components: {},
  data: function () {
    return {}
  },
  props: {
    params: {
      type: Object,
      required: true,
    },
    values: {
      type: Array,
      required: false,
    },
  },
  computed: {},
  methods: {
    defaultParams: function () {
      return {
        typ: "combo",
        //values: [{ text: "", value: this.createUUID() }],
        multi: false,
      }
    },

    defaultValues: function () {
      return [{ text: "", value: this.createUUID() }]
    },

    addValue: function () {
      console.log("Adding value in editorChoice", this.values)
      this.values.push({ text: "", value: this.createUUID() })
      this.$forceUpdate()
    },

    createUUID: function () {
      var dt = ulid()
      dt =
        dt.substr(0, 5) +
        "-" +
        dt.substr(5, 5) +
        "-" +
        dt.substr(10, 4) +
        "-" +
        dt.substr(14, 4) +
        "-" +
        dt.substr(18, 4) +
        "-" +
        dt.substr(22, 4)
      console.log("ULID", dt)
      return dt
    },
  },
}
</script>
