<template>
  <v-layout align-center justify-center>
    <v-flex xs12 sm8 md4>
      <v-card class="elevation-12">
        <v-toolbar flat dense card>
          <v-toolbar-title>Prihlásenie do aplikácie Ordinácia</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form>
            <text-field
              v-model="username"
              ref="usernameField"
              prepend-icon="person"
              name="username"
              label="Email"
              type="text"
              placeholder=" "
              autocomplete="username"
              v-validate="'required'"
              data-vv-name="username"
              :error-messages="errors.collect('username')"
              :data-vv-as="$vuetify.t('email')"
              :key-up-enter="sendLogin"
              data-test="email"
            ></text-field>
            <text-field-password
              v-model="password"
              id="password"
              prepend-icon="lock"
              name="password"
              label="Heslo"
              placeholder=" "
              autocomplete="current-password"
              v-validate="'required'"
              data-vv-name="password"
              :error-messages="errors.collect('password')"
              :data-vv-as="$vuetify.t('heslo')"
              :key-up-enter="sendLogin"
            ></text-field-password>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn flat @click="registration">Registrovať</v-btn>
          <v-btn flat @click="passwordReset">Zabudnuté heslo</v-btn>
          <v-spacer></v-spacer>
          <v-btn flat color="primary" @click="sendLogin" data-test="login">{{
            $vuetify.t("Prihlásiť")
          }}</v-btn>
        </v-card-actions>
      </v-card>
      <v-card class="elevation-12 mt-4" v-if="demo">
        <v-card-text>
          Pre prístup do <b>DEMO</b> aplikácie zadajte<br />
          <b>prihlasovacie meno:</b> lekar@mydoctor.sk,
          <b>heslo:</b> 12345<br />
          alebo<br />
          <b>prihlasovacie meno:</b> sestra@mydoctor.sk, <b>heslo:</b> 12345.
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import loginMixin from "@/components/mixins/login.js"
import textField from "@/components/TextField.vue"
import textFieldPassword from "@/components/TextFieldPassword.vue"
import { mapState } from "vuex"

export default {
  name: "Login",
  components: { textFieldPassword, textField },
  $_veeValidate: {
    validator: "new",
  },
  mixins: [loginMixin],
  data: function () {
    return {
      username: "",
      password: "",
    }
  },
  computed: {
    ...mapState({
      demo: (state) => state.demo,
    }),
  },
  methods: {
    sendLogin: function () {
      this.login(this.username, this.password)
    },
    registration: function () {
      this.$router.push("registration")
    },
    passwordReset: function () {
      this.$router.push("passwordReset")
    },
  },
  mounted: function () {
    this.$refs.usernameField.focus()
  },
}
</script>

<style>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
