<template>
  <v-container pa-3>
    <v-layout row wrap>
      <v-flex xs12 v-if="!isHelpDeskAllowed">
        <v-alert :value="true" :type="'warning'" class="py-2" outline>
          {{ $vuetify.t("$vuetify.helpdesk.notAllowed") }}
        </v-alert>
      </v-flex>
      <v-flex v-else>
        <div style="position: absolute; top: 4px; right: 4px">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <div v-on="on" @click="openNewWindow" class="clicker">
                <v-icon>open_in_new</v-icon>
              </div>
            </template>
            <span>{{ $vuetify.t("Otvoriť v novom okne") }}</span>
          </v-tooltip>
        </div>
        <iframe
          width="400"
          height="500"
          :src="helpdeskUrl"
          v-if="userID !== '' && helpdeskUrl !== ''"
          style="border: none"
          scrolling="no"
        ></iframe>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import config from "../../../app.config.js"
import * as Miscella from "./../../miscella.js"

export default {
  props: {
    isHelpDeskAllowed: {
      type: Boolean,
      required: true,
    },
  },
  components: {},
  data: function () {
    return {}
  },
  watch: {},
  computed: {
    userID: function () {
      let ret = ""
      if (Miscella.isSet(this.$store.state.me)) {
        if (Miscella.isSet(this.$store.state.me.user)) {
          ret = this.$store.state.me.user.id
        }
      }
      return ret
    },
    helpdeskUrl: function () {
      let ret = ""
      if (config.helpdeskClientUrl !== null) {
        ret = `${config.helpdeskClientUrl}/?id=${this.userID}`
      }
      return ret
    },
  },
  methods: {
    openNewWindow: function () {
      window.open(
        this.helpdeskUrl,
        "helpDeskWindow",
        "width=400,height=500,menubar=no,location=no,resizable=yes,scrollbars=yes,status=no"
      )
    },
  },
}
</script>
