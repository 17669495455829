<template>
  <v-layout column>
    <BaseTable
      :headers="headers"
      :customParams="{ clinic_id: clinic ? clinic.id : null }"
      :title="$vuetify.t('Zoznam používateľov')"
      ref="baseTable"
      @reload="fetchItems"
    >
      <template slot="toolbarItems">
        <v-tooltip top>
          <v-btn icon slot="activator" @click="addUser">
            <v-icon>add</v-icon>
          </v-btn>
          <span>{{ $vuetify.t("Pozvať používateľa na kliniku") }}</span>
        </v-tooltip>
      </template>
      <template slot="items" slot-scope="props">
        <tr @click="viewItem(props.item)">
          <slot name="item" :item="props.item">
            <td>{{ props.item.name }}</td>
            <td>{{ props.item.email }}</td>
            <td>{{ props.item.phone }}</td>
            <td></td>
          </slot>
        </tr>
      </template>
    </BaseTable>
    <BaseDialog
      :dialogTitle="$vuetify.t('Pozvanie používateľa na kliniku')"
      ref="dialogPozvaniePouzivatelaNaKliniku"
    >
      <FormPozvaniePouzivatelaNaKliniku
        ref="formPozvaniePouzivatelaNaKliniku"
        @saved="reloadTable"
      ></FormPozvaniePouzivatelaNaKliniku>
    </BaseDialog>
    <!-- <UserDialog ref="userDialog" @save="reloadTable" /> -->
  </v-layout>
</template>

<script>
import BaseDialog from "./../../components/BaseDialog.vue"
import BaseTable from "./../../components/BaseTable.vue"
import UserDialog from "./../../components/dialogs/UserDialog.vue"
import FormPozvaniePouzivatelaNaKliniku from "@/components/forms/FormPozvaniePouzivatelaNaKliniku.vue"
import * as Miscella from "./../../miscella.js"
import Vue from "vue"

export default {
  name: "users",
  components: {
    BaseDialog,
    FormPozvaniePouzivatelaNaKliniku,
    BaseTable,
  },
  data: function () {
    return {
      headers: [
        { text: "Meno", value: "name", sortable: true },
        { text: "Email", value: "email", sortable: true },
        { text: "Telefón", value: "phone", sortable: true },
        { text: "", sortable: false },
      ],
    }
  },
  computed: {
    clinic: function () {
      return this.$store.state.me.clinic
    },
    usersCount: function () {
      if (this.$refs.baseTable) {
        return this.$refs.baseTable.data.tableData.length
      }
      return 0
    },
    activeUsersCount: function () {
      if (this.$refs.baseTable) {
        return this.$refs.baseTable.data.tableData.filter(
          (user) => user.isActive
        ).length
      }
      return 0
    },
    canAddUser: function () {
      return (
        Miscella.isSet(this.clinic) &&
        this.clinic.isPaid &&
        this.usersCount > 0 &&
        this.clinic.subscriptionLevel > this.activeUsersCount - 1
      )
    },
  },
  methods: {
    viewItem: function (item) {
      //this.$refs.userDialog.show(item)
    },
    addUser: function () {
      /*
      if (this.canAddUser) {
        this.$refs.userDialog.add()
      } else {
        this.$store.commit(
          "setInfo",
          this.$vuetify.t(
            "Nie je možné pridať nového používateľa. Počet aktívnych používateľov by presiahol počet predplatených používateľov!"
          )
        )
      }
      */
      this.$refs.formPozvaniePouzivatelaNaKliniku.init()
      this.$refs.dialogPozvaniePouzivatelaNaKliniku.open()
    },
    toggleIsActive: async function (user) {
      if (user.id === this.clinic.adminID) {
        this.$store.commit(
          "setInfo",
          this.$vuetify.t("Nie je možné deaktivovať administrátora!")
        )
        return
      }
      if (!user.isActive && !this.canAddUser) {
        this.$store.commit(
          "setInfo",
          this.$vuetify.t(
            "Nie je možné aktivovať používateľa. Počet aktívnych používateľov by presiahol počet predplatených používateľov!"
          )
        )
        return
      }
      user.isActive = !user.isActive
      await this.$api.post("/api/user", user)

      this.$store.commit(
        "setSuccess",
        this.$vuetify.t(
          "Používateľ bol úspešne " +
            (user.isActive ? "aktivovaný." : "deaktivovaný.")
        )
      )
      this.reloadTable()
    },
    reloadTable: function () {
      this.$refs.baseTable.reload()
    },
    newUserAccessToken: async function (user) {
      await this.$api.post(`/api/user/${user.id}/newAccessToken`, {})
      this.$store.commit(
        "setSuccess",
        this.$vuetify.t(
          "Používateľovi bude v najbližšom čase doručená správa s odkazom na zmenu hesla"
        )
      )
    },
    fetchItems: function () {
      let url = "/v1/proto.UserService/ListUsers"
      let t = this.$refs.baseTable
      var params = t.customParams
      t.loading = true
      return this.$api
        .post(url, params)
        .then((data) => {
          t.setItems(data.users)
        })
        .catch((error) => {
          console.log("xErr:", error)
        })
        .finally(() => {
          Vue.nextTick(() => {
            t.loading = false
          })
        })
    },
  },
  mounted: function () {
    this.reloadTable()
  },
}
</script>

<style scoped></style>
