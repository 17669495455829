<template>
  <v-layout column v-if="pacient">
    <v-layout row justify-end>
      <v-btn
        color="primary"
        flat
        @click="dajPSKontaktneUdajeSNaplnenimPacienta"
        :disabled="!isEhealthReady"
        >{{ $vuetify.t("Načítať z ezdravia") }}</v-btn
      >
      <v-btn
        color="primary"
        flat
        @click="zapisPSKontaktneUdaje"
        :disabled="!isEhealthReady"
        >{{ $vuetify.t("Uložiť do ezdravia") }}</v-btn
      >
    </v-layout>
    <v-tabs v-model="activeTab">
      <v-tab>{{ $vuetify.t("Základné info") }}</v-tab>
      <v-tab>{{ $vuetify.t("Kontaktné osoby") }}</v-tab>
      <v-tab>{{ $vuetify.t("Preferovaní zdr. pracovníci") }}</v-tab>
      <v-tab>{{ $vuetify.t("Dátumy") }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="activeTab">
      <v-tab-item>
        <v-container fluid class="pa-0">
          <v-layout row>
            <v-flex xs12>
              <v-container fluid>
                <v-layout row wrap>
                  <v-flex xs12 class="text-xs-right">
                    <v-btn
                      color="primary"
                      flat
                      @click="editInfo"
                      align-self-end
                      >{{ $vuetify.t("Zmeniť") }}</v-btn
                    >
                  </v-flex>
                  <v-flex xs2>
                    <span class="nadpis">{{ $vuetify.t("Meno") }}:</span>
                  </v-flex>
                  <v-flex xs10>
                    <span class="hodnota">{{ pacient.full_name }}</span>
                  </v-flex>
                  <v-flex xs2>
                    <span class="nadpis">{{ $vuetify.t("Rodné číslo") }}:</span>
                  </v-flex>
                  <v-flex xs10>
                    <span class="hodnota">{{ pacient.person_unique_id }}</span>
                    <span
                      v-if="jeDovera && pacient.person_unique_id !== ''"
                      class="pl-2"
                    >
                      <a @click="openDovera()">On-line</a>
                    </span>
                  </v-flex>
                  <v-flex xs2>
                    <span class="nadpis">{{ $vuetify.t("Pohlavie") }}:</span>
                  </v-flex>
                  <v-flex xs10>
                    <span class="hodnota">{{
                      $vuetify.t("$vuetify.enum.sex." + pacient.sex)
                    }}</span>
                  </v-flex>
                  <v-flex xs2>
                    <span class="nadpis"
                      >{{ $vuetify.t("Dátum narodenia") }}:</span
                    >
                  </v-flex>
                  <v-flex xs10>
                    <span class="hodnota">{{
                      pacient.birthdate | formatDatum
                    }}</span>
                  </v-flex>
                  <v-flex xs2>
                    <span class="nadpis">{{ $vuetify.t("Vek") }}:</span>
                  </v-flex>
                  <v-flex xs10>
                    <span class="hodnota">{{ vekString }}</span>
                  </v-flex>
                  <v-flex xs2>
                    <span class="nadpis"
                      >{{ $vuetify.t("Zdravotná poisťovňa") }}:</span
                    >
                  </v-flex>
                  <v-flex xs10>
                    <span class="hodnota">{{
                      pacient.zdravotna_poistovna
                        ? pacient.zdravotna_poistovna.display_name
                        : ""
                    }}</span>
                  </v-flex>
                </v-layout>
              </v-container>
              <v-container fluid>
                <v-layout row>
                  <v-flex xs2>
                    <span class="nadpis">{{ $vuetify.t("Telefón") }}:</span>
                  </v-flex>
                  <v-flex xs10>
                    <span class="hodnota">{{ pacient.phone }}</span>
                  </v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex xs2>
                    <span class="nadpis"> {{ $vuetify.t("Email") }}: </span>
                  </v-flex>
                  <v-flex xs10>
                    <span class="hodnota">
                      <a :href="`mailto:${pacient.email}`">
                        {{ pacient.email }}
                      </a>
                    </span>
                  </v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex xs2>
                    <span class="nadpis">{{ $vuetify.t("Ulica") }}:</span>
                  </v-flex>
                  <v-flex xs10>
                    <span class="hodnota"
                      >{{ pacient.address.street }}
                      {{ pacient.address.street_number }}</span
                    >
                  </v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex xs2>
                    <span class="nadpis">{{ $vuetify.t("PSČ") }}:</span>
                  </v-flex>
                  <v-flex xs10>
                    <span class="hodnota">{{
                      pacient.address.postal_code_cb
                        ? pacient.address.postal_code_cb.display_name
                        : pacient.address.postal_code
                    }}</span>
                  </v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex xs2>
                    <span class="nadpis">{{ $vuetify.t("Mesto") }}:</span>
                  </v-flex>
                  <v-flex xs10>
                    <span class="hodnota">
                      {{
                        pacient.address.city_cb
                          ? pacient.address.city_cb.display_name
                          : pacient.address.city
                      }}
                    </span>
                  </v-flex>
                </v-layout>

                <v-layout row>
                  <v-flex xs2>
                    <span class="nadpis">{{ $vuetify.t("Krajina") }}:</span>
                  </v-flex>
                  <v-flex xs10>
                    <span class="hodnota">{{
                      pacient.address.country_cb
                        ? pacient.address.country_cb.display_name
                        : ""
                    }}</span>
                  </v-flex>
                </v-layout>
              </v-container>
              <v-container fluid>
                <v-layout row>
                  <v-flex xs2>
                    <span class="nadpis">{{ $vuetify.t("Poznámka") }}:</span>
                  </v-flex>
                  <v-flex xs10>
                    <span class="hodnota">{{ pacient.note }}</span>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-flex>
          </v-layout>
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <v-container fluid>
          <v-layout row>
            <v-flex class="text-xs-right">
              <v-btn
                color="primary"
                flat
                @click="addInfoOsoba"
                align-self-end
                >{{ $vuetify.t("Pridať") }}</v-btn
              >
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex>
              <v-card>
                <v-list
                  three-line
                  subheader
                  dense
                  v-if="pacient.contact_persons.length > 0"
                >
                  <template v-for="(kontakt, index) in pacient.contact_persons">
                    <v-list-tile
                      :key="'list' + index"
                      @click="editKontakt(kontakt, index)"
                    >
                      <v-list-tile-content>
                        <v-container fluid>
                          <v-layout row>
                            <v-flex xs2 xl1>
                              <span class="lbl">{{ $vuetify.t("Meno") }}:</span
                              ><br />
                              <span class="lbl"
                                >{{ $vuetify.t("Priezvisko") }}:</span
                              >
                            </v-flex>
                            <v-flex xs4 xl2>
                              {{ kontakt.name }}<br />
                              {{ kontakt.surname }}
                            </v-flex>
                            <v-flex xs2 xl1>
                              <span class="lbl"
                                >{{ $vuetify.t("Telefón") }}:</span
                              ><br />
                              <span class="lbl"
                                >{{ $vuetify.t("Vzťah") }}:</span
                              >
                            </v-flex>
                            <v-flex xs4 xl2>
                              {{ kontakt.phone }}<br />
                              {{
                                kontakt.affinity
                                  ? kontakt.affinity.display_name
                                  : ""
                              }}
                            </v-flex>
                            <v-flex xs2 xl1>
                              <span class="lbl">{{ $vuetify.t("Email") }}:</span
                              ><br />
                              <span class="lbl"
                                >{{ $vuetify.t("Poznámka") }}:</span
                              >
                            </v-flex>
                            <v-flex xs10 xl5>
                              {{ kontakt.email }}<br />
                              {{ kontakt.note }}
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-list-tile-content>
                      <v-list-tile-action>
                        <v-icon @click.stop="removeKontakt(index)"
                          >delete</v-icon
                        >
                      </v-list-tile-action>
                    </v-list-tile>
                    <v-divider
                      :key="'divider' + index"
                      v-if="index !== pacient.contact_persons.length - 1"
                    ></v-divider>
                  </template>
                </v-list>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <v-container fluid>
          <v-layout row>
            <v-flex class="text-xs-right">
              <v-btn
                color="primary"
                flat
                @click="addPreferovanyZdravotnickyPracovnik"
                align-self-end
                >{{ $vuetify.t("Pridať") }}</v-btn
              >
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex>
              <v-card>
                <v-list
                  three-line
                  subheader
                  dense
                  v-if="pacient.preferovani_zdravotnicki_pracovnici.length > 0"
                >
                  <template
                    v-for="(
                      pzp, index
                    ) in pacient.preferovani_zdravotnicki_pracovnici"
                  >
                    <v-list-tile
                      :key="'list' + index"
                      @click="editPreferovanyZdravotnickyPracovnik(pzp, index)"
                    >
                      <v-list-tile-content>
                        <v-container fluid>
                          <v-layout row>
                            <v-flex xs2 xl1>
                              <span class="lbl">{{ $vuetify.t("Meno") }}:</span
                              ><br />
                              <span class="lbl"
                                >{{ $vuetify.t("Priezvisko") }}:</span
                              >
                            </v-flex>
                            <v-flex xs4 xl2>
                              {{ pzp.name }}<br />
                              {{ pzp.surname }}
                            </v-flex>
                            <v-flex xs2 xl1>
                              <span class="lbl"
                                >{{ $vuetify.t("Telefón") }}:</span
                              ><br />
                              <span class="lbl"
                                >{{ $vuetify.t("Email") }}:</span
                              >
                            </v-flex>
                            <v-flex xs4 xl2>
                              {{ pzp.phone }}<br />
                              {{ pzp.email }}
                            </v-flex>
                            <v-flex xs2 xl1>
                              <span class="lbl"
                                >{{ $vuetify.t("Poznámka") }}:</span
                              >
                            </v-flex>
                            <v-flex xs10 xl5>
                              {{ pzp.note }}
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-list-tile-content>
                      <v-list-tile-action>
                        <v-icon
                          @click.stop="
                            removePreferovanyZdravotnickyPracovnik(index)
                          "
                          >delete</v-icon
                        >
                      </v-list-tile-action>
                    </v-list-tile>
                    <v-divider
                      :key="'divider' + index"
                      v-if="
                        index !==
                        pacient.preferovani_zdravotnicki_pracovnici.length - 1
                      "
                    ></v-divider>
                  </template>
                </v-list>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <v-container fluid>
          <v-layout row>
            <v-flex xs3>
              <span class="nadpis">{{ $vuetify.t("Posledná zmena") }}:</span>
            </v-flex>
            <v-flex xs9>
              <span class="hodnota">
                {{ this.pacient.updated_at | formatDatumACas }}
              </span>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs3>
              <span class="nadpis"
                >{{ $vuetify.t("Posledné vyšetrenie") }}:</span
              >
            </v-flex>
            <v-flex xs9>
              <span class="hodnota">
                {{ this.getPacientDatum("V") | formatDatum }}
              </span>
            </v-flex>
          </v-layout>
          <!-- <v-layout row>
            <v-flex xs3>
              <span class="nadpis"
                >{{ $vuetify.t("Termín budúcej kontroly") }}:</span
              >
            </v-flex>
            <v-flex xs9>
              <span class="hodnota">
                {{ kontrolaText }}
              </span>
            </v-flex>
          </v-layout> -->
          <!--  <v-layout row>
            <v-flex class="text-xs-right">
              <v-layout justify-end>
                <base-popup
                  v-if="pacient.kontrola"
                  :title="
                    $vuetify.t('Aktuálny termín kontroly: ') + kontrolaText
                  "
                >
                  <template v-slot:activator="{ on }">
                    <v-btn color="primary" flat v-on="on"
                      >{{ $vuetify.t("Termín kontroly") }}
                    </v-btn>
                  </template>
                  <template v-slot="{ toggle }">
                    <form-kontrola
                      :pacientId="id"
                      :kontrola="pacient.kontrola"
                      @saved="
                        toggle()
                        readPacient()
                      "
                    />
                  </template>
                </base-popup>
                <v-btn color="primary" flat @click="addEvent">{{
                  $vuetify.t("Nové objednanie")
                }}</v-btn>
              </v-layout>
            </v-flex>
          </v-layout> -->
          <!--           <v-layout row>
            <v-flex>
              <v-list subheader dense v-if="afterNowEvents.length">
                <v-subheader>{{ $vuetify.t("Budúce termíny") }}</v-subheader>
                <template v-for="(event, index) in afterNowEvents">
                  <v-list-tile
                    :key="'list' + event.ID"
                    @click="editEvent(event)"
                  >
                    <v-list-tile-content>
                      <v-container fluid>
                        <v-layout row>
                          <v-flex xs2 xl1>
                            {{ event.Datum | formatDatum }}
                          </v-flex>
                          <v-flex xs1 xl1>
                            {{ event.CasOd }}
                          </v-flex>
                          <v-flex xs9 xl10>
                            {{ event.Desc }}
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-list-tile-content>
                    <v-list-tile-action>
                      <v-icon @click.stop="removeEvent(event)">delete</v-icon>
                    </v-list-tile-action>
                  </v-list-tile>
                  <v-divider
                    :key="'divider' + index"
                    v-if="index !== afterNowEvents.length - 1"
                  ></v-divider>
                </template>
              </v-list>
            </v-flex>
          </v-layout> -->
          <!-- <v-layout row pt-1>
            <v-flex>
              <v-list subheader dense v-if="beforeNowEvents.length">
                <v-subheader>{{
                  $vuetify.t("Termíny v minulosti")
                }}</v-subheader>
                <template v-for="(event, index) in beforeNowEvents">
                  <v-list-tile
                    :key="'list' + event.ID"
                    @click="editEvent(event)"
                  >
                    <v-list-tile-content>
                      <v-container fluid>
                        <v-layout row>
                          <v-flex xs2 xl1>
                            {{ event.Datum | formatDatum }}
                          </v-flex>
                          <v-flex xs1 xl1>
                            {{ event.CasOd }}
                          </v-flex>
                          <v-flex xs9 xl10>
                            {{ event.Desc }}
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-list-tile-content>
                    <v-list-tile-action>
                      <v-icon @click.stop="removeEvent(event)">delete</v-icon>
                    </v-list-tile-action>
                  </v-list-tile>
                  <v-divider
                    :key="'divider' + index"
                    v-if="index !== beforeNowEvents.length - 1"
                  ></v-divider>
                </template>
              </v-list>
            </v-flex>
          </v-layout> -->
        </v-container>
      </v-tab-item>
    </v-tabs-items>
    <BaseDialog
      :dialogTitle="$vuetify.t('Pacient - základné info')"
      ref="dialogPacientInfo"
    >
      <FormPacient
        ref="formPacient"
        @saved="savedPacient($refs.dialogPacientInfo)"
      />
    </BaseDialog>
    <BaseDialog
      :dialogTitle="$vuetify.t('Kontaktná osoba')"
      ref="dialogPacientInfoOsoba"
      @save="$refs.formPacientInfoOsoba.save()"
    >
      <FormPacientInfoOsoba
        ref="formPacientInfoOsoba"
        :pacient="pacient"
        @saved="savedPacient($refs.dialogPacientInfoOsoba)"
      />
    </BaseDialog>
    <BaseDialog
      :dialogTitle="$vuetify.t('Preferovaný zdravotnícky pracovník')"
      ref="dialogPacientPreferovanyZdravotnickyPracovnik"
    >
      <FormPacientPreferovanyZdravotnickyPracovnik
        ref="formPacientPreferovanyZdravotnickyPracovnik"
        :pacient="pacient"
        @saved="
          savedPacient($refs.dialogPacientPreferovanyZdravotnickyPracovnik)
        "
      />
    </BaseDialog>
    <BaseDialog
      :dialogTitle="$vuetify.t('Kalendár')"
      ref="dialogPacientEvent"
      width="100%"
      height="90vh"
      :fillHeight="true"
      :showSaveButton="false"
      @close="
        fetchEventy()
        readPacient()
      "
    >
      <v-layout column>
        <v-layout row align-center>
          <v-flex grow>
            <span class="title">{{
              $refs.calendar ? $refs.calendar.currentDateLabel : ""
            }}</span>
          </v-flex>
          <v-flex shrink>
            <calendar-navigation
              v-model="calendarType"
              @prev="$refs.calendar.prev()"
              @next="$refs.calendar.next()"
            />
          </v-flex>
        </v-layout>
        <v-layout row fill-height>
          <calendar
            ref="calendar"
            :type="calendarType"
            :pacientId="id"
            :pacient-name="`${pacient.FirstName} ${pacient.Surname}`"
          />
        </v-layout>
      </v-layout>
    </BaseDialog>
  </v-layout>
</template>

<script>
//import FormKontrola from "./../../components/forms/FormKontrola.vue"
import FormPacientInfoOsoba from "./../../components/forms/FormPacientInfoOsoba.vue"
import FormPacient from "./../../components/forms/FormPacient.vue"
import FormPacientPreferovanyZdravotnickyPracovnik from "./../../components/forms/FormPacientPreferovanyZdravotnickyPracovnik.vue"
// import FormEvent from "./../../components/forms/FormEvent.vue"
import BaseDialog from "./../../components/BaseDialog.vue"
//import BasePopup from "./../../components/BasePopup.vue"
import Calendar from "./../../components/Calendar.vue"
import CalendarNavigation from "./../../components/CalendarNavigation.vue"
import moment from "moment"
import * as mixPatient from "./../../components/mixins/patient.js"
import * as Miscella from "./../../miscella.js"
import { mapState, mapGetters, mapMutations } from "vuex"

export default {
  name: "info",
  components: {
    BaseDialog,
    //BasePopup,
    Calendar,
    CalendarNavigation,
    //FormKontrola,
    FormPacientInfoOsoba,
    FormPacientPreferovanyZdravotnickyPracovnik,
    FormPacient,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    tab: {
      type: String,
      required: false,
    },
  },
  mixins: [mixPatient.mixins],
  data: function () {
    return {
      beforeNowEvents: [],
      afterNowEvents: [],
      calendarType: "week",
    }
  },
  computed: {
    vekString: function () {
      return this.getAge(this.pacient.birthdate)
    },
    /* bmi: function () {
      let ret = null
      if (Miscella.isSet(this.pacient.poslVital)) {
        ret = Miscella.getBmi(
          this.pacient.poslVital.hmotnost,
          this.pacient.poslVital.vyska
        )
      }
      return ret
    }, */
    /* povrchTela: function () {
      let ret = null
      if (Miscella.isSet(this.pacient.poslVital)) {
        ret = Miscella.getPovrchTela(
          this.pacient.poslVital.hmotnost,
          this.pacient.poslVital.vyska
        )
      }
      return ret
    }, */
    /* telesnyTuk: function () {
      let ret = null
      if (Miscella.isSet(this.pacient.poslVital)) {
        ret = Miscella.getTelesnyTuk(
          this.pacient.poslVital.hmotnost,
          this.pacient.poslVital.vyska,
          this.bmi,
          this.vek,
          this.pohlavie
        )
      }
      return ret
    }, */
    /* vek: function () {
      if (this.$store.state.pacient === null) {
        return null
      }
      let birthdate = this.$store.state.pacient.Birthdate
      let years = moment().diff(moment(birthdate), "years")
      return years
    }, */
    pohlavie: function () {
      if (this.$store.state.pacient === null) {
        return null
      }
      // u nas v modeli je 1 - Muz 2 - Zena
      let sex = this.$store.state.pacient.Sex
      if (sex === 1 || sex === 2) {
        if (sex === 2) {
          sex = 0
        }
        return sex
        // pre vypocet je 1 - Muz 0 - Zena
      } else {
        return null
      }
    },
    activeTab: {
      get: function () {
        return Miscella.isSet(this.tab) ? Number(this.tab) : 0
      },
      set: function (tab) {
        this.$router.push({
          name: "info",
          params: { id: this.id, tab: String(tab) },
        })
      },
    },
    ...mapState({
      pacient: (state) => state.pacient,
      ambulanceID: (state) => state.me?.ambulance?.id,
    }),
    ...mapGetters(["isEhealthReady"]),
  },
  watch: {
    "$route.query.action": function (action) {
      this.fireAction(action)
    },
  },
  methods: {
    fireAction: function (action) {
      if (Miscella.isSet(action)) {
        switch (action) {
          case "editInfo":
            this.editInfo()
            break
          case "addObjednanie":
            if (Miscella.isSet(this.pacient.objednanie)) {
              this.editEvent(this.pacient.objednanie)
            } else {
              this.addEvent()
            }
            break
        }
        this.$router.replace({ query: { action: undefined } })
      }
    },
    savedPacient: function (aDialog) {
      this.readPacient()
      aDialog.close()
    },
    savedEvent: function (aDialog) {
      this.readPacient()
      this.fetchEventy()
      aDialog.close()
    },
    readPacient: function () {
      this.$store.commit("setReloadPacient", true)
    },
    editInfo: function () {
      this.$refs.formPacient.edit(this.pacient)
    },
    addInfoOsoba: function () {
      this.$refs.formPacientInfoOsoba.new()
      this.$refs.dialogPacientInfoOsoba.open()
    },
    editKontakt: function (aKontakt, aIndex) {
      this.$refs.formPacientInfoOsoba.edit(this.pacient, aIndex)
      this.$refs.dialogPacientInfoOsoba.open()
    },
    removeKontakt: function (aIndex) {
      return this.$confirm(
        this.$vuetify.t("Naozaj si želáte vymazať kontaktnú osobu?")
      ).then(
        function (res) {
          if (res) {
            this.pacient.contact_persons.splice(aIndex, 1)
            return this.$api
              .post("/v1/proto.PatientService/UpdatePatient", {
                patient: this.pacient,
              })
              .then(
                function () {
                  this.$store.commit(
                    "setSuccess",
                    this.$vuetify.t("Pacient bol úspešne uložený")
                  )
                  this.readPacient()
                }.bind(this)
              )
              .catch(
                function (err) {
                  this.$store.commit(
                    "setError",
                    this.$vuetify.t("Pri ukladaní pacienta vznikla chyba ") +
                      " (" +
                      err +
                      ")."
                  )
                }.bind(this)
              )
          }
        }.bind(this)
      )
    },
    addPreferovanyZdravotnickyPracovnik: function () {
      this.$refs.formPacientPreferovanyZdravotnickyPracovnik.new()
      this.$refs.dialogPacientPreferovanyZdravotnickyPracovnik.open()
    },
    editPreferovanyZdravotnickyPracovnik: function (aKontakt, aIndex) {
      this.$refs.formPacientPreferovanyZdravotnickyPracovnik.edit(
        this.pacient,
        aIndex
      )
      this.$refs.dialogPacientPreferovanyZdravotnickyPracovnik.open()
    },
    removePreferovanyZdravotnickyPracovnik: function (aIndex) {
      return this.$confirm(
        this.$vuetify.t(
          "Naozaj si želáte vymazať preferovaného zdravotníckeho pracovníka?"
        )
      ).then(
        function (res) {
          if (res) {
            this.pacient.preferovani_zdravotnicki_pracovnici.splice(aIndex, 1)
            return this.$api
              .post("/v1/proto.PatientService/UpdatePatient", {
                patient: this.pacient,
              })
              .then(
                function () {
                  this.notifySuccess("Pacient bol úspešne uložený")
                  this.readPacient()
                }.bind(this)
              )
              .catch(
                function (err) {
                  this.processError("Pri ukladaní pacienta vznikla chyba ")
                }.bind(this)
              )
          }
        }.bind(this)
      )
    },
    editDateOsoba: function () {
      this.$refs.modalPacientInfoDates.initForm(this.pacient)
    },
    fetchEventy: function () {
      this.$api
        .get("/api/ambulance/patient/" + this.id + "/events")
        .then(this.setEventy)
    },
    setEventy: function (data) {
      // console.log('setEventy', data)
      this.beforeNowEvents = []
      this.afterNowEvents = []
      if (data !== null) {
        for (let event of data) {
          var dateTime = moment(event.Datum + "T" + event.CasOd)
          if (moment().isBefore(dateTime)) {
            this.afterNowEvents.unshift(event)
          } else {
            this.beforeNowEvents.push(event)
          }
        }
      }
      // console.log('setEventy', this.beforeNowEvents)
      // console.log('setEventy', this.afterNowEvents)
    },
    addEvent: function () {
      this.$refs.dialogPacientEvent.open()
    },
    editEvent: function (aEvent) {
      this.$refs.calendar.eventDate = aEvent.Datum
      //this.$refs.formPacientEvent.edit(aEvent)
      this.$refs.dialogPacientEvent.open()
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.calendar.editEvent(aEvent)
        }, 1000)
      })
    },
    removeEvent: function (aEvent) {
      this.$api
        .delete("api/event/" + aEvent.ID)
        .then(this.removeEventOK)
        .catch(this.processError)
    },
    removeEventOK: function () {
      this.$store.commit(
        "setSuccess",
        this.$vuetify.t("Pacient bol úspešne uložený.")
      )
      this.fetchEventy()
    },
    editKontrola: function () {
      let k = {}
      if (Miscella.isSet(this.pacient)) {
        if (Miscella.isSet(this.pacient.kontrola)) {
          k = this.pacient.kontrola
        }
      }
      this.$refs.dialogKontrola.edit(k)
    },
    showError: function (err) {
      this.$store.commit(
        "setError",
        this.$vuetify.t(`Pri ukladaní pacienta vznikla chyba (${err}).`)
      )
    },
    zapisPSKontaktneUdaje: async function () {
      this.startAppLoading("Ukladajú sa kontaktné údaje do pacientskeho sumára")
      try {
        var response =
          await this.$api.ehealthService.zapisPacientskehoSumaruKontaktneUdaje({
            patient_id: this.id,
            ambulance_id: this.ambulanceID,
          })
        this.notifySuccess(
          "Kontaktné údaje z pacientskeho sumára boli úspešne uložené"
        )
      } catch (error) {
        this.processEhealthError(
          error,
          "Pri ukladaní kontaktných údajov pacientskeho sumára vznikla chyba"
        )
      } finally {
        this.stopAppLoading()
      }
    },
    dajPSKontaktneUdajeSNaplnenimPacienta: async function () {
      this.startAppLoading(
        "Načítavajú sa kontaktné údaje z pacientskeho sumára"
      )
      try {
        var response =
          await this.$api.ehealthService.dajPSKontaktneUdajeSNaplnenimPacienta({
            patient_id: this.id,
            ambulance_id: this.ambulanceID,
          })
        this.readPacient()
        this.notifySuccess(
          "Kontaktné údaje z pacientskeho sumára boli úspešne načítané"
        )
      } catch (error) {
        this.processEhealthError(
          error,
          "Pri načítavaní kontaktných údajov pacientskeho sumára vznikla chyba"
        )
      } finally {
        this.stopAppLoading()
      }
    },
  },
  mounted: function () {
    this.fetchEventy()
    this.fireAction(this.$route.query.action)
  },
}
</script>
<style scoped>
span.nadpis {
  font-size: 16px;
  font-weight: bold;
}
span.lbl {
  font-weight: bold;
  margin-right: 0.5rem;
}
span.val {
  display: inline-block;
  width: 7rem;
}
span.chartLabel {
  display: inline-block;
  width: 7rem;
}
span.subtitle {
  text-transform: capitalize;
  color: grey;
}
</style>
