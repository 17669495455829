<template>
  <v-layout column>
    <v-tabs v-model="activeTab">
      <v-tab>{{ $vuetify.t("Základné info") }}</v-tab>
      <v-tab>{{ $vuetify.t("Pacientsky sumár EDS") }}</v-tab>
      <v-tab>{{ $vuetify.t("PS kontaktné údaje") }}</v-tab>
      <v-tab>{{ $vuetify.t("Logy") }}</v-tab>
      <v-tab>{{ $vuetify.t("Fronta") }}</v-tab>
      <v-tabs-items v-model="activeTab">
        <!-- Základné info -->
        <v-tab-item>
          <v-container fluid grid-list-xs>
            <v-layout row justify-end>
              <v-btn
                color="primary"
                flat
                @click="identifyPatient"
                :disabled="!canIdentifyPatient"
                >{{
                  $vuetify.t(
                    hasPatientPersonUniqueID
                      ? "Identifikuj pacienta v ezdraví"
                      : "Identifikuj pacienta s eID"
                  )
                }}</v-btn
              >
              <v-btn
                color="primary"
                flat
                @click="dajZp"
                :disabled="!isEhealthReady"
                >{{ $vuetify.t("Over zdravotnú poisťovňu pacienta") }}</v-btn
              >
              <v-btn color="primary" flat @click="overPoistenca">{{
                $vuetify.t("Over pacienta v poisťovni")
              }}</v-btn>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 v-if="!hasPatientPersonUniqueID">
                <v-card>
                  <v-card-text>
                    <v-alert :value="true" type="warning" class="py-2" outline>
                      {{
                        $vuetify.t(
                          "Rodné číslo pacienta nie je zadané. Pri identifikácii pacienta sa bude vyžadovať vloženie eID pacienta do čítačky."
                        )
                      }}
                    </v-alert>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs12 v-if="!isPatientIdentificationEhealthRecord">
                <v-card>
                  <v-card-text>
                    <v-alert
                      :value="true"
                      :type="hasPatientJruzID ? 'success' : 'error'"
                      class="py-2"
                      outline
                    >
                      {{
                        $vuetify.t(
                          "$vuetify.ezdravie.hasPatientJruzID." +
                            hasPatientJruzID
                        )
                      }}
                    </v-alert>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs12>
                <v-card v-if="pacient">
                  <EhealthRecord
                    v-model="pacient.ehealth_record"
                    :objectID="pacient?.id"
                    titleText="Identifikácia pacienta"
                    statusTextPrefix="$vuetify.enum.ehealthRecord.patientIdentification"
                  ></EhealthRecord>
                </v-card>
              </v-flex>
              <v-flex xs12 v-if="zpPrZs">
                <v-subheader>{{
                  $vuetify.t("Zdravotné poisťovne")
                }}</v-subheader>
                <v-data-table
                  :headers="[
                    {
                      text: $vuetify.t('Názov poisťovne'),
                      align: 'left',
                      sortable: false,
                      value: 'nazov',
                    },
                    {
                      text: $vuetify.t('Platnosť od'),
                      align: 'left',
                      sortable: false,
                      value: 'platnostOd',
                    },
                    {
                      text: $vuetify.t('Platnosť do'),
                      align: 'left',
                      sortable: false,
                      value: 'platnostOd',
                    },
                  ]"
                  :items="zpPrZs.Data.ZP_PrZS"
                  class="elevation-1"
                  hide-actions
                >
                  <template v-slot:items="{ item }">
                    <td>
                      {{
                        $vuetify.t(
                          "$vuetify.enum.ezdravieZP." + item.IdZP.extension
                        )
                      }}
                    </td>
                    <td>{{ item.Platnost.DatumOd | formatDatum }}</td>
                    <td>{{ item.Platnost.DatumDo | formatDatum }}</td>
                  </template>
                </v-data-table>
              </v-flex>
              <v-flex xs12 v-if="overPoistencaData">
                <v-subheader>{{
                  $vuetify.t("Overenie pacienta v poisťovni:") +
                  this.pacient.zdravotna_poistovna.display_name
                }}</v-subheader>
                <v-data-table
                  :headers="[
                    {
                      text: $vuetify.t('Poistný vzťah'),
                      align: 'left',
                      sortable: false,
                      value: 'poistnyVztah',
                    },
                    {
                      text: $vuetify.t('Neplatič'),
                      align: 'left',
                      sortable: false,
                      value: 'neplatic',
                    },
                    {
                      text: $vuetify.t('Začiatok poistenia'),
                      align: 'left',
                      sortable: false,
                      value: 'zaciatok',
                    },
                    {
                      text: $vuetify.t('Má nárok na odkladnú ZS'),
                      align: 'left',
                      sortable: false,
                      value: 'narokNaOdkladnuZS',
                    },
                  ]"
                  :items="[overPoistencaData]"
                  class="elevation-1"
                  hide-actions
                >
                  <template v-slot:items="{ item }">
                    <td>{{ item.PoistnyVztahPoistenca }}</td>
                    <td>
                      {{
                        $vuetify.t("$vuetify.app.boolean." + item.JeNeplatic)
                      }}
                    </td>
                    <td>{{ item.ZaciatokEUPoistenia | formatDatum }}</td>
                    <td>
                      {{
                        $vuetify.t(
                          "$vuetify.app.boolean." + item.MaNarokNaOdkladnuZS
                        )
                      }}
                    </td>
                  </template>
                </v-data-table>
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>

        <!-- Pacientsky sumár EDS -->
        <v-tab-item>
          <v-container fluid>
            <v-layout row justify-end>
              <v-btn color="primary" flat @click="dajPacientskySumarEDS">{{
                $vuetify.t("Načítaj pacientsky sumár EDS")
              }}</v-btn>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 class="mb-3" v-if="psEDS !== null">
                <v-card flat>
                  <v-list two-line subheader dense>
                    <v-subheader
                      >Lieková anamnéza ({{
                        psEDS.Data.LiekovaAnamneza.length
                      }})</v-subheader
                    >
                    <div style="max-height: 304px; overflow: auto">
                      <template
                        v-for="(item, index) in psEDS.Data.LiekovaAnamneza"
                      >
                        <v-list-tile :key="'listLA' + index" @click="dummy()">
                          <v-list-tile-content>
                            <v-container fluid px-0>
                              <v-layout row>
                                <v-flex xs3>
                                  {{ item.AktivnyOd.time | formatDatumACas }}
                                </v-flex>
                                <v-flex xs9>
                                  {{
                                    item.Liek == null
                                      ? ""
                                      : item.Liek.Nazov.originalText
                                  }}
                                  {{
                                    item.Liek == null
                                      ? ""
                                      : item.Liek.DoplnokNazov.originalText
                                  }}
                                </v-flex>
                              </v-layout>
                              <v-layout row>
                                <v-flex xs12>
                                  {{
                                    item.PopisMedikacie == null
                                      ? ""
                                      : item.PopisMedikacie.originalText
                                  }}
                                </v-flex>
                              </v-layout>
                            </v-container>
                          </v-list-tile-content>
                          <v-list-tile-action> </v-list-tile-action>
                        </v-list-tile>
                        <v-divider
                          :key="'divider' + index"
                          v-if="index !== psEDS.Data.LiekovaAnamneza.length - 1"
                        ></v-divider>
                      </template>
                    </div>
                  </v-list>
                </v-card>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 class="mb-3" v-if="psEDS !== null">
                <v-card flat>
                  <v-list two-line subheader dense>
                    <v-subheader
                      >Pôrodnicka anamnéza ({{
                        psEDS.Data.PorodnickaAnamneza.length
                      }})</v-subheader
                    >
                    <div style="max-height: 304px; overflow: auto">
                      <template
                        v-for="(item, index) in psEDS.Data.PorodnickaAnamneza"
                      >
                        <v-list-tile :key="'listPA' + index" @click="dummy()">
                          <v-list-tile-content>
                            <v-container fluid px-0>
                              <v-layout row>
                                <v-flex xs12>
                                  {{
                                    item.PredpokladanyDatumPorodu == null
                                      ? ""
                                      : item.PredpokladanyDatumPorodu.date.Day +
                                        "." +
                                        item.PredpokladanyDatumPorodu.date
                                          .Month +
                                        "." +
                                        item.PredpokladanyDatumPorodu.date.Year
                                  }}
                                </v-flex>
                              </v-layout>
                            </v-container>
                          </v-list-tile-content>
                          <v-list-tile-action> </v-list-tile-action>
                        </v-list-tile>
                        <v-divider
                          :key="'divider' + index"
                          v-if="
                            index !== psEDS.Data.PorodnickaAnamneza.length - 1
                          "
                        ></v-divider>
                      </template>
                    </div>
                  </v-list>
                </v-card>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 class="mb-3" v-if="psEDS !== null">
                <v-card flat>
                  <v-list two-line subheader dense>
                    <v-subheader
                      >Varovania ({{
                        psEDS.Data.Varovania.length
                      }})</v-subheader
                    >
                    <div style="max-height: 304px; overflow: auto">
                      <template v-for="(item, index) in psEDS.Data.Varovania">
                        <v-list-tile :key="'listV' + index" @click="dummy()">
                          <v-list-tile-content>
                            <v-container fluid px-0>
                              <v-layout row>
                                <v-flex xs3>
                                  <span v-if="item.Committal !== null">
                                    {{
                                      item.Committal.time_committed == null
                                        ? ""
                                        : $options.filters.formatDatumACas(
                                            item.Committal.time_committed.time
                                          )
                                    }}
                                  </span>
                                </v-flex>
                                <v-flex xs9>
                                  {{
                                    item.Alergen.Latka == null
                                      ? ""
                                      : "Alergén: " +
                                        item.Alergen.Latka.DisplayName
                                  }}
                                  {{
                                    item.Alergen.Liecivo == null
                                      ? ""
                                      : "Liečivo: " +
                                        item.Alergen.Liecivo.DisplayName
                                  }}
                                </v-flex>
                              </v-layout>
                              <v-layout row>
                                <v-flex xs12>
                                  {{
                                    item.Poznamka == null
                                      ? ""
                                      : item.Poznamka.originalText
                                  }}
                                </v-flex>
                              </v-layout>
                            </v-container>
                          </v-list-tile-content>
                          <v-list-tile-action> </v-list-tile-action>
                        </v-list-tile>
                        <v-divider
                          :key="'divider' + index"
                          v-if="index !== psEDS.Data.Varovania.length - 1"
                        ></v-divider>
                      </template>
                    </div>
                  </v-list>
                </v-card>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 class="mb-3" v-if="psEDS !== null">
                <v-card flat>
                  <v-list two-line subheader dense>
                    <v-subheader
                      >Zdravotné problémy ({{
                        psEDS.Data.ZdravotneProblemy.length
                      }})</v-subheader
                    >
                    <div style="max-height: 304px; overflow: auto">
                      <template
                        v-for="(item, index) in psEDS.Data.ZdravotneProblemy"
                      >
                        <v-list-tile
                          :key="'listZP' + index"
                          @click="dummy()"
                          v-if="
                            item.RcIdOid === '1.3.158.00165387.100.50.40.120'
                          "
                        >
                          <v-list-tile-content>
                            <v-container fluid px-0>
                              <v-layout row>
                                <v-flex xs3>
                                  <span v-if="item.Committal !== null">
                                    {{
                                      item.Committal.time_committed == null
                                        ? ""
                                        : $options.filters.formatDatumACas(
                                            item.Committal.time_committed.time
                                          )
                                    }}
                                  </span>
                                </v-flex>
                                <v-flex xs3>
                                  <span>
                                    Biohazard:
                                    {{
                                      item.Biohazard !== null
                                        ? item.Biohazard
                                        : "Nie"
                                    }}
                                  </span>
                                </v-flex>
                                <v-flex xs6>
                                  Život ovplyvňujúca diagnóza:
                                  {{
                                    item.ZivotOvplyvnujucaDiagnoza !== null
                                      ? item.ZivotOvplyvnujucaDiagnoza
                                          .KodDiagnozy.DisplayName
                                      : ""
                                  }}
                                  <br />
                                  {{
                                    item.ZivotOvplyvnujucaDiagnoza !== null &&
                                    item.ZivotOvplyvnujucaDiagnoza
                                      .Upresnenie !== null
                                      ? item.ZivotOvplyvnujucaDiagnoza
                                          .Upresnenie.originalText
                                      : ""
                                  }}
                                </v-flex>
                              </v-layout>
                            </v-container>
                          </v-list-tile-content>
                          <v-list-tile-action> </v-list-tile-action>
                        </v-list-tile>
                        <v-list-tile
                          :key="'listZP' + index"
                          @click="dummy()"
                          v-if="
                            item.RcIdOid === '1.3.158.00165387.100.50.40.100'
                          "
                        >
                          <v-list-tile-content>
                            <v-container fluid px-0>
                              <v-layout row>
                                <v-flex xs3>
                                  <span v-if="item.Committal !== null">
                                    Používaná zdravotnícka pomôcka:
                                    <br />
                                    {{
                                      item.Committal.time_committed == null
                                        ? ""
                                        : $options.filters.formatDatumACas(
                                            item.Committal.time_committed.time
                                          )
                                    }}
                                  </span>
                                </v-flex>
                                <v-flex xs3>
                                  <span>
                                    Druh:
                                    {{
                                      item.Druh !== null
                                        ? item.Druh.DisplayName
                                        : ""
                                    }}
                                  </span>
                                </v-flex>
                                <!-- <v-flex xs9> -->
                                <!--   Obchodný názov: -->
                                <!--   {{ -->
                                <!--     item.ObchodnyNazov !== null -->
                                <!--       ? "" -->
                                <!--       : item.ObchodnyNazov -->
                                <!--           .KodDiagnozy.DisplayName -->
                                <!--   }} -->
                                <!-- </v-flex> -->
                                <v-flex xs6>
                                  Popis:
                                  {{
                                    item.PopisZdravotnickejPomocky == null
                                      ? ""
                                      : item.PopisZdravotnickejPomocky
                                          .originalText
                                  }}
                                </v-flex>
                              </v-layout>
                            </v-container>
                          </v-list-tile-content>
                          <v-list-tile-action> </v-list-tile-action>
                        </v-list-tile>
                        <v-divider
                          :key="'divider' + index"
                          v-if="
                            index !== psEDS.Data.ZdravotneProblemy.length - 1
                          "
                        ></v-divider>
                      </template>
                    </div>
                  </v-list>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>

        <!-- PS kontaktné údaje -->
        <v-tab-item>
          <v-container fluid>
            <v-layout row justify-end>
              <v-btn
                color="primary"
                flat
                @click="dajPacientskySumarKontaktneUdaje"
                >{{
                  $vuetify.t("Načítaj kontaktné údaje pacientskeho sumára")
                }}</v-btn
              >
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 class="mb-3" v-if="psKontaktneUdaje !== null">
                <v-card flat>
                  <v-list two-line subheader dense>
                    <v-subheader
                      >Preferovaní pracovníci zdravotnej starostlivosti ({{
                        psKontaktneUdaje.Data
                          .PreferovaniPracovniciZdravotnejStarostlivosti.length
                      }})</v-subheader
                    >
                    <div style="max-height: 304px; overflow: auto">
                      <template
                        v-for="(item, index) in psKontaktneUdaje.Data
                          .PreferovaniPracovniciZdravotnejStarostlivosti"
                      >
                        <v-list-tile
                          :key="'listPPZS' + index"
                          @click="editPracovnik(item)"
                        >
                          <v-list-tile-content>
                            <v-container fluid px-0>
                              <v-layout row>
                                <v-flex xs3>
                                  {{
                                    item.MenoAPriezvisko == null
                                      ? ""
                                      : item.MenoAPriezvisko.KrstneMeno +
                                        " " +
                                        item.MenoAPriezvisko.Priezvisko
                                  }}
                                </v-flex>
                                <v-flex xs6>
                                  {{ getAdresaString(item.Adresa) }}
                                </v-flex>
                                <v-flex xs3>
                                  {{ item.Telefon }} {{ item.Email }}
                                </v-flex>
                              </v-layout>
                              <v-layout row>
                                <v-flex xs12>
                                  {{ item.Poznamka }}
                                </v-flex>
                              </v-layout>
                            </v-container>
                          </v-list-tile-content>
                          <v-list-tile-action>
                            <v-tooltip
                              :key="
                                'ehealth-ps-preferovanipracovnici-action' +
                                index
                              "
                              left
                            >
                              <v-icon
                                slot="activator"
                                @click.stop="zrusKontaktneUdaje(item)"
                                >delete</v-icon
                              >
                              <span>{{ $vuetify.t("Zmazať") }}</span>
                            </v-tooltip>
                          </v-list-tile-action>
                        </v-list-tile>
                        <v-divider
                          :key="'divider' + index"
                          v-if="
                            index !==
                            psKontaktneUdaje.Data
                              .PreferovaniPracovniciZdravotnejStarostlivosti
                              .length -
                              1
                          "
                        ></v-divider>
                      </template>
                    </div>
                  </v-list>
                </v-card>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 class="mb-3" v-if="psKontaktneUdaje !== null">
                <v-card flat>
                  <v-list two-line subheader dense>
                    <v-subheader
                      >Kontaktné osoby ({{
                        psKontaktneUdaje.Zapis.KontaktneOsoby_v4.length
                      }})</v-subheader
                    >
                    <div style="max-height: 304px; overflow: auto">
                      <template
                        v-for="(item, index) in psKontaktneUdaje.Zapis
                          .KontaktneOsoby_v4"
                      >
                        <v-list-tile
                          :key="'listKO' + index"
                          @click="editOsoba(item)"
                        >
                          <v-list-tile-content>
                            <v-container fluid px-0>
                              <v-layout row>
                                <v-flex xs3>
                                  {{
                                    item.Meno == null
                                      ? ""
                                      : item.Meno.KrstneMeno +
                                        " " +
                                        item.Meno.Priezvisko
                                  }}
                                </v-flex>
                                <v-flex xs3>
                                  {{
                                    item.TypKontaktu == null
                                      ? ""
                                      : " " +
                                        item.TypKontaktu.IdPolozkaCiselnika
                                          .displayName
                                  }}
                                </v-flex>
                                <v-flex xs6>
                                  {{ item.Telefon }} {{ item.Email }}
                                </v-flex>
                              </v-layout>
                              <v-layout row>
                                <v-flex xs12>
                                  {{ item.Poznamka }}
                                </v-flex>
                              </v-layout>
                            </v-container>
                          </v-list-tile-content>
                          <v-list-tile-action>
                            <v-tooltip
                              :key="'ehealth-ps-osoby-action' + index"
                              left
                            >
                              <v-icon
                                slot="activator"
                                @click.stop="zrusKontaktneUdaje(item)"
                                >delete</v-icon
                              >
                              <span>{{ $vuetify.t("Zmazať") }}</span>
                            </v-tooltip>
                          </v-list-tile-action>
                        </v-list-tile>
                        <v-divider
                          :key="'divider' + index"
                          v-if="
                            index !==
                            psKontaktneUdaje.Zapis.KontaktneOsoby_v4.length - 1
                          "
                        ></v-divider>
                      </template>
                    </div>
                  </v-list>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
          <BaseDialog
            :dialogTitle="
              $vuetify.t('Preferovaný pracovník zdravotnej starostlivosti')
            "
            ref="dialog"
          >
            <FormPacSumarKontUdajePrefZdrPracovnik
              ref="formPacSumarKontUdajePrefZdrPracovnik"
            ></FormPacSumarKontUdajePrefZdrPracovnik>
          </BaseDialog>
          <BaseDialog :dialogTitle="$vuetify.t('Kontaktná osoba')" ref="dialog">
            <FormPacSumarKontUdajeOsoba
              ref="formPacSumarKontUdajeOsoba"
            ></FormPacSumarKontUdajeOsoba>
          </BaseDialog>
        </v-tab-item>

        <!-- LOGY -->
        <v-tab-item>
          <EZdravieLog :customParams="{ patient_id: id }"></EZdravieLog>
        </v-tab-item>

        <!-- FRONTA -->
        <v-tab-item>
          <EhealthQueue :patientId="id"></EhealthQueue>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-layout>
</template>

<script>
import * as Miscella from "./../../miscella.js"
import { mapState, mapGetters, mapMutations } from "vuex"
import EZdravieLog from "@/pages/admin/logs/EZdravieLog.vue"
import EhealthQueue from "@/pages/admin/logs/EhealthQueue.vue"
import BaseDialog from "./../../components/BaseDialog.vue"
import FormPacSumarKontUdajePrefZdrPracovnik from "./../../components/forms/FormPacSumarKontUdajePrefZdrPracovnik.vue"
import FormPacSumarKontUdajeOsoba from "./../../components/forms/FormPacSumarKontUdajeOsoba.vue"
import EhealthRecord from "@/components/EhealthRecord.vue"
import * as enums from "@/plugins/enums.js"

export default {
  name: "Ehealth",
  props: ["id"],
  components: {
    EZdravieLog,
    EhealthQueue,
    BaseDialog,
    FormPacSumarKontUdajePrefZdrPracovnik,
    FormPacSumarKontUdajeOsoba,
    EhealthRecord,
  },
  data: function () {
    return {
      activeTab: 0,
      zpPrZs: null,
      overPoistencaData: null,
      psEDS: null,
      psKontaktneUdaje: null,
      enums: enums,
    }
  },
  computed: {
    isPatientIdentificationEhealthRecord: function () {
      return Miscella.isSet(this.pacient?.ehealth_record)
    },
    isPatientIdentificationEhrError: function () {
      return (
        this.isPatientIdentificationEhealthRecord &&
        this.pacient.ehealth_record.status === enums.EhealthRecordStatus.Error
      )
    },
    canIdentifyPatient: function () {
      return (
        this.isEhealthReadyForAmbulance &&
        (!this.isPatientIdentificationEhealthRecord ||
          this.isPatientIdentificationEhrError)
      )
    },
    ...mapState({
      pacient: (state) => state.pacient,
      ambulance: (state) => state.me?.ambulance,
      ambulanceID: (state) => state.me?.ambulance?.id,
      ehealthStatus: (state) => state.ehealth.status,
      ehealthEnabled: (state) => state.ehealth.enabled,
      events: (state) => state.events.queue,
    }),
    ...mapGetters([
      "isEhealthReady",
      "isEhealthReadyForAmbulance",
      "hasPatientPersonUniqueID",
      "hasPatientJruzID",
    ]),
  },
  methods: {
    dummy: function () {
      console.log("Clicked dummy!")
    },
    editPracovnik: function (item) {
      this.$refs.formPacSumarKontUdajePrefZdrPracovnik.edit(item)
    },
    editOsoba: function (item) {
      this.$refs.formPacSumarKontUdajeOsoba.edit(item)
    },
    getAdresaString: function (adresa) {
      let ret = ""
      if (adresa != null) {
        ret += adresa.Ulica + " " + adresa.CisloPopisne
        ret = ret.trim()
        if (adresa.PSC != null) {
          ret += " " + adresa.PSC.IdPolozkaCiselnika.displayName
          ret = ret.trim()
        }
        if (adresa.Mesto != null) {
          ret += " " + adresa.Mesto.IdPolozkaCiselnika.displayName
          ret = ret.trim()
        }
        if (adresa.Krajina != null) {
          ret += " " + adresa.Krajina.IdPolozkaCiselnika.displayName
          ret = ret.trim()
        }
      }
      return ret
    },
    identifyPatient: async function () {
      try {
        var params = {
          ambulance_id: this.ambulanceID,
          patient_id: this.id,
          object_id: this.id,
          object_type: this.hasPatientPersonUniqueID
            ? "DAJ_JRUZ_IDENTIFIKATOR_GW"
            : "DAJ_PACIENT_INFO",
        }
        var result = await this.$api.ehealthService.sendToEhealth(params)
      } catch (e) {
        this.processError(e, "Pri indetifikácii pacienta vznikla chyba")
      }
    },
    dajZp: async function () {
      this.startAppLoading("Overuje sa zdravotná poisťovňa pacienta")
      try {
        var response = await this.$api.ehealthService.dajZpPrZs({
          patient_id: this.id,
          ambulance_id: this.ambulanceID,
        })
        this.zpPrZs = JSON.parse(response.data)
        //this.$refs.zpDialog.open()
      } catch (error) {
        this.processEhealthError(
          error,
          "Pri načítaní zdravotnej poisťovne pacienta vznikla chyba"
        )
      } finally {
        this.stopAppLoading()
      }
    },
    overPoistenca: async function () {
      this.startAppLoading("Overuje sa zdravotná poisťovňa pacienta")
      try {
        var response = await this.$api.ehealthService.overPoistenca({
          patient_id: this.id,
          ambulance_id: this.ambulanceID,
        })
        this.overPoistencaData = JSON.parse(response.data)
        //this.$refs.zpDialog.open()
      } catch (error) {
        this.processEhealthError(
          error,
          "Pri overovaní pacienta v zdravotnej poisťovni vznikla chyba"
        )
      } finally {
        this.stopAppLoading()
      }
    },
    dajPacientskySumarEDS: async function () {
      this.startAppLoading("Načítava sa pacientsky sumár EDS")
      try {
        var response = await this.$api.ehealthService.dajPacientskySumarEds({
          patient_id: this.id,
          ambulance_id: this.ambulanceID,
        })
        this.psEDS = JSON.parse(response.data)
      } catch (error) {
        this.processEhealthError(
          error,
          "Pri načítaní pacientskeho sumára EDS vznikla chyba"
        )
      } finally {
        this.stopAppLoading()
      }
    },
    dajPacientskySumarKontaktneUdaje: async function () {
      this.startAppLoading("Načítavajú sa kontaktné údaje pacientskeho sumára")
      try {
        var response =
          await this.$api.ehealthService.dajPacientskySumarKontaktneUdaje({
            patient_id: this.id,
            ambulance_id: this.ambulanceID,
          })
        this.psKontaktneUdaje = JSON.parse(response.data)
      } catch (error) {
        this.processEhealthError(
          error,
          "Pri načítaní kontaktných údajov pacientskeho sumára vznikla chyba"
        )
      } finally {
        this.stopAppLoading()
      }
    },
    zrusKontaktneUdaje: async function (item) {
      this.startAppLoading("Mažú sa kontaktné údaje")
      try {
        var response =
          await this.$api.ehealthService.zrusKontaktneUdajePacientskehoSumaru({
            patient_id: this.id,
            ambulance_id: this.ambulanceID,
            rc_ids: [
              {
                extension: item.RcId.extension,
                root: item.RcId.root.oid,
              },
            ],
          })
      } catch (error) {
        this.processEhealthError(
          error,
          "Pri mazaní kontaktných údajov pacientskeho sumára vznikla chyba"
        )
      } finally {
        this.stopAppLoading()
      }
    },
  },
}
</script>

<style scoped></style>
