var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.vysetrenie !== null && _vm.vysetrenie !== undefined)?_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{attrs:{"ripple":""}},[_vm._v(" "+_vm._s(_vm.$vuetify.t("$vuetify.enum.evysetrenie.typ." + _vm.typ))+" ")]),_c('v-tab-item',[_c('v-container',{staticClass:"px-0",attrs:{"fluid":"","grid-list-xs":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[(_vm.isOdborneVysetrenie || _vm.isZobrazovacieVysetrenie)?_c('v-flex',{attrs:{"xs12":"","md6":""}},[_c('titled-text',{attrs:{"title":"Dátum a čas vyšetrenia","text":_vm.vysetrenie.DatumACasVysetrenia !== null &&
                    _vm.vysetrenie.DatumACasVysetrenia !== undefined
                      ? _vm.$options.filters.formatDatumACas(
                          _vm.vysetrenie.DatumACasVysetrenia.time
                        )
                      : ' '}})],1):_vm._e(),(_vm.isPrepustaciaSprava)?_c('v-flex',{attrs:{"xs12":"","md3":""}},[_c('titled-text',{attrs:{"title":"Dátum a čas prijatia","text":_vm.vysetrenie.DatumACasPrijatia !== null &&
                    _vm.vysetrenie.DatumACasPrijatia !== undefined
                      ? _vm.$options.filters.formatDatumACas(
                          _vm.vysetrenie.DatumACasPrijatia.time
                        )
                      : ' '}})],1):_vm._e(),(_vm.isPrepustaciaSprava)?_c('v-flex',{attrs:{"xs12":"","md3":""}},[_c('titled-text',{attrs:{"title":"Dátum a čas prepustenia","text":_vm.vysetrenie.DatumACasPrepustenia !== null &&
                    _vm.vysetrenie.DatumACasPrepustenia !== undefined
                      ? _vm.$options.filters.formatDatumACas(
                          _vm.vysetrenie.DatumACasPrepustenia.time
                        )
                      : ' '}})],1):_vm._e(),_c('v-flex',{attrs:{"xs12":"","md3":""}},[_c('titled-text',{attrs:{"title":"Dátum a čas odoslania","text":_vm.vysetrenie.DatumACasOdoslania !== null &&
                    _vm.vysetrenie.DatumACasOdoslania !== undefined
                      ? _vm.$options.filters.formatDatumACas(
                          _vm.vysetrenie.DatumACasOdoslania.time
                        )
                      : ''}})],1),_c('v-flex',{attrs:{"xs12":"","md3":""}},[_c('titled-text',{attrs:{"title":"Urgentnosť","text":_vm.vysetrenie.UrgentnostVysetrenia.DisplayName}})],1),(_vm.isOdborneVysetrenie || _vm.isZobrazovacieVysetrenie)?_c('v-flex',{attrs:{"xs12":""}},[_c('ezdravie-textovy-popis',{attrs:{"title":"Popis"},model:{value:(_vm.vysetrenie.Popis),callback:function ($$v) {_vm.$set(_vm.vysetrenie, "Popis", $$v)},expression:"vysetrenie.Popis"}})],1):_vm._e(),(_vm.hasNazovVysetrenia)?_c('v-flex',{attrs:{"xs12":""}},[_c('titled-text',{attrs:{"title":"Názov vyšetrenia","text":_vm.vysetrenie.NazovVysetrenia.originalText}})],1):_vm._e(),(_vm.hasInternaPoznamka)?_c('v-flex',{attrs:{"xs12":""}},[_c('titled-text',{attrs:{"title":"Interná poznámka","text":_vm.vysetrenie.InternaPoznamka.originalText}})],1):_vm._e(),(
                  (_vm.isOdborneVysetrenie || _vm.isPrepustaciaSprava) &&
                  _vm.hasPredpokladanyDatumPorodu
                )?_c('v-flex',{attrs:{"xs12":""}},[_c('titled-text',{attrs:{"title":"Predpokladaný dátum pôrodu","text":_vm._f("formatDatum")(_vm.makeDatumPorodu(_vm.vysetrenie.PredpokladanyDatumPorodu))}})],1):_vm._e(),_c('v-flex',{attrs:{"xs12":""}},[_c('v-subheader',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$vuetify.t("Ošetrujúci lekár"))+" ")])],1),_c('v-flex',{attrs:{"xs6":""}},[_c('titled-text',{attrs:{"title":"Meno"}},[_c('ezdravie-zdravotnicky-pracovnik',{ref:"zdravotnickyPracovnikField",attrs:{"ii":_vm.vysetrenie.InfoProvider.Performer}})],1)],1),_c('v-flex',{attrs:{"xs12":"","md6":""}},[_c('titled-text',{attrs:{"title":"Špecializácia","text":_vm.vysetrenie.InfoProvider.Function.DisplayName}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('titled-text',{attrs:{"title":"OÚ PZS"}},[_c('ezdravie-ou-pzs',{ref:"ouPzsField",attrs:{"ii":_vm.vysetrenie.InfoProvider.HealthcareFacillity}})],1)],1),(_vm.vysetrenie.DiagnostickyZaver)?_c('v-flex',{attrs:{"xs12":""}},[_c('v-subheader',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$vuetify.t("Diagnostický záver"))+" ")]),_c('v-data-table',{staticClass:"elevation-4",attrs:{"headers":[
                    {
                      text: 'Hlavná diagnóza',
                      value: 'hlavna',
                      sortable: false,
                    },
                    {
                      text: 'Život ovplyvňujúca diagnóza',
                      value: 'zivotOvplyvnujuca',
                      sortable: false,
                    },
                    {
                      text: 'Názov diagnózy',
                      value: 'nazov',
                      sortable: false,
                    },
                    {
                      text: 'Upresnenie diagnózy',
                      value: 'upresnenie',
                      sortable: false,
                    },
                  ],"items":_vm.vysetrenie.DiagnostickyZaver,"hide-actions":""},scopedSlots:_vm._u([{key:"items",fn:function({ item }){return [_c('tr',{staticClass:"noclicker"},[_c('td',[_c('v-checkbox',{attrs:{"input-value":item.HlavnaDiagnoza.value,"primary":"","hide-details":"","readonly":""}})],1),_c('td',[_c('v-checkbox',{attrs:{"input-value":item.ZivotOvplyvnujucaDiagnoza.value,"primary":"","hide-details":"","readonly":""}})],1),_c('td',[_vm._v(_vm._s(item.Diagnoza.KodDiagnozy.DisplayName))]),_c('td',[_vm._v(_vm._s(item.Diagnoza.Upresnenie.originalText))])])]}}],null,false,185006617)})],1):_vm._e(),(_vm.vysetrenie.DiagnozaPriOdoslani)?_c('v-flex',{attrs:{"xs12":""}},[_c('v-subheader',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$vuetify.t("Diagnóza pri odoslaní"))+" ")]),_c('v-data-table',{staticClass:"elevation-4",attrs:{"headers":[
                    {
                      text: 'Názov diagnózy',
                      value: 'nazov',
                      sortable: false,
                    },
                    {
                      text: 'Upresnenie diagnózy',
                      value: 'upresnenie',
                      sortable: false,
                    },
                  ],"items":_vm.vysetrenie.DiagnozaPriOdoslani,"hide-actions":""},scopedSlots:_vm._u([{key:"items",fn:function({ item }){return [_c('td',[_vm._v(_vm._s(item.KodDiagnozy.DisplayName))]),_c('td',[_vm._v(_vm._s(item.Upresnenie.originalText))])]}}],null,false,506832856)})],1):_vm._e(),(_vm.vysetrenie.OdosielajuciLekar)?_c('v-flex',{attrs:{"xs12":""}},[_c('v-subheader',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$vuetify.t("Odosielajúci lekár")))]),_c('v-container',{staticClass:"pa-0",attrs:{"fluid":"","grid-list-xs":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs6":""}},[_c('titled-text',{attrs:{"title":"Meno"}},[_c('ezdravie-zdravotnicky-pracovnik',{attrs:{"ii":_vm.vysetrenie.OdosielajuciLekar.IdentifikaciaZPrac}})],1)],1),_c('v-flex',{attrs:{"xs12":"","md6":""}},[_c('titled-text',{attrs:{"title":"Špecializácia","text":_vm.vysetrenie.OdosielajuciLekar.SpecializaciaLekara
                            .DisplayName}})],1),_c('v-flex',{attrs:{"xs12":"","md6":""}},[_c('titled-text',{attrs:{"title":"OÚ PZS"}},[_c('ezdravie-ou-pzs',{attrs:{"ii":_vm.vysetrenie.OdosielajuciLekar.IdentifikaciaOupzs}})],1)],1),_c('v-flex',{attrs:{"xs12":"","md6":""}},[_c('titled-text',{attrs:{"title":"Nezmluvný lekár","text":_vm.$vuetify.t(
                            _vm.vysetrenie.OdosielajuciLekar.NezmluvnyLekar
                              ? 'Áno'
                              : 'Nie'
                          )}})],1)],1)],1)],1):_vm._e()],1),(
                (_vm.isOdborneVysetrenie || _vm.isPrepustaciaSprava) && _vm.hasAlergen
              )?_c('v-flex',{attrs:{"xs12":""}},[_c('v-subheader',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$vuetify.t("Alergické reakcie"))+" ")]),_c('v-data-table',{staticClass:"elevation-4",attrs:{"headers":[
                  {
                    text: 'Látka',
                    value: 'latka',
                    sortable: false,
                  },
                  {
                    text: 'Liečivo',
                    value: 'liecivo',
                    sortable: false,
                  },
                ],"items":_vm.vysetrenie.Alergen,"hide-actions":""},scopedSlots:_vm._u([{key:"items",fn:function({ item }){return [_c('tr',{staticClass:"noclicker"},[(_vm.hasLatka(item))?_c('td',[_vm._v(" "+_vm._s(item.Latka.DisplayName)+" ")]):_vm._e(),(_vm.hasLiecivo(item))?_c('td',[_vm._v(" "+_vm._s(item.Liecivo.DisplayName)+" ")]):_vm._e()])]}}],null,false,717735599)})],1):_vm._e(),(
                (_vm.isOdborneVysetrenie || _vm.isPrepustaciaSprava) && _vm.hasMedikacia
              )?_c('v-flex',{attrs:{"xs12":""}},[_c('v-subheader',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$vuetify.t("Medikácia"))+" ")]),_c('v-data-table',{staticClass:"elevation-4",attrs:{"headers":[
                  {
                    text: 'Popis Medikácie',
                    value: 'popisMedikacie',
                    sortable: false,
                  },
                ],"items":_vm.vysetrenie.Medikacia,"hide-actions":""},scopedSlots:_vm._u([{key:"items",fn:function({ item }){return [_c('tr',{staticClass:"noclicker"},[_c('td',[_vm._v(_vm._s(item.PopisMedikacie.originalText))])])]}}],null,false,3729990821)})],1):_vm._e(),(
                (_vm.isOdborneVysetrenie || _vm.isPrepustaciaSprava) &&
                _vm.hasImplantovanaZdravotnickaPomocka
              )?_c('v-flex',{attrs:{"xs12":""}},[_c('v-subheader',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$vuetify.t("Implantovaná zdravotnícka pomôcka"))+" ")]),_c('v-data-table',{staticClass:"elevation-4",attrs:{"headers":[
                  {
                    text: 'Druh zdravotníckej pomôcky',
                    value: 'druhZdravotnickejPomocky',
                    sortable: false,
                  },
                  {
                    text: 'Popis zdravotníckej pomôcky',
                    value: 'popisZdravotnickejPomocky',
                    sortable: false,
                  },
                ],"items":_vm.vysetrenie.ImplantovanaZdravotnickaPomocka,"hide-actions":""},scopedSlots:_vm._u([{key:"items",fn:function({ item }){return [_c('tr',{staticClass:"noclicker"},[(_vm.hasDruhZP(item))?_c('td',[_vm._v(" "+_vm._s(item.Druh.DisplayName)+" ")]):_vm._e(),(_vm.hasPopisZP(item))?_c('td',[_vm._v(" "+_vm._s(item.PopisZdravotnickejPomocky.originalText)+" ")]):_vm._e()])]}}],null,false,4203047226)})],1):_vm._e()],1),(_vm.vysetrenie !== null && _vm.isOdborneVysetrenie)?_c('EZdravieOdborneVysetrenie',{attrs:{"vysetrenie":_vm.vysetrenie}}):_vm._e(),(_vm.vysetrenie !== null && _vm.isZobrazovacieVysetrenie)?_c('EZdravieZobrazovacieVysetrenie',{attrs:{"vysetrenie":_vm.vysetrenie}}):_vm._e(),(_vm.vysetrenie !== null && _vm.isPrepustaciaSprava)?_c('EZdraviePrepustaciaSprava',{attrs:{"vysetrenie":_vm.vysetrenie}}):_vm._e()],1),(
            _vm.vysetrenie.OdporucanieNaVysetrenie &&
            (_vm.isOdborneVysetrenie || _vm.isPrepustaciaSprava)
          )?_c('v-tab',{attrs:{"ripple":""}},[_vm._v(" "+_vm._s(_vm.$vuetify.t("Výmenné lístky"))+" ")]):_vm._e(),(
            _vm.vysetrenie.OdporucanieNaVysetrenie &&
            (_vm.isOdborneVysetrenie || _vm.isPrepustaciaSprava)
          )?_c('v-tab-item',[_vm._l((_vm.vysetrenie.OdporucanieNaVysetrenie),function(odporucanie,index){return [_c('v-card',{key:'ezdravie-vysetrenie-odporucanie' + index,staticClass:"mt-4 elevation-4"},[_c('v-toolbar',{attrs:{"flat":"","dense":"","card":"","color":"grey darken-2","dark":""}},[_c('v-toolbar-title',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({staticClass:"subheading"},on),[_c('span',{staticClass:"font-weight-medium pl-1"},[_vm._v(" "+_vm._s(odporucanie.IdentifikatorVymennehoListku.extension))])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.t("Identifikátor výmenného lístka")))])])],1),_c('v-spacer'),(_vm.showPrevezmiVymennyListokButton)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"flat":"","icon":""},on:{"click":function($event){return _vm.$emit(
                          'prevzatieVymennehoListka',
                          odporucanie.IdentifikatorVymennehoListku.extension
                        )}}},on),[_c('v-icon',[_vm._v("download_done")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.t("Prevezmi výmenný lístok")))])]):_vm._e(),(_vm.showPrevezmiVymennyListokButton)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"flat":"","icon":""},on:{"click":function($event){return _vm.vytvorVysledok(odporucanie)}}},on),[_c('v-icon',[_vm._v("post_add")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.t("Vytvor vyšetrenie k VL")))])]):_vm._e()],1),_c('v-card-text',[(_vm.vysetrenie.OdporucanieNaVysetrenie.length > 0)?_c('EZdravieOdporucanie',{attrs:{"pacient-id":_vm.pacientId,"odporucanie":odporucanie}}):_vm._e()],1)],1)]})],2):_vm._e()],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }