<template>
  <v-container fluid>
    <v-layout row wrap>
      <v-flex class="text-xs-right" xs12>
        <v-btn color="primary" flat @click="addItem" align-self-end>{{
          $vuetify.t("Pridať")
        }}</v-btn>
      </v-flex>
      <v-flex xs12 class="mb-3">
        <v-card
          v-if="
            pacient !== null &&
            pacient.Rodina !== null &&
            pacient.Rodina.length > 0
          "
        >
          <v-list three-line subheader dense>
            <template v-for="(item, index) in pacient.Rodina">
              <v-list-tile :key="'list' + index" @click="editItem(item, index)">
                <v-list-tile-content>
                  <v-container fluid>
                    <v-layout row>
                      <v-flex xs4>
                        <v-tooltip top>
                          <span slot="activator">{{
                            item.PribuzenskyStav
                          }}</span>
                          <span>{{ $vuetify.t("Rodinný vztah") }}</span>
                        </v-tooltip>
                      </v-flex>
                      <v-flex xs8>
                        <v-tooltip top>
                          <span slot="activator">{{ item.Desc }}</span>
                          <span>{{ $vuetify.t("Anamnéza") }}</span>
                        </v-tooltip>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-tooltip left>
                    <v-icon slot="activator" @click.stop="removeItem(index)"
                      >delete</v-icon
                    >
                    <span>{{ $vuetify.t("Odstrániť") }}</span>
                  </v-tooltip>
                </v-list-tile-action>
              </v-list-tile>
              <template v-if="pacient.Rodina !== null">
                <v-divider
                  :key="'divider' + index"
                  v-if="index !== pacient.Rodina.length - 1"
                ></v-divider>
              </template>
            </template>
          </v-list>
        </v-card>
      </v-flex>
    </v-layout>
    <BaseDialog
      :dialogTitle="$vuetify.t(dialogTitle)"
      ref="dialog"
      @save="$refs.form.save()"
    >
      <FormRodina ref="form" :pacientId="id" @saved="savedRodina"></FormRodina>
    </BaseDialog>
  </v-container>
</template>

<script>
import BaseDialog from "./../../components/BaseDialog.vue"
import FormRodina from "./../../components/forms/FormRodina.vue"

export default {
  components: {
    BaseDialog,
    FormRodina,
  },
  props: ["id"],
  data: function () {
    return {
      dialogTitle: "",
    }
  },
  computed: {
    pacient: {
      get() {
        return this.$store.state.pacient
      },
    },
  },
  methods: {
    readPacient: function () {
      this.$store.commit("setReloadPacient", true)
    },
    addItem: function () {
      this.dialogTitle = "Nová rodinná anamnéza"
      this.$refs.form.new()
      this.$refs.dialog.open()
    },
    editItem: function (item, index) {
      this.dialogTitle = "Nová rodinná anamnéza"
      this.$refs.form.edit(item, index)
      this.$refs.dialog.open()
      // this.$refs.modalNewRodina.edit(this.patient.Rodina[aIndex], aIndex)
    },
    removeItem: function (aIndex) {
      this.$api
        .post(
          "/api/patient/" +
            this.$route.params.id +
            "/rodina/delete?index=" +
            aIndex,
          null
        )
        .then(() => {
          this.$store.commit(
            "setSuccess",
            this.$vuetify.t("Rodinná anamnéza bola úspešne odstránená")
          )
          this.readPacient()
        })
        .catch((err) => {
          this.$store.commit(
            "setError",
            this.$vuetify.t(
              "Pri odstraňovaní rodinnej anamnézy vznikla chyba "
            ) +
              " (" +
              err +
              ")."
          )
        })
    },
    savedRodina: function () {
      this.$refs.dialog.close()
      this.readPacient()
    },
  },
}
</script>
