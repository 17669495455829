<template>
  <v-container fluid>
    <v-form>
      <v-card>
        <v-card-text>
          <v-tabs>
            <v-tab>{{ $vuetify.t("Údaje používateľa") }}</v-tab>
            <!-- <v-tab>{{ $vuetify.t("Tlač") }}</v-tab> -->
            <!-- <v-tab>{{ $vuetify.t("Formuláre") }}</v-tab> -->
            <!-- <v-tab>{{ $vuetify.t("Značky") }}</v-tab> -->
          </v-tabs>
          <v-tabs-items>
            <v-tab-item>
              <v-container grid-list-md>
                <v-layout row wrap>
                  <v-flex xs12 md6>
                    <TextField
                      v-model="user.name"
                      name="user.name"
                      :label="$vuetify.t('Meno a priezvisko')"
                      :maxlength="255"
                      v-validate="'required'"
                    ></TextField>
                  </v-flex>
                  <v-flex xs12 md6>
                    <TextField
                      v-model="user.email"
                      name="user.email"
                      label="Email"
                      :maxlength="100"
                      v-validate="'required|email'"
                      disabled
                    ></TextField>
                  </v-flex>
                  <v-flex xs12 md6>
                    <vue-tel-input-vuetify
                      v-model="user.phone"
                      clearable
                      name="user.phone"
                      :label="$vuetify.t('Telefónne číslo')"
                      :placeholder="$vuetify.t('Telefónne číslo')"
                      :maxLen="255"
                      defaultCountry="SK"
                      :preferredCountries="[
                        'SK',
                        'CZ',
                        'AT',
                        'PL',
                        'HU',
                        'DE',
                        'UA',
                      ]"
                      @input="onPhoneInput"
                      :error-messages="errors.collect('phone')"
                    >
                    </vue-tel-input-vuetify>
                  </v-flex>
                  <v-flex xs12 md6>
                    <TextField
                      v-model="user.code"
                      name="user.code"
                      :label="$vuetify.t('Kód zdravotníckeho pracovníka')"
                      :maxlength="9"
                      v-validate="{
                        regex: /^([A-GIN]{1}[0-9]{8})$/,
                      }"
                      mask="A########"
                      clearable
                    ></TextField>
                  </v-flex>
                  <v-flex xs12 md6>
                    <codebook-autocomplete
                      codebook-id="34"
                      name="odbornosti"
                      v-model="user.odbornosti"
                      :label="$vuetify.t('Zdravotnícka odbornosť')"
                      v-validate="'required'"
                      :multiple="true"
                      :limit="0"
                    >
                    </codebook-autocomplete>
                  </v-flex>
                  <v-flex xs12 md6>
                    <TextField
                      v-model="user.jruz_id"
                      name="user.jruz_id"
                      :label="$vuetify.t('JRUZ ID')"
                      :maxlength="11"
                      v-validate="'numeric|min:11|max:11'"
                      mask="###########"
                    ></TextField>
                  </v-flex>
                  <v-flex xs12>
                    <v-textarea
                      auto-grow
                      rows="2"
                      v-model="user.jruz_id_key"
                      name="user.jruz_id_key"
                      :label="$vuetify.t('Verejný kľúč')"
                      clearable
                      v-validate="'max:1024'"
                      :counter="1024"
                    ></v-textarea>
                  </v-flex>
                  <v-flex xs12 md6>
                    <TextField
                      v-model="user.dovera_username"
                      name="user.dovera_username"
                      :label="$vuetify.t('Dôvera - prihlasovacie meno')"
                      :maxlength="100"
                    ></TextField>
                  </v-flex>
                  <v-flex xs12 md6>
                    <TextField
                      v-model="user.dovera_password"
                      name="user.dovera_password"
                      :label="$vuetify.t('Dôvera - heslo')"
                      :maxlength="100"
                    ></TextField>
                  </v-flex>
                  <v-flex xs12 md6>
                    <TextField
                      v-model="user.vszp_username"
                      name="user.vszp_username"
                      :label="$vuetify.t('VšZP - prihlasovacie meno')"
                      :maxlength="100"
                    ></TextField>
                  </v-flex>
                  <v-flex xs12 md6>
                    <TextField
                      v-model="user.vszp_password"
                      name="user.vszp_password"
                      :label="$vuetify.t('VšZP - heslo')"
                      :maxlength="100"
                    ></TextField>
                  </v-flex>
                  <v-flex xs12 md6>
                    <TextField
                      v-model="user.union_username"
                      name="user.union_username"
                      :label="$vuetify.t('UNION - prihlasovacie meno')"
                      :maxlength="100"
                    ></TextField>
                  </v-flex>
                  <v-flex xs12 md6>
                    <TextField
                      v-model="user.union_password"
                      name="user.union_password"
                      :label="$vuetify.t('UNION - heslo')"
                      :maxlength="100"
                    ></TextField>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions style="flex: 0 1 auto">
          <v-spacer></v-spacer>
          <v-btn flat color="primary" @click="save">{{
            $vuetify.t("Uložiť")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <!--
    <dialog-overenie-telefonneho-cisla
      ref="dialogOverenie"
      @smsCodeSent="fetchData"
      @saved="fetchData"
    >
    </dialog-overenie-telefonneho-cisla>
    -->
  </v-container>
</template>

<script>
import * as Miscella from "@/miscella.js"
import CodebookAutocomplete from "@/components/ciselnikComponents/CodebookAutocomplete.vue"
import TextField from "@/components/TextField.vue"
import VueTelInputVuetify from "@/components/vue-tel-input-vuetify/vue-tel-input-vuetify.vue"

export default {
  inject: ["$validator"],
  components: { TextField, VueTelInputVuetify, CodebookAutocomplete },
  data: function () {
    return {
      loading: false,
      message: {
        level: "",
        text: "",
      },
      mesta: [],
      countries: [],
      user: {
        name: "",
        email: "",
        phone: "",
        code: "",
        jruz_id: "",
        // isDoctor: false,
        // isActive: false,
        // isPhoneVerify: false,
        // doveraUsername: "",
        // doveraPassword: "",
        // vszpUsername: "",
        // vszpPassword: "",
        // unionUsername: "",
        // unionPassword: "",
        // phoneVerifyCode: "",
        // resendCode: false,
      },
      phone: {
        number: "",
        valid: false,
        country: undefined,
      },
    }
  },
  methods: {
    onPhoneInput: function (formattedNumber, { number, valid, country }) {
      this.phone.number = number.e164
      this.phone.valid = valid
      this.phone.country = country && country.name
    },
    fetchData: async function () {
      try {
        this.loading = true
        var me = await this.$store.dispatch("fetchMe", this.$api)
        this.user = Miscella.cloneObject(me.user)
        this.$validator.reset()
      } catch (err) {
        this.processError(err, "Pri načítaní údajov používateľa nastala chyba")
      } finally {
        this.loading = false
      }
    },
    save: function () {
      this.errors.clear()
      this.$validator.validateAll().then(
        function () {
          let isOK = true
          // miesto na custom validacie
          if (this.phone.number && !this.phone.valid) {
            this.errors.add({
              field: "phone",
              msg: this.$vuetify.t("Nesprávny formát telefónneho čísla."),
            })
          }
          if (this.errors.any() || !isOK) {
            event.stopPropagation()
            return
          }
          let params = {
            user: this.user,
          }
          params.user.phone = this.phone.number
          this.$api
            .post("/v1/proto.UserService/UpdateUser", params)
            .then(this.saveOK)
            .catch(this.saveErr)
        }.bind(this)
      )
    },
    saveOK: function () {
      this.$store.commit(
        "setSuccess",
        this.$vuetify.t("Používateľ bol úspešne uložený.")
      )
      this.$store.dispatch("reloadMe", this.$api)
      this.fetchData()
    },
    saveErr: function (error) {
      this.$store.commit("setError", "Chyba " + error)
    },
  },
  mounted: function () {
    console.log("ME.VUE mounted")
    this.errors.clear()
    this.fetchData()
  },
}
</script>

<style scoped></style>
