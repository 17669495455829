<template>
  <v-container grid-list-sm class="pa-0">
    <v-layout column>
      <v-flex>
        <v-select
          v-model="tag"
          :items="clinicTags"
          item-text="Name"
          return-object
          :label="$vuetify.t('Značka')"
          hide-details
          :disabled="
            tagIndex !== -1 || clinicTags === null || clinicTags.length === 0
          "
        ></v-select>
      </v-flex>
      <v-flex>
        <v-textarea
          label="Popis"
          v-model="tag.Description"
          auto-grow
          rows="2"
          :disabled="clinicTags === null || clinicTags.length === 0"
        ></v-textarea>
      </v-flex>
      <v-flex>
        <v-btn
          color="primary"
          flat
          @click="save"
          :disabled="clinicTags === null || clinicTags.length === 0"
          >{{ $vuetify.t("$vuetify.app.button.save") }}</v-btn
        >
        <v-btn flat @click.native="close">
          {{ $vuetify.t("$vuetify.app.button.close") }}
        </v-btn>
      </v-flex>
    </v-layout>
    <v-alert
      v-if="clinicTags === null || clinicTags.length === 0"
      type="warning"
      :value="true"
      outline
    >
      {{
        $vuetify.t(
          "Nemáte zadefinované vlastné značky. Tie si môžete zadefinovať v menu Administrácia > PZS > Značky."
        )
      }}
    </v-alert>
  </v-container>
</template>

<script>
import * as Miscella from "./../../miscella.js"

export default {
  props: {
    toggle: {
      type: Function,
      required: true,
    },
  },
  data: function () {
    return {
      tagIndex: -1,
      tag: {},
      clinicTags: [],
    }
  },
  computed: {
    pacient: function () {
      if (Miscella.isSet(this.$store.state.pacient)) {
        return this.$store.state.pacient
      }
      return {}
    },
  },
  methods: {
    init: function () {
      if (Miscella.isSet(this.$store.state.me.clinic)) {
        this.clinicTags = Miscella.deepCloneObject(
          this.$store.state.me.clinic.tags
        )
      } else {
        this.clinicTags = []
      }
    },
    new: function () {
      console.log("FormTag, new", this.tag)
      this.init()
      this.tagIndex = -1
      this.tag = { Color: "#808080", Name: "Príklad", Description: "" }
      if (this.clinicTags && this.clinicTags.length > 0) {
        this.tag = this.clinicTags[0]
      }
      this.toggle()
    },
    edit: function (aIndex) {
      console.log("FormTag, new", this.tag)
      this.init()
      this.tagIndex = aIndex
      this.tag = {}
      if (this.pacient.Tags === null) {
        this.pacient.Tags = []
      }
      if (this.pacient.Tags.length > 0) {
        this.tag = Miscella.cloneObject(this.pacient.Tags[aIndex])
      }
      this.toggle()
    },
    save: function () {
      if (this.tagIndex === -1) {
        if (this.pacient.Tags === null) {
          this.pacient.Tags = []
        }
        this.pacient.Tags.push(this.tag)
      } else {
        this.pacient.Tags[this.tagIndex] = this.tag
      }
      this.toggle()
      this.$emit("changed")
    },
    close: function () {
      this.toggle()
    },
  },
}
</script>
