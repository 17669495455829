<template>
  <v-layout row align-center justify-end>
    <v-tooltip top>
      <span slot="activator"
        >{{ lOffset + 1 }} - {{ lOffset + itemsVisibleCount }}</span
      >
      <span>{{ $vuetify.t("Zobrazené záznamy") }}</span>
    </v-tooltip>
    <v-tooltip top>
      <v-btn
        flat
        icon
        slot="activator"
        @click.stop="firstPage"
        :loading="loading"
        :disabled="lOffset == 0"
        ><v-icon>first_page</v-icon></v-btn
      >
      <span>{{ $vuetify.t("Prvá stránka") }}</span>
    </v-tooltip>
    <v-tooltip top>
      <v-btn
        flat
        icon
        slot="activator"
        @click.stop="prevPage"
        :loading="loading"
        :disabled="!hasPreviousPage"
        ><v-icon>$vuetify.icons.prev</v-icon></v-btn
      >
      <span>{{ $vuetify.t("Predchádzajúca stránka") }}</span>
    </v-tooltip>
    <v-tooltip top>
      <v-btn
        flat
        icon
        slot="activator"
        @click.stop="nextPage"
        :loading="loading"
        :disabled="!hasNextPage"
        ><v-icon>$vuetify.icons.next</v-icon></v-btn
      >
      <span>{{ $vuetify.t("Nasledujúca stránka") }}</span>
    </v-tooltip>
  </v-layout>
</template>
<script>
export default {
  props: ["offset", "itemsVisibleCount", "itemsCount", "loading", "pageSize"],
  data: function () {
    return {}
  },
  computed: {
    hasPreviousPage: function () {
      return this.lOffset > 0
    },
    hasNextPage: function () {
      return this.itemsCount > this.pageSize
    },
    lOffset: {
      get: function () {
        return this.offset
      },
      set: function (value) {
        this.$emit("setOffset", value)
      },
    },
  },
  methods: {
    firstPage: function () {
      this.lOffset = 0
      this.$emit("reload")
    },
    prevPage: function () {
      let os = this.lOffset - this.pageSize
      if (os < 0) {
        os = 0
      }
      this.lOffset = os
      this.$emit("reload")
    },
    nextPage: function () {
      this.lOffset = this.lOffset + this.pageSize
      this.$emit("reload")
    },
  },
}
</script>
