<template>
  <v-container fluid>
    <v-layout row wrap>
      <v-flex class="px-1">
        <v-autocomplete
          v-model="filterPacient"
          :label="$vuetify.t('Pacient')"
          :items="pacienti"
          item-text="full_name"
          clearable
          return-object
        >
        </v-autocomplete>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex class="px-1">
        <DatePicker
          v-model="filterDatumVznikuOd"
          :label="$vuetify.t('Dátum vzniku od')"
        ></DatePicker>
      </v-flex>
      <v-flex class="px-1">
        <DatePicker
          v-model="filterDatumVznikuDo"
          :label="$vuetify.t('Dátum vzniku do')"
        ></DatePicker>
      </v-flex>
      <v-flex class="px-1">
        <DatePicker
          v-model="filterZaciatokPlatnostiOd"
          :label="$vuetify.t('Začiatok platnosti od')"
        ></DatePicker>
      </v-flex>
      <v-flex class="px-1">
        <DatePicker
          v-model="filterZaciatokPlatnostiDo"
          :label="$vuetify.t('Začiatok platnosti do')"
        ></DatePicker>
      </v-flex>
      <v-flex class="px-1">
        <DatePicker
          v-model="filterKoniecPlatnostiOd"
          :label="$vuetify.t('Koniec platnosti od')"
        ></DatePicker>
      </v-flex>
      <v-flex class="px-1">
        <DatePicker
          v-model="filterKoniecPlatnostiDo"
          :label="$vuetify.t('Koniec platnosti do')"
        ></DatePicker>
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex xs12 class="mb-3">
        <template v-for="item in items">
          <v-card :key="item.patient.id">
            <EDPNList
              ref="list"
              :items="item.items"
              :showHistory="false"
              :showPredlzenie="false"
              :title="item.patient.full_name"
              @editItem="editItem"
            />
          </v-card>
        </template>
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex class="text-xs-center" xs12>
        <v-btn color="primary" flat @click="fetchData" align-self-end>{{
          $vuetify.t("Načítať potvrdenia o dočasnej PN z EZdravia")
        }}</v-btn>
      </v-flex>
    </v-layout>
    <BaseDialog
      ref="dialog"
      width="90%"
      :dialogTitle="pnPatient.full_name"
      @save="$refs.form.saveForm()"
    >
      <FormEDpn ref="form" :patientId="pnPatient.id"></FormEDpn>
      <template slot="buttons">
        <v-btn flat @click.native="$refs.form.print()">{{
          $vuetify.t("$vuetify.app.button.print")
        }}</v-btn>
        <v-btn flat @click.native="$refs.dialog.close()">{{
          $vuetify.t("$vuetify.app.button.close")
        }}</v-btn>
      </template>
    </BaseDialog>
  </v-container>
</template>

<script>
import * as enums from "@/plugins/enums.js"
import BaseDialog from "@/components/BaseDialog.vue"
import DatePicker from "@/components/DatePicker.vue"
import FormEDpn from "@/components/forms/FormEDpn.vue"
import EDPNList from "@/components/EDPNList.vue"
import * as Miscella from "./../../miscella.js"
import moment from "moment"
import { mapState } from "vuex"
import Vue from "vue"

export default {
  components: {
    BaseDialog,
    DatePicker,
    FormEDpn,
    EDPNList,
  },
  props: ["id"],
  data: function () {
    return {
      showAllItems: false,
      filterPacient: null,
      filterDatumVznikuOd: null,
      filterDatumVznikuDo: null,
      filterZaciatokPlatnostiOd: null,
      filterZaciatokPlatnostiDo: null,
      filterKoniecPlatnostiOd: null,
      filterKoniecPlatnostiDo: null,
      items: [],
      activeTab: 0,
      pacienti: [],
      pnPatient: { id: "", full_name: "" },
    }
  },
  computed: {
    ...mapState({
      ambulanceID: (state) => state.me?.ambulance?.id,
    }),
    enums: function () {
      return enums
    },
  },
  watch: {},
  methods: {
    editItem: async function (item) {
      console.log("EDPN.VUE, editItem", item, this.items)
      for (let i = 0; i < this.items.length; i++) {
        const it = this.items[i]
        for (let j = 0; j < it.items.length; j++) {
          const itit = it.items[j]
          if (itit.id === item.id) {
            //idem editovat tuto PN
            this.pnPatient = it.patient
          }
        }
      }
      await Vue.nextTick()
      let formDisabled = true
      await this.$refs.form.edit(item?.header?.rc_id, formDisabled)
      this.$refs.dialog.open()
    },
    fetchData: async function () {
      this.items = []
      let patients = []
      if (Miscella.isSet(this.filterPacient)) {
        patients.push(this.filterPacient)
      } else {
        for (let i = 0; i < this.pacienti.length; i++) {
          const p = this.pacienti[i]
          patients.push(p)
        }
      }
      for (let i = 0; i < patients.length; i++) {
        const pID = patients[i].id
        this.startAppLoading(
          //"Načítavam zoznam EDPN z Ezdravia, " + (i + 1) + " z " + patients.length + ".")
          "Načítavam zoznam EDPN z Ezdravia.")
        try {
          let items = await this.fetchDataOnePatient(pID)
          if (items.length > 0) {
            let item = { patient: patients[i], items: items }
            this.items.push(item)
          }
        } catch (error) {
          this.processError("Pri komunikácii s EZdravím vznikla chyba")
          console.log("Pri komunikácii s EZdravím vznikla chyba", error)
        } finally {
          this.stopAppLoading()
        }
      }
      console.log("fetchData END", this.items)
    },
    fetchDataOnePatient: async function (patientID) {
      console.log("EDPN.VUE, fetchDataOnePatient", patientID)
      let potvrdeniaDPN = []
      try {
        let res = await this.$api.ehealthService.vyhladajPotvrdenieDPN({
          patient_id: patientID,
          ambulance_id: this.ambulanceID,
          aj_stornovane_zaznamy: false,
          bez_konca_platnosti: false,
          datum_vzniku_zaznamu_od: this.filterDatumVznikuOd,
          datum_vzniku_zaznamu_do: this.filterDatumVznikuDo,
          zaciatok_platnosti_od: this.filterZaciatokPlatnostiOd,
          zaciatok_platnosti_do: this.filterZaciatokPlatnostiOd,
          koniec_platnosti_od: this.filterKoniecPlatnostiOd,
          koniec_platnosti_do: this.filterKoniecPlatnostiDo,
          strankovanie: {
            velkost_stranky: "500",
            index: "1",
          },
        })
        //console.log("EDPN.VUE, fetchData res", res)
        potvrdeniaDPN = res.potvrdenia_dpn
      } catch (error) {
        throw error
      } finally {
        return potvrdeniaDPN
      }
    },
    fetchPacients: async function () {
      console.log("EDPN.VUE, fetchPacients")
      this.pacienti = []
      try {
        let res = await this.$api.patientService.listPatients({
          clinic_id: this.$store.state.me.clinic
            ? this.$store.state.me.clinic.id
            : null,
          ambulance_id: this.$store.state.me.ambulance
            ? this.$store.state.me.ambulance.id
            : null,
          table_request: {
            limit: 0,
          },
        })
        console.log("EDPN.VUE, fetchPacients res", res)
        let pacientiAll = res.patients
        let pacienti = []
        for (let i = 0; i < pacientiAll.length; i++) {
          const p = pacientiAll[i]
          if (p.jruz_id !== "") {
            pacienti.push(p)
          }
        }
        this.pacienti = pacienti
      } catch (error) {
        this.processError(error)
        console.log("error", error)
      } finally {
      }
    },
  },
  mounted: async function () {
    await this.$store.dispatch("fetchMe", this.$api)
    this.fetchPacients()
  },
}
</script>
