<template>
  <v-container fluid px-0>
    <v-layout row>
      <v-flex xs1>
        <v-tooltip top>
          <span slot="activator">{{
            chirurgickyVykon.date | formatDatum
          }}</span>
          <span>{{ $vuetify.t("Približný dátum výkonu") }}</span>
        </v-tooltip>
      </v-flex>
      <v-flex xs5>
        <v-tooltip top v-if="chirurgickyVykon.chirurgicky_vykon.typ !== null">
          <span slot="activator">{{
            chirurgickyVykon.chirurgicky_vykon.typ.display_name
          }}</span>
          <span>{{ $vuetify.t("Typ") }}</span>
        </v-tooltip>
      </v-flex>
      <v-flex xs6 class="text-truncate">
        <v-tooltip top>
          <span slot="activator">{{
            chirurgickyVykon.chirurgicky_vykon.poznamka
          }}</span>
          <span>{{ $vuetify.t("Poznámka") }}</span>
        </v-tooltip>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  name: "ChirurgickyVykonRow",
  props: {
    chirurgickyVykon: {
      type: Object,
      required: true,
    },
  },
}
</script>
