import * as Miscella from "../../miscella.js"
import Vue from "vue"

export var mixins = {
  methods: {
    sortTable: function (event) {
      console.log("SortTable", event.target.id)
      if (this.sortBy === event.target.id) {
        this.sortAsc = !this.sortAsc
      } else {
        this.sortBy = event.target.id
        this.sortAsc = true
      }
      this.fetchData()
    },

    changePage: function (newPage) {
      this.itemsPage = newPage
      this.fetchData()
    },

    setItems: function (tableResponse) {
      if (tableResponse.tableData != null) {
        this.cisItems = tableResponse.tableData
      } else {
        this.cisItems = tableResponse
      }
      this.pagination.page = tableResponse.tableInfo.Page
      this.pagination.pages = tableResponse.tableInfo.PageCount
      this.pagination.totalItems = tableResponse.tableInfo.Count
      this.loading = false
      Vue.nextTick(
        function () {
          this.loading = false
        }.bind(this)
      )
    },

    xErr: function (err) {
      console.error(err)
      this.loading = false
    },

    fetchData: function () {
      this.cisItems = []
      var params = {
        sortBy: this.pagination.sortBy,
        sortAsc: this.pagination.sortAsc,
        filter: this.itemsFilter,
        page: this.pagination.page,
      }

      this.loading = true
      return this.$api
        .get(this.cisItemsUrl, params)
        .then(this.setItems)
        .catch(this.xErr)
    },

    changeFilter: function (newFilter) {
      this.itemsFilter = newFilter
      Miscella.myDelay(
        function () {
          this.itemsPage = 1
          this.fetchData()
        }.bind(this),
        500
      )
    },

    itemClicked: function (item) {
      this.chosen = item
    },
  },
}
