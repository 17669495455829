<template>
  <v-layout column>
    <ApiTable
      ref="table"
      :headers="headers"
      :url="$api.ehealthService.ehealthRecordsUrl"
      :title="$vuetify.t('Fronta')"
      defaultSortBy="CreatedAt"
      defaultSortDesc
      :customParams="customParams"
      :hasFilter="false"
    >
      <template v-slot:beforeTable>
        <v-expansion-panel v-model="filterPanel">
          <v-expansion-panel-content>
            <template v-slot:header>
              <span class="subheading">{{ $vuetify.t("Filter") }}</span>
            </template>
            <v-container fluid>
              <v-layout row>
                <v-flex xs12 md4>
                  <v-select
                    ref="filter"
                    v-model="filter.status"
                    :label="$vuetify.t('Stav')"
                    :items="statusFilterItems"
                    multiple
                    chips
                    deletable-chips
                    small-chips
                    @change="onChangeFilter"
                    clearable
                  >
                    <template v-slot:prepend-item>
                      <v-list-tile ripple @click="onClickCloseStatusFilter">
                        <v-list-tile-action>
                          <v-icon>close</v-icon>
                        </v-list-tile-action>
                        <v-list-tile-content>
                          <v-list-tile-title>Zavrieť</v-list-tile-title>
                        </v-list-tile-content>
                      </v-list-tile>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-flex>
              </v-layout>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </template>
      <template v-slot:items="{ item }">
        <tr>
          <td class="text-md-left">
            {{ item.created_at | formatDatumACas }}
          </td>
          <td class="text-md-left">
            <EhealthRecord
              :value="item"
              :objectID="item.object_id"
              :statusTextPrefix="`$vuetify.enum.ehealthRecord.versionStatus.${item.version_status}`"
              table
              compact
              @input="reload"
            ></EhealthRecord>
          </td>
          <td class="text-md-left">
            {{
              $vuetify.t(
                `$vuetify.enum.ehealthRecord.type.${item.object_type}.text`
              )
            }}
          </td>
          <td class="text-md-right">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" @click.stop="showLogs(item)">list </v-icon>
              </template>
              <span>
                {{ $vuetify.t("Zobraziť logy") }}
              </span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" @click.stop="goToObject(item)"
                  >read_more
                </v-icon>
              </template>
              <span>
                {{
                  $vuetify.t(
                    `$vuetify.enum.ehealthRecord.type.${item.object_type}.goToObject`
                  )
                }}
              </span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </ApiTable>
    <BaseDialog :dialogTitle="$vuetify.t('Logy')" width="75%" ref="logsDialog">
      <EZdravieLog :customParams="customParamsLogs"></EZdravieLog>
    </BaseDialog>
    <BaseDialog
      ref="dialogPN"
      width="90%"
      :dialogTitle="$vuetify.t('Elektronické potvrdenie o dočasnej PN')"
      @save="$refs.formPN.saveForm()"
    >
      <FormEDpn ref="formPN" :patientId="patientId" @saved="reload"></FormEDpn>
      <template slot="buttons">
        <v-btn flat @click.native="$refs.formPN.print()">{{
          $vuetify.t("$vuetify.app.button.print")
        }}</v-btn>
        <!--
        <v-btn
          flat
          :disabled="$refs.formPN?.stav === 0 || $refs.formPN?.stav === 3"
          @click.native="$refs.formPN.storno()"
          >{{ $vuetify.t("$vuetify.app.button.storno") }}</v-btn
        >

        <v-btn
          flat
          :disabled="$refs.formPN?.stav > 1"
          @click.native="$refs.dialogPN.save()"
          >{{ $vuetify.t("$vuetify.app.button.send") }}</v-btn
        >
        -->
        <v-btn flat @click.native="$refs.dialogPN.close()">{{
          $vuetify.t("$vuetify.app.button.close")
        }}</v-btn>
      </template>
    </BaseDialog>
  </v-layout>
</template>

<script>
import * as enums from "@/plugins/enums.js"
import ApiTable from "@/components/ApiTable.vue"
import BaseDialog from "@/components/BaseDialog.vue"
import EhealthRecord from "@/components/EhealthRecord.vue"
import EZdravieLog from "@/pages/admin/logs/EZdravieLog.vue"
import FormEDpn from "@/components/forms/FormEDpn.vue"
import Vue from "vue"
import { mapState, mapGetters } from "vuex"

export default {
  components: {
    ApiTable,
    EhealthRecord,
    BaseDialog,
    EZdravieLog,
    FormEDpn,
  },
  props: {
    patientId: {
      type: String,
      required: false,
      default: "",
    },
  },
  data: function () {
    var statusFilterItems = []
    for (let i = 1; i <= enums.EhealthRecordStatus.Success; i++) {
      statusFilterItems.push({
        text: this.$vuetify.t(`$vuetify.enum.ehealthRecord.status.${i}.text`),
        value: i,
      })
    }
    return {
      enums: enums,
      headers: [
        { text: "Vytvorené", sortable: true, value: "CreatedAt" },
        { text: "Stav", sortable: false, value: "Status" },
        { text: "Typ", sortable: false, value: "ObjectType" },
        { text: "", sortable: false, value: "" },
      ],
      filter: {
        status: [],
      },
      statusFilterItems: statusFilterItems,
      filterPanel: 0,
      chosen: null,
    }
  },
  computed: {
    customParams: function () {
      return {
        patient_id: this.patientId,
        status: this.filter.status,
      }
    },
    customParamsLogs: function () {
      var params = {
        patient_id: this.patientId,
        ehealth_record_id: null,
      }
      if (this.chosen !== null) {
        params.ehealth_record_id = this.chosen.id
      }
      return params
    },
    ...mapState({
      events: (state) => state.events.queue,
    }),
    ...mapGetters(["getUserEventTopic"]),
  },
  watch: {
    events: function (evt) {
      if (
        evt.event === this.getUserEventTopic("ehealth:ehealthRecord:created")
      ) {
        this.reload()
      }
    },
  },
  methods: {
    reload: function () {
      Vue.nextTick(() => {
        this.$refs.table.reload()
      })
    },
    onChangeFilter: function () {
      this.reload()
    },
    onClickCloseStatusFilter: function (evt) {
      this.$refs.filter.isMenuActive = false
      /*Vue.nextTick(() => {
        this.$refs.table.reload()
      })*/
    },
    showLogs: function (item) {
      this.chosen = item
      this.$refs.logsDialog.open()
    },
    goToObject: function (item) {
      switch (item.object_type) {
        case "ZAPIS_ZAZNAM_O_VYSETRENI_V7":
        case "STORNUJ_ZAZNAM_O_VYSETRENI_V7":
          console.log("case GO tO OBJECt ", item)
          this.$router.push({
            name: "vysetrenie",
            params: { id: item.patient_id, vysetrenieID: item.object_id },
          })
          break
        case "ZAPIS_POTVRDENIE_DPN":
          console.log("goToObject", item)
          this.editPN(item)
          break
      }
    },
    editPN: function (item) {
      console.log("EHEalthQueue.VUE, editPN", item)
      this.$refs.formPN.editFromObject(item)
      this.$refs.dialogPN.open()
    },
  },
}
</script>

<style scoped></style>
