<template>
  <v-app id="inspire" :dark="isThemeDark" :light="!isThemeDark">
    <message-bar></message-bar>
    <router-view></router-view>
  </v-app>
</template>

<script>
import MessageBar from "./../components/MessageBar.vue"
import { mapGetters } from "vuex"
import * as Miscella from "../miscella.js"

export default {
  components: {
    MessageBar,
  },
  data: function () {
    return {
      ezdravieDialog: false,
      twitterKey: 0,
      dragging: false,
    }
  },
  computed: {
    theme: {
      get: function () {
        let ret = this.$store.state.theme
        if (ret === null || ret === "") {
          ret = localStorage.getItem("theme")
        }
        return ret
      },
      set: function (val) {
        if (val !== "") {
          this.$store.commit("setTheme", val)
        }
      },
    },
    ...mapGetters(["isThemeDark"]),
  },
  watch: {},
  methods: {},
  mounted: async function () {
    let eZdravieCardIP = localStorage.getItem("eZdravieCardIP")
    if (!Miscella.isEmpty(eZdravieCardIP)) {
      this.$store.commit("setEhealthIP", eZdravieCardIP)
    }
    await this.$store.dispatch("fetchMe", this.$api)
    this.$store.dispatch("fetchServerClinicStatus", this.$api)
    setInterval(() => {
      this.$store.dispatch("fetchServerClinicStatus", this.$api)
    }, 10 * 1000)
  },
}
</script>
<style></style>
