<template>
  <div>
    <v-navigation-drawer
      app
      :mini-variant="miniDrawer"
      :mini-variant-width="50"
      right
      clipped
      fixed
      width="200"
      permanent
    >
      <v-tooltip left v-if="!miniDrawer">
        <template v-slot:activator="{ on }">
          <v-btn
            absolute
            icon
            color="grey"
            flat
            @click.stop="toggleDrawer"
            style="top: 2px; left: 2px; z-index: 10"
            v-on="on"
          >
            <v-icon>chevron_right</v-icon>
          </v-btn>
        </template>
        <span>{{ $vuetify.t("Zbaliť panel") }} </span>
      </v-tooltip>
      <v-list :class="{ 'mt-4': !miniDrawer }" dense>
        <v-list-tile v-if="miniDrawer">
          <v-list-tile-action>
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn
                  flat
                  icon
                  @click.stop="toggleDrawer"
                  v-on="on"
                  color="grey"
                >
                  <v-icon>chevron_left</v-icon>
                </v-btn>
              </template>
              <span>{{ $vuetify.t("Roztiahnuť panel") }} </span>
            </v-tooltip>
          </v-list-tile-action>
        </v-list-tile>
        <template
          v-for="item in [
            {
              title: 'Nové vyšetrenie',
              icon: $vuetify.t('$vuetify.thing.type.ThingVysetrenie.icon'),
              handler: addVysetrenie,
              disabled: isNew,
            },
            {
              title: 'Nová diagnóza',
              icon: $vuetify.t('$vuetify.thing.type.ThingDiagnoza.icon'),
              handler: getHandler(addItem, 'diagnozaForm'),
              disabled: !isEditable,
            },
            {
              title: 'Nový výmenný lístok',
              icon: $vuetify.t('$vuetify.thing.type.ThingOdporucanie.icon'),
              handler: getHandler(addItem, 'odporucanieForm'),
              disabled: !isEditable || isZobrazovacieVysetrenie,
            },
            {
              title: 'Nový recept',
              icon: $vuetify.t('$vuetify.thing.type.ThingRecept.icon'),
              handler: addRecept,
              disabled: !isEditable || isZobrazovacieVysetrenie,
            },
            /*{
              title: 'Nový výkon',
              icon: $vuetify.t('$vuetify.thing.type.ThingVykon.icon'),
              handler: addVykon,
              disabled: !isEditable,
            },*/
            {
              title: 'Nová nežiadúca reakcia',
              icon: $vuetify.t(
                '$vuetify.thing.type.ThingNeziaducaReakcia.icon'
              ),
              handler: getHandler(addItem, 'alergiaForm'),
              disabled: !isEditable || isZobrazovacieVysetrenie,
            },
            {
              title: 'Nové očkovanie',
              icon: $vuetify.t('$vuetify.thing.type.ThingOckovanie.icon'),
              handler: getHandler(addItem, 'ockovanieForm'),
              disabled: !isEditable || isZobrazovacieVysetrenie,
            },
            {
              title: 'Nová medikácia',
              icon: $vuetify.t('$vuetify.thing.type.ThingMedikacia.icon'),
              handler: getHandler(addItem, 'medikaciaForm'),
              disabled: !isEditable || isZobrazovacieVysetrenie,
            },
            {
              title: 'Nová zdravotnícka pomôcka',
              icon: $vuetify.t(
                '$vuetify.thing.type.ThingZdravotnickaPomocka.icon'
              ),
              handler: getHandler(addItem, 'zdravotnickaPomockaForm'),
              disabled: !isEditable || isZobrazovacieVysetrenie,
            },
            {
              title: 'Nové meranie krvného tlaku',
              icon: $vuetify.t('$vuetify.thing.type.ThingKrvnyTlak.icon'),

              handler: getHandler(addItem, 'krvnyTlakForm'),
              disabled: !isEditable || isZobrazovacieVysetrenie,
            },
            {
              title: 'Nové meranie  vitálnych a antropometrických hodnôt',
              icon: $vuetify.t(
                '$vuetify.thing.type.ThingVitAntropHodnoty.icon'
              ),
              handler: getHandler(addItem, 'vitAntropHodnotyForm'),
              disabled: !isEditable || isZobrazovacieVysetrenie,
            },
            {
              title: 'Nové vyš. krvnej skupiny',
              icon: $vuetify.t('$vuetify.thing.type.ThingKrvnaSkupina.icon'),

              handler: getHandler(addItem, 'krvnaSkupinaForm'),
              disabled: !isEditable || isZobrazovacieVysetrenie,
            },
            {
              title: 'Nový chirurgický výkon',
              icon: 'fas fa-user-injured',
              handler: getHandler(addItem, 'chirurgickyVykonForm'),
              disabled: !isEditable || isZobrazovacieVysetrenie,
            },
            {
              title: 'Nový zdravotný výkon',
              icon: 'fas fa-user-injured',
              handler: getHandler(addItem, 'vykonForm'),
              disabled: !isEditable,
            },
            {
              title: 'Reštrikcia záznamu',
              icon: $vuetify.t('$vuetify.thing.type.ThingRestrikcia.icon'),
              handler: getHandler(addItem, 'restrikciaForm'),
              disabled:
                !isEditable ||
                maRestrikciu ||
                !maAmbulanciaPravoZadatRestrikciu,
            },
            {
              title: 'Vyhľadať vým.lístok',
              icon: 'far fa-heart',
              handler: searchEZdravieVysetrenie,
              disabled: !canSearchInEhealthByID,
            },
            {
              title: 'Vyhľadať vyšetrenia',
              icon: 'far fa-heart',
              handler: searchEZdravieVysetrenia,
              disabled: !canSearchInEhealth,
            },
            {
              title: 'Tlačiť vyšetrenie',
              icon: 'print',
              handler: printVysetrenie,
              disabled: isNew,
            },
          ]"
        >
          <v-list-tile
            :key="item.title"
            @click="item.handler"
            :disabled="item.disabled"
          >
            <v-list-tile-action>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-icon small v-on="on" color="primary">{{
                    item.icon
                  }}</v-icon>
                </template>
                <span>{{ $vuetify.t(item.title) }}</span>
              </v-tooltip>
            </v-list-tile-action>

            <v-list-tile-content>
              <v-list-tile-title>{{ item.title }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </template>
        <!-- <base-popup -->
        <!--   v-if="pacient.kontrola" -->
        <!--   :title="$vuetify.t('Aktuálny termín kontroly: ') + kontrolaText" -->
        <!-- > -->
        <!--   <template v-slot:activator="{ toggle }"> -->
        <!--     <v-list-tile @click="toggle" :disabled="!isEditable"> -->
        <!--       <v-list-tile-action> -->
        <!--         <v-tooltip left> -->
        <!--           <template v-slot:activator="{ on }"> -->
        <!--             <v-icon v-on="on" color="red" small>calendar_today</v-icon> -->
        <!--           </template> -->
        <!--           <span>{{ $vuetify.t("Termín kontroly") }}</span> -->
        <!--         </v-tooltip> -->
        <!--       </v-list-tile-action> -->

        <!--       <v-list-tile-content> -->
        <!--         <v-list-tile-title>{{ -->
        <!--           $vuetify.t("Termín kontroly") -->
        <!--         }}</v-list-tile-title> -->
        <!--       </v-list-tile-content> -->
        <!--     </v-list-tile> -->
        <!--   </template> -->
        <!--   <template v-slot="{ toggle }"> -->
        <!--     <form-kontrola -->
        <!--       :pacientId="id" -->
        <!--       :kontrola="pacient.kontrola" -->
        <!--       @saved=" -->
        <!--         toggle() -->
        <!--         readPacient() -->
        <!--       " -->
        <!--     /> -->
        <!--   </template> -->
        <!-- </base-popup> -->
        <!-- <template -->
        <!--   v-for="item in [ -->
        <!--     { -->
        <!--       title: 'Objednanie', -->
        <!--       icon: 'calendar_today', -->
        <!--       handler: addObjednanie, -->
        <!--       disabled: !isEditable, -->
        <!--     }, -->
        <!--   ]" -->
        <!-- > -->
        <!--   <v-list-tile -->
        <!--     :key="item.title" -->
        <!--     @click="item.handler" -->
        <!--     :disabled="item.disabled" -->
        <!--   > -->
        <!--     <v-list-tile-action> -->
        <!--       <v-tooltip left> -->
        <!--         <template v-slot:activator="{ on }"> -->
        <!--           <v-icon v-on="on" small color="red">{{ item.icon }}</v-icon> -->
        <!--         </template> -->
        <!--         <span>{{ item.title }}</span> -->
        <!--       </v-tooltip> -->
        <!--     </v-list-tile-action> -->

        <!--     <v-list-tile-content> -->
        <!--       <v-list-tile-title>{{ item.title }}</v-list-tile-title> -->
        <!--     </v-list-tile-content> -->
        <!--   </v-list-tile> -->
        <!-- </template> -->
        <v-list-tile @click="showFormTree()" :disabled="!isEditable">
          <v-list-tile-action>
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-icon small v-on="on" color="green">far fa-file-alt</v-icon>
              </template>
              <span>{{ $vuetify.t("Formulár ...") }}</span>
            </v-tooltip>
          </v-list-tile-action>

          <v-list-tile-content>
            <v-list-tile-title>{{
              $vuetify.t("Formulár ...")
            }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <template v-for="form in formDefinitions">
          <v-list-tile
            :key="form.ID"
            @click="addThingForm(form)"
            :disabled="!isEditable"
          >
            <v-list-tile-action>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" small color="green">far fa-file-alt</v-icon>
                </template>
                <span>{{ form.title }}</span>
              </v-tooltip>
            </v-list-tile-action>

            <v-list-tile-content>
              <v-list-tile-title>
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">{{ form.title }}</span>
                  </template>
                  <span>{{ form.title }}</span>
                </v-tooltip>
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </template>
        <!-- <v-list-tile @click="showFormTree()" :disabled="!isEditable"> -->
        <!--   <v-list-tile-action> -->
        <!--     <v-tooltip left> -->
        <!--       <template v-slot:activator="{ on }"> -->
        <!--         <v-icon v-on="on" small color="green">vertical_split</v-icon> -->
        <!--       </template> -->
        <!--       <span>{{ $vuetify.t("Formulár ...") }}</span> -->
        <!--     </v-tooltip> -->
        <!--   </v-list-tile-action> -->

        <!--   <v-list-tile-content> -->
        <!--     <v-list-tile-title>{{ -->
        <!--       $vuetify.t("Formulár ...") -->
        <!--     }}</v-list-tile-title> -->
        <!--   </v-list-tile-content> -->
        <!-- </v-list-tile> -->
      </v-list>
    </v-navigation-drawer>
    <v-form id="vysetrenie-main">
      <v-container fluid>
        <v-layout row v-if="pocetNovsichVysetreni > 0">
          <v-flex xs12>
            <v-card class="mb-1 elevation-4">
              <v-toolbar flat dense card>
                <v-spacer></v-spacer>
                <router-link
                  tag="a"
                  :to="{ name: 'vysetrenie', params: { id: id } }"
                  >{{
                    $vuetify.t("Počet novších vyšetrení: ") +
                    pocetNovsichVysetreni
                  }}</router-link
                >
              </v-toolbar>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs12>
            <v-card class="mb-1 elevation-4">
              <v-toolbar flat dense card color="primary" dark>
                <v-toolbar-title>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">{{
                        isNew ? "fiber_new" : "edit"
                      }}</v-icon>
                    </template>
                    <span>{{
                      $vuetify.t(
                        isNew ? "Nové vyšetrenie" : "Editácia vyšetrenia"
                      )
                    }}</span>
                  </v-tooltip>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip top v-if="model.author">
                  <template v-slot:activator="{ on }">
                    <v-chip v-on="on">
                      <span class="subheading">
                        <v-icon small>fas fa-user-md</v-icon>
                        <span class="font-weight-medium pl-1">
                          {{ model.author ? model.author.name : "" }}
                        </span>
                      </span>
                    </v-chip>
                  </template>
                  <span>{{ $vuetify.t("Ošetrujúci lekár") }}</span>
                </v-tooltip>
                <v-tooltip top v-if="hlavnaDiagnoza">
                  <template v-slot:activator="{ on }">
                    <div v-on="on" style="max-width: 300px">
                      <v-chip class="text-truncate" style="max-width: 300px">
                        <span class="subheading text-truncate">
                          <v-icon small>{{
                            $vuetify.t("$vuetify.thing.type.ThingDiagnoza.icon")
                          }}</v-icon>
                          <span class="font-weight-medium pl-1 hidden-xs-only">
                            {{ hlavnaDiagnoza.diagnoza.diagnoza.skratka }}
                          </span>
                        </span>
                      </v-chip>
                    </div>
                  </template>
                  <span
                    >{{ $vuetify.t("Hlavná diagnóza") }}:
                    {{ hlavnaDiagnoza.diagnoza.diagnoza.skratka }}
                    {{ hlavnaDiagnoza.diagnoza.diagnoza.display_name }}</span
                  >
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      flat
                      icon
                      v-on="on"
                      @click="saveForm"
                      :disabled="!isEditable"
                      ><v-icon>save</v-icon></v-btn
                    >
                  </template>
                  <span>{{ $vuetify.t("Uložiť vyšetrenie") }}</span>
                </v-tooltip>
                <!-- <v-tooltip top> -->
                <!--   <template v-slot:activator="{ on }"> -->
                <!--     <v-btn -->
                <!--       flat -->
                <!--       icon -->
                <!--       v-on="on" -->
                <!--       @click="saveToEhealth" -->
                <!--       :disabled="!canSaveToEhealth" -->
                <!--       ><v-icon>cloud_upload</v-icon></v-btn -->
                <!--     > -->
                <!--   </template> -->
                <!--   <span>{{ $vuetify.t("Odoslať do ezdravia") }}</span> -->
                <!-- </v-tooltip> -->
                <!-- <v-tooltip top> -->
                <!--   <template v-slot:activator="{ on }"> -->
                <!--     <v-btn -->
                <!--       flat -->
                <!--       icon -->
                <!--       v-on="on" -->
                <!--       @click="deleteInEhealth" -->
                <!--       :disabled="!canDeleteInEhealth" -->
                <!--       ><v-icon>delete</v-icon></v-btn -->
                <!--     > -->
                <!--   </template> -->
                <!--   <span>{{ $vuetify.t("Storno v ezdraví") }}</span> -->
                <!-- </v-tooltip> -->
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      flat
                      icon
                      v-on="on"
                      @click="printVysetrenie"
                      :disabled="isNew"
                      ><v-icon>print</v-icon></v-btn
                    >
                  </template>
                  <span>{{ $vuetify.t("Tlačiť vyšetrenie") }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      flat
                      icon
                      v-on="on"
                      @click="deleteVysetrenie"
                      :disabled="!isEditable"
                      ><v-icon>delete</v-icon></v-btn
                    >
                  </template>
                  <span>{{ $vuetify.t("Odstrániť vyšetrenie") }}</span>
                </v-tooltip>
                <v-menu open-on-hover bottom offset-y>
                  <v-btn flat icon slot="activator">
                    <v-icon>favorite</v-icon>
                  </v-btn>

                  <v-card>
                    <v-list dense>
                      <template
                        v-for="item in [
                          {
                            title: 'Uložiť do ezdravia',
                            icon: 'cloud_upload',
                            handler: saveToEhealth,
                            disabled: !canSaveToEhealth,
                          },
                          {
                            title: 'Storno v ezdraví',
                            icon: 'delete',
                            handler: deleteInEhealth,
                            disabled: !canDeleteInEhealth,
                          },
                        ]"
                      >
                        <v-list-tile
                          :key="item.title"
                          @click="item.handler"
                          :disabled="item.disabled"
                        >
                          <!-- TODO zobrazit aj ikonu? -->
                          <!-- <v-list-tile-action> -->
                          <!--   <v-tooltip left> -->
                          <!--     <template v-slot:activator="{ on }"> -->
                          <!--       <v-icon small v-on="on" color="primary">{{ -->
                          <!--         item.icon -->
                          <!--       }}</v-icon> -->
                          <!--     </template> -->
                          <!--     <span>{{ $vuetify.t(item.title) }}</span> -->
                          <!--   </v-tooltip> -->
                          <!-- </v-list-tile-action> -->

                          <v-list-tile-content>
                            <v-list-tile-title>{{
                              item.title
                            }}</v-list-tile-title>
                          </v-list-tile-content>
                        </v-list-tile>
                      </template>
                    </v-list>
                  </v-card>
                </v-menu>
              </v-toolbar>
              <v-card-text>
                <v-container fluid grid-list-sm class="pa-0">
                  <v-layout row wrap align-center>
                    <!-- <v-flex xs12 md4> -->
                    <!--   <v-btn @click="saveForm" :disabled="!isEditable">{{ -->
                    <!--     $vuetify.t("Uložiť vyšetrenie") -->
                    <!--   }}</v-btn> -->
                    <!--   <v-btn -->
                    <!--     @click="saveToEhealth" -->
                    <!--     :disabled="!canSaveToEhealth" -->
                    <!--     >{{ $vuetify.t("Odoslať do ezdravia") }}</v-btn -->
                    <!--   > -->
                    <!-- </v-flex> -->
                    <!-- <v-flex xs12 md3> -->
                    <!--   <span v-if="isEVysetrenie"> -->
                    <!--     <strong>{{ -->
                    <!--       $vuetify.t("Stav odoslania do ezdravia") -->
                    <!--     }}</strong -->
                    <!--     >: -->
                    <!--     {{ -->
                    <!--       $vuetify.t( -->
                    <!--         "$vuetify.enum.evysetrenie.stav." + -->
                    <!--           model.EVysetrenie.Stav -->
                    <!--       ) -->
                    <!--     }} -->
                    <!--   </span> -->
                    <!-- </v-flex> -->
                    <!-- <v-flex xs12 md3> -->
                    <!--   <router-link -->
                    <!--     v-if="pocetNovsichVysetreni > 0" -->
                    <!--     tag="a" -->
                    <!--     :to="{ name: 'vysetrenie', params: { id: id } }" -->
                    <!--     >{{ -->
                    <!--       $vuetify.t("Počet novších vyšetrení: ") + -->
                    <!--         pocetNovsichVysetreni -->
                    <!--     }}</router-link -->
                    <!--   > -->
                    <!-- </v-flex> -->
                    <!-- <v-flex xs12 md2 class="text-sm-right"> -->
                    <!--   <v-tooltip top v-if="authorName"> -->
                    <!--     <template v-slot:activator="{ on }"> -->
                    <!--       <span v-on="on"> -->
                    <!--         <v-icon small>fas fa-user-md</v-icon> -->
                    <!--         <span class="subheading"> -->
                    <!--           {{ authorName }} -->
                    <!--         </span> -->
                    <!--       </span> -->
                    <!--     </template> -->
                    <!--     <span>{{ $vuetify.t("Ošetrujúci lekár") }}</span> -->
                    <!--   </v-tooltip> -->
                    <!-- </v-flex> -->
                    <v-flex xs12 sm6 md4>
                      <DatePicker
                        v-model="model.date"
                        :label="$vuetify.t('Dátum')"
                        name="date"
                        v-validate="'required'"
                        :disabled="!isEditable"
                      ></DatePicker>
                    </v-flex>
                    <v-spacer />
                    <v-flex xs12 sm6 md4>
                      <EhealthRecord
                        v-model="model.ehealth_record"
                        :objectID="vysetrenieID"
                        titleText="evyšetrenie"
                        :statusTextPrefix="`$vuetify.enum.ehealthRecord.versionStatus.${
                          model.ehealth_record
                            ? model.ehealth_record.version_status
                            : ''
                        }`"
                      ></EhealthRecord>
                    </v-flex>
                    <v-flex xs12>
                      <text-editor
                        ref="popisEditor"
                        v-model="model.vysetrenie.popis"
                        :disabled="!isEditable"
                        :placeholder="
                          $vuetify.t('Prosím, zadajte popis vyšetrenia ...')
                        "
                      />
                    </v-flex>
                    <v-flex xs12>
                      <v-expansion-panel
                        v-model="doplnkovePoliaPanel"
                        class="elevation-1"
                      >
                        <v-expansion-panel-content>
                          <template v-slot:header>
                            <span class="caption">{{
                              $vuetify.t("Doplnkové polia")
                            }}</span>
                          </template>
                          <v-container fluid grid-list-sm>
                            <v-layout row wrap>
                              <v-flex xs12 md6>
                                <v-select
                                  v-model="model.vysetrenie.typ"
                                  :label="$vuetify.t('Typ vyšetrenia')"
                                  :items="[
                                    {
                                      text: $vuetify.t(
                                        '$vuetify.enum.evysetrenie.typ.ZAZNAM_O_ODBORNOM_VYSETRENI'
                                      ),
                                      value: 'ZAZNAM_O_ODBORNOM_VYSETRENI',
                                    },
                                    {
                                      text: $vuetify.t(
                                        '$vuetify.enum.evysetrenie.typ.ZAZNAM_O_ZOBRAZOVACOM_VYSETRENI'
                                      ),
                                      value: 'ZAZNAM_O_ZOBRAZOVACOM_VYSETRENI',
                                    },
                                  ]"
                                  :error-messages="errors.collect('typ')"
                                  data-vv-name="typ"
                                  :data-vv-as="$vuetify.t('typ')"
                                  :disabled="!isEditable || !changeTypeEnabled"
                                ></v-select>
                              </v-flex>
                              <v-flex xs12 md6>
                                <codebook-autocomplete
                                  codebook-id="123"
                                  name="urgentnost_vysetrenia"
                                  v-model="
                                    model.vysetrenie.urgentnost_vysetrenia
                                  "
                                  :label="$vuetify.t('Urgentnosť')"
                                  :disabled="!isEditable"
                                  :limit="0"
                                  noEmptyValue
                                >
                                </codebook-autocomplete>
                              </v-flex>
                              <v-flex xs12>
                                <v-textarea
                                  auto-grow
                                  rows="2"
                                  v-model="model.vysetrenie.nazov_vysetrenia"
                                  :label="$vuetify.t('Názov vyšetrenia')"
                                  :disabled="!isEditable"
                                  clearable
                                  v-validate="'max:512'"
                                  data-vv-name="nazov_vysetrenia"
                                  :data-vv-as="$vuetify.t('Názov vyšetrenia')"
                                  :error-messages="
                                    errors.collect('nazov_vysetrenia')
                                  "
                                  :counter="512"
                                ></v-textarea>
                              </v-flex>
                              <v-flex xs12 md4>
                                <DatePicker
                                  v-model="
                                    model.vysetrenie.predpokladany_datum_porodu
                                  "
                                  :label="
                                    $vuetify.t('Predpokladaný dátum pôrodu')
                                  "
                                  name="predpokladany_datum_porodu"
                                  :disabled="
                                    !(isEditable && hasPredpokladanyDatumPorodu)
                                  "
                                />
                              </v-flex>
                              <v-flex xs12 md4>
                                <codebook-autocomplete
                                  codebook-id="256"
                                  name="modalita_zobrazovacieho_vysetrenia"
                                  v-model="
                                    model.vysetrenie
                                      .modalita_zobrazovacieho_vysetrenia
                                  "
                                  :label="
                                    $vuetify.t(
                                      'Modalita zobrazovacieho vyšetrenia'
                                    )
                                  "
                                  :disabled="
                                    !(isEditable && isZobrazovacieVysetrenie)
                                  "
                                >
                                </codebook-autocomplete>
                              </v-flex>
                              <v-flex xs12 md4>
                                <codebook-autocomplete
                                  codebook-id="115"
                                  name="vysetrovany_organ"
                                  v-model="model.vysetrenie.vysetrovany_organ"
                                  :label="$vuetify.t('Vyšetrovaný orgán')"
                                  :disabled="
                                    !(isEditable && isZobrazovacieVysetrenie)
                                  "
                                >
                                </codebook-autocomplete>
                              </v-flex>
                              <v-flex xs12>
                                <v-textarea
                                  auto-grow
                                  rows="2"
                                  v-model="model.vysetrenie.interna_poznamka"
                                  :label="$vuetify.t('Interná poznámka')"
                                  :disabled="!isEditable"
                                  clearable
                                  v-validate="'max:6000'"
                                  data-vv-name="interna_poznamka"
                                  :data-vv-as="$vuetify.t('Interná poznámka')"
                                  :error-messages="
                                    errors.collect('interna_poznamka')
                                  "
                                  :counter="6000"
                                ></v-textarea>
                              </v-flex>
                              <v-flex xs12>
                                <codebook-autocomplete
                                  :codebook-id="
                                    enums.CodebookIDZdravotnickyPracovnikKod
                                  "
                                  name="odosielajuci_lekar"
                                  v-model="model.vysetrenie.odosielajuci_lekar"
                                  :label="$vuetify.t('Odosielajúci lekár')"
                                  :disabled="!isEditable"
                                >
                                  <template
                                    slot="selection"
                                    slot-scope="{ item }"
                                  >
                                    <span>
                                      {{ item.code }} -
                                      {{ item.display_name }}
                                    </span>
                                  </template>
                                  <template slot="item" slot-scope="{ item }">
                                    <v-list-tile-content>
                                      <v-list-tile-title>
                                        {{ item.display_name }}
                                      </v-list-tile-title>
                                      <v-list-tile-sub-title>
                                        {{ item.code }}
                                      </v-list-tile-sub-title>
                                    </v-list-tile-content>
                                  </template>
                                </codebook-autocomplete>
                              </v-flex>
                              <v-flex xs12>
                                <codebook-autocomplete
                                  :codebook-id="enums.CodebookIDOUPZS"
                                  name="odosielajuci_lekar_oupzs"
                                  v-model="
                                    model.vysetrenie.odosielajuci_lekar_oupzs
                                  "
                                  :label="
                                    $vuetify.t('OÚ PZS odosielajúceho lekára')
                                  "
                                  :disabled="!isEditable"
                                >
                                  <template
                                    slot="selection"
                                    slot-scope="{ item }"
                                  >
                                    <span>
                                      {{ item.oupzs.kod_pzs }} -
                                      {{ item.display_name }}
                                    </span>
                                  </template>
                                  <template slot="item" slot-scope="{ item }">
                                    <v-list-tile-content>
                                      <v-list-tile-title>
                                        {{ item.display_name }}
                                      </v-list-tile-title>
                                      <v-list-tile-sub-title>
                                        {{ item.oupzs.kod_pzs }}
                                      </v-list-tile-sub-title>
                                    </v-list-tile-content>
                                  </template>
                                </codebook-autocomplete>
                              </v-flex>
                              <v-flex xs12 md6>
                                <TextField
                                  v-model="
                                    model.vysetrenie.poziadavka_na_vysetrenie
                                  "
                                  :label="
                                    $vuetify.t('Požiadavka na vyšetrenie')
                                  "
                                  name="poziadavka_na_vysetrenie"
                                  :disabled="!isEditable"
                                />
                              </v-flex>
                              <v-flex xs12 md6>
                                <DatePicker
                                  v-model="
                                    model.vysetrenie.datum_a_cas_odoslania
                                  "
                                  :label="$vuetify.t('Dátum a čas odoslania')"
                                  name="datum_a_cas_odoslania"
                                  :disabled="!isEditable"
                                />
                              </v-flex>
                            </v-layout>
                          </v-container>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-flex>
                    <v-flex xs12 v-if="diagnozy.length > 0">
                      <v-list dense class="elevation-1">
                        <template v-for="(item, index) in diagnozy">
                          <v-subheader
                            :key="'title' + index"
                            v-if="index === 0"
                          >
                            <span class="caption">{{
                              $vuetify.t("Diagnózy")
                            }}</span>
                          </v-subheader>
                          <v-list-tile
                            :key="'list' + index"
                            @click="editItem('diagnozaForm', item)"
                          >
                            <v-list-tile-content>
                              <v-container fluid class="px-2">
                                <v-layout row>
                                  <v-flex xs1>
                                    <v-tooltip top>
                                      <span slot="activator">{{
                                        item.diagnoza.diagnoza.skratka
                                      }}</span>
                                      <span>{{ $vuetify.t("Skratka") }}</span>
                                    </v-tooltip>
                                  </v-flex>
                                  <v-flex xs9>
                                    <v-tooltip top>
                                      <span slot="activator">{{
                                        item.diagnoza.diagnoza.display_name
                                      }}</span>
                                      <span>{{ $vuetify.t("Názov") }}</span>
                                    </v-tooltip>
                                  </v-flex>
                                  <v-flex xs1>
                                    <v-tooltip top>
                                      <span slot="activator">{{
                                        item.diagnoza.datum_ukoncenia
                                          | formatDatum
                                      }}</span>
                                      <span>{{
                                        $vuetify.t("Dátum ukončenia")
                                      }}</span>
                                    </v-tooltip>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-list-tile-content>
                            <v-list-tile-action
                              d-flex
                              style="
                                flex-direction: row;
                                justify-content: flex-end;
                              "
                            >
                              <template
                                v-for="(action, index) in [
                                  {
                                    text: isHlavnaDiagnoza(item)
                                      ? 'Hlavná diagnóza'
                                      : 'Označiť ako hlavnú diagnózu',
                                    icon: isHlavnaDiagnoza(item)
                                      ? 'favorite'
                                      : 'favorite_border',
                                    handler: markDiagnoza,
                                  },
                                  {
                                    text: isDiagnozaPriOdoslani(item)
                                      ? 'Diagnóza pri odoslaní'
                                      : 'Označiť ako diagnózu pri odoslaní',
                                    icon: isDiagnozaPriOdoslani(item)
                                      ? 'fas fa-arrow-alt-circle-right'
                                      : 'far fa-arrow-alt-circle-right',
                                    handler: markDiagnozaPriOdoslani,
                                  },
                                  {
                                    text:
                                      item.diagnoza.datum_ukoncenia !== null
                                        ? 'Ukončená diagnóza'
                                        : 'Ukončiť diagnózu',
                                    icon:
                                      item.diagnoza.datum_ukoncenia !== null
                                        ? 'update'
                                        : 'refresh',
                                    handler: endDiagnoza,
                                  },
                                  {
                                    text: 'Odstrániť diagnózu',
                                    icon: 'delete',
                                    handler: deleteDiagnoza,
                                  },
                                ]"
                              >
                                <v-tooltip left :key="'action' + index">
                                  <v-icon
                                    slot="activator"
                                    @click.stop="action.handler(item, index)"
                                    :disabled="!isEditable"
                                    >{{ action.icon }}</v-icon
                                  >
                                  <span>{{ $vuetify.t(action.text) }}</span>
                                </v-tooltip>
                              </template>
                            </v-list-tile-action>
                          </v-list-tile>
                          <v-divider
                            :key="'divider' + index"
                            v-if="index !== diagnozy.length - 1"
                          ></v-divider>
                        </template>
                      </v-list>
                    </v-flex>
                    <v-flex xs12 v-if="odporucania.length > 0">
                      <v-list dense class="elevation-1">
                        <template v-for="(item, index) in odporucania">
                          <v-subheader
                            :key="'title' + index"
                            v-if="index === 0"
                          >
                            <span class="caption">{{
                              $vuetify.t("Výmenné lístky")
                            }}</span>
                          </v-subheader>
                          <v-list-tile
                            :key="'list' + index"
                            @click="editItem('odporucanieForm', item)"
                          >
                            <v-list-tile-content>
                              <v-container fluid class="px-2">
                                <v-layout row>
                                  <v-flex xs1>
                                    <v-tooltip top>
                                      <span slot="activator">{{
                                        item.odporucanie.diagnoza.skratka
                                      }}</span>
                                      <span>{{ $vuetify.t("Diagnóza") }}</span>
                                    </v-tooltip>
                                  </v-flex>
                                  <v-flex xs4>
                                    <v-tooltip top>
                                      <span slot="activator">{{
                                        item.odporucanie
                                          .druh_specializovaneho_utvaru
                                          .display_name
                                      }}</span>
                                      <span>{{
                                        $vuetify.t("Druh špecializované útvaru")
                                      }}</span>
                                    </v-tooltip>
                                  </v-flex>
                                  <v-flex xs4>
                                    <v-tooltip top>
                                      <span slot="activator">{{
                                        item.odporucanie.odborne_zameranie
                                          .display_name
                                      }}</span>
                                      <span>{{
                                        $vuetify.t("Odborné zameranie")
                                      }}</span>
                                    </v-tooltip>
                                  </v-flex>
                                  <v-flex xs2>
                                    <v-tooltip top v-if="item.ehealth_record">
                                      <span
                                        slot="activator"
                                        @click.stop="copyOdporucanieRcID(item)"
                                      >
                                        {{ item.ehealth_record.rc_id }}
                                        <input
                                          type="hidden"
                                          :id="
                                            'rcID' + item.ehealth_record.rc_id
                                          "
                                          :value="item.ehealth_record.rc_id"
                                        />
                                      </span>
                                      <span>{{
                                        $vuetify.t("Identifikátor")
                                      }}</span>
                                    </v-tooltip>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-list-tile-content>
                            <v-list-tile-action
                              d-flex
                              style="
                                flex-direction: row;
                                justify-content: flex-end;
                              "
                            >
                              <template
                                v-for="(action, index) in [
                                  /*{
                              text: 'Kopírovať identifikátor do schránky',
                              icon: 'file_copy',
                              handler: copyOdporucanieExtID
                            },*/
                                  {
                                    text: 'Tlačiť výmenný lístok',
                                    icon: 'print',
                                    handler: printOdporucanie,
                                    disabled: false,
                                  },
                                  {
                                    text: 'Odstrániť výmenný lístok',
                                    icon: 'delete',
                                    handler: deleteThing,
                                    disabled: !isEditable,
                                  },
                                ]"
                              >
                                <v-tooltip left :key="'action' + index">
                                  <v-icon
                                    slot="activator"
                                    @click.stop="action.handler(item, index)"
                                    :disabled="action.disabled"
                                    >{{ action.icon }}</v-icon
                                  >
                                  <span>{{ $vuetify.t(action.text) }}</span>
                                </v-tooltip>
                              </template>
                            </v-list-tile-action>
                          </v-list-tile>
                          <v-divider
                            :key="'divider' + index"
                            v-if="index !== odporucania.length - 1"
                          ></v-divider>
                        </template>
                      </v-list>
                    </v-flex>
                    <v-flex xs12 v-if="recepty.length > 0">
                      <v-list dense class="elevation-1">
                        <template v-for="(item, index) in recepty">
                          <v-list-tile
                            :key="'list' + index"
                            @click="editItem('receptForm', item)"
                          >
                            <v-list-tile-content>
                              <v-container fluid class="px-2">
                                <v-layout row>
                                  <v-flex xs2>
                                    <v-list-tile-title
                                      v-if="index === 0"
                                      class="font-weight-medium"
                                      >{{
                                        $vuetify.t("Lieky")
                                      }}</v-list-tile-title
                                    >
                                  </v-flex>
                                  <v-flex xs2>
                                    <v-tooltip top>
                                      <span slot="activator">{{
                                        item.recept.diagnoza.skratka
                                      }}</span>
                                      <span>{{
                                        item.recept.diagnoza.display_name
                                      }}</span>
                                    </v-tooltip>
                                  </v-flex>
                                  <template
                                    v-if="item.recept.liek_typ === 'Liek'"
                                  >
                                    <v-flex xs1>
                                      <v-tooltip top>
                                        <span slot="activator">{{
                                          item.recept.liek.liek.kod_sukl
                                        }}</span>
                                        <span>{{
                                          $vuetify.t("Kód ŠÚKL")
                                        }}</span>
                                      </v-tooltip>
                                    </v-flex>
                                    <v-flex xs8>
                                      <v-tooltip top>
                                        <span slot="activator"
                                          >>{{ item.recept.liek.liek.nazov }}
                                          <span
                                            class="grey--text text--lighten-1 caption"
                                            :title="$vuetify.t('Doplnok názvu')"
                                            >{{
                                              item.recept.liek.liek
                                                .doplnok_nazvu
                                            }}</span
                                          >
                                        </span>
                                        <span>{{ $vuetify.t("Názov") }}</span>
                                      </v-tooltip>
                                    </v-flex>
                                  </template>
                                  <template
                                    v-else-if="item.recept.liek_typ === 'IPL'"
                                  >
                                    <v-flex xs9>
                                      <v-tooltip top>
                                        <span slot="activator">{{
                                          item.recept.zlozenie_ipl
                                        }}</span>
                                        <span>{{
                                          $vuetify.t("Zloženie IPL")
                                        }}</span>
                                      </v-tooltip>
                                    </v-flex>
                                  </template>
                                  <template v-else>
                                    <v-flex xs9>
                                      {{ item.recept.liek.display_name }}
                                    </v-flex>
                                  </template>
                                  <v-flex xs1>
                                    <v-tooltip top>
                                      <span slot="activator">{{
                                        item.recept.liek_typ
                                      }}</span>
                                      <span>{{ $vuetify.t("Typ") }}</span>
                                    </v-tooltip>
                                  </v-flex>
                                  <v-flex xs1>
                                    <v-tooltip top>
                                      <span slot="activator">
                                        <span
                                          v-if="
                                            item.recept.blo_ciarovy_kod !== ''
                                          "
                                          class="green--text"
                                          >BLO</span
                                        >
                                        <template v-else>
                                          <span
                                            v-if="item.recept.je_blo"
                                            class="red--text"
                                            >BLO</span
                                          >
                                        </template>
                                      </span>
                                      <span>
                                        <span
                                          v-if="item.recept.blo !== undefined"
                                          >{{ item.recept.blo.error_msg }}</span
                                        >
                                        <span v-else>{{
                                          $vuetify.t("Stav")
                                        }}</span>
                                      </span>
                                    </v-tooltip>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-list-tile-content>
                            <v-list-tile-action
                              d-flex
                              style="
                                flex-direction: row;
                                justify-content: flex-end;
                              "
                            >
                            </v-list-tile-action>
                          </v-list-tile>
                          <v-divider
                            :key="'divider' + index"
                            v-if="index !== recepty.length - 1"
                          ></v-divider>
                        </template>
                      </v-list>
                    </v-flex>
                    <!-- KRVNÝ TLAK-->
                    <v-flex xs12 v-if="krvnyTlak.length > 0">
                      <v-list dense class="elevation-1">
                        <template v-for="(item, index) in krvnyTlak">
                          <v-subheader
                            :key="'title' + index"
                            v-if="index === 0"
                          >
                            <span class="caption">{{
                              $vuetify.t("Krvný tlak")
                            }}</span>
                          </v-subheader>
                          <v-list-tile
                            :key="'list' + index"
                            @click="editItem('krvnyTlakForm', item)"
                          >
                            <v-list-tile-content>
                              <KrvnyTlakRow :krvnyTlak="item" />
                            </v-list-tile-content>
                            <v-list-tile-action
                              d-flex
                              style="
                                flex-direction: row;
                                justify-content: flex-end;
                              "
                            >
                              <template
                                v-for="(action, index) in [
                                  {
                                    text: 'Odstrániť meranie krvného tlaku',
                                    icon: 'delete',
                                    handler: deleteThing,
                                  },
                                ]"
                              >
                                <v-tooltip left :key="'action' + index">
                                  <v-icon
                                    slot="activator"
                                    @click.stop="action.handler(item, index)"
                                    >{{ action.icon }}</v-icon
                                  >
                                  <span>{{ $vuetify.t(action.text) }}</span>
                                </v-tooltip>
                              </template>
                            </v-list-tile-action>
                          </v-list-tile>
                          <v-divider
                            :key="'divider' + index"
                            v-if="index !== krvnyTlak.length - 1"
                          ></v-divider>
                        </template>
                      </v-list>
                    </v-flex>
                    <!-- VITALNE A ANTROPOMETRICKE HODNOTY  -->
                    <v-flex xs12 v-if="vitAntropHodnoty.length > 0">
                      <v-list dense class="elevation-1">
                        <template v-for="(item, index) in vitAntropHodnoty">
                          <v-subheader
                            :key="'title' + index"
                            v-if="index === 0"
                          >
                            <span class="caption">{{
                              $vuetify.t("Vitálne a antropometrické hodnoty")
                            }}</span>
                          </v-subheader>
                          <v-list-tile
                            :key="'list' + index"
                            @click="editItem('vitAntropHodnotyForm', item)"
                          >
                            <v-list-tile-content>
                              <VitAntropHodnotyRow :vitAntropHodnoty="item" />
                            </v-list-tile-content>
                            <v-list-tile-action
                              d-flex
                              style="
                                flex-direction: row;
                                justify-content: flex-end;
                              "
                            >
                              <template
                                v-for="(action, index) in [
                                  {
                                    text: 'Odstrániť meranie vitálnych a antropometrických hodnôt',
                                    icon: 'delete',
                                    handler: deleteThing,
                                  },
                                ]"
                              >
                                <v-tooltip left :key="'action' + index">
                                  <v-icon
                                    slot="activator"
                                    @click.stop="action.handler(item, index)"
                                    >{{ action.icon }}</v-icon
                                  >
                                  <span>{{ $vuetify.t(action.text) }}</span>
                                </v-tooltip>
                              </template>
                            </v-list-tile-action>
                          </v-list-tile>
                          <v-divider
                            :key="'divider' + index"
                            v-if="index !== vitAntropHodnoty.length - 1"
                          ></v-divider>
                        </template>
                      </v-list>
                    </v-flex>
                    <!-- KRVNÁ SKUPINA-->
                    <v-flex xs12 v-if="krvnaSkupina.length > 0">
                      <v-list dense class="elevation-1">
                        <template v-for="(item, index) in krvnaSkupina">
                          <v-subheader
                            :key="'title' + index"
                            v-if="index === 0"
                          >
                            <span class="caption">{{
                              $vuetify.t("Krvná skupina")
                            }}</span>
                          </v-subheader>
                          <v-list-tile
                            :key="'list' + index"
                            @click="editItem('krvnaSkupinaForm', item)"
                          >
                            <v-list-tile-content>
                              <KrvnaSkupinaRow :krvnaSkupina="item" />
                            </v-list-tile-content>
                            <v-list-tile-action
                              d-flex
                              style="
                                flex-direction: row;
                                justify-content: flex-end;
                              "
                            >
                              <template
                                v-for="(action, index) in [
                                  {
                                    text: 'Odstrániť vyšetrenie krvnej skupiny',
                                    icon: 'delete',
                                    handler: deleteThing,
                                  },
                                ]"
                              >
                                <v-tooltip left :key="'action' + index">
                                  <v-icon
                                    slot="activator"
                                    @click.stop="action.handler(item, index)"
                                    >{{ action.icon }}</v-icon
                                  >
                                  <span>{{ $vuetify.t(action.text) }}</span>
                                </v-tooltip>
                              </template>
                            </v-list-tile-action>
                          </v-list-tile>
                          <v-divider
                            :key="'divider' + index"
                            v-if="index !== krvnaSkupina.length - 1"
                          ></v-divider>
                        </template>
                      </v-list>
                    </v-flex>
                    <!-- RESTRIKCIA -->
                    <v-flex xs12 v-if="restrikcia !== null">
                      <v-list dense class="elevation-1">
                        <template>
                          <v-subheader>
                            <span class="caption">{{
                              $vuetify.t("Reštrikcia")
                            }}</span>
                          </v-subheader>
                          <v-list-tile
                            @click="editItem('restrikciaForm', restrikcia)"
                          >
                            <v-list-tile-content>
                              <RestrikciaRow :restrikcia="restrikcia" />
                            </v-list-tile-content>
                            <v-list-tile-action
                              d-flex
                              style="
                                flex-direction: row;
                                justify-content: flex-end;
                              "
                            >
                              <template
                                v-for="(action, index) in [
                                  {
                                    text: 'Odstrániť reštrikciu',
                                    icon: 'delete',
                                    handler: deleteThing,
                                  },
                                  {
                                    text:
                                      restrikcia.restrikcia.datum_ukoncenia !==
                                        null &&
                                      !jeVBuducnosti(
                                        restrikcia.restrikcia.datum_ukoncenia
                                      )
                                        ? 'Ukončená reštrikcia '
                                        : 'Ukončiť reštrikciu',
                                    icon:
                                      restrikcia.datum_ukoncenia !== null &&
                                      !jeVBuducnosti(
                                        restrikcia.restrikcia.datum_ukoncenia
                                      )
                                        ? 'update'
                                        : 'refresh',
                                    handler: endRestrikcia,
                                  },
                                ]"
                              >
                                <v-tooltip left :key="'action' + index">
                                  <v-icon
                                    slot="activator"
                                    @click.stop="action.handler(restrikcia)"
                                    >{{ action.icon }}</v-icon
                                  >
                                  <span>{{ $vuetify.t(action.text) }}</span>
                                </v-tooltip>
                              </template>
                            </v-list-tile-action>
                          </v-list-tile>
                        </template>
                      </v-list>
                    </v-flex>
                    <!-- NEŽIADUCA REAKCIA-->
                    <v-flex xs12 v-if="neziaduceReakcie.length > 0">
                      <v-list dense class="elevation-1">
                        <template v-for="(item, index) in neziaduceReakcie">
                          <v-subheader
                            :key="'title' + index"
                            v-if="index === 0"
                          >
                            <span class="caption">{{
                              $vuetify.t("Nežiadúce reakcie")
                            }}</span>
                          </v-subheader>
                          <v-list-tile
                            :key="'list' + index"
                            @click="editItem('alergiaForm', item)"
                          >
                            <v-list-tile-content>
                              <NeziaducaReakciaRow :reakcia="item" />
                            </v-list-tile-content>
                            <v-list-tile-action
                              d-flex
                              style="
                                flex-direction: row;
                                justify-content: flex-end;
                              "
                            >
                              <template
                                v-for="(action, index) in [
                                  {
                                    text: 'Odstrániť alergiu',
                                    icon: 'delete',
                                    handler: deleteThing,
                                    disabled: !isEditable,
                                  },
                                ]"
                              >
                                <v-tooltip left :key="'action' + index">
                                  <v-icon
                                    slot="activator"
                                    @click.stop="action.handler(item, index)"
                                    :disabled="action.disabled"
                                    >{{ action.icon }}</v-icon
                                  >
                                  <span>{{ $vuetify.t(action.text) }}</span>
                                </v-tooltip>
                              </template>
                            </v-list-tile-action>
                          </v-list-tile>
                          <v-divider
                            :key="'divider' + index"
                            v-if="index !== neziaduceReakcie.length - 1"
                          ></v-divider>
                        </template>
                      </v-list>
                    </v-flex>
                    <!-- OČKOVANIE-->
                    <v-flex xs12 v-if="ockovania.length > 0">
                      <v-list dense class="elevation-1">
                        <template v-for="(item, index) in ockovania">
                          <v-subheader
                            :key="'title' + index"
                            v-if="index === 0"
                          >
                            <span class="caption">{{
                              $vuetify.t("Očkovania")
                            }}</span>
                          </v-subheader>
                          <v-list-tile
                            :key="'list' + index"
                            @click="editItem('ockovanieForm', item)"
                          >
                            <v-list-tile-content>
                              <OckovanieRow :item="item" />
                            </v-list-tile-content>
                            <v-list-tile-action
                              d-flex
                              style="
                                flex-direction: row;
                                justify-content: flex-end;
                              "
                            >
                              <template
                                v-for="(action, index) in [
                                  {
                                    text: 'Odstrániť očkovanie',
                                    icon: 'delete',
                                    handler: deleteThing,
                                  },
                                ]"
                              >
                                <v-tooltip left :key="'action' + index">
                                  <v-icon
                                    slot="activator"
                                    @click.stop="action.handler(item, index)"
                                    >{{ action.icon }}</v-icon
                                  >
                                  <span>{{ $vuetify.t(action.text) }}</span>
                                </v-tooltip>
                              </template>
                            </v-list-tile-action>
                          </v-list-tile>
                          <v-divider
                            :key="'divider' + index"
                            v-if="index !== ockovania.length - 1"
                          ></v-divider>
                        </template>
                      </v-list>
                    </v-flex>
                    <!-- Chirurgický výkon-->
                    <v-flex xs12 v-if="chirurgickyVykon.length > 0">
                      <v-list dense class="elevation-1">
                        <template v-for="(item, index) in chirurgickyVykon">
                          <v-subheader
                            :key="'title' + index"
                            v-if="index === 0"
                          >
                            <span class="caption">{{
                              $vuetify.t("Chirurgické výkony")
                            }}</span>
                          </v-subheader>
                          <v-list-tile
                            :key="'list' + index"
                            @click="editItem('chirurgickyVykonForm', item)"
                          >
                            <v-list-tile-content>
                              <ChirurgickyVykonRow :chirurgickyVykon="item" />
                            </v-list-tile-content>
                            <v-list-tile-action
                              d-flex
                              style="
                                flex-direction: row;
                                justify-content: flex-end;
                              "
                            >
                              <template
                                v-for="(action, index) in [
                                  {
                                    text: 'Odstrániť chirurgický výkon',
                                    icon: 'delete',
                                    handler: deleteThing,
                                  },
                                ]"
                              >
                                <v-tooltip left :key="'action' + index">
                                  <v-icon
                                    slot="activator"
                                    @click.stop="action.handler(item, index)"
                                    >{{ action.icon }}</v-icon
                                  >
                                  <span>{{ $vuetify.t(action.text) }}</span>
                                </v-tooltip>
                              </template>
                            </v-list-tile-action>
                          </v-list-tile>
                          <v-divider
                            :key="'divider' + index"
                            v-if="index !== chirurgickyVykon.length - 1"
                          ></v-divider>
                        </template>
                      </v-list>
                    </v-flex>
                    <!-- Výkon-->
                    <v-flex xs12 v-if="vykony.length > 0">
                      <v-list dense class="elevation-1">
                        <template v-for="(item, index) in vykony">
                          <v-subheader
                            :key="'title' + index"
                            v-if="index === 0"
                          >
                            <span class="caption">{{
                              $vuetify.t("Zdravotné výkony")
                            }}</span>
                          </v-subheader>
                          <v-list-tile
                            :key="'list' + index"
                            @click="editItem('vykonForm', item)"
                          >
                            <v-list-tile-content>
                              <VykonRow :vykon="item" />
                            </v-list-tile-content>
                            <v-list-tile-action
                              d-flex
                              style="
                                flex-direction: row;
                                justify-content: flex-end;
                              "
                            >
                              <template
                                v-for="(action, index) in [
                                  {
                                    text: 'Odstrániť zdravotný výkon',
                                    icon: 'delete',
                                    handler: deleteThing,
                                  },
                                ]"
                              >
                                <v-tooltip left :key="'action' + index">
                                  <v-icon
                                    slot="activator"
                                    @click.stop="action.handler(item, index)"
                                    >{{ action.icon }}</v-icon
                                  >
                                  <span>{{ $vuetify.t(action.text) }}</span>
                                </v-tooltip>
                              </template>
                            </v-list-tile-action>
                          </v-list-tile>
                          <v-divider
                            :key="'divider' + index"
                            v-if="index !== vykony.length - 1"
                          ></v-divider>
                        </template>
                      </v-list>
                    </v-flex>
                    <!-- Ezdravie-->
                    <v-flex xs12 v-if="eZdravieVysetrenia.length > 0">
                      <v-list dense class="elevation-1">
                        <template v-for="(item, index) in eZdravieVysetrenia">
                          <v-list-tile
                            :key="'list' + index"
                            @click="showEZdravieVysetrenie(item)"
                          >
                            <v-list-tile-content>
                              <v-container fluid class="px-2">
                                <v-layout row>
                                  <v-flex xs1>
                                    <v-list-tile-title v-if="index === 0">{{
                                      $vuetify.t("evyšetrenie")
                                    }}</v-list-tile-title>
                                  </v-flex>
                                  <v-flex xs2>
                                    <v-tooltip top>
                                      <span slot="activator">
                                        {{
                                          $vuetify.t(
                                            "$vuetify.enum.evysetrenie.typ." +
                                              item.Typ
                                          )
                                        }}</span
                                      >
                                      <span>{{ $vuetify.t("Typ") }}</span>
                                    </v-tooltip>
                                  </v-flex>
                                  <v-flex xs7>
                                    <v-tooltip top>
                                      <span slot="activator">{{
                                        item.DatumACasVysetrenia
                                          | formatDatumACas
                                      }}</span>
                                      <span>{{
                                        $vuetify.t("Dátum a čas vyšetrenia")
                                      }}</span>
                                    </v-tooltip>
                                  </v-flex>
                                  <v-flex xs2>
                                    <v-tooltip
                                      top
                                      v-if="
                                        item.OdporucaneVysetrenie &&
                                        item.OdporucaneVysetrenie.length > 0 &&
                                        item.OdporucaneVysetrenie[0]
                                          .IdentifikatorVymennehoListku
                                          .Extension
                                      "
                                    >
                                      <span slot="activator">{{
                                        $vuetify.t("VL")
                                      }}</span>
                                      <span>{{
                                        $vuetify.t(
                                          "Vyšetrenie s odporúčaním, na základe ktorého bol pacient odoslaný"
                                        )
                                      }}</span>
                                    </v-tooltip>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-list-tile-content>
                          </v-list-tile>
                          <v-divider
                            :key="'divider' + index"
                            v-if="index !== eZdravieVysetrenia.length - 1"
                          ></v-divider>
                        </template>
                      </v-list>
                    </v-flex>
                    <!-- FORMULAR -->
                    <v-flex xs12 v-if="thingForms.length > 0">
                      <template v-for="(item, index) in thingForms">
                        <v-card
                          :key="'list' + index"
                          @click="editThingForm(item)"
                          class="clicker mb-1 elevation-1"
                        >
                          <v-card-text>
                            <vue-markdown>{{
                              item.formatted_text
                            }}</vue-markdown>
                          </v-card-text>
                        </v-card>
                      </template>
                    </v-flex>
                    <!-- MEDIKACIA-->
                    <v-flex xs12 v-if="medikacie.length > 0">
                      <ListMedikacie
                        :items="medikacie"
                        @edit="editItem('medikaciaForm', $event)"
                        @delete="deleteThing"
                        :disabled="!isEditable"
                      ></ListMedikacie>
                    </v-flex>
                    <!-- ZDRAVOTNICKE POMOCKY-->
                    <v-flex xs12 v-if="zdravotnickePomocky.length > 0">
                      <ListZdravotnickePomocky
                        :items="zdravotnickePomocky"
                        @edit="editItem('zdravotnickaPomockaForm', $event)"
                        @delete="deleteThing"
                        :disabled="!isEditable"
                      ></ListZdravotnickePomocky>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs12>
            <v-subheader class="caption">
              {{ $vuetify.t("Staršie záznamy") }}
            </v-subheader>
            <Dekurz ref="dekurzy" :patientID="id"></Dekurz>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>
    <!-- DIAGNOZA DIALOG -->
    <BaseDialog
      :dialogTitle="$vuetify.t(diagnozaDialogTitle)"
      ref="diagnozaDialog"
    >
      <form-diagnoza
        ref="diagnozaForm"
        :pacientId="id"
        :saveFunc="saveThingFunc"
      ></form-diagnoza>
      <FormPripojitKVysetreniu
        v-if="$refs.diagnozaForm && $refs.diagnozaForm.model.id === ''"
        v-model="pripojitKVysetreniu"
      >
      </FormPripojitKVysetreniu>
    </BaseDialog>
    <!-- VYMENNY LISTOK DIALOG -->
    <BaseDialog
      :dialogTitle="$vuetify.t('Výmenný lístok')"
      ref="odporucanieDialog"
      @save="$refs.odporucanieForm.save()"
    >
      <FormOdporucanie
        ref="odporucanieForm"
        :pacientId="id"
        :saveFunc="saveThingFunc"
      ></FormOdporucanie>
      <FormPripojitKVysetreniu
        v-if="$refs.odporucanieForm && $refs.odporucanieForm.model.id === ''"
        v-model="pripojitKVysetreniu"
      >
      </FormPripojitKVysetreniu>
    </BaseDialog>
    <!-- RECEPT DIALOG -->
    <base-dialog
      :dialogTitle="$vuetify.t('Recept')"
      ref="receptDialog"
      :closeOnSave="false"
      width="90%"
    >
      <form-recept
        ref="receptForm"
        :pacientId="id"
        :vysetrenieId="vysetrenieID"
        :saveFunc="saveThingFunc"
      ></form-recept>
      <FormPripojitKVysetreniu
        v-if="$refs.receptForm && $refs.receptForm.model.id === ''"
        v-model="pripojitKVysetreniu"
      >
      </FormPripojitKVysetreniu>
      <template v-slot:buttons>
        <buttons-form-recept
          @close="$refs.receptDialog.close()"
          @form-save="$refs.receptDialog.save()"
          :form="$refs.receptForm"
        ></buttons-form-recept>
      </template>
      <!--v-btn
        v-if="form.model.id !== '' && form.isOpakRecept"
        flat
        @click="form.zneplatnit"
        >{{ $vuetify.t("Zneplatniť op.recept") }}</!--v-btn
      >
      <v-btn v-if="form.model.id !== ''" flat @click="form.tlacit">
        {{ $vuetify.t("Tlačiť recept") }}
      </v-btn>
      <v-btn-- v-if="form.model.id !== ''" flat @click="form.stornoRecept">
        {{ $vuetify.t("Stornovať recept") }}
      </v-btn-->
    </base-dialog>
    <!-- VYKON DIALOG -->
    <BaseDialog :dialogTitle="$vuetify.t('Výkon')" ref="vykonDialog">
      <FormVykon
        ref="vykonForm"
        :pacientId="id"
        :saveFunc="saveThingFunc"
      ></FormVykon>
      <FormPripojitKVysetreniu
        v-if="$refs.vykonForm && $refs.vykonForm.model.id === ''"
        v-model="pripojitKVysetreniu"
      >
      </FormPripojitKVysetreniu>
    </BaseDialog>
    <!-- KRVNY TLAK DIALOG -->
    <BaseDialog
      :dialogTitle="$vuetify.t('Meranie Krvného Tlaku')"
      ref="krvnyTlakDialog"
    >
      <FormPacientKrvnyTlak
        ref="krvnyTlakForm"
        :pacientId="id"
        :saveFunc="saveThingFunc"
      />
      <FormPripojitKVysetreniu
        v-if="$refs.krvnyTlakForm && $refs.krvnyTlakForm.model.id === ''"
        v-model="pripojitKVysetreniu"
      >
      </FormPripojitKVysetreniu>
    </BaseDialog>
    <!-- VITALNE ANTROPOMETRICKE HODNOTY DIALOG -->
    <BaseDialog
      :dialogTitle="$vuetify.t('Meranie vitálnych a antropometrických hodnôt')"
      ref="vitAntropHodnotyDialog"
    >
      <FormVitAntropHodnoty
        ref="vitAntropHodnotyForm"
        :pacientId="id"
        :saveFunc="saveThingFunc"
      />
      <FormPripojitKVysetreniu
        v-if="
          $refs.vitAntropHodnotyForm &&
          $refs.vitAntropHodnotyForm.model.id === ''
        "
        v-model="pripojitKVysetreniu"
      >
      </FormPripojitKVysetreniu>
    </BaseDialog>
    <!-- KRVNA SKUPINA DIALOG -->
    <BaseDialog
      :dialogTitle="$vuetify.t('Vyšetrenie krvnej skupiny')"
      ref="krvnaSkupinaDialog"
    >
      <FormKrvnaSkupina
        ref="krvnaSkupinaForm"
        :pacientId="id"
        :saveFunc="saveThingFunc"
      />
      <FormPripojitKVysetreniu
        v-if="$refs.krvnaSkupinaForm && $refs.krvnaSkupinaForm.model.id === ''"
        v-model="pripojitKVysetreniu"
      >
      </FormPripojitKVysetreniu>
    </BaseDialog>
    <!-- ALERGIA DIALOG -->
    <BaseDialog :dialogTitle="$vuetify.t('Alergia')" ref="alergiaDialog">
      <FormNeziaducaReakcia
        ref="alergiaForm"
        :pacientId="id"
        :saveFunc="saveThingFunc"
      />
      <FormPripojitKVysetreniu
        v-if="$refs.alergiaForm && $refs.alergiaForm.model.id === ''"
        v-model="pripojitKVysetreniu"
      >
      </FormPripojitKVysetreniu>
    </BaseDialog>
    <!-- OCKOVANIE DIALOG -->
    <BaseDialog :dialogTitle="$vuetify.t('Očkovanie')" ref="ockovanieDialog">
      <form-ockovanie
        ref="ockovanieForm"
        :pacientId="id"
        :saveFunc="saveThingFunc"
      />
      <FormPripojitKVysetreniu
        v-if="$refs.ockovanieForm && $refs.ockovanieForm.model.id === ''"
        v-model="pripojitKVysetreniu"
      >
      </FormPripojitKVysetreniu>
    </BaseDialog>
    <!-- MEDIKACIA DIALOG -->

    <BaseDialog :dialogTitle="$vuetify.t('Medikácia')" ref="medikaciaDialog">
      <FormMedikacia
        ref="medikaciaForm"
        :pacientId="id"
        :saveFunc="saveThingFunc"
      />
    </BaseDialog>
    <!-- ZDRAVOTNICKA POMOCKA DIALOG -->
    <BaseDialog
      :dialogTitle="$vuetify.t('Zdravotnícka pomôcka')"
      ref="zdravotnickaPomockaDialog"
    >
      <FormZdravotnickaPomocka
        ref="zdravotnickaPomockaForm"
        :pacientId="id"
        :saveFunc="saveThingFunc"
      />
    </BaseDialog>
    <!-- CHIRURGICKY VYKON DIALOG -->
    <BaseDialog
      :dialogTitle="$vuetify.t('Chirurgický výkon')"
      ref="chirurgickyVykonDialog"
    >
      <FormChirurgickyVykon
        ref="chirurgickyVykonForm"
        :pacientId="id"
        :saveFunc="saveThingFunc"
      />
      <FormPripojitKVysetreniu
        v-if="
          $refs.chirurgickyVykonForm &&
          $refs.chirurgickyVykonForm.model.id === ''
        "
        v-model="pripojitKVysetreniu"
      >
      </FormPripojitKVysetreniu>
    </BaseDialog>
    <!-- RESTRIKCIA DIALOG -->
    <BaseDialog
      :dialogTitle="$vuetify.t('Reštrikcia záznamu z vyšetrenia')"
      ref="restrikciaDialog"
    >
      <FormRestrikcia
        ref="restrikciaForm"
        :pacientId="id"
        :saveFunc="saveThingFunc"
      />
    </BaseDialog>
    <!-- EZDRAVIE ODPORUCANIE VYHLADANIE DIALOG -->
    <BaseDialog
      :dialogTitle="$vuetify.t('ezdravie: vyhľadanie odporúčania')"
      ref="ezdravieVyhladajVysetrenieDialog"
      @save="$refs.ezdravieVyhladajVysetrenieForm.search()"
      :saveText="$vuetify.t('Vyhľadať')"
      width="75%"
    >
      <FormEzdravieVyhladajVysetrenie
        ref="ezdravieVyhladajVysetrenieForm"
        @saved="savedEzdravieVysetrenieSOdporucanim"
        @vytvorenieVysledku="vytvorVysledok"
      ></FormEzdravieVyhladajVysetrenie>
    </BaseDialog>
    <!-- EZDRAVIE VYSETRENIE VYHLADANIE DIALOG -->
    <BaseDialog
      :dialogTitle="$vuetify.t('ezdravie: vyhľadanie vyšetrení')"
      ref="ezdravieVyhladajVysetreniaDialog"
      @save="$refs.ezdravieVyhladajVysetreniaForm.save()"
      :showSaveButton="false"
      width="75%"
    >
      <FormEzdravieVyhladajVysetrenia
        :pacientId="id"
        ref="ezdravieVyhladajVysetreniaForm"
        @saved="
          savedThing($refs.ezdravieVyhladajVysetreniaDialog, $event.ID, '')
        "
      ></FormEzdravieVyhladajVysetrenia>
      <template v-slot:buttons>
        <v-btn
          flat
          @click.native="$refs.ezdravieVyhladajVysetreniaDialog.close()"
          >{{ $vuetify.t("$vuetify.app.button.close") }}</v-btn
        >
        <v-btn
          flat
          @click="$refs.ezdravieVyhladajVysetreniaForm.zapisSuhlas(false)"
          :loading="$store.state.loading.save"
          >{{ $vuetify.t("Zapísať súhlas s výzvou") }}</v-btn
        >
        <v-btn
          flat
          @click="$refs.ezdravieVyhladajVysetreniaForm.zapisSuhlas(true)"
          :loading="$store.state.loading.save"
          >{{ $vuetify.t("Zapísať súhlas s PIN") }}</v-btn
        >
        <v-btn
          flat
          color="primary"
          @click="$refs.ezdravieVyhladajVysetreniaForm.vyhladajVysetrenia()"
          :loading="$store.state.loading.save"
          >{{ $vuetify.t("Vyhľadať") }}</v-btn
        >
      </template>
    </BaseDialog>
    <!-- EZDRAVIE VYSETRENIE DIALOG -->
    <BaseDialog
      :dialogTitle="$vuetify.t('ezdravie: vyšetrenie')"
      width="75%"
      :showSaveButton="false"
      ref="ezdravieVysetrenieDialog"
    >
      <EZdravieVysetrenie
        ref="ezdravieVysetrenieForm"
        :vysetrenie="ezdravieVysetrenie"
      ></EZdravieVysetrenie>
    </BaseDialog>
    <!-- AXONFORM DIALOG -->
    <BaseDialog
      :dialogTitle="$vuetify.t('Formulár')"
      width="80%"
      ref="axonFormDialog"
      @save="$refs.axonFormForm.save()"
    >
      <FormThingForm
        ref="axonFormForm"
        :pacientId="id"
        :saveFunc="saveThingFunc"
      />
    </BaseDialog>
    <!-- FORMULAR VYBER DIALOG -->
    <BaseDialog
      :dialogTitle="$vuetify.t('Formulár - výber')"
      width="80%"
      ref="axonFormTreeDialog"
      @save="$refs.axonFormTree.save()"
    >
      <FormAxonFormTree
        ref="axonFormTree"
        :forms="formsTree"
        :show-author="false"
        :show-stars="false"
        @choose="treeChoose"
      >
        <template slot="otherButtons" slot-scope="{ active }">
          <v-btn @click="$refs.axonFormTreeDialog.close()">
            {{ $vuetify.t("$vuetify.app.button.close") }}
          </v-btn>
          <v-btn @click="treeChoose(active)" :disabled="active.length === 0">
            {{ $vuetify.t("Vybrať") }}
          </v-btn>
        </template>
      </FormAxonFormTree>
      <template v-slot:buttons>
        <v-btn style="display: none">
          {{ $vuetify.t("Dummy") }}
        </v-btn>
      </template>
    </BaseDialog>
    <!-- KALENDAR EVENT DIALOG -->
    <!-- <BaseDialog -->
    <!--   :dialogTitle="$vuetify.t('Kalendár')" -->
    <!--   ref="dialogPacientEvent" -->
    <!--   width="100%" -->
    <!--   height="90vh" -->
    <!--   :showSaveButton="false" -->
    <!--   @close="readPacient" -->
    <!-- > -->
    <!--   <v-layout column> -->
    <!--     <v-layout row align-center> -->
    <!--       <v-flex grow> -->
    <!--         <span class="title">{{ -->
    <!--           $refs.calendar ? $refs.calendar.currentDateLabel : "" -->
    <!--         }}</span> -->
    <!--       </v-flex> -->
    <!--       <v-flex shrink> -->
    <!--         <calendar-navigation -->
    <!--           v-model="calendarType" -->
    <!--           @prev="$refs.calendar.prev()" -->
    <!--           @next="$refs.calendar.next()" -->
    <!--         /> -->
    <!--       </v-flex> -->
    <!--     </v-layout> -->
    <!--     <v-layout row fill-height> -->
    <!--       <calendar -->
    <!--         ref="calendar" -->
    <!--         :type="calendarType" -->
    <!--         :pacientId="id" -->
    <!--         :pacient-name="`${pacient.FirstName} ${pacient.Surname}`" -->
    <!--       /> -->
    <!--     </v-layout> -->
    <!--   </v-layout> -->
    <!-- </BaseDialog> -->
  </div>
</template>

<script>
import moment from "moment"
import BaseDialog from "./../../components/BaseDialog.vue"
import BasePopup from "./../../components/BasePopup.vue"
import FormThingForm from "./../../components/forms/FormThingForm.vue"
import FormAxonFormTree from "./../../components/FormTree.vue"
import FormDiagnoza from "./../../components/forms/FormDiagnoza.vue"
import FormOdporucanie from "./../../components/forms/FormOdporucanie.vue"
import FormRecept from "./../../components/forms/FormRecept.vue"
import FormVykon from "./../../components/forms/FormPacientVykon.vue"
import FormEzdravieVyhladajVysetrenie from "./../../components/forms/FormEzdravieVyhladajVysetrenie.vue"
import FormEzdravieVyhladajVysetrenia from "./../../components/forms/FormEzdravieVyhladajVysetrenia.vue"
import FormNeziaducaReakcia from "./../../components/forms/FormNeziaducaReakcia.vue"
import FormOckovanie from "./../../components/forms/FormOckovanie.vue"

import FormMedikacia from "./../../components/forms/FormMedikacia.vue"
import FormZdravotnickaPomocka from "./../../components/forms/FormZdravotnickaPomocka.vue"
import FormPacientKrvnyTlak from "./../../components/forms/FormPacientKrvnyTlak.vue"
import FormVitAntropHodnoty from "./../../components/forms/FormVitAntropHodnoty.vue"
import FormKrvnaSkupina from "./../../components/forms/FormKrvnaSkupina.vue"
import FormChirurgickyVykon from "./../../components/forms/FormChirurgickyVykon.vue"
import FormKontrola from "./../../components/forms/FormKontrola.vue"
import DatePicker from "./../../components/DatePicker.vue"
import TextField from "./../../components/TextField.vue"
import TextEditor from "./../../components/TextEditorInline.vue"
import Dekurz from "./Dekurz.vue"
import * as Miscella from "./../../miscella.js"
import * as enums from "@/plugins/enums.js"
import * as diagnozaMixin from "./../../components/mixins/diagnoza.js"
import * as subory from "./../../components/mixins/subory.js"
import EVysetrenie from "./../../ehealth/evysetrenie.js"
import EZdravieVysetrenie from "./../../components/ezdravie/EZdravieVysetrenie.vue"
import { mapState, mapGetters } from "vuex"
import VueMarkdown from "vue-markdown-v2"
import * as mixPatient from "./../../components/mixins/patient.js"
import ButtonsFormRecept from "./../../components/forms/ButtonsFormRecept.vue"
import Calendar from "./../../components/Calendar.vue"
import CalendarNavigation from "./../../components/CalendarNavigation.vue"
import thingMixin from "@/components/mixins/thing.js"
import CodebookAutocomplete from "@/components/ciselnikComponents/CodebookAutocomplete.vue"
import FormPripojitKVysetreniu from "@/components/forms/FormPripojitKVysetreniu.vue"
import ListMedikacie from "./vysetrenie/ListMedikacie.vue"
import ListZdravotnickePomocky from "./vysetrenie/ListZdravotnickePomocky.vue"
import NeziaducaReakciaRow from "@/components/rows/NeziaducaReakciaRow.vue"
import OckovanieRow from "@/components/rows/OckovanieRow.vue"
import KrvnyTlakRow from "@/components/rows/KrvnyTlakRow.vue"
import VitAntropHodnotyRow from "@/components/rows/VitAntropHodnotyRow.vue"
import KrvnaSkupinaRow from "@/components/rows/KrvnaSkupinaRow.vue"
import ChirurgickyVykonRow from "@/components/rows/ChirurgickyVykonRow.vue"
import VykonRow from "@/components/rows/VykonRow.vue"
import EhealthRecord from "@/components/EhealthRecord.vue"
import FormRestrikcia from "./../../components/forms/FormRestrikcia.vue"
import RestrikciaRow from "@/components/rows/RestrikciaRow.vue"

export default {
  $_veeValidate: {
    validator: "new",
  },
  name: "vysetrenie",
  mixins: [diagnozaMixin.mixins, mixPatient.mixins, thingMixin, subory.mixins],
  components: {
    BaseDialog,
    //BasePopup,
    ButtonsFormRecept,
    FormThingForm,
    FormAxonFormTree,
    FormDiagnoza,
    FormOdporucanie,
    FormRecept,
    FormVykon,
    FormNeziaducaReakcia,
    FormOckovanie,
    FormMedikacia,
    FormZdravotnickaPomocka,
    FormEzdravieVyhladajVysetrenie,
    FormEzdravieVyhladajVysetrenia,
    FormPacientKrvnyTlak,
    FormVitAntropHodnoty,
    FormKrvnaSkupina,
    FormChirurgickyVykon,
    FormRestrikcia,
    //FormKontrola,
    DatePicker,
    TextField,
    TextEditor,
    Dekurz,
    EZdravieVysetrenie,
    VueMarkdown,
    //Calendar,
    //CalendarNavigation,
    CodebookAutocomplete,
    FormPripojitKVysetreniu,
    ListMedikacie,
    ListZdravotnickePomocky,
    NeziaducaReakciaRow,
    OckovanieRow,
    KrvnyTlakRow,
    VitAntropHodnotyRow,
    KrvnaSkupinaRow,
    ChirurgickyVykonRow,
    VykonRow,
    RestrikciaRow,
    EhealthRecord,
  },
  props: ["id", "vysetrenieID"],
  data: function () {
    return {
      model: null,
      doplnkovePoliaPanel: null,
      vysetrenia: [],
      pocetNovsichVysetreni: 0,
      recepty: [],
      diagnozy: [],
      vykony: [],
      neziaduceReakcie: [],
      ockovania: [],

      krvnyTlak: [],
      vitAntropHodnoty: [],
      krvnaSkupina: [],
      chirurgickyVykon: [],
      odporucania: [],
      ezdravieVysetrenie: {},
      eZdravieVysetrenia: [],
      formsTree: [],
      formDefinitions: [],
      thingForms: [],
      medikacie: [],
      zdravotnickePomocky: [],
      enums: enums,
      calendarType: "week",
      pripojitKVysetreniu: true,
      restrikcia: null,
    }
  },
  computed: {
    maRestrikciu: function () {
      return Miscella.isSet(this.restrikcia)
    },
    maAmbulanciaPravoZadatRestrikciu: function () {
      //TODO ambulancia ma odbornost z nespravneho ciselnika, mal by byt ciselnik 39 nie 34
      //zatial sa kontroluje podla skratky, ktora je v oboch rovnaka
      const opravneneZameraniaKody = [
        "00000351353",
        "00000351874",
        "00000351932",
        "00000352849",
        "00000352252",
        "00000353581",
        "00000353417",
        "00000353789",
        "00000352989",
        "00000354860",
        "00000354340",
        "00000351924",
        "00000352021",
      ]
      const opravneneZameraniaSkratky = [
        "005",
        "067",
        "074",
        "223",
        "144",
        "340",
        "302",
        "367",
        "241",
        "709",
        "578",
        "073",
        "105",
      ]

      if (!Miscella.isSet(this.ambulance)) {
        return false
      } else {
        return opravneneZameraniaSkratky.includes(
          this.ambulance.zameranie.skratka
        )
      }
    },
    vek: function () {
      if (this.$store.state.pacient === null) {
        return null
      }
      let birthdate = this.$store.state.pacient.Birthdate
      let years = moment().diff(moment(birthdate), "years")
      return years
    },
    pohlavie: function () {
      if (this.$store.state.pacient === null) {
        return null
      }
      // u nas v modeli je 1 - Muz 2 - Zena
      let sex = this.$store.state.pacient.Sex
      if (sex === 1 || sex === 2) {
        if (sex === 2) {
          sex = 0
        }
        return sex
        // pre vypocet je 1 - Muz 0 - Zena
      } else {
        return null
      }
    },
    isNew: function () {
      return this.vysetrenieID === "new"
    },
    datumDoPreDekurzy: function () {
      return moment(this.model.date).subtract(1, "s").toDate()
    },
    hlavnaDiagnoza: function () {
      if (!Miscella.isEmpty(this.model.vysetrenie.hlavna_diagnoza_id)) {
        return this.diagnozy.find((d) => this.isHlavnaDiagnoza(d))
      }
      return null
    },
    isEhealthRecord: function () {
      return Miscella.isSet(this.model.ehealth_record)
    },
    isEditable: function () {
      return (
        !this.isEhealthRecord ||
        (this.model.ehealth_record.version_status !==
          enums.EhrVersionStatus.Deleted &&
          (this.model.ehealth_record.status === enums.EhealthRecordStatus.NA ||
            this.model.ehealth_record.status ===
              enums.EhealthRecordStatus.Error))
      )
    },
    isEhrSaved: function () {
      return (
        this.isEhealthRecord &&
        this.model.ehealth_record.status === enums.EhealthRecordStatus.Success
      )
    },
    isEhrError: function () {
      return (
        this.isEhealthRecord &&
        this.model.ehealth_record.status === enums.EhealthRecordStatus.Error
      )
    },
    isEhrVersionFinal: function () {
      return (
        this.isEhealthRecord &&
        this.model.ehealth_record.version_status ===
          enums.EhrVersionStatus.Final
      )
    },
    isEhrVersionDeleted: function () {
      return (
        this.isEhealthRecord &&
        this.model.ehealth_record.version_status ===
          enums.EhrVersionStatus.Deleted
      )
    },
    // ci sa moze ulozit do ezdravia
    canSaveToEhealth: function () {
      return (
        this.isEhealthSetUp &&
        !this.isNew &&
        (this.isEditable || (this.isEhrVersionFinal && this.isEhrError))
      )
    },
    // ci sa moze stornovat v ezdravi
    canDeleteInEhealth: function () {
      return (
        this.isEhealthSetUp &&
        ((this.isEhrVersionFinal && this.isEhrSaved) ||
          (this.isEhrVersionDeleted && this.isEhrError))
      )
    },
    // ci sa moze ulozit alebo stornovat v ezdravi
    canSendToEhealth: function () {
      return this.canSaveToEhealth || this.canDeleteInEhealth
    },
    canSearchInEhealthByID: function () {
      return (
        this.isEhealthReady && !this.isEhealthRecord
        //(!this.isEhealthRecord ||
        //Miscella.isEmpty(this.model.EVysetrenie.PoziadavkaNaVysetrenie))
      )
    },
    canSearchInEhealth: function () {
      return this.isEhealthReady
    },
    hasPredpokladanyDatumPorodu: function () {
      if (Miscella.isSet(this.ambulance)) {
        return this.ambulance.zameranie.skratka === "009"
      }
      return false
    },
    isZobrazovacieVysetrenie: function () {
      return this.model.vysetrenie.typ === "ZAZNAM_O_ZOBRAZOVACOM_VYSETRENI"
    },
    changeTypeEnabled: function () {
      if (Miscella.isSet(this.model.vysetrenie.things)) {
        for (let thing of this.model.vysetrenie.things) {
          if (!Miscella.isSet(thing.diagnoza)) {
            return false
          }
        }
      }
      return true
    },
    ...mapState({
      pacient: (state) => state.pacient,
      ambulance: (state) => state.me?.ambulance,
      ambulanceID: (state) => state.me?.ambulance?.id,
      miniDrawer: (state) => state.drawer.vysetrenie,
      userID: (state) => state.me?.user?.id,
    }),
    ...mapGetters(["isEhealthSetUp", "isEhealthReady"]),
  },
  methods: {
    toggleDrawer: function () {
      this.$store.commit("toggleDrawer", "vysetrenie")
    },
    init: function () {
      this.model = {
        id: "",
        date: moment().format(),
        vysetrenie: {
          typ: "ZAZNAM_O_ODBORNOM_VYSETRENI",
          urgentnost_vysetrenia: null,
          popis: "",
          hlavna_diagnoza_id: "",
          nazov_vysetrenia: "",
          interna_poznamka: "",
          predpokladany_datum_porodu: null,
          modalita_zobrazovacieho_vysetrenia: null,
          vysetrovany_organ: null,
          odosielajuci_lekar: null,
          odosielajuci_lekar_oupzs: null,
          diagnoza_pri_odoslani: [],
          poziadavka_na_vysetrenie: "",
          datum_a_cas_odoslania: null,
        },
      }
      this.doplnkovePoliaPanel = null

      this.diagnozy = []
      this.recepty = []
      this.odporucania = []
      this.vykony = []
      this.neziaduceReakcie = []
      this.ockovania = []

      this.krvnyTlak = []
      this.vitAntropHodnoty = []
      this.krvnaSkupina = []
      this.chirurgickyVykon = []
      this.ezdravieVysetrenie = null
      this.eZdravieVysetrenia = []
      this.thingForms = []
      this.medikacie = []
      this.zdravotnickePomocky = []
      this.restrikcia = null
      this.$validator.reset()
    },
    isHlavnaDiagnoza: function (dg) {
      return dg.id === this.model.vysetrenie.hlavna_diagnoza_id
    },
    isDiagnozaPriOdoslani: function (dg) {
      return this.model.vysetrenie.diagnoza_pri_odoslani.find(
        (d) => d.id === dg.id
      )
    },

    fetchData: function () {
      this.init()
      if (
        Miscella.isEmpty(this.vysetrenieID) ||
        moment(this.vysetrenieID, moment.ISO_8601).isValid()
      ) {
        var date = Miscella.isEmpty(this.vysetrenieID)
          ? moment()
          : moment(this.vysetrenieID, moment.ISO_8601)
        return this.$api.thingService
          .listThings({
            patient_id: this.id,
            date_from: date.startOf("day").format(),
            date_to: date.endOf("day").format(),
            table_request: {
              sort_by: "Date",
              sort_desc: true,
              limit: 1,
            },
            vysetrenie: {},
          })
          .then((data) => {
            this.reload(
              Miscella.isSet(data.items) &&
                data.items.length > 0 &&
                Miscella.isSet(data.items[0].id)
                ? data.items[0].id
                : "new"
            )
          })
      }
      var vysetreniePromise = this.isNew
        ? Promise.resolve()
        : this.$api.thingService
            .getThing({ thing_id: this.vysetrenieID })
            .then(this.setVysetrenie)
      var zoznamVysetreniPromise = this.$api.thingService
        .listThings({
          patient_id: this.id,
          vysetrenie: {},
        })
        .then((data) => {
          this.vysetrenia = data.items
        })
      return Promise.all([vysetreniePromise, zoznamVysetreniPromise]).then(
        () => {
          this.pocetNovsichVysetreni = 0
          for (let v of this.vysetrenia) {
            if (moment(v.date).utc().isAfter(this.model.date)) {
              this.pocetNovsichVysetreni++
            }
          }
          this.$nextTick(() => {
            this.$refs.dekurzy.init(this.datumDoPreDekurzy)
            this.$refs.popisEditor.focus()
          })
        }
      )
    },
    setVysetrenie: function (data) {
      //if (data === null) {
      this.init()
      //} else {
      this.model = data.thing

      if (Miscella.isSet(this.model.vysetrenie.things)) {
        for (let thing of this.model.vysetrenie.things) {
          if (Miscella.isSet(thing.diagnoza)) {
            if (this.isHlavnaDiagnoza(thing)) {
              this.diagnozy.splice(0, 0, thing) // daj na prve miesto
            } else {
              this.diagnozy.push(thing)
            }
          }
          if (Miscella.isSet(thing.neziaduca_reakcia)) {
            this.neziaduceReakcie.push(thing)
          }
          if (Miscella.isSet(thing.odporucanie)) {
            this.odporucania.push(thing)
          }
          if (Miscella.isSet(thing.recept)) {
            this.recepty.push(thing)
          }
          if (Miscella.isSet(thing.medikacia)) {
            this.medikacie.push(thing)
          }
          if (Miscella.isSet(thing.zdravotnicka_pomocka)) {
            this.zdravotnickePomocky.push(thing)
          }
          if (Miscella.isSet(thing.ockovanie)) {
            this.ockovania.push(thing)
          }
          if (Miscella.isSet(thing.krvny_tlak)) {
            this.krvnyTlak.push(thing)
          }
          if (Miscella.isSet(thing.vit_antrop_hodnoty)) {
            this.vitAntropHodnoty.push(thing)
          }
          if (Miscella.isSet(thing.krvna_skupina)) {
            this.krvnaSkupina.push(thing)
          }
          if (Miscella.isSet(thing.chirurgicky_vykon)) {
            this.chirurgickyVykon.push(thing)
          }
          if (Miscella.isSet(thing.restrikcia)) {
            this.restrikcia = thing
          }
          if (Miscella.isSet(thing.vykon)) {
            this.vykony.push(thing)
          }
          if (Miscella.isSet(thing.form_data)) {
            this.thingForms.push(thing)
          }
        }
      }
      //}
      //this.doplnkovePoliaPanel = !this.isEVysetrenieEmpty ? 0 : null
    },
    addVysetrenie: function () {
      this.$router.push({
        name: "vysetrenie",
        params: { id: this.id, vysetrenieID: "new" },
      })
    },
    saveVysetrenie: function () {
      // TODO spravit ako params?
      this.model.patient_id = this.id
      this.model.ambulance_id = this.ambulanceID
      return this.$api.thingService.saveThing({
        thing: this.model,
      })
    },
    saveForm: async function (aQuietly) {
      var valid = await this.$validator.validateAll()
      if (!valid) {
        return
      }
      try {
        var data = await this.saveVysetrenie()

        if (!aQuietly) {
          this.notifySuccess("Vyšetrenie bolo úspešne uložené")
        }
        this.readPacient()
        this.reload(data.thing.id)
      } catch (err) {
        this.processError(err, "Pri ukladaní vyšetrenia vznikla chyba")
      }
    },
    changePopis: function (value) {
      if (this.model.vysetrenie.popis !== value) {
        this.model.vysetrenie.popis = value
        this.saveForm()
      }
    },

    deleteVysetrenie: async function () {
      var result = await this._deleteThing(this.model) // from thing mixin
      if (result) {
        this.$router.replace({ name: "vysetrenie", params: { id: this.id } })
      }
    },
    deleteDiagnoza: async function (dg) {
      var result = await this._deleteThing(dg) // from thing mixin
      if (result) {
        if (this.isHlavnaDiagnoza(dg)) {
          this.model.vysetrenie.hlavna_diagnoza_id =
            this.diagnozy.length > 1 ? this.diagnozy[1].id : ""
          return this.saveForm(true)
        } else {
          this.fetchData()
        }
      }
    },
    deleteThing: async function (thing) {
      var result = await this._deleteThing(thing) // from thing mixin
      if (result) {
        this.fetchData()
      }
    },
    endRestrikcia: function (r) {
      if (moment(r.restrikcia.datum_ukoncenia).isBefore(moment())) {
        return Promise.resolve()
      } else {
        let datumVytvorenia = moment(r.date)
        let datumUkoncenia = moment()
        if (datumUkoncenia.isBefore(datumVytvorenia, "day")) {
          this.$store.commit(
            "setError",
            this.$vuetify.t(
              "Dátum ukončenia nemôže predchádzať dátumu vytvorenia."
            )
          )
          return Promise.resolve()
        }
      }
      return this.$confirm(
        this.$vuetify.t("Naozaj si želáte ukončiť reštrikciu?")
      ).then((res) => {
        if (res) {
          var params = { thing: r }
          params.thing.restrikcia.datum_ukoncenia = moment().format()
          this.$api.thingService
            .saveThing(params)
            .then((result) => {
              this.$store.commit(
                "setSuccess",
                this.$vuetify.t("Reštrikcia bola úspešne ukončená")
              )
              this.fetchData()
            })
            .catch((err) => {
              this.$store.commit(
                "setError",
                this.$vuetify.t("Pri ukončovaní reštrikcie vznikla chyba ") +
                  " (" +
                  err +
                  ")."
              )
            })
        }
      })
    },

    jeVBuducnosti: function (datum) {
      return !moment(datum).isBefore(moment())
    },

    /*deleteVysetrenie: async function () {
      var result = await this.deleteThing(thing) // from mixin
      if (result) {
        this.dekurzy.splice(this.dekurzy.indexOf(thing), 1)
        this.$emit("changed")
      }
      var res = await this.$confirm(
        this.$vuetify.t(
          "Naozaj si želáte odstrániť vyšetrenie so všetkými dátami?"
        )
      )
      if (res) {
        try {
          await this.$api.delete(this.deleteVysetrenieUrl)
          this.$store.commit(
            "setSuccess",
            this.$vuetify.t("Vyšetrenie bolo úspešne odstránené")
          )
          this.$store.commit("setReloadPatient", true)
          this.$router.replace({ name: "vysetrenie", params: { id: this.id } })
        } catch (err) {
          this.$store.commit(
            "setError",
            this.$vuetify.t("Pri odstraňovaní vyšetrenia vznikla chyba ") +
              " (" +
              err +
              ")."
          )
        }
      }
    },*/
    markDiagnoza: function (dg, index) {
      if (this.isHlavnaDiagnoza(dg)) {
        return
      }
      this.model.vysetrenie.hlavna_diagnoza_id = dg.id
      Miscella.move(this.diagnozy, index, 0)
      this.saveForm(true)
    },
    markDiagnozaPriOdoslani: function (dg) {
      var found = this.isDiagnozaPriOdoslani(dg)
      if (found) {
        this.model.vysetrenie.diagnoza_pri_odoslani.splice(
          this.model.vysetrenie.diagnoza_pri_odoslani.indexOf(found),
          1
        )
      } else {
        this.model.vysetrenie.diagnoza_pri_odoslani.push(dg)
      }
      this.saveForm(true)
    },
    deletedThing: function (aThingID) {
      return this.saveForm(true)
    },
    copyOdporucanieRcID: function (o) {
      let rcIDToCopy = document.querySelector("#rcID" + o.ehealth_record.rc_id)
      rcIDToCopy.setAttribute("type", "text") // hidden
      rcIDToCopy.select()
      var successful = document.execCommand("copy")
      if (successful) {
        this.notifySuccess("Identifikátor úspešne skopírovaný do schránky")
      }
      /* unselect the range */
      rcIDToCopy.setAttribute("type", "hidden")
      window.getSelection().removeAllRanges()
    },
    addVykon: function () {
      this.$refs.vykonForm.new()
      this.$refs.vykonDialog.open()
    },
    editVykon: function (item) {
      this.$refs.vykonForm.edit(item)
      this.$refs.vykonDialog.open()
    },
    deleteVykon: function (o) {
      return this.$confirm(this.$vuetify.t("Naozaj si želáte odstrániť výkon?"))
        .then((res) => {
          if (res) {
            this.$api
              .delete("/api/patient/" + this.id + "/vykon/" + o.ID)
              .then(() => {
                this.deletedThing(o.ID)
                this.$store.commit(
                  "setSuccess",
                  this.$vuetify.t("Výkon bol úspešne odstránený")
                )
              })
              .catch((err) => {
                this.$store.commit(
                  "setError",
                  this.$vuetify.t("Pri odstraňovaní výkonu vznikla chyba ") +
                    " (" +
                    err +
                    ")."
                )
              })
          }
        })
        .catch(function () {})
    },
    deleteRecept: function (item) {
      this.deletedThing(item.ID)
    },
    getHandler: function (func, ...params) {
      return () => {
        func(...params)
      }
    },
    addItem: function (formName) {
      if (Miscella.isSet(this.$refs[formName])) {
        this.pripojitKVysetreniu = true
        this.$refs[formName].new()
      }
    },
    editItem: function (formName, item) {
      if (!this.isEditable) {
        return
      }
      if (Miscella.isSet(this.$refs[formName])) {
        this.$refs[formName].edit(item)
      }
    },
    addRecept: function () {
      if (Miscella.isSet(this.$refs.receptForm)) {
        this.pripojitKVysetreniu = true
        let datumTeraz = moment().utc().startOf("day").toDate()
        this.$refs.receptForm.new(datumTeraz, this.hlavnaDiagnoza)
      }
    },
    /* addVital: function () {
      this.$refs.pacientVitalForm.new()
      this.$refs.pacientVitalDialog.open()
    },
    editVital: function (item) {
      this.$refs.pacientVitalForm.edit(item)
      this.$refs.pacientVitalDialog.open()
    },
    deleteVital: async function (item) {
      var res = await this.$confirm(
        this.$vuetify.t("Naozaj chcete odstrániť meranie?")
      )
      if (res) {
        await this.$api.delete(`/api/patient/${this.id}/vital/` + item.ID)
        this.deletedThing(item.ID)
        this.$store.commit(
          "setSuccess",
          this.$vuetify.t("Meranie bolo úspešne odstránené")
        )
      }
    }, */
    editKontrola: function () {
      let k = {}
      let p = {}
      if (Miscella.isSet(this.$store.state.pacient)) {
        p = this.$store.state.pacient
        if (Miscella.isSet(p.kontrola)) {
          k = p.kontrola
        }
      }
      this.$refs.dialogKontrola.edit(k)
    },
    addObjednanie: function () {
      this.$refs.dialogPacientEvent.open()
    },
    fetchForms: async function () {
      let params = { user_id: this.userID }
      let resp = await this.$api.formService.getForms(params)
      this.formDefinitions = resp.forms
    },
    fetchFormsTree: async function () {
      let params = { user_id: this.userID }
      let resp = await this.$api.formService.getFormTree(params)
      let myTree = Miscella.clearEmptyArrays(resp.tree)
      this.formsTree = myTree
    },
    addThingForm: function (form) {
      this.$refs.axonFormForm.new(form)
      this.$refs.axonFormDialog.open()
    },
    editThingForm: function (item) {
      this.$refs.axonFormForm.edit(item)
      this.$refs.axonFormDialog.open()
    },
    showFormTree: function () {
      this.$refs.axonFormTreeDialog.open()
    },
    fetchForm: function (formID) {
      var params = { id: formID }
      return this.$api.formService.getForm(params)
    },
    treeChoose: async function (formIDs) {
      var form = await this.fetchForm(formIDs[0])
      this.$refs.axonFormTreeDialog.close()
      this.addThingForm(form.form)
    },
    searchEZdravieVysetrenie: function () {
      this.$refs.ezdravieVyhladajVysetrenieForm.init()
      this.$refs.ezdravieVyhladajVysetrenieDialog.open()
    },
    searchEZdravieVysetrenia: function () {
      this.$refs.ezdravieVyhladajVysetreniaForm.search()
      this.$refs.ezdravieVyhladajVysetreniaDialog.open()
    },
    showEZdravieVysetrenie: function (eov) {
      this.ezdravieVysetrenie = eov
      this.$refs.ezdravieVysetrenieDialog.open()
    },
    vytvorVysledok: async function (vysledok) {
      this.$refs.ezdravieVyhladajVysetrenieDialog.close()
      let params = {
        patient_id: this.id,
        ambulance_id: this.ambulanceID,
        poziadavka_na_vysetrenie: vysledok.poziadavka_na_vysetrenie,
        datum_a_cas_odoslania: moment(vysledok.datum_a_cas_odoslania),
        odosielajuci_lekar_jruz_id: vysledok.odosielajuci_lekar,
        odosielajuci_lekar_oupzs_jruz_id: vysledok.odosielajuci_lekar_oupzs,
        diagnoza_pri_odoslani: [],
      }
      for (let dg of vysledok.diagnoza_pri_odoslani) {
        params.diagnoza_pri_odoslani.push({
          code: dg.CodeValue,
          version: dg.CodingSchemeVersion,
        })
      }
      var data = await this.$api.thingService.createVysetreniePodlaOdporucania(
        params
      )
      this.setVysetrenie(data)
      this.notifySuccess(
        "Boli naplnené hodnoty vyšetrenia Odosielajúci lekár, Dátum a čas odoslania, Požiadavka na vyšetrenie a Diagnóza pri odoslaní podľa zvoleného výmenného lístka. Prosím dokončite vytváranie vyšetrenia."
      )
      this.readPacient()
      this.reload(data.thing.id)
    },
    savedEzdravieVysetrenieSOdporucanim: function (result) {
      //this.model.vysetrenie.poziadavka_na_vysetrenie = result.odporucanieID
      /*this.savedThing(
        this.$refs.ezdravieVyhladajVysetrenieDialog,
        result.ezdravieVysetrenie.ID,
        ""
      )*/
    },
    /*savedThingOckovanie(aDialog, aEvent) {
      let thingOckovanieID = aEvent.ID
      let diagnozaID = aEvent.DiagnozaID
      let thingVykonID = aEvent.ThingVykonID
      this.savedThingNEW(aDialog, thingOckovanieID, diagnozaID)
    },
    savedThingVital(aDialog, aEvent) {
      console.log("savedThingVital", aEvent)
      let thingVitalID = aEvent
      this.savedThingNEW(aDialog, thingVitalID, null)
    },
    //TODO skopirovat, prerobit a tuto staru verziu zmazat
    savedThing: function (aThing) {
      if (!Miscella.isEmpty(aThingDiagnozaID)) {
        console.log("savedThing - add thingDiagnoza", aThingDiagnozaID)
        if (Miscella.isEmpty(this.model.HlavnaDiagnozaID)) {
          this.model.HlavnaDiagnozaID = aThingDiagnozaID
        }
      }
      this.saveVysetrenie(true)
    },*/
    /*savedThingNEW: async function (aDialog, aThingID, aDiagnozaID) {
      if (!Miscella.isEmpty(aDiagnozaID)) {
        //zistime ci je uz aDiagnoza v diagnozach na vysetreni
        let found = this.diagnozy.some(function (d) {
          return d.DiagnozaID === aDiagnozaID
        })
        //ak nie je, tak ju ulozime ako ThingDiagnozu
        if (!found) {
          let params = { DiagnozaID: aDiagnozaID }
          let res = await this.$api.post(
            `/api/patient/${this.id}/diagnoza`,
            params
          )
          let thingDiagnozaID = res
          //pripadne z nej spravime hlavnu diagnozu
          if (Miscella.isEmpty(this.model.HlavnaDiagnozaID)) {
            this.model.HlavnaDiagnozaID = thingDiagnozaID
          }
        }
      }
      this.saveVysetrenie(true)
    },*/
    savedThingDiagnoza: function (dg) {},

    reload: function (vysetrenieID) {
      if (vysetrenieID === this.vysetrenieID) {
        this.fetchData()
      } else {
        this.$router.replace({
          name: "vysetrenie",
          params: { id: this.id, vysetrenieID: vysetrenieID },
        })
      }
    },
    saveThingFunc: async function (form) {
      console.log("VYSETRENIE.VUE, saveThingFunc")
      if (!this.pripojitKVysetreniu) {
        console.log("VYSETRENIE.VUE, no pripojenie, calling form.save()")
        return form.save()
      }
      try {
        if (this.isNew) {
          console.log(
            "VYSETRENIE.VUE, new vysetrenie, calling saveVysetrenie()"
          )
          var data = await this.saveVysetrenie()
          this.setVysetrenie(data)
        }
        form.model.parent_id = this.model.id
        form.$once("saved", async (thing) => {
          // ak je to diagnoza a zaroven prva diagnoza, tak nastavime hlavnu diagnozu
          if (
            Miscella.isEmpty(this.model.vysetrenie.hlavna_diagnoza_id) &&
            Miscella.isSet(thing) &&
            Miscella.isSet(thing.diagnoza) &&
            !Miscella.isEmpty(thing.id)
          ) {
            this.model.vysetrenie.hlavna_diagnoza_id = thing.id
          }
          // save vysetrenia po kazdom save naviazaneho thingu, potrebne okrem ineho aj na pregenerovanie search textu
          console.log("VYSETRENIE.VUE, calling saveVysetrenie()")
          var data = await this.saveVysetrenie()
          this.setVysetrenie(data)
          this.reload(this.model.id)
        })
        console.log("VYSETRENIE.VUE, calling form.save()")
        await form.save()
      } catch (err) {
        this.processError(err, "Pri ukladaní vyšetrenia vznikla chyba")
      }
    },
    saveToEhealth: async function () {
      this.errors.clear()
      var valid = await this.$validator.validateAll()
      if (!Miscella.isSet(this.model.vysetrenie.urgentnost_vysetrenia)) {
        valid = false
        this.errors.add({
          field: "urgentnost_vysetrenia",
          msg: this.$vuetify.t(
            "Položka Urgentnosť vyšetrenia je pri odosielaní do ezdravia povinná"
          ),
        })
      }
      if (!valid) {
        return
      }
      try {
        var data = await this.saveVysetrenie()
        this.setVysetrenie(data)
        var params = {
          ambulance_id: this.ambulanceID,
          patient_id: this.id,
          object_id: this.vysetrenieID,
          object_type: "ZAPIS_ZAZNAM_O_VYSETRENI_V7",
          version_status: enums.EhrVersionStatus.Final,
        }
        var result = await this.$api.ehealthService.sendToEhealth(params)
        this.notifySuccess("Vyšetrenie sa úspešne začalo odosielať do ezdravia")
      } catch (e) {
        console.log("ERROR saveToEhealth: ", e)
        this.processError(
          e,
          "Pri odosielaní vyšetrenia do ezdravia vznikla chyba"
        )
      }
    },
    deleteInEhealth: async function () {
      // TODO dialog text dovod
      try {
        var params = {
          ambulance_id: this.ambulanceID,
          patient_id: this.id,
          object_id: this.vysetrenieID,
          object_type: "STORNUJ_ZAZNAM_O_VYSETRENI_V7",
          version_status: enums.EhrVersionStatus.Deleted,
        }
        var result = await this.$api.ehealthService.sendToEhealth(params)
        this.notifySuccess(
          "Storno vyšetrenia sa úspešne začalo odosielať do ezdravia"
        )
      } catch (e) {
        console.log("ERROR deleteInEhealth: ", e)
        this.processError(
          e,
          "Pri odosielaní storna vyšetrenia do ezdravia vznikla chyba"
        )
      }
    },
    printThingOK: function (data) {
      console.log("VYSETRENIE.VUE, printThingOK", data)
      this.openPrintWindow(
        this.$api.storageService.storageTmpUrl(data.file_path)
      )
    },
    printThingErr: function (err) {
      this.$store.commit(
        "setError",
        this.$vuetify.t("Pri tlači nastala chyba ") + err
      )
    },
    printVysetrenie: function () {
      let route = this.$router.resolve({
        path: "/print/vysetrenie",
        query: {
          pacientID: this.id,
          vysetrenieID: this.vysetrenieID,
          clinicID: this.$store.state.me.clinic.id,
        },
      })
      // let w = window.open(route.href, "_blank")
      // w.print()
      window.open(route.href, "_blank")
    },
    printOdporucanie: function (odporucanie) {
      const data = { thing_id: odporucanie.id }
      this.$api
        .post("/v1/proto.ThingService/PrintThing", data)
        .then(this.printThingOK)
        .catch(this.printThingErr)
    },
    readPacient: function () {
      this.$store.commit("setReloadPacient", true)
    },
  },
  mounted: function () {},
  created: function () {
    this.fetchData()
    this.fetchForms()
    this.fetchFormsTree()
  },
  watch: {
    $route: function () {
      this.fetchData()
      this.$vuetify.goTo("#vysetrenie-main")
    },
  },
}
</script>
<style scoped>
span.lbl {
  font-weight: bold;
  margin-right: 0.5rem;
}
</style>
