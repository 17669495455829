<template>
  <v-container grid-list-sm class="pa-0">
    <v-layout row wrap v-if="demo">
      <v-flex xs12>
        <v-card @contextmenu="showDemoMenu">
          <v-card-text>
            <v-alert :value="true" type="warning" class="py-2" outline>
              {{
                $vuetify.t("Demo mód " + (ehealthDemo ? "zapnutý" : "vypnutý"))
              }}
            </v-alert>
            <v-menu
              v-model="demoMenu"
              :position-x="demoMenuX"
              :position-y="demoMenuY"
              absolute
              offset-y
            >
              <v-list>
                <v-list-tile @click="ehealthDemo = !ehealthDemo">
                  <v-list-tile-title>{{
                    $vuetify.t(
                      isEhealthDemo
                        ? "Vypnúť ezdravie demo"
                        : "Zapnúť ezdravie demo"
                    )
                  }}</v-list-tile-title>
                </v-list-tile>
              </v-list>
            </v-menu>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card>
          <v-toolbar flat dense card class="py-0"
            ><v-toolbar-title>{{
              $vuetify.t("Stav ezdravia")
            }}</v-toolbar-title></v-toolbar
          >
          <v-card-text>
            <v-alert
              :value="true"
              :type="ehealthEnabled ? 'success' : 'error'"
              class="py-2"
              outline
            >
              {{ $vuetify.t("$vuetify.ezdravie.enabled." + ehealthEnabled) }}
            </v-alert>
            <!-- <v-layout row wrap v-if="ehealthEnabled"> -->
            <!-- <v-alert -->
            <!--   :value="true" -->
            <!--   :type="isEhealthModuleConnected ? 'success' : 'error'" -->
            <!--   class="py-2" -->
            <!--   outline -->
            <!-- > -->
            <!--   {{ -->
            <!--     $vuetify.t( -->
            <!--       "$vuetify.ezdravie.moduleConnected." + isEhealthModuleConnected -->
            <!--     ) -->
            <!--   }} -->
            <!-- </v-alert> -->
            <!-- <v-alert -->
            <!--   :value="true" -->
            <!--   :type=" -->
            <!--     isEhealthModuleConnected && ehealthStatus.ServerDostupny -->
            <!--       ? 'success' -->
            <!--       : 'error' -->
            <!--   " -->
            <!--   class="py-2" -->
            <!--   outline -->
            <!-- > -->
            <!--   {{ -->
            <!--     $vuetify.t( -->
            <!--       "$vuetify.ezdravie.serverStatus." + -->
            <!--         (isEhealthModuleConnected -->
            <!--           ? ehealthStatus.ServerDostupny -->
            <!--           : "undefined") -->
            <!--     ) -->
            <!--   }} -->
            <!-- </v-alert> -->
            <div v-if="ehealthEnabled">
              <v-alert
                :value="true"
                :type="isAmbulanceJruzId ? 'success' : 'error'"
                class="py-2"
                outline
              >
                {{ $vuetify.t(ambulanceJruzIdStatusString) }}
              </v-alert>
              <v-alert
                :value="true"
                :type="isEhealthCardConnected ? 'success' : 'error'"
                class="py-2"
                outline
              >
                {{ this.ehealthCardStatusString }}
              </v-alert>
              <v-alert
                v-if="pacient"
                :value="true"
                :type="hasPatientJruzID ? 'success' : 'error'"
                class="py-2"
                outline
              >
                {{
                  $vuetify.t(
                    "$vuetify.ezdravie.hasPatientJruzID." + hasPatientJruzID
                  )
                }}
              </v-alert>
            </div>
          </v-card-text>
        </v-card>
        <div v-if="pacient && pacient.ehealth_record">
          <v-card>
            <v-toolbar flat dense card class="py-0"
              ><v-toolbar-title>{{
                $vuetify.t("Pacient")
              }}</v-toolbar-title></v-toolbar
            >
            <EhealthRecord
              v-model="pacient.ehealth_record"
              :objectID="pacient.id"
              titleText="Identifikácia pacienta"
              statusTextPrefix="$vuetify.enum.ehealthRecord.patientIdentification"
            ></EhealthRecord>
          </v-card>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import TextField from "./../TextField.vue"
import * as Miscella from "./../../miscella.js"
import { mapState, mapGetters, mapMutations } from "vuex"
import EhealthRecord from "@/components/EhealthRecord.vue"

export default {
  components: {
    EhealthRecord,
    //TextField,
  },
  data: function () {
    return {
      demoMenu: false,
      demoMenuX: 0,
      demoMenuY: 0,
    }
  },
  computed: {
    isAmbulanceJruzId: function () {
      return (
        Miscella.isSet(this.ambulance) &&
        !Miscella.isEmpty(this.ambulance.jruz_id)
      )
    },
    isAmbulanceCode: function () {
      return (
        Miscella.isSet(this.ambulance) &&
        !Miscella.isEmpty(this.ambulance.kod_ambulancie)
      )
    },
    isUserJruzId: function () {
      return Miscella.isSet(this.user) && !Miscella.isEmpty(this.user.jruz_id)
    },
    ehealthCardStatusString: function () {
      if (!this.isUserJruzId) {
        return this.$vuetify.t(
          "JRUZ ID zdravotníckeho pracovníka nie je zadané. " +
            "Nebude možné spárovať kartičku zdravotníckeho pracovníka s aktuálnym používateľom."
        )
      }
      return this.$vuetify.t(
        "$vuetify.ezdravie.cardStatus." +
          (this.isEhealthCardConnected ? this.ehealthStatus : "undefined")
      )
    },
    ambulanceJruzIdStatusString: function () {
      if (this.isAmbulanceJruzId) {
        return this.$vuetify.t("JRUZ ID ambulancie zadané")
      }
      if (this.isAmbulanceCode) {
        return this.$vuetify.t(
          "Kód ambulancie bol zadaný ale JRUZ ID ambulancie sa nenašlo." +
            " Možná príčina je nesprávne zadaný kód ambulancie. Prosím, prekontrolujte kód PZS a kód ambulancie v nastaveniach." +
            "Na základe kódu ambulancie sa systém pokúsi nájsť JRUZ ID, ktoré je nevyhnutné pre komunikáciu s ezdravím. " +
            "JRUZ ID je možné zadať aj priamo bez zadania kódu."
        )
      }
      return this.$vuetify.t(
        "Kód ambulancie nebol zadaný. Prosím, zadajte kódy PZS a ambulancie v nastaveniach. " +
          "Na základe kódu ambulancie sa systém pokúsi nájsť JRUZ ID, ktoré je nevyhnutné pre komunikáciu s ezdravím. " +
          "JRUZ ID je možné zadať aj priamo bez zadania kódu."
      )
    },
    ...mapState({
      pacient: (state) => state.pacient,
      ambulance: (state) => state.me.ambulance,
      user: (state) => state.me.user,
      ehealthStatus: (state) => state.ehealth.status,
      ehealthEnabled: (state) => state.ehealth.enabled,
      demo: (state) => state.demo,
    }),
    ...mapGetters([
      "isEhealthModuleConnected",
      "isEhealthCardConnected",
      "isEhealthDemo",
      "hasPatientJruzID",
    ]),
    ehealthDemo: {
      // getter
      get() {
        return this.isEhealthDemo
      },
      // setter
      set(isDemo) {
        this.$store.commit("setEhealthDemo", isDemo)
        this.ehealthDemoChanged(isDemo) // from global mixin in main.js
        console.log("API", this.$api)
      },
    },
  },
  methods: {
    showDemoMenu: function (e) {
      e.preventDefault()
      this.demoMenu = false
      this.demoMenuX = e.clientX
      this.demoMenuY = e.clientY
      this.$nextTick(() => {
        this.demoMenu = true
      })
    },
  },
}
</script>
