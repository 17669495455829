import * as enums from "@/plugins/enums.js"

export default {
  computed: {
    eventPopupWidth: function () {
      return 450
    },
    viewportWidth: function () {
      return Math.max(
        document.documentElement.clientWidth,
        window.innerWidth || 0
      )
    },
  },
  methods: {
    isKontrola: function (event) {
      return event.Typ === enums.TypEventu.TypEventuKontrola
    },
    isObjednanie: function (event) {
      return event.Typ === enums.TypEventu.TypEventuObjednanie
    },
    isObjednanieOrders: function (event) {
      return event.Typ === enums.TypEventu.TypEventuObjednanieOrders
    },
    getColorOfEventDay: function (typ, active = false) {
      switch (typ) {
        case enums.TypEventu.TypEventuKalendar:
          return active === true ? "purple darken-2" : "purple lighten-3"
        case enums.TypEventu.TypEventuKontrola:
          return active === true ? "blue darken-2" : "blue lighten-3"
        case enums.TypEventu.TypEventuObjednanie:
          return active === true
            ? "light-green darken-2"
            : "light-green lighten-3"
        case enums.TypEventu.TypEventuObjednanieOrders:
          return active === true ? "orange darken-2" : "orange lighten-3"
      }
      return ""
    },
  },
}
