var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
      _vm.form.model.id !== '' &&
      _vm.form.isOpakRecept &&
      _vm.form.model.recept.blo_ciarovy_kod != undefined &&
      _vm.form.model.recept.blo_ciarovy_kod != ''
    )?_c('v-btn',{attrs:{"flat":""},on:{"click":_vm.form.zneplatnitReceptOFFLINE}},[_vm._v(_vm._s(_vm.$vuetify.t("Zneplatniť op.recept")))]):_vm._e(),(_vm.form.model.id !== '')?_c('v-btn',{attrs:{"flat":""},on:{"click":_vm.form.tlacit}},[_vm._v(" "+_vm._s(_vm.$vuetify.t("Tlačiť recept"))+" ")]):_vm._e(),(
      _vm.form.model.recept.blo_ciarovy_kod != undefined &&
      _vm.form.model.recept.blo_ciarovy_kod != ''
    )?_c('v-btn',{attrs:{"flat":""},on:{"click":_vm.form.stornoReceptOFFLINE}},[_vm._v(" "+_vm._s(_vm.$vuetify.t("Stornovať recept"))+" ")]):_vm._e(),_c('v-btn',{attrs:{"flat":""},nativeOn:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$vuetify.t("$vuetify.app.button.close"))+" ")]),(!_vm.form.formDisabled)?_c('v-btn',{attrs:{"color":"primary","flat":"","loading":_vm.form.saveSpinning},nativeOn:{"click":function($event){return _vm.$emit('form-save')}}},[_vm._v(_vm._s(_vm.$vuetify.t("Uložiť")))]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }