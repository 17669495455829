<template>
  <div>
    <v-container fluid px-0 mx-0 pt-0 mt-0 printContainer>
      <v-layout row wrap>
        <template>
          <v-flex xs3 v-if="isCustomLogo">
            <img :width="imageWidth" id="imageLogo" />
          </v-flex>
          <v-flex xs3 v-else> </v-flex>
          <v-flex
            v-if="isCustomHeader"
            xs9
            v-html="clinic.printHeaderText"
            align-self-center
            text-xs-right
            nadpis
            font-weight-bold
          >
          </v-flex>
          <v-flex
            v-else
            xs9
            v-html="headerText"
            align-self-center
            text-xs-right
            nadpis
            font-weight-bold
          >
          </v-flex>
        </template>
        <v-flex xs12 bordered pa-1>
          <v-layout row wrap>
            <v-flex xs2 font-weight-bold>Pacient:</v-flex>
            <v-flex xs3
              ><b>{{ pacient.full_name }}</b></v-flex
            >
            <v-flex xs3 text-xs-center>
              <span class="font-weight-bold">R.č.:</span>
              {{ pacient.person_unique_id }}
            </v-flex>
            <v-flex xs2 text-xs-right font-weight-bold>ZP:</v-flex>
            <v-flex xs2 text-xs-right>{{ pacientPoistovnaKod }}</v-flex>
            <v-flex xs2 font-weight-bold>Bydlisko:</v-flex>
            <v-flex xs6>{{ pacientAddress }}</v-flex>
            <v-flex xs2 text-xs-right font-weight-bold>Dátum:</v-flex>
            <v-flex xs2 text-xs-right>{{ model.date | formatDatum }}</v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 pt-2>
          <div id="popis">
            <vue-markdown :source="modelFormattedText"></vue-markdown>
          </div>
        </v-flex>
      </v-layout>
      <div class="pageBottom" v-html="clinic.printVysetrenie">
        <!-- napr. toto chce HOCHEL
        <div>
          <hr />
          V zmysle §6 zákona 576/2004 Z.z. som bol dostatočne informovaný o
          povahe môjho ochorenia, dôvode, účele, spôsobe a možnostiach
          zdravotnej starostlivosti, ktorá mi bude/bola poskytnutá ako aj o
          možných následkoch a rizikách spojených s jej poskytnutím, čo
          potvrdzujem svojim podpisom.
          <br /><br /><br />
        </div>
        <div style="text-align: right">
          podpis pacienta/zákonného zástupcu
        </div>
        -->
      </div>
    </v-container>
  </div>
</template>

<script>
import * as Miscella from "./../../miscella.js"
import * as enums from "@/plugins/enums.js"
import Http from "@/plugins/http.js"
import moment from "moment"
import VueMarkdown from "vue-markdown-v2"
//import Vue from "vue"

export default {
  components: { VueMarkdown },
  data() {
    return {
      model: {},
      clinic: {},
      pacient: {},
    }
  },
  computed: {
    logoUrl: function () {
      return "/api/binary/" + this.clinic.logoID
    },
    isCustomLogo: function () {
      let ret = false
      if (Miscella.isSet(this.clinic.logoID)) {
        if (this.clinic.logoID !== "") {
          ret = true
        }
      }
      return ret
    },
    isCustomHeader: function () {
      let ret = false
      if (Miscella.isSet(this.clinic.printHeaderText)) {
        if (!Miscella.isBlank(this.clinic.printHeaderText)) {
          ret = true
        }
      }
      return ret
    },
    imageWidth: function () {
      let ret = "120"
      if (Miscella.isSet(this.clinic.printLogoWidth)) {
        if (!Miscella.isBlank(this.clinic.printLogoWidth)) {
          ret = this.clinic.printLogoWidth
        }
      }
      return ret
    },
    pacientPoistovnaKod: function () {
      return enums.getPoistovnaKod(this.pacient.poistovna)
    },
    pacientAddress: function () {
      let ret = ""
      if (!Miscella.isSet(this.pacient)) {
        return ret
      }
      if (!Miscella.isSet(this.pacient.address)) {
        return ret
      }
      let address = this.pacient.address
      ret += address.street + " "
      ret += address.street_number
      ret = ret.trim() + ", "
      if (address.city == "") {
        if (Miscella.isSet(address.city_cb)) {
          ret += address.city_cb.display_name
        }
      } else {
        ret += address.city
      }
      ret = ret.trim()
      ret = ret + " " + address.postal_code
      ret = ret.trim() + ", " + address.country
      ret = ret.trim()
      if (ret === ",") {
        ret = ""
      }
      return ret
    },
    clinicAddress: function () {
      let ret = ""
      ret += this.clinic.address
      return ret
    },
    headerText: function () {
      let ret = this.clinic.name + "<br>"
      ret += this.clinicAddress
      return ret
    },
    modelFormattedText: function () {
      let ret = this.model.formatted_text
      return ret
    },
  },
  watch: {},
  methods: {
    fetchData: function () {
      Promise.all([
        this.fetchVysetrenie(),
        this.fetchClinic(),
        this.fetchPacient(),
      ])
        .then(this.fetchLogo)
        .then(() => {
          console.log("promises finished")
          window.print()
          window.close()
          return Promise.resolve()
        })
    },
    fetchVysetrenie: function () {
      console.log("fetchVysetrenie start")
      return this.$api.thingService
        .getThing({ thing_id: this.$route.query.vysetrenieID })
        .then(this.setVysetrenie)
      // print: true,
    },
    setVysetrenie: function (data) {
      this.model = data.thing
      console.log("fetchVysetrenie end")
      return Promise.resolve()
    },
    fetchClinic: function () {
      console.log("fetchClinic start")
      return this.$api
        .post("/v1/proto.ClinicService/GetClinic", {
          clinic_id: this.$route.query.clinicID,
        })
        .then((data) => {
          this.clinic = data.clinic
          console.log("fetchClinic end")
        })
    },
    fetchPacient: function () {
      console.log("fetchPacient start")
      return this.$api
        .post("/v1/proto.PatientService/GetPatient", {
          patient_id: this.$route.query.pacientID,
        })
        .then((json) => {
          this.pacient = json.patient
          console.log("fetchPacient end")
          return Promise.resolve()
        })
    },
    fetchLogo: async function () {
      console.log("fetchLogo start")
      // if (this.clinic.logoID === "") {
      //   console.log("fetchLogo end, no logo")
      //   return Promise.resolve()
      // }
      // let data = await Http.get(this.logoUrl)
      // let buffer = await data.arrayBuffer()
      // console.log("fetchLogo", buffer)
      // var base64Flag = "data:image/jpeg;base64,"
      // var imageStr = Miscella.arrayBufferToBase64(buffer)
      // document.querySelector("#imageLogo").src = base64Flag + imageStr
      console.log("fetchLogo end")
      return Promise.resolve()
    },
  },
  mounted: function () {
    console.log("PrintVysetrenie.vue mounted")
    this.fetchData()
  },
}
</script>
<style>
.printContainer {
  font-family: sans-serif;
  font-size: 11pt;
  background-color: white;
}
.printContainer h5,
h4,
h3,
h2,
h1 {
  font-family: sans-serif;
  font-size: 11pt;
}
@media print {
  .nadpis {
    font-size: 14pt;
    color: grey;
    -webkit-print-color-adjust: exact;
  }
}
.smallFont {
  font-size: 10pt;
}
.bordered {
  border: solid 1px black;
}
.pageBottom {
  margin-top: 8px;
  page-break-inside: avoid;
}
div#popis p {
  margin-bottom: 4px;
}
</style>
