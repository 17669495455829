<template>
  <v-form>
    <v-container fluid>
      <v-layout row>
        <v-flex xs12 md6>
          <DatePicker
            v-model="model.date"
            :label="$vuetify.t('Dátum')"
            name="datum"
            v-validate="'required'"
          ></DatePicker>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs12 md6>
          <codebook-autocomplete
            codebook-id="115"
            name="miestoMerania"
            v-model="model.krvny_tlak.miesto_merania"
            :label="$vuetify.t('Miesto Merania')"
          >
          </codebook-autocomplete>
        </v-flex>
        <v-flex xs12 md6>
          <codebook-autocomplete
            codebook-id="132"
            name="metodaMerania"
            v-model="model.krvny_tlak.metoda_merania"
            :label="$vuetify.t('Metóda Merania')"
          >
          </codebook-autocomplete>
        </v-flex>
        <v-flex xs12 md6>
          <codebook-autocomplete
            codebook-id="30"
            name="okolnostiMerania"
            v-model="model.krvny_tlak.okolnosti_merania"
            :label="$vuetify.t('Okolnosti Merania')"
          >
          </codebook-autocomplete>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs12 md6>
          <TextField
            v-model="model.krvny_tlak.tlak_sys"
            type="number"
            :label="$vuetify.t('SYS krvný tlak')"
            name="tlakD"
            v-validate="'numeric'"
          ></TextField>
        </v-flex>
        <v-flex xs12 md6>
          <TextField
            v-model="model.krvny_tlak.tlak_dia"
            type="number"
            :label="$vuetify.t('DIA krvný tlak')"
            name="tlakH"
            v-validate="'numeric'"
          ></TextField>
        </v-flex>
        <v-flex xs12 md6>
          <TextField
            v-model="model.krvny_tlak.tlak_arterial"
            type="number"
            :label="$vuetify.t('ARTERIÁLNY krvný tlak')"
            name="tlakA"
            v-validate="'numeric'"
          ></TextField>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs12 md6>
          <TextField
            v-model="model.krvny_tlak.pulz"
            type="number"
            :label="$vuetify.t('Pulz')"
            name="tep"
            v-validate="'numeric'"
          ></TextField>
        </v-flex>
      </v-layout>
      <v-flex xs12>
        <v-textarea
          v-model="model.krvny_tlak.poznamka"
          :label="$vuetify.t('Poznámka')"
        ></v-textarea>
      </v-flex>
    </v-container>
  </v-form>
</template>

<script>
import BaseForm from "./../BaseForm.vue"
import moment from "moment"
import * as Miscella from "./../../miscella.js"
import TextField from "./../TextField.vue"
import DatePicker from "./../DatePicker.vue"
import CodebookAutocomplete from "./../ciselnikComponents/CodebookAutocomplete.vue"

export default {
  name: "FormPacientKrvntTlak",
  extends: BaseForm,
  components: {
    DatePicker,
    CodebookAutocomplete,
    TextField,
  },
  props: {
    pacientId: {
      type: String,
      required: true,
    },
  },
  $_veeValidate: {
    validator: "new", // give me my own validator scope.
  },
  data: function () {
    return {
      model: null,
    }
  },
  computed: {
    ambulanceId: function () {
      let id = null
      if (Miscella.isSet(this.$store.state.me.ambulance)) {
        id = this.$store.state.me.ambulance.id
      }
      return id
    },
  },
  watch: {},
  methods: {
    new: function () {
      this.init()
      this.$emit("activated")
    },
    edit: function (item) {
      this.init()
      this.model = Miscella.cloneObject(item)
      this.$emit("activated")
    },
    init: function () {
      console.log("init")
      this.model = {
        id: "",
        patient_id: this.pacientId,
        ambulance_id: this.ambulanceId,
        date: moment().format(),
        parent_id: "",
        krvny_tlak: {
          tlak_sys: null,
          tlak_dia: null,
          tlak_arterial: null,
          pulz: null,
          miesto_merania: null,
          metoda_merania: null,
          okolnosti_merania: null,
          poznamka: null,
        },
      }

      this.$validator.reset()
      this.errors.clear()
    },
    validate: async function () {
      return this.$validator.validateAll()
    },
    save: async function () {
      try {
        var params = { thing: Miscella.cloneObject(this.model) }
        var result = await this.$api.thingService.saveThing(params)
        this.notifySuccess("Krvný tlak bol úspešne uložený")
        this.$emit("saved", result.thing)
        this.model = result.thing
      } catch (err) {
        this.processError(err, "Pri ukladaní krvného tlaku vznikla chyba")
      }
    },
  },
  mounted: function () {},
  created: function () {
    this.init()
  },
}
</script>
