<template>
  <v-container fluid px-0>
    <v-layout row>
      <v-flex xs6>
        <v-tooltip top>
          <span slot="activator">{{ krvnaSkupina.date | formatDatum }}</span>
          <span>{{ $vuetify.t("Dátum vyšetrenia") }}</span>
        </v-tooltip>
      </v-flex>
      <v-flex xs6>
        <v-tooltip
          top
          v-if="krvnaSkupina.krvna_skupina.nazov_krvnej_skupiny !== null"
        >
          <span slot="activator">{{
            krvnaSkupina.krvna_skupina.nazov_krvnej_skupiny.display_name
          }}</span>
          <span>{{ $vuetify.t("Názov krvnej skupiny") }}</span>
        </v-tooltip>
      </v-flex>
      <v-flex xs6 class="text-truncate">
        <v-tooltip top>
          <span slot="activator">{{
            krvnaSkupina.krvna_skupina.poznamka
          }}</span>
          <span>{{ $vuetify.t("Poznámka") }}</span>
        </v-tooltip>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  name: "KrvnaSkupinaRow",
  props: {
    krvnaSkupina: {
      type: Object,
      required: true,
    },
  },
}
</script>
