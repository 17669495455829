<template>
  <div>
    <v-tabs v-model="activeTab">
      <v-tab to="/admin/logs/eZdravieLog">{{ $vuetify.t("EZdravie") }}</v-tab>
      <!-- <v-tab to="/admin/logs/eVysetrenieQueue">{{
        $vuetify.t("EVyšetrenie")
      }}</v-tab>
      <v-tab to="/admin/logs/eReceptLog">{{ $vuetify.t("ERecept") }}</v-tab> -->
    </v-tabs>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      activeTab: 0,
    }
  },
}
</script>

<style scoped></style>
