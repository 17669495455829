export default class ThingService {
  constructor(api) {
    this.api = api
  }

  get listThingsUrl() {
    return "/v1/proto.ThingService/ListThings"
  }

  saveThing(params, options) {
    return this.api.post("/v1/proto.ThingService/SaveThing", params, options)
  }

  getThing(params, options) {
    return this.api.post("/v1/proto.ThingService/GetThing", params, options)
  }

  listThings(params, options) {
    return this.api.post(this.listThingsUrl, params, options)
  }

  deleteThing(params, options) {
    return this.api.post("/v1/proto.ThingService/DeleteThing", params, options)
  }

  createVysetreniePodlaOdporucania(params, options) {
    return this.api.post(
      "/v1/proto.ThingService/CreateVysetreniePodlaOdporucania",
      params,
      options
    )
  }
}
