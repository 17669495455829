<template>
  <v-form>
    <v-container fluid>
      <v-layout row wrap>
        <v-flex xs12 md4>
          <TextField
            v-model="pacient.name"
            :label="$vuetify.t('Meno')"
            name="name"
            v-validate="'required'"
          ></TextField>
        </v-flex>
        <v-flex xs12 md4>
          <TextField
            v-model="pacient.surname"
            :label="$vuetify.t('Priezvisko')"
            name="surname"
            v-validate="'required'"
          ></TextField>
        </v-flex>
        <v-flex xs12 md4>
          <TextField
            v-model="pacient.person_unique_id"
            :label="$vuetify.t('Rodné číslo/Identifikátor')"
            name="person_unique_id"
            :success-messages="
              rcIsValid(rcSanitize(pacient.person_unique_id)) &&
              pacient.person_unique_id.length >= 5
                ? 'Platné slovenské RČ'
                : []
            "
          ></TextField>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12 md4>
          <v-select
            v-model="pacient.sex"
            :label="$vuetify.t('Pohlavie')"
            :items="pohlavia"
            v-validate="'dummy'"
            clearable
            :error-messages="errors.collect('sex')"
            data-vv-name="sex"
            :data-vv-as="$vuetify.t('Pohlavie')"
          ></v-select>
        </v-flex>
        <v-flex xs12 md4>
          <DatePicker
            v-model="pacient.birthdate"
            :label="$vuetify.t('Dátum narodenia')"
            name="birthdate"
            v-validate="'dummy'"
          ></DatePicker>
        </v-flex>
        <v-flex xs12 md4>
          <TextField
            :label="$vuetify.t('Vek')"
            :value="notSRVek"
            name="vek"
            disabled
          ></TextField>
        </v-flex>
        <v-flex xs12 md8>
          <codebook-autocomplete
            :codebook-id="enums.CodebookIDZdravotnaPoistovna"
            name="zdravotna_poistovna"
            v-model="model.zdravotna_poistovna"
            :label="$vuetify.t('Zdravotná poisťovňa')"
          >
          </codebook-autocomplete>
        </v-flex>
      </v-layout>
      <template id="pacientKontakt">
        <v-layout row wrap>
          <v-flex xs12 md4>
            <TextField
              v-model="pacient.email"
              :label="$vuetify.t('Email')"
              name="email"
              v-validate="'email'"
            ></TextField>
          </v-flex>
          <v-flex xs12 md4>
            <TextField
              v-model="pacient.phone"
              :label="$vuetify.t('Telefón')"
              name="phone"
            ></TextField>
          </v-flex>
          <v-flex xs12 md4>
            <codebook-autocomplete
              ref="countryField"
              codebook-id="51"
              v-model="pacient.address.country_cb"
              name="country"
              :label="$vuetify.t('Krajina')"
              :prepended-items-params="{
                in_filter: {
                  attr: 'Skratka',
                  values: ['703', '203', '804', '348', '040', '616'],
                },
              }"
              :prepended-items-process-func="
                (items) => {
                  const sortBy = ['703', '203', '804', '348', '040', '616']
                  items.sort(
                    (a, b) =>
                      sortBy.indexOf(a.skratka) - sortBy.indexOf(b.skratka)
                  )
                }
              "
            ></codebook-autocomplete>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs12 md4 v-if="isSlovak">
            <codebook-autocomplete
              codebook-id="45"
              name="city"
              v-model="pacient.address.city_cb"
              :label="$vuetify.t('Mesto')"
            ></codebook-autocomplete>
          </v-flex>
          <v-flex xs12 md4 v-else>
            <TextField
              v-model="pacient.address.city"
              :label="$vuetify.t('Mesto')"
              name="city"
            ></TextField>
          </v-flex>
          <v-flex xs12 md3>
            <TextField
              v-model="pacient.address.street"
              :label="$vuetify.t('Ulica')"
              name="address_street"
            ></TextField>
          </v-flex>
          <v-flex xs12 md2>
            <TextField
              v-model="pacient.address.street_number"
              :label="$vuetify.t('Číslo')"
              name="address_street_number"
            ></TextField>
          </v-flex>
          <v-flex xs12 md3>
            <codebook-autocomplete
              v-if="isSlovak"
              codebook-id="150"
              v-model="pacient.address.postal_code_cb"
              name="postal_code_cb"
              :label="$vuetify.t('PSČ')"
            ></codebook-autocomplete>
            <TextField
              v-else
              v-model="pacient.address.postal_code"
              :label="$vuetify.t('PSČ')"
              name="psc"
            ></TextField>
          </v-flex>
          <v-flex xs12>
            <v-textarea
              v-model="pacient.note"
              :label="$vuetify.t('Poznámka')"
              name="note"
              auto-grow
              rows="2"
              :counter="4000"
              v-validate="'max:4000'"
              data-vv-name="note"
              :data-vv-as="$vuetify.t('Poznámka')"
            ></v-textarea>
          </v-flex>
        </v-layout>
      </template>
    </v-container>
  </v-form>
</template>

<script>
import * as Miscella from "./../../miscella.js"
import TextField from "./../TextField.vue"
import DatePicker from "./../DatePicker.vue"
import * as mixPatient from "./../mixins/patient.js"
import BaseForm from "./../BaseForm.vue"
import CodebookAutocomplete from "./../ciselnikComponents/CodebookAutocomplete.vue"
import * as enums from "@/plugins/enums.js"

export default {
  name: "FormPacient",
  extends: BaseForm,
  $_veeValidate: {
    validator: "new",
  },
  components: {
    TextField,
    DatePicker,
    CodebookAutocomplete,
  },
  mixins: [mixPatient.mixins],
  data: function () {
    return {
      saveUrl: "",
      enums: enums,
      pacient: null,
      pohlavia: Miscella.SEX.values.map((v) => {
        return {
          text: this.$vuetify.t("$vuetify.enum.sex." + v),
          value: v,
        }
      }),
    }
  },
  computed: {
    model: function () {
      return this.pacient
    },
    isSlovak: function () {
      return this.rcIsValid(this.pacient.person_unique_id)
    },
    vek: function () {
      return this.getAgeFromRC(this.pacient.person_unique_id)
    },
    notSRVek: function () {
      return this.getAge(this.pacient.birthdate)
    },
  },
  watch: {
    "pacient.person_unique_id": function () {
      if (!Miscella.isSet(this.pacient.birthdate) && this.isSlovak) {
        let datum = this.getDatumFromRC(this.pacient.person_unique_id)
        let pohlavie = this.getSexFromRC(this.pacient.person_unique_id)
        if (datum !== null && datum.isValid()) {
          this.pacient.birthdate = datum.toDate()
        }
        if (pohlavie !== null && pohlavie !== 0) {
          this.pacient.sex = pohlavie
        }
      }
    },
  },
  methods: {
    init: function () {
      this.pacient = {
        clinic_id: this.$store.state.me.clinic.id,
        name: "",
        surname: "",
        person_unique_id: "",
        sex: null,
        address: {
          street: null,
          street_number: null,
          city: "",
          city_cb: null,
          postal_code: null,
          postal_code_cb: null,
          country_cb: null,
        },
        zdravotna_poistovna: null,
        birthdate: null,
        note: "",
      }
      this.$validator.reset()
    },
    new: function () {
      this.init()
      this.$validator.reset()
      this.saveUrl = this.$api.patientService.createPatientUrl
      this.$emit("activated")
    },
    validate: async function () {
      await this.$validator.validateAll()
      if (!this.isSlovak) {
        if (Miscella.isEmpty(this.pacient.birthdate)) {
          this.errors.add({
            field: "birthdate",
            msg: this.$vuetify.t("Položka dátum narodenia je povinná."),
          })
        }
        if (Miscella.isEmpty(this.pacient.sex) || this.pacient.sex === 0) {
          this.errors.add({
            field: "sex",
            msg: this.$vuetify.t("Položka pohlavie je povinná."),
          })
        }
      }
      return !this.errors.any()
    },
    edit: function (aPacient) {
      this.pacient = Miscella.cloneObject(aPacient)
      this.$validator.reset()
      this.saveUrl = this.$api.patientService.updatePatientUrl
      this.$emit("activated")
    },
    save: async function () {
      try {
        this.pacient.person_unique_id = this.rcSanitize(
          this.pacient.person_unique_id
        )
        var params = Miscella.cloneObject(this.pacient)
        if (this.isSlovak) {
          this.pacient.address.city = ""
          this.pacient.address.postal_code = ""
        } else {
          this.pacient.address.city_cb = null
          this.pacient.address.postal_code_cb = null
        }
        var result = await this.$api.post(this.saveUrl, {
          patient: params,
        })
        this.notifySuccess("Pacient bol úspešne uložený")
        this.$emit("saved", result.pacient)
        this.model = result.pacient
      } catch (err) {
        this.processError(err)
      }
    },
  },
  created: function () {
    this.init()
  },
}
</script>
