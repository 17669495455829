<template>
  <v-content app>
    <v-sheet height="100%">
      <v-container fluid fill-height>
        <v-layout row wrap>
          <v-flex xs12 sm12 md4>
            <Calendar></Calendar>
          </v-flex>
          <v-flex xs12 sm12 md4>
            <Pacienti></Pacienti>
          </v-flex>
          <v-flex xs12 sm12 md4><Vysetrenia /></v-flex>
        </v-layout>
      </v-container>
    </v-sheet>
  </v-content>
</template>

<script>
import Calendar from "../components/dashboard/Calendar.vue"
import Pacienti from "../components/dashboard/Pacienti.vue"
import Vysetrenia from "../components/dashboard/Vysetrenia.vue"

export default {
  components: {
    Calendar,
    Pacienti,
    Vysetrenia,
  },
}
</script>
