<template>
  <v-container fluid>
    <ApiList
      ref="list"
      url="/v1/proto.ThingService/ListThings"
      :customParams="{
        patient_id: id,
        odporucanie: {},
      }"
      :title="$vuetify.t('Odporúčania')"
      :actions="[
        {
          text: $vuetify.t('Odstrániť odporúčanie'),
          icon: 'delete',
          handler: removeItem,
        },
      ]"
      @addItem="addItem"
      @editItem="editItem"
    >
      <template v-slot:items="{ item }">
        <v-container fluid>
          <v-layout row>
            <v-flex xs1>
              <v-tooltip top>
                <span slot="activator">{{
                  item.odporucanie.diagnoza.skratka
                }}</span>
                <span>{{ $vuetify.t("Diagnóza") }}</span>
              </v-tooltip>
            </v-flex>
            <v-flex xs4>
              <v-tooltip top>
                <span slot="activator">{{
                  item.odporucanie.druh_specializovaneho_utvaru.display_name
                }}</span>
                <span>{{ $vuetify.t("Druh špecializované útvaru") }}</span>
              </v-tooltip>
            </v-flex>
            <v-flex xs4>
              <v-tooltip top>
                <span slot="activator">{{
                  item.odporucanie.odborne_zameranie.display_name
                }}</span>
                <span>{{ $vuetify.t("Odborné zameranie") }}</span>
              </v-tooltip>
            </v-flex>
          </v-layout>
        </v-container>
      </template>
    </ApiList>
    <BaseDialog :dialogTitle="$vuetify.t('Výmenný lístok')" ref="dialog">
      <FormOdporucanie
        ref="form"
        :pacientId="id"
        @saved="fetchData"
      ></FormOdporucanie>
    </BaseDialog>
  </v-container>
</template>

<script>
import ApiList from "./../../components/ApiList.vue"
import BaseDialog from "./../../components/BaseDialog.vue"
import FormOdporucanie from "./../../components/forms/FormOdporucanie.vue"
import thingMixin from "@/components/mixins/thing.js"

export default {
  components: {
    ApiList,
    BaseDialog,
    FormOdporucanie,
  },
  props: ["id"],
  mixins: [thingMixin],
  methods: {
    fetchData: function () {
      this.$refs.list.reload()
    },
    addItem: function () {
      this.$refs.form.new()
    },
    editItem: function (item) {
      this.$refs.form.edit(item)
    },
    removeItem: async function (item) {
      var result = await this._deleteThing(item) // from thing mixin
      if (result) {
        this.fetchData()
      }
    },
  },
}
</script>
