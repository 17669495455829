<template>
  <BaseDialog
    :dialogTitle="$vuetify.t('Detail')"
    ref="dialog"
    :showSaveButton="false"
    @close="closeDialog"
  >
    <v-container grid-list-md>
      <v-layout column wrap>
        <v-text-field
          readonly
          label="Názov"
          v-model="chosen.display_name"
        ></v-text-field>
        <v-text-field
          readonly
          label="Skratka"
          v-model="chosen.skratka"
        ></v-text-field>
        <v-textarea
          readonly
          label="Popis"
          v-model="chosen.popis"
          auto-grow
          rows="1"
        ></v-textarea>
      </v-layout>
    </v-container>
  </BaseDialog>
</template>

<script>
import BaseDialog from "./../BaseDialog.vue"

export default {
  components: {
    BaseDialog,
  },
  data: function () {
    return {
      chosen: {
        DisplayName: "",
      },
    }
  },
  methods: {
    show: function (item) {
      this.chosen = item
      this.$refs.dialog.open()
    },
    closeDialog: function () {
      this.$emit("close", this.chosen)
    },
  },
}
</script>

<style scoped></style>
