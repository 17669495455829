<template>
  <v-layout column>
    <ApiTable
      ref="table"
      :headers="headers"
      url="/twirp/proto.EHealth/SmartcardLogs"
      :title="$vuetify.t('')"
      defaultSortBy=""
      :customParams="{}"
      @reload="fetchItems"
    >
      <template slot="items" slot-scope="props">
        <tr @click="itemClicked(props.item)">
          <td class="text-md-left">{{ props.item.CreatedString }}</td>
          <td class="text-md-left">{{ props.item.PoistovnaSR }}</td>
          <td class="text-md-left">{{ props.item.Type }}</td>
          <td class="text-md-left">{{ props.item.Stav }}</td>
          <td class="text-md-left">{{ props.item.ErrorMsg }}</td>
        </tr>
      </template>
    </ApiTable>
    <BaseDialog
      :dialogTitle="$vuetify.t('Detail logu')"
      ref="dialog"
      :showSaveButton="false"
    >
      <v-container fluid grid-list-xl>
        <v-layout row wrap>
          <v-flex xs3><h5>ID:</h5></v-flex>
          <v-flex xs9>{{ chosen.ID }}</v-flex>
          <v-flex xs3><h5>ObjectID:</h5></v-flex>
          <v-flex xs9>{{ chosen.ObjectID }}</v-flex>

          <v-flex xs3><h5>Poistovna:</h5></v-flex>
          <v-flex xs9>{{ chosen.PoistovnaSR }}</v-flex>
          <v-flex xs3><h5>Created:</h5></v-flex>
          <v-flex xs9>{{ chosen.Created }}</v-flex>
          <v-flex xs3><h5>ErrorMsg:</h5></v-flex>
          <v-flex xs9>{{ chosen.ErrorMsg }}</v-flex>
          <v-flex xs3><h5>BLOSpravy:</h5></v-flex>
          <v-flex xs9>{{ chosen.BLOSpravy }}</v-flex>
          <v-flex xs3><h5>Stav:</h5></v-flex>
          <v-flex xs9>{{ chosen.Stav }}</v-flex>
          <v-flex xs3><h5>Type:</h5></v-flex>
          <v-flex xs9>{{ chosen.Type }}</v-flex>
          <v-flex xs3><h5>Updated:</h5></v-flex>
          <v-flex xs9>{{ chosen.ObjectID }}</v-flex>
          <v-flex xs3><h5>SoapRequest:</h5></v-flex>
          <v-flex xs9>{{ chosen.SoapRequest }}</v-flex>
          <v-flex xs3><h5>SoapResponse:</h5></v-flex>
          <v-flex xs9>{{ chosen.SoapResponse }}</v-flex>
        </v-layout>
      </v-container>
    </BaseDialog>
  </v-layout>
</template>

<script>
import ApiTable from "@/components/ApiTable.vue"
import BaseDialog from "@/components/BaseDialog.vue"
import Vue from "vue"

export default {
  components: {
    ApiTable,
    BaseDialog,
  },
  data: function () {
    return {
      chosen: {},
      headers: [
        { text: "Vytvorené", sortable: true, value: "CreatedString" },
        { text: "Poisťovňa", sortable: true, value: "PoistovnaSR" },
        { text: "Typ", sortable: true, value: "Type" },
        { text: "Stav", sortable: true, value: "Stav" },
        { text: "Chyba", sortable: true, value: "ErrorMsg" },
      ],
    }
  },
  computed: {},
  methods: {
    getClass: function (item) {
      return {
        "success--text": !item.IsError,
        "error--text": item.IsError,
      }
    },
    viewItem: function (item) {
      this.chosen = item
      this.$refs.dialog.open()
    },
    fetchItems: function () {
      let url = "/api/eReceptLogs"
      let t = this.$refs.baseTable
      var params = t.customParams
      t.loading = true
      return this.$api
        .get(url, params)
        .then((data) => {
          console.log("data", data)
          data.forEach((item) => {
            item.CreatedString = this.$options.filters.formatDatumACas(
              item.Created,
              "YYYY.MM.DD HH:mm:ss"
            )
          })
          t.setItems(data)
        })
        .catch((error) => {
          console.log("xErr:", error)
        })
        .finally(() => {
          Vue.nextTick(() => {
            t.loading = false
          })
        })
    },
  },
  // mounted: function () {
  //   this.$refs.baseTable.reload()
  // },
}
</script>

<style scoped></style>
