<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="value"
    lazy
    transition="scale-transition"
    offset-y
    full-width
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field :value="value" :label="label" v-on="on" readonly>
        <template v-slot:append>
          <v-icon v-on="on"> schedule </v-icon>
        </template>
      </v-text-field>
    </template>
    <v-time-picker
      v-if="menu"
      :value="value"
      @input="updateValue"
      full-width
      format="24hr"
      @click:minute="$refs.menu.save(value)"
    ></v-time-picker>
  </v-menu>
</template>

<script>
export default {
  inject: ["$validator"],
  props: {
    name: {
      type: String,
      default: "field",
    },
    label: {
      type: String,
      default: "Čas",
    },
    value: {
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      menu: false,
    }
  },
  methods: {
    updateValue: function (value) {
      console.log("value", value)
      this.$emit("input", value)
    },
  },
}
</script>
