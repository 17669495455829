<template>
  <v-form>
    <v-container fluid>
      <!--v-layout row justify-center>
        <v-dialog v-model="dialogDovodStorna" persistent max-width="500px">
          <v-card>
            <v-card-title>
              {{ $vuetify.t("Dôvod storna") }}
            </v-card-title>
            <v-card-text>
              <v-textarea
                v-model="dovodStorna"
                :label="$vuetify.t('Dôvod storna')"
                rows="2"
                hint="Zdôvodnenie storna, pri stornovaní PN ide o povinnú položku."
              ></v-textarea>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" flat @click="dialogDovodStorna=false" :disabled="dovodStorna === ''">{{ $vuetify.t("$vuetify.app.button.close") }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout-->
      <v-layout row>
        <v-flex class="text-xs-left mb-0 pb-0" xs12>
          <span>
            <b>Stav:</b>
            <span class="grey--text pl-1" :title="stav">{{
              this.stavString
            }}</span>
            <EhealthRecord
              v-show="model.id !== null"
              v-model="model.ehealth_record"
              :objectID="model.id"
              titleText="edpn"
              :statusTextPrefix="`$vuetify.enum.ehealthRecord.versionStatus.${
                model.ehealth_record ? model.ehealth_record.version_status : ''
              }`"
            ></EhealthRecord>
          </span>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs12>
          <v-layout row wrap>
            <v-flex class="xs12">
              <v-system-bar>
                <v-card-title>
                  <span class="subheading">{{ $vuetify.t("Všeobecné") }}</span>
                </v-card-title>
              </v-system-bar>
            </v-flex>
            <v-flex class="xs4 px-1">
              <v-checkbox
                v-model="model.potvrdenie_dpn.liecba_v_cudzine"
                :label="$vuetify.t('Liečba v cudzine')"
                :disabled="!isNew || liecbaVCudzineDisabled"
              />
            </v-flex>
            <v-flex class="xs4 px-1">
              <DatePicker
                v-model="model.potvrdenie_dpn.datum_vystavenia"
                :label="$vuetify.t('Dátum vystavenia')"
                name="datum_vystavenia"
                v-validate="'required'"
                :disabled="true"
              ></DatePicker>
            </v-flex>
            <v-flex class="xs4 px-1">
              <DatePicker
                v-model="model.potvrdenie_dpn.neschopny_prace_od"
                :label="$vuetify.t('Neschopný práce od')"
                name="neschopny_prace_od"
                v-validate="'required'"
                :disabled="!isNew"
              ></DatePicker>
              <span class="v-messages">{{ hlaskaNeschopnyPraceOdDatum }}</span>
              <span class="v-messages error--text">{{
                errorNeschopnyPraceOdDatum
              }}</span>
            </v-flex>
            <v-flex class="xs12">
              <v-textarea
                v-model="model.potvrdenie_dpn.poznamka"
                :label="$vuetify.t('Poznámka')"
                rows="1"
                :auto-grow="true"
                name="poznamka"
                :error-messages="errors.collect('poznamka')"
                :disabled="!isNew"
              ></v-textarea>
            </v-flex>
            <v-flex class="xs12">
              <v-system-bar>
                <v-card-title>
                  <span class="subheading">{{ $vuetify.t("Diagnóza") }}</span>
                </v-card-title>
              </v-system-bar>
            </v-flex>
            <v-flex class="xs4 px-1">
              <codebook-autocomplete
                :codebook-id="enums.CodebookIDDiagnoza"
                name="diagnoza"
                v-model="model.potvrdenie_dpn.diagnoza.diagnoza"
                :label="$vuetify.t('Diagnóza')"
                :disabled="formDisabled"
                v-validate="'required'"
              >
                <template v-slot:selection="{ item }"
                  ><span
                    >{{ item.skratka }} - {{ item.display_name }}</span
                  ></template
                >
                <template v-slot:item="{ item }">
                  <v-list-tile-content>
                    <v-list-tile-title>{{ item.skratka }}</v-list-tile-title>
                    <v-list-tile-sub-title>{{
                      item.display_name
                    }}</v-list-tile-sub-title>
                  </v-list-tile-content>
                </template>
              </codebook-autocomplete>
            </v-flex>
            <v-flex class="xs4 px-1">
              <DatePicker
                v-model="model.potvrdenie_dpn.diagnoza.platnost_od"
                name="diagnoza_platnost_od"
                :label="$vuetify.t('Platná od')"
                :disabled="formDisabled"
              ></DatePicker>
              <span class="v-messages">{{ hlaskaDiagnozaOdDatum }}</span>
            </v-flex>
            <v-flex class="xs12">
              <v-system-bar>
                <v-card-title>
                  <span class="subheading">{{ $vuetify.t("Dôvod PN") }}</span>
                </v-card-title>
              </v-system-bar>
            </v-flex>
            <v-flex class="xs4 px-1">
              <codebook-autocomplete
                :codebook-id="enums.CodebookIDDovodPN"
                name="dovod_pn"
                v-model="model.potvrdenie_dpn.dovod_pn.dovod"
                :label="$vuetify.t('Dôvod')"
                :disabled="formDisabled"
                v-validate="'required'"
              >
              </codebook-autocomplete>
            </v-flex>
            <v-flex class="xs4 px-1">
              <DatePicker
                v-model="model.potvrdenie_dpn.dovod_pn.platnost_od"
                :label="$vuetify.t('Platnosť od')"
                name="dovod_platnost_od"
                :disabled="formDisabled"
              ></DatePicker>
              <span class="v-messages">{{ hlaskaDovodOdDatum }}</span>
            </v-flex>
            <v-flex class="xs4 px-1 pt-1">
              <v-label>{{
                $vuetify.t("Požitý alkohol alebo iná návyková látka:")
              }}</v-label>
              <v-btn-toggle
                v-model="model.potvrdenie_dpn.dovod_pn.pozitie_alkoholu"
              >
                <v-btn flat :value="true" :disabled="formDisabled"> Áno </v-btn>
                <v-btn flat :value="false" :disabled="formDisabled">
                  Nie
                </v-btn>
              </v-btn-toggle>
              <div
                v-for="(err, index) in errors.collect('pozitie_alkoholu')"
                :key="index"
              >
                <span v-html="err" class="v-messages error--text"></span>
              </div>
            </v-flex>
            <template v-if="model.potvrdenie_dpn.pobyt_v_case_pn">
              <v-flex class="xs12">
                <v-system-bar>
                  <v-card-title>
                    <span class="subheading">{{
                      $vuetify.t("Pobyt v čase PN")
                    }}</span>
                  </v-card-title>
                </v-system-bar>
              </v-flex>
              <v-flex class="xs2 px-1">
                <DatePicker
                  v-model="
                    model.potvrdenie_dpn.pobyt_v_case_pn.datum_platnosti_od
                  "
                  :label="$vuetify.t('Pobyt platný od')"
                  name="datum_platnosti_od"
                  :disabled="!isNew || formReadOnly"
                ></DatePicker>
              </v-flex>
              <v-flex class="xs4 px-1">
                <codebook-autocomplete
                  codebook-id="51"
                  v-model="
                    model.potvrdenie_dpn.pobyt_v_case_pn.adresa.country_cb
                  "
                  name="country_cb"
                  :label="$vuetify.t('Krajina')"
                  :prepended-items-params="{
                    in_filter: {
                      attr: 'Skratka',
                      values: ['703', '203', '804', '348', '040', '616'],
                    },
                  }"
                  :prepended-items-process-func="
                    (items) => {
                      const sortBy = ['703', '203', '804', '348', '040', '616']
                      items.sort(
                        (a, b) =>
                          sortBy.indexOf(a.skratka) - sortBy.indexOf(b.skratka)
                      )
                    }
                  "
                  :disabled="!isNew || formReadOnly"
                ></codebook-autocomplete>
              </v-flex>
              <v-flex class="xs4 px-1" v-if="isSlovak && isNew">
                <codebook-autocomplete
                  codebook-id="45"
                  v-model="model.potvrdenie_dpn.pobyt_v_case_pn.adresa.city_cb"
                  name="city_cb"
                  :label="$vuetify.t('Mesto')"
                  :disabled="!isNew || formReadOnly"
                ></codebook-autocomplete>
              </v-flex>
              <v-flex class="xs4 px-1" v-else>
                <TextField
                  v-model="model.potvrdenie_dpn.pobyt_v_case_pn.adresa.city"
                  name="city"
                  :label="$vuetify.t('Mesto')"
                  :disabled="!isNew || formReadOnly"
                ></TextField>
              </v-flex>
              <v-flex class="xs2 px-1" v-if="isSlovak && isNew">
                <codebook-autocomplete
                  codebook-id="150"
                  v-model="
                    model.potvrdenie_dpn.pobyt_v_case_pn.adresa.postal_code_cb
                  "
                  name="postal_code_cb"
                  :label="$vuetify.t('PSČ')"
                  :disabled="!isNew || formReadOnly"
                ></codebook-autocomplete>
              </v-flex>
              <v-flex class="xs2 px-1" v-else>
                <TextField
                  v-model="
                    model.potvrdenie_dpn.pobyt_v_case_pn.adresa.postal_code
                  "
                  name="postal_code"
                  :label="$vuetify.t('PSČ')"
                  :disabled="!isNew || formReadOnly"
                ></TextField>
              </v-flex>
              <v-flex class="xs6 px-1">
                <TextField
                  v-model="model.potvrdenie_dpn.pobyt_v_case_pn.adresa.street"
                  type="text"
                  :label="$vuetify.t('Ulica')"
                  name="ulica"
                  :disabled="!isNew || formReadOnly"
                ></TextField>
              </v-flex>
              <v-flex class="xs4 px-1">
                <TextField
                  v-model="
                    model.potvrdenie_dpn.pobyt_v_case_pn.adresa.building_num
                  "
                  type="text"
                  :label="$vuetify.t('Súpisné číslo')"
                  name="building_num"
                  :disabled="!isNew || formReadOnly"
                ></TextField>
              </v-flex>
              <v-flex class="xs2 px-1">
                <TextField
                  v-model="
                    model.potvrdenie_dpn.pobyt_v_case_pn.adresa.street_number
                  "
                  type="text"
                  :label="$vuetify.t('Orientačné číslo')"
                  name="street_number"
                  :disabled="!isNew || formReadOnly"
                >
                </TextField>
              </v-flex>
              <v-flex class="xs3 px-1">
                <TextField
                  v-model="model.potvrdenie_dpn.pobyt_v_case_pn.poschodie"
                  type="text"
                  :label="$vuetify.t('Poschodie')"
                  name="poschodie"
                  :disabled="!isNew || formReadOnly"
                ></TextField>
              </v-flex>
              <v-flex class="xs3 px-1">
                <TextField
                  v-model="model.potvrdenie_dpn.pobyt_v_case_pn.cislo_bytu"
                  type="text"
                  :label="$vuetify.t('Číslo bytu')"
                  name="cislo_bytu"
                  :disabled="!isNew || formReadOnly"
                ></TextField>
              </v-flex>
              <v-flex class="xs6">
                <v-textarea
                  v-model="model.potvrdenie_dpn.pobyt_v_case_pn.poznamka"
                  :label="$vuetify.t('Poznámka')"
                  rows="1"
                  :auto-grow="true"
                  :disabled="!isNew || formReadOnly"
                ></v-textarea>
              </v-flex>
            </template>

            <v-flex class="xs12">
              <v-system-bar>
                <v-card-title>
                  <span class="subheading">{{ $vuetify.t("Trvanie PN") }}</span>
                </v-card-title>
              </v-system-bar>
            </v-flex>
            <v-flex class="xs4 px-1">
              <DatePicker
                v-model="
                  model.potvrdenie_dpn.trvanie_pn
                    .datum_predpokladaneho_ukoncenia
                "
                :label="
                  $vuetify.t('Dátum predpokladaného ukončenia / kontroly')
                "
                name="datum_predpokladaneho_ukoncenia"
                v-validate="'required'"
                :disabled="formDisabled"
              ></DatePicker>
            </v-flex>
            <v-flex class="xs4 px-1">
              <v-textarea
                v-model="model.potvrdenie_dpn.trvanie_pn.poznamka"
                :label="$vuetify.t('Poznámka')"
                rows="1"
                :auto-grow="true"
                :disabled="formDisabled"
              ></v-textarea>
            </v-flex>
            <v-flex class="xs4 px-1">
              <DatePicker
                v-model="model.potvrdenie_dpn.schopny_prace_od"
                :label="$vuetify.t('Schopný práce od')"
                name="schopny_prace_od"
                :disabled="schopnyPraceOdDisabled"
              ></DatePicker>
            </v-flex>
            <v-flex class="xs12">
              <v-system-bar>
                <v-card-title>
                  <span class="subheading">{{ $vuetify.t("Kontakt") }}</span>
                </v-card-title>
              </v-system-bar>
            </v-flex>
            <v-flex class="xs4 px-1">
              <TextField
                v-model="model.potvrdenie_dpn.telefon"
                type="text"
                :label="$vuetify.t('Telefón')"
                name="telefon"
                :disabled="formDisabled"
              ></TextField>
            </v-flex>
            <v-flex class="xs12">
              <v-system-bar>
                <v-card-title>
                  <span class="subheading">{{ $vuetify.t("Vychádzky") }}</span>
                </v-card-title>
              </v-system-bar>
            </v-flex>
            <v-flex
              class="xs12"
              v-for="(item, index) in model.potvrdenie_dpn.vychadzky"
              :key="index"
            >
              <v-layout row>
                <v-flex grow>
                  <DatePicker
                    v-model="dateVychadzky"
                    :label="$vuetify.t('Povolená odo dňa')"
                    :disabled="formDisabled"
                    name="date_vychadzky"
                  >
                  </DatePicker>
                </v-flex>
                <v-flex pl-1 shrink>
                  <TimePicker
                    v-model="item.cas_od"
                    :label="$vuetify.t('Čas od')"
                    :disabled="formDisabled"
                  ></TimePicker>
                </v-flex>
                <v-flex pl-1 shrink>
                  <TimePicker
                    v-model="item.cas_do"
                    :label="$vuetify.t('Čas do')"
                    :disabled="formDisabled"
                  ></TimePicker>
                </v-flex>
                <v-flex>
                  <v-btn
                    fab
                    icon
                    small
                    outline
                    @click="model.potvrdenie_dpn.vychadzky.splice(index, 1)"
                  >
                    <v-icon>remove</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex v-show="!formDisabled" class="xs12 text-xs-right">
              <v-btn
                small
                @click="
                  model.potvrdenie_dpn.vychadzky.push({
                    datum_od: dateVychadzky,
                    cas_od: '08:00',
                    cas_do: '18:00',
                  })
                "
              >
                <span>{{ $vuetify.t("Pridať") }}</span>
              </v-btn>
            </v-flex>
            <v-flex class="xs12">
              <v-system-bar>
                <v-card-title>
                  <span class="subheading">{{
                    $vuetify.t("Výnimky z poistenia")
                  }}</span>
                </v-card-title>
              </v-system-bar>
            </v-flex>
            <template v-if="isNew">
              <v-flex
                class="xs12"
                v-for="(item, index) in model.potvrdenie_dpn
                  .vynimky_z_poistenia"
                :key="index"
              >
                <v-layout row>
                  <v-flex grow>
                    <v-autocomplete
                      v-model="item.cislo_poistenia"
                      :label="$vuetify.t('')"
                      :items="poistenia"
                      clearable
                      no-filter
                      item-value="id_socialne_poistenie"
                      :disabled="!isNew"
                    >
                      <template v-slot:selection="{ item }">
                        {{ item.odvadzatel.nazov }}
                      </template>
                      <template v-slot:item="{ item }">
                        <v-list-tile-content>
                          <v-list-tile-title>{{
                            item.odvadzatel.nazov
                          }}</v-list-tile-title>
                          <v-list-tile-sub-title>{{
                            item.typ_role
                          }}</v-list-tile-sub-title>
                        </v-list-tile-content>
                      </template>
                    </v-autocomplete>
                  </v-flex>
                  <v-flex shrink>
                    <v-btn
                      fab
                      icon
                      small
                      outline
                      :disabled="!isNew"
                      @click="
                        model.potvrdenie_dpn.vynimky_z_poistenia.splice(
                          index,
                          1
                        )
                      "
                    >
                      <v-icon>remove</v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>
                <div
                  v-for="(err, index) in errors.collect('vynimky_z_poistenia')"
                  :key="index"
                >
                  <span v-html="err" class="v-messages error--text"></span>
                </div>
              </v-flex>
              <v-flex v-show="isNew" class="xs12 text-xs-right">
                <v-btn small @click="addVynimka">
                  <span>{{ $vuetify.t("Pridať") }}</span>
                </v-btn>
              </v-flex>
            </template>
            <template v-else>
              <v-flex
                text-xs-center
                class="xs12"
                v-if="model.potvrdenie_dpn.vynimky_z_poistenia?.length > 0"
              >
                <v-btn
                  small
                  @click="fetchVynimky"
                  v-if="poistenia.length === 0"
                >
                  <span>{{
                    $vuetify.t(
                      "Načítať výnimky z EZdravia, počet: " +
                        model.potvrdenie_dpn.vynimky_z_poistenia.length
                    )
                  }}</span>
                </v-btn>
                <v-list dense v-else>
                  <v-list-tile v-for="(item, index) in poistenia" :key="index">
                    <v-list-tile-content>
                      <v-list-tile-title>{{
                        item.odvadzatel.nazov
                      }}</v-list-tile-title>
                      <v-list-tile-sub-title>{{
                        item.typ_role
                      }}</v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-flex>
            </template>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>
<script>
import { mapState } from "vuex"
import * as Miscella from "@/miscella.js"
import * as enums from "@/plugins/enums.js"
import BaseForm from "@/components/BaseForm.vue"
import CodebookAutocomplete from "@/components/ciselnikComponents/CodebookAutocomplete.vue"
import DatePicker from "@/components/DatePicker.vue"
import TimePicker from "@/components/TimePicker.vue"
import moment from "moment"
import TextField from "@/components/TextField.vue"
import CodebookService from "../../plugins/api/codebookService"
import EhealthRecord from "@/components/EhealthRecord.vue"

export default {
  name: "FormEDpn",
  extends: BaseForm,
  $_veeValidate: {
    validator: "new",
  },
  components: {
    CodebookAutocomplete,
    DatePicker,
    EhealthRecord,
    TextField,
    TimePicker,
  },
  mixins: [],
  data: function () {
    return {
      model: null,
      dialogDovodStorna: false,
      dovodStorna: "",
      formReadOnly: false,
      poistenia: [],
      dateVychadzky: null,
      schopnyPraceOdDisabled: false,
      liecbaVCudzineDisabled: false,
    }
  },
  computed: {
    ...mapState({
      pacient: (state) => state.pacient,
      //ambulance: (state) => state.me?.ambulance,
      ambulanceID: (state) => state.me?.ambulance?.id,
    }),
    isSlovak: function () {
      if (
        !Miscella.isSet(
          this.model.potvrdenie_dpn.pobyt_v_case_pn.adresa.country_cb
        )
      ) {
        return true
      }
      return (
        this.model.potvrdenie_dpn.pobyt_v_case_pn.adresa.country_cb.skratka ===
        "703"
      )
    },
    enums: function () {
      return enums
    },
    formDisabled: function () {
      let ret = false
      if (this.stav > 1) {
        ret = true
      }
      if (this.formReadOnly) {
        ret = true
      }
      return ret
    },
    hlaskaNeschopnyPraceOdDatum: function () {
      let ret = ""
      if (!this.isNew) {
        return ret
      }
      try {
        let today = moment().startOf("day")
        let d = moment(this.model.potvrdenie_dpn.neschopny_prace_od).startOf(
          "day"
        )
        let diff = d.diff(today, "days")
        if (diff !== 0 && this.model.potvrdenie_dpn.liecba_v_cudzine) {
          ret = "Vystavujete eDPN iba pre liečenie v cudzine."
        }
        if (!this.model.potvrdenie_dpn.liecba_v_cudzine) {
          if (diff < 0 && diff > -4) {
            ret =
              "Vystavujete eDPN spätne " +
              Math.abs(diff) +
              " dni v zmysle §12a ods. 5. zákona 576/2004 Z.z."
          }
          if (diff === 1) {
            ret =
              "Vystavujete eDPN od nasledujúceho dňa v zmysle §12a ods. 4 zákona 576/2004 Z.z."
          }
        }
      } catch (error) {
        console.error("watcher na neschopny prace od", error)
      }
      return ret
    },
    errorNeschopnyPraceOdDatum: function () {
      let ret = ""
      if (!this.isNew) {
        return ret
      }
      try {
        let today = moment().startOf("day")
        let d = moment(this.model.potvrdenie_dpn.neschopny_prace_od).startOf(
          "day"
        )
        let diff = d.diff(today, "days")
        if (!this.model.potvrdenie_dpn.liecba_v_cudzine) {
          if (diff < -3) {
            ret = "Nie je možné vystaviť eDPN viac ako 3 dni do minulosti."
          }
          if (diff > 1) {
            ret = "Nie je možné vystaviť eDPN viac ako 1 deň do budúcnosti."
          }
        }
      } catch (error) {
        console.error("errorNeschopnyPraceOdDatum", error)
      }
      return ret
    },
    //Ak diagnoza.Datum_od > dátum zmeny - upozornenie lekára, že mení eDPN s platnosťou diagnózy mimo doterajšieho trvania eDPN
    hlaskaDiagnozaOdDatum: function () {
      let ret = ""
      try {
        if (
          !this.isNew &&
          Miscella.isSet(this.model.potvrdenie_dpn.diagnoza.platnost_od)
        ) {
          let today = moment().startOf("day")
          let d = moment(
            this.model.potvrdenie_dpn.diagnoza.platnost_od
          ).startOf("day")
          let diff = d.diff(today, "days")
          if (diff > 0) {
            ret =
              "Meníte eDPN s platnosťou diagnózy mimo doterajšieho trvania eDPN."
          }
        }
      } catch (error) {
        console.error("hlaskaDiagnozaOdDatum", error)
      }
      return ret
    },
    hlaskaDovodOdDatum: function () {
      let ret = ""
      try {
        if (
          !this.isNew &&
          Miscella.isSet(this.model.potvrdenie_dpn.dovod_pn.platnost_od)
        ) {
          let today = moment().startOf("day")
          let d = moment(
            this.model.potvrdenie_dpn.dovod_pn.platnost_od
          ).startOf("day")
          let diff = d.diff(today, "days")
          if (diff > 0) {
            ret =
              "Meníte eDPN s platnosťou dôvodu mimo doterajšieho trvania eDPN."
          }
        }
      } catch (error) {
        console.error("hlaskaDovodOdDatum", error)
      }
      return ret
    },

    // 0 - New
    // 1 - Platna, ulozena
    // 2 - Ukončená
    // 3 - Stornovaná
    stav: function () {
      let s = 0
      let v = this.model.potvrdenie_dpn?.header?.version_status
      if (v === "VER01") {
        s = 1
      }
      if (v === "VER04") {
        s = 3
      }
      return s
    },
    stavString: function () {
      let s = ""
      switch (this.stav) {
        case 0:
          s = this.$vuetify.t("Nová")
          break
        case 1:
          s = this.$vuetify.t("Platná, uložená v EZdraví")
          break
        case 2:
          s = this.$vuetify.t("Ukončená")
          break
        case 3:
          s = this.$vuetify.t("Stornovaná")
          break
        default:
          break
      }
      return s
    },
    isNew: function () {
      return this.stav === 0
    },
  },
  props: {
    patientId: String,
  },
  watch: {
    "model.potvrdenie_dpn.schopny_prace_od": {
      deep: true,
      handler(newVal, oldVal) {
        try {
          console.log("watch model.potvrdenie_dpn.schopny_prace_od", newVal)
          console.log("watch model.potvrdenie_dpn.schopny_prace_od", oldVal)
          console.log(
            "watch model.potvrdenie_dpn.schopny_prace_od",
            Miscella.isSet(this.model.potvrdenie_dpn.schopny_prace_od)
          )
          if (Miscella.isSet(this.model.potvrdenie_dpn.schopny_prace_od)) {
            if (this.model.potvrdenie_dpn.schopny_prace_od === "") {
              console.log(
                "watch model.potvrdenie_dpn.schopny_prace_od je prazdny string"
              )
              return
            }
            let d = moment(this.model.potvrdenie_dpn.schopny_prace_od)
            console.log("setting datum_predpokladaneho_ukoncenia")
            this.model.potvrdenie_dpn.trvanie_pn.datum_predpokladaneho_ukoncenia =
              d.add(-1, "days").format()
          } else {
            //console.log("setting datum_predpokladaneho_ukoncenia to null")
            //this.model.potvrdenie_dpn.trvanie_pn.datum_predpokladaneho_ukoncenia = null
          }
        } catch (error) {
          console.error("Watcher schopny_prace_od", datum, error)
        }
      },
    },
  },
  methods: {
    new: async function (datum) {
      this.init()
      this.model.potvrdenie_dpn.datum_vystavenia = datum
      if (this.pacient.address) {
        this.model.potvrdenie_dpn.pobyt_v_case_pn.adresa = Miscella.cloneObject(
          this.pacient.address
        )
      }
      if (this.pacient.phone) {
        this.model.potvrdenie_dpn.telefon = Miscella.cloneObject(
          this.pacient.phone
        )
      }
      let d = await Miscella.getCodebookItemByString(
        this.$api.codebookService,
        enums.CodebookIDDovodPN,
        "Choroba"
      )
      this.model.potvrdenie_dpn.dovod_pn.dovod = d //{code:"02089917385",display_name:"Choroba",skratka:"08",popis:"Choroba",codebook_id:"265"}
      this.model.potvrdenie_dpn.neschopny_prace_od = new moment()
      this.isDirty = false
    },
    edit: async function (id, formDisabled) {
      console.log("FORMEDPN.VUE, edit", id, formDisabled)
      this.init()
      this.startAppLoading("Prebieha komunikácia s EZdravím.")
      this.formReadOnly = formDisabled
      try {
        let res = await this.$api.ehealthService.dajPotvrdenieDPN({
          ambulance_id: this.model.ambulance_id,
          patient_id: this.model.patient_id,
          id_potvrdenia_dpn: id,
        })
        if (res != null) {
          this.model.potvrdenie_dpn = res.potvrdenie_dpn
          for (
            let i = 0;
            i < this.model.potvrdenie_dpn.vychadzky?.length;
            i++
          ) {
            let vychadzka = this.model.potvrdenie_dpn.vychadzky[i]
            this.dateVychadzky = vychadzka.datum_od
            vychadzka.datum_od = this.dateVychadzky
          }
          this.setSchopnyPraceOdDisabled()
        }
      } catch (error) {
        console.error("chyba pri načítaní PN", error)
      }
      this.isDirty = false
      this.stopAppLoading()
    },
    editFromObject: async function (item) {
      console.log("FORMEDPN.VUE, editFromObject", item)
      this.init()
      this.formReadOnly = true //radsej to dam readonly, nech sa da PN iba vytlacit
      let res = JSON.parse(item.object)
      console.log("FORMEDPN.VUE, editFromObject", res.potvrdenie_dpn)
      this.model.potvrdenie_dpn = res.potvrdenie_dpn
      for (let i = 0; i < this.model.potvrdenie_dpn.vychadzky?.length; i++) {
        let vychadzka = this.model.potvrdenie_dpn.vychadzky[i]
        this.dateVychadzky = vychadzka.datum_od
        vychadzka.datum_od = this.dateVychadzky
      }
      this.setSchopnyPraceOdDisabled()
      this.isDirty = false
    },
    predlzPN: async function (oldPN) {
      console.log("Predlzenie PN START", oldPN)
      let datumTeraz = moment().toDate()
      await this.new(datumTeraz)
      //z povodnej PN sa prevezme liecba v cudzine a datum ukoncenia sa skopiruje do datum neschopny od
      this.model.potvrdenie_dpn.liecba_v_cudzine = oldPN.liecba_v_cudzine
      console.log("Predlzenie PN liecba_v_cudzine", oldPN.liecba_v_cudzine)
      this.model.potvrdenie_dpn.neschopny_prace_od = oldPN.schopny_prace_od
      console.log("Predlzenie PN neschopny_prace_od", oldPN.schopny_prace_od)
      this.liecbaVCudzineDisabled = true
      this.isDirty = false
    },
    setSchopnyPraceOdDisabled: function () {
      this.schopnyPraceOdDisabled = false
      if (
        !this.isNew &&
        this.model.potvrdenie_dpn.schopny_prace_od !== null &&
        this.model.potvrdenie_dpn.schopny_prace_od !== ""
      ) {
        this.schopnyPraceOdDisabled = true
      }
      if (this.formDisabled) {
        this.schopnyPraceOdDisabled = true
      }
    },
    init: function () {
      this.model = this.clearModel()
      this.dialogDovodStorna = false
      this.dovodStorna = ""
      this.formReadOnly = false
      this.poistenia = []
      this.schopnyPraceOdDisabled = false
      this.liecbaVCudzineDisabled = false
      this.dateVychadzky = null
      this.$validator.reset()
      this.errors.clear()
    },
    clearModel: function () {
      var model = {
        id: null,
        ambulance_id: this.ambulanceID,
        patient_id: this.patientId,
        potvrdenie_dpn: {
          liecba_v_cudzine: false,
          datum_vystavenia: null,
          neschopny_prace_od: null,
          schopny_prace_od: null,
          poznamka: "",
          trvanie_pn: {
            datum_predpokladaneho_ukoncenia: null,
            poznamka: "",
          },
          dovod_pn: {
            dovod: null,
            pozitie_alkoholu: null,
            platnost_od: null,
          },
          telefon: "",
          pobyt_v_case_pn: {
            poschodie: "",
            cislo_bytu: "",
            poznamka: "",
            adresa: {
              street: "",
              street_number: "",
              building_num: "",
              postal_code: "",
              city: "",
              county: "",
              region: "",
              country: "",
              city_cb: null,
              county_cb: null,
              region_cb: null,
              country_cb: null,
              postal_code_cb: null,
            },
            datum_platnosti_od: null,
          },
          vychadzky: [],
          /*
          vychadzky: [
            {
              datum_od: null,
              cas_od: null,
              cas_do: null
            }
          ],*/
          vynimky_z_poistenia: [],
          diagnoza: {
            diagnoza: null,
            platnost_od: null,
          },
        },
        ehealth_record: {
          status: 0,
          version_status: 0,
        },
      }
      return model
    },
    save: async function () {
      console.log("FormEDpn save")
      // this.$validator.reset()
      try {
        let isValid = await this.validate()
        console.log("FORMEDPN.VUE, save", isValid)
        if (isValid) {
          if (this.isSlovak) {
            this.model.potvrdenie_dpn.pobyt_v_case_pn.adresa.city = ""
            this.model.potvrdenie_dpn.pobyt_v_case_pn.adresa.postal_code = ""
          } else {
            this.model.potvrdenie_dpn.pobyt_v_case_pn.adresa.city_cb = null
            this.model.potvrdenie_dpn.pobyt_v_case_pn.adresa.postal_code_cb =
              null
          }
          //Tu sa upravuje object na odoslanie, neprepisovat model!
          let potvrdenie_dpn = Miscella.cloneObject(this.model.potvrdenie_dpn)
          //ak nejde o prve odoslanie, adresa sa nema posielat
          if (!this.isNew) {
            delete potvrdenie_dpn.pobyt_v_case_pn
          }
          /*
          console.log("potvrdenie_dpn.poznamka", potvrdenie_dpn.poznamka)
          if (potvrdenie_dpn.poznamka === "") {
            potvrdenie_dpn.poznamka = "."
          }*/
          for (let i = 0; i < potvrdenie_dpn.vychadzky.length; i++) {
            let vychadzka = potvrdenie_dpn.vychadzky[i]
            vychadzka.datum_od = this.dateVychadzky
            console.log(
              "setting datumVychadzky",
              vychadzka.datum_od,
              this.dateVychadzky
            )
          }
          //ak nejde o prve odoslanie, vynimky z poistenia sa nemaju posielat
          /*
          if (!this.isNew) {
            delete potvrdenie_dpn.vynimky_z_poistenia
          }
          */
          this.startAppLoading(
            "Prebieha komunikácia s EZdravím.",
            potvrdenie_dpn
          )
          let res = await this.$api.ehealthService.zapisPotvrdenieDPN({
            ambulance_id: this.model.ambulance_id,
            patient_id: this.model.patient_id,
            potvrdenie_dpn: potvrdenie_dpn,
          })
          this.model.id = res.id
          this.model.ehealth_record = {}
          this.model.ehealth_record.status = 0
          this.model.ehealth_record.version_status = 0
          this.notifySuccess("DPN sa úspešne začalo odosielať do ezdravia")
          /*
          if (res != null) {
            if (res.result.code === "0") {
              //zapisane
              console.log("FORMEDPN.VUE, save", res)
              this.notifySuccess("DPN bola úspešne odoslaná do ezdravia")
              this.$emit("saved")
              this.isDirty = false
            } else {
              let ret = ""
              if (res.result.exception) {
                ret =
                  res.result.exception.kod_chyby +
                  "/" +
                  res.result.exception.nazov
              }
              if (res.message) {
                ret = ret + "." + res.message
              }
              throw new Error(ret)
            }
          }
          */
        } else {
          console.log("FORMEDPN.VUE, validate found errors", this.errors)
        }
      } catch (error) {
        this.processError("Pri odosielaní DPN vznikla chyba " + error)
        console.log("pri ukladani DPN vznikla chyba", error)
      } finally {
        this.stopAppLoading()
      }
      console.log("FormEDpn save FINISHED")
    },
    validate: async function () {
      try {
        console.log("FormEDpn validate")
        this.errors.clear()
        console.log("FormEDpn validate 01")
        await this.$validator.validateAll()
        console.log("FormEDpn validate 02")
        // miesto na dalsie custom validacie
        //ak nejde o liecbu v cudzine, tak neschopny_prace_od moze byt maximalne 3 dni dozadu a 1 den dopredu
        let today = moment().startOf("day")
        if (this.isNew && !this.model.potvrdenie_dpn.liecba_v_cudzine) {
          let neschopny_prace_od = moment(
            this.model.potvrdenie_dpn.neschopny_prace_od
          ).startOf("day")
          let diff = neschopny_prace_od.diff(today, "days")
          if (diff < -3 || diff > 1) {
            console.log("FormEDpn validate 03a, error neschopny_prace_od")
            this.errors.add({
              field: "neschopny_prace_od",
              msg: this.$vuetify.t(
                "Dátum je možné zadať maximálne 3 kalendárne dni spätne a jeden kalendárny deň do budúcnosti (" +
                  diff +
                  ")."
              ),
            })
          }
        }
        //dátum vychádzky nemoze byt pred datumom zmeny, t.j. dnes
        if (this.model.potvrdenie_dpn.vychadzky.length > 0) {
          let momentDateVychadzky = moment(this.dateVychadzky).startOf("day")
          let diff = momentDateVychadzky.diff(today, "days")
          if (diff < 0) {
            console.log("FormEDpn validate 03b, error dateVychadzky", diff)
            this.errors.add({
              field: "date_vychadzky",
              msg: this.$vuetify.t(
                "Dátum vychádzky nie je možné zadať spätne do minulosti."
              ),
            })
          }
          //Dátum vychádzok nesmie byť väčší ako dátum predpokladaného ukončenia DPN
          let datumPredpokladanehoUkoncenia =
            this.model.potvrdenie_dpn.trvanie_pn.datum_predpokladaneho_ukoncenia
          if (Miscella.isSet(datumPredpokladanehoUkoncenia)) {
            try {
              let momentDatumPredpokladanehoUkoncenia = moment(
                datumPredpokladanehoUkoncenia
              ).startOf("day")
              diff = momentDateVychadzky.diff(
                momentDatumPredpokladanehoUkoncenia,
                "days"
              )
              console.log(
                "FormEDpn validate, error datum_predpokladaneho_ukoncenia",
                diff
              )
              if (diff > 0) {
                console.log(
                  "FormEDpn validate, error datum_predpokladaneho_ukoncenia",
                  diff
                )
                this.errors.add({
                  field: "date_vychadzky",
                  msg: this.$vuetify.t(
                    "Dátum vychádzok nesmie byť väčší ako dátum predpokladaného ukončenia DPN."
                  ),
                })
              }
            } catch (error) {}
          }
        }
        //Dátum ukončenia platnosti nemôže byť pred dátumom zmeny.
        let momentPredpUkoncenia = moment(
          this.model.potvrdenie_dpn.trvanie_pn.datum_predpokladaneho_ukoncenia
        ).startOf("day")
        let diff = momentPredpUkoncenia.diff(today, "days")
        console.log("FormEDpn validate 03b, error momentPredpUkoncenia", diff)
        if (diff < 0) {
          console.log("FormEDpn validate 03b, error momentPredpUkoncenia", diff)
          this.errors.add({
            field: "datum_predpokladaneho_ukoncenia",
            msg: this.$vuetify.t(
              "Dátum ukončenia platnosti nemôže byť pred dátumom zmeny."
            ),
          })
        }
        //Dátum predpokladaného ukončenia nemoze byt pred datum zacatia - neschopny od
        let datumNeschopnyPraceOd = this.model.potvrdenie_dpn.neschopny_prace_od
        if (Miscella.isSet(datumNeschopnyPraceOd)) {
          try {
            let momentDatumNeschopnyPraceOd = moment(
              datumNeschopnyPraceOd
            ).startOf("day")
            diff = momentPredpUkoncenia.diff(
              momentDatumNeschopnyPraceOd,
              "days"
            )
            console.log(
              "FormEDpn validate, error datum_predpokladaneho_ukoncenia",
              diff
            )
            if (diff < 0) {
              console.log(
                "FormEDpn validate, error datum_predpokladaneho_ukoncenia",
                diff
              )
              this.errors.add({
                field: "datum_predpokladaneho_ukoncenia",
                msg: this.$vuetify.t(
                  "Dátum ukončenia platnosti nemôže byť pred dátumom začatia PN."
                ),
              })
            }
          } catch (error) {}
        }
        //Dátum platnosti diagnózy na novej eDPN musí byť zhodný s dátumom práceneschopný od.
        if (
          this.isNew &&
          this.model.potvrdenie_dpn.diagnoza.platnost_od !== null
        ) {
          let dDiagnozaOd = moment(
            this.model.potvrdenie_dpn.diagnoza.platnost_od
          ).startOf("day")
          let neschopnyPraceOd = moment(
            this.model.potvrdenie_dpn.neschopny_prace_od
          ).startOf("day")
          let diff = neschopnyPraceOd.diff(dDiagnozaOd, "days")
          if (diff != 0) {
            console.log(
              "FormEDpn validate 03c, error diagnoza.platnost_od",
              diff
            )
            this.errors.add({
              field: "diagnoza_platnost_od",
              msg: this.$vuetify.t(
                "Dátum platnosti diagnózy na novej eDPN musí byť zhodný s dátumom Neschopný práce od."
              ),
            })
          }
        }
        //Dátum platnosti dôvodu DPN na novej eDPN musí byť zhodný s dátumom práceneschopný od
        if (
          this.isNew &&
          this.model.potvrdenie_dpn.dovod_pn.platnost_od !== null
        ) {
          let dDovodOd = moment(
            this.model.potvrdenie_dpn.dovod_pn.platnost_od
          ).startOf("day")
          let neschopnyPraceOd = moment(
            this.model.potvrdenie_dpn.neschopny_prace_od
          ).startOf("day")
          let diff = neschopnyPraceOd.diff(dDovodOd, "days")
          if (diff != 0) {
            console.log("FormEDpn validate 03c, error dovod.platnost_od", diff)
            this.errors.add({
              field: "dovod_platnost_od",
              msg: this.$vuetify.t(
                "Dátum platnosti dôvodu DPN na novej eDPN musí byť zhodný s dátumom Neschopný práce od."
              ),
            })
          }
        }
        //pri editovani existujucej PN
        //Ak diagnoza.Datum_od < dátum práceneschopný od - chyba "Dátum platnosti diagnózy nesmie byť pred začiatkom eDPN"
        if (
          !this.isNew &&
          Miscella.isSet(this.model.potvrdenie_dpn.diagnoza.platnost_od)
        ) {
          let dDiagnozaOd = moment(
            this.model.potvrdenie_dpn.diagnoza.platnost_od
          ).startOf("day")
          let neschopnyPraceOd = moment(
            this.model.potvrdenie_dpn.neschopny_prace_od
          ).startOf("day")
          let diff = neschopnyPraceOd.diff(dDiagnozaOd, "days")
          if (diff > 0) {
            console.log("FormEDpn validate, error diagnoza.platnost_od 2", diff)
            this.errors.add({
              field: "diagnoza_platnost_od",
              msg: this.$vuetify.t(
                "Dátum platnosti diagnózy nesmie byť pred začiatkom eDPN."
              ),
            })
          }
        }
        //pri editovani existujucej PN
        //Ak dovod.Datum_od < dátum práceneschopný od - chyba "Dátum platnosti dôvodu DPN nesmie byť pred začiatkom eDPN"
        if (
          !this.isNew &&
          Miscella.isSet(this.model.potvrdenie_dpn.dovod_pn.platnost_od)
        ) {
          let dDovodOd = moment(
            this.model.potvrdenie_dpn.dovod_pn.platnost_od
          ).startOf("day")
          let neschopnyPraceOd = moment(
            this.model.potvrdenie_dpn.neschopny_prace_od
          ).startOf("day")
          let diff = neschopnyPraceOd.diff(dDovodOd, "days")
          if (diff > 0) {
            console.log("FormEDpn validate, error dovod.platnost_od 2", diff)
            this.errors.add({
              field: "dovod_platnost_od",
              msg: this.$vuetify.t(
                "Dátum platnosti dôvodu DPN nesmie byť pred začiatkom eDPN."
              ),
            })
          }
        }
        //Príznak požitie alkoholu alebo zneužitie iných návykových látok nebol zadaný, eDPN nie je možné vytvoriť
        //Pre dôvod DPN úraz alebo pracovný úraz
        let dovod_pn = this.model.potvrdenie_dpn.dovod_pn.dovod
        let pozitie = this.model.potvrdenie_dpn.dovod_pn.pozitie_alkoholu
        if (Miscella.isSet(dovod_pn)) {
          let dovod_pn_text = dovod_pn.display_name.toUpperCase()
          if (dovod_pn_text.includes("ÚRAZ")) {
            if (!Miscella.isSet(pozitie)) {
              console.log("FormEDpn validate 03d, error pozitie_alkoholu")
              this.errors.add({
                field: "pozitie_alkoholu",
                msg: this.$vuetify.t(
                  "Príznak požitie alkoholu alebo zneužitie iných návykových látok nebol zadaný, eDPN nie je možné vytvoriť."
                ),
              })
            }
          }
        }
        if (this.isNew) {
          if (
            !Miscella.isSet(
              this.model.potvrdenie_dpn.pobyt_v_case_pn.adresa.country_cb
            )
          ) {
            console.log("FormEDpn validate 03a, error country_cb")
            this.errors.add({
              field: "country_cb",
              msg: this.$vuetify.t("Krajina je povinná položka."),
            })
          }
        }
        //ak ide o slovensku adresu, je mesto povinne
        if (this.isSlovak && this.isNew) {
          if (
            !Miscella.isSet(
              this.model.potvrdenie_dpn.pobyt_v_case_pn.adresa.city_cb
            )
          ) {
            console.log("FormEDpn validate 03a, error city_cb")
            this.errors.add({
              field: "city_cb",
              msg: this.$vuetify.t(
                "Pri slovenskej adrese je mesto povinná položka."
              ),
            })
          }
        }
        //nemôžu byť vybrané VŠETKY poistne vztahy ako vynimky z poistenia
        //vynimky_z_poistenia
        if (this.isNew) {
          if (this.model.potvrdenie_dpn.vynimky_z_poistenia.length > 0) {
            if (this.poistenia.length > 0) {
              if (
                this.poistenia.length ===
                this.model.potvrdenie_dpn.vynimky_z_poistenia.length
              ) {
                console.log("FormEDpn validate, error vynimky_z_poistenia")
                this.errors.add({
                  field: "vynimky_z_poistenia",
                  msg: this.$vuetify.t(
                    "Vylúčenie všetkých poistných vzťahov z eDPN je neštandardné. EDPN bude odoslaná do Sociálnej poisťovne, ktorá situáciu vyhodnotí a ak nenájde žiadny nevylúčený vzťah, eDPN nemá opodstatnenie."
                  ),
                })
              }
            }
          }
        }
        return !this.errors.any()
      } catch (error) {
        console.log("FORMEDPN.VUE, validate throw error", error)
        return false
      }
    },
    storno: async function () {
      console.log("FormEDpn storno")
      try {
        //ak je ukoncena, spytat sa
        if (
          this.model.potvrdenie_dpn.schopny_prace_od !== null &&
          this.model.potvrdenie_dpn.schopny_prace_od !== ""
        ) {
          let doStorno = await this.$confirm(
            this.$vuetify.t(
              "Naozaj chcete stornovať ePN? Táto ePN je ukončená."
            )
          )
          if (!doStorno) {
            return
          }
        }
        let doStorno = await this.$confirm(
          this.$vuetify.t(
            "Naozaj chcete stornovať ePN? Tento úkon sa má používať len na administratívne účely, ak lekár zapísal záznam na nesprávneho pacienta alebo lekár nesprávne vybral z číselníka príslušnú hodnotu atribútu, ktorý nie je možné zmeniť."
          )
        )
        if (!doStorno) {
          return
        }
        let today_month = moment().month()
        let datum_vystavenia_month = moment(
          this.model.potvrdenie_dpn.datum_vystavenia
        ).month()
        if (datum_vystavenia_month !== today_month) {
          doStorno = await this.$confirm(
            this.$vuetify.t(
              "Naozaj chcete vykonať storno ePN, na ktorej už mohli byť vyplatené nemocenské dávky? Vzniká riziko preplatku na dávke a možnosť vyvodenia právnej zodpovednosti za jeho vznik."
            )
          )
          if (!doStorno) {
            return
          }
        }
        let ret = this.askForDovod()
        if (ret === null || ret === "") {
          return
        }
        this.startAppLoading("Prebieha komunikácia s EZdravím.")
        let res = await this.$api.ehealthService.stornujPotvrdenieDPN({
          ambulance_id: this.model.ambulance_id,
          patient_id: this.model.patient_id,
          rc_id: this.model.potvrdenie_dpn.header.rc_id,
          version_set_id: this.model.potvrdenie_dpn.header.version_set_id,
          dovod_storna: ret,
        })
        if (res != null) {
          if (res.result.code === "0") {
            //zapisane
            console.log("FORMEDPN.VUE, storno", res)
            this.notifySuccess("Storno DPN bolo úspešne odoslané do ezdravia")
            this.$emit("saved")
            this.isDirty = false
          } else {
            let ret = ""
            if (res.result.exception) {
              ret =
                res.result.exception.kod_chyby +
                "/" +
                res.result.exception.nazov
            }
            if (res.message) {
              ret = ret + "." + res.message
            }
            throw new Error(ret)
          }
        }
      } catch (error) {
        this.processError("Pri odosielaní storna DPN vznikla chyba " + error)
        console.log("pri ukladani DPN vznikla chyba", error)
      } finally {
        this.stopAppLoading()
      }
      console.log("FormEDpn storno FINISHED")
    },
    askForDovod: function () {
      let ret = prompt("Zadajte dôvod storna, text je povinný.")
      return ret
    },
    addVynimka: async function () {
      let doContinue = await this.$confirm(
        this.$vuetify.t(
          "Prehliadať údaje o pracovno-právnych vzťahoch je oprávnený výlučne zdravotnícky pracovník, na základe požiadavky pacienta, za účelom vylúčenia pracovnoprávneho vzťahu u vybraného zamestnávateľa, v príčinnej súvislosti s poskytovaním zdravotnej starostlivosti osobe, ktorej sa potvrdenie o dočasnej pracovnej neschopnosti vystavuje. Zdravotnícky pracovník uvedené berie na vedomie a je si vedomý možných trestnoprávnych následkov, v prípade ak sa preukáže opak. Chcete pokračovať?"
        )
      )
      if (!doContinue) {
        return
      }
      try {
        //
        if (this.poistenia.length === 0) {
          this.startAppLoading("Prebieha komunikácia s EZdravím.")
          let res = await this.$api.ehealthService.vyhladajPoisteniaSP({
            ambulance_id: this.model.ambulance_id,
            patient_id: this.model.patient_id,
          })
          if (res != null) {
            if (res.result.code === "0") {
              console.log("FORMEDPN.VUE, addVynimka", res)
              this.poistenia = res.poistenia
            } else {
              let ret = ""
              if (res.result.exception) {
                ret =
                  res.result.exception.kod_chyby +
                  "/" +
                  res.result.exception.nazov
              }
              if (res.message) {
                ret = ret + "." + res.message
              }
              throw new Error(ret)
            }
          }
        }
        this.model.potvrdenie_dpn.vynimky_z_poistenia.push({
          cislo_poistenia: "",
        })
      } catch (error) {
        this.processError("Pri komunikácii s NZIS vznikla chyba " + error)
        console.log("Pri komunikácii s NZIS vznikla chyba", error)
      } finally {
        this.stopAppLoading()
      }
    },
    print: function (item) {
      console.log("FORMEDPN, print", this.model.potvrdenie_dpn)
      this.$store.commit("setThing", this.model.potvrdenie_dpn)
      localStorage.setItem("thing", JSON.stringify(this.model.potvrdenie_dpn))
      localStorage.setItem("user", JSON.stringify(this.$store.state.me.user))
      let route = this.$router.resolve({
        path: "/print/dpn",
        query: {
          patientId: this.patientId,
          clinicId: this.$store.state.me.clinic.id,
        },
      })
      window.open(route.href, "_blank")
    },
    fetchVynimky: async function () {
      try {
        this.startAppLoading("Prebieha komunikácia s EZdravím.")
        let poisteniaIDs = []
        this.model.potvrdenie_dpn.vynimky_z_poistenia.forEach((item) =>
          poisteniaIDs.push(item.cislo_poistenia)
        )
        let res = await this.$api.ehealthService.vyhladajPoisteniaSP({
          ambulance_id: this.model.ambulance_id,
          patient_id: this.model.patient_id,
          id_socialne_poistenie: poisteniaIDs,
        })
        if (res != null) {
          if (res.result.code === "0") {
            console.log("FORMEDPN.VUE, addVynimka", res)
            this.poistenia = res.poistenia
          } else {
            let ret = ""
            if (res.result.exception) {
              ret =
                res.result.exception.kod_chyby +
                "/" +
                res.result.exception.nazov
            }
            if (res.message) {
              ret = ret + "." + res.message
            }
            throw new Error(ret)
          }
        }
      } catch (error) {
        this.processError("Pri komunikácii s NZIS vznikla chyba " + error)
        console.log("Pri komunikácii s NZIS vznikla chyba", error)
      } finally {
        this.stopAppLoading()
      }
    },
  },
  created: function () {
    this.init()
  },
}
</script>
