<template>
  <form-editor ref="formEditor" :form-definition="model" />
</template>

<script>
import * as Miscella from "./../../miscella.js"
import FormEditor from "@/components/axonforms/FormEditor.vue"

export default {
  components: {
    FormEditor,
  },
  data: function () {
    return {
      model: null,
    }
  },
  methods: {
    init: function () {
      this.model = {
        polozky: [],
      }
    },
    new: function () {
      this.init()
    },
    edit: function (item) {
      this.init()
      this.model = Miscella.cloneObject(item)
    },
    save: function () {
      if (!this.$refs.formEditor.validate()) {
        return
      }
      this.model.ambulance_id = this.ambulanceID
      this.transformPolozky(this.model.polozky)
      var params = { form: this.model }
      console.log("save", params)
      return this.$api.formService
        .saveForm(params)
        .then((result) => {
          this.notifySuccess("Formulár bol úspešne uložený")
          this.model = result.form
          this.$emit("saved", this.model)
        })
        .catch((error) => {
          console.error(error)
          this.processError(error, "Pri ukladaní formuláru vznikla chyba")
        })
    },
    transformPolozky: function (polozky) {
      for (let i = 0; i < polozky.length; i++) {
        let polozka = polozky[i]
        for (const key in polozka.params) {
          if (!isNaN(polozka.params[key])) {
            if (polozka.params[key] != null) {
              polozka.params[key] = polozka.params[key].toString()
            } else {
              polozka.params[key] = "null"
            }
          }
        }
      }
    },
  },
  created: function () {
    this.init()
  },
}
</script>
