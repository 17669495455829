<template>
  <v-form>
    <v-container fluid>
      <v-layout row>
        <v-flex xs12 md6>
          <DatePicker
            v-model="model.date"
            :label="$vuetify.t('Dátum')"
            name="datum"
            v-validate="'required'"
          ></DatePicker>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs12 md6>
          <TextField
            v-model="model.vit_antrop_hodnoty.hmotnost_hodnota"
            type="number"
            :label="$vuetify.t('Hmotnosť')"
            name="hmotnostHodnota"
            v-validate="'numeric'"
          ></TextField>
        </v-flex>
        <v-flex xs12 md6>
          <codebook-autocomplete
            :codebook-id="enums.CodebookIDMerneJednotky"
            name="hmotnostJednotka"
            v-model="model.vit_antrop_hodnoty.hmotnost_jednotka"
            :label="$vuetify.t('jednotka')"
            :custom-params="{
              in_filter: { attr: 'Skratka', values: ['kg', 'g'] },
            }"
          >
          </codebook-autocomplete>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs12 md6>
          <TextField
            v-model="model.vit_antrop_hodnoty.vyska_hodnota"
            type="number"
            :label="$vuetify.t('Výška')"
            name="vyskaHodnota"
            v-validate="'numeric'"
          ></TextField>
        </v-flex>
        <v-flex xs12 md6>
          <codebook-autocomplete
            :codebook-id="enums.CodebookIDMerneJednotky"
            name="vyskaJednotka"
            v-model="model.vit_antrop_hodnoty.vyska_jednotka"
            :label="$vuetify.t('jednotka')"
            :custom-params="{
              in_filter: { attr: 'Skratka', values: ['cm', 'mm'] },
            }"
          >
          </codebook-autocomplete>
        </v-flex>
      </v-layout>
      <v-flex xs12>
        <v-textarea
          v-model="model.vit_antrop_hodnoty.poznamka"
          :label="$vuetify.t('Poznámka')"
        ></v-textarea>
      </v-flex>
    </v-container>
  </v-form>
</template>

<script>
import BaseForm from "./../BaseForm.vue"
import moment from "moment"
import * as Miscella from "./../../miscella.js"
import TextField from "./../TextField.vue"
import DatePicker from "./../DatePicker.vue"
import CodebookAutocomplete from "./../ciselnikComponents/CodebookAutocomplete.vue"
import * as enums from "@/plugins/enums.js"

export default {
  name: "FormPacientKrvntTlak",
  extends: BaseForm,
  components: {
    DatePicker,
    CodebookAutocomplete,
    TextField,
  },
  props: {
    pacientId: {
      type: String,
      required: true,
    },
  },
  $_veeValidate: {
    validator: "new", // give me my own validator scope.
  },
  data: function () {
    return {
      model: null,
    }
  },
  computed: {
    ambulanceId: function () {
      let id = null
      if (Miscella.isSet(this.$store.state.me.ambulance)) {
        id = this.$store.state.me.ambulance.id
      }
      return id
    },
    enums: function () {
      return enums
    },
  },
  watch: {},
  methods: {
    new: function () {
      this.init()
      this.$emit("activated")
    },
    edit: function (item) {
      this.init()
      this.model = Miscella.cloneObject(item)
      this.$emit("activated")
    },
    init: function () {
      console.log("init")
      this.model = {
        id: "",
        patient_id: this.pacientId,
        ambulance_id: this.ambulanceId,
        date: moment().format(),
        parent_id: "",
        vit_antrop_hodnoty: {
          hmotnost_hodnota: null,
          hmotnost_jednotka: null,
          vyska_hodnota: null,
          vyska_jednotka: null,
          poznamka: null,
        },
      }

      this.$validator.reset()
      this.errors.clear()
    },
    validate: async function () {
      return this.$validator.validateAll()
    },
    save: async function () {
      try {
        var params = { thing: Miscella.cloneObject(this.model) }
        var result = await this.$api.thingService.saveThing(params)
        this.notifySuccess(
          "Meranie vitálnych a antropometrických hodnôt bolo úspešne uložené"
        )
        this.$emit("saved", result.thing)
        this.model = result.thing
      } catch (err) {
        this.processError(
          err,
          "Pri ukladaní vitálnych a antropometrických hodnôt vznikla chyba"
        )
      }
    },
  },
  mounted: function () {},
  created: function () {
    this.init()
  },
}
</script>
