<template>
  <v-form>
    <v-container fluid>
      <v-layout row wrap>
        <v-flex xs12>
          <TextField
            v-model="form.email"
            name="form.email"
            ref="emailField"
            v-validate="'required|email'"
            :maxlength="100"
            :label="$vuetify.t('E-mail')"
            autocomplete="username"
          ></TextField>
        </v-flex>
        <v-flex xs12>
          <v-autocomplete
            v-model="form.ambulanceIDs"
            :label="$vuetify.t('Ambulancie')"
            :items="ambulances"
            name="form.ambulanceIDs"
            :error-messages="errors.collect('form.ambulanceIDs')"
            hide-no-data
            clearable
            item-text="name"
            item-value="id"
            multiple
            chips
            deletable-chips
            small-chips
          >
          </v-autocomplete>
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
import BaseForm from "./../BaseForm.vue"
import TextField from "@/components/TextField.vue"

export default {
  name: "FormPozvaniePouzivatelaNaKliniku",
  extends: BaseForm,
  $_veeValidate: {
    validator: "new",
  },
  components: { TextField },
  data() {
    return {
      form: {
        email: "",
        ambulanceIDs: [],
      },
      ambulances: [],
    }
  },
  computed: {
    clinic: function () {
      return this.$store.state.me.clinic
    },
  },
  methods: {
    init: function () {
      this.form = {
        email: "",
        ambulanceIDs: [],
      }
      this.fetchAmbulances()
      this.$validator.reset()
      this.$refs.emailField.focus()
    },
    fetchAmbulances: async function () {
      let url = "/v1/proto.AmbulanceService/ListAmbulances"
      try {
        let data = await this.$api.post(url, {
          clinic_id: this.clinic ? this.clinic.id : null,
        })
        this.ambulances = data.ambulances
      } catch (err) {
        this.processError(err)
      }
    },
    save: async function () {
      this.errors.clear()
      await this.$validator.validateAll()
      if (this.form.ambulanceIDs.length == 0) {
        this.errors.add({
          field: "form.ambulanceIDs",
          msg: this.$vuetify.t("Položka ambulancie je povinná."),
        })
      }
      if (this.errors.any()) {
        return
      }
      try {
        let params = {
          clinic_id: this.clinic ? this.clinic.id : null,
          email: this.form.email,
          ambulance_ids: this.form.ambulanceIDs,
        }
        await this.$api.post("/v1/proto.Registration/InviteUser", params)
        this.$store.commit(
          "setSuccess",
          this.$vuetify.t("Používateľ bol úspešne pozvaný na kliniku")
        )
        this.$emit("saved")
      } catch (err) {
        this.processError(err)
      }
    },
  },
}
</script>
