var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"dashboard-panel ma-2 elevation-5"},[_c('v-toolbar',{attrs:{"flat":"","dense":"","card":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$vuetify.t("Vyšetrenia"))+" ")]),_c('v-spacer'),_c('v-text-field',{staticClass:"dense mx-0",attrs:{"flat":"","hide-details":"","prepend-inner-icon":"search","solo-inverted":"","clearable":""},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({staticClass:"ml-3 font-weight-bold error--text"},on),[_vm._v(" "+_vm._s(_vm.vysetrenia != null ? _vm.vysetrenia.pocet_neodoslanych_vysetreni : 0))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.t( "Počet neodoslaných vyšetrení do ezdravia za posledných 7 dní" )))])])],1),_c('v-card-text',[_vm._l((['dnes', 'vcera', 'tyzden', 'mesiac', 'starsie']),function(obdobie){return [(
          _vm.vysetrenia !== null &&
          _vm.vysetrenia.mapa_vysetreni !== undefined &&
          _vm.vysetrenia.mapa_vysetreni[obdobie] !== undefined
        )?[_c('v-toolbar',{key:obdobie,attrs:{"flat":"","dense":"","card":"","dark":_vm.isThemeDark}},[_vm._v(" "+_vm._s(_vm.$vuetify.t("$vuetify.dashboard.vysetrenia.obdobie." + obdobie))+" ")]),_vm._l((_vm.vysetrenia.mapa_vysetreni[obdobie]),function(vysetrenie,index){return [_c('v-card',{key:'list' + obdobie + index,staticClass:"highlight-on-hover"},[_c('v-container',{staticClass:"px-3 py-2 clicker",attrs:{"fluid":""},on:{"click":function($event){return _vm.clickedVysetrenie(vysetrenie)}}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","md6":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({staticClass:"font-weight-light"},on),[_vm._v(" "+_vm._s(_vm._f("formatDatumACas")(vysetrenie.datum,"D.M.YYYY HH:mm")))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.t("Dátum vyšetrenia")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({staticClass:"font-weight-medium"},on),[_vm._v(" "+_vm._s(vysetrenie.meno_pacienta))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.t("Pacient")))])])],1),_c('v-flex',{staticClass:"text-md-right",attrs:{"xs12":"","md6":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({staticClass:"font-weight-regular",class:{
                          'error--text':
                            vysetrenie.stav_odoslania !== 'SUCCESS',
                          'success--text':
                            vysetrenie.stav_odoslania === 'SUCCESS',
                        }},on),[_vm._v(" "+_vm._s(_vm.$vuetify.t( "$vuetify.enum.evysetrenie.stav." + vysetrenie.stav_odoslania )))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.t("Stav odoslania")))])])],1),(vysetrenie.hlavna_diagnoza_skratka !== '')?_c('v-flex',{staticClass:"mb-2",attrs:{"xs12":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({staticClass:"font-weight-medium"},on),[_vm._v(" "+_vm._s(vysetrenie.hlavna_diagnoza_skratka)+" ")]),_c('span',_vm._g({staticClass:"font-weight-light"},on),[_vm._v(" "+_vm._s(vysetrenie.hlavna_diagnoza_display_name)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.t("Hlavná diagnóza")))])])],1):_vm._e()],1)],1)],1),(index !== _vm.vysetrenia.mapa_vysetreni[obdobie].length - 1)?_c('v-divider',{key:'divider' + obdobie + index}):_vm._e()]})]:_vm._e()]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }