<template>
  <v-container fluid>
    <ApiList
      ref="list"
      :url="url"
      :customParams="getParams()"
      :title="$vuetify.t('Merania vitálnych a antropometrických hodnôt')"
      :actions="[
        {
          text: $vuetify.t('Odstrániť meranie'),
          icon: 'delete',
          handler: removeMeranie,
        },
      ]"
      @addItem="addItem"
      @editItem="editItem"
    >
      <template v-slot:items="{ item }">
        <VitAntropHodnotyRow :vitAntropHodnoty="item" />
      </template>
    </ApiList>
    <BaseDialog
      :dialogTitle="$vuetify.t(dialogTitle)"
      ref="dialog"
      @save="$refs.form.save()"
    >
      <FormVitAntropHodnoty
        ref="form"
        :pacientId="id"
        @saved="fetchData"
      ></FormVitAntropHodnoty>
    </BaseDialog>
  </v-container>
</template>

<script>
import ApiList from "./../../components/ApiList.vue"
import BaseDialog from "./../../components/BaseDialog.vue"
import FormVitAntropHodnoty from "./../../components/forms/FormVitAntropHodnoty.vue"
import thingMixin from "@/components/mixins/thing.js"
import VitAntropHodnotyRow from "@/components/rows/VitAntropHodnotyRow.vue"

export default {
  name: "VitAntropHodnoty",
  mixins: [thingMixin],
  components: {
    ApiList,
    BaseDialog,
    FormVitAntropHodnoty,
    VitAntropHodnotyRow,
  },
  props: ["id"],
  data: function () {
    return {
      dialogTitle: "Vitálne a antropometrické hodnoty",
    }
  },
  computed: {
    url() {
      return "/v1/proto.ThingService/ListThings"
    },
    pacient: function () {
      return this.$store.state.pacient !== null ? this.$store.state.pacient : {}
    },
  },
  methods: {
    getParams: function () {
      return {
        patient_id: this.id,
        table_request: {
          sort_by: "Date",
          sort_desc: true,
        },
        vit_antrop_hodnoty: {},
      }
    },
    readPacient: function () {
      this.$store.commit("setReloadPacient", true)
    },
    fetchData: function () {
      this.$refs.list.reload()
      this.readPacient()
    },
    addItem: function () {
      this.$refs.form.new()
    },
    editItem: function (item) {
      this.$refs.form.edit(item)
    },
    removeMeranie: async function (meranie) {
      var result = await this._deleteThing(meranie) // from thing mixin
      if (result) {
        this.fetchData()
      }
    },
  },
}
</script>
