<template>
  <v-layout align-center justify-center>
    <v-flex xs12 sm8 md4>
      <v-card class="elevation-12">
        <v-toolbar flat dense card>
          <v-toolbar-title>{{
            $vuetify.t("Overenie používateľského konta")
          }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <div v-if="verified">
            <div v-if="success" class="text-md-left px-4">
              <h3 class="headline">
                {{ $vuetify.t("Vitajte v aplikácii Ordinácia") }}
              </h3>
              <p>
                {{
                  $vuetify.t(
                    "Vaše konto bolo úspešne overené. Prosím, pokračujte prihlásením sa do aplikácie"
                  )
                }}
              </p>
            </div>
            <v-alert v-else type="error" :value="true">
              {{
                $vuetify.t("Nastala chyba! Vaše konto nebolo korektne overené.")
              }}
            </v-alert>
          </div>
          <div v-else>
            {{ $vuetify.t("Prebieha overovanie konta") }}
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat v-if="verified" color="primary" @click="goToLogin">{{
            $vuetify.t("Späť na prihlásenie")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "AdminAccountVerification",
  props: {
    token: {
      type: String,
      required: false,
    },
  },
  data: function () {
    return {
      verified: false,
      success: false,
    }
  },
  methods: {
    verify: async function () {
      try {
        await this.$api.post("/v1/proto.Registration/RegisterVerify", {
          token: this.token,
        })
        this.success = true
      } catch (err) {
      } finally {
        this.verified = true
      }
    },
    goToLogin: function () {
      this.$router.replace({ name: "login" })
    },
  },
  mounted: function () {
    this.verify()
  },
}
</script>
