<template>
  <v-container fluid>
    <v-form>
      <v-card>
        <v-card-text>
          <v-tabs v-model="activeTab">
            <v-tab>{{ $vuetify.t("Údaje PZS") }}</v-tab>
            <!-- <v-tab>{{ $vuetify.t("Tlač") }}</v-tab> -->
            <!-- <v-tab>{{ $vuetify.t("Formuláre") }}</v-tab> -->
            <!-- <v-tab>{{ $vuetify.t("Značky") }}</v-tab> -->
          </v-tabs>
          <v-tabs-items v-model="activeTab">
            <v-tab-item>
              <v-container class="px-2">
                <TextField
                  v-model="clinic.name"
                  name="clinic.name"
                  :label="$vuetify.t('Názov')"
                  :maxlength="255"
                  :disabled="!isEditable"
                  v-validate="'required'"
                ></TextField>
                <!-- <v-text-field -->
                <!--   v-model="clinic.adminName" -->
                <!--   :label="$vuetify.t('Administrátor')" -->
                <!--   maxlength="100" -->
                <!--   required -->
                <!--   :disabled="true" -->
                <!--   v-validate="'required'" -->
                <!--   :error-messages="errors.collect('adminName')" -->
                <!--   data-vv-name="adminName" -->
                <!--   :data-vv-as="$vuetify.t('Administrátor')" -->
                <!-- ></v-text-field> -->
                <TextField
                  v-model="clinic.kod"
                  name="clinic.kod"
                  :label="$vuetify.t('Kód PZS')"
                  :maxlength="6"
                  :disabled="!isEditable"
                  v-validate="'min:6'"
                  mask="A#####"
                ></TextField>
                <TextField
                  v-model="clinic.ico"
                  name="clinic.ico"
                  :label="$vuetify.t('IČO')"
                  :maxlength="8"
                  :disabled="!isEditable"
                  v-validate="'numeric|min:8'"
                  mask="########"
                ></TextField>
                <v-textarea
                  v-model="clinic.address"
                  :label="$vuetify.t('Adresa')"
                  rows="3"
                  auto-grow
                  :disabled="!isEditable"
                  :counter="255"
                  clearable
                >
                </v-textarea>
              </v-container>
            </v-tab-item>
            <v-tab-item>
              <v-flex> </v-flex>
              <v-container fluid>
                <v-layout row>
                  <v-flex class="text-xs-center">
                    <img :src="imageUrl" height="150" v-if="imageUrl" />
                  </v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex grow>
                    <v-text-field
                      v-model="imageName"
                      readonly
                      :label="$vuetify.t('Logo')"
                      hint="Logo sa bude zobrazovať v ľavej časti hlavičky pri tlači dokumentov"
                      persistent-hint
                      prepend-icon="attach_file"
                      @click="pickFile"
                      :disabled="!clinic.isAdmin"
                    ></v-text-field>
                    <input
                      type="file"
                      style="display: none"
                      ref="image"
                      accept="image/gif, image/jpeg, image/png"
                      @change="onFilePicked"
                    />
                  </v-flex>
                  <v-flex shrink>
                    <v-btn @click="deleteLogo()" v-if="imageUrl">
                      <v-icon left>delete</v-icon>
                      {{ $vuetify.t("Zmazať") }}
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-container>
              <v-text-field
                v-model="clinic.printLogoWidth"
                :label="$vuetify.t('Šírka loga')"
                type="number"
                placeholder="120"
                hint="Šírka loga v pixeloch, výška sa dopočíta podľa pôvodného pomeru"
                persistent-hint
                :disabled="!clinic.isAdmin"
                v-validate="'numeric'"
                :error-messages="errors.collect('printLogoWidth')"
                data-vv-name="printLogoWidth"
                :data-vv-as="$vuetify.t('Šírka loga')"
              ></v-text-field>
              <v-textarea
                v-model="clinic.printHeaderText"
                :label="$vuetify.t('Text hlavičky')"
                :hint="$vuetify.t('Môžete používať HTML formátovacie značky')"
                maxlength="1000"
                :disabled="!clinic.isAdmin"
              ></v-textarea>
              <v-textarea
                v-model="clinic.printVysetrenie"
                :label="
                  $vuetify.t(
                    'Text umiestnený na konci dokumentu pri tlači vyšetrenia'
                  )
                "
                :hint="$vuetify.t('Môžete používať HTML formátovacie značky')"
                maxlength="2000"
                :disabled="!clinic.isAdmin"
              ></v-textarea>
            </v-tab-item>
            <v-tab-item>
              <v-container fluid>
                <form-tree
                  :forms="forms"
                  :show-author="false"
                  :show-stars="false"
                  @choose="choose"
                  @close="close"
                >
                  <template slot="otherButtons" slot-scope="{ active }">
                    <v-btn @click="addForm">
                      <v-icon left>add</v-icon>
                      {{ $vuetify.t("Pridať") }}
                    </v-btn>
                    <v-btn
                      @click="editForm(active)"
                      :disabled="active.length === 0"
                    >
                      <v-icon left>edit</v-icon>
                      {{ $vuetify.t("Zmeniť") }}
                    </v-btn>
                    <v-btn
                      @click="sendToCloud(active)"
                      :disabled="active.length === 0"
                      ><v-icon left>cloud_upload</v-icon
                      >{{ $vuetify.t("Odoslať do cloudu") }}</v-btn
                    >
                    <v-btn
                      @click="getFromCloud()"
                      :disabled="remoteForms.length === 0"
                    >
                      <v-icon left>cloud_download</v-icon
                      >{{ $vuetify.t("Načítať z cloudu") }}</v-btn
                    >
                  </template>
                </form-tree>
              </v-container>
            </v-tab-item>
            <v-tab-item>
              <v-container fluid>
                <v-list two-line v-if="clinic.tags && clinic.tags.length > 0">
                  <v-list-tile
                    avatar
                    v-for="(t, index) in clinic.tags"
                    :key="index"
                  >
                    <v-list-tile-avatar>
                      <v-btn :color="t.Color" fab small dark>
                        <v-icon>edit</v-icon>
                      </v-btn>
                      <label
                        style="
                          position: absolute;
                          top: 0;
                          left: 0;
                          width: 40px;
                          height: 40px;
                        "
                      >
                        <input
                          type="color"
                          v-model="t.Color"
                          style="visibility: hidden"
                      /></label>
                    </v-list-tile-avatar>
                    <v-list-tile-content>
                      <v-container>
                        <v-layout row>
                          <v-flex xs9>
                            <v-text-field
                              v-model="t.Name"
                              :label="$vuetify.t('Názov')"
                              maxlength="255"
                              :ref="'tagField' + index"
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs3>
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  fab
                                  flat
                                  small
                                  icon
                                  @click="clinic.tags.splice(index, 1)"
                                  v-on="on"
                                >
                                  <v-icon>remove</v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $vuetify.t("Vymazať značku") }}</span>
                            </v-tooltip>
                            <v-tooltip
                              top
                              v-if="index === clinic.tags.length - 1"
                            >
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  fab
                                  flat
                                  small
                                  icon
                                  @click="
                                    clinic.tags.push({
                                      Name: '',
                                      Color: '#00DD00',
                                    })
                                    focusTagField(clinic.tags.length - 1)
                                  "
                                  v-on="on"
                                >
                                  <v-icon>add</v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $vuetify.t("Pridať značku") }}</span>
                            </v-tooltip>
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
                <v-tooltip top v-if="clinic.tags && clinic.tags.length === 0">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      fab
                      flat
                      small
                      icon
                      @click="
                        clinic.tags.push({ Name: '', Color: '#00DD00' })
                        focusTagField(clinic.tags.length - 1)
                      "
                      v-on="on"
                    >
                      <v-icon>add</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $vuetify.t("Pridať novú značku") }}</span>
                </v-tooltip>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions style="flex: 0 1 auto">
          <v-spacer></v-spacer>
          <v-btn flat color="primary" @click="saveClinic">{{
            $vuetify.t("Uložiť")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>

    <BaseDialog
      :dialogTitle="$vuetify.t('Formulár')"
      width="80%"
      ref="dialog"
      @save="$refs.form.save()"
    >
      <form-form ref="form" @saved="savedForm" />
    </BaseDialog>
    <!--BaseDialog
      :dialogTitle="$vuetify.t('Formulár - výber')"
      width="80%"
      ref="dialogTree"
      @save="$refs.formTree.save()"
    >
      <remote-form-tree
        :forms="remoteForms"
        ref="formTree"
        :show-author="true"
        :show-stars="false"
      >
        <template slot="otherButtons" slot-scope="{ active, activeObject }">
          <v-btn @click="$refs.dialogTree.close()">
            {{ $vuetify.t("$vuetify.app.button.close") }}
          </v-btn>
          <v-btn
            @click="treeChoose(activeObject)"
            :disabled="active.length === 0"
          >
            {{ $vuetify.t("Vybrať") }}
          </v-btn>
        </template>
      </remote-form-tree>
      <template v-slot:buttons>
        <v-btn style="display: none">
          {{ $vuetify.t("Dummy") }}
        </v-btn>
      </template>
    </BaseDialog-->
  </v-container>
</template>

<script>
import BaseDialog from "./../../components/BaseDialog.vue"
import FormForm from "./../../components/forms/FormForm.vue"
import FormTree from "./../../components/FormTree.vue"
//import RemoteFormTree from "axonforms/FormTree.vue"
import * as Miscella from "./../../miscella.js"
import TextField from "@/components/TextField.vue"

export default {
  name: "Clinic",
  inject: ["$validator"],
  components: {
    BaseDialog,
    FormForm,
    FormTree,
    TextField,
  },
  data: function () {
    return {
      activeTab: 0,
      clinic: {},
      cities: [],
      countries: [],
      countrySearch: "",
      citySearch: "",
      imageName: "",
      imageUrl: "",
      imageFile: "",
      forms: [],
      remoteForms: [],
    }
  },
  computed: {
    isEditable: function () {
      // TODO editovatelne len pre adminov na klinike
      return true
    },
    clinicID: function () {
      return this.$store.state.me.clinic.id
    },
  },
  watch: {
    countrySearch: function (newValue, oldValue) {
      console.log("CLINIC.VUE countrySearch", newValue, oldValue)
      this.findCountries()
    },
    citySearch: function (newValue, oldValue) {
      console.log("CLINIC.VUE citySearch", newValue, oldValue)
      if (newValue !== null) {
        this.findMesta()
      }
    },
  },
  methods: {
    pickFile: function () {
      this.$refs.image.click()
    },
    deleteLogo: function () {
      this.$api.delete("/api/clinic/logo").then(this.saveOK).catch(this.saveErr)
    },
    onFilePicked: function (e) {
      const files = e.target.files
      if (files[0] !== undefined) {
        this.imageName = files[0].name
        if (this.imageName.lastIndexOf(".") <= 0) {
          return
        }
        const fr = new FileReader()
        fr.readAsDataURL(files[0])
        fr.addEventListener("load", () => {
          this.imageUrl = fr.result
          this.imageFile = files[0] // this is an image file that can be sent to server...
        })
      } else {
        this.imageName = ""
        this.imageFile = ""
        this.imageUrl = ""
      }
    },
    findMesta: function () {
      let ret = "/api/codebook/45"
      let params = {
        filter: this.citySearch === null ? "" : this.citySearch,
      }
      this.$api.get(ret, params).then(
        function (data) {
          this.cities = data.tableData
        }.bind(this)
      )
    },
    findCountries: function () {
      let ret = "/api/codebook/51"
      let params = {
        filter: this.countrySearch === null ? "" : this.countrySearch,
      }
      this.$api.get(ret, params).then(
        function (data) {
          this.countries = data.tableData
        }.bind(this)
      )
    },
    fetchData: async function () {
      this.loading = true
      /*this.imageName = ""
      this.imageUrl = ""
      this.imageFile = ""*/
      try {
        var data = await this.$api.post("/v1/proto.ClinicService/GetClinic", {
          clinic_id: this.clinicID,
        })
        this.clinic = data.clinic
      } catch (e) {
        this.processError(e)
      } finally {
        this.loading = false
      }
    },
    fetchDataOK: function (data) {
      console.log("Clinic.fetchData.success", data)
      this.clinic = data
      if (!Miscella.isSet(this.clinic.tags)) {
        this.clinic.tags = []
      }
      if (Miscella.isSet(this.clinic.country)) {
        this.countries.push(this.clinic.country)
      }
      if (Miscella.isSet(this.clinic.obec)) {
        this.cities.push(this.clinic.obec)
      }
      if (!Miscella.isEmpty(this.clinic.logoID)) {
        this.imageUrl = "/api/binary/" + this.clinic.logoID
      }
      console.log(
        "Clinic.fetchData.success",
        this.clinic.country,
        this.clinic.obec
      )
    },
    fetchDataErr: function (err) {
      console.log("Clinic.fetchData.fail", err)
    },
    saveClinic: async function () {
      var valid = await this.$validator.validateAll()
      if (!valid) {
        return
      }
      /*    let formData = new FormData()
          formData.append("clinic", JSON.stringify(this.clinic))
          formData.append("file", this.imageFile)
          formData.append("imageName", this.imageName)
          this.$api
            .postBinary("/api/clinic", formData)
            .then(this.saveOK)
            .catch(this.saveErr)
        }.bind(this),
        function () {
          console.log("errors", this.errors)
        }.bind(this)
      )*/

      try {
        await this.$api.post("/v1/proto.ClinicService/UpdateClinic", {
          clinic: this.clinic,
        })
        this.$store.commit(
          "setSuccess",
          this.$vuetify.t("Údaje PZS boli úspešne uložené")
        )
        await this.$store.dispatch("reloadMe", this.$api)
        this.fetchData()
      } catch (e) {
        this.processError(e)
      }
    },
    saveOK: function () {
      this.$store.commit(
        "setSuccess",
        this.$vuetify.t("PZS bol úspešne uložený.")
      )
      this.$store.dispatch("reloadMe", this.$api)
      this.fetchData()
    },
    saveErr: function (error) {
      let ret = this.$vuetify.t(error)
      this.$store.commit(
        "setError",
        this.$vuetify.t("Pri ukladaní PZS vznikla chyba", { message: ret })
      )
    },
    printKod: function () {
      let w = window.open("/api/clinic/pdf")
      w.print()
    },
    fetchForm: function (formID) {
      return this.$api.get(`/api/form/${formID}`)
    },
    addForm: function () {
      this.$refs.form.new()
      this.$refs.dialog.open()
    },
    editForm: async function (formID) {
      var form = await this.fetchForm(formID)
      this.$refs.form.edit(form)
      this.$refs.dialog.open()
    },
    savedForm: function () {
      this.$refs.dialog.close()
      this.fetchFormsTree()
    },
    treeChoose: async function (aItem) {
      this.$refs.dialogTree.close()
      await this.$api.post("/api/form", aItem)
      this.$store.commit(
        "setSuccess",
        this.$vuetify.t("Formulár bol úspešne uložený")
      )
      this.fetchFormsTree()
      //save
    },
    fetchFormsTree: async function () {
      this.forms = await this.$api.get("/api/formstree")
    },
    fetchCloudFormsTree: async function () {
      var data = await this.$api.get("/api/cloud/formstree")
      if (Miscella.isSet(data.Trees)) {
        this.remoteForms = data.Trees.slice()
      }
      console.log("fetchCloudFormsTree", this.remoteForms)
    },
    sendToCloud: async function (formID) {
      var aForm = await this.fetchForm(formID)
      console.log("sendToCloud", aForm)
      let data = aForm
      data.author = {}
      data.author.id = this.$store.state.me.user.id
      data.author.name = this.$store.state.me.user.name
      let res = await this.$api.post("/api/cloud/form", data)
      res = JSON.parse(res)
      console.log("sendToCloud", res)
      if (Miscella.isSet(res.error)) {
        this.$store.commit(
          "setError",
          this.$vuetify.t(
            `Pri odosielaní formulára vznikla chyba (${res.error})`
          )
        )
      } else {
        this.$store.commit(
          "setSuccess",
          this.$vuetify.t("Formulár bol úspešne odoslaný.")
        )
      }
    },
    getFromCloud: function () {
      console.log("getFromCloud called")
      this.$refs.dialogTree.open()
    },
    choose: function (aRet) {
      console.log("Choose called:" + aRet)
    },
    close: function () {
      console.log("Close called")
    },
    focusTagField: function (index) {
      this.$nextTick(() => {
        this.$refs["tagField" + index][0].focus()
      })
    },
  },
  mounted: async function () {
    await this.$store.dispatch("fetchMe", this.$api)
    this.fetchData()
    //this.fetchFormsTree()
    //this.fetchCloudFormsTree()
  },
}
</script>

<style scoped></style>
