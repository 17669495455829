<template>
  <div style="height: 100%">
    <v-navigation-drawer
      app
      clipped
      fixed
      width="200"
      permanent
      :mini-variant.sync="miniDrawer"
      :mini-variant-width="50"
    >
      <v-tooltip right v-if="!miniDrawer">
        <template v-slot:activator="{ on }">
          <v-btn
            absolute
            icon
            color="grey"
            flat
            @click.stop="toggleDrawer"
            style="top: 2px; right: 2px; z-index: 10"
            v-on="on"
          >
            <v-icon>chevron_left</v-icon>
          </v-btn>
        </template>
        <span>{{ $vuetify.t("Zbaliť panel") }} </span>
      </v-tooltip>
      <v-list :class="{ 'mt-2': !miniDrawer }" dense>
        <v-list-tile v-if="miniDrawer">
          <v-list-tile-action>
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-btn
                  flat
                  icon
                  @click.stop="toggleDrawer"
                  v-on="on"
                  color="grey"
                >
                  <v-icon>chevron_right</v-icon>
                </v-btn>
              </template>
              <span>{{ $vuetify.t("Roztiahnuť panel") }} </span>
            </v-tooltip>
          </v-list-tile-action>
        </v-list-tile>

        <v-list-tile
          v-for="item in [
            { name: 'info', title: 'Info', icon: 'info' },
            {
              name: 'vysetrenie',
              title: 'Vyšetrenie',
              icon: $vuetify.t('$vuetify.thing.type.ThingVysetrenie.icon'),
            },
            { name: 'karta', title: 'Karta', icon: 'fas fa-notes-medical' },
            {
              name: 'ockovania',
              title: 'Očkovania',
              icon: $vuetify.t('$vuetify.thing.type.ThingOckovanie.icon'),
            },
            {
              name: 'recepty',
              title: 'Recepty',
              icon: $vuetify.t('$vuetify.thing.type.ThingRecept.icon'),
            },
            {
              name: 'reakcie',
              title: 'Nežiadúce reakcie',
              icon: $vuetify.t(
                '$vuetify.thing.type.ThingNeziaducaReakcia.icon'
              ),
            },
            {
              name: 'diagnozy',
              title: 'Diagnózy',
              icon: $vuetify.t('$vuetify.thing.type.ThingDiagnoza.icon'),
            },
            {
              name: 'odporucania',
              title: 'Výmenné lístky',
              icon: $vuetify.t('$vuetify.thing.type.ThingOdporucanie.icon'),
            },
            {
              name: 'vykon',
              title: 'Zdravotné výkony',
              icon: 'fas fa-user-injured',
            },
            {
              name: 'tlak',
              title: 'Merania krvného tlaku',
              icon: $vuetify.t('$vuetify.thing.type.ThingKrvnyTlak.icon'),
            },
            {
              name: 'vitAntropHodnoty',
              title: 'Merania Vitálnych a antropometrických hodnôt',
              icon: $vuetify.t(
                '$vuetify.thing.type.ThingVitAntropHodnoty.icon'
              ),
            },
            {
              name: 'skupina',
              title: 'Vyš. krvnej skupiny',
              icon: $vuetify.t('$vuetify.thing.type.ThingKrvnaSkupina.icon'),
            },
            {
              name: 'chirurgickyVykon',
              title: 'Chirurgické výkony',
              icon: 'fas fa-user-injured',
            },
            /*{
              name: 'poistov/*ne',
              title: 'Poisťovne',
              icon: 'fas fa-building',
            },*/
            { name: 'subory', title: 'Súbory', icon: 'fas fa-file-alt' },
            /*{ name: 'rodina', title: 'Rodinná anamnéza', icon: 'people' },
            { name: 'komunikacia', title: 'Komunikácia', icon: 'message' },*/
            { name: 'ehealth', title: 'Ezdravie', icon: 'far fa-heart' },
            { name: 'edpn', title: 'EDPN', icon: 'far fa-face-mask' },
          ]"
          :key="item.title"
          :to="{ name: item.name, params: { id: id } }"
          active-class="primary--text"
        >
          <v-list-tile-action>
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-icon small v-on="on">{{ item.icon }}</v-icon>
              </template>
              <span>{{ $vuetify.t(item.title) }}</span>
            </v-tooltip>
          </v-list-tile-action>

          <v-list-tile-content>
            <v-list-tile-title>{{ $vuetify.t(item.title) }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-navigation-drawer>
    <v-content app class="fill-height">
      <v-expansion-panel
        :value="$store.state.pacientInfoExpanded"
        @input="pacientInfoExpandedChanged"
      >
        <v-expansion-panel-content class="my-0 pt-0 pb-2" v-if="pacient.id">
          <template v-slot:header>
            <v-layout row fluid>
              <v-flex shrink>
                <v-btn
                  flat
                  :to="pacientNameRoute"
                  active-class=""
                  @click.native.stop=""
                  mx-0
                >
                  {{ pacient.full_name | toText }}
                  ( {{ sexText }} | {{ getAge(pacient.birthdate) }} |
                  {{ pacient.birthdate | formatDatum }} )
                </v-btn>
              </v-flex>
              <!-- <v-flex xs9 md6 v-show="isPacientInfoExpanded">
                <v-layout fill-height align-center>
                  <span
                    v-if="zobrazitKapitacnyText"
                    v-bind:class="
                      jePacientKapitovany ? 'green--text' : 'red--text'
                    "
                    class="font-weight-medium"
                  >
                    {{
                      jePacientKapitovany
                        ? $vuetify.t("$vuetify.pacient.jeKapitovany")
                        : $vuetify.t("$vuetify.pacient.nieJeKapitovany")
                    }}</span
                  >
                </v-layout>
              </v-flex> -->
              <v-flex xs9 md6 v-show="!isPacientInfoExpanded">
                <PacientInfo
                  :expanded="false"
                  :pacientId="id"
                  @editKontrola="editKontrola"
                ></PacientInfo>
              </v-flex>
              <v-flex xs1>
                <v-layout
                  row
                  wrap
                  align-content-center
                  fill-height
                  justify-center
                >
                  <v-tooltip top>
                    <span slot="activator">
                      <v-icon
                        :color="pacientJeVCakarni ? 'success' : 'none'"
                        @click.native.stop="
                          pacientJeVCakarni = !pacientJeVCakarni
                        "
                        >airline_seat_recline_normal
                      </v-icon>
                    </span>
                    <span>
                      {{
                        $vuetify.t(
                          "$vuetify.pacient.cakaren." + pacientJeVCakarni
                        )
                      }}
                    </span>
                  </v-tooltip>
                </v-layout>
              </v-flex>
            </v-layout>
          </template>
          <v-card class="mx-0 elevation-0">
            <v-container py-0 fluid wrap>
              <v-layout row>
                <!--  <v-flex shrink>
                  <v-avatar
                    color="transparent"
                    size="150"
                    v-if="pacientImageLink !== ''"
                  >
                    <img alt="" :src="pacientImageLink" />
                  </v-avatar>
                </v-flex> -->
                <v-flex>
                  <PacientInfo
                    v-bind:expanded="true"
                    v-bind:pacientId="id"
                    @editKontrola="editKontrola"
                  ></PacientInfo>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <router-view></router-view>
    </v-content>
  </div>
</template>

<script>
import PacientInfo from "./../../components/PacientInfo.vue"
import * as Miscella from "./../../miscella.js"
import * as mixPatient from "./../../components/mixins/patient.js"
import { mapState, mapGetters } from "vuex"

export default {
  components: {
    PacientInfo,
  },
  props: ["id"],
  data: function () {
    return {
      ezdravieZP: null,
    }
  },
  computed: {
    pacientImageLink: function () {
      let ret = ""
      if (this.pacient !== null) {
        ret = this.pacient.imageLink
      }
      //ret = this.defaultText(ret, "//www.gravatar.com/avatar/xxx?d=mp") nezobrazovat DEFAULT avatar
      return ret
    },
    zobrazitKapitacnyText: function () {
      return (
        Miscella.isSet(this.pacientData) &&
        Miscella.isSet(this.pacientData.poistenie) &&
        this.pacient.poistenie.CharakterPoistenca === "SR" &&
        Miscella.isSet(this.$store.state.me.ambulance) &&
        this.$store.state.me.ambulance.kapitacia
      )
    },
    pacient: function () {
      if (Miscella.isSet(this.$store.state.pacient)) {
        return this.$store.state.pacient
      }
      return {}
    },
    isPacientInfoExpanded: function () {
      return this.$store.state.pacientInfoExpanded !== null
    },
    sexText: function () {
      return this.$vuetify.t("$vuetify.enum.sex.skratka." + this.pacient.sex)
    },
    pacientNameRoute: function () {
      if (this.$route.name === "info") {
        return {
          name: "vysetrenie",
          params: { id: this.id },
        }
      }
      return {
        name: "info",
        params: { id: this.id, tab: "0" },
        query: { action: "editInfo" },
      }
    },
    pacientJeVCakarni: {
      get: function () {
        let pacientId = this.id
        let ambulance = this.$store.state.me.ambulance
        let cakaren = ambulance !== null ? ambulance.cakaren : null
        let ret = false
        if (!Miscella.isSet(cakaren.items)) {
          for (let i = 0; i < cakaren.items.length; i++) {
            let p = cakaren.items[i]
            if (p.patient_id === pacientId) {
              ret = true
            }
          }
        }
        return ret
      },
      set: async function (newValue) {
        let pacientId = this.id
        let ambulance = this.$store.state.me.ambulance
        let cakaren = ambulance !== null ? ambulance.cakaren : null
        if (newValue === false) {
          cakaren = Miscella.cakarenRemove(cakaren, pacientId)
        }
        if (newValue) {
          let p = { patient_id: pacientId }
          cakaren.items.push(p)
        }
        try {
          let result = await Miscella.cakarenUpdate(
            cakaren,
            ambulance.id,
            this.$api
          )
          this.$store.commit(
            "setSuccess",
            this.$vuetify.t("Čakáreň bola úspešne uložená.")
          )
          this.$store.dispatch("reloadMe", this.$api)
          console.log("cakaren saved", result)
        } catch (err) {
          this.$store.commit(
            "setError",
            this.$vuetify.t(
              "Pri ukladaní pacienta do čakárne vznikla chyba ({message}).",
              {
                message: err,
              }
            )
          )
        }
      },
    },
    ...mapState({
      ehealthEnabled: (state) => state.ehealth.enabled,
      miniDrawer: (state) => state.drawer.pacient,
    }),
    ...mapGetters(["isEhealthReady"]),
  },
  mixins: [mixPatient.mixins],
  watch: {
    "$store.state.reloadPacient": function () {
      if (this.$store.state.reloadPacient) {
        this.$store.dispatch("reloadPacient", {
          api: this.$api,
          pacientId: this.id,
        })
      }
    },
    id: function () {
      this.readPacient()
    },
  },
  methods: {
    pacientInfoExpandedChanged: function (value) {
      this.$store.commit("setPacientInfoExpanded", value)
      localStorage.setItem("pacientInfoExpanded", JSON.stringify(value))
    },
    editKontrola: function () {
      // kontrola uz v novej app nie je
    },
    readPacient: function () {
      return this.$store.dispatch("fetchPacient", {
        api: this.$api,
        pacientId: this.id,
      })
    },
    toggleDrawer: function () {
      this.$store.commit("toggleDrawer", "pacient")
    },
  },
  mounted: function () {
    Promise.all([
      this.readPacient(),
      this.$store.dispatch("fetchMe", this.$api),
      // this.$store.dispatch("fetchEhealthStatus", this.$api)
    ])
    /*  .then(() => {
        if (this.isEhealthReady) {
          var eVysetrenie = new EVysetrenie(
            this.$store.state.pacient,
            this.$store,
            this.$api,
            this.$store.state.me.ambulance
          )
          return eVysetrenie.getZpPacienta().then(zp => {
            this.ezdravieZP = zp
          })
        }
      })
      .catch(error => {
        this.$store.commit("setError", error)
      }) */

    var pacientInfoExpanded = localStorage.getItem("pacientInfoExpanded")
    if (Miscella.isSet(pacientInfoExpanded)) {
      this.$store.commit(
        "setPacientInfoExpanded",
        JSON.parse(pacientInfoExpanded)
      )
    }
  },
}
</script>
<style>
.v-tooltip span span span {
  cursor: pointer;
  padding-left: 0.5em;
  font-weight: bold;
}
</style>
