<template>
  <v-container fluid>
    <ApiList
      ref="listAktivne"
      :url="url"
      :customParams="getParams(true)"
      :title="$vuetify.t('Aktívne diagnózy')"
      :actions="[
        {
          text: $vuetify.t('Ukončiť diagnózu'),
          icon: 'update',
          handler: endDiagnoza,
        },
        {
          text: $vuetify.t('Odstrániť diagnózu'),
          icon: 'delete',
          handler: deleteDiagnoza,
        },
      ]"
      @addItem="addDiagnoza"
      @editItem="editDiagnoza"
    >
      <template slot="items" slot-scope="{ item }">
        <v-container fluid>
          <v-layout row>
            <v-flex xs2>
              <v-tooltip top>
                <span slot="activator">{{ item.date | formatDatum }}</span>
                <span>{{ $vuetify.t("Dátum") }}</span>
              </v-tooltip>
            </v-flex>
            <v-flex xs2>
              <v-tooltip top>
                <span slot="activator">{{
                  item.diagnoza.diagnoza.skratka
                }}</span>
                <span>{{ $vuetify.t("Skratka") }}</span>
              </v-tooltip>
            </v-flex>
            <v-flex xs8>
              <v-tooltip top>
                <span slot="activator"
                  >{{ item.diagnoza.diagnoza.display_name }}<br />{{
                    item.diagnoza.poznamka
                  }}</span
                >
                <span>{{ $vuetify.t("Popis") }}</span>
              </v-tooltip>
            </v-flex>
          </v-layout>
        </v-container>
      </template>
    </ApiList>
    <ApiList
      ref="listNeaktivne"
      :showToolbar="false"
      :url="url"
      :customParams="getParams(false)"
      :title="$vuetify.t('Neaktívne diagnózy')"
      @addItem="addDiagnoza"
      @editItem="editDiagnoza"
      :actions="[
        {
          text: $vuetify.t('Odstrániť diagnózu'),
          icon: 'delete',
          handler: deleteDiagnoza,
        },
      ]"
    >
      <template slot="items" slot-scope="{ item }">
        <v-container fluid>
          <v-layout row>
            <v-flex xs2>
              <v-tooltip top>
                <span slot="activator" v-if="item.diagnoza !== null"
                  >{{ item.date | formatDatum }} -
                  {{ item.diagnoza.datum_ukoncenia | formatDatum }}</span
                >
                <span>{{ $vuetify.t("Dátum od do") }}</span>
              </v-tooltip>
            </v-flex>
            <v-flex xs2>
              <v-tooltip top>
                <span slot="activator">{{
                  item.diagnoza.diagnoza.skratka
                }}</span>
                <span>{{ $vuetify.t("Skratka") }}</span>
              </v-tooltip>
            </v-flex>
            <v-flex xs8>
              <v-tooltip top>
                <span slot="activator"
                  >{{ item.diagnoza.diagnoza.display_name }}<br />{{
                    item.diagnoza.poznamka
                  }}</span
                >
                <span>{{ $vuetify.t("Popis") }}</span>
              </v-tooltip>
            </v-flex>
          </v-layout>
        </v-container>
      </template>
    </ApiList>
    <BaseDialog
      :dialogTitle="$vuetify.t(diagnozaDialogTitle)"
      ref="diagnozaDialog"
      @save="$refs.diagnozaForm.save()"
    >
      <form-diagnoza
        ref="diagnozaForm"
        :pacientId="id"
        @saved="savedDiagnoza"
      ></form-diagnoza>
    </BaseDialog>
  </v-container>
</template>

<script>
import ApiList from "./../../components/ApiList.vue"
import BaseDialog from "./../../components/BaseDialog.vue"
import FormDiagnoza from "./../../components/forms/FormDiagnoza.vue"
import * as diagnozaMixin from "./../../components/mixins/diagnoza.js"
import thingMixin from "@/components/mixins/thing.js"

export default {
  components: {
    ApiList,
    BaseDialog,
    FormDiagnoza,
  },
  props: ["id"],
  computed: {
    url() {
      return "/v1/proto.ThingService/ListThings"
    },
  },
  mixins: [diagnozaMixin.mixins, thingMixin],
  methods: {
    getParams: function (active) {
      return {
        patient_id: this.id,
        diagnoza: {
          active: active,
          dispenzarna: null,
        },
        table_request: {
          sort_by: "Date",
          sort_desc: true,
        },
      }
    },
    fetchData: function () {
      this.$refs.listAktivne.reload()
      this.$refs.listNeaktivne.reload()
    },
    deleteDiagnoza: async function (dg) {
      var result = await this._deleteThing(dg) // from thing mixin
      if (result) {
        this.fetchData()
      }
    },
  },
}
</script>
