<template>
  <div v-if="value && value !== null">
    <v-tooltip top v-if="toolbar">
      <template v-slot:activator="{ on }">
        <v-btn flat icon v-on="on">
          <v-icon :small="compact" :color="color">{{
            $vuetify.t(statusPrefix + "icon")
          }}</v-icon>
        </v-btn>
      </template>
      <span
        >{{ $vuetify.t(titleText) }}:
        <strong :class="[color !== '' ? color + '--text' : '']">{{
          $vuetify.t(statusPrefix + "text")
        }}</strong></span
      >
    </v-tooltip>
    <span v-else-if="table">
      <span>
        <v-icon :small="compact" :color="color">{{
          $vuetify.t(statusPrefix + "icon")
        }}</v-icon>
      </span>
      <span class="ml-2">
        <strong :class="[color !== '' ? color + '--text' : '']">{{
          $vuetify.t(statusPrefix + "text")
        }}</strong>
      </span>
    </span>
    <v-list v-else style="background: inherit">
      <v-list-tile avatar>
        <v-list-tile-avatar>
          <v-icon :small="compact" :color="color">{{
            $vuetify.t(statusPrefix + "icon")
          }}</v-icon>
        </v-list-tile-avatar>

        <v-list-tile-content>
          <v-list-tile-title v-if="!compact">{{
            $vuetify.t(titleText)
          }}</v-list-tile-title>
          <v-list-tile-sub-title>
            <strong :class="[color !== '' ? color + '--text' : '']">{{
              $vuetify.t(statusPrefix + "text")
            }}</strong>
          </v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>
    </v-list>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex"

export default {
  name: "EhealthRecord",
  props: {
    value: {
      required: true,
    },
    compact: {
      type: Boolean,
      required: false,
      default: false,
    },
    toolbar: {
      type: Boolean,
      required: false,
      default: false,
    },
    table: {
      type: Boolean,
      required: false,
      default: false,
    },
    objectID: {
      type: String,
      required: false,
      default: null,
    },
    titleText: {
      type: String,
      required: false,
      default: "ezdravie",
    },
    statusTextPrefix: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    status: function () {
      return this.value?.status
    },
    version_status: function () {
      return this.value?.version_status
    },
    statusPrefix: function () {
      return `${this.statusTextPrefix}.status.${this.status}.`
      //return `$vuetify.enum.ehealthRecord.versionStatus.${this.version_status}.status.${this.status}.`
    },
    color: function () {
      return this.$vuetify.t(this.statusPrefix + "color")
    },
    ...mapState({
      events: (state) => state.events.queue,
    }),
    ...mapGetters(["getUserEventTopic"]),
  },
  watch: {
    events: function (evt) {
      if (
        evt.event === this.getUserEventTopic("ehealth:ehealthRecord:changed") &&
        evt.data.object_id === this.objectID
      ) {
        console.log("EHEALTHRECORD", evt)
        this.$emit("input", evt.data)
      }
    },
  },
}
</script>
