<template>
  <v-form
    v-bind:style="{
      opacity: dragging ? '0.6' : '1',
      position: 'relative',
    }"
    @drop.native="onFileDragged"
    @dragenter.native="dragEnter"
    @dragover.native="dragEnter"
    @dragleave.native="dragLeave"
  >
    <v-container fluid>
      <v-layout row wrap>
        <v-flex xs12 class="text-xs-center">
          <img :src="fileUrl" height="300" v-if="fileUrl" />
        </v-flex>
        <v-flex xs12 class="text-xs-center">
          <v-text-field
            :disabled="this.model.id !== ''"
            label="Súbor"
            @click="pickFile"
            @click:prepend="pickFile"
            v-model="model.binary.name"
            prepend-icon="attach_file"
          ></v-text-field>
          <input
            type="file"
            style="display: none"
            ref="fileUpload"
            @change="onFilePicked"
          />
        </v-flex>
        <v-flex xs12>
          <v-textarea
            v-model="model.binary.poznamka"
            :label="$vuetify.t('Poznámka')"
          ></v-textarea>
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>
<script>
import * as Miscella from "./../../miscella.js"
import moment from "moment"
import BaseForm from "./../BaseForm.vue"

export default {
  name: "FormSubor",
  extends: BaseForm,
  $_veeValidate: {
    validator: "new",
  },
  components: {},
  mixins: [],
  data: function () {
    return {
      model: null,
      fileUrl: "",
      file: "",
      loading: true,
      dragging: false,
    }
  },
  computed: {
    ambulanceId: function () {
      let id = null
      if (Miscella.isSet(this.$store.state.me.ambulance)) {
        id = this.$store.state.me.ambulance.id
      }
      return id
    },
  },
  props: {
    patientId: String,
  },
  methods: {
    init: function () {
      console.log("init")
      this.model = {
        id: "",
        patient_id: this.patientId,
        ambulance_id: this.ambulanceId,
        date: moment().format(),
        parent_id: "",
        binary: {
          name: "",
          url: "",
          content_type: "",
          poznamka: "",
          size: 0,
        },
      }
      this.fileUrl = ""
      this.file = null
      this.$validator.reset()
      this.errors.clear()
    },
    validate: function () {
      return this.$validator.validateAll()
    },
    newSubor: function () {
      this.init()
    },
    editSubor: function (subor) {
      this.init()
      this.model = subor
    },
    pickFile() {
      this.$refs.fileUpload.click()
    },
    onFilePicked(e) {
      const files = e.target.files
      this.readFile(files[0])
    },
    readFile(file) {
      if (file !== undefined) {
        this.model.binary.name = file.name
        if (this.model.binary.name.lastIndexOf(".") <= 0) {
          return
        }
        this.model.binary.size = file.size
        const fr = new FileReader()
        fr.readAsDataURL(file)
        fr.addEventListener("load", () => {
          this.fileUrl = fr.result
          this.file = file
        })
      } else {
        this.model.binary.name = ""
        this.file = ""
        this.fileUrl = ""
      }
    },
    save: async function () {
      try {
        // TODO malo by sa to robit opacne, naprv upload binarky, potom ulozenie objektu thingBinary
        var params = { thing: Miscella.cloneObject(this.model) }
        var result = await this.$api.thingService.saveThing(params)
        this.model = result.thing
        // TODO nemala by sa ukladat cela url v tvare /v1/storage/UID (ten prefix /v1/storage by sa mohol zmenit?),
        //      ale len path, v tomto pripade UID, bolo by to v sulade aj s tym TODO vyssie a aj potom na serveri by sa
        //      dalo nieco s tym suborom robit, lebo v miniu je to ulozene iba pod tym UID, takto by sme museli to UID z
        //      toho url dolovat
        result = await this.$api.storageService.storagePost(
          this.model.binary.url,
          this.file
        )
        this.notifySuccess("Súbor bol úspešne vytvorený")
        this.$emit("saved", this.model)
      } catch (err) {
        this.processError(err, "Pri vytváraní súboru vznikla chyba")
      }
    },
    dragEnter: function (e) {
      console.log("dragEnter FORM")
      e.preventDefault()
      this.dragging = true
    },
    dragLeave: function (e) {
      e.preventDefault()
      console.log("dragLeave FORM")
      this.dragging = false
    },
    onFileDragged: function (e) {
      console.log("upload file by drag and drop FORM")
      e.preventDefault()
      e.stopPropagation()
      var files = e.dataTransfer.files
      this.readFile(files[0])
    },
  },
  created: function () {
    this.init()
  },
}
</script>
