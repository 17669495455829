var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',[_c('v-container',{attrs:{"fluid":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","md6":""}},[_c('selectable-api-table',{ref:"vykonyTable",attrs:{"headers":[
            {
              text: 'Kód',
              value: 'Kod',
              sortable: false,
            },
            {
              text: 'Popis',
              value: 'Popis',
              sortable: false,
            },
          ],"url":_vm.vykonyUrl,"title":_vm.$vuetify.t('Výkony'),"full-toolbar":false,"selectable":""},on:{"input":function($event){_vm.model.Vykony = $event}},scopedSlots:_vm._u([{key:"selectedItems",fn:function({ item }){return [_c('span',{staticClass:"text-truncate",staticStyle:{"max-width":"200px"},attrs:{"title":item.Popis}},[_vm._v(_vm._s(item.Kod)+" - "+_vm._s(item.Popis))])]}}])})],1),_c('v-flex',{attrs:{"xs12":"","md6":""}},[_c('selectable-api-table',{ref:"diagnozyTable",attrs:{"headers":[
            {
              text: 'Skratka',
              value: 'Skratka',
              sortable: false,
            },
            {
              text: 'Názov',
              value: 'DisplayName',
              sortable: false,
            },
          ],"url":_vm.diagnozyUrl,"title":_vm.$vuetify.t('Diagnózy'),"full-toolbar":false,"selectable":""},on:{"input":function($event){_vm.model.Diagnozy = $event}},scopedSlots:_vm._u([{key:"selectedItems",fn:function({ item }){return [_vm._v(" "+_vm._s(item.Skratka)+" - "+_vm._s(item.DisplayName)+" ")]}}])})],1),_c('v-flex',{attrs:{"xs7":""}},[_c('v-radio-group',{attrs:{"row":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticStyle:{"padding-right":"5px"}},[_vm._v(_vm._s(_vm.$vuetify.t("Jednotka")))])]},proxy:true}]),model:{value:(_vm.model.JeCena),callback:function ($$v) {_vm.$set(_vm.model, "JeCena", $$v)},expression:"model.JeCena"}},[_c('v-radio',{attrs:{"label":"Cena","value":true}}),_c('v-radio',{attrs:{"label":"Bod","value":false}}),_c('v-radio',{attrs:{"label":"Bez hodnoty","value":null}})],1)],1),_c('v-flex',{attrs:{"xs5":""}},[_c('TextField',{directives:[{name:"validate",rawName:"v-validate",value:({ decimal: true }),expression:"{ decimal: true }"}],attrs:{"type":"number","label":_vm.$vuetify.t('Hodnota'),"name":"hodnota"},model:{value:(_vm.model.Hodnota),callback:function ($$v) {_vm.$set(_vm.model, "Hodnota", $$v)},expression:"model.Hodnota"}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-autocomplete',{attrs:{"label":_vm.$vuetify.t('Cenník'),"items":_vm.cenniky,"item-text":"TypHodnoty","item-value":"ID","hide-no-data":"","clearable":""},model:{value:(_vm.model.CennikID),callback:function ($$v) {_vm.$set(_vm.model, "CennikID", $$v)},expression:"model.CennikID"}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-textarea',{attrs:{"label":_vm.$vuetify.t('Popis')},model:{value:(_vm.model.Popis),callback:function ($$v) {_vm.$set(_vm.model, "Popis", $$v)},expression:"model.Popis"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }