<template>
  <v-container
    fluid
    class="pa-0"
    v-if="vysetrenie !== null && vysetrenie !== undefined"
  >
    <v-layout row wrap>
      <v-flex xs12>
        <v-tabs v-model="tab">
          <v-tab ripple>
            {{ $vuetify.t("$vuetify.enum.evysetrenie.typ." + typ) }}
          </v-tab>
          <v-tab-item>
            <v-container fluid grid-list-xs class="px-0">
              <v-layout row wrap>
                <v-flex
                  xs12
                  md6
                  v-if="isOdborneVysetrenie || isZobrazovacieVysetrenie"
                >
                  <titled-text
                    title="Dátum a čas vyšetrenia"
                    :text="
                      vysetrenie.DatumACasVysetrenia !== null &&
                      vysetrenie.DatumACasVysetrenia !== undefined
                        ? $options.filters.formatDatumACas(
                            vysetrenie.DatumACasVysetrenia.time
                          )
                        : ' '
                    "
                  />
                </v-flex>
                <v-flex xs12 md3 v-if="isPrepustaciaSprava">
                  <titled-text
                    title="Dátum a čas prijatia"
                    :text="
                      vysetrenie.DatumACasPrijatia !== null &&
                      vysetrenie.DatumACasPrijatia !== undefined
                        ? $options.filters.formatDatumACas(
                            vysetrenie.DatumACasPrijatia.time
                          )
                        : ' '
                    "
                  />
                </v-flex>
                <v-flex xs12 md3 v-if="isPrepustaciaSprava">
                  <titled-text
                    title="Dátum a čas prepustenia"
                    :text="
                      vysetrenie.DatumACasPrepustenia !== null &&
                      vysetrenie.DatumACasPrepustenia !== undefined
                        ? $options.filters.formatDatumACas(
                            vysetrenie.DatumACasPrepustenia.time
                          )
                        : ' '
                    "
                  />
                </v-flex>
                <v-flex xs12 md3>
                  <titled-text
                    title="Dátum a čas odoslania"
                    :text="
                      vysetrenie.DatumACasOdoslania !== null &&
                      vysetrenie.DatumACasOdoslania !== undefined
                        ? $options.filters.formatDatumACas(
                            vysetrenie.DatumACasOdoslania.time
                          )
                        : ''
                    "
                  />
                </v-flex>
                <v-flex xs12 md3>
                  <titled-text
                    title="Urgentnosť"
                    :text="vysetrenie.UrgentnostVysetrenia.DisplayName"
                  />
                </v-flex>
                <v-flex
                  xs12
                  v-if="isOdborneVysetrenie || isZobrazovacieVysetrenie"
                >
                  <ezdravie-textovy-popis
                    title="Popis"
                    v-model="vysetrenie.Popis"
                  />
                </v-flex>
                <v-flex xs12 v-if="hasNazovVysetrenia">
                  <titled-text
                    title="Názov vyšetrenia"
                    :text="vysetrenie.NazovVysetrenia.originalText"
                  />
                </v-flex>
                <v-flex xs12 v-if="hasInternaPoznamka">
                  <titled-text
                    title="Interná poznámka"
                    :text="vysetrenie.InternaPoznamka.originalText"
                  />
                </v-flex>
                <v-flex
                  xs12
                  v-if="
                    (isOdborneVysetrenie || isPrepustaciaSprava) &&
                    hasPredpokladanyDatumPorodu
                  "
                >
                  <titled-text
                    title="Predpokladaný dátum pôrodu"
                    :text="
                      makeDatumPorodu(vysetrenie.PredpokladanyDatumPorodu)
                        | formatDatum
                    "
                  />
                </v-flex>
                <v-flex xs12>
                  <v-subheader class="caption">
                    {{ $vuetify.t("Ošetrujúci lekár") }}
                  </v-subheader>
                </v-flex>
                <v-flex xs6>
                  <titled-text title="Meno">
                    <ezdravie-zdravotnicky-pracovnik
                      ref="zdravotnickyPracovnikField"
                      :ii="vysetrenie.InfoProvider.Performer"
                    />
                  </titled-text>
                </v-flex>
                <v-flex xs12 md6>
                  <titled-text
                    title="Špecializácia"
                    :text="vysetrenie.InfoProvider.Function.DisplayName"
                  />
                </v-flex>
                <v-flex xs12>
                  <titled-text title="OÚ PZS">
                    <ezdravie-ou-pzs
                      ref="ouPzsField"
                      :ii="vysetrenie.InfoProvider.HealthcareFacillity"
                    />
                  </titled-text>
                </v-flex>

                <v-flex xs12 v-if="vysetrenie.DiagnostickyZaver">
                  <v-subheader class="caption">
                    {{ $vuetify.t("Diagnostický záver") }}
                  </v-subheader>
                  <v-data-table
                    :headers="[
                      {
                        text: 'Hlavná diagnóza',
                        value: 'hlavna',
                        sortable: false,
                      },
                      {
                        text: 'Život ovplyvňujúca diagnóza',
                        value: 'zivotOvplyvnujuca',
                        sortable: false,
                      },
                      {
                        text: 'Názov diagnózy',
                        value: 'nazov',
                        sortable: false,
                      },
                      {
                        text: 'Upresnenie diagnózy',
                        value: 'upresnenie',
                        sortable: false,
                      },
                    ]"
                    :items="vysetrenie.DiagnostickyZaver"
                    class="elevation-4"
                    hide-actions
                  >
                    <template v-slot:items="{ item }">
                      <tr class="noclicker">
                        <td>
                          <v-checkbox
                            :input-value="item.HlavnaDiagnoza.value"
                            primary
                            hide-details
                            readonly
                          ></v-checkbox>
                        </td>
                        <td>
                          <v-checkbox
                            :input-value="item.ZivotOvplyvnujucaDiagnoza.value"
                            primary
                            hide-details
                            readonly
                          ></v-checkbox>
                        </td>
                        <td>{{ item.Diagnoza.KodDiagnozy.DisplayName }}</td>
                        <td>{{ item.Diagnoza.Upresnenie.originalText }}</td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-flex>
                <v-flex xs12 v-if="vysetrenie.DiagnozaPriOdoslani">
                  <v-subheader class="caption">
                    {{ $vuetify.t("Diagnóza pri odoslaní") }}
                  </v-subheader>
                  <v-data-table
                    :headers="[
                      {
                        text: 'Názov diagnózy',
                        value: 'nazov',
                        sortable: false,
                      },
                      {
                        text: 'Upresnenie diagnózy',
                        value: 'upresnenie',
                        sortable: false,
                      },
                    ]"
                    :items="vysetrenie.DiagnozaPriOdoslani"
                    class="elevation-4"
                    hide-actions
                  >
                    <template v-slot:items="{ item }">
                      <td>{{ item.KodDiagnozy.DisplayName }}</td>
                      <td>{{ item.Upresnenie.originalText }}</td>
                    </template>
                  </v-data-table>
                </v-flex>
                <v-flex xs12 v-if="vysetrenie.OdosielajuciLekar">
                  <v-subheader class="caption">{{
                    $vuetify.t("Odosielajúci lekár")
                  }}</v-subheader>
                  <v-container fluid grid-list-xs class="pa-0">
                    <v-layout row wrap>
                      <v-flex xs6>
                        <titled-text title="Meno">
                          <ezdravie-zdravotnicky-pracovnik
                            :ii="
                              vysetrenie.OdosielajuciLekar.IdentifikaciaZPrac
                            "
                          />
                        </titled-text>
                      </v-flex>
                      <v-flex xs12 md6>
                        <titled-text
                          title="Špecializácia"
                          :text="
                            vysetrenie.OdosielajuciLekar.SpecializaciaLekara
                              .DisplayName
                          "
                        />
                      </v-flex>
                      <v-flex xs12 md6>
                        <titled-text title="OÚ PZS">
                          <ezdravie-ou-pzs
                            :ii="
                              vysetrenie.OdosielajuciLekar.IdentifikaciaOupzs
                            "
                          />
                        </titled-text>
                      </v-flex>
                      <v-flex xs12 md6>
                        <titled-text
                          title="Nezmluvný lekár"
                          :text="
                            $vuetify.t(
                              vysetrenie.OdosielajuciLekar.NezmluvnyLekar
                                ? 'Áno'
                                : 'Nie'
                            )
                          "
                        />
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-flex>
              </v-layout>
              <v-flex
                xs12
                v-if="
                  (isOdborneVysetrenie || isPrepustaciaSprava) && hasAlergen
                "
              >
                <v-subheader class="caption">
                  {{ $vuetify.t("Alergické reakcie") }}
                </v-subheader>
                <v-data-table
                  :headers="[
                    {
                      text: 'Látka',
                      value: 'latka',
                      sortable: false,
                    },
                    {
                      text: 'Liečivo',
                      value: 'liecivo',
                      sortable: false,
                    },
                  ]"
                  :items="vysetrenie.Alergen"
                  class="elevation-4"
                  hide-actions
                >
                  <template v-slot:items="{ item }">
                    <tr class="noclicker">
                      <td v-if="hasLatka(item)">
                        {{ item.Latka.DisplayName }}
                      </td>
                      <td v-if="hasLiecivo(item)">
                        {{ item.Liecivo.DisplayName }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-flex>
              <v-flex
                xs12
                v-if="
                  (isOdborneVysetrenie || isPrepustaciaSprava) && hasMedikacia
                "
              >
                <v-subheader class="caption">
                  {{ $vuetify.t("Medikácia") }}
                </v-subheader>
                <v-data-table
                  :headers="[
                    {
                      text: 'Popis Medikácie',
                      value: 'popisMedikacie',
                      sortable: false,
                    },
                  ]"
                  :items="vysetrenie.Medikacia"
                  class="elevation-4"
                  hide-actions
                >
                  <template v-slot:items="{ item }">
                    <tr class="noclicker">
                      <td>{{ item.PopisMedikacie.originalText }}</td>
                    </tr>
                  </template>
                </v-data-table>
              </v-flex>
              <v-flex
                xs12
                v-if="
                  (isOdborneVysetrenie || isPrepustaciaSprava) &&
                  hasImplantovanaZdravotnickaPomocka
                "
              >
                <v-subheader class="caption">
                  {{ $vuetify.t("Implantovaná zdravotnícka pomôcka") }}
                </v-subheader>
                <v-data-table
                  :headers="[
                    {
                      text: 'Druh zdravotníckej pomôcky',
                      value: 'druhZdravotnickejPomocky',
                      sortable: false,
                    },
                    {
                      text: 'Popis zdravotníckej pomôcky',
                      value: 'popisZdravotnickejPomocky',
                      sortable: false,
                    },
                  ]"
                  :items="vysetrenie.ImplantovanaZdravotnickaPomocka"
                  class="elevation-4"
                  hide-actions
                >
                  <template v-slot:items="{ item }">
                    <tr class="noclicker">
                      <td v-if="hasDruhZP(item)">
                        {{ item.Druh.DisplayName }}
                      </td>
                      <td v-if="hasPopisZP(item)">
                        {{ item.PopisZdravotnickejPomocky.originalText }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-flex>
            </v-container>
            <EZdravieOdborneVysetrenie
              v-if="vysetrenie !== null && isOdborneVysetrenie"
              :vysetrenie="vysetrenie"
            ></EZdravieOdborneVysetrenie>
            <EZdravieZobrazovacieVysetrenie
              v-if="vysetrenie !== null && isZobrazovacieVysetrenie"
              :vysetrenie="vysetrenie"
            ></EZdravieZobrazovacieVysetrenie>
            <EZdraviePrepustaciaSprava
              v-if="vysetrenie !== null && isPrepustaciaSprava"
              :vysetrenie="vysetrenie"
            ></EZdraviePrepustaciaSprava>
          </v-tab-item>
          <v-tab
            ripple
            v-if="
              vysetrenie.OdporucanieNaVysetrenie &&
              (isOdborneVysetrenie || isPrepustaciaSprava)
            "
          >
            {{ $vuetify.t("Výmenné lístky") }}
          </v-tab>
          <v-tab-item
            v-if="
              vysetrenie.OdporucanieNaVysetrenie &&
              (isOdborneVysetrenie || isPrepustaciaSprava)
            "
          >
            <template
              v-for="(odporucanie, index) in vysetrenie.OdporucanieNaVysetrenie"
            >
              <v-card
                :key="'ezdravie-vysetrenie-odporucanie' + index"
                class="mt-4 elevation-4"
              >
                <v-toolbar flat dense card color="grey darken-2" dark>
                  <v-toolbar-title>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <span v-on="on" class="subheading">
                          <span class="font-weight-medium pl-1">
                            {{
                              odporucanie.IdentifikatorVymennehoListku.extension
                            }}</span
                          >
                        </span>
                      </template>
                      <span>{{
                        $vuetify.t("Identifikátor výmenného lístka")
                      }}</span>
                    </v-tooltip>
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-tooltip top v-if="showPrevezmiVymennyListokButton">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        flat
                        icon
                        v-on="on"
                        @click="
                          $emit(
                            'prevzatieVymennehoListka',
                            odporucanie.IdentifikatorVymennehoListku.extension
                          )
                        "
                        ><v-icon>download_done</v-icon></v-btn
                      >
                    </template>
                    <span>{{ $vuetify.t("Prevezmi výmenný lístok") }}</span>
                  </v-tooltip>
                  <v-tooltip top v-if="showPrevezmiVymennyListokButton">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        flat
                        icon
                        v-on="on"
                        @click="vytvorVysledok(odporucanie)"
                        ><v-icon>post_add</v-icon></v-btn
                      >
                    </template>
                    <span>{{ $vuetify.t("Vytvor vyšetrenie k VL") }}</span>
                  </v-tooltip>
                </v-toolbar>
                <v-card-text>
                  <EZdravieOdporucanie
                    v-if="vysetrenie.OdporucanieNaVysetrenie.length > 0"
                    :pacient-id="pacientId"
                    :odporucanie="odporucanie"
                  ></EZdravieOdporucanie>
                </v-card-text>
              </v-card>
            </template>
          </v-tab-item>
        </v-tabs>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import EZdravieOdborneVysetrenie from "./../ezdravie/EZdravieOdborneVysetrenie.vue"
import EZdravieZobrazovacieVysetrenie from "./../ezdravie/EZdravieZobrazovacieVysetrenie.vue"
import EZdraviePrepustaciaSprava from "./../ezdravie/EZdraviePrepustaciaSprava.vue"
import EZdravieOdporucanie from "./../ezdravie/EZdravieOdporucanie.vue"
import EzdravieZdravotnickyPracovnik from "../ezdravie/EZdravieZdravotnickyPracovnik.vue"
import EzdravieOuPzs from "../ezdravie/EZdravieOuPzs.vue"
import TitledText from "./TitledText.vue"
import * as Miscella from "./../../miscella.js"
import moment from "moment"
import EzdravieTextovyPopis from "./EzdravieTextovyPopis.vue"

export default {
  name: "EZdravieVysetrenie",
  props: {
    pacientId: {
      type: String,
      required: false,
    },
    vysetrenie: {
      type: Object,
      required: false,
    },
    showPrevezmiVymennyListokButton: {
      type: Boolean,
      default: false,
    },
    showVytvorenieVysledkuButton: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TitledText,
    EZdravieOdborneVysetrenie,
    EZdravieZobrazovacieVysetrenie,
    EZdraviePrepustaciaSprava,
    EZdravieOdporucanie,
    EzdravieZdravotnickyPracovnik,
    EzdravieOuPzs,
    EzdravieTextovyPopis,
  },
  data: function () {
    return {
      tab: 0,
    }
  },
  computed: {
    typ: function () {
      switch (this.vysetrenie.RcIdOid) {
        case "1.3.158.00165387.100.60.90":
          return "ZAZNAM_O_ODBORNOM_VYSETRENI"
          break
        case "1.3.158.00165387.100.60.80":
          return "ZAZNAM_O_ZOBRAZOVACOM_VYSETRENI"
          break
        case "1.3.158.00165387.100.60.100":
          return "ZAZNAM_PREPUSTACIA_SPRAVA"
          break
      }
      return "ZAZNAM_O_ODBORNOM_VYSETRENI"
      //throw "Neznamy typ vysetrenia"
    },
    saveUrl: function () {
      var url = "/api/patient/" + this.pacientId
      switch (this.vysetrenie.Typ) {
        case "ZAZNAM_O_ODBORNOM_VYSETRENI":
          url += "/ezdravieOdborneVysetrenie"
          break
        case "ZAZNAM_O_ZOBRAZOVACOM_VYSETRENI":
          url += "/ezdravieZobrazovacieVysetrenie"
          break
        case "ZAZNAM_PREPUSTACIA_SPRAVA":
          url += "/ezdraviePrepustaciaSprava"
          break
      }
      return url
    },
    isOdborneVysetrenie: function () {
      return this.typ === "ZAZNAM_O_ODBORNOM_VYSETRENI"
    },
    isZobrazovacieVysetrenie: function () {
      return this.typ === "ZAZNAM_O_ZOBRAZOVACOM_VYSETRENI"
    },
    isPrepustaciaSprava: function () {
      return this.typ === "ZAZNAM_PREPUSTACIA_SPRAVA"
    },
    hasMedikacia: function () {
      return this.vysetrenie.Medikacia != null
    },
    hasInternaPoznamka: function () {
      return this.vysetrenie.InternaPoznamka != null
    },
    hasNazovVysetrenia: function () {
      return this.vysetrenie.NazovVysetrenia != null
    },
    hasPredpokladanyDatumPorodu: function () {
      return this.vysetrenie.PredpokladanyDatumPorodu != null
    },
    hasAlergen: function () {
      return this.vysetrenie.Alergen != null
    },
    hasImplantovanaZdravotnickaPomocka: function () {
      return this.vysetrenie.ImplantovanaZdravotnickaPomocka != null
    },
  },
  methods: {
    save: async function () {
      try {
        /*var params = Miscella.cloneObject(this.vysetrenie)
        if (
          params.Typ === "ZAZNAM_O_ODBORNOM_VYSETRENI" &&
          params.OdporucaneVysetrenie !== null
        ) {
          for (var vl of params.OdporucaneVysetrenie) {
            if (
              vl.IdentifikatorVymennehoListku !== null &&
              vl.IdentifikatorVymennehoListku.Extension.search(
                this.odporucanieID
              ) >= 0
            ) {
              this.odporucanie = vl
            }
            vl.Platnost = moment(vl.Platnost).format()
          }
        }
        params.DatumACasOdoslania = moment(
          this.vysetrenie.DatumACasOdoslania
        ).format()
        params.DatumACasVysetrenia = moment(
          this.vysetrenie.DatumACasVysetrenia
        ).format()
        params.FeederAudit.Time_committed = moment(
          this.vysetrenie.FeederAudit.Time_committed
        ).format()
        var result = await this.$api.post(this.saveUrl, params)
        this.$store.commit(
          "setSuccess",
          this.$vuetify.t("Externé vyšetrenie bolo úspešne uložené")
        )*/
        //this.$emit("saved", result)
        this.$emit("saved", this.vysetrenie)
      } catch (err) {
        this.$store.commit(
          "setError",
          this.$vuetify.t("Pri ukladaní externého vyšetrenia vznikla chyba ") +
            " (" +
            err +
            ")."
        )
      }
    },
    vytvorVysledok: function (odporucanie) {
      this.$emit("vytvorenieVysledku", {
        poziadavka_na_vysetrenie:
          odporucanie.IdentifikatorVymennehoListku.extension,
        datum_a_cas_odoslania: this.vysetrenie.DatumACasVysetrenia,
        odosielajuci_lekar: this.vysetrenie.InfoProvider.Performer.extension,
        odosielajuci_lekar_oupzs:
          this.vysetrenie.InfoProvider.HealthcareFacillity.extension,
        diagnoza_pri_odoslani: odporucanie.Diagnoza,
      })
    },
    hasLatka: function (alergen) {
      return alergen.Latka != null
    },
    hasLiecivo: function (alergen) {
      return alergen.Liecivo != null
    },
    hasPopisZP: function (pomocka) {
      return pomocka.PopisZdravotnickejPomocky != null
    },
    hasDruhZP: function (pomocka) {
      return pomocka.Druh != null
    },
    makeDatumPorodu: function (datumPorodu) {
      return moment([datumPorodu.Year, datumPorodu.Month - 1, datumPorodu.Day])
    },
  },
}
</script>
