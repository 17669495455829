import Vue from "vue"
import Router from "vue-router"

export const router = new Router({
  routes: [
    {
      path: "/",
      redirect: { name: "login" },
    },
    {
      path: "/unlogged",
      name: "unlogged",
      component: require("./components/Unlogged.vue").default,
      children: [
        {
          path: "/login",
          name: "login",
          component: require("./pages/Login.vue").default,
        },
        {
          path: "/registration",
          name: "registration",
          component: require("./pages/Registration.vue").default,
        },
        {
          path: "/passwordChange/:token?",
          name: "passwordChange",
          component: require("./pages/PasswordChange.vue").default,
          props: true,
        },
        {
          path: "/passwordReset",
          name: "passwordReset",
          component: require("./pages/PasswordReset.vue").default,
          props: true,
        },
        {
          path: "/adminAccountVerification/:token?",
          name: "adminAccountVerification",
          component: require("./pages/AdminAccountVerification.vue").default,
          props: true,
        },
        {
          path: "/userAccountVerification/:token?",
          name: "userAccountVerification",
          component: require("./pages/UserAccountVerification.vue").default,
          props: true,
        },
      ],
    },
    {
      path: "/logged",
      component: require("./pages/Logged.vue").default,
      children: [
        {
          path: "/menu",
          name: "loggedWithMenu",
          component: require("./pages/LoggedWithMenu.vue").default,
          children: [
            // home is default page for the application, every place that wants to redirect to "Home Page" should redirect to /home
            // the purpose of this is to create a single point, where the home/start page can be set
            { path: "/home", redirect: "/patients" },
            {
              path: "/dashboarduser",
              name: "dashboardUser",
              component: require("./pages/DashboardUser.vue").default,
            },
            {
              path: "/dashboard",
              name: "dashboard",
              component: require("./pages/Dashboard.vue").default,
            },
            {
              path: "/calendar",
              name: "calendar",
              component: require("./pages/Calendar.vue").default,
            },
            {
              path: "/patients",
              name: "pacienti",
              component: require("./pages/Pacienti.vue").default,
            },
            {
              path: "/patients/:id",
              name: "pacient",
              component: require("./pages/pacient/Pacient.vue").default,
              props: true,
              redirect: "/patients/:id/vysetrenie",
              children: [
                {
                  path: "/patients/:id/info/:tab?",
                  component: require("./pages/pacient/Info.vue").default,
                  props: true,
                  name: "info",
                },
                {
                  path: "/patients/:id/vysetrenie/:vysetrenieID?",
                  component: require("./pages/pacient/Vysetrenie.vue").default,
                  props: true,
                  name: "vysetrenie",
                },
                // {path: '/patient/:id/prehlad', component: require('./pages/patient/Prehlad.vue').default, props: true},
                {
                  path: "/patients/:id/karta",
                  component: require("./pages/pacient/Karta.vue").default,
                  props: true,
                  name: "karta",
                },
                {
                  path: "/patients/:id/ockovania",
                  component: require("./pages/pacient/Ockovania.vue").default,
                  name: "ockovania",
                  props: true,
                },
                {
                  path: "/patients/:id/diagnozy",
                  component: require("./pages/pacient/Diagnozy.vue").default,
                  props: true,
                  name: "diagnozy",
                },
                {
                  path: "/patients/:id/recepty",
                  component: require("./pages/pacient/Lieky.vue").default,
                  props: true,
                  name: "recepty",
                },
                {
                  path: "/patients/:id/reakcie",
                  component: require("./pages/pacient/NeziaduceReakcie.vue")
                    .default,
                  props: true,
                  name: "reakcie",
                },
                {
                  path: "/patients/:id/vykon",
                  component: require("./pages/pacient/Vykony.vue").default,
                  props: true,
                  name: "vykon",
                },
                {
                  path: "/patients/:id/tlak",
                  component: require("./pages/pacient/KrvnyTlak.vue").default,

                  props: true,
                  name: "tlak",
                },
                {
                  path: "/patients/:id/skupina",
                  component: require("./pages/pacient/KrvnaSkupina.vue")
                    .default,
                  props: true,
                  name: "skupina",
                },
                {
                  path: "/patients/:id/vitAntropHodnoty",
                  component: require("./pages/pacient/VitAntropHodnoty.vue")
                    .default,
                  props: true,
                  name: "vitAntropHodnoty",
                },
                {
                  path: "/patients/:id/chirurgickyVykon",
                  component: require("./pages/pacient/ChirurgickeVykony.vue")
                    .default,
                  props: true,
                  name: "chirurgickyVykon",
                },
                {
                  path: "/patients/:id/rodina",
                  component: require("./pages/pacient/Rodina.vue").default,
                  props: true,
                  name: "rodina",
                },
                {
                  path: "/patients/:id/subory",
                  component: require("./pages/pacient/Subory.vue").default,
                  props: true,
                  name: "subory",
                },
                {
                  path: "/patients/:id/poistovne",
                  component: require("./pages/pacient/Poistovne.vue").default,
                  name: "poistovne",
                  props: true,
                },
                {
                  path: "/patients/:id/komunikacia",
                  component: require("./pages/pacient/Komunikacia.vue").default,
                  props: true,
                  name: "komunikacia",
                },
                {
                  path: "/patients/:id/ehealth",
                  component: require("./pages/pacient/Ehealth.vue").default,
                  props: true,
                  name: "ehealth",
                },
                {
                  path: "/patients/:id/odporucania",
                  component: require("./pages/pacient/Odporucania.vue").default,
                  props: true,
                  name: "odporucania",
                },
                {
                  path: "/patients/:id/edpn",
                  component: require("./pages/pacient/EDpn.vue").default,
                  props: true,
                  name: "edpn",
                },
              ],
            },
            {
              path: "/admin",
              component: require("./pages/Admin.vue").default,
              redirect: "/admin/me",
              children: [
                {
                  path: "/admin/ambulances",
                  component: require("./pages/admin/Ambulances.vue").default,
                  name: "ambulances",
                },
                {
                  path: "/admin/users",
                  component: require("./pages/admin/Users.vue").default,
                },
                {
                  path: "/admin/me",
                  component: require("./pages/admin/Me.vue").default,
                },
                {
                  path: "/admin/clinic",
                  component: require("./pages/admin/Clinic.vue").default,
                },
                {
                  path: "/admin/logs",
                  component: require("./pages/admin/Logs.vue").default,
                  redirect: "/admin/logs/eZdravieLog",
                  children: [
                    {
                      path: "/admin/logs/eZdravieLog",
                      component: require("./pages/admin/logs/EZdravieLog.vue")
                        .default,
                    },
                    {
                      path: "/admin/logs/eVysetrenieQueue",
                      component:
                        require("./pages/admin/logs/EVysetrenieQueue.vue")
                          .default,
                    },
                    {
                      path: "/admin/logs/eReceptLog",
                      component: require("./pages/admin/logs/EReceptLog.vue")
                        .default,
                    },
                  ],
                },
                {
                  path: "/admin/chat",
                  component: require("./pages/admin/Chat.vue").default,
                },
                {
                  path: "/admin/zmluvnesubjekty",
                  component: require("./pages/admin/ZmluvneSubjekty.vue")
                    .default,
                },
                {
                  path: "/admin/zmluvnesubjekty/:subjectId/cenniky",
                  component: require("./pages/admin/ZmluvneSubjektyCennik.vue")
                    .default,
                  props: true,
                },
                {
                  path: "/admin/sablony",
                  component: require("./pages/admin/Sablony.vue").default,
                },
                {
                  path: "/admin/codebook/codebooks",
                  component: require("./pages/codebook/Codebooks.vue").default,
                },
                {
                  path: "/admin/codebook/zdravotnickaPomocka",
                  component: require("./pages/codebook/ZdravotnickaPomocka.vue")
                    .default,
                },
                {
                  path: "/admin/forms",
                  component: require("./pages/admin/Forms.vue").default,
                },
                {
                  path: "/admin/poistovne/exportdavok",
                  component: require("./pages/poistovne/ExportDavokPreZP.vue")
                    .default,
                },
                {
                  path: "/admin/poistovne/poistovnazmluvy",
                  component: require("./pages/poistovne/PoistovnaZmluvy.vue")
                    .default,
                },
                {
                  path: "/admin/poistovne/:poistovna/cenniky",
                  component: require("./pages/poistovne/PoistovnaCenniky.vue")
                    .default,
                  props: true,
                },
                {
                  path: "/admin/poistovne/exportdavok/:obdobieId/davky",
                  component: require("./pages/poistovne/DavkyPreZP.vue")
                    .default,
                  props: true,
                },
                {
                  path: "/admin/edpn",
                  component: require("./pages/admin/EDpn.vue").default,
                  props: true,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "/print",
      component: require("./pages/Print.vue").default,
      children: [
        {
          path: "/print/vysetrenie",
          name: "printVysetrenie",
          component: require("./pages/print/PrintVysetrenie.vue").default,
        },
        {
          path: "/print/dpn",
          name: "printPotvrdenieDPN",
          component: require("./pages/print/PrintPotvrdenieDPN.vue").default,
        },
      ],
    },
  ],
})

router.beforeResolve((to, from, next) => {
  console.log("router.beforeResolve: from: ", from, " to: ", to)
  console.log(
    "router.beforeResolve: start beforeEach",
    localStorage.getItem("token")
  )

  if (localStorage.getItem("token") !== null) {
    console.log(
      "beforeEach: token exists => authorised => go to dashboard or requested page"
    )
    if (to.matched[0].path !== "/logged" && to.matched[0].path !== "/print") {
      console.log("beforeEach: go to home page", to)
      next("/home")
    } else {
      next()
    }
  } else {
    console.log("beforeEach: token not exists")
    // if (!localStorage.getItem('clinic')) {
    console.log("beforeEach: in storage no clinic, ask server")
    if (to.matched[0].path === "/unlogged") {
      next()
    } else {
      next("/login")
    }
  }
})

Vue.use(Router)
