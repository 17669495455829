import BaseApiService from "./baseApiService.js"

export default class PatientService extends BaseApiService {
  constructor(api) {
    super(
      api,
      "/v1/proto.PatientService/CreatePatient",
      "/v1/proto.PatientService/GetPatient",
      "/v1/proto.PatientService/ListPatients",
      "/v1/proto.PatientService/UpdatePatient"
    )
  }
}
