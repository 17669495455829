<template>
  <v-form ref="axonForm">
    <v-container fluid>
      <v-layout row wrap>
        <v-flex mb-4 xs12>
          <v-subheader class="title">{{ formDefinition.title }}</v-subheader>
          <v-subheader class="subtitle-1">{{
            formDefinition.description
          }}</v-subheader>
        </v-flex>
        <template v-for="(item, index) in formDefinition.polozky">
          <v-flex :key="index" pa-4 :class="flexSize(item)">
            <form-component
              :formItemDefinition="item"
              v-model="formData.polozky[item.id]"
            />
          </v-flex>
        </template>
        <v-flex xs12 v-if="showButtons">
          <v-btn color="primary" @click="save">Uložiť</v-btn>
          <v-btn color="secondary" @click="init">Reset</v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
import FormComponent from "./FormComponent.vue"

export default {
  components: {
    FormComponent,
  },
  data: function () {
    return {}
  },
  props: {
    formDefinition: {
      type: Object,
      required: true,
    },
    formData: {
      type: Object,
      required: true,
    },
    showButtons: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {},
  methods: {
    flexSize: function (polozka) {
      let ret = "xs12 sm6"
      let wideItems = ["subheader", "separator", "textarea"]
      if (wideItems.indexOf(polozka.typ_polozka) > -1) {
        ret = "xs12"
      }
      return ret
    },
    init: function () {
      //this.$refs.axonForm.reset()
      this.$refs.axonForm.resetValidation()
    },
    save: function () {
      if (this.$refs.axonForm.validate()) {
        this.$emit("save") // TODO: add result - formData resp. formData.data
      } else {
        alert("Formulár obsahuje chyby")
      }
    },
  },
}
</script>

<style scoped></style>
