<template>
  <div class="fill-height">
    <v-navigation-drawer app clipped permanent width="250">
      <v-list dense>
        <menu-item
          link="/admin/ambulances"
          text="Ambulancie"
          v-if="isUserAdmin"
        />
        <menu-item link="/admin/users" text="Používatelia" v-if="isUserAdmin" />
        <menu-item link="/admin/me" text="Moje info" />
        <menu-item link="/admin/clinic" text="PZS" v-if="isUserAdmin" />
        <menu-item link="/admin/logs" text="Logy" v-if="isUserAdmin" />

        <!-- TODO zatial zakomentovane v ramci prechodu na cloud, nedokoncena funkcionalita -->
        <!-- <menu-item link="/admin/zmluvnesubjekty" text="Zmluvné subjekty" /> -->
        <menu-item link="/admin/codebook/codebooks" text="Číselníky" />
        <menu-item link="/admin/chat" text="Chat" />
        <menu-item link="/admin/forms" text="Formuláre" />
        <menu-item link="/admin/edpn" text="Expirujúce eDPN" />
        <!-- menu-item link="/admin/codebook/liek" text="Lieky" /-->
        <!-- <menu-item -->
        <!--   link="/admin/codebook/zdravotnickaPomocka" -->
        <!--   text="Zdravotnícke pomôcky" -->
        <!-- /> -->
        <!-- <menu-item link="/admin/codebook/atc" text="ATC skupiny" /> -->

        <!-- TODO zatial zakomentovane v ramci prechodu na cloud, nedokoncena funkcionalita -->
        <!-- <v-list-group :value="null" class="ml-3"> -->
        <!--   <template v-slot:activator> -->
        <!--     <v-list-tile-title>{{ $vuetify.t("Poisťovne") }}</v-list-tile-title> -->
        <!--   </template> -->
        <!--   <menu-item link="/admin/poistovne/exportdavok" text="Export dávok" /> -->
        <!--   <menu-item link="/admin/poistovne/poistovnazmluvy" text="Zmluvy" /> -->
        <!-- </v-list-group> -->
      </v-list>
    </v-navigation-drawer>
    <v-content app class="fill-height">
      <router-view></router-view>
    </v-content>
  </div>
</template>

<script>
import MenuItem from "./../components/MenuItem.vue"
import { mapGetters } from "vuex"

export default {
  name: "Admin",
  components: {
    MenuItem,
  },
  computed: {
    ...mapGetters(["isUserAdmin"]),
  },
}
</script>
