<template>
  <v-layout align-center justify-center>
    <v-flex xs12 sm8 md4>
      <v-card class="elevation-12">
        <v-toolbar flat dense card>
          <v-toolbar-title>{{ $vuetify.t("Zabudnuté heslo") }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <div v-if="sent">
            <div v-if="success" class="text-md-left px-4">
              <h3 class="headline">
                {{ $vuetify.t("Požiadavka na obnovu hesla bola úspešná") }}
              </h3>
              <p>
                {{
                  $vuetify.t(
                    "Na Váš e-mail bol úspešne odoslaný odkaz na obnovu hesla. Prosím postupujte ďalej podľa inštrukcií obsiahnutých v doručenej správe."
                  )
                }}
              </p>
            </div>
            <v-alert v-else type="error" :value="true">
              {{
                $vuetify.t(
                  "Nastala chyba! Odkaz na obnovu hesla nebol odoslaný na Váš e-mail."
                )
              }}
            </v-alert>
          </div>
          <div v-else>
            <v-container grid-list-md class="pa-0">
              <v-layout row wrap>
                <v-flex xs12>
                  <TextField
                    v-model="form.email"
                    ref="emailField"
                    name="form.email"
                    v-validate="'required|email'"
                    :maxlength="100"
                    :label="$vuetify.t('E-mail')"
                    autocomplete="username"
                    :hint="
                      $vuetify.t(
                        'Zadajte prosím Váš prihlasovací e-mail. Následne na túto adresu pošleme odkaz pre obnovu hesla.'
                      )
                    "
                    :keyUpEnter="send"
                  ></TextField>
                </v-flex>
              </v-layout>
            </v-container>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn flat @click="goToLogin">{{
            $vuetify.t("Späť na prihlásenie")
          }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn flat v-if="!sent" @click="send" color="primary">{{
            $vuetify.t("Odoslať")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import TextField from "@/components/TextField.vue"

export default {
  name: "PasswordReset",
  $_veeValidate: {
    validator: "new",
  },
  components: { TextField },
  props: {
    token: {
      type: String,
      required: false,
    },
  },
  data: function () {
    return {
      sent: false,
      success: false,
      form: {
        email: "",
      },
    }
  },
  methods: {
    send: async function () {
      var valid = await this.$validator.validateAll()
      if (!valid) {
        return
      }
      try {
        await this.$api.post("/v1/proto.Registration/PasswordReset", {
          email: this.form.email,
        })
        this.success = true
      } catch (err) {
      } finally {
        this.sent = true
      }
    },
    goToLogin: function () {
      this.$router.replace({ name: "login" })
    },
  },
  mounted: function () {
    this.$refs.emailField.focus()
  },
}
</script>
