<template>
  <div>
    <v-form>
      <v-tabs v-model="activeTab">
        <v-tab>{{ $vuetify.t("Základné údaje") }}</v-tab>
        <v-tab>{{ $vuetify.t("Súvisiace vyšetrenia") }}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="activeTab">
        <v-tab-item>
          <v-container fluid grid-list-md>
            <v-layout row wrap>
              <v-flex xs12 md6>
                <DatePicker
                  v-model="model.date"
                  :label="$vuetify.t('Dátum')"
                  name="datum"
                  v-validate="'required'"
                ></DatePicker>
              </v-flex>
              <v-flex xs12 md6>
                <codebook-autocomplete
                  codebook-id="123"
                  name="urgentnost"
                  v-model="model.odporucanie.urgentnost"
                  :label="$vuetify.t('Urgentnosť')"
                  v-validate="'required'"
                  :limit="0"
                >
                </codebook-autocomplete>
              </v-flex>
              <v-flex xs12 md12>
                <codebook-autocomplete
                  codebook-id="25"
                  name="diagnoza"
                  v-model="model.odporucanie.diagnoza"
                  :label="$vuetify.t('Diagnóza')"
                  v-validate="'required'"
                >
                  <template v-slot:selection="{ item }"
                    ><span
                      >{{ item.skratka }} - {{ item.display_name }}</span
                    ></template
                  >
                  <template v-slot:item="{ item }">
                    <v-list-tile-content>
                      <v-list-tile-title
                        v-html="item.skratka"
                      ></v-list-tile-title>
                      <v-list-tile-sub-title
                        v-html="item.display_name"
                      ></v-list-tile-sub-title>
                    </v-list-tile-content>
                  </template>
                </codebook-autocomplete>
              </v-flex>
              <v-flex xs12 md6>
                <codebook-autocomplete
                  codebook-id="37"
                  name="druh_specializovaneho_utvaru"
                  v-model="model.odporucanie.druh_specializovaneho_utvaru"
                  :label="$vuetify.t('Druh špecializovaného útvaru')"
                  v-validate="'required'"
                >
                </codebook-autocomplete>
              </v-flex>
              <v-flex xs12 md6>
                <codebook-autocomplete
                  codebook-id="39"
                  name="odborne_zameranie"
                  v-model="model.odporucanie.odborne_zameranie"
                  :label="$vuetify.t('Odborné zameranie')"
                  v-validate="'required'"
                >
                </codebook-autocomplete>
              </v-flex>
              <v-flex xs12>
                <v-textarea
                  v-model="model.odporucanie.pozadovane_vysetrenie"
                  :label="$vuetify.t('Požadované vyšetrenie')"
                  v-validate="'required|max:6000'"
                  data-vv-name="pozadovane_vysetrenie"
                  :data-vv-as="$vuetify.t('Požadované vyšetrenie')"
                  :error-messages="errors.collect('pozadovane_vysetrenie')"
                  auto-grow
                  rows="2"
                  :counter="6000"
                ></v-textarea>
              </v-flex>
              <v-flex xs12>
                <v-textarea
                  v-model="model.odporucanie.informacia_pre_pacienta"
                  :label="$vuetify.t('Informácia pre pacienta')"
                  v-validate="'max:2000'"
                  data-vv-name="informacia_pre_pacienta"
                  :data-vv-as="$vuetify.t('Informácia pre pacienta')"
                  auto-grow
                  rows="2"
                  :counter="2000"
                ></v-textarea>
              </v-flex>
              <v-flex xs12>
                <v-textarea
                  v-model="model.odporucanie.vysetrovany_organ"
                  :label="$vuetify.t('Vyšetrovaný orgán')"
                  v-validate="'max:512'"
                  data-vv-name="vysetrovany_organ"
                  :data-vv-as="$vuetify.t('Vyšetrovaný orgán')"
                  auto-grow
                  rows="2"
                  :counter="512"
                ></v-textarea>
              </v-flex>
              <v-flex xs12 md6>
                <codebook-autocomplete
                  codebook-id="256"
                  name="modalita_zobrazovacieho_vysetrenia"
                  v-model="model.odporucanie.modalita_zobrazovacieho_vysetrenia"
                  :label="$vuetify.t('Modalita zobrazovacieho vyšetrenia')"
                >
                </codebook-autocomplete>
              </v-flex>
              <v-flex xs12 md6>
                <v-checkbox
                  v-model="model.odporucanie.potreba_sedacie"
                  :label="$vuetify.t('Potreba sedácie')"
                ></v-checkbox>
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>
        <v-tab-item>
          <v-list>
            <v-list-tile
              v-for="(item, index) in model.odporucanie.extract"
              :key="item.rc_id"
            >
              <v-list-tile-content>
                <v-container fluid grid-list-md class="px-0">
                  <v-layout row wrap>
                    <v-flex xs12 md4>
                      <v-list-tile-title
                        v-text="item.rc_id"
                      ></v-list-tile-title>
                    </v-flex>
                    <v-flex xs12 md4>
                      {{
                        $vuetify.t("$vuetify.enum.evysetrenie.typ." + item.typ)
                      }}
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-list-tile-content>
              <v-list-tile-action>
                <v-tooltip top>
                  <v-btn
                    icon
                    @click="removeExtractItem(index)"
                    slot="activator"
                  >
                    <v-icon>remove</v-icon>
                  </v-btn>
                  <span>{{ $vuetify.t("Odstrániť") }}</span>
                </v-tooltip>
              </v-list-tile-action>
            </v-list-tile>
          </v-list>
          <div class="text-xs-center">
            <v-btn flat @click.native="addExtract"
              >{{ $vuetify.t("Pridať") }}<v-icon right>add</v-icon></v-btn
            >
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-form>
    <!-- EZDRAVIE VYSETRENIE VYHLADANIE DIALOG -->
    <BaseDialog
      :dialogTitle="$vuetify.t('ezdravie: vyhľadanie vyšetrení')"
      ref="ezdravieVyhladajVysetreniaDialog"
      @save="$refs.ezdravieVyhladajVysetreniaForm.save()"
      :showSaveButton="false"
      width="75%"
    >
      <FormEzdravieVyhladajVysetrenia
        :pacientId="pacientId"
        ref="ezdravieVyhladajVysetreniaForm"
        selectable
        @selected="processSelectedExtractItem"
        @loaded="processLoadedExtractItems"
      ></FormEzdravieVyhladajVysetrenia>
      <template v-slot:buttons>
        <v-btn
          flat
          @click.native="$refs.ezdravieVyhladajVysetreniaDialog.close()"
          >{{ $vuetify.t("$vuetify.app.button.close") }}</v-btn
        >
        <v-btn
          flat
          @click="$refs.ezdravieVyhladajVysetreniaForm.zapisSuhlas()"
          :loading="$store.state.loading.save"
          >{{ $vuetify.t("Zapísať súhlas") }}</v-btn
        >
        <v-btn
          flat
          color="primary"
          @click="$refs.ezdravieVyhladajVysetreniaForm.vyhladajVysetrenia()"
          :loading="$store.state.loading.save"
          >{{ $vuetify.t("Vyhľadať") }}</v-btn
        >
        <v-btn flat color="primary" @click="selectionDone">{{
          $vuetify.t("Dokončiť")
        }}</v-btn>
      </template>
    </BaseDialog>
  </div>
</template>

<script>
import BaseForm from "./../BaseForm.vue"
import BaseDialog from "./../BaseDialog.vue"
import * as Miscella from "./../../miscella.js"
import DatePicker from "./../DatePicker.vue"
import moment from "moment"
import CodebookAutocomplete from "./../ciselnikComponents/CodebookAutocomplete.vue"
import FormEzdravieVyhladajVysetrenia from "./../../components/forms/FormEzdravieVyhladajVysetrenia.vue"

export default {
  name: "FormOdporucanie",
  extends: BaseForm,
  $_veeValidate: {
    validator: "new",
  },
  components: {
    DatePicker,
    CodebookAutocomplete,
    BaseDialog,
    FormEzdravieVyhladajVysetrenia,
  },
  props: {
    pacientId: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      model: null,
      diagnoza: null,
      diagnozy: [],
      diagnozySearch: "",
      urgentnosti: [],
      druhyUtvaru: [],
      zamerania: [],
      zameraniaSearch: "",
      activeTab: 0,
    }
  },
  computed: {
    ambulanceId: function () {
      let id = null
      if (Miscella.isSet(this.$store.state.me.ambulance)) {
        id = this.$store.state.me.ambulance.id
      }
      return id
    },
  },
  methods: {
    init: function () {
      this.model = {
        id: "",
        patient_id: this.pacientId,
        ambulance_id: this.ambulanceId,
        date: moment().format(),
        parent_id: "",
        odporucanie: {
          urgentnost: null,
          druh_specializovaneho_utvaru: null,
          odborne_zameranie: null,
          diagnoza: null,
          pozadovane_vysetrenie: "",
          informacia_pre_pacienta: "",
          vysetrovany_organ: "",
          modalita_zobrazovacieho_vysetrenia: null,
          potreba_sedacie: false,
          extract: null,
        },
      }
      this.activeTab = 0
      this.$validator.reset()
      if (Miscella.isSet(this.$refs.ezdravieVyhladajVysetreniaForm)) {
        this.$refs.ezdravieVyhladajVysetreniaForm.init()
      }
    },
    new: function () {
      this.init()
      this.$emit("activated")
    },
    edit: function (item) {
      this.init()
      this.model = Miscella.cloneObject(item)
      this.$emit("activated")
    },
    validate: function () {
      return this.$validator.validateAll()
    },
    save: async function () {
      try {
        var params = { thing: Miscella.cloneObject(this.model) }
        var result = await this.$api.thingService.saveThing(params)
        this.notifySuccess("Výmenný lístok bol úspešne uložený")
        this.$emit("saved", result.thing)
        this.model = result.thing
      } catch (err) {
        this.processError(err, "Pri ukladaní výmenného lístka vznikla chyba")
      }
    },
    // TODO presunut na jedno miesto, napr. mixin
    getTyp: function (item) {
      switch (item.RcIdOid) {
        case "1.3.158.00165387.100.60.90":
          return "ZAZNAM_O_ODBORNOM_VYSETRENI"
          break
        case "1.3.158.00165387.100.60.80":
          return "ZAZNAM_O_ZOBRAZOVACOM_VYSETRENI"
          break
        case "1.3.158.00165387.100.60.100":
          return "ZAZNAM_PREPUSTACIA_SPRAVA"
          break
      }
      return "ZAZNAM_O_ODBORNOM_VYSETRENI"
      //throw "Neznámy typ vyšetrenia"
    },
    addExtract: function () {
      this.$refs.ezdravieVyhladajVysetreniaDialog.open()
    },
    removeExtractItem: function (index) {
      this.model.odporucanie.extract.splice(index, 1)
    },
    processSelectedExtractItem: function (item) {
      if (this.model.odporucanie.extract === null) {
        this.model.odporucanie.extract = []
      }
      if (item.selected) {
        //this.model.extract.push(item)
        this.model.odporucanie.extract.push({
          rc_id: item.RcIdExtension,
          typ: this.getTyp(item),
        })
        //this.selectedAll = this.isSelectedAll()
      } else {
        //this.selected.splice(this.selected.indexOf(item), 1)
        var index = this.model.odporucanie.extract.findIndex(
          (el) => el.rc_id == item.RcIdExtension
        )
        this.removeExtractItem(index)
        //this.selectedAll = this.isSelectedAll()
      }
    },
    processLoadedExtractItems: function (items) {
      if (
        this.model.odporucanie.extract === null ||
        this.model.odporucanie.extract.length === 0
      ) {
        return
      }
      for (let item of items) {
        if (
          this.model.odporucanie.extract.find(
            (el) => el.rc_id == item.RcIdExtension
          )
        ) {
          item.selected = true
        }
      }
    },
    selectionDone: function () {
      this.$refs.ezdravieVyhladajVysetreniaDialog.close()
    },
  },
  created: function () {
    this.init()
  },
}
</script>
