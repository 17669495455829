<template>
  <v-form>
    <v-container fluid>
      <v-layout row wrap>
        <v-flex xs12 md6>
          <TextField
            v-model="osoba.name"
            :label="$vuetify.t('Meno')"
            name="name"
            v-validate="'required'"
          ></TextField>
        </v-flex>
        <v-flex xs12 md6>
          <TextField
            v-model="osoba.surname"
            :label="$vuetify.t('Priezvisko')"
            name="surname"
            v-validate="'required'"
          ></TextField>
        </v-flex>
        <v-flex xs12 md4>
          <codebook-autocomplete
            codebook-id="54"
            v-model="osoba.affinity"
            :label="$vuetify.t('Príbuzenský vztah')"
            name="affinity"
            v-validate="'required'"
          >
          </codebook-autocomplete>
        </v-flex>
        <v-flex xs12 md4>
          <TextField
            v-model="osoba.phone"
            :label="$vuetify.t('Telefón')"
            name="phone"
          ></TextField>
        </v-flex>
        <v-flex xs12 md4>
          <TextField
            v-model="osoba.email"
            :label="$vuetify.t('Email')"
            name="email"
            v-validate="'email'"
          ></TextField>
        </v-flex>
        <v-flex xs12>
          <v-textarea
            v-model="osoba.note"
            :label="$vuetify.t('Poznámka')"
            name="note"
            auto-grow
            rows="2"
            :counter="4000"
            v-validate="'max:4000'"
            data-vv-name="note"
            :data-vv-as="$vuetify.t('Poznámka')"
          ></v-textarea>
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
import BaseForm from "./../BaseForm.vue"
import * as Miscella from "./../../miscella.js"
import TextField from "./../TextField.vue"
import CodebookAutocomplete from "./../ciselnikComponents/CodebookAutocomplete.vue"

export default {
  name: "FormPacientInfoOsoba",
  extends: BaseForm,
  props: {
    pacient: {
      type: Object,
      required: true,
    },
  },
  $_veeValidate: {
    validator: "new", // give me my own validator scope.
  },
  components: {
    TextField,
    CodebookAutocomplete,
  },
  data: function () {
    return {
      osoba: {},
      osobaIdx: -1,
    }
  },
  computed: {
    model: function () {
      return this.osoba
    },
    vek: function () {
      return this.getAge(this.pacient.birthdate)
    },
  },
  watch: {},
  methods: {
    init: function () {
      this.osoba = {
        name: "",
        surname: "",
        affinity: null,
        phone: "",
        email: "",
        note: "",
      }
      this.osobaIdx = -1
      this.$validator.reset()
    },
    new: function () {
      this.init()
    },
    edit: function (pacient, oIdx) {
      this.osobaIdx = oIdx
      this.pacient = pacient
      this.osoba = Miscella.cloneObject(
        this.pacient.contact_persons[this.osobaIdx]
      )
      this.$validator.reset()
    },
    save: function () {
      return this.$validator.validateAll().then(
        function () {
          if (this.errors.any()) {
            return
          }
          //this.osoba.affinity = this.affinity.display_name
          if (this.osobaIdx < 0) {
            this.pacient.contact_persons.push(this.osoba)
          } else {
            this.pacient.contact_persons[this.osobaIdx] = this.osoba
          }
          return this.$api
            .post("/v1/proto.PatientService/UpdatePatient", {
              patient: this.pacient,
            })
            .then(
              function (result) {
                this.$store.commit(
                  "setSuccess",
                  this.$vuetify.t("Pacient bol úspešne uložený")
                )
                this.$emit("saved", result)
              }.bind(this)
            )
            .catch(
              function (err) {
                this.$store.commit(
                  "setError",
                  this.$vuetify.t("Pri ukladaní pacienta vznikla chyba ") +
                    " (" +
                    err +
                    ")."
                )
              }.bind(this)
            )
        }.bind(this)
      )
    },
  },
  mounted: function () {},
  created: function () {
    this.init()
  },
}
</script>
