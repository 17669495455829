var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-list',{staticClass:"elevation-1",attrs:{"dense":""}},[_vm._l((_vm.items),function(item,index){return [(index === 0)?_c('v-subheader',{key:'title' + index},[_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$vuetify.t("Medikácie")))])]):_vm._e(),_c('v-list-tile',{key:'list' + index,on:{"click":function($event){return _vm.$emit('edit', item)}}},[_c('v-list-tile-content',[_c('v-container',{staticClass:"px-2",attrs:{"fluid":""}},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"xs2":""}},[_c('v-tooltip',{attrs:{"top":""}},[_c('span',{attrs:{"slot":"activator"},slot:"activator"},[_vm._v(_vm._s(_vm._f("formatDatum")(item.date)))]),_c('span',[_vm._v(_vm._s(_vm.$vuetify.t("Dátum")))])])],1),_c('v-flex',{staticClass:"text-truncate",attrs:{"xs10":""}},[_c('v-tooltip',{attrs:{"top":""}},[_c('span',{attrs:{"slot":"activator"},slot:"activator"},[_vm._v(_vm._s(item.medikacia.popis))]),_c('span',[_vm._v(_vm._s(_vm.$vuetify.t("Popis")))])])],1)],1)],1)],1),_c('v-list-tile-action',{staticStyle:{"flex-direction":"row","justify-content":"flex-end"},attrs:{"d-flex":""}},[_vm._l(([
            {
              text: 'Odstrániť medikáciu',
              icon: 'delete',
              event: 'delete',
              disabled: _vm.disabled,
            },
          ]),function(action,index){return [_c('v-tooltip',{key:'action' + index,attrs:{"left":""}},[_c('v-icon',{attrs:{"slot":"activator","disabled":action.disabled},on:{"click":function($event){$event.stopPropagation();return _vm.$emit(action.event, item)}},slot:"activator"},[_vm._v(_vm._s(action.icon))]),_c('span',[_vm._v(_vm._s(_vm.$vuetify.t(action.text)))])],1)]})],2)],1),(index !== _vm.items.length - 1)?_c('v-divider',{key:'divider' + index}):_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }