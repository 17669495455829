<template>
  <v-layout column>
    <ApiTable
      ref="table"
      :headers="headers"
      :url="$api.ehealthService.smartcardLogsUrl"
      :title="$vuetify.t('Logy')"
      defaultSortBy="CreatedAt"
      defaultSortDesc
      :customParams="customParams"
      responseItems="logs"
      :hasFilter="false"
    >
      <template v-slot:items="{ item }">
        <tr @click="viewItem(item)" :class="getClass(item)">
          <td class="text-md-left">
            {{ item.created_at | formatDatumACas }}
          </td>
          <td class="text-md-left">
            {{ item.sent_at | formatDatumACas }}
          </td>
          <td class="text-md-left">
            {{ item.received_at | formatDatumACas }}
          </td>
          <td class="text-md-left">
            {{ item.service_type }}
          </td>
        </tr>
      </template>
    </ApiTable>
    <BaseDialog
      :dialogTitle="$vuetify.t('Detail logu')"
      ref="dialog"
      :showSaveButton="false"
      width="90%"
    >
      <h4>Request:</h4>
      <pre>{{ showText(chosen.request) }}</pre>
      <h4>Response:</h4>
      <pre>{{ showText(chosen.response) }}</pre>
    </BaseDialog>
  </v-layout>
</template>

<script>
import ApiTable from "@/components/ApiTable.vue"
import BaseDialog from "@/components/BaseDialog.vue"
import Vue from "vue"

export default {
  components: {
    ApiTable,
    BaseDialog,
  },
  props: {
    customParams: {
      type: Object,
      required: false,
      default: function () {
        return {}
      },
    },
  },
  data: function () {
    return {
      chosen: {},
      headers: [
        { text: "Vytvorené", sortable: true, value: "CreatedAt" },
        { text: "Odoslané", sortable: true, value: "SentAt" },
        { text: "Prijaté", sortable: true, value: "ReceivedAt" },
        { text: "Služba", sortable: true, value: "Service" },
      ],
    }
  },
  computed: {},
  methods: {
    getClass: function (item) {
      if (item.received_at) {
        return {
          "success--text": !item.response_error,
          "error--text": item.response_error,
        }
      }
      return ""
    },
    viewItem: function (item) {
      this.chosen = item
      this.$refs.dialog.open()
    },
    showText: function (item) {
      let ret = item
      let obj = null
      try {
        obj = JSON.parse(item)
        console.log("xml", obj)
        if (obj.Xml) {
          ret = obj.Xml
        }
      } catch (error) {
        //Do nothing
      }
      return ret
    },
  },
}
</script>

<style scoped></style>
