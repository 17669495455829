<template>
  <v-container fluid class="pa-0">
    <v-layout row wrap>
      <v-flex xs12>
        <v-form>
          <v-expansion-panel v-model="filterPanel" class="elevation-1">
            <v-expansion-panel-content>
              <template v-slot:header>
                <span class="caption">{{ $vuetify.t("Filter") }}</span>
              </template>
              <v-container fluid grid-list-md>
                <v-layout row wrap>
                  <!-- <v-flex xs12 md4> -->
                  <!--   <TextField -->
                  <!--     v-model="form.IDZaznamuVysetrenia" -->
                  <!--     name="idZaznamuVysetrenia" -->
                  <!--     :label="$vuetify.t('ID vyšetrenia')" -->
                  <!--   ></TextField> -->
                  <!-- </v-flex> -->
                  <v-flex xs12 md3>
                    <DatePicker
                      v-model="model.datum_vysetrenia_od"
                      :label="$vuetify.t('Dátum vyšetrenia od')"
                      name="datumOd"
                    ></DatePicker>
                  </v-flex>
                  <v-flex xs12 md3>
                    <DatePicker
                      v-model="model.datum_vysetrenia_do"
                      :label="$vuetify.t('Dátum vyšetrenia do')"
                      name="datumDo"
                    ></DatePicker>
                  </v-flex>
                  <!-- <v-flex -->
                  <!--   xs12 -->
                  <!--   md4 -->
                  <!--   v-for="typ in [ -->
                  <!--     { -->
                  <!--       text: 'Odborné vyšetrenie', -->
                  <!--       value: 'ZAZNAM_O_ODBORNOM_VYSETRENI', -->
                  <!--     }, -->
                  <!--     { -->
                  <!--       text: 'Zobrazovacie vyšetrenie', -->
                  <!--       value: 'ZAZNAM_O_ZOBRAZOVACOM_VYSETRENI', -->
                  <!--     }, -->
                  <!--     { -->
                  <!--       text: 'Prepúšťacia správa', -->
                  <!--       value: 'ZAZNAM_PREPUSTACIA_SPRAVA', -->
                  <!--     }, -->
                  <!--   ]" -->
                  <!--   :key="typ.value" -->
                  <!-- > -->
                  <!--   <v-checkbox -->
                  <!--     v-model="model.typ_vysetrenia" -->
                  <!--     :label="$vuetify.t(typ.text)" -->
                  <!--     :value="typ.value" -->
                  <!--   ></v-checkbox> -->
                  <!-- </v-flex> -->
                  <v-flex xs12 md6>
                    <v-select
                      v-model="model.typ_vysetrenia"
                      :label="$vuetify.t('Typ vyšetrenia')"
                      :items="[
                        {
                          text: $vuetify.t(
                            '$vuetify.enum.evysetrenie.typ.ZAZNAM_O_ODBORNOM_VYSETRENI'
                          ),
                          value: 'ZAZNAM_O_ODBORNOM_VYSETRENI',
                        },
                        {
                          text: $vuetify.t(
                            '$vuetify.enum.evysetrenie.typ.ZAZNAM_O_ZOBRAZOVACOM_VYSETRENI'
                          ),
                          value: 'ZAZNAM_O_ZOBRAZOVACOM_VYSETRENI',
                        },
                        {
                          text: $vuetify.t(
                            '$vuetify.enum.evysetrenie.typ.ZAZNAM_PREPUSTACIA_SPRAVA'
                          ),
                          value: 'ZAZNAM_PREPUSTACIA_SPRAVA',
                        },
                      ]"
                      multiple
                      chips
                      deletable-chips
                      small-chips
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 md6>
                    <codebook-autocomplete
                      codebook-id="39"
                      name="odborne_zameranie"
                      v-model="model.odborne_zameranie"
                      :label="$vuetify.t('Odborné zameranie OÚ PZS')"
                    >
                    </codebook-autocomplete>
                  </v-flex>
                  <v-flex xs12 md6>
                    <codebook-autocomplete
                      codebook-id="34"
                      name="zdravotnicka_odbornost"
                      v-model="model.zdravotnicka_odbornost"
                      :label="$vuetify.t('Zdravotnícka odbornosť lekára')"
                    >
                    </codebook-autocomplete>
                  </v-flex>
                  <v-flex xs12>
                    <codebook-autocomplete
                      :codebook-id="enums.CodebookIDOUPZS"
                      name="odborny_utvar_pzs"
                      v-model="model.odborny_utvar_pzs"
                      :label="$vuetify.t('Odborný útvar PZS')"
                    >
                      <template slot="selection" slot-scope="{ item }">
                        <span>
                          {{ item.oupzs.kod_pzs }} -
                          {{ item.display_name }}
                        </span>
                      </template>
                      <template slot="item" slot-scope="{ item }">
                        <v-list-tile-content>
                          <v-list-tile-title>
                            {{ item.display_name }}
                          </v-list-tile-title>
                          <v-list-tile-sub-title>
                            {{ item.oupzs.kod_pzs }}
                          </v-list-tile-sub-title>
                        </v-list-tile-content>
                      </template>
                    </codebook-autocomplete>
                  </v-flex>
                  <v-flex xs12 md6>
                    <TextField
                      v-model="model.nazov_vysetrenia"
                      name="nazov_vysetrenia"
                      :label="$vuetify.t('Názov vyšetrenia')"
                    ></TextField>
                  </v-flex>
                  <v-flex xs12 md2>
                    <v-select
                      v-model="model.citlivost"
                      :label="$vuetify.t('Citlivosť')"
                      :items="[
                        {
                          text: '3',
                          value: '3',
                        },
                        {
                          text: '5',
                          value: '5',
                        },
                      ]"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 md2>
                    <v-checkbox
                      v-model="model.vlastne_zaznamy"
                      :label="$vuetify.t('Vlastné záznamy')"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 md2>
                    <v-checkbox
                      v-model="model.kompletny_zaznam"
                      :label="$vuetify.t('Kompletný záznam')"
                    ></v-checkbox>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-container fluid class="pa-0 pt-2">
            <v-layout row wrap v-if="vysetrenia !== null">
              <v-flex xs12 v-if="warning">
                <v-alert :value="true" type="warning">
                  {{ $vuetify.t(warning) }}
                </v-alert>
              </v-flex>
              <v-flex xs12>
                <v-subheader class="caption">
                  {{ $vuetify.t("Zoznam vyšetrení") }}
                </v-subheader>
                <v-data-table
                  :headers="[
                    {
                      text: 'ID',
                      value: 'id',
                      sortable: false,
                    },
                    {
                      text: 'Ošetrujúci lekár',
                      value: 'osetrujuciLekar',
                      sortable: false,
                    },
                    {
                      text: 'OÚ PZS',
                      value: 'oupzs',
                      sortable: false,
                    },
                    {
                      text: 'Hlavná diagnóza',
                      value: 'diagnoza',
                      sortable: false,
                    },
                    {
                      text: 'Typ',
                      value: 'typ',
                      sortable: false,
                    },
                    {
                      text: 'Dátum a čas vytvorenia',
                      value: 'datum',
                      sortable: false,
                    },
                  ]"
                  :items="vysetrenia"
                  class="elevation-1"
                  hide-actions
                >
                  <template v-slot:no-data>
                    {{ noDataMessage }}
                  </template>
                  <template v-slot:headers="{ headers }">
                    <tr>
                      <th v-if="selectable">
                        <!-- <v-checkbox -->
                        <!--   v-model="selectedAll" -->
                        <!--   primary -->
                        <!--   hide-details -->
                        <!--   @change="selectAllItems" -->
                        <!-- /> -->
                      </th>
                      <th
                        v-for="header in selectable
                          ? headers.slice(1)
                          : headers"
                        :key="header.text"
                        class="column text-xs-left"
                      >
                        {{ header.text }}
                      </th>
                    </tr>
                  </template>
                  <template v-slot:items="{ item }">
                    <tr @click="onClickVysetrenieItem(item)">
                      <td v-if="selectable" @click.stop="selectItem(item)">
                        <v-checkbox
                          :input-value="item.selected"
                          primary
                          hide-details
                        ></v-checkbox>
                      </td>
                      <td>{{ item.RcIdExtension }}</td>
                      <td>
                        <ezdravie-zdravotnicky-pracovnik
                          :ii="item.InfoProvider.Performer"
                        />
                      </td>
                      <td>
                        <ezdravie-ou-pzs
                          :ii="item.InfoProvider.HealthcareFacillity"
                        />
                      </td>
                      <td>
                        {{ getHlavnaDiagnoza(item) }}
                      </td>
                      <td>
                        {{
                          $vuetify.t(
                            "$vuetify.enum.evysetrenie.typ." + getTyp(item)
                          )
                        }}
                      </td>
                      <td class="text-xs-right">
                        {{
                          (item.FeederAudit.time_committed !== null &&
                          item.FeederAudit.time_committed !== undefined
                            ? item.FeederAudit.time_committed.time
                            : null) | formatDatumACas
                        }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-flex>
              <v-flex xs12>
                <span>{{
                  $vuetify.t(
                    "$vuetify.ezdravie.pagination",
                    zobrazeneZaznamyOd,
                    zobrazeneZaznamyDo
                  )
                }}</span>
                <v-tooltip top>
                  <v-btn
                    flat
                    icon
                    slot="activator"
                    @click.stop="prevPage"
                    :loading="$store.state.loading.save"
                    :disabled="model.strankovanie.index === 1"
                    ><v-icon>$vuetify.icons.prev</v-icon></v-btn
                  >
                  <span>{{ $vuetify.t("Predchádzajúca stránka") }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <v-btn
                    flat
                    icon
                    slot="activator"
                    @click.stop="nextPage"
                    :loading="$store.state.loading.save"
                    :disabled="
                      vysetrenia.length < model.strankovanie.velkost_stranky
                    "
                    ><v-icon>$vuetify.icons.next</v-icon></v-btn
                  >
                  <span>{{ $vuetify.t("Nasledujúca stránka") }}</span>
                </v-tooltip>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-flex>
    </v-layout>
    <BaseDialog
      :dialogTitle="$vuetify.t('ezdravie: vyšetrenie')"
      width="75%"
      ref="ezdravieVysetrenieDialog"
    >
      <EZdravieVysetrenie
        ref="ezdravieVysetrenieForm"
        :pacientId="pacientId"
        :vysetrenie="vysetrenie"
        @prevzatieVymennehoListka="prevezmiVymennyListok"
        @saved="savedEzdravieVysetrenie"
      ></EZdravieVysetrenie>
      <template v-slot:buttons>
        <v-btn flat @click.native="$refs.ezdravieVysetrenieDialog.close()">{{
          $vuetify.t("$vuetify.app.button.close")
        }}</v-btn>
        <v-btn
          flat
          @click="
            dajDetailVysetrenia(vysetrenie.RcIdExtension, getTyp(vysetrenie))
          "
          :loading="$store.state.loading.save"
          >{{ $vuetify.t("Načítať kompletné vyšetrenie") }}</v-btn
        >
        <!-- <v-btn -->
        <!--   color="primary" -->
        <!--   flat -->
        <!--   @click="$refs.ezdravieVysetrenieForm.save()" -->
        <!--   :loading="$store.state.loading.save" -->
        <!--   >{{ $vuetify.t("Uložiť") }}</v-btn -->
        <!-- > -->
      </template>
    </BaseDialog>
  </v-container>
</template>

<script>
import BaseDialog from "./../../components/BaseDialog.vue"
import TextField from "./../TextField.vue"
import DatePicker from "./../DatePicker.vue"
import EZdravieVysetrenie from "./../ezdravie/EZdravieVysetrenie.vue"
import EzdravieZdravotnickyPracovnik from "../ezdravie/EZdravieZdravotnickyPracovnik.vue"
import EzdravieOuPzs from "../ezdravie/EZdravieOuPzs.vue"
import * as Miscella from "./../../miscella.js"
import CodebookAutocomplete from "@/components/ciselnikComponents/CodebookAutocomplete.vue"
import * as enums from "@/plugins/enums.js"
import { mapState, mapGetters, mapMutations } from "vuex"
import moment from "moment"

export default {
  $_veeValidate: {
    validator: "new", // give me my own validator scope.
  },
  props: {
    pacientId: String,
    selectable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    BaseDialog,
    TextField,
    DatePicker,
    EZdravieVysetrenie,
    EzdravieZdravotnickyPracovnik,
    EzdravieOuPzs,
    CodebookAutocomplete,
  },
  data: function () {
    return {
      vysetrenie: null,
      vysetrenia: [],
      model: null,
      filterPanel: 0,
      noDataMessage: "Vyhľadávanie ešte neprebehlo.",
      warning: undefined,
    }
  },
  computed: {
    enums: function () {
      return enums
    },
    zobrazeneZaznamyOd: function () {
      return this.model.strankovanie.index
    },
    zobrazeneZaznamyDo: function () {
      return this.model.strankovanie.index + this.vysetrenia.length - 1
    },
    ...mapState({
      ambulanceID: (state) => state.me?.ambulance?.id,
      ehealthStatus: (state) => state.ehealth.status,
      ehealthEnabled: (state) => state.ehealth.enabled,
    }),
    ...mapGetters(["isEhealthReady"]),
  },
  methods: {
    init: function () {
      this.filterPanel = 0
      this.vysetrenie = null
      this.vysetrenia = []
      this.model = {
        datum_vysetrenia_od: null,
        datum_vysetrenia_do: null,
        typ_vysetrenia: [],
        odborne_zameranie: null,
        odborny_utvar_pzs: null,
        poskytovatel_zs: null,
        zdravotnicka_odbornost: null,
        nazov_vysetrenia: "",
        citlivost: "3",
        vlastne_zaznamy: false,
        kompletny_zaznam: false,

        //id_vymenneho_listku: "",
        //id_zaznamu_vysetrenia: "",

        strankovanie: {
          index: 1,
          velkost_stranky: 10,
        },
        //TriedenieMetaData: null,
        // TriedenieMetaData: {
        //   Pole: ''
        // }
      }
      this.warning = undefined
    },
    // TODO presunut na jedno miesto, napr. mixin
    getTyp: function (item) {
      switch (item.RcIdOid) {
        case "1.3.158.00165387.100.60.90":
          return "ZAZNAM_O_ODBORNOM_VYSETRENI"
          break
        case "1.3.158.00165387.100.60.80":
          return "ZAZNAM_O_ZOBRAZOVACOM_VYSETRENI"
          break
        case "1.3.158.00165387.100.60.100":
          return "ZAZNAM_PREPUSTACIA_SPRAVA"
          break
      }
      return "ZAZNAM_O_ODBORNOM_VYSETRENI"
      //throw "Neznámy typ vyšetrenia"
    },
    search: function () {
      this.init()
    },
    setVysetrenie: function (data) {
      this.vysetrenie = data
    },
    vyhladajVysetrenia: async function () {
      this.startAppLoading("Vyhľadávajú sa vyšetrenia")
      var online = await this.checkEhealthStatus()
      if (!online) {
        this.stopAppLoading()
        return
      }
      try {
        var params = Miscella.cloneObject(this.model)
        params.patient_id = this.pacientId
        params.ambulance_id = this.ambulanceID
        if (Miscella.isSet(params.datum_vysetrenia_od)) {
          params.datum_vysetrenia_od = moment(params.datum_vysetrenia_od)
            .startOf("day")
            .utc()
            .format()
        }
        if (Miscella.isSet(params.datum_vysetrenia_do)) {
          params.datum_vysetrenia_do = moment(params.datum_vysetrenia_do)
            .endOf("day")
            .utc()
            .format()
        }
        var response =
          await this.$api.ehealthService.vyhladajZaznamyOVysetreniach(params)
        console.log("AFTER vyhladaj")
        this.filterPanel = null
        var data = JSON.parse(response.data)
        console.log("AFTER parse", data)
        if (Miscella.isSet(data.Data.AllCompositions)) {
          this.vysetrenia = data.Data.AllCompositions
        }
        this.$emit("loaded", this.vysetrenia)
        this.noDataMessage = "Neboli nájdené žiadne vyšetrenia."
        console.log("AFTER noDataMessage", this.noDataMessage)
        this.warning =
          data.Result.Code !== "0" ? data.Result.Message : undefined
      } catch (error) {
        this.processEhealthError(
          error,
          "Pri vyhľadávaní vyšetrení vznikla chyba"
        )
      } finally {
        this.stopAppLoading()
      }
    },
    // TODO dat na jedno miesto, napr. mixin
    checkEhealthStatus: async function () {
      if (!this.isEhealthReady) {
        notifyError("Ezdravie je momentálne nedostupné.")
        return false
      }
      return true
    },
    zapisSuhlas: async function (pinRequired) {
      this.startAppLoading("Zapisuje sa súhlas osoby")
      var online = await this.checkEhealthStatus()
      if (!online) {
        this.stopAppLoading()
        return
      }
      try {
        var response = await this.$api.ehealthService.zapisSuhlasOsobyPrePzs({
          patient_id: this.pacientId,
          ambulance_id: this.ambulanceID,
          citlivost: this.model.citlivost,
          platny_od: moment().format(),
          povoleny_pristup: ["VVP"],
          patient_presence_pin_required: pinRequired,
        })
        this.notifySuccess("Súhlas bol úspešne zapísaný")
      } catch (error) {
        this.processEhealthError(error, "Pri zapisovaní súhlasu vznikla chyba")
      } finally {
        this.stopAppLoading()
      }
    },
    dajDetailVysetrenia: async function (rcID, typ) {
      this.startAppLoading("Načítava sa vyšetrenie")
      var online = await this.checkEhealthStatus()
      if (!online) {
        this.stopAppLoading()
        return
      }
      try {
        var response = await this.$api.ehealthService.dajZaznamOVysetreni({
          patient_id: this.pacientId,
          ambulance_id: this.ambulanceID,
          id_zaznamu_vysetrenia: rcID,
          typ_zaznamu_vysetrenia: typ,
        })
        var data = JSON.parse(response.data)
        this.setVysetrenie(data.Data.AllCompositions)
      } catch (error) {
        this.processEhealthError(
          error,
          "Pri načítavaní vyšetrenia vznikla chyba"
        )
      } finally {
        this.stopAppLoading()
      }
    },
    prevezmiVymennyListok: async function (rcID) {
      this.startAppLoading("Preberá sa výmenný lístok")
      var online = await this.checkEhealthStatus()
      if (!online) {
        this.stopAppLoading()
        return
      }
      try {
        var response = await this.$api.ehealthService.prevezmiVymennyListok({
          patient_id: this.pacientId,
          ambulance_id: this.ambulanceID,
          id_vymenneho_listku: rcID,
        })
        var data = JSON.parse(response.data)
        this.notifySuccess(
          "Prevzatie odporúčania bolo úspešné. Boli sprístupnené údaje pacienta do: " +
            this.$options.filters.formatDatumACas(data.Data.DatumDo)
        )
      } catch (error) {
        this.processEhealthError(
          error,
          "Pri načítavaní vyšetrenia vznikla chyba"
        )
      } finally {
        this.stopAppLoading()
      }
    },
    onClickVysetrenieItem: function (item) {
      this.vysetrenie = item
      this.$refs.ezdravieVysetrenieDialog.open()
    },
    selectItem: function (item) {
      item.selected = !item.selected
      this.$emit("selected", item)
    },
    prevPage: function () {
      this.model.strankovanie.index -= this.model.strankovanie.velkost_stranky
      this.vyhladajVysetrenia()
    },
    nextPage: function () {
      this.model.strankovanie.index += this.model.strankovanie.velkost_stranky
      this.vyhladajVysetrenia()
    },
    savedEzdravieVysetrenie: function (ev) {
      this.$refs.ezdravieVysetrenieDialog.close()
      this.$emit("saved", ev)
    },
    getHlavnaDiagnoza: function (item) {
      if (Miscella.isSet(item.DiagnostickyZaver)) {
        for (let dg of item.DiagnostickyZaver) {
          if (dg.HlavnaDiagnoza.value === true) {
            return dg.Diagnoza.KodDiagnozy.DisplayName
          }
        }
      }
      return ""
    },
  },
  created: function () {
    this.init()
  },
}
</script>
