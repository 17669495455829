<template>
  <v-layout column>
    <v-container fluid>
      <form-tree :forms="forms" :show-author="false" :show-stars="false">
        <template slot="otherButtons" slot-scope="{ active }">
          <v-btn @click="create">
            <v-icon left>add</v-icon>
            {{ $vuetify.t("Pridať") }}
          </v-btn>
          <v-btn @click="edit(active[0])" :disabled="active.length === 0">
            <v-icon left>edit</v-icon>
            {{ $vuetify.t("Zmeniť") }}
          </v-btn>
        </template>
      </form-tree>
    </v-container>
    <BaseDialog
      :dialogTitle="$vuetify.t('Formulár')"
      width="80%"
      ref="dialog"
      @save="$refs.form.save()"
    >
      <form-form ref="form" @saved="fetchFormsTree" />
      <!--form-editor ref="formEditor" :form-definition="model" /-->
    </BaseDialog>
  </v-layout>
</template>

<script>
import * as Miscella from "@/miscella.js"
import BaseDialog from "@/components/BaseDialog.vue"
import FormTree from "@/components/FormTree.vue"
import FormForm from "@/components/forms/FormForm.vue"
import { mapState } from "vuex"

export default {
  name: "forms",
  components: {
    FormTree,
    BaseDialog,
    FormForm,
  },
  data: function () {
    return {
      forms: [],
    }
  },
  computed: {
    ...mapState({
      ambulanceID: (state) => state.me?.ambulance?.id,
      userID: (state) => state.me?.user?.id,
    }),
  },
  methods: {
    create: function () {
      this.$refs.form.new()
      this.$refs.dialog.open()
    },
    edit: async function (item) {
      console.log("edit", item)
      var params = { id: item }
      let result = await this.$api.formService.getForm(params)
      this.$refs.form.edit(result.form)
      this.$refs.dialog.open()
    },
    fetchFormsTree: async function () {
      let params = { user_id: this.userID }
      let resp = await this.$api.formService.getFormTree(params)
      console.log("fetchFormsTree before transform", resp.tree)
      let myTree = Miscella.clearEmptyArrays(resp.tree)
      this.forms = myTree
      console.log("fetchFormsTree", this.forms)
    },
  },
  created: function () {
    this.fetchFormsTree()
  },
  mounted: function () {},
}
</script>

<style scoped></style>
