<template>
  <v-container fluid class="pa-0">
    <v-layout row wrap>
      <v-flex xs12 v-if="hasModalitaOld || hasModalitaNew">
        <titled-text
          v-if="hasModalitaNew"
          title="Modalita zobrazovacieho vyšetrenia"
          :text="vysetrenie.ModalitaZobrazovaciehoVysetreniaCV.DisplayName"
        />
        <titled-text
          v-if="hasModalitaOld"
          title="Modalita zobrazovacieho vyšetrenia"
          :text="vysetrenie.ModalitaZobrazovaciehoVysetrenia.originalText"
        />
      </v-flex>
      <v-flex xs12 v-if="hasVysetrovanyOrgan">
        <titled-text
          title="Vyšetrovaný orgán"
          :text="vysetrenie.VysetrovanyOrgan.DisplayName"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import TitledText from "./TitledText.vue"

export default {
  props: {
    vysetrenie: {
      required: true,
    },
  },
  components: { TitledText },
  data: function () {
    return {}
  },
  computed: {
    hasModalitaOld: function () {
      return this.vysetrenie.ModalitaZobrazovaciehoVysetrenia != null
    },
    hasModalitaNew: function () {
      return this.vysetrenie.ModalitaZobrazovaciehoVysetreniaCV != null
    },
    hasVysetrovanyOrgan: function () {
      return this.vysetrenie.VysetrovanyOrgan != null
    },
  },
}
</script>
