<template>
  <v-container fluid fill-height>
    <v-layout row v-if="chats === null || chats.length === 0">
      <v-flex>
        {{ $vuetify.t("Nemáte žiadne konverzácie.") }}
      </v-flex>
    </v-layout>
    <v-layout row v-else>
      <v-flex>
        <v-card class="fill-height">
          <v-container class="pa-0" fluid fill-height>
            <v-layout row>
              <v-flex xs3>
                <v-card class="dashboard-panel">
                  <v-card-text class="pa-0">
                    <v-list dense two-line class="pa-0 fill-height">
                      <template v-for="chat in chats">
                        <v-list-tile
                          :key="chat.id"
                          @click="
                            activeChat = chat
                            fetchMessages()
                            markAsRead()
                          "
                          :class="{
                            'primary--text': chat === activeChat,
                          }"
                        >
                          <v-list-tile-content>
                            <v-list-tile-title
                              :class="{
                                'font-weight-bold': !isRead(chat),
                              }"
                              class="subheading"
                              >{{ chatMembers(chat) }}</v-list-tile-title
                            >
                            <v-list-tile-sub-title
                              v-if="chat.last_message !== null"
                              :class="{
                                'font-weight-bold': !isRead(chat),
                              }"
                            >
                              <span
                                v-if="chat.last_message.member_id === memberID"
                                >{{ chat.last_message.author.name }}:</span
                              >
                              {{
                                chat.last_message.text
                              }}</v-list-tile-sub-title
                            >
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-divider :key="'divider' + chat.id"></v-divider>
                      </template>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs9>
                <v-card class="dashboard-panel">
                  <v-card-text
                    style="display: flex; flex-direction: column-reverse"
                  >
                    <template v-for="msg in messages">
                      <v-card :key="msg.id" class="elevation-0">
                        <v-card-text>
                          <div>
                            <div>
                              <span class="body-2">
                                {{ msg.author.name }}
                              </span>
                              <span class="ml-2 grey--text">{{
                                msg.created_at
                                  | formatDatumACas("D.M.YYYY HH:mm")
                              }}</span>
                            </div>
                            <div>{{ msg.text }}</div>
                            <v-layout
                              row
                              wrap
                              v-if="miscella.isSet(msg.attachment)"
                            >
                              <v-flex xs12 sm6 md4 lg3>
                                <attachment
                                  v-model="msg.attachment"
                                ></attachment>
                              </v-flex>
                            </v-layout>
                          </div>
                        </v-card-text>
                      </v-card>
                    </template>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-layout column>
                      <v-layout row align-center>
                        <v-textarea
                          ref="messageField"
                          hide-details
                          v-model="text"
                          :placeholder="$vuetify.t('Odošlite správu ...')"
                          rows="2"
                          auto-grow
                          solo
                          flat
                          @keypress.enter.prevent="sendMessage"
                          @click="markAsRead"
                          @keydown="markAsRead"
                        >
                        </v-textarea>
                        <v-tooltip top :disabled="!!file">
                          <template v-slot:activator="{ on }">
                            <v-btn
                              flat
                              icon
                              v-on="on"
                              @click="pickFile"
                              @click:prepend="pickFile"
                              :disabled="!!file"
                              ><v-icon>attach_file</v-icon></v-btn
                            >
                          </template>
                          <span>{{ $vuetify.t("Priložiť súbor") }}</span>
                        </v-tooltip>
                        <input
                          type="file"
                          style="display: none"
                          ref="fileUpload"
                          @change="onFilePicked"
                        />
                      </v-layout>
                      <v-layout row>
                        <v-flex xs12 sm6 md4 lg3>
                          <attachment
                            ref="newAttachment"
                            v-model="file"
                            @remove="removeFile"
                          ></attachment>
                        </v-flex>
                      </v-layout>
                    </v-layout>
                  </v-card-actions>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import * as Miscella from "./../../miscella.js"
import moment from "moment"
import { mapState, mapGetters } from "vuex"
import Attachment from "@/components/Attachment.vue"
import * as subory from "../../components/mixins/subory.js"

export default {
  name: "Chat",
  mixins: [subory.mixins],
  props: ["id"],
  components: { Attachment },
  data: function () {
    return {
      text: "",
      file: "",
      messages: [],
      chats: [],
      activeChat: null,
      activeMember: null,
      page: 0,
      scrollTop: 0,
      scrollHeight: 0,
      clientHeight: 0,
      loading: false,
    }
  },
  computed: {
    // TODO memberID, authorID a authorType by mal byt prop, ak ma byt tento Chat component univerzalny
    memberID: function () {
      return this.ambulanceID
    },
    authorID: function () {
      return this.userID
    },
    authorType: function () {
      return "AUTHOR_TYPE_USER"
    },
    fileIsImage: function () {
      return (
        this.file && this.file.type && this.file.type.split("/")[0] === "image"
      )
    },
    miscella: function () {
      return Miscella
    },
    ...mapState({
      userID: (state) => state.me?.user?.id,
      ambulanceID: (state) => state.me?.ambulance?.id,
      events: (state) => state.events.queue,
    }),
    ...mapGetters(["isThemeDark", "getAmbulanceEventTopic"]),
  },
  watch: {
    chats: function () {
      if (this.chats.length > 0) {
        for (var chat of this.chats) {
          // do premennej me kazdeho chatu dame seba, aby sme neskor nemuseli v kuse prehladavat vsetkych memberov
          chat.me = chat.members.find((m) => m.member_id === this.memberID)
        }
        if (Miscella.isSet(this.activeChat)) {
          var found = this.chats.find((c) => c.id === this.activeChat.id)
          if (Miscella.isSet(found)) {
            this.activeChat = found
            return
          }
        }
        this.activeChat = this.chats[0]
        this.fetchMessages()
      }
    },
    activeChat: function () {
      this.activeMember = this.activeChat.me
    },
    events: function (evt) {
      if (evt.event === this.getAmbulanceEventTopic("chat:chatUpdated")) {
        this.fetchData()
      }
      // TODO nebolo by jednoduchsie nacitat spravy, teda this.fetchMessages() ?
      //      je tam ale problem, bolo by treba doplnit atribut, ze ich chces nacitat ale neupdatovat, ze si ich precital
      if (evt.event === `${this.ambulanceID}:chat:messageSent`) {
        if (evt.data.chat_id === this.activeChat.id) {
          this.messages.unshift(evt.data)
        }
      }
    },
  },
  methods: {
    pickFile() {
      this.$refs.fileUpload.click()
    },
    onFilePicked(e) {
      const files = e.target.files
      this.$refs.newAttachment.upload(files[0])
      this.focusMessageField()
    },
    removeFile: function () {
      this.file = ""
      this.focusMessageField()
    },
    fetchData: async function () {
      try {
        var params = {
          member_id: this.memberID,
        }
        var data = await this.$api.ambulanceService.listChats(params)
        this.chats = data.items
      } catch (err) {
        this.processError(err, "Pri načítaní konverzácií nastala chyba")
      }
    },
    fetchMessages: async function () {
      try {
        var params = {
          chat_id: this.activeChat.id,
          member_id: this.memberID,
          table_request: {
            sort_by: "CreatedAt",
            sort_desc: true,
          },
        }
        var data = await this.$api.ambulanceService.listMessages(params)
        this.messages = data.items
        this.focusMessageField()
      } catch (err) {
        this.processError(err, "Pri načítaní správ nastala chyba")
      }
    },
    markAsRead: async function () {
      if (this.isRead(this.activeChat)) {
        return
      }
      try {
        var params = {
          chat_id: this.activeChat.id,
          member_id: this.memberID,
        }
        await this.$api.ambulanceService.markChatAsRead(params)
      } catch (err) {
        this.processError(err, "Pri označovaní ako prečítané nastala chyba")
      }
    },
    focusMessageField: function () {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.messageField.$refs.input.focus()
        }, 0)
      })
    },
    sendMessage: async function () {
      try {
        var params = {
          chat_id: this.activeChat.id,
          member_id: this.memberID,
          author_id: this.authorID,
          author_type: this.authorType,
          text: this.text,
        }
        if (!Miscella.isEmpty(this.file)) {
          params.attachment = this.file
        }
        var data = await this.$api.ambulanceService.sendMessage(params)
        this.text = ""
        this.file = ""

        // TODO hack, aby sa znova zmensila textarea, ak sa zada viacriadkovy text, neviem ako inak spravit momentalne,
        //   metodu calculateInputHeight som vytiahol zo zdrojaku VTextArea.js
        this.$nextTick(this.$refs.messageField.calculateInputHeight)
      } catch (err) {
        this.processError(err, "Pri odosielaní správy nastala chyba")
      }
    },
    chatMembers: function (chat) {
      return chat.members
        .filter((m) => m.member_id !== this.ambulanceID)
        .map((m) => m.member.name)
        .join()
    },
    isRead: function (chat) {
      return (
        Miscella.isSet(chat.me.read_at) &&
        moment(chat.me.read_at).isSameOrAfter(moment(chat.last_message_at))
      )
    },
  },
  mounted: async function () {
    await this.$store.dispatch("fetchMe", this.$api)
    this.fetchData(true)
  },
}
</script>
