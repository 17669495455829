<template>
  <v-card
    class="dashboard-panel komunikacia"
    height="95%"
    v-if="clinicIsVerified"
  >
    <v-card-actions class="pt-0 pb-2">
      <v-container fluid grid-list-lg class="py-1">
        <v-layout row justify-space-between align-end>
          <v-btn small color="primary" @click.stop="send" :disabled="!enabled"
            >{{ $vuetify.t("Odoslať") }}
            <v-icon small right>send</v-icon></v-btn
          >
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-checkbox
                v-on="on"
                v-model="enabled"
                :label="enabledCheckboxLabel"
                primary
                hide-details
                class="ml-2"
                :readonly="!enabled && !pacientMaKontakt"
              >
                <template v-slot:append>
                  <v-tooltip top v-if="!enabled && !pacientMaKontakt">
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" class="error--text">warning</v-icon>
                    </template>
                    <span>{{
                      $vuetify.t(
                        "Komunikáciu nie je možné povoliť, pacient nemá zadaný email ani telefónne číslo"
                      )
                    }}</span>
                  </v-tooltip>
                  <v-tooltip top v-if="unsynced">
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" class="warning--text"
                        >sync_problem</v-icon
                      >
                    </template>
                    <span>{{
                      $vuetify.t(
                        enabled
                          ? "Čaká sa na synchronizáciu so serverom, pozvánka zatiaľ nebola odoslaná, pacient nemôže komunikovať"
                          : "Čaká sa na synchronizáciu so serverom, pacient stále môže komunikovať"
                      )
                    }}</span>
                  </v-tooltip>
                </template>
              </v-checkbox>
            </template>
            <span>{{
              $vuetify.t(
                enabled
                  ? "Kliknutím zablokujete komunikáciu s pacientom"
                  : "Kliknutím povolíte komunikáciu s pacientom (pacientovi bude odoslaná pozvánka)"
              )
            }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                small
                @click.stop="fetchData(true)"
                :loading="loading"
              >
                <v-icon>sync</v-icon>
              </v-btn>
            </template>
            <span>{{ $vuetify.t("Obnoviť zoznam správ") }}</span>
          </v-tooltip>
        </v-layout>
        <v-layout row>
          <v-flex xs12 class="px-0 py-1">
            <v-textarea
              solo
              hide-details
              v-model="text"
              :placeholder="$vuetify.t('Odošlite správu ...')"
              rows="2"
              auto-grow
              :disabled="!enabled"
            >
            </v-textarea>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-actions>
    <v-divider></v-divider>
    <v-card-text id="scroll-target" v-scroll:#scroll-target="onScroll">
      <template v-for="message in messages">
        <v-card
          :key="message.ID"
          class="message elevation-0"
          :class="{
            amb: message.From === 'CAmb',
            patient: message.From === 'Patient',
          }"
        >
          <v-system-bar class="message-toolbar">
            <v-avatar
              v-if="message.From === 'Patient'"
              color="red"
              size="32"
              class="ml-1 mr-2"
            >
              <img alt="" :src="pacient.imageLink" />
            </v-avatar>
            <span
              class="ml-1 mr-2 fa-stack blue--text lighten-3"
              style="font-size: 16px"
              v-else
            >
              <i class="fas fa-circle fa-stack-2x"></i>
              <i class="fas fa-user-md fa-stack-1x fa-inverse"></i>
            </span>
            <span>
              <span class="font-weight-bold">
                {{
                  message.From === "Patient"
                    ? pacient.full_name
                    : message.Data.authorName
                }}
              </span>
              <span class="font-weight-light pl-1">
                {{ message.Data.created | formatDatumACas("D.M.YYYY HH:mm") }}
              </span>
            </span>
            <v-spacer></v-spacer>
            <v-tooltip top v-if="message.From === 'CAmb'">
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  v-on="on"
                  :class="messageStatusIcon(message).class"
                  >{{ messageStatusIcon(message).icon }}</v-icon
                >
              </template>
              <span>{{ $vuetify.t(messageStatusIcon(message).label) }}</span>
            </v-tooltip>
          </v-system-bar>
          <v-card-text class="message-text pb-1 pt-2">
            <vue-markdown>{{ message.Data.text }}</vue-markdown>
          </v-card-text>
        </v-card>
      </template>
      <v-progress-linear
        :indeterminate="true"
        v-if="loading"
      ></v-progress-linear>
    </v-card-text>
  </v-card>
  <v-container v-else>
    <v-alert type="error" outline :value="true">
      {{
        $vuetify.t(
          "Administrátor nie je overený na serveri. Komunikácia s pacientom bude možná až po overení."
        )
      }}
    </v-alert>
  </v-container>
</template>

<script>
import * as Miscella from "./../../miscella.js"
import VueMarkdown from "vue-markdown-v2"

export default {
  name: "komunikacia",
  props: ["id"],
  components: {
    VueMarkdown,
  },
  data: function () {
    return {
      text: "",
      messages: [],
      page: 0,
      scrollTop: 0,
      scrollHeight: 0,
      clientHeight: 0,
      loading: false,
    }
  },
  computed: {
    clinicIsVerified: function () {
      let ret = false
      if (Miscella.isSet(this.$store.state.me)) {
        if (Miscella.isSet(this.$store.state.me.clinic)) {
          ret = this.$store.state.me.clinic.isVerified
        }
      }
      return ret
    },
    pacient: function () {
      return this.$store.state.pacient !== null ? this.$store.state.pacient : {}
    },
    pacientMaKontakt: function () {
      return (
        this.pacient &&
        (!Miscella.isEmpty(this.pacient.Email) ||
          !Miscella.isEmpty(this.pacient.Phone))
      )
    },
    enabled: {
      get: function () {
        return this.pacient.conversation && !this.pacient.conversation.Disabled
      },
      set: function () {
        this.toggle()
      },
    },
    unsynced: function () {
      return this.pacient.conversation && !this.pacient.conversation.Sync
    },
    enabledCheckboxLabel: function () {
      var label = ""
      if (this.enabled) {
        label = this.$vuetify.t("Komunikácia s pacientom je povolená")
      } else {
        label = this.$vuetify.t("Komunikácia s pacientom nie je povolená")
      }
      return label
    },
  },
  methods: {
    messageStatusIcon: function (message) {
      var status = {
        icon: "",
        class: "",
        label: "",
      }
      switch (message.Status) {
        case 0:
          status.icon = "sync_problem"
          status.class = "warning--text"
          status.label = "Nová správa, čaká na synchronizáciu"
          break
        case 1:
          status.icon = "done"
          status.class = "success--text"
          status.label = "Správa odoslaná, neprečítaná"
          break
        case 3:
          status.icon = "done_all"
          status.class = "success--text"
          status.label = "Správa bola prečítaná"
          break
      }
      return status
    },
    onScroll: function (e) {
      this.scrollTop = e.target.scrollTop
      this.scrollHeight = e.target.scrollHeight
      this.clientHeight = e.target.clientHeight
      if (this.scrollTop + this.clientHeight >= this.scrollHeight) {
        this.fetchData()
      }
    },
    fetchData: async function (reload = false) {
      // TODO mozno brat objekt konverzacie samostatnym requestom aby som nemusel reloadovat pacienta
      this.$store.commit("setReloadPacient", true)
      if (reload) {
        this.page = 0
        this.messages = []
      }
      this.loading = true
      var params = {
        page: this.page + 1,
        pageSize: 10,
      }
      var data = await this.$api.get(`/api/patient/${this.id}/messages`, params)
      this.messages = this.messages.concat(data.tableData)
      this.page = data.tableInfo.Page
      this.loading = false
    },
    toggle: async function () {
      try {
        await this.$api.post(`/api/patient/${this.id}/conversation`)
        this.$store.commit("setReloadPacient", true)
      } catch (err) {
        this.processError(
          err,
          "Pri aktivovaní/deaktivovaní komunikácie nastala chyba"
        )
      }
    },
    send: async function () {
      if (!Miscella.isEmpty(this.text)) {
        try {
          await this.$api.post(`/api/patient/${this.id}/message`, {
            text: this.text,
          })
          this.text = ""
          this.fetchData(true)
        } catch (err) {
          this.processError(err, "Pri odosielaní správy nastala chyba")
        }
      }
    },
  },
  mounted: function () {
    this.fetchData(true)
  },
}
</script>
<style scoped lang="stylus">
.message
  .message-text
    padding-left: 4em
.theme--dark.v-card.komunikacia
  background-color: transparent !important
  .message
    &.amb
      background-color: #E3F2FD
    &.patient
      background-color: #FFEBEE

.theme--light.v-card.komunikacia
  background-color: transparent !important
  .message
    .message-toolbar
      color: black
    &.amb
      background-color: transparent !important
      .message-toolbar
        background-color: #E3F2FD
    &.patient
      background-color: transparent !important
      .message-toolbar
        background-color: #FFEBEE

.message-spacer
  background-color: #E3F2FD
</style>
