import moment from "moment"

export default {
  install: function (Vue) {
    var Filters = {
      formatDatumACas: function (datum, format = "D.M.YYYY HH:mm:ss") {
        if (
          datum === undefined ||
          datum === null ||
          datum === "" ||
          datum === "0001-01-01T00:00:00Z"
        ) {
          return ""
        }
        moment.locale("sk")
        return moment(datum).format(format)
      },
      formatDatum: function (datum) {
        return Filters.formatDatumACas(datum, "D.M.YYYY")
      },
      formatDatumLong: function (datum) {
        if (datum === "0001-01-01T00:00:00Z") {
          return ""
        }
        if (datum === null) {
          return ""
        }
        return moment(datum).format("D.M.YYYY HH:mm")
      },
      formatSuma: function (suma, pocetDesatinychMiest = 2) {
        if (suma === undefined || suma === null) {
          return ""
        }

        return suma.toFixed(pocetDesatinychMiest) + "€"
      },
      toText: function (value) {
        let ret = ""
        if (value === null || value === undefined || value === "") {
          ret = "<span class='nezadane'>Nezadané</span>" // app.nezadane
        } else {
          ret = value
        }
        return ret
      },
      // napr. formatString('111111', 'XXX-XXX')
      formatString: function (string, format) {
        var regex = ""
        for (var i = 1; format.indexOf("X") >= 0; ++i) {
          format = format.replace("X", "$" + i)
          regex += "(\\d)"
        }
        // regex += '[^]*' // Match the rest of the string to crop characters overflowing the format.
        // Remove this ^ line if you want `format('12345678', 'XX/XX/XX')` to return `12/34/5678` instead of `12/34/56`;
        return string.replace(new RegExp(regex), format)
      },
      liekTypString: function (aRet, aRecept) {
        if (aRet === "") {
          if (
            aRecept.ZlozenieIPL !== "" ||
            aRecept.PokynyPriVyrobeIPL !== "" ||
            aRecept.PokynKUzivaniuIPL !== ""
          ) {
            return "IPL"
          }
        }
        return aRet
      },
      firstToUpper: function (aRet) {
        return aRet.charAt(0).toUpperCase() + aRet.slice(1)
      },
    }

    Vue.filter("formatDatum", Filters.formatDatum)
    Vue.filter("formatDatumACas", Filters.formatDatumACas)
    Vue.filter("toText", Filters.toText)
    Vue.filter("formatString", Filters.formatString)
    Vue.filter("liekTypString", Filters.liekTypString)
    Vue.filter("firstToUpper", Filters.firstToUpper)
  },
}
