var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-content',{attrs:{"app":""}},[_c('v-container',[_c('v-layout',{attrs:{"justify-center":""}},[_c('v-card',{staticClass:"dashboard-panel ma-2 elevation-5",attrs:{"width":"80%"}},[_c('v-toolbar',{attrs:{"flat":"","dense":"","card":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$vuetify.t("Výber aktuálneho PZS a ambulancie"))+" ")]),_c('v-spacer')],1),_c('v-container',{staticClass:"pb-0 grey--text"},[_vm._v(" "+_vm._s(_vm.$vuetify.t( "Kliknutím si zvoľte ambulanciu, v ktorej chcete pracovať. Aktuálne zvolený PZS a ambulancia sú zvýraznené." ))+" ")]),_vm._l((_vm.clinics),function(clinic){return _c('v-container',{key:clinic.id,attrs:{"fluid":"","grid-list-md":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('h5',{staticClass:"headline",class:{ 'primary--text': _vm.isCurrentClinic(clinic) },domProps:{"textContent":_vm._s(clinic.name)}})]),(clinic.ambulances && clinic.ambulances.length > 0)?_vm._l((clinic.ambulances),function(ambulance){return _c('v-flex',{key:ambulance.id,attrs:{"xs12":"","sm6":"","md4":"","lg3":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return _c('v-card',{class:{
                      'elevation-12': hover,
                      'elevation-4': !hover,
                      'primary--text': _vm.isCurrent(clinic, ambulance),
                      clicker: hover,
                    },on:{"click":function($event){_vm.save(clinic, ambulance)
                      _vm.$router.push('/home')}}},[_c('v-card-title',{staticClass:"pa-4"},[_c('div',[_c('h1',{staticClass:"headline"},[_vm._v(_vm._s(ambulance.name))]),_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(ambulance.zameranie))])])])],1)}}],null,true)})],1)}):_c('v-flex',[_vm._v(" "+_vm._s(_vm.$vuetify.t("Nie ste členom žiadnej ambulancie v tomto PZS."))+" "),(clinic.user_role === 'USER_ROLE_ADMIN')?_c('div',[_vm._v(" "+_vm._s(_vm.$vuetify.t( "Ako administrátor tohto PZS máte možnosť v menu" ))+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"small":"","color":"primary"},on:{"click":function($event){_vm.save(clinic)
                        _vm.$router.push('/admin/ambulances')}}},on),[_vm._v(_vm._s(_vm.$vuetify.t("Administrácia")))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.t( "Kliknutím zvolíte PZS a prejdete do administrácie ambulancií" ))+" ")])]),_vm._v(" "+_vm._s(_vm.$vuetify.t( "vytvárať a meniť ambulancie a taktiež priraďovať používateľov do jednotlivých ambulancií." ))+" ")],1):_vm._e()])],2)],1)})],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }