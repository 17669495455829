<template>
  <v-form>
    <v-container fluid class="pt-0">
      <v-layout row wrap>
        <v-flex xs12 md6>
          <v-checkbox
            :input-value="value"
            @change="updateValue"
            :label="$vuetify.t('Pripojiť k vyšetreniu')"
          ></v-checkbox>
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script type="text/babel">
export default {
  props: {
    value: {
      type: Boolean,
      default: null,
    },
  },
  methods: {
    updateValue: function (value) {
      this.$emit("input", value)
    },
  },
}
</script>
