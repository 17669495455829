var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('ApiList',{ref:"list",attrs:{"url":_vm.url,"customParams":_vm.getParams(),"title":_vm.$vuetify.t('Merania vitálnych a antropometrických hodnôt'),"actions":[
      {
        text: _vm.$vuetify.t('Odstrániť meranie'),
        icon: 'delete',
        handler: _vm.removeMeranie,
      },
    ]},on:{"addItem":_vm.addItem,"editItem":_vm.editItem},scopedSlots:_vm._u([{key:"items",fn:function({ item }){return [_c('VitAntropHodnotyRow',{attrs:{"vitAntropHodnoty":item}})]}}])}),_c('BaseDialog',{ref:"dialog",attrs:{"dialogTitle":_vm.$vuetify.t(_vm.dialogTitle)},on:{"save":function($event){return _vm.$refs.form.save()}}},[_c('FormVitAntropHodnoty',{ref:"form",attrs:{"pacientId":_vm.id},on:{"saved":_vm.fetchData}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }