<template>
  <span>{{ menoLekara }}</span>
</template>

<script>
import * as Miscella from "./../../miscella.js"
import * as enums from "@/plugins/enums.js"

export default {
  props: {
    ii: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      lekarRegister: null,
    }
  },
  computed: {
    menoLekara: function () {
      if (
        Miscella.isSet(this.lekarRegister) &&
        !Miscella.isEmpty(this.lekarRegister.zdravotnicky_pracovnik_kod.name) &&
        !Miscella.isEmpty(this.lekarRegister.zdravotnicky_pracovnik_kod.surname)
      ) {
        return `${this.lekarRegister.zdravotnicky_pracovnik_kod.name} ${this.lekarRegister.zdravotnicky_pracovnik_kod.surname}`
      }
      return ""
    },
  },
  watch: {
    ii: function () {
      this.fetchLekar()
    },
  },
  methods: {
    fetchLekar: async function () {
      if (Miscella.isSet(this.ii)) {
        var params = {}
        params.codebook_ids = [enums.CodebookIDZdravotnickyPracovnikKod]
        params.table_request = {
          limit: 1,
        }
        params.zdravotnicky_pracovnik_kod_filter = {
          jruz_id: this.ii.extension,
        }
        var data = await this.$api.codebookService.listCodebookItems(params)
        if (Miscella.isNotSet(data.items) || data.items.length == 0) {
          throw "Zdravotnicky pracovnik not found!"
        }
        this.lekarRegister = data.items[0]
      }
    },
  },
  mounted: function () {
    this.fetchLekar()
  },
}
</script>
