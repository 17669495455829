<template>
  <v-form>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-alert
          v-on="on"
          type="error"
          :value="true"
          class="ma-0 pa-0"
          v-if="errors.any()"
        >
          {{ $vuetify.t("Formulár obsahuje chyby.") }}
        </v-alert>
      </template>
      <div v-for="(err, index) in errors" :key="index">
        <span v-html="err.msg + '<br>'"></span>
      </div>
    </v-tooltip>
    <v-alert
      v-for="(message, index) in model.recept.blo_messages"
      :key="'warning' + index"
      type="warning"
      v-model="showBLOError"
      class="ma-0 pa-0 text-truncate"
      transition="expand"
      dismissible
    >
      {{ message }}
    </v-alert>

    <v-layout row>
      <v-flex class="text-xs-left mb-0 pb-0" xs11>
        <span v-if="model.id === ''"><b>Stav receptu:</b> Nový</span>
        <span v-if="model.id !== '' && model.recept.blo_ciarovy_kod === ''">
          <b>Stav receptu:</b>
          <span class="grey--text pl-1"
            >Uložený v aplikácii, neodoslaný do BLO</span
          >
          <span class="yellow--text pl-1"
            >Vytlačte recept bez čiarového kódu</span
          >
        </span>
        <span v-if="model.id !== '' && model.recept.blo_ciarovy_kod !== ''">
          <b>Stav receptu:</b>
          <span class="grey--text pl-1"
            >Uložený v aplikácii, odoslaný do BLO</span
          >
        </span>
        <span class="red--text pl-1">
          {{ logError }}
        </span>
        <span v-if="model.recept.je_stornovany" class="red--text pl-1">
          <b>Stornovaný</b>
        </span>
        <span v-if="model.recept.je_zneplatneny" class="red--text pl-1">
          <b>Zneplatnený</b>
        </span>
      </v-flex>
      <v-flex class="text-xs-right mb-0 pb-0" xs1>{{ getTyp }}</v-flex>
    </v-layout>
    <v-tabs v-model="activeTab">
      <v-tab :class="{ isError: getTabContainsError(0) }">{{
        $vuetify.t("Základné údaje")
      }}</v-tab>
      <v-tab :class="{ isError: getTabContainsError(1) }">{{
        $vuetify.t("Dávkovanie")
      }}</v-tab>
      <v-tab :class="{ isError: getTabContainsError(2) }">{{
        $vuetify.t("Extra")
      }}</v-tab>
      <v-tab :class="{ isError: getTabContainsError(3) }">{{
        $vuetify.t("Zastupovanie a odporúčanie")
      }}</v-tab>
    </v-tabs>
    <div style="min-height: 530px">
      <v-tabs-items v-model="activeTab">
        <v-tab-item>
          <v-container fluid grid-list-md class="pt-1 pb-1">
            <v-layout row wrap>
              <v-flex xs12 md3>
                <DatePicker
                  v-model="model.date"
                  :label="$vuetify.t('Dátum')"
                  name="date"
                  v-validate="'required'"
                  :disabled="formDisabled"
                ></DatePicker>
              </v-flex>
              <v-flex xs12 md9>
                <codebook-autocomplete
                  codebook-id="25"
                  name="diagnoza"
                  v-model="model.recept.diagnoza"
                  :label="$vuetify.t('Diagnóza')"
                  v-validate="'required'"
                  :disabled="formDisabled"
                >
                  <template v-slot:selection="{ item }"
                    ><span
                      >{{ item.skratka }} - {{ item.display_name }}</span
                    ></template
                  >
                  <template v-slot:item="{ item }">
                    <v-list-tile-content>
                      <v-list-tile-title
                        v-html="item.skratka"
                      ></v-list-tile-title>
                      <v-list-tile-sub-title
                        v-html="item.display_name"
                      ></v-list-tile-sub-title>
                    </v-list-tile-content>
                  </template>
                </codebook-autocomplete>
              </v-flex>
              <v-expand-transition>
                <v-flex v-if="!isIPL" xs12>
                  <v-autocomplete
                    v-model="liek"
                    :label="$vuetify.t('Liek')"
                    :items="lieky"
                    :search-input.sync="liekySearch"
                    @change="changeLiek"
                    hide-no-data
                    no-filter
                    clearable
                    return-object
                    data-vv-name="smartLiek"
                    :data-vv-as="$vuetify.t('Liek')"
                    :error-messages="errors.collect('smartLiek')"
                    :disabled="formDisabled"
                  >
                    <template slot="selection" slot-scope="{ item }">
                      <span>
                        {{ item.kod }} 
                        {{ item.title }} 
                        {{ item.subtitle }}
                      </span>
                    </template>
                    <template slot="item" slot-scope="{ item }">
                      <v-list-tile-content>
                        <v-list-tile-title>
                          <v-layout justify-space-between>
                            <span>{{ item.kod }}</span>
                            <span>{{ item.typ }}</span>
                          </v-layout>
                        </v-list-tile-title>
                        <v-list-tile-sub-title
                          :class="{ bold: item.kategorizacia }"
                        >
                          <span>{{ item.title }}</span>
                          <span class="text-truncate">
                            {{ item.subtitle }}
                          </span>
                        </v-list-tile-sub-title>
                      </v-list-tile-content>
                    </template>
                  </v-autocomplete>
                </v-flex>
              </v-expand-transition>
              <v-expand-transition>
                <v-flex v-if="!isLiek" xs12 md4>
                  <v-textarea
                    v-model="model.recept.zlozenie_ipl"
                    :label="$vuetify.t('Zloženie IPL')"
                    rows="2"
                    auto-grow
                    :disabled="formDisabled"
                    :error-messages="errors.collect('zlozenieIPL')"
                  ></v-textarea>
                </v-flex>
              </v-expand-transition>
              <v-expand-transition>
                <v-flex v-if="!isLiek" xs12 md4>
                  <v-textarea
                    v-model="model.recept.pokyny_pri_vyrobe_ipl"
                    :label="$vuetify.t('Pokyny pri výrobe IPL')"
                    rows="2"
                    auto-grow
                    :disabled="formDisabled"
                    :error-messages="errors.collect('pokynyPriVyrobeIPL')"
                  ></v-textarea>
                </v-flex>
              </v-expand-transition>
              <v-expand-transition>
                <v-flex v-if="!isLiek" xs12 md4>
                  <v-textarea
                    v-model="model.recept.pokyn_k_uzivaniu_ipl"
                    :label="$vuetify.t('Pokyn k užívaniu IPL')"
                    rows="2"
                    auto-grow
                    :disabled="formDisabled"
                    :error-messages="errors.collect('pokynKUzivaniuIPL')"
                  ></v-textarea>
                </v-flex>
              </v-expand-transition>
              <v-expand-transition>
                <v-flex v-if="!isLiek" xs12 md4>
                  <v-select
                    v-model="model.recept.ipl_cenova_kategoria"
                    :label="$vuetify.t('Cenová kategória')"
                    :items="iplCenoveKategorie"
                    item-text="name"
                    item-value="id"
                    :disabled="formDisabled"
                  ></v-select>
                </v-flex>
              </v-expand-transition>
              <v-expand-transition>
                <v-flex v-if="isTypZP" xs12 md6>
                  <v-textarea
                    v-model="model.recept.opis_pomocky_na_mieru"
                    :label="$vuetify.t('Opis pomôcky na mieru')"
                    rows="2"
                    auto-grow
                    maxlength="1800"
                    :counter="1800"
                    :disabled="formDisabled"
                  ></v-textarea>
                </v-flex>
              </v-expand-transition>
              <v-expand-transition>
                <v-flex v-if="isTypATC" xs12 md6>
                  <v-textarea
                    v-model="model.recept.atc_doplnok"
                    :label="$vuetify.t('ATC Doplnok')"
                    rows="2"
                    auto-grow
                    :disabled="formDisabled"
                    maxlength="120"
                    :counter="120"
                    :hint="'Vykázaný ATC doplnok, bude obsahovať cestu podania + liekovú formu + množstva liečiva v liekovej forme + veľkosť balenia (maximálna dĺžka 120 znakov) podľa údajov uvedených v zozname kategorizovaných liekov (doplnok referenčnej skupiny/podskupiny) v zmysle platnej legislatívy (zákon o lieku).'"
                  ></v-textarea>
                </v-flex>
              </v-expand-transition>
              <v-flex xs12 md8>
                <v-textarea
                  v-model="model.recept.zdovodnenie"
                  :label="$vuetify.t('Zdôvodnenie')"
                  rows="1"
                  auto-grow
                  :disabled="formDisabled"
                  maxlength="1800"
                  :counter="1800"
                  :hint="'Zdôvodnenie predpisu lieku či pomôcky, ak doteraz takúto informáciu uvádzal predpisujúci lekár aj na papierový recept/poukaz (napr. stupeň inkontinencie, množstevný limit, doplnkové diagnózy pre individuálne zdravotné pomôcky, informácia o trvalej/dočasnej potrebe pomôcky a pod.). Atribút je povinný pre individuálne zhotovované zdravotnícke pomôcky.'"
                  :error-messages="errors.collect('zdovodnenie')"
                ></v-textarea>
              </v-flex>
              <v-flex xs12 md4>
                <TextField
                  v-model="model.recept.mnozstvo_vykazane"
                  type="number"
                  :label="$vuetify.t('Počet balení')"
                  name="mnozstvoVykazane"
                  v-validate="'required|decimal|min_value:1'"
                  :disabled="formDisabled"
                ></TextField>
              </v-flex>
              <v-flex xs12 md6 v-if="isTypZP">
                <codebook-autocomplete
                  codebook-id="211"
                  name="merna_jednotka_zp"
                  v-model="model.recept.merna_jednotka_zp"
                  :label="$vuetify.t('Merná jednotka')"
                  :disabled="formDisabled"
                >
                </codebook-autocomplete>
              </v-flex>
              <v-flex xs4>
                <v-tooltip bottom>
                  <v-checkbox
                    slot="activator"
                    v-model="model.recept.produkt_na_vynimku"
                    :label="$vuetify.t('Produkt na výnimku')"
                    :disabled="formDisabled"
                  />
                  <span
                    v-html="
                      $vuetify.t(
                        'Príznak identifikujúci, či pre predpisovaný produkt má poistenec schválenú výnimku<br>alebo návrh na ZS v zdravotnej poisťovni a pre daný produkt sa vyžaduje predchádzajúce<br>schválenie zdravotnou poisťovňou.<br>Príznak vyznačuje predpisujúci lekár.'
                      )
                    "
                  ></span>
                </v-tooltip>
              </v-flex>
              <v-flex xs4>
                <v-checkbox
                  v-model="model.recept.neodkladna_zs"
                  :label="$vuetify.t('Neodkladná ZS')"
                  :disabled="formDisabled"
                />
              </v-flex>
              <v-flex xs4>
                <v-text-field
                  :label="$vuetify.t('Neplatič ?')"
                  disabled
                  :value="['Neviem', 'Áno', 'Nie'][model.recept.je_neplatic]"
                  hide-details
                ></v-text-field>
              </v-flex>
              <v-flex xs6 md4>
                <v-tooltip bottom>
                  <v-checkbox
                    slot="activator"
                    v-model="model.recept.trvale_postihnutie"
                    :label="$vuetify.t('Trvalé postihnutie ?')"
                    :disabled="formDisabled || !isJeBLOVisible"
                  />
                  <span
                    v-html="
                      $vuetify.t(
                        'Označenie, že predmet preskripcie je predpísaný pre pacienta s trvalým postihnutím.<br>Používa sa najmä pre predpis zdravotných pomôcok.'
                      )
                    "
                  ></span>
                </v-tooltip>
              </v-flex>
              <!--v-flex xs6 md4>
                <v-checkbox
                  v-model="model.recept.je_blo"
                  :label="$vuetify.t('Odoslať na Bezpečné lieky online ?')"
                  :disabled="formDisabled || !isJeBLOVisible"
                  :error-messages="errors.collect('JeBLO')"
                  @change="changeJeBLO()"
                />
              </v-flex-->
              <!--v-expand-transition>
                <v-flex v-if="model.recept.je_blo" xs6 md8>
                  <v-checkbox
                    v-model="jeXMLSign"
                    :label="$vuetify.t('Podpísať recept elektronicky ?')"
                    :disabled="formDisabled || !isJeBLOVisible"
                    :error-messages="errors.collect('jeXMLSign')"
                  />
                </v-flex>
              </v-expand-transition-->
              <v-expand-transition>
                <v-flex xs6 md4 v-show="showVynutenyZapis">
                  <v-checkbox
                    v-model="model.recept.vynuteny_zapis"
                    :label="$vuetify.t('Vynútený zápis ?')"
                    :disabled="formDisabled"
                  />
                </v-flex>
              </v-expand-transition>
              <v-expand-transition>
                <v-flex xs6 md4 v-show="showVynutenyZapis">
                  <v-textarea
                    v-model="model.recept.vynuteny_zapis_dovod"
                    :label="$vuetify.t('Dôvod vynúteného zápisu')"
                    rows="2"
                    auto-grow
                    :disabled="formDisabled || !model.recept.vynuteny_zapis"
                  ></v-textarea>
                </v-flex>
              </v-expand-transition>
              <v-expand-transition>
                <div v-show="ciarovyKod !== ''" style="text-align: center">
                  <svg id="barcode"></svg>
                </div>
              </v-expand-transition>
            </v-layout>
            <v-layout row>
              <v-flex grow>
                <v-badge left>
                  <span slot="badge">
                    {{ this.generika === null ? 0 : this.generika.length }}
                  </span>
                  <v-menu offset-y>
                    <v-btn
                      slot="activator"
                      small
                      :loading="generikaSpinning"
                      class="mx-0"
                      >{{ $vuetify.t("Generiká") }}</v-btn
                    >
                    <v-list
                      v-if="this.generika !== null && this.generika.length > 0"
                    >
                      <v-list-tile
                        v-for="item in generika"
                        :key="item.ID"
                        @click="changeGenerLiek(item.Kod)"
                      >
                        <v-list-tile-title>
                          {{ item.Nazov + " " + item.DoplnokNazvu }}
                        </v-list-tile-title>
                      </v-list-tile>
                    </v-list>
                  </v-menu>
                </v-badge>
                <v-btn small @click="showInterakcie()">
                  {{ $vuetify.t("Overiť interakcie") }}
                </v-btn>
                <v-btn small @click="showLiekovaKarta()">
                  {{ $vuetify.t("Lieková karta") }}
                </v-btn>
              </v-flex>
              <v-flex shrink>
                <v-btn small @click="activeTab = 1">
                  {{ $vuetify.t("Ďalej") }}
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>
        <!-- ************************************ TAB C.2 ************************************ -->
        <v-tab-item>
          <v-container fluid grid-list-md class="pt-1 pb-1">
            <v-layout row wrap>
              <v-flex xs12>
                <v-select
                  v-model="model.recept.typ_davkovania"
                  :label="$vuetify.t('Typ dávkovania')"
                  :items="typyDavkovania"
                  item-text="name"
                  item-value="id"
                  clearable
                  v-validate="'dummy'"
                  :error-messages="errors.collect('TypDavkovania')"
                  data-vv-name="TypDavkovania"
                  :data-vv-as="$vuetify.t('Typ dávkovania')"
                  :disabled="formDisabled"
                ></v-select>
              </v-flex>
              <v-expand-transition>
                <v-flex v-if="isDenneDavkovanie1Visible" xs12>
                  <v-alert
                    type="error"
                    v-if="isDenneDavkovanie1Error"
                    :value="true"
                    class="ma-0 pa-0"
                  >
                    {{ errors.first("DenneDavkovanie1") }}
                  </v-alert>
                  <v-layout row>
                    <v-flex xs3 mx-1>
                      <v-text-field
                        type="number"
                        v-model.number="model.recept.denne_davkovanie1[0]"
                        :label="$vuetify.t('Ráno')"
                        v-validate="'decimal|min_value:0'"
                        data-vv-name="DenneDavkovanie10"
                        :data-vv-as="$vuetify.t('Ráno')"
                        :error-messages="errors.collect('DenneDavkovanie10')"
                        maxlength="5"
                        :disabled="formDisabled"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs3 mx-1>
                      <v-text-field
                        type="number"
                        v-model.number="model.recept.denne_davkovanie1[1]"
                        :label="$vuetify.t('Obed')"
                        v-validate="'decimal|min_value:0'"
                        data-vv-name="DenneDavkovanie11"
                        :data-vv-as="$vuetify.t('Obed')"
                        :error-messages="errors.collect('DenneDavkovanie11')"
                        maxlength="5"
                        :disabled="formDisabled"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs3 mx-1>
                      <v-text-field
                        type="number"
                        v-model.number="model.recept.denne_davkovanie1[2]"
                        :label="$vuetify.t('Večer')"
                        v-validate="'decimal|min_value:0'"
                        data-vv-name="DenneDavkovanie12"
                        :data-vv-as="$vuetify.t('Večer')"
                        :error-messages="errors.collect('DenneDavkovanie12')"
                        maxlength="5"
                        :disabled="formDisabled"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs3 mx-1>
                      <v-text-field
                        type="number"
                        v-model.number="model.recept.denne_davkovanie1[3]"
                        :label="$vuetify.t('Noc')"
                        v-validate="'decimal|min_value:0'"
                        data-vv-name="DenneDavkovanie13"
                        :data-vv-as="$vuetify.t('Noc')"
                        :error-messages="errors.collect('DenneDavkovanie13')"
                        maxlength="5"
                        :disabled="formDisabled"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-expand-transition>
              <v-expand-transition>
                <v-flex v-if="isDenneDavkovanie2Visible" xs6>
                  <TextField
                    v-model="model.recept.denne_davkovanie2_pj"
                    type="number"
                    :label="$vuetify.t('Počet jednotiek')"
                    name="DenneDavkovanie2PJ"
                    v-validate="'decimal|min_value:0'"
                    :disabled="formDisabled"
                  ></TextField>
                </v-flex>
              </v-expand-transition>
              <v-expand-transition>
                <v-flex v-if="isDenneDavkovanie2Visible" xs6>
                  <TextField
                    v-model="model.recept.denne_davkovanie2_oh"
                    type="number"
                    :label="$vuetify.t('Opakovanie v hodinách')"
                    name="DenneDavkovanie2OH"
                    v-validate="'decimal|min_value:0'"
                    :disabled="formDisabled"
                  ></TextField>
                </v-flex>
              </v-expand-transition>
              <v-expand-transition>
                <v-flex v-if="isJednotkaDavkovaniaVisible" xs4>
                  <v-select
                    v-model="model.recept.jednotka_davkovania"
                    :label="$vuetify.t('Jednotka dávkovania')"
                    :items="jednotkyDavkovania"
                    item-text="name"
                    item-value="id"
                    clearable
                    v-validate="'dummy'"
                    :error-messages="errors.collect('JednotkaDavkovania')"
                    data-vv-name="JednotkaDavkovania"
                    :data-vv-as="$vuetify.t('Jednotka dávkovania')"
                    :disabled="formDisabled"
                  ></v-select>
                </v-flex>
              </v-expand-transition>
              <v-expand-transition>
                <v-flex v-if="isSposobPodaniaVisible" xs4>
                  <v-select
                    v-model="model.recept.sposob_podania"
                    :label="$vuetify.t('Spôsob podania')"
                    :items="CS.CSSposobPodania"
                    item-text="name"
                    item-value="id"
                    clearable
                    v-validate="'dummy'"
                    :error-messages="errors.collect('SposobPodania')"
                    data-vv-name="SposobPodania"
                    :disabled="formDisabled"
                    :data-vv-as="$vuetify.t('Spôsob podania')"
                  ></v-select>
                </v-flex>
              </v-expand-transition>
              <v-expand-transition>
                <v-flex v-if="isPeriodicitaVisible" xs4>
                  <v-select
                    v-model="model.recept.periodicita"
                    :label="$vuetify.t('Periodicita')"
                    :items="CS.CSPeriodaDavkovania"
                    item-text="name"
                    item-value="id"
                    clearable
                    v-validate="'dummy'"
                    :error-messages="errors.collect('Periodicita')"
                    data-vv-name="Periodicita"
                    :data-vv-as="$vuetify.t('Periodicita')"
                    :disabled="formDisabled"
                  ></v-select>
                </v-flex>
              </v-expand-transition>
              <v-flex xs12>
                <TextField
                  v-model="model.recept.doba_liecby"
                  type="number"
                  :label="$vuetify.t('Doba liečby v dňoch')"
                  name="DobaLiecby"
                  v-validate="'min_value:1'"
                  :disabled="formDisabled"
                ></TextField>
              </v-flex>
              <v-expand-transition>
                <v-flex v-if="isPoznamkaVisible" xs12>
                  <v-textarea
                    v-model="model.recept.poznamka"
                    :label="$vuetify.t('Voľný text')"
                    rows="2"
                    auto-grow
                    :disabled="formDisabled"
                    :error-messages="errors.collect('Poznamka')"
                  ></v-textarea>
                </v-flex>
              </v-expand-transition>
              <v-flex xs12>
                <v-textarea
                  v-model="automatickyPrepis"
                  :label="$vuetify.t('Prepis')"
                  rows="2"
                  auto-grow
                  readonly
                  :disabled="formDisabled"
                ></v-textarea>
              </v-flex>
              <v-flex>
                <v-layout row>
                  <v-flex class="text-xs-left">
                    <v-btn small @click="activeTab = 0">
                      {{ $vuetify.t("Späť") }}
                    </v-btn>
                  </v-flex>
                  <v-flex class="text-xs-right">
                    <v-btn small @click="activeTab = 2">
                      {{ $vuetify.t("Ďalej") }}
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>
        <!-- ********************************** TAB C.3 ************************************ -->
        <v-tab-item>
          <v-container fluid grid-list-md class="pt-1 pb-1">
            <v-layout column>
              <v-flex>
                <v-layout row>
                  <v-flex grow>
                    <v-layout column>
                      <v-flex
                        v-for="(item, index) in model.recept.zakazane_lieky"
                        :key="index"
                      >
                        <v-layout row v-if="!formDisabled">
                          <v-flex grow>
                            <codebook-autocomplete
                              v-if="
                                model.recept.zakazane_lieky[index].id === -1
                              "
                              :codebook-id="enums.CodebookIDLiek"
                              name="zakazane_lieky"
                              v-model="model.recept.zakazane_lieky[index]"
                              :label="$vuetify.t('')"
                            >
                              <template slot="selection" slot-scope="{ item }">
                                <span>
                                  {{ item.liek.kod_sukl }}-{{ item.liek.nazov }}
                                  {{ item.liek.doplnok_nazvu }}
                                </span>
                              </template>
                              <template slot="item" slot-scope="{ item }">
                                <v-list-tile-content>
                                  <v-list-tile-title>
                                    {{ item.liek.kod_sukl }}
                                  </v-list-tile-title>
                                  <v-list-tile-sub-title>
                                    <strong>{{ item.liek.nazov }}</strong>
                                    <span class="pl-1 text-truncate">
                                      {{ item.liek.doplnok_nazvu }}
                                    </span>
                                  </v-list-tile-sub-title>
                                </v-list-tile-content>
                              </template>
                            </codebook-autocomplete>
                            <v-text-field
                              v-else
                              readonly
                              :value="
                                getOnlyLiekOptionLabel(
                                  model.recept.zakazane_lieky[index]
                                )
                              "
                            ></v-text-field>
                          </v-flex>
                          <v-flex shrink>
                            <v-btn
                              fab
                              icon
                              small
                              outline
                              @click="
                                model.recept.zakazane_lieky.splice(index, 1)
                              "
                            >
                              <v-icon>remove</v-icon>
                            </v-btn>
                          </v-flex>
                        </v-layout>
                        <v-text-field
                          v-else
                          readonly
                          :value="
                            getOnlyLiekOptionLabel(
                              model.recept.zakazane_lieky[index]
                            )
                          "
                        ></v-text-field>
                      </v-flex>
                      <v-flex v-show="!formDisabled" class="text-xs-right">
                        <v-btn
                          small
                          @click="model.recept.zakazane_lieky.push({ id: -1 })"
                        >
                          <span>{{ $vuetify.t("Pridať zakázaný liek") }}</span>
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex>
                <v-checkbox
                  v-model="model.recept.zakaz_vydania_nahrady"
                  :label="$vuetify.t('Zákaz vydania náhrady ?')"
                  :disabled="formDisabled"
                />
              </v-flex>
              <v-flex>
                <TextField
                  v-model="model.recept.perioda_opakovaneho_receptu"
                  type="number"
                  :label="$vuetify.t('Perióda opakovaného receptu')"
                  name="PeriodaOpakovanehoReceptu"
                  :disabled="formDisabled"
                ></TextField>
              </v-flex>
              <v-flex>
                <TextField
                  v-model="model.recept.pocet_opakovani_opakovaneho_receptu"
                  type="number"
                  :label="$vuetify.t('Počet opakovaní op.receptu')"
                  name="PocetOpakovaniOpakovanehoReceptu"
                  :disabled="formDisabled"
                ></TextField>
              </v-flex>
              <v-flex>
                <v-text-field
                  readonly
                  :disabled="formDisabled"
                  :value="model.recept.platnost_receptu | formatDatum"
                  :label="$vuetify.t('Platnosť receptu')"
                  name="PlatnostReceptu"
                  v-validate="'dummy'"
                  :error-messages="errors.collect('PlatnostReceptu')"
                  data-vv-name="PlatnostReceptu"
                ></v-text-field>
              </v-flex>
              <v-flex>
                <v-layout row>
                  <v-flex class="text-xs-left">
                    <v-btn small @click="activeTab = 1">
                      {{ $vuetify.t("Späť") }}
                    </v-btn>
                  </v-flex>
                  <v-flex class="text-xs-right">
                    <v-btn small @click="activeTab = 3">
                      {{ $vuetify.t("Ďalej") }}
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>
        <!-- ************************************ TAB C.4 ************************************ -->
        <v-tab-item>
          <v-container fluid grid-list-md class="pt-1 pb-1">
            <v-layout column>
              <v-flex>
                <DatePicker
                  v-model="model.recept.datum_odporucania"
                  :label="$vuetify.t('Dátum odporúčania')"
                  name="DatumOdporucania"
                  :disabled="formDisabled"
                ></DatePicker>
              </v-flex>
              <v-flex>
                <codebook-autocomplete
                  :codebook-id="enums.CodebookIDZdravotnickyPracovnikKod"
                  name="odporucajuci_lekar"
                  v-model="model.recept.odporucajuci_lekar"
                  :label="$vuetify.t('Odporúčajúci lekár')"
                  :disabled="formDisabled"
                >
                  <template slot="selection" slot-scope="{ item }">
                    <span>
                      {{ item.code }} -
                      {{ item.display_name }}
                    </span>
                  </template>
                  <template slot="item" slot-scope="{ item }">
                    <v-list-tile-content>
                      <v-list-tile-title>
                        {{ item.display_name }}
                      </v-list-tile-title>
                      <v-list-tile-sub-title>
                        {{ item.code }}
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </template>
                </codebook-autocomplete>
              </v-flex>
              <v-flex>
                <codebook-autocomplete
                  :codebook-id="enums.CodebookIDOUPZS"
                  name="odporucajuci_lekar_oupzs"
                  v-model="model.recept.odporucajuci_lekar_oupzs"
                  :label="$vuetify.t('OÚ PZS odp.lekára')"
                  :disabled="formDisabled"
                >
                  <template slot="selection" slot-scope="{ item }">
                    <span>
                      {{ item.oupzs.kod_pzs }} -
                      {{ item.display_name }}
                    </span>
                  </template>
                  <template slot="item" slot-scope="{ item }">
                    <v-list-tile-content>
                      <v-list-tile-title>
                        {{ item.display_name }}
                      </v-list-tile-title>
                      <v-list-tile-sub-title>
                        {{ item.oupzs.kod_pzs }}
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </template>
                </codebook-autocomplete>
              </v-flex>
              <v-flex>
                <codebook-autocomplete
                  :codebook-id="enums.CodebookIDZdravotnickyPracovnikKod"
                  name="zastupovany_lekar"
                  v-model="model.recept.zastupovany_lekar"
                  :label="$vuetify.t('Zastupovaný lekár')"
                  :disabled="formDisabled"
                >
                  <template slot="selection" slot-scope="{ item }">
                    <span>
                      {{ item.code }} -
                      {{ item.display_name }}
                    </span>
                  </template>
                  <template slot="item" slot-scope="{ item }">
                    <v-list-tile-content>
                      <v-list-tile-title>
                        {{ item.display_name }}
                      </v-list-tile-title>
                      <v-list-tile-sub-title>
                        {{ item.code }}
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </template>
                </codebook-autocomplete>
              </v-flex>
              <v-flex>
                <codebook-autocomplete
                  :codebook-id="enums.CodebookIDOUPZS"
                  name="zastupovany_lekar_oupzs"
                  v-model="model.recept.zastupovany_lekar_oupzs"
                  :label="$vuetify.t('OÚ PZS zast.lekára')"
                  :disabled="formDisabled"
                >
                  <template slot="selection" slot-scope="{ item }">
                    <span>
                      {{ item.oupzs.kod_pzs }} -
                      {{ item.display_name }}
                    </span>
                  </template>
                  <template slot="item" slot-scope="{ item }">
                    <v-list-tile-content>
                      <v-list-tile-title>
                        {{ item.display_name }}
                      </v-list-tile-title>
                      <v-list-tile-sub-title>
                        {{ item.oupzs.kod_pzs }}
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </template>
                </codebook-autocomplete>
              </v-flex>
              <v-flex>
                <v-layout row>
                  <v-flex class="text-xs-left">
                    <v-btn small @click="activeTab = 2">
                      {{ $vuetify.t("Späť") }}
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <!--v-layout column>
      <v-flex>
        <p
          v-if="
            model.recept.je_blo &&
            model.id !== '' &&
            model.recept.blo_over_date !== null
          "
        >
          <b>Overenie v poisťovni:</b>
          <span class="grey--text pl-1">
            {{ model.recept.blo_over_date | formatDatum }}
            {{
              model.recept.blo_over_error === ""
                ? "OK"
                : model.recept.blo_over_error
            }}
          </span>
        </p>
      </v-flex>
    </v-layout-->
  </v-form>
</template>

<script>
import BaseForm from "./../BaseForm.vue"
import moment from "moment"
import * as Miscella from "./../../miscella.js"
import TextField from "./../TextField.vue"
import DatePicker from "./../DatePicker.vue"
import Vue from "vue"
import JsBarcode from "jsbarcode"
import * as enums from "../../plugins/enums.js"
import * as subory from "../../components/mixins/subory.js"
import EZdravie from "../../ehealth/ezdravie.js"
import CodebookAutocomplete from "./../ciselnikComponents/CodebookAutocomplete.vue"
import { mapState } from "vuex"

export default {
  name: "FormRecept",
  extends: BaseForm,
  $_veeValidate: {
    validator: "new",
  },
  props: {
    pacientId: {
      type: String,
      required: true,
    },
    vysetrenieId: {
      type: String,
      required: false,
      default: "",
    },
  },
  components: {
    TextField,
    DatePicker,
    CodebookAutocomplete,
  },
  mixins: [subory.mixins],
  data: function () {
    return {
      mapErrorsInTab: new Map([
        [
          0,
          [
            "datum",
            "diagnoza",
            "smartLiek",
            "zlozenieIPL",
            "pokynyPriVyrobeIPL",
            "pokynKUzivaniuIPL",
            "mnozstvoVykazane",
            "JeBLO",
            "jeXMLSign",
            "zdovodnenie",
          ],
        ],
        [
          1,
          [
            "DenneDavkovanie1",
            "DenneDavkovanie10",
            "DenneDavkovanie11",
            "DenneDavkovanie12",
            "DenneDavkovanie13",
            "DenneDavkovanie2PJ",
            "DenneDavkovanie2OH",
            "JednotkaDavkovania",
            "SposobPodania",
            "Periodicita",
            "DobaLiecby",
            "Poznamka",
          ],
        ],
        [
          2,
          [
            "PeriodaOpakovanehoReceptu",
            "PocetOpakovaniOpakovanehoReceptu",
            "PlatnostReceptu",
          ],
        ],
        [
          3,
          [
            "DatumOdporucania",
            "odporucajuci_lekar_oupzs",
            "zastupovany_lekar_oupzs",
          ],
        ],
      ]),
      loading: true,
      activeTab: 0,
      liek: null,
      lieky: [],
      liekySearch: "",
      generika: [],
      onlyLieky: [],
      diagnozy: [],
      formAction: "",
      saveSpinning: false,
      generikaSpinning: false,
      // recept
      model: this.clearRecept(),
      // ciselniky
      CS: {
        CSJednotkaDavkovania: [],
        CSPeriodaDavkovania: [],
        CSSposobPodania: [],
        CSTypDavkovania: [],
        MapZlfJd: [],
      },
      //jeXMLSign: true,
      //localStorage.getItem("jeXMLSign") !== null
      //  ? localStorage.getItem("jeXMLSign")
      //  : false,
      xmlSignature: "",
      logError: "",
      showBLOError: true,
    }
  },
  computed: {
    ...mapState({
      ambulanceId: (state) =>
        Miscella.isSet(state.me.ambulance) ? state.me.ambulance.id : "",
    }),
    enums: function () {
      return enums
    },
    ciarovyKod: function () {
      let ret = ""
      ret = this.model.recept.blo_ciarovy_kod
      if (!Miscella.isBlank(ret)) {
        ret = ret.substring(ret.indexOf(")") + 1, ret.length)
      }
      return ret
    },
    getTyp: function () {
      let ret = ""
      if (Miscella.isSet(this.liek)) {
        ret = this.liek.typ
      }
      if (this.isIPL) {
        ret = "IPL"
      }
      return ret
    },
    isIPL: function () {
      let ret = false
      if (
        !Miscella.isEmpty(this.model.recept.zlozenie_ipl) ||
        !Miscella.isEmpty(this.model.recept.pokyny_pri_vyrobe_ipl) ||
        !Miscella.isEmpty(this.model.recept.pokyn_k_uzivaniu_ipl)
      ) {
        ret = true
      }
      return ret
    },
    isTypZP: function () {
      let ret = false
      if (Miscella.isSet(this.liek) && this.liek.typ === "ZP") {
        ret = true
      }
      return ret
    },
    isTypATC: function () {
      let ret = false
      if (Miscella.isSet(this.liek) && this.liek.typ === "ATC") {
        ret = true
      }
      return ret
    },
    isLiek: function () {
      let ret = false
      if (Miscella.isSet(this.liek) && this.liek.id !== "") {
        ret = true
      }
      return ret
    },
    isTypLiek: function () {
      let ret = false
      if (Miscella.isSet(this.liek) && this.liek.typ === "Liek") {
        ret = true
      }
      return ret
    },
    isOpakRecept: function () {
      let ret = false
      if (
        this.model.recept.perioda_opakovaneho_receptu !== "" &&
        this.model.recept.perioda_opakovaneho_receptu !== 0
      ) {
        if (
          this.model.recept.pocet_opakovani_opakovaneho_receptu !== "" &&
          this.model.recept.pocet_opakovani_opakovaneho_receptu !== 0
        ) {
          ret = true
        }
      }
      if (
        this.model.recept.pocet_opakovani_opakovaneho_receptu !== "" &&
        this.model.recept.pocet_opakovani_opakovaneho_receptu !== 0
      ) {
        if (
          this.model.recept.perioda_opakovaneho_receptu !== "" &&
          this.model.recept.perioda_opakovaneho_receptu !== 0
        ) {
          ret = true
        }
      }
      return ret
    },
    typyDavkovania: function () {
      let td = []
      if (this.isTypZP) {
        let csTypDavkovaniaVAR = {
          id: enums.CSTypDavkovania.CSTypDavkovaniaVAR,
          name: enums.getName(
            this.CS.CSTypDavkovania,
            enums.CSTypDavkovania.CSTypDavkovaniaVAR
          ),
        }
        let csTypDavkovaniaDOP = {
          id: enums.CSTypDavkovania.CSTypDavkovaniaDOP,
          name: enums.getName(
            this.CS.CSTypDavkovania,
            enums.CSTypDavkovania.CSTypDavkovaniaDOP
          ),
        }
        td.push(csTypDavkovaniaVAR)
        td.push(csTypDavkovaniaDOP)
        if (
          this.model.recept.typ_davkovania !==
            enums.CSTypDavkovania.CSTypDavkovaniaVAR &&
          this.model.recept.typ_davkovania !==
            enums.CSTypDavkovania.CSTypDavkovaniaDOP
        ) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.model.recept.typ_davkovania =
            enums.CSTypDavkovania.CSTypDavkovaniaVAR
        }
      } else {
        console.log("typyDavkovania Start", td.length)
        if (Miscella.isSet(this.CS.CSTypDavkovania)) {
          console.log("typyDavkovania 1")
          td = this.CS.CSTypDavkovania
        }
      }
      console.log("typyDavkovania End", td)
      return td
    },
    iplCenoveKategorie: function () {
      let td = []
      td = enums.IPLCenoveKategorie
      return td
    },
    jednotkyDavkovania: function () {
      let liekovaForma = "" // prve tri znaky z doplnku nazvu lieku
      if (this.isTypLiek) {
        let ret = this.liek.subtitle
        ret = ret.substr(0, 3)
        ret = ret.toUpperCase()
        liekovaForma = ret // this.liek.subtitle.substr(0, 3).toUpperCase()
        let povJednotky = this.CS.MapZlfJd[liekovaForma]
        let jd = []
        if (povJednotky === undefined) {
          jd = this.CS.CSJednotkaDavkovania
        } else {
          for (let pj of povJednotky) {
            for (let jedn of this.CS.CSJednotkaDavkovania) {
              if (jedn.id === pj) {
                jd.push(jedn)
              }
            }
          }
        }
        let found = jd.find(
          function (element) {
            return element.id === this.model.recept.jednotka_davkovania
          }.bind(this)
        )
        if (found === null) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.model.recept.jednotka_davkovania = jd[0].id
        }
        return jd
      } else {
        if (
          this.CS.CSJednotkaDavkovania !== undefined &&
          this.CS.CSJednotkaDavkovania !== null
        ) {
          let found = this.CS.CSJednotkaDavkovania.find(
            function (element) {
              return element.id === this.model.recept.jednotka_davkovania
            }.bind(this)
          )
          if (found === null) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.model.recept.jednotka_davkovania =
              this.CS.CSJednotkaDavkovania[0].id
          }
        }
        return this.CS.CSJednotkaDavkovania
      }
    },
    /*
    uzivanieOdDisabledState: function () {
      return {
        to: this.datum
      }
    },
    uzivanieDo: function () {
      return moment(this.uzivanieOd)
        .add(
          this.dobaUzivania !== '' && this.dobaUzivania > 0
            ? this.dobaUzivania - 1
            : 0,
          'days'
        )
        .toDate()
    },
    */
    isDenneDavkovanie1Error: function () {
      /*
      return (
        this.errors.has("DenneDavkovanie1") ||
        this.errors.has("DenneDavkovanie10") ||
        this.errors.has("DenneDavkovanie11") ||
        this.errors.has("DenneDavkovanie12") ||
        this.errors.has("DenneDavkovanie13")
      )
      */
      return this.errors.has("DenneDavkovanie1")
    },
    isJeBLOVisible: function () {
      let ret = false
      let dnes = moment().startOf("day").utc()
      let receptDatum = moment(this.model.Date).startOf("day").utc()
      if (receptDatum.isSame(dnes)) {
        ret = true
      }
      return ret
    },
    isDenneDavkovanie1Visible: function () {
      let ret = false
      if (
        this.model.recept.typ_davkovania ===
        enums.CSTypDavkovania.CSTypDavkovaniaPEV
      ) {
        ret = !this.isDenneDavkovanie2Vyplnene
      }
      return ret
    },
    isDenneDavkovanie2Visible: function () {
      let ret = false
      if (
        this.model.recept.typ_davkovania ===
        enums.CSTypDavkovania.CSTypDavkovaniaPEV
      ) {
        ret = !this.isDenneDavkovanie1Vyplnene
      }
      return ret
    },
    isDenneDavkovanie1Vyplnene: function () {
      let pocet =
        Number(this.model.recept.denne_davkovanie1[0]) +
        Number(this.model.recept.denne_davkovanie1[1]) +
        Number(this.model.recept.denne_davkovanie1[2]) +
        Number(this.model.recept.denne_davkovanie1[3])
      return pocet !== 0
    },
    isDenneDavkovanie2Vyplnene: function () {
      let pocet =
        Number(this.model.recept.denne_davkovanie2_pj) +
        Number(this.model.recept.denne_davkovanie2_oh)
      return pocet !== 0
    },
    isDenneDavkovanieVyplnene: function () {
      let ret = false
      if (this.isDenneDavkovanie1Visible) {
        ret = this.isDenneDavkovanie1Vyplnene
      }
      if (this.isDenneDavkovanie2Visible) {
        ret = this.isDenneDavkovanie2Vyplnene
      }
      return ret
    },
    isJednotkaDavkovaniaVisible: function () {
      let ret = false
      if (
        this.model.recept.typ_davkovania ===
        enums.CSTypDavkovania.CSTypDavkovaniaPEV
      ) {
        ret = true
      }
      return ret
    },
    isSposobPodaniaVisible: function () {
      let ret = false
      if (
        this.model.recept.typ_davkovania ===
        enums.CSTypDavkovania.CSTypDavkovaniaPEV
      ) {
        ret = true
      }
      return ret
    },
    isPeriodicitaVisible: function () {
      let ret = false
      if (
        this.model.recept.typ_davkovania ===
        enums.CSTypDavkovania.CSTypDavkovaniaPEV
      ) {
        ret = true
      }
      return ret
    },
    isPoznamkaVisible: function () {
      let ret = true
      if (
        this.model.recept.typ_davkovania ===
        enums.CSTypDavkovania.CSTypDavkovaniaPEV
      ) {
        ret = false
        if (this.isDenneDavkovanieVyplnene) {
          if (this.model.recept.jednotka_davkovania !== "") {
            if (this.model.recept.sposob_podania !== "") {
              if (this.model.recept.periodicita !== "") {
                ret = true
              }
            }
          }
        }
      }
      return ret
    },
    diagnozyUrl: function () {
      return "/api/patient/" + this.pacientId + "/diagnozyForSelect"
    },
    ehealthStatus: function () {
      return this.$store.state.ehealth.status
    },
    ehealthDemo: function () {
      return this.$store.state.ehealth.demo
    },
    formDisabled: function () {
      if (this.model.recept != null) {
        if (this.model.recept.blo_ciarovy_kod != null) {
          if (this.model.recept.blo_ciarovy_kod !== "") {
            return true
          }
        }
        if (this.model.recept.pocet_vytlaceni != null) {
          if (this.model.recept.pocet_vytlaceni > 0) {
            return true
          }
        }
      }
      return false
    },
    showVynutenyZapis: function () {
      return true
      /*
      if (this.model.recept != null) {
        if (this.model.recept.blo !== undefined) {
          if (this.model.recept.blo.error_msg !== "") {
            // ak je chyba z BLO tak ho zobraz
            return true
          }
        }
        // ak je vyplneny tak ho predsa len zobraz
        if (this.model.recept.vynuteny_zapis_dovod !== "") {
          return true
        }
      }
      return false
      */
    },
    automatickyPrepis: function () {
      let ret = ""
      if (
        this.model.recept.typ_davkovania ===
        enums.CSTypDavkovania.CSTypDavkovaniaPEV
      ) {
        if (this.isDenneDavkovanie1Vyplnene) {
          ret =
            "D.S." +
            this.model.recept.denne_davkovanie1.join("-") +
            ", " +
            enums.getName(
              this.CS.CSJednotkaDavkovania,
              this.model.recept.jednotka_davkovania
            ) +
            " " +
            enums.getName(
              this.CS.CSPeriodaDavkovania,
              this.model.recept.periodicita
            ) +
            " " +
            enums.getName(
              this.CS.CSSposobPodania,
              this.model.recept.sposob_podania
            ) +
            " " +
            this.model.recept.poznamka +
            (this.model.recept.doba_liecby !== null
              ? " po dobu " + this.model.recept.doba_liecby + " dní"
              : "") +
            "."
        }
        if (this.isDenneDavkovanie2Vyplnene) {
          ret =
            "D.S. á " +
            this.model.recept.denne_davkovanie2_oh +
            " hod. " +
            this.model.recept.denne_davkovanie2_pj +
            " " +
            enums.getName(
              this.CS.CSJednotkaDavkovania,
              this.model.recept.jednotka_davkovania
            ) +
            " " +
            enums.getName(
              this.CS.CSSposobPodania,
              this.model.recept.sposob_podania
            ) +
            " " +
            this.model.recept.poznamka +
            (this.model.recept.doba_liecby !== null
              ? " po dobu " + this.model.recept.doba_liecby + " dní"
              : "") +
            "."
        }
      }
      if (
        this.model.recept.typ_davkovania ===
        enums.CSTypDavkovania.CSTypDavkovaniaVAR
      ) {
        ret = "D.S. " + this.model.recept.poznamka + "."
      }
      if (
        this.model.recept.typ_davkovania ===
          enums.CSTypDavkovania.CSTypDavkovaniaAMM ||
        this.model.recept.typ_davkovania ===
          enums.CSTypDavkovania.CSTypDavkovaniaDOP ||
        this.model.recept.typ_davkovania ===
          enums.CSTypDavkovania.CSTypDavkovaniaROZ ||
        this.model.recept.typ_davkovania ===
          enums.CSTypDavkovania.CSTypDavkovaniaRAD
      ) {
        ret =
          "D.S. " +
          enums.getName(
            this.CS.CSTypDavkovania,
            this.model.recept.typ_davkovania
          ) +
          " " +
          this.model.recept.poznamka +
          "."
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.model.recept.prepis_davkovania = ret
      return ret
    },
    tabClass: function () {
      return {
        "text-danger": true,
      }
    },
    isEhealthCardConnected: function () {
      let jeKarta = false
      if (this.ehealthStatus === "CARD_STATUS_CONNECTED") {
        jeKarta = true
      }
      return jeKarta
    },
  },
  watch: {
    "$store.state.thing": function () {
      let thing = this.$store.state.thing
      if (thing !== null) {
        if (thing.id === this.model.id) {
          console.log(
            "FormRecept.vue, changed thing in store, setting this.model.recept",
            thing
          )
          //this.model = Miscella.cloneObject(thing)
          this.edit(thing)
          this.$store.commit("setThing", null)
          this.$emit("saved", this.model)
        }
      }
    },
    liekySearch: function () {
      console.log("FormRecept.vue, lieky search", this.liekySearch)
      if (this.liekySearch !== undefined) {
        if (this.liekySearch !== null && this.liekySearch.length > 2) {
          //Miscella.myDelay(this.fetchLieky, 1000)
          this.myDelay(() => {
            this.fetchLieky()
          }, 1000)
        }
      }
    },
    "model.recept.perioda_opakovaneho_receptu": function () {
      this.setPlatnostReceptu()
    },
    "model.recept.pocet_opakovani_opakovaneho_receptu": function () {
      this.setPlatnostReceptu()
    },
    "model.recept.blo_ciarovy_kod": function () {
      if (this.ciarovyKod !== "") {
        JsBarcode("#barcode", this.ciarovyKod, { width: 1, height: 25 })
      }
    },
    "model.recept.denne_davkovanie1": function () {
      this.setDobaLiecby(
        this.model.recept.typ_davkovania,
        this.model.recept.periodicita,
        this.model.recept.jednotka_davkovania
      )
    },
    "model.recept.denne_davkovanie2_pj": function () {
      this.setDobaLiecby(
        this.model.recept.typ_davkovania,
        this.model.recept.periodicita,
        this.model.recept.jednotka_davkovania
      )
    },
    "model.recept.denne_davkovanie2_oh": function () {
      this.setDobaLiecby(
        this.model.recept.typ_davkovania,
        this.model.recept.periodicita,
        this.model.recept.jednotka_davkovania
      )
    },
    "model.recept.jednotka_davkovania": function (newValue) {
      if (
        newValue === enums.CSJednotkaDavkovania.CSJednotkaDavkovaniaTAB ||
        newValue === enums.CSJednotkaDavkovania.CSJednotkaDavkovaniaCAP ||
        newValue === enums.CSJednotkaDavkovania.CSJednotkaDavkovaniaKAP
      ) {
        this.setDobaLiecby(
          this.model.recept.typ_davkovania,
          this.model.recept.periodicita,
          newValue
        )
      }
    },
    "model.recept.periodicita": function (newValue) {
      if (newValue !== enums.CSPeriodaDavkovania.CSPeriodaDavkovaniaP) {
        this.setDobaLiecby(
          this.model.recept.typ_davkovania,
          newValue,
          this.model.recept.jednotka_davkovania
        )
      }
    },
    "model.recept.typ_davkovania": function (newValue, oldValue) {
      if (
        oldValue === enums.CSTypDavkovania.CSTypDavkovaniaPEV ||
        newValue != enums.CSTypDavkovania.CSTypDavkovaniaPEV
      ) {
        this.model.recept.denne_davkovanie2_pj = 0
        this.model.recept.denne_davkovanie2_oh = 0
        this.model.recept.jednotka_davkovania = null
        this.model.recept.sposob_podania = null
        this.model.recept.periodicita = null
      }
      if (
        newValue === enums.CSTypDavkovania.CSTypDavkovaniaAMM ||
        newValue === enums.CSTypDavkovania.CSTypDavkovaniaDOP ||
        newValue === enums.CSTypDavkovania.CSTypDavkovaniaROZ ||
        newValue === enums.CSTypDavkovania.CSTypDavkovaniaRAD
      ) {
        if (this.isTypZP === false && this.loading === false) {
          this.$confirm(
            this.$vuetify.t(
              "Ste si istý, že nechcete vyplniť štruktúrované dávkovanie?"
            )
          ).then(
            function (isYes) {
              if (!isYes) {
                this.model.recept.typ_davkovania = oldValue
              }
            }.bind(this)
          )
        }
      }
      if (newValue === enums.CSTypDavkovania.CSTypDavkovaniaPEV) {
        this.setDobaLiecby(
          newValue,
          this.model.recept.periodicita,
          this.model.recept.jednotka_davkovania
        )
      }
    },
    /*
    jeXMLSign: function (newValue) {
      localStorage.setItem("jeXMLSign", newValue)
    },
    */
    "model.recept.doba_liecby": function (newValue) {
      console.log("FormRecept, dobaLiecby watcher called", newValue)
      if (newValue === "") {
        this.model.recept.doba_liecby = null
      }
    },
    odporucajuciLekar: function (val) {
      if (Miscella.isSet(val)) {
        this.model.recept.kod_odporucajuci_lekar = val.code
        this.model.recept.id_odporucajuci_lekar = val.id
        this.model.recept.meno_odporucajuceho_lekara = `$(val.name) $(surname)`
        //this.model.recept.kod_nz_odporucajuceho_lekara = val.kod_pzs
      } else {
        this.odporucajuciLekar = val
        this.model.recept.kod_odporucajuci_lekar = ""
        this.model.recept.id_odporucajuci_lekar = 0
        this.model.recept.meno_odporucajuceho_lekara = ""
        //this.model.recept.kod_nz_odporucajuceho_lekara = ""
      }
    },
  },
  methods: {
    new: function (datum, aDiagnoza) {
      this.loading = true
      this.init()
      if (Miscella.isSet(aDiagnoza)) {
        this.model.recept.diagnoza = {
          id: aDiagnoza.diagnoza.diagnoza.id,
          skratka: aDiagnoza.diagnoza.diagnoza.skratka,
          display_name: aDiagnoza.diagnoza.diagnoza.display_name,
        }
        this.diagnozy = [this.model.recept.diagnoza]
      }
      this.fetchData()
      this.model.date = datum
      this.loading = false
      this.$emit("activated")
    },
    edit: function (aThing) {
      this.loading = true
      this.init()
      let thing = Miscella.deepCloneObject(aThing)
      this.formAction = "edit"
      this.model = thing
      if (Miscella.isSet(this.model.recept.liek)) {
        this.liek = this.transformSmartLiek(this.model.recept.liek)
        this.lieky = [this.liek]
      }
      if (
        Miscella.isSet(this.model.recept.zakazane_lieky) &&
        this.model.recept.zakazane_lieky.length > 0
      ) {
        this.onlyLieky = this.model.recept.zakazane_lieky.slice()
      }
      // this.diagnozy = [recept.Diagnoza]
      this.diagnozy = [this.model.recept.diagnoza]
      this.fetchData()
      // this.recept.BLOCiarovyKod = '858800585407873954743'
      if (this.ciarovyKod !== "") {
        JsBarcode("#barcode", this.ciarovyKod, { width: 1, height: 25 })
      }
      Vue.nextTick(
        function () {
          this.loading = false
          this.$emit("activated")
        }.bind(this)
      )
    },
    init: function () {
      this.liek = null
      this.model = this.clearRecept()
      this.lieky = []
      this.generika = []
      this.onlyLieky = []
      this.diagnozy = []
      this.saveSpinning = false
      this.generikaSpinning = false
      this.activeTab = 0
      this.logError = ""
      this.showBLOError = true
      this.setJeNeplatic()
      this.$validator.reset()
      this.errors.clear()
    },
    fetchData: function () {
      if (this.lieky.length === 0) {
        this.fetchLieky()
      }
    },
    fetchLieky: async function () {
      var data = await this.$api.codebookService.listCodebookItems({
        codebook_ids: [
          enums.CodebookIDLiek,
          enums.CodebookIDATC,
          enums.CodebookIDZdravotnickaPomocka,
        ],
        table_request: {
          fulltext_search: Miscella.isEmpty(this.liekySearch)
            ? ""
            : this.liekySearch,
          limit: 100,
        },
      })
      let smartLieky = []
      for (let i = 0; i < data.items.length; i++) {
        let sl = this.transformSmartLiek(data.items[i])
        smartLieky.push(sl)
      }
      this.lieky = smartLieky
    },
    transformSmartLiek: function (liek) {
      //Liek, ATC, ZP
      let typ = "ATC"
      let id = liek.id
      //let title = liek.skratka + " " + liek.display_name
      let title = liek.display_name
      /*
      let subtitle = liek.atributy
        ? liek.atributy[2] + " " + liek.atributy[1]
        : ""
      */
      let subtitle = ""
      let kategorizacia = false
      let kod = liek.skratka
      let isOpiat = false
      let liekATCSkratka = liek.skratka
      if (liek.codebook_id === enums.CodebookIDLiek.toString()) {
        typ = "Liek"
        title = liek.liek.nazov
        subtitle =
          liek.liek.doplnok_nazvu +
          " " +
          liek.liek.atc.skratka +
          " " +
          liek.liek.atc_text
        kod = liek.skratka
        kategorizacia = liek.liek.kategorizacia
        isOpiat = liek.liek.is_opiat
        liekATCSkratka = liek.liek?.atc?.skratka
      }
      if (liek.codebook_id === enums.CodebookIDZdravotnickaPomocka.toString()) {
        typ = "ZP"
        title = liek.zdravotnicka_pomocka.nazov
        subtitle = liek.zdravotnicka_pomocka.kod
        kod = liek.zdravotnicka_pomocka.kod
        liekATCSkratka = ""
      }
      return {
        id: id,
        typ: typ,
        title: title,
        subtitle: subtitle,
        kod: kod,
        kategorizacia: kategorizacia,
        isOpiat: isOpiat,
        liekATCSkratka: liekATCSkratka,
      }
    },
    fetchGenerika: async function () {
      this.generika = []
      if (Miscella.isSet(this.liek)) {
        this.generikaSpinning = true
        try {
          const data = {
            ambulance_id: this.ambulanceId,
            patient_id: this.pacientId,
            liek_kod: this.liek.kod,
          }
          var response = await this.$api.ehealthService.najdiGenerika(data)
          let ret = JSON.parse(response.data)
          if (ret?.Generika?.Liek) {
            this.generika = ret.Generika.Liek
          }
          console.log("this.generika", this.generika)
          this.generikaSpinning = false
        } catch (error) {
          //nezobrazovat chybu
          //this.processEhealthError(error, "Chyba")
          this.generikaSpinning = false
        }
      }
    },
    showLiekovaKarta: async function () {
      let url = ""
      try {
        const data = {
          ambulance_id: this.ambulanceId,
          patient_id: this.pacientId,
        }
        var response = await this.$api.ehealthService.dajLiekovuKartu(data)
        if (!Miscella.isEmpty(response?.file_path)) {
          url = this.$api.storageService.storageTmpUrl(response.file_path)
          this.openWindow(url)
          console.log("liekovaKartaUrl", url)
        } else {
          this.processError("Lieková karta je prázdna.")
        }
      } catch (error) {
        this.processError(error)
      }
    },
    getOnlyLiekOptionLabel: function (item) {
      let ret = ""
      if (Miscella.isSet(item)) {
        if (Miscella.isSet(item.liek)) {
          ret =
            item.liek.kod_sukl +
            "-" +
            item.liek.nazov +
            " " +
            item.liek.doplnok_nazvu
        }
      }
      return ret
    },

    changeLiek: function (val) {
      if (val !== undefined) {
        this.lieky = [val]
        this.liek = val
      }
      this.fetchGenerika()
    },

    changeGenerLiek: async function (kod) {
      if (Miscella.isSet(kod)) {
        let params = {}
        params.codebook_ids = [enums.CodebookIDLiek]
        params.table_request = {
          fulltext_search: kod,
          limit: 1,
        }
        var data = await this.$api.codebookService.listCodebookItems(params)
        if (data.items.length > 0) {
          let liek = this.transformSmartLiek(data.items[0])
          this.lieky = [liek]
          this.liek = liek
          console.log("FormRecept, changeGenerLiek, new smartLiek", this.liek)
          this.fetchGenerika()
        }
      }
    },

    computeDobaUzivania: function () {
      if (
        this.liek !== null &&
        this.liek.velkost_balenia !== "" &&
        this.pocetDenne !== ""
      ) {
        this.dobaUzivania = Math.ceil(
          (parseFloat(this.liek.velkost_balenia) * this.pocet) /
            this.pocet_denne
        )
      } else {
        this.dobaUzivania = ""
      }
    },

    onDatumSelect: function (datum) {
      this.datum = datum
      if (this.uzivanieOd < this.datum) {
        this.uzivanieOd = this.datum
      }
    },

    tlacit: async function () {
      if (this.isOpakRecept) {
        this.$store.commit(
          "setError",
          this.$vuetify.t("Opakovaný recept sa nedá vytlačiť.")
        )
        return
      }
      if (Miscella.normalizeString(this.$store.state.me.user.code) === "") {
        this.$store.commit(
          "setError",
          this.$vuetify.t(
            "Užívateľ nemá vyplnený kód zdravotníckeho pracovníka."
          )
        )
        return
      }
      if (this.ciarovyKod === "") {
        let goOn = await this.$confirm(
          this.$vuetify.t("Naozaj chcete tlačiť recept bez čiarového kódu?")
        )
        if (!goOn) {
          return
        }
      }
      if (this.liek.isOpiat) {
        alert(
          this.$vuetify.t(
            "Liek je OPL a preto skontrolujte či recept tlačíte na špeciálne tlačivo!"
          )
        )
      }
      this.printRecept(this.model)
      /*
      var params = {
        Recept: this.recept,
      }
      this.$api
        .post("/api/patient/" + this.pacientId + "/recept/pdf", params)
        .then(function (data) {
          var ret = "/api/binary/" + data
          var w = window.open(ret)
          w.print()
        })
        .catch(
          function (error) {
            this.processError(error)
          }.bind(this)
        )
        */
    },
    printRecept: function (thing) {
      const data = { thing_id: thing.id }
      this.$api
        .post("/v1/proto.ThingService/PrintThing", data)
        .then((data) =>
          this.openPrintWindow(
            this.$api.storageService.storageTmpUrl(data.file_path)
          )
        )
        .catch((error) => this.processError(error))
        .finally(() => {
          this.$emit("saved", this.model)
        })
    },
    clearRecept: function () {
      var thing = {
        id: "",
        patient_id: this.pacientId,
        ambulance_id: this.ambulanceId,
        date: "",
        parent_id: "",
        formatted_text: "",
        recept: {
          liecivo_id: 0,
          atc_doplnok: "",
          liek_id: 0,
          liek_typ: "",
          mnozstvo_vykazane: 1.0,
          neodkladna_zs: false,
          je_neplatic: 0,
          je_neplatic_time: null,
          je_blo: true, //Miscella.getLocalStorageItem("recept.JeBLO", "true") === "true",
          vynuteny_zapis: false,
          vynuteny_zapis_dovod: "",
          zlozenie_ipl: "",
          pokyny_pri_vyrobe_ipl: "",
          pokyn_k_uzivaniu_ipl: "",
          ipl_cenova_kategoria: "M01000",
          opis_pomocky_na_mieru: "",
          zdovodnenie: "",
          produkt_na_vynimku: false,
          diagnoza_id: 0,
          diagnoza: null,
          typ_davkovania: "PEV",
          denne_davkovanie1: [0, 0, 0, 0],
          denne_davkovanie2_pj: 0,
          denne_davkovanie2_oh: 0,
          jednotka_davkovania: "",
          sposob_podania: "",
          periodicita: "D",
          doba_liecby: null,
          poznamka: "",
          prepis_davkovania: "",
          zakazane_lieky_ids: [],
          zakaz_vydania_nahrady: false,
          perioda_opakovaneho_receptu: 0,
          pocet_opakovani_opakovaneho_receptu: 0,
          platnost_receptu: "",
          odporucajuci_lekar: null,
          odporucajuci_lekar_oupzs: null,
          datum_odporucania: null,
          zastupovany_lekar: null,
          zastupovany_lekar_oupzs: null,
          blo_id: "",
          blo_id_preskripcneho_zaznamu: 0,
          blo_externy_identifikator_riadku2: 0,
          blo_ciarovy_kod: "",
          blo_poznamka_zp: "",
          blo_over_error: "",
          blo_over_date: null,
          zakazane_lieky: [],
        },
      }
      return thing
    },
    showInterakcie: async function () {
      let url = ""
      try {
        const data = {
          ambulance_id: this.ambulanceId,
          patient_id: this.pacientId,
          liek_kod: this.liek.kod,
        }
        var response = await this.$api.ehealthService.overInterakciePacienta(
          data
        )
        if (!Miscella.isEmpty(response?.file_path)) {
          url = this.$api.storageService.storageTmpUrl(response.file_path)
          this.openWindow(url)
          console.log("overInterakciePacienta fileUrl", url)
        } else {
          this.processError("Overenie interakcií pacienta je prázdne.")
        }
      } catch (error) {
        this.processError(error)
      }
    },
    setPlatnostReceptu: function () {
      console.log(
        "setPlatnostReceptu START",
        this.model.recept.perioda_opakovaneho_receptu,
        this.model.recept.pocet_opakovani_opakovaneho_receptu
      )
      let pocet = 0
      this.model.recept.platnost_receptu = null
      if (
        this.model.recept.perioda_opakovaneho_receptu !== "" &&
        this.model.recept.perioda_opakovaneho_receptu > 0
      ) {
        if (
          this.model.recept.pocet_opakovani_opakovaneho_receptu !== "" &&
          this.model.recept.pocet_opakovani_opakovaneho_receptu > 0
        ) {
          pocet =
            this.model.recept.perioda_opakovaneho_receptu *
            this.model.recept.pocet_opakovani_opakovaneho_receptu

          console.log("setPlatnostReceptu, pocet", pocet)
          if (pocet > 365) {
            this.errors.add({
              field: "PlatnostReceptu",
              msg: this.$vuetify.t(
                "Platnosť receptu môže byť maximálne 1 rok."
              ),
            })
          }
          this.model.recept.platnost_receptu = moment()
            .utc()
            .startOf("day")
            .add(pocet, "days")
            .subtract(1, "days")
            .add(23, "hours")
            .add(
              8 + this.model.recept.pocet_opakovani_opakovaneho_receptu,
              "days"
            )
            .toDate()
        }
      }
      console.log("setPlatnostReceptu FINISH")
    },
    setDobaLiecby: function (
      aTypDavkovania,
      aPeriodicita,
      aJednotkaDavkovania
    ) {
      console.log(
        "setDobaLiecby START",
        aTypDavkovania,
        aPeriodicita,
        aJednotkaDavkovania
      )
      if (this.loading) {
        return false
      }
      if (
        aJednotkaDavkovania ===
          enums.CSJednotkaDavkovania.CSJednotkaDavkovaniaTAB ||
        aJednotkaDavkovania ===
          enums.CSJednotkaDavkovania.CSJednotkaDavkovaniaCAP ||
        aJednotkaDavkovania ===
          enums.CSJednotkaDavkovania.CSJednotkaDavkovaniaKAP
      ) {
        console.log("setDobaLiecby kus")
        if (aPeriodicita !== enums.CSPeriodaDavkovania.CSPeriodaDavkovaniaP) {
          if (aTypDavkovania === enums.CSTypDavkovania.CSTypDavkovaniaPEV) {
            if (
              this.model.recept.mnozstvo_vykazane !== null &&
              this.model.recept.mnozstvo_vykazane !== 0
            ) {
              console.log(
                "setDobaLiecby je mnozstvo",
                this.model.recept.mnozstvo_vykazane
              )
              if (this.liek !== null) {
                // pocet jednotiek lieku v baleni
                let ret = this.liek.subtitle
                ret = ret.match(/\d+/)
                let pocetJednotiekVBaleni = Number(ret)
                console.log(
                  "setDobaLiecby pocetJednotiekVBaleni",
                  pocetJednotiekVBaleni
                )
                if (pocetJednotiekVBaleni > 0) {
                  let pocetJednotiekLiekuZaDen = 0
                  if (this.isDenneDavkovanie1Vyplnene) {
                    pocetJednotiekLiekuZaDen =
                      Number(this.model.recept.denne_davkovanie1[0]) +
                      Number(this.model.recept.denne_davkovanie1[1]) +
                      Number(this.model.recept.denne_davkovanie1[2]) +
                      Number(this.model.recept.denne_davkovanie1[3])
                  }
                  if (this.isDenneDavkovanie2Vyplnene) {
                    pocetJednotiekLiekuZaDen =
                      Number(this.model.recept.denne_davkovanie2_pj) +
                      Number(this.model.recept.denne_davkovanie2_oh)
                  }
                  console.log(
                    "setDobaLiecby pocetJednotiekLiekuZaDen",
                    pocetJednotiekLiekuZaDen
                  )
                  if (pocetJednotiekLiekuZaDen !== 0) {
                    let doba = Number(
                      (
                        (this.model.recept.mnozstvo_vykazane *
                          pocetJednotiekVBaleni) /
                        pocetJednotiekLiekuZaDen
                      ).toFixed(0)
                    )
                    if (doba > 0) {
                      this.model.recept.doba_liecby = doba
                      console.log("setDobaLiecby doba", doba)
                    }
                  }
                }
              }
            }
          }
        }
      }
      console.log("setDobaLiecby FINISH")
    },
    save: async function () {
      console.log("FormRecept save")
      this.saveSpinning = true
      this.loading = true // vypnutie automatickych prepoctov pri zmene modelu
      // this.$validator.reset()
      try {
        let isValid = await this.validate()
        if (isValid) {
          await this.saveRecept()
          await this.sendRecept()
          this.$emit("saved", this.model)
          this.isDirty = false
        } else {
          console.log("FORMRECEPT.VUE, validate found errors", this.errors)
        }
      } catch (error) {
        console.log("pri ukladani receptu vznikla chyba", error)
      } finally {
        this.saveSpinning = false
        this.loading = false
        this.activeTab = 0
      }
    },
    validate: async function () {
      this.errors.clear()
      console.log("FormRecept validate", this.errors)
      await this.$validator.validateAll()
      // miesto na dalsie custom validacie
      console.log("FormRecept validate 2", this.errors)
      /*
      if (!this.model.recept.je_blo) {
        //this.jeXMLSign = false
      }
      */
      if (this.model.recept.je_blo) {
        let ambulance = this.$store.state.me.ambulance
        if (Miscella.isBlank(ambulance.kod_ambulancie)) {
          this.errors.add({
            field: "",
            msg: this.$vuetify.t(
              "Nie je vyplnený kód ambulancie. Nie je možné poslať recept do BLO."
            ),
          })
        }
      }
      console.log("FormRecept validate 3", this.errors)
      let pacient = this.$store.state.pacient
      let poistovna =
        Miscella.isSet(pacient) && Miscella.isSet(pacient.zdravotna_poistovna)
          ? pacient.zdravotna_poistovna
          : ""
      console.log("poistovna", poistovna)
      if (this.model.recept.je_blo) {
        if (poistovna === "") {
          this.errors.add({
            field: "smartLiek",
            msg: this.$vuetify.t(
              "Pacient nemá vyplnenú zdravotnú poisťovňu. Nie je možné poslať recept do BLO."
            ),
          })
        }
      }
      // povinny liek, ak nie je IPL
      if (this.isIPL) {
        if (this.model.recept.zlozenie_ipl === "") {
          this.errors.add({
            field: "zlozenieIPL",
            msg: this.$vuetify.t("Zloženie IPL musí byť vyplnené."),
          })
        }
        if (this.model.recept.pokyny_pri_vyrobe_ipl === "") {
          this.errors.add({
            field: "pokynyPriVyrobeIPL",
            msg: this.$vuetify.t("Pokyny pri výrobe IPL musia byť vyplnené."),
          })
        }
        if (this.model.recept.pokyn_k_uzivaniu_ipl === "") {
          this.errors.add({
            field: "pokynKUzivaniuIPL",
            msg: this.$vuetify.t("Pokyn k užívaniu IPL musí byť vyplnený."),
          })
        }
      } else {
        if (this.liek === null) {
          this.errors.add({
            field: "smartLiek",
            msg: this.$vuetify.t(
              "Musí byť vybratý liek alebo vyplnené položky k IPL."
            ),
          })
        }
      }
      console.log("FormRecept validate 4", this.errors)
      // if ATC tak je povinny ATC doplnok
      if (this.isTypATC) {
        if (this.model.recept.atc_doplnok === "") {
          this.errors.add({
            field: "atcDoplnok",
            msg: this.$vuetify.t(
              "Ak je predpisované ATC, ATC doplnok je povinná položka."
            ),
          })
        }
      }
      // if trvale postihnutie tak je povinne zdovodnenie
      if (
        this.model.recept.trvale_postihnutie &&
        this.model.recept.zdovodnenie === ""
      ) {
        this.errors.add({
          field: "zdovodnenie",
          msg: this.$vuetify.t(
            "Ak je označené Trvalé postihnutie, Zdôvodnenie je povinná položka."
          ),
        })
      }
      if (this.isJeBLOVisible && this.model.recept.je_blo) {
        if (this.isEhealthCardConnected === false) {
          if (!this.ehealthDemo) {
            this.errors.add({
              field: "smartLiek",
              msg: this.$vuetify.t(
                "Nie je možné podpísať recept. Skontrolujte kartu zdr.pracovníka."
                ),
              })
            }
          }
      }
      console.log("FormRecept validate 5", this.errors)
      // davkovanie, tab c.2
      if (
        this.model.recept.typ_davkovania ===
        enums.CSTypDavkovania.CSTypDavkovaniaPEV
      ) {
        let pocet =
          this.model.recept.denne_davkovanie1[0] +
          this.model.recept.denne_davkovanie1[1] +
          this.model.recept.denne_davkovanie1[2] +
          this.model.recept.denne_davkovanie1[3]
        pocet =
          pocet +
          this.model.recept.denne_davkovanie2_pj +
          this.model.recept.denne_davkovanie2_oh
        if (pocet === 0) {
          this.errors.add({
            field: "DenneDavkovanie1",
            msg: this.$vuetify.t(
              "Pri pevne stanovenom dávkovaní musí byť vyplnené aspoň jedno z polí Denné dávkovanie alebo Počet jednotiek a Opakovanie v hodinách."
            ),
          })
        }
      }
      if (this.model.recept.doba_liecby === 0) {
        this.errors.add({
          field: "DobaLiecby",
          msg: this.$vuetify.t("Doba liečby nemôže byť 0."),
        })
      }
      if (
        this.model.recept.typ_davkovania ===
        enums.CSTypDavkovania.CSTypDavkovaniaVAR
      ) {
        if (this.model.recept.poznamka.length < 3) {
          this.errors.add({
            field: "Poznamka",
            msg: this.$vuetify.t(
              "Pri variabilnom dávkovaní musí Voľný text obsahovať aspoň 3 znaky."
            ),
          })
        }
      }
      console.log("FormRecept validate 6", this.errors)
      // tab c.3
      if (
        this.model.recept.perioda_opakovaneho_receptu !== "" &&
        this.model.recept.perioda_opakovaneho_receptu !== 0
      ) {
        if (
          this.model.recept.pocet_opakovani_opakovaneho_receptu === "" ||
          this.model.recept.pocet_opakovani_opakovaneho_receptu === 0
        ) {
          this.errors.add({
            field: "PocetOpakovaniOpakovanehoReceptu",
            msg: this.$vuetify.t(
              "Ak je Perióda opak.receptu vyplnená, musí byť aj Počet opakovaní."
            ),
          })
        }
      }
      if (
        this.model.recept.pocet_opakovani_opakovaneho_receptu !== "" &&
        this.model.recept.pocet_opakovani_opakovaneho_receptu !== 0
      ) {
        if (
          this.model.recept.perioda_opakovaneho_receptu === "" ||
          this.model.recept.perioda_opakovaneho_receptu === 0
        ) {
          this.errors.add({
            field: "PeriodaOpakovanehoReceptu",
            msg: this.$vuetify.t(
              "Ak je Počet opakovaní opak.receptu vyplnený, musí byť aj Perióda."
            ),
          })
        }
      }
      this.setPlatnostReceptu()
      if (this.isOpakRecept) {
        /*
        if (this.isJeBLOVisible && !this.model.recept.je_blo) {
          this.errors.add({
            field: "smartLiek",
            msg: this.$vuetify.t(
              "Pri opakovanom recepte musí byť recept posielaný do BLO."
            ),
          })
        }
        */
        /*
        if (!this.model.recept.je_blo) {
          this.errors.add({
            field: "jeXMLSign",
            msg: this.$vuetify.t(
              "Pri opakovanom recepte musí byť recept podpísaný."
            ),
          })
        }
        */
        let atcSkratka = this.liek.liekATCSkratka
        console.log("validate start", this.liek)
        if (atcSkratka != "") {
          //je to OPL podla pravidiel pre opak.recept?
          console.log("validate start", atcSkratka)
          let found = enums.ATCReRxOPL.find((s) => s === atcSkratka)
          if (found) {
            this.errors.add({
              field: "smartLiek",
              msg: this.$vuetify.t(
                "Pre danú ATC skupinu /OPL/ nie je možné predpísať opakovaný recept."
              ),
            })
          }
          console.log("validate found OPL", found)
          //je to ATB podla pravidiel pre opak.recept?
          //found = enums.ATCReRxATB.find(s => atcSkratka.startsWith(s))
          found = enums.ATCReRxATB.find(function (s) {
            console.log("myFind", s, atcSkratka)
            return atcSkratka.startsWith(s)
          })
          console.log("validate found ATB", found)
          if (found) {
            this.errors.add({
              field: "smartLiek",
              msg: this.$vuetify.t(
                "Pre danú ATC skupinu /ATB/ nie je možné predpísať opakovaný recept."
              ),
            })
          }
        }
      }
      console.log("FormRecept validate 7", this.errors)
      // tab c.4
      if (Miscella.isSet(this.model.recept.odporucajuci_lekar)) {
        if (this.model.recept.datum_odporucania === null) {
          this.errors.add({
            field: "DatumOdporucania",
            msg: this.$vuetify.t(
              "Ak je vyplnený odporúčajúci lekár, tak je dátum odporúčania povinný."
            ),
          })
        }
        if (!Miscella.isSet(this.model.recept.odporucajuci_lekar_oupzs)) {
          this.errors.add({
            field: "odporucajuci_lekar_oupzs",
            msg: this.$vuetify.t("Odporúčajúci lekár nemá vyplnený OÚ PZS."),
          })
        }
      }
      if (Miscella.isSet(this.model.recept.zastupovany_lekar)) {
        if (!Miscella.isSet(this.model.recept.zastupovany_lekar_oupzs)) {
          this.errors.add({
            field: "zastupovany_lekar_oupzs",
            msg: this.$vuetify.t("Zastupovaný lekár nemá vyplnený OÚ PZS."),
          })
        }
      }
      console.log("FormRecept validate FINISH", this.errors)
      return !this.errors.any()
    },

    saveRecept: function () {
      /*
      if (this.diagnoza) {
        this.model.recept.diagnoza_id = this.diagnoza.id
      }
      */
      if (Miscella.isSet(this.liek)) {
        this.model.recept.liek_id = this.liek.id
        this.model.recept.liek_typ = this.liek.typ
      }
      this.model.recept.zakazane_lieky_ids = []
      let newArray = []
      if (Miscella.isSet(this.model.recept.zakazane_lieky)) {
        if (this.model.recept.zakazane_lieky.length > 0) {
          for (let i = 0; i < this.model.recept.zakazane_lieky.length; i++) {
            newArray.push(this.model.recept.zakazane_lieky[i].id)
          }
        }
      }
      this.model.recept.zakazane_lieky_ids = newArray
      var params = { thing: Miscella.cloneObject(this.model) }
      console.log("saveRecept", params)
      return this.$api.thingService
        .saveThing(params)
        .then((result) => {
          this.notifySuccess("Recept bol úspešne uložený")
          this.model = result.thing
        })
        .catch((error) => {
          console.error(error)
          this.processError(error, "Pri ukladaní receptu vznikla chyba")
        })
    },
    sendRecept: function () {
      if (this.model.recept.je_blo && this.isEhealthCardConnected) {
        let params = {
          ambulance_id: this.ambulanceId,
          patient_id: this.pacientId,
          recept_id: this.model.id,
        }
        return this.$api.ehealthService
          .ulozPreskripcnyZaznam(params)
          .then((result) => {
            let data = JSON.parse(result.data)
            console.log("sendRecept, data2", data)
            if (Miscella.isBlank(data.CiarovyKod)) {
              throw "error"
            }
            this.notifySuccess("Recept bol úspešne odoslaný do poisťovne")
            this.$store.commit("setThing", result.thing)
          })
          .catch((error) => {
            console.error(error)
            //this.logError = error
            this.showBLOError = true
            this.processError("Pri odosielaní receptu vznikla chyba")
          })
      }
    },
    sendReceptOFFLINE: async function () {
      try {
        if (this.model.recept.je_blo) {
          var params = {
            ambulance_id: this.ambulanceId,
            patient_id: this.pacientId,
            object_id: this.model.id,
            object_type: "ULOZ_PRESKRIPCNY_ZAZNAM",
            version_status: enums.EhrVersionStatus.Final,
          }
          console.log("FormRecept.vue, recept sending", params)
          var result = await this.$api.ehealthService.sendToEhealth(params)
          console.log("FormRecept.vue, recept send", result)
          this.notifySuccess("Recept sa úspešne začal odosielať do poisťovne")
        }
      } catch (e) {
        console.log("ERROR saveToEhealth: ", e)
        this.processError(e, "Pri odosielaní receptu vznikla chyba")
      }
    },
    stornoRecept: async function () {
      console.log("MODALFORMRECEPT.VUE, stornoRecept START")
      let doStorno = await this.$confirm(
        this.$vuetify.t("Naozaj chcete stornovať recept?")
      )
      if (!doStorno) {
        return
      }
      if (this.model.recept.je_blo && this.isEhealthCardConnected) {
        let params = {
          ambulance_id: this.ambulanceId,
          patient_id: this.pacientId,
          recept_id: this.model.id,
        }
        return this.$api.ehealthService
          .stornujPreskripcnyZaznam(params)
          .then((result) => {
            let data = JSON.parse(result.data)
            console.log("stornoRecept, data", data)
            this.model.recept.blo_messages = []
            if (data.Spravy?.Sprava !== undefined) {
              for (let i = 0; i < data.Spravy.Sprava.length; i++) {
                const s = data.Spravy.Sprava[i]
                let m = `Správa [Kód: ${s.Kod} Závažnosť:${s.Zavaznost} Text:${s.Text}]`
                this.model.recept.blo_messages.push(m)
              }
            }
            if (Miscella.isBlank(data.Stornovany)) {
              throw "error"
            }
            this.notifySuccess(
              "Stornovanie receptu bolo úspešne odoslané do poisťovne"
            )
          })
          .catch((error) => {
            console.error(error)
            this.showBLOError = true
            this.processError(
              "Pri odosielaní stornovania receptu vznikla chyba"
            )
          })
      }
    },
    stornoReceptOFFLINE: async function () {
      try {
        if (this.model.recept.je_blo) {
          var params = {
            ambulance_id: this.ambulanceId,
            patient_id: this.pacientId,
            object_id: this.model.id,
            object_type: "STORNUJ_PRESKRIPCNY_ZAZNAM",
            version_status: enums.EhrVersionStatus.Final,
          }
          console.log("FormRecept.vue, storno recept sending", params)
          var result = await this.$api.ehealthService.sendToEhealth(params)
          console.log("FormRecept.vue, storno recept send", result)
          this.notifySuccess(
            "Stornovanie receptu sa úspešne začalo odosielať do poisťovne"
          )
        }
      } catch (e) {
        console.log("ERROR saveToEhealth: ", e)
        this.showBLOError = true
        this.processError(e, "Pri odosielaní stornovania receptu vznikla chyba")
      }
    },
    zneplatnit: async function () {
      console.log("MODALFORMRECEPT.VUE, zneplatnit START")
      let doStorno = await this.$confirm(
        this.$vuetify.t("Naozaj chcete zneplatniť opakovaný recept?")
      )
      if (!doStorno) {
        return
      }
      if (this.model.recept.je_blo && this.isEhealthCardConnected) {
        let params = {
          ambulance_id: this.ambulanceId,
          patient_id: this.pacientId,
          recept_id: this.model.id,
        }
        return this.$api.ehealthService
          .zneplatniPreskripcnyZaznam(params)
          .then((result) => {
            let data = JSON.parse(result.data)
            console.log("zneplatnit, data", data)
            this.model.recept.blo_messages = []
            if (data.Spravy?.Sprava !== undefined) {
              for (let i = 0; i < data.Spravy.Sprava.length; i++) {
                const s = data.Spravy.Sprava[i]
                let m = `Správa [Kód: ${s.Kod} Závažnosť:${s.Zavaznost} Text:${s.Text}]`
                this.model.recept.blo_messages.push(m)
              }
            }
            this.notifySuccess(
              "Zneplatnenie receptu bolo úspešne odoslané do poisťovne"
            )
          })
          .catch((error) => {
            console.error(error)
            this.showBLOError = true
            this.processError(
              "Pri odosielaní zneplatnenia receptu vznikla chyba"
            )
          })
      }
    },
    zneplatnitReceptOFFLINE: async function () {
      try {
        if (this.model.recept.je_blo) {
          var params = {
            ambulance_id: this.ambulanceId,
            patient_id: this.pacientId,
            object_id: this.model.id,
            object_type: "ZNEPLATNI_PRESKRIPCNY_ZAZNAM",
            version_status: enums.EhrVersionStatus.Final,
          }
          console.log("FormRecept.vue, zneplatnenie recept sending", params)
          var result = await this.$api.ehealthService.sendToEhealth(params)
          console.log("FormRecept.vue, zneplatnenie recept send", result)
          this.notifySuccess(
            "Zneplatnenie receptu sa úspešne začalo odosielať do poisťovne"
          )
        }
      } catch (e) {
        console.log("ERROR saveToEhealth: ", e)
        this.showBLOError = true
        this.processError(
          e,
          "Pri odosielaní zneplatnenia receptu vznikla chyba"
        )
      }
    },

    changeJeBLO: function () {
      console.log(
        "MODALFORMRECEPT.VUE, recept.JeBLO:" + this.model.recept.je_blo
      )
      localStorage.setItem("recept.JeBLO", this.model.recept.je_blo)
    },
    getTabContainsError: function (aIndex) {
      let ret = false
      let counter = 0
      if (this.errors.any()) {
        let errArray = this.errors.items
        for (let i = 0; i < errArray.length; i++) {
          const err = errArray[i]
          if (this.getTabIndexOfError(err) === aIndex) {
            counter++
          }
        }
      }
      ret = counter > 0
      return ret
    },
    getTabIndexOfError: function (aErr) {
      let ret = -1
      const errField = aErr.field
      this.mapErrorsInTab.forEach(function (tabErrors, tabIndex) {
        if (tabErrors.indexOf(errField) > -1) {
          ret = tabIndex
        }
      })
      return ret
    },
    setJeNeplatic: async function () {
      //0-neviem, 1-neplatic 2-platic
      console.log("setJeNeplatic")
      const data = {
        ambulance_id: this.ambulanceId,
        patient_id: this.pacientId,
      }
      var response = await this.$api.ehealthService.overPoistenca(data)
      let dataJson = JSON.parse(response.data)
      let jeNeplatic = 0
      if (dataJson.JeNeplatic === true) {
        jeNeplatic = 1
      }
      if (dataJson.JeNeplatic === false) {
        jeNeplatic = 2
      }
      this.model.recept.je_neplatic = jeNeplatic
      console.log("setJeNeplatic", dataJson.JeNeplatic, jeNeplatic)
    },
  },
  mounted: function () {
    console.log("FormRecept, mounted START")
    console.log("FormRecept, mounted END")
  },
  created: async function () {
    console.log("FormRecept, created START")
    // fetch ciselniky
    try {
      let data = await this.$api.post("v1/proto.CodebookService/ListCS", null)
      this.CS.CSJednotkaDavkovania = data.cs_jednotka_davkovania
      this.CS.CSPeriodaDavkovania = data.cs_perioda_davkovania
      this.CS.CSSposobPodania = data.cs_sposob_podania
      this.CS.CSTypDavkovania = data.cs_typ_davkovania
      this.CS.MapZlfJd = data.map_zlf_jd
    } catch (err) {
      this.processError(err)
    }
    this.init()
    console.log("FormRecept, created END")
  },
}
</script>
<style>
div.isError a {
  color: rgb(255, 107, 107);
}
.bold {
  font-weight: bold;
}
</style>
