<template>
  <v-container fluid>
    <ApiTable
      ref="tableKapitacie"
      :headers="headers"
      :url="this.kapitacieUrl"
      :hasFilter="false"
      :title="$vuetify.t('Kapitácie')"
    >
      <template slot="toolbarItems">
        <v-tooltip top>
          <v-btn icon slot="activator" @click="addKapitacia">
            <v-icon>add</v-icon>
          </v-btn>
          <span>{{ $vuetify.t("Pridať") }}</span>
        </v-tooltip>
      </template>
      <template slot="items" slot-scope="props">
        <tr @click="editKapitacia(props.item)">
          <td class="text-md-left">
            {{ props.item.DatumDohody | formatDatum }}
          </td>
          <td class="text-md-left">
            {{ props.item.DatumDohodyDo | formatDatum }}
          </td>
          <td class="text-md-left">{{ props.item.Ambulancia.Name }}</td>
          <td class="text-md-left">
            {{ props.item.Prirastok ? "Prírastok" : "Úbytok" }}
          </td>
          <td class="text-md-right">
            <v-tooltip left>
              <v-icon slot="activator" @click.stop="deleteKapitacia(props.item)"
                >delete</v-icon
              >
              <span>{{ $vuetify.t("Odstrániť") }}</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </ApiTable>
    <ApiTable
      ref="tablePoistenia"
      :headers="headersPoistenia"
      :url="this.poisteniaUrl"
      :hasFilter="false"
      :title="$vuetify.t('Poistenia')"
    >
      <template slot="toolbarItems">
        <v-tooltip top>
          <v-btn icon slot="activator" @click="addPoistenie">
            <v-icon>add</v-icon>
          </v-btn>
          <span>{{ $vuetify.t("Pridať") }}</span>
        </v-tooltip>
      </template>
      <template slot="items" slot-scope="props">
        <tr @click="editPoistenie(props.item)">
          <td class="text-md-left">
            {{ props.item.DatumOd | formatDatum }}
          </td>
          <td class="text-md-left">
            {{
              $vuetify.t("$vuetify.enum.poistovna." + props.item.PoistovnaSR)
            }}

            {{
              $vuetify.t(
                "$vuetify.enum.charakterPoistenca." +
                  props.item.CharakterPoistenca
              )
            }}
          </td>
          <td class="text-md-right">
            <v-tooltip left>
              <v-icon slot="activator" @click.stop="deletePoistenie(props.item)"
                >delete</v-icon
              >
              <span>{{ $vuetify.t("Odstrániť") }}</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </ApiTable>
    <ApiTable
      ref="tablePoisteniaZahranicne"
      :headers="headersPoisteniaZahranicne"
      :url="this.poisteniaZahranicneUrl"
      :hasFilter="false"
      :title="$vuetify.t('Zahraničné poistenia')"
    >
      <template slot="toolbarItems">
        <v-tooltip top>
          <v-btn icon slot="activator" @click="addPoistenieZahranicne">
            <v-icon>add</v-icon>
          </v-btn>
          <span>{{ $vuetify.t("Pridať") }}</span>
        </v-tooltip>
      </template>
      <template slot="items" slot-scope="props">
        <tr @click="editPoistenieZahranicne(props.item)">
          <td class="text-md-left">
            {{ props.item.IdentCislo }}
          </td>
          <td class="text-md-left">
            {{ props.item.DatumOd | formatDatum }}
          </td>
          <td class="text-md-left">
            {{ props.item.DatumDo | formatDatum }}
          </td>
          <td class="text-md-right">
            <v-tooltip left>
              <v-icon
                slot="activator"
                @click.stop="deletePoistenieZahranicne(props.item)"
                >delete</v-icon
              >
              <span>{{ $vuetify.t("Odstrániť") }}</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </ApiTable>
    <BaseDialog
      ref="dialogPacientKapitacia"
      :dialogTitle="this.dialogTitleKapitacia"
      @save="$refs.formPacientKapitacia.saveForm()"
    >
      <FormPacientKapitacia
        ref="formPacientKapitacia"
        :patientId="id"
        @saved="this.savedKapitacia"
      ></FormPacientKapitacia>
    </BaseDialog>
    <BaseDialog
      ref="dialogPacientPoistenie"
      :dialogTitle="this.dialogTitlePoistenie"
      @save="$refs.formPacientPoistenie.saveForm()"
    >
      <FormPacientPoistenie
        ref="formPacientPoistenie"
        :patientId="id"
        @saved="this.savedPoistenie"
      ></FormPacientPoistenie>
    </BaseDialog>
    <BaseDialog
      ref="dialogPacientPoistenieZahranicne"
      :dialogTitle="this.dialogTitlePoistenieZahranicne"
      @save="$refs.formPacientPoistenieZahranicne.saveForm()"
    >
      <FormPacientPoistenieZahranicne
        ref="formPacientPoistenieZahranicne"
        :patientId="id"
        @saved="this.savedPoistenieZahranicne"
      ></FormPacientPoistenieZahranicne>
    </BaseDialog>
  </v-container>
</template>

<script>
import ApiTable from "./../../components/ApiTable.vue"
import BaseDialog from "./../../components/BaseDialog.vue"
import FormPacientKapitacia from "./../../components/forms/FormPacientKapitacia.vue"
import FormPacientPoistenie from "./../../components/forms/FormPacientPoistenie.vue"
import FormPacientPoistenieZahranicne from "./../../components/forms/FormPacientPoistenieZahranicne.vue"

export default {
  props: ["id"],
  components: {
    ApiTable,
    BaseDialog,
    FormPacientKapitacia,
    FormPacientPoistenie,
    FormPacientPoistenieZahranicne,
  },
  data: function () {
    return {
      loading: true,
      dialogTitleKapitacia: "",
      dialogTitlePoistenie: "",
      dialogTitlePoistenieZahranicne: "",
      kapitacie: [],
      poistenia: [],
      poisteniaZahr: [],
      headers: [
        {
          text: "Dátum dohody od",
          value: "datumDohodyOd",
          sortable: false,
        },
        {
          text: "Dátum dohody do",
          value: "datumDohodyDo",
          sortable: false,
        },
        {
          text: "Ambulancia",
          value: "ambulancia",
          sortable: false,
        },
        {
          text: "Pohyb",
          value: "pohyb",
          sortable: false,
        },
        {
          text: "",
          sortable: false,
        },
      ],
      headersPoistenia: [
        {
          text: "Dátum od",
          value: "datumOd",
          sortable: false,
        },
        {
          text: "Poisťovňa",
          value: "poistovna",
          sortable: false,
        },
        {
          text: "",
          sortable: false,
        },
      ],
      headersPoisteniaZahranicne: [
        {
          text: "Identifikačné číslo",
          value: "identCis",
          sortable: false,
        },
        {
          text: "Dátum od",
          value: "datumOd",
          sortable: false,
        },
        {
          text: "Dátum do",
          value: "datumDo",
          sortable: false,
        },
        {
          text: "",
          sortable: false,
        },
      ],
    }
  },
  computed: {
    patient: {
      get() {
        return this.$store.state.patient
      },
      set(value) {
        this.$store.commit("setPatient", value)
      },
    },
    kapitacieUrl() {
      return "/api/patient/" + this.id + "/kapitacie"
    },
    poisteniaUrl() {
      return "/api/patient/" + this.id + "/poistenia"
    },
    poisteniaZahranicneUrl() {
      return "/api/patient/" + this.id + "/poisteniaZahr"
    },
  },
  methods: {
    addKapitacia: function () {
      this.dialogTitleKapitacia = "Nová kapitácia"
      this.$refs.formPacientKapitacia.newKapitacia()
      this.$refs.dialogPacientKapitacia.open()
    },
    editKapitacia: function (aKapitacia) {
      this.dialogTitleKapitacia = "Úprava kapitácie"
      this.$refs.formPacientKapitacia.editKapitacia(aKapitacia)
      this.$refs.dialogPacientKapitacia.open()
    },
    savedKapitacia: function () {
      this.$store.commit("setReloadPacient", true)
      this.$refs.tableKapitacie.reload()
      this.$refs.dialogPacientKapitacia.close()
    },
    deleteKapitacia: async function (kapitacia) {
      try {
        await this.$api.post(
          "/api/patient/" + this.id + "/kapitacia/" + kapitacia.ID + "/delete",
          null,
          this.deleteKapitaciaOK,
          this.deleteKapitaciaError
        )
        this.deleteKapitaciaOK()
      } catch (e) {
        this.deleteKapitaciaError(e)
      }
    },
    deleteKapitaciaOK: function () {
      this.$store.commit("setReloadPacient", true)
      this.$store.commit(
        "setSuccess",
        this.$vuetify.t("Kapitácia bola úspešne vymazaná")
      )
      this.$refs.tableKapitacie.reload()
    },
    deleteKapitaciaError: function () {
      this.$store.commit(
        "setError",
        this.$vuetify.t("Pri mazaní kapitácie vznikla chyba.")
      )
    },
    addPoistenie: function () {
      this.dialogTitlePoistenie = "Nové poistenie"
      this.$refs.formPacientPoistenie.newPoistenie()
      this.$refs.dialogPacientPoistenie.open()
    },
    editPoistenie: function (poistenie) {
      this.dialogTitlePoistenie = "Úprava poistenia"
      this.$refs.formPacientPoistenie.editPoistenie(poistenie)
      this.$refs.dialogPacientPoistenie.open()
    },
    savedPoistenie: function () {
      this.$store.commit("setReloadPacient", true)
      this.$refs.tablePoistenia.reload()
      this.$refs.dialogPacientPoistenie.close()
    },
    deletePoistenie: async function (poistenie) {
      try {
        await this.$api.post(
          "/api/patient/" + this.id + "/poistenie/" + poistenie.ID + "/delete",
          null
        )
        this.deletePoistenieOK()
      } catch (e) {
        this.deletePoistenieError()
      }
    },
    deletePoistenieOK: function () {
      this.$store.commit("setReloadPacient", true)
      this.$refs.tablePoistenia.reload()
      this.$store.commit(
        "setSuccess",
        this.$vuetify.t("Poistenie bolo úspešne vymazané")
      )
    },
    deletePoistenieError: function () {
      this.$store.commit(
        "setError",
        this.$vuetify.t("Pri mazaní poistenia vznikla chyba.")
      )
    },
    addPoistenieZahranicne: function () {
      this.dialogTitlePoistenieZahranicne = "Nové zahraničné poistenie"
      this.$refs.formPacientPoistenieZahranicne.newPoistenie()
      this.$refs.dialogPacientPoistenieZahranicne.open()
    },
    editPoistenieZahranicne: function (poistenie) {
      this.dialogTitlePoistenieZahranicne = "Úprava zahraničného poistenia"
      this.$refs.formPacientPoistenieZahranicne.editPoistenie(poistenie)
      this.$refs.dialogPacientPoistenieZahranicne.open()
    },
    savedPoistenieZahranicne: function () {
      this.$store.commit("setReloadPacient", true)
      this.$refs.tablePoisteniaZahranicne.reload()
      this.$refs.dialogPacientPoistenieZahranicne.close()
    },
    deletePoistenieZahranicne: async function (poistenie) {
      try {
        await this.$api.post(
          "/api/patient/" +
            this.id +
            "/poistenieZahr/" +
            poistenie.ID +
            "/delete",
          null
        )
        this.deletePoistenieZahranicneOK()
      } catch (e) {
        this.deletePoistenieZahranicneError()
      }
    },
    deletePoistenieZahranicneOK: function () {
      this.$store.commit("setReloadPacient", true)
      this.$refs.tablePoisteniaZahranicne.reload()
      this.$store.commit(
        "setSuccess",
        this.$vuetify.t("Zahraničné poistenie bolo úspešne vymazané")
      )
    },
    deletePoistenieZahranicneError: function () {
      this.$store.commit(
        "setError",
        this.$vuetify.t("Pri mazaní zahraničného poistenia vznikla chyba.")
      )
    },
    fetchData: function () {
      Promise.all([
        this.$api.get(
          "/api/patient/" + this.id + "/kapitacie",
          null,
          this.setKapitacie
        ),
        this.$api.get(
          "/api/patient/" + this.id + "/poistenia",
          null,
          this.setPoistenia
        ),
        this.$api.get(
          "/api/patient/" + this.id + "/poisteniaZahr",
          null,
          this.setPoisteniaZahr
        ),
      ])
      this.$store.commit("setReloadPacient", true)
    },
    setKapitacie: function (data) {
      this.kapitacie = data
    },
    setPoistenia: function (data) {
      this.poistenia = data
    },
    setPoisteniaZahr: function (data) {
      this.poisteniaZahr = data
    },
  },
  mounted: function () {
    this.fetchData()
  },
}
</script>
