export default class CodebookService {
  constructor(api) {
    this.api = api
  }

  get listCodebookItemsUrl() {
    return "/v1/proto.CodebookService/ListCodebookItems"
  }

  listCodebookItems(params, options) {
    return this.api.post(this.listCodebookItemsUrl, params, options)
  }

  listCodebooks(params, options) {
    return this.api.post(
      "/v1/proto.CodebookService/ListCodebooks",
      params,
      options
    )
  }
}
