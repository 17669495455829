import BaseApiService from "./baseApiService.js"

export default class UserService extends BaseApiService {
  constructor(api) {
    super(
      api,
      "/v1/proto.UserService/Me",
      "/v1/proto.UserService/ListUsers",
      "/v1/proto.UserService/UpdateUser"
    )
  }
}
