<template>
  <div>
    <v-container fluid px-0 mx-0 pt-0 mt-0 printContainer>
      <v-layout row wrap>
        <v-flex xs12 pa-1>
          <v-layout row wrap style="border: 2px solid black">
            <v-flex xs12 text-xs-center font-weight-bold pa-3>
              Odpis potvrdenia o dočasnej pracovnej neschopnosti<br />vystavenej
              elektronicky
            </v-flex>
            <v-flex
              xs12
              text-xs-center
              px-5
              py-2
              style="font-size: x-small; border-bottom: 2px solid black"
            >
              §12a ods. 3 zákona č. 576/2004 Z. z. o zdravotnej starostlivosti,
              službách súvisiacich s poskytovaním zdravotnej starostlivosti a o
              zmene a doplnení niektorých zákonov v znení neskorších predpisov.
              Článok 27 Nariadenia Európskeho parlamentu a Rady (ES) č.
              987/2009, ktorým sa stanovuje postup vykonávania nariadenia (ES)
              č. 883/2004 o koordinácii systémov sociálneho zabezpečenia
            </v-flex>
            <v-flex xs6 font-weight-bold borderedBR> Meno </v-flex>
            <v-flex xs6 borderedB>
              {{ pacient.name }}
            </v-flex>
            <v-flex xs6 font-weight-bold borderedBR> Priezvisko </v-flex>
            <v-flex xs6 borderedB>
              {{ pacient.surname }}
            </v-flex>
            <v-flex xs6 font-weight-bold borderedBR> Rodné číslo </v-flex>
            <v-flex xs6 borderedB>
              {{ pacient.person_unique_id }}
            </v-flex>
            <v-flex xs6 font-weight-bold borderedBR> Dátum narodenia </v-flex>
            <v-flex xs6 borderedB>
              {{ pacient.birthdate | formatDatum }}
            </v-flex>
            <v-flex xs6 font-weight-bold borderedBR>
              Adresa pobytu počas PN - vstupná
            </v-flex>
            <v-flex xs6 borderedB>
              {{ pacientPNAddress }}
            </v-flex>
            <v-flex xs12 borderedB> &nbsp;<br /><br /><br /> </v-flex>
            <v-flex xs6 font-weight-bold borderedBR>
              Dôvod práceneschopnosti
            </v-flex>
            <v-flex xs6 borderedB>
              {{ model.dovod_pn?.dovod?.display_name }}
            </v-flex>
            <v-flex xs12 borderedB> &nbsp;<br /><br /><br /> </v-flex>
            <v-flex xs6 font-weight-bold borderedBR>
              Vychádzky povolené od
            </v-flex>
            <v-flex xs6 borderedB>
              <span>
                {{ getDatumOdVychadzky }}
              </span>
            </v-flex>
            <v-flex xs12 text-xs-center font-weight-bold borderedB>
              Časové vymedzenie povolených vychádzok
            </v-flex>
            <template v-for="(vychadzka, index) in model.vychadzky">
              <v-flex xs12 :key="index">
                <v-layout row wrap>
                  <v-flex xs6 font-weight-bold borderedBR> od - do </v-flex>
                  <v-flex xs3 borderedBR>
                    {{ vychadzka.cas_od }}
                  </v-flex>
                  <v-flex xs3 borderedB>
                    {{ vychadzka.cas_do }}
                  </v-flex>
                </v-layout>
              </v-flex>
            </template>
            <v-flex xs12 borderedB> &nbsp;<br /><br /> </v-flex>
            <v-flex xs6 font-weight-bold borderedBR>
              Neschopný práce od
            </v-flex>
            <v-flex xs6 borderedB>
              {{ model.neschopny_prace_od | formatDatum }}
            </v-flex>
            <v-flex xs12 borderedB> &nbsp;<br /><br /> </v-flex>
            <v-flex xs6 font-weight-bold borderedBR>
              Dátum konca práceneschopnosti
            </v-flex>
            <v-flex xs6 borderedB>
              {{ model.schopny_prace_od | formatDatum }}
            </v-flex>
            <v-flex xs12 borderedB> &nbsp;<br /><br /><br /> </v-flex>
            <v-flex xs6 font-weight-bold borderedBR>
              Dátum vystavenia odpisu
            </v-flex>
            <v-flex xs6 borderedB>
              {{ teraz | formatDatum }}
            </v-flex>
            <v-flex xs6 font-weight-bold borderedBR>
              PN vystavil ošetrujúci lekár
            </v-flex>
            <v-flex xs6 borderedB>
              {{ user.name }}
            </v-flex>
            <v-flex xs6 font-weight-bold borderedBR py-5>
              Pečiatka,<br />podpis oprávneného lekára
            </v-flex>
            <v-flex xs6 borderedB> &nbsp;<br /><br /> </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <pre></pre>
    </v-container>
  </div>
</template>

<script>
import * as Miscella from "../../miscella.js"
import * as enums from "@/plugins/enums.js"
import moment from "moment"
//import Vue from "vue"

export default {
  components: {},
  data() {
    return {
      model: {},
      clinic: {},
      pacient: {},
      user: {},
    }
  },
  computed: {
    logoUrl: function () {
      return "/api/binary/" + this.clinic.logoID
    },
    isCustomLogo: function () {
      let ret = false
      if (Miscella.isSet(this.clinic.logoID)) {
        if (this.clinic.logoID !== "") {
          ret = true
        }
      }
      return ret
    },
    teraz: function () {
      return new moment()
    },
    isCustomHeader: function () {
      let ret = false
      if (Miscella.isSet(this.clinic.printHeaderText)) {
        if (!Miscella.isBlank(this.clinic.printHeaderText)) {
          ret = true
        }
      }
      return ret
    },
    imageWidth: function () {
      let ret = "120"
      if (Miscella.isSet(this.clinic.printLogoWidth)) {
        if (!Miscella.isBlank(this.clinic.printLogoWidth)) {
          ret = this.clinic.printLogoWidth
        }
      }
      return ret
    },
    pacientPoistovnaKod: function () {
      return enums.getPoistovnaKod(this.pacient.poistovna)
    },
    pacientPNAddress: function () {
      let ret = ""
      if (!Miscella.isSet(this.model.pobyt_v_case_pn)) {
        return ret
      }
      if (!Miscella.isSet(this.model.pobyt_v_case_pn.adresa)) {
        return ret
      }
      let address = this.model.pobyt_v_case_pn.adresa
      ret += address.street + " "
      ret += address.street_number
      ret = ret.trim()
      if (address.building_num !== "") {
        ret += "/" + address.building_num
      }
      ret = ret.trim() + ", "
      if (address.city == "") {
        if (Miscella.isSet(address.city_cb)) {
          ret += address.city_cb.display_name
        }
      } else {
        ret += address.city
      }
      ret = ret.trim()
      if (address.postal_code == "") {
        if (Miscella.isSet(address.postal_code_cb)) {
          ret += " " + address.postal_code_cb.display_name
        }
      } else {
        ret += " " + address.postal_code
      }
      if (address.country == "") {
        if (Miscella.isSet(address.country_cb)) {
          ret += " " + address.country_cb.display_name
        }
      } else {
        ret += " " + address.country
      }
      ret = ret.trim()
      if (ret === ",") {
        ret = ""
      }
      return ret
    },
    clinicAddress: function () {
      let ret = ""
      ret += this.clinic.address
      return ret
    },
    headerText: function () {
      let ret = this.clinic.name + "<br>"
      ret += this.clinicAddress
      return ret
    },
    getDatumOdVychadzky: function () {
      let ret = ""
      if (Miscella.isSet(this.model)) {
        if (Miscella.isSet(this.model.vychadzky)) {
          if (this.model.vychadzky.length > 0) {
            ret = this.$options.filters.formatDatum(
              this.model.vychadzky[0].datum_od
            )
          }
        }
      }
      return ret
    },
  },
  watch: {},
  methods: {
    fetchData: function () {
      //let potvrdenie = this.$store.state.thing
      this.model = JSON.parse(localStorage.getItem("thing"))
      this.user = JSON.parse(localStorage.getItem("user"))
      console.log("PrintPotvrdenieDPN, fetchData", this.model)
      Promise.all([this.fetchClinic(), this.fetchPacient()])
        .then(this.fetchLogo)
        .then(() => {
          console.log("promises finished")
          window.print()
          window.close()
          return Promise.resolve()
        })
    },
    fetchClinic: function () {
      console.log("fetchClinic start")
      let clinicId = this.$route.query.clinicId
      if (Miscella.isSet(clinicId)) {
        return this.$api
          .post("/v1/proto.ClinicService/GetClinic", {
            clinic_id: clinicId,
          })
          .then((data) => {
            this.clinic = data.clinic
            console.log("fetchClinic end")
          })
      }
    },
    fetchPacient: function () {
      console.log("fetchPacient start", this.$route.query)
      return this.$api
        .post("/v1/proto.PatientService/GetPatient", {
          patient_id: this.$route.query.patientId,
        })
        .then((json) => {
          this.pacient = json.patient
          console.log("fetchPacient end")
          return Promise.resolve()
        })
    },
    fetchLogo: async function () {
      console.log("fetchLogo start")
      // if (this.clinic.logoID === "") {
      //   console.log("fetchLogo end, no logo")
      //   return Promise.resolve()
      // }
      // let data = await Http.get(this.logoUrl)
      // let buffer = await data.arrayBuffer()
      // console.log("fetchLogo", buffer)
      // var base64Flag = "data:image/jpeg;base64,"
      // var imageStr = Miscella.arrayBufferToBase64(buffer)
      // document.querySelector("#imageLogo").src = base64Flag + imageStr
      console.log("fetchLogo end")
      return Promise.resolve()
    },
  },
  mounted: function () {
    console.log("PrintVysetrenie.vue mounted")
    this.fetchData()
  },
}
</script>
<style>
.printContainer {
  font-family: sans-serif;
  font-size: 11pt;
  background-color: white;
}
.printContainer h5,
h4,
h3,
h2,
h1 {
  font-family: sans-serif;
  font-size: 11pt;
}
@media print {
  .nadpis {
    font-size: 14pt;
    color: grey;
    -webkit-print-color-adjust: exact;
  }
}
.smallFont {
  font-size: 10pt;
}
.bordered {
  border: solid 0.5px black;
}
.borderedBR {
  border-bottom: solid 0.5px black;
  border-right: solid 0.5px black;
}
.borderedB {
  border-bottom: solid 0.5px black;
}
.pageBottom {
  margin-top: 8px;
  page-break-inside: avoid;
}
div#popis p {
  margin-bottom: 4px;
}
</style>
