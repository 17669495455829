var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":"","grid-list-md":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[(_vm.hasAnamneza)?_c('v-flex',{attrs:{"xs12":""}},[_c('titled-text',{attrs:{"title":"Anamnéza","text":_vm.vysetrenie.Anamneza.originalText}})],1):_vm._e(),(_vm.hasEpikriza)?_c('v-flex',{attrs:{"xs12":""}},[_c('titled-text',{attrs:{"title":"Epikríza","text":_vm.vysetrenie.Epikriza.originalText}})],1):_vm._e(),(_vm.hasObjektivnyNalez)?_c('v-flex',{attrs:{"xs12":""}},[_c('titled-text',{attrs:{"title":"Objektívny nález","text":_vm.vysetrenie.ObjektivnyNalez.originalText}})],1):_vm._e(),(_vm.hasOdporucanie)?_c('v-flex',{attrs:{"xs12":""}},[_c('titled-text',{attrs:{"title":"Odporúčanie","text":_vm.vysetrenie.Odporucanie.originalText}})],1):_vm._e(),(_vm.hasTerajsieOchorenie)?_c('v-flex',{attrs:{"xs12":""}},[_c('titled-text',{attrs:{"title":"Terajšie ochorenie","text":_vm.vysetrenie.TerajsieOchorenie.originalText}})],1):_vm._e(),_c('v-flex',{attrs:{"xs12":""}},[_c('ezdravie-textovy-popis',{attrs:{"title":"Výsledok vykonaného vyšetrenia"},model:{value:(_vm.vysetrenie.VysledokVykonanehoVysetrenia),callback:function ($$v) {_vm.$set(_vm.vysetrenie, "VysledokVykonanehoVysetrenia", $$v)},expression:"vysetrenie.VysledokVykonanehoVysetrenia"}})],1),(_vm.hasZaverPriPrijati)?_c('v-flex',{attrs:{"xs12":""}},[_c('titled-text',{attrs:{"title":"Záver pri prijatí","text":_vm.vysetrenie.ZaverPriPrijati.originalText}})],1):_vm._e(),(_vm.vysetrenie.OperacneVykony)?_c('v-flex',{attrs:{"xs12":""}},[_c('v-subheader',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$vuetify.t("Operačné výkony"))+" ")]),_c('v-data-table',{staticClass:"elevation-4",attrs:{"headers":[
          {
            text: 'Popis',
            value: 'popis',
            sortable: false,
          },
        ],"items":_vm.vysetrenie.OperacneVykony,"hide-actions":""},scopedSlots:_vm._u([{key:"items",fn:function({ item }){return [_c('td',[_vm._v(_vm._s(item.Popis.originalText))])]}}],null,false,1225268586)})],1):_vm._e(),(_vm.vysetrenie.OdbornyZastupca)?_c('v-flex',{attrs:{"xs12":""}},[_c('v-subheader',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$vuetify.t("Odborný zástupca")))]),_c('v-container',{staticClass:"pa-0",attrs:{"fluid":"","grid-list-xs":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs6":""}},[_c('titled-text',{attrs:{"title":"Meno"}},[_c('ezdravie-zdravotnicky-pracovnik',{attrs:{"ii":_vm.vysetrenie.OdbornyZastupca.IdentifikaciaZPrac}})],1)],1),_c('v-flex',{attrs:{"xs12":"","md6":""}},[_c('titled-text',{attrs:{"title":"Špecializácia","text":_vm.vysetrenie.OdbornyZastupca.SpecializaciaLekara.DisplayName}})],1),_c('v-flex',{attrs:{"xs12":"","md6":""}},[_c('titled-text',{attrs:{"title":"OÚ PZS"}},[_c('ezdravie-ou-pzs',{attrs:{"ii":_vm.vysetrenie.OdbornyZastupca.IdentifikaciaOupzs}})],1)],1),_c('v-flex',{attrs:{"xs12":"","md6":""}},[_c('titled-text',{attrs:{"title":"Nezmluvný lekár","text":_vm.$vuetify.t(
                  _vm.vysetrenie.OdbornyZastupca.NezmluvnyLekar ? 'Áno' : 'Nie'
                )}})],1)],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }