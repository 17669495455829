<template>
  <v-form>
    <v-container fluid>
      <v-layout column>
        <v-tabs v-model="activeTab">
          <v-tab>{{ $vuetify.t("Základné údaje") }}</v-tab>
          <v-tab v-if="ambulance.id">{{ $vuetify.t("Používatelia") }}</v-tab>
        </v-tabs>
        <div style="min-height: 450px">
          <v-tabs-items v-model="activeTab">
            <v-tab-item>
              <v-container fluid grid-list-md class="pt-1 pb-1">
                <v-layout row wrap>
                  <v-flex xs12>
                    <codebook-autocomplete
                      codebook-id="39"
                      name="zameranie"
                      v-model="ambulance.zameranie"
                      :label="$vuetify.t('Odborné zameranie (povinné)')"
                      v-validate="'required'"
                    >
                    </codebook-autocomplete>
                  </v-flex>
                  <v-flex xs12>
                    <TextField
                      v-model="ambulance.name"
                      name="ambulance.name"
                      :label="$vuetify.t('Názov')"
                      :maxlength="255"
                      :placeholder="namePlaceholder"
                      :hint="
                        $vuetify.t(
                          'Zadanie názvu nie je povinné. Ak nezadáte názov, vyplní sa automaticky podľa zvoleného odborného zamerania.'
                        )
                      "
                      autocomplete="off"
                    ></TextField>
                  </v-flex>
                  <v-flex xs4>
                    <TextField
                      v-model="ambulance.kod_ambulancie"
                      name="ambulance.kod_ambulancie"
                      :label="$vuetify.t('Kód')"
                      :maxlength="12"
                      v-validate="'min:12|max:12'"
                      mask="A###########"
                      :hint="
                        $vuetify.t(
                          'Zadajte kód v tvare A99999999999 (nepovinné).'
                        )
                      "
                      autocomplete="off"
                    ></TextField>
                  </v-flex>
                  <v-flex xs4>
                    <TextField
                      v-model="ambulance.kod_pobocky_poistovne"
                      name="ambulance.kod_pobocky_poistovne"
                      :label="$vuetify.t('Kód pobočky poisťovne')"
                      :maxlength="2"
                      v-validate="'min:2|max:2'"
                      mask="##"
                      :hint="
                        $vuetify.t(
                          'Číselný kód potrebný pri generovaní dávok do posťovne (nepovinné).'
                        )
                      "
                      autocomplete="off"
                    ></TextField>
                  </v-flex>
                  <v-flex xs4>
                    <TextField
                      v-model="ambulance.jruz_id"
                      name="ambulance.jruz_id"
                      :label="$vuetify.t('JRUZ ID (OU PZS)')"
                      :maxlength="11"
                      v-validate="'numeric|min:11|max:11'"
                      mask="###########"
                      :hint="
                        $vuetify.t(
                          'Zadajte ID v tvare 99999999999 (nepovinné).'
                        )
                      "
                      autocomplete="off"
                    ></TextField>
                  </v-flex>
                  <v-flex xs6>
                    <vue-tel-input-vuetify
                      v-model="ambulance.phone"
                      clearable
                      name="ambulance.phone"
                      :label="$vuetify.t('Telefónne číslo')"
                      :placeholder="$vuetify.t('Telefónne číslo')"
                      :maxLen="255"
                      defaultCountry="SK"
                      :preferredCountries="[
                        'SK',
                        'CZ',
                        'AT',
                        'PL',
                        'HU',
                        'DE',
                        'UA',
                      ]"
                      @input="onPhoneInput"
                      :error-messages="errors.collect('phone')"
                    >
                    </vue-tel-input-vuetify>
                  </v-flex>
                  <v-flex xs6>
                    <TextField
                      v-model="ambulance.email"
                      name="ambulance.email"
                      v-validate="'email'"
                      :maxlength="100"
                      :label="$vuetify.t('E-mail')"
                      autocomplete="email"
                    ></TextField>
                  </v-flex>
                  <v-flex xs6>
                    <codebook-autocomplete
                      ref="countryField"
                      codebook-id="51"
                      v-model="ambulance.address.country_cb"
                      name="country"
                      :label="$vuetify.t('Krajina')"
                      :prepended-items-params="{
                        in_filter: {
                          attr: 'Skratka',
                          values: ['703', '203', '804', '348', '040', '616'],
                        },
                      }"
                      :prepended-items-process-func="
                        (items) => {
                          const sortBy = [
                            '703',
                            '203',
                            '804',
                            '348',
                            '040',
                            '616',
                          ]
                          items.sort(
                            (a, b) =>
                              sortBy.indexOf(a.skratka) -
                              sortBy.indexOf(b.skratka)
                          )
                        }
                      "
                    ></codebook-autocomplete>
                  </v-flex>
                  <v-flex xs6 v-if="isSlovak">
                    <codebook-autocomplete
                      codebook-id="45"
                      name="city"
                      v-model="ambulance.address.city_cb"
                      :label="$vuetify.t('Mesto')"
                    ></codebook-autocomplete>
                  </v-flex>
                  <v-flex xs6 v-else>
                    <TextField
                      v-model="ambulance.address.city"
                      :label="$vuetify.t('Mesto')"
                      name="city"
                    ></TextField>
                  </v-flex>
                  <v-flex xs6>
                    <TextField
                      v-model="ambulance.address.street"
                      :label="$vuetify.t('Ulica')"
                      name="address_street"
                    ></TextField>
                  </v-flex>
                  <v-flex xs3>
                    <TextField
                      v-model="ambulance.address.street_number"
                      :label="$vuetify.t('Číslo')"
                      name="address_street_number"
                    ></TextField>
                  </v-flex>
                  <v-flex xs3>
                    <codebook-autocomplete
                      v-if="isSlovak"
                      codebook-id="150"
                      v-model="ambulance.address.postal_code_cb"
                      name="postal_code_cb"
                      :label="$vuetify.t('PSČ')"
                    ></codebook-autocomplete>
                    <TextField
                      v-else
                      v-model="ambulance.address.postal_code"
                      :label="$vuetify.t('PSČ')"
                      name="psc"
                    ></TextField>
                  </v-flex>
                  <v-flex xs12>
                    <v-textarea
                      v-model="ambulance.note"
                      :label="$vuetify.t('Poznámka')"
                      name="ambulance.note"
                      auto-grow
                      rows="2"
                      :counter="5000"
                      v-validate="'max:5000'"
                      data-vv-name="note"
                      :data-vv-as="$vuetify.t('Poznámka')"
                    ></v-textarea>
                  </v-flex>
                  <v-flex xs12>
                    <v-checkbox
                      v-model="ambulance.public"
                      :label="$vuetify.t('Je verejne dostupná?')"
                      :hint="
                        $vuetify.t(
                          ambulance.public
                            ? 'Ambulancia bude verejne dostupná v mobilnej aplikácii, viditeľné budú nasledovné údaje: Názov, Odbornosť, Telefón, Email, Adresa, Poznámka.'
                            : 'Ambulancia nebude verejne dostupná v mobilnej aplikácii'
                        )
                      "
                      persistent-hint
                    ></v-checkbox>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-tab-item>
            <v-tab-item v-if="ambulance.id">
              <v-list>
                <v-list-tile
                  v-for="(user, index) in users"
                  :key="user.id"
                  @click="dummy"
                >
                  <v-list-tile-content>
                    <v-list-tile-title v-html="user.name"></v-list-tile-title>
                    <v-list-tile-sub-title>
                      {{
                        user.ambulance_user_odbornost == null
                          ? ""
                          : user.ambulance_user_odbornost.display_name
                      }}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                  <v-list-tile-action>
                    <v-tooltip top>
                      <v-btn icon @click="removeUser(index)" slot="activator">
                        <v-icon>remove</v-icon>
                      </v-btn>
                      <span>{{ $vuetify.t("Odstrániť") }}</span>
                    </v-tooltip>
                  </v-list-tile-action>
                </v-list-tile>
                <v-list-tile v-show="addingNewUser === true">
                  <v-autocomplete
                    v-model="newUser"
                    :label="$vuetify.t('')"
                    :items="otherUsers"
                    @change="newUserAdded"
                    :search-input.sync="newUserSearch"
                    hide-no-data
                    clearable
                    no-filter
                    return-object
                  >
                    <template v-slot:selection="{ item }">
                      {{ item.name }}
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-tile-content>
                        <v-list-tile-title
                          v-html="item.name"
                        ></v-list-tile-title>
                        <v-list-tile-sub-title>
                          {{
                            item.ambulance_user_odbornost == null
                              ? ""
                              : item.ambulance_user_odbornost.display_name
                          }}
                        </v-list-tile-sub-title>
                      </v-list-tile-content>
                    </template>
                  </v-autocomplete>
                </v-list-tile>
              </v-list>
              <div class="text-xs-center" v-show="addingNewUser === false">
                <v-btn @click="addingNewUser = !addingNewUser"
                  >{{ $vuetify.t("Pridať") }}<v-icon right>add</v-icon></v-btn
                >
              </div>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
import BaseForm from "@/components/BaseForm.vue"
import * as Miscella from "@/miscella.js"
import TextField from "@/components/TextField.vue"
import CodebookAutocomplete from "@/components/ciselnikComponents/CodebookAutocomplete.vue"
import VueTelInputVuetify from "@/components/vue-tel-input-vuetify/vue-tel-input-vuetify.vue"
import { normalizeString } from "../../miscella"

export default {
  name: "FormAmbulancia",
  $_veeValidate: {
    validator: "new",
  },
  extends: BaseForm,
  components: {
    TextField,
    CodebookAutocomplete,
    VueTelInputVuetify,
  },
  data: function () {
    return {
      action: "new",
      saveUrl: "",
      ambulance: {
        address: {},
      },
      activeTab: 0,
      users: [],
      addingNewUser: false,
      newUser: null,
      newUserSearch: null,
      otherUsers: [],
      allUsers: [],
      phone: {
        number: "",
        valid: false,
        country: undefined,
      },
    }
  },
  props: {},
  computed: {
    namePlaceholder: function () {
      return Miscella.isSet(this.ambulance.zameranie) &&
        Miscella.isEmpty(this.ambulance.name)
        ? this.ambulance.zameranie.display_name
        : ""
    },
    isSlovak: function () {
      if (!Miscella.isSet(this.ambulance.address.country_cb)) {
        return true
      }
      return this.ambulance.address.country_cb.skratka === "703"
    },
  },
  watch: {},
  methods: {
    dummy: function () {},
    init: function () {
      this.action = ""
      this.ambulance = {
        id: "",
        clinic_id: this.$store.state.me.clinic.id,
        name: "",
        zameranie: null,
        kod_ambulancie: "",
        jruz_id: "",
        public: false,
        phone: "",
        email: "",
        note: "",
        kod_pobocky_poistovne: "",
        address: {
          street: "",
          street_number: "",
          city: "",
          city_cb: null,
          postal_code: "",
          postal_code_cb: null,
          country_cb: null,
        },
      }
      this.activeTab = 0
      this.users = []
      this.addingNewUser = false
      this.newUser = ""
      this.otherUsers = []
      this.allUsers = []
      this.errors.clear()
    },
    edit: function (ambulance) {
      this.init()
      this.action = "edit"
      this.ambulance = Miscella.cloneObject(ambulance)
      this.fetchUsers()
      this.getOtherUsers()
      this.$validator.reset()
    },
    new: function () {
      this.init()
      this.action = "new"
      this.$validator.reset()
    },
    onPhoneInput: function (formattedNumber, { number, valid, country }) {
      this.phone.number = number.e164
      this.phone.valid = valid
      this.phone.country = country && country.name
    },
    validate: async function () {
      this.errors.clear()
      var valid = await this.$validator.validateAll()
      if (this.phone.number && !this.phone.valid) {
        this.errors.add({
          field: "phone",
          msg: this.$vuetify.t("Nesprávny formát telefónneho čísla."),
        })
      }
      return valid
    },
    save: async function () {
      try {
        if (Miscella.isEmpty(this.ambulance.name)) {
          this.ambulance.name = this.ambulance.zameranie.display_name
        }
        if (this.isSlovak) {
          this.ambulance.address.city = ""
          this.ambulance.address.postal_code = ""
        } else {
          this.ambulance.address.city_cb = null
          this.ambulance.address.postal_code_cb = null
        }
        let params = {
          ambulance: {
            id: this.ambulance.id ? this.ambulance.id : "",
            clinic_id: this.ambulance.clinic_id,
            name: this.ambulance.name,
            zameranie: this.ambulance.zameranie,
            kod_ambulancie: this.ambulance.kod_ambulancie,
            jruz_id: this.ambulance.jruz_id,
            public: this.ambulance.public,
            note: this.ambulance.note,
            kod_pobocky_poistovne: this.ambulance.kod_pobocky_poistovne,
            address: this.ambulance.address,
          },
        }
        if (this.phone.valid) {
          params.ambulance.phone = this.phone.number
        } else {
          params.ambulance.phone = ""
        }
        params.ambulance.email = Miscella.normalizeString(this.ambulance.email)
        console.log("FormAmbulance, save", params)

        var url = this.ambulance.id
          ? this.$api.ambulanceService.updateAmbulanceUrl
          : this.$api.ambulanceService.createAmbulanceUrl
        var data = await this.$api.post(url, params)
        this.ambulance = data.ambulance
        if (!Miscella.isSet(this.$store.state.me.ambulance)) {
          this.$store.commit("setAmbulance", this.ambulance)
        }
        if (this.$store.state.me.ambulance.id === this.ambulance.id) {
          this.$store.dispatch("reloadMe", this.$api)
        }
        this.notifySuccess("Ambulancia bola úspešne uložená")
        this.$emit("saved")
      } catch (err) {
        this.processError(err, "Pri ukladaní ambulancie vznikla chyba")
      }
    },
    fetchUsers: async function () {
      try {
        let data = await this.$api.post("v1/proto.UserService/ListUsers", {
          clinic_id: this.ambulance.clinic_id,
          ambulance_id: this.ambulance.id,
        })
        this.users = data.users || []
      } catch (err) {
        this.processError(err)
      }
    },
    newUserAdded: async function (aNew) {
      if (!this.addingNewUser) {
        return
      }
      try {
        console.log("Adding user to ambulance", aNew)
        let data = await this.$api.post(
          "v1/proto.AmbulanceService/AddAmbulanceUser",
          {
            //user_id: aNew.origID,
            user_id: aNew.id,
            ambulance_id: this.ambulance.id,
            odbornost: aNew.ambulance_user_odbornost,
          }
        )
        this.users.push(aNew)
        this.getOtherUsers()
        this.$store.commit(
          "setSuccess",
          this.$vuetify.t("Používateľ bol úspešne pridaný do ambulancie.")
        )
      } catch (err) {
        this.processError(err)
      }
      this.addingNewUser = false
      this.newUser = null
      this.newUserSearch = null
    },
    getOtherUsers: async function () {
      this.otherUsers = []
      let arrRemove = []
      if (this.allUsers.length === 0) {
        try {
          let data = await this.$api.post("v1/proto.UserService/ListUsers", {
            clinic_id: this.ambulance.clinic_id,
          })
          this.allUsers = data.users
        } catch (err) {
          this.processError(err)
        }
      }
      let arr = this.allUsers.slice()
      if (this.users && this.users.length > 0) {
        for (let i = 0; i < arr.length; i++) {
          for (let j = 0; j < this.users.length; j++) {
            if (arr[i].id === this.users[j].id) {
              arrRemove.push(arr[i])
            }
          }
          /*
          for (let j = 0; j < this.ambulance.guests.length; j++) {
            if (arr[i].id === this.ambulance.guests[j].id) {
              arrRemove.push(arr[i])
            }
          }
          */
        }
        arr = arr.filter(function (el) {
          return !arrRemove.includes(el)
        })
      }
      this.otherUsers = arr
      console.log("other users", this.otherUsers)
      arr = []
      for (let i = 0; i < this.otherUsers.length; i++) {
        let user = this.otherUsers[i]
        if (user.odbornosti && user.odbornosti.length > 0) {
          //console.log("user", user)
          for (let j = 0; j < user.odbornosti.length; j++) {
            let odbornost = user.odbornosti[j]
            //console.log("ina odbornost", odbornost.display_name)
            let userCopy = Miscella.cloneObject(user)
            //userCopy.origID = user.id
            //userCopy.id = user.id + j
            userCopy.ambulance_user_odbornost = odbornost
            arr.push(userCopy)
            //console.log("added user s odbornostou", arr.length, userCopy.odbornost.display_name, userCopy)
          }
        } else {
          arr.push(user)
          //console.log("added user bez odbornosti", arr.length, user)
        }
      }
      this.otherUsers = arr
      console.log("other users", this.otherUsers)
    },
    removeUser: async function (aIndex) {
      try {
        let data = await this.$api.post(
          "v1/proto.AmbulanceService/RemoveAmbulanceUser",
          {
            user_id: this.users[aIndex].id,
            ambulance_id: this.ambulance.id,
          }
        )
        Miscella.remove(this.users, aIndex)
        this.getOtherUsers()
        this.$store.commit(
          "setSuccess",
          this.$vuetify.t("Používateľ bol úspešne odradený z ambulancie.")
        )
      } catch (err) {
        this.processError(err)
      }
      this.addingNewUser = false
      this.newUser = null
      this.newUserSearch = null
    },
  },
  mounted: function () {
    //this.fetchData()
  },
}
</script>
