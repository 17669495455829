<template>
  <v-content>
    <ApiTable
      ref="table"
      :headers="headers"
      url="/v1/proto.PatientService/ListPatients"
      :title="$vuetify.t('Zoznam pacientov')"
      filterTooltip="Vyhľadávanie v položkách Rodné číslo, Meno, Priezvisko, Telefón, Email"
      defaultSortBy=""
      :customParams="{
        clinic_id: this.$store.state.me.clinic
          ? this.$store.state.me.clinic.id
          : null,
        ambulance_id: this.$store.state.me.ambulance
          ? this.$store.state.me.ambulance.id
          : null,
      }"
      responseItems="patients"
    >
      <template slot="toolbarItems">
        <v-tooltip top>
          <v-btn icon slot="activator" @click="addPacient">
            <v-icon>add</v-icon>
          </v-btn>
          <span>{{ $vuetify.t("Pridať") }}</span>
        </v-tooltip>
      </template>
      <template slot="items" slot-scope="props">
        <tr @click="itemClicked(props.item)">
          <td class="text-md-left">
            <v-avatar color="red" size="32">
              <img
                alt=""
                :src="
                  defaultText(
                    props.item.imageLink,
                    '//www.gravatar.com/avatar/xxx?d=mp'
                  )
                "
              />
            </v-avatar>
          </td>
          <td class="text-md-left">{{ props.item.person_unique_id }}</td>
          <td class="text-md-left">{{ props.item.name }}</td>
          <td class="text-md-left">{{ props.item.surname }}</td>
          <td class="text-md-left">{{ props.item.phone }}</td>
          <td class="text-md-left">{{ props.item.email }}</td>
          <td>
            {{ props.item.posledne_vysetrenie_na_ambulancii | formatDatum }}
            <!-- <span -->
            <!--   class="dashhead-subtitle" -->
            <!--   v-if="zobrazitPosledneVysetrenie(props.item)" -->
            <!-- > -->
            <!--   ({{ props.item.posledne_vysetrenie | formatDatum }}) -->
            <!-- </span> -->
          </td>
          <!-- <td>
            {{ props.item.nextAppointment | formatDatumACas("D.M.YYYY HH:mm") }}
          </td> -->
          <td>
            <!--v-tooltip left>
              <v-icon slot="activator" @click.stop="deletePacient(props.item)"
                >delete</v-icon
              >
              <span>{{ $vuetify.t("Odstrániť") }}</span>
            </v-tooltip-->
            <v-tooltip top>
              <span slot="activator">
                <v-icon
                  :color="jePacientVCakarni(props.item) ? 'success' : 'none'"
                  @click.native.stop="setPacientVCakarni(props.item)"
                  >airline_seat_recline_normal
                </v-icon>
              </span>
              <span>
                {{
                  $vuetify.t(
                    "$vuetify.pacient.cakaren." + jePacientVCakarni(props.item)
                  )
                }}
              </span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </ApiTable>
    <BaseDialog :dialogTitle="$vuetify.t('Nový pacient')" ref="dialog">
      <FormPacient ref="form" @saved="savedPacient" width="75%"></FormPacient>
    </BaseDialog>
  </v-content>
</template>

<script>
import * as Miscella from "../miscella.js"
import ApiTable from "../components/ApiTable.vue"
import BaseDialog from "./../components/BaseDialog.vue"
import FormPacient from "../components/forms/FormPacient.vue"

export default {
  components: {
    ApiTable,
    BaseDialog,
    FormPacient,
  },
  data() {
    return {
      headers: [
        {
          text: "",
          value: "",
          sortable: false,
          width: 32,
        },
        {
          text: "Rodné číslo",
          value: "PersonUniqueID",
        },
        {
          text: "Meno",
          value: "Name",
        },
        {
          text: "Priezvisko",
          value: "Surname",
        },
        {
          text: "Telefón",
          value: "Phone",
          sortable: false,
        },
        {
          text: "Email",
          value: "Email",
          sortable: false,
        },
        {
          text: "Posledné vyšetrenie",
          value: "posledneVysetrenieNaAmbulancii",
          sortable: false,
        },
        // {
        //   text: "Objednaný",
        //   value: "nextAppointment",
        //   sortable: false,
        // },
        {
          text: "",
          value: "",
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ambulanceId: function () {
      let id = null
      if (Miscella.isSet(this.$store.state.me.ambulance)) {
        id = this.$store.state.me.ambulance.id
      }
      return id
    },
  },
  watch: {
    ambulanceId: function () {
      this.$refs.table.reload()
    },
  },
  methods: {
    addPacient: function () {
      this.$refs.form.new()
    },
    savedPacient: function () {
      this.$refs.table.reload()
    },
    itemClicked: function (item) {
      this.$router.push({ name: "pacient", params: { id: item.id } })
    },
    deletePacient: function (item) {
      return this.$confirm(
        this.$vuetify.t("Naozaj si želáte odstrániť pacienta?")
      ).then((res) => {
        if (res) {
          return this.$api
            .delete("/api/patient/" + item.id)
            .then(() => {
              this.$store.commit(
                "setSuccess",
                this.$vuetify.t("Pacient bol úspešne odstránený")
              )
              this.$refs.table.reload()
            })
            .catch((err) => {
              this.$store.commit(
                "setError",
                this.$vuetify.t("Pri mazaní pacienta vznikla chyba ") +
                  " (" +
                  err +
                  ")."
              )
            })
        }
      })
    },
    zobrazitPosledneVysetrenie(item) {
      /*if (item.posledneVysetrenie) {
        if (item.posledneVysetrenieNaAmbulancii) {
          return item.posledneVysetrenie > item.posledneVysetrenieNaAmbulancii
        }
        return true
      }*/
      return false
    },
    jePacientVCakarni: function (pacient) {
      let pacientId = pacient.id
      let ambulance = this.$store.state.me.ambulance
      let cakaren = ambulance !== null ? ambulance.cakaren : null
      let ret = false
      if (Miscella.isSet(cakaren?.items)) {
        for (let i = 0; i < cakaren.items.length; i++) {
          let p = cakaren.items[i]
          if (p.patient_id === pacientId) {
            ret = true
          }
        }
      }
      return ret
    },
    setPacientVCakarni: async function (pacient) {
      let pacientId = pacient.id
      let ambulance = this.$store.state.me.ambulance
      let cakaren = ambulance !== null ? ambulance.cakaren : null
      if (this.jePacientVCakarni(pacient)) {
        cakaren = Miscella.cakarenRemove(cakaren, pacientId)
      } else {
        let p = { patient_id: pacientId }
        cakaren.items.push(p)
      }
      try {
        let result = await Miscella.cakarenUpdate(
          cakaren,
          ambulance.id,
          this.$api
        )
        this.$store.commit(
          "setSuccess",
          this.$vuetify.t("Čakáreň bola úspešne uložená.")
        )
        this.$store.dispatch("reloadMe", this.$api)
        console.log("cakaren saved", result)
      } catch (err) {
        console.error(err)
        this.$store.commit(
          "setError",
          this.$vuetify.t(
            "Pri ukladaní pacienta do čakárne vznikla chyba ({message}).",
            {
              message: err,
            }
          )
        )
      }
    },
  },

  // mounted: function () {
  // if (this.$route.query.itemsFilter !== undefined) {
  // this.itemsFilter = this.$route.query.itemsFilter
  // this.$refs.patientsDashhead.setFilter(this.itemsFilter)
  // }
  // }
}
</script>
