<template>
  <v-container fluid pa-0>
    <v-form>
      <template
        v-for="item in [
          { text: 'Udalosť', value: enums.TypEventu.TypEventuKalendar },
          { text: 'Objednanie', value: enums.TypEventu.TypEventuObjednanie },
          { text: 'Kontrola', value: enums.TypEventu.TypEventuKontrola },
        ]"
      >
        <v-tooltip top :key="item.value">
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              :flat="event.Typ !== item.value"
              @click="setTyp(item.value, event.Typ)"
              :color="event.Typ === item.value ? 'primary' : ''"
              :disabled="lockedType && event.Typ !== item.value"
            >
              {{ $vuetify.t(item.text) }}
            </v-btn>
          </template>
          <span>{{ $vuetify.t(item.text) }}</span>
        </v-tooltip>
      </template>
      <v-container
        fluid
        grid-list-sm
        class="pa-0"
        v-if="
          event.Typ == enums.TypEventu.TypEventuKalendar ||
          event.Typ == enums.TypEventu.TypEventuObjednanie
        "
      >
        <v-layout row wrap>
          <v-flex xs4>
            <DatePicker
              v-model="event.Datum"
              :label="$vuetify.t('Dátum')"
              format="YYYY-MM-DD"
              name="datum"
              v-validate="'required'"
              :class="isDateInPast ? 'eventDatumBad' : 'eventDatumOk'"
            ></DatePicker>
          </v-flex>
          <v-flex xs4>
            <TimePicker
              v-model="event.CasOd"
              :label="$vuetify.t('Čas')"
              name="casOd"
              v-validate="'required'"
            ></TimePicker>
          </v-flex>
          <v-flex xs4>
            <v-combobox
              v-model.number="event.Duration"
              :items="durationItems"
              :return-object="false"
              label="Trvanie (min)"
            ></v-combobox>
          </v-flex>
        </v-layout>
      </v-container>
      <approx-date-picker
        v-if="event.Typ == enums.TypEventu.TypEventuKontrola"
        :label="$vuetify.t('Približný dátum')"
        v-model="event.Datum"
        :initial-date="initialDate"
        format="YYYY-MM-DD"
        v-validate="'required'"
      ></approx-date-picker>
      <v-layout row>
        <v-flex xs12>
          <v-chip
            outline
            small
            class="ma-1 clicker"
            v-for="{ text } in eventTexty"
            :key="text"
            @click="event.Desc = text"
          >
            <span
              class="text-none text-truncate clicker"
              style="max-width: 200px"
              :title="text"
              >{{ text }}</span
            >
          </v-chip>
          <v-textarea
            auto-grow
            rows="2"
            v-model="event.Desc"
            name="desc"
            :label="$vuetify.t('Popis')"
            :error-messages="errors.collect('popis')"
          ></v-textarea>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs12>
          <v-autocomplete
            v-if="event.Typ !== enums.TypEventu.TypEventuKalendar"
            v-model="event.PatientId"
            :label="$vuetify.t('Pacient')"
            :items="pacienti"
            :search-input.sync="pacientiSearch"
            hide-no-data
            no-filter
            clearable
            item-value="id"
            item-text="name"
            :error-messages="errors.collect('pacient')"
            prepend-inner-icon="search"
            :disabled="pacientId !== undefined"
          >
            <!--template v-slot:item="data">
              <v-list-tile-avatar>
                <img :src="data.item === null ? null : data.item.imageLink" />
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title
                  v-html="data.item === null ? null : data.item.name"
                ></v-list-tile-title>
              </v-list-tile-content>
            </template-->
          </v-autocomplete>
          <v-text-field v-else :label="$vuetify.t('Pacient')" disabled />
        </v-flex>
      </v-layout>
    </v-form>
  </v-container>
</template>

<script>
const DATE_FORMAT = "YYYY-MM-DD"

import * as Miscella from "./../../miscella.js"
import * as enums from "@/plugins/enums.js"
import DatePicker from "./../DatePicker.vue"
import ApproxDatePicker from "./../ApproxDatePicker.vue"
import TimePicker from "./../TimePicker.vue"
import Vue from "vue"
import moment from "moment"

export default {
  props: {
    pacientId: {
      type: String,
      required: false,
    },
    pacientName: {
      type: String,
      required: false,
      default: "",
    },
  },
  $_veeValidate: {
    validator: "new", // give me my own validator scope.
  },
  components: {
    DatePicker,
    TimePicker,
    ApproxDatePicker,
  },
  data: function () {
    return {
      enums: enums,
      event: null,
      initialDate: null,
      pacienti: [],
      pacientiSearch: "",
      pacientiLoading: false,
      eventTexty: [],
      lockedType: false,
    }
  },
  watch: {
    pacientiSearch: function (newValue) {
      if (Miscella.isSet(newValue)) {
        this.fetchPacienti()
      }
    },
  },
  computed: {
    durationItems: function () {
      return Miscella.isSet(this.$store.state.me.ambulance) &&
        Miscella.isSet(this.$store.state.me.ambulance.eventDurations)
        ? this.$store.state.me.ambulance.eventDurations
        : [15, 30, 45, 60, 90, 120]
    },
    range: function () {
      return this.$store.state.calendar.range
    },
    isDateInPast: function () {
      let ret = false
      if (!Miscella.isSet(this.event)) {
        return false
      }
      if (!Miscella.isSet(this.event.Datum)) {
        return false
      }
      let d = moment(this.event.Datum, "YYYY-MM-DD")
      let n = new moment()
      if (this.event.ID === "") {
        ret = d.isBefore(n, "days")
      }
      return ret
    },
  },
  methods: {
    setTime: function () {
      if (this.event.CasOd) {
        return
      }
      var hour =
        this.range[0] > moment().hour() + 1 ||
        this.range[1] < moment().hour() + 1
          ? this.range[0]
          : moment().hour() + 1
      this.event.CasOd = `${moment(hour, "H", true).format("HH")}:00`
    },
    setTyp: function (newTyp, oldTyp) {
      if (newTyp === oldTyp) {
        return
      }
      this.event.Typ = newTyp
      switch (this.event.Typ) {
        case enums.TypEventu.TypEventuKontrola:
          //this.event.Datum = null
          this.event.CasOd = "00:00"
          break
        default:
          if (oldTyp == enums.TypEventu.TypEventuKontrola) {
            //this.event.Datum = null
          }
          this.setTime()
          break
      }
      this.errors.clear()
    },
    init: function () {
      this.pacienti = []
      this.pacientiLoading = false
      this.pacientiSearch = ""
      this.event = {
        ID: "",
        Datum: null,
        CasOd: null,
        Desc: "",
        Duration: 30,
        Typ: Miscella.isEmpty(this.pacientId)
          ? enums.TypEventu.TypEventuKalendar
          : enums.TypEventu.TypEventuObjednanie,
      }
      this.lockedType = false
      this.initialDate = null
      this.fetchData()
      if (!Miscella.isEmpty(this.pacientId)) {
        this.event.PatientId = this.pacientId
        this.pacienti = [{ id: this.pacientId, name: this.pacientName }]
      }
      this.$validator.reset()
    },
    new: function (datum, cas) {
      this.init()
      this.initialDate = !Miscella.isEmpty(datum)
        ? datum
        : moment().format(DATE_FORMAT)
      this.event.Datum = this.initialDate
      if (!Miscella.isEmpty(cas)) {
        this.event.CasOd = cas
      } else {
        this.setTime()
      }
    },
    edit: function (aEvent) {
      this.init()
      this.event = aEvent
      this.initialDate = this.event.Datum
      this.pacienti = [this.pacient]
      this.lockedType = true
    },
    save: function () {
      this.errors.clear()
      return this.$validator.validateAll().then(
        function () {
          var params = Miscella.cloneObject(this.event)
          params.AmbulanceId = localStorage.getItem("ambId")
          let isPacient = !Miscella.isEmpty(this.event.PatientId)
          switch (this.event.Typ) {
            case enums.TypEventu.TypEventuKalendar:
              params.PatientId = ""
              if (Miscella.isEmpty(this.event.Desc)) {
                this.errors.add({
                  field: "popis",
                  msg: this.$vuetify.t("Položka Popis je povinná."),
                })
              }

              break
            case enums.TypEventu.TypEventuKontrola:
            case enums.TypEventu.TypEventuObjednanie:
              if (!isPacient) {
                this.errors.add({
                  field: "pacient",
                  msg: this.$vuetify.t("Položka Pacient je povinná."),
                })
              }
              break
            /*case enums.TypEventu.TypEventuObjednanie:
              if (!isPacient && Miscella.isEmpty(this.event.Desc)) {
                this.errors.add({
                  field: "popis",
                  msg: this.$vuetify.t(
                    "Jedna z položiek Popis alebo Pacient je povinná."
                  )
                })
                this.errors.add({
                  field: "pacient",
                  msg: this.$vuetify.t(
                    "Jedna z položiek Popis alebo Pacient je povinná."
                  )
                })
              }*/
          }
          if (this.errors.any()) {
            return
          }
          return this.$api
            .post("/api/event", params)
            .then(
              function (result) {
                this.$store.commit(
                  "setSuccess",
                  this.$vuetify.t("Udalosť bola úspešne uložená.")
                )
                this.$emit("saved", result)
              }.bind(this)
            )
            .catch(
              function (err) {
                this.$store.commit(
                  "setError",
                  this.$vuetify.t(
                    "Pri ukladaní udalosti vznikla chyba ({message}).",
                    { message: err }
                  )
                )
              }.bind(this)
            )
        }.bind(this)
      )
    },
    fetchData: function () {
      //if (this.pacienti.length === 0) {
      //  this.fetchPacienti()
      //}
      this.fetchTexty()
    },
    fetchTexty: async function () {
      this.eventTexty = await this.$api.get("/api/ambulance/eventTexty")
    },
    fetchPacienti: function () {
      let params = {}
      params.sortBy = "id"
      params.sortAsc = "false"
      params.filter = this.pacientiSearch
      this.pacientiLoading = true
      return this.$api
        .get("/api/patients", params)
        .then(this.setPacients)
        .catch(this.processError)
        .finally(() => {
          Vue.nextTick(() => {
            this.pacientiLoading = false
          })
        })
    },
    setPacients: function (data) {
      this.pacienti = data.tableData
    },
  },
  mounted: function () {},
  created: function () {
    this.init()
  },
}
</script>
<style>
div.eventDatumBad div.v-text-field input {
  color: red !important;
}
div.eventDatumOk div.v-text-field input {
  color: auto;
}
</style>
