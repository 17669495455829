<template>
  <v-layout row wrap>
    <v-flex xs12 class="mb-3">
      <v-card>
        <v-list subheader dense>
          <v-subheader v-if="title">{{ title }}</v-subheader>
          <template v-for="(item, index) in items">
            <v-list-tile
              :key="'list' + index"
              @click="editItem(item)"
              v-if="showAllItems ? true : index < 10"
            >
              <v-list-tile-content>
                <v-container fluid>
                  <v-layout row>
                    <v-flex xs1>
                      <v-tooltip top>
                        <span slot="activator"
                          >{{ item.recept.diagnoza.skratka }}
                        </span>
                        <span>{{ item.recept.diagnoza.display_name }}</span>
                      </v-tooltip>
                    </v-flex>
                    <template v-if="item.recept.liek_typ === 'Liek'">
                      <v-flex xs1>
                        <v-tooltip top>
                          <span slot="activator">{{
                            item.recept.liek !== null
                              ? item.recept.liek.liek.kod_sukl
                              : ""
                          }}</span>
                          <span>{{ $vuetify.t("Kód ŠÚKL") }}</span>
                        </v-tooltip>
                      </v-flex>
                      <v-flex xs7>
                        <v-tooltip top>
                          <span slot="activator"
                            >{{
                              item.recept.liek !== null
                                ? item.recept.liek.liek.nazov
                                : ""
                            }}
                            <span
                              class="grey--text text--lighten-1 caption"
                              :title="$vuetify.t('Doplnok názvu')"
                              >{{
                                item.recept.liek !== null
                                  ? item.recept.liek.liek.doplnok_nazvu
                                  : ""
                              }}</span
                            >
                          </span>
                          <span>{{ $vuetify.t("Názov") }}</span>
                        </v-tooltip>
                      </v-flex>
                    </template>
                    <template v-else-if="isIPL(item)">
                      <v-flex xs8>
                        <v-tooltip top>
                          <span slot="activator">{{
                            item.recept.zlozenie_ipl
                          }}</span>
                          <span>{{ $vuetify.t("Zloženie IPL") }}</span>
                        </v-tooltip>
                      </v-flex>
                    </template>
                    <template v-else>
                      <v-flex xs8>
                        {{
                          item.recept.liek != null
                            ? item.recept.liek.display_name
                            : ""
                        }}
                      </v-flex>
                    </template>
                    <v-flex xs1>
                      <v-tooltip top>
                        <span slot="activator">
                          <span v-if="isIPL(item)"> IPL </span>
                          <span v-else>
                            {{ item.recept.liek_typ }}
                          </span>
                        </span>
                        <span>{{ $vuetify.t("Typ") }}</span>
                      </v-tooltip>
                    </v-flex>
                    <v-flex xs2>
                      <v-tooltip top>
                        <span slot="activator">
                          <span
                            v-if="item.recept.blo_ciarovy_kod !== ''"
                            class="green--text"
                            >BLO</span
                          >
                          <template v-else>
                            <span v-if="item.recept.je_blo" class="red--text"
                              >BLO</span
                            >
                          </template>
                        </span>
                        <span>
                          <span v-if="item.recept.blo !== undefined">{{
                            item.recept.blo.error_msg
                          }}</span>
                          <span v-else>{{ $vuetify.t("Stav") }}</span>
                        </span>
                      </v-tooltip>
                      <span
                        v-if="item.recept.je_stornovany"
                        class="red--text pl-1"
                        >STORNO</span
                      >
                      <span
                        v-if="item.recept.je_zneplatneny"
                        class="red--text pl-1"
                        >ZNEPLATNENÝ</span
                      >
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-list-tile-content>
            </v-list-tile>
            <v-divider
              :key="'divider' + index"
              v-if="index !== items.length - 1"
            ></v-divider>
          </template>
        </v-list>
      </v-card>
    </v-flex>
    <v-flex
      class="text-xs-right"
      xs12
      v-if="!showAllItems && items.length > 10"
    >
      <v-btn
        color="primary"
        @click.prevent="showAllItems = !showAllItems"
        align-self-end
        >{{ $vuetify.t("Zobraziť všetky") }}(+{{ items.length - 10 }})</v-btn
      >
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  data: function () {
    return {
      showAllItems: false,
    }
  },
  methods: {
    addItem: function () {
      this.$emit("addItem")
    },
    editItem: function (item) {
      this.$emit("editItem", item)
    },
    isIPL: function (item) {
      let ret = false
      if (
        item.recept.zlozenie_ipl !== "" ||
        item.recept.pokyny_pri_vyrobe_ipl !== "" ||
        item.recept.pokyn_k_uzivaniu_ipl !== ""
      ) {
        ret = true
      }
      return ret
    },
  },
  mounted: function () {},
}
</script>
