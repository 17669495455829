<template>
  <v-container fluid px-0>
    <v-layout row>
      <v-flex xs1>
        <v-tooltip top>
          <span slot="activator"
            >{{ reakcia.date | formatDatum }}
            <template v-if="reakcia.neziaduca_reakcia.datum_ukoncenia !== null"
              >-
              {{
                reakcia.neziaduca_reakcia.datum_ukoncenia | formatDatum
              }}</template
            ></span
          >
          <span>{{ $vuetify.t("Dátum spozorovania") }}</span>
        </v-tooltip>
      </v-flex>
      <v-flex xs2>
        <v-tooltip top v-if="reakcia.neziaduca_reakcia.typ_reakcie !== null">
          <span slot="activator">{{
            reakcia.neziaduca_reakcia.typ_reakcie.display_name
          }}</span>
          <span>{{ $vuetify.t("Typ reakcie") }}</span>
        </v-tooltip>
      </v-flex>
      <v-flex xs2>
        <v-tooltip top v-if="reakcia.neziaduca_reakcia.liecivo !== null">
          <span slot="activator">{{
            reakcia.neziaduca_reakcia.liecivo.display_name
          }}</span>
          <span>{{ $vuetify.t("Alergén: Liečivo") }}</span>
        </v-tooltip>
      </v-flex>
      <v-flex xs2>
        <v-tooltip top v-if="reakcia.neziaduca_reakcia.alergen !== null">
          <span slot="activator">{{
            reakcia.neziaduca_reakcia.alergen.display_name
          }}</span>
          <span>{{ $vuetify.t("Alergén: Látka") }}</span>
        </v-tooltip>
      </v-flex>
      <v-flex xs2>
        <v-tooltip top v-if="reakcia.neziaduca_reakcia.typ_alergie !== null">
          <span slot="activator">{{
            reakcia.neziaduca_reakcia.typ_alergie.display_name
          }}</span>
          <span>{{ $vuetify.t("Prejav: Typ alergie") }}</span>
        </v-tooltip>
      </v-flex>
      <v-flex xs1>
        <v-tooltip top v-if="reakcia.neziaduca_reakcia.diagnoza !== null">
          <span slot="activator">{{
            reakcia.neziaduca_reakcia.diagnoza.skratka
          }}</span>
          <span>{{
            $vuetify.t(
              "Prejav: Diagnóza - " +
                reakcia.neziaduca_reakcia.diagnoza.display_name
            )
          }}</span>
        </v-tooltip>
      </v-flex>
      <!-- <v-flex xs2 class="text-truncate"> -->
      <!--   <v-tooltip top> -->
      <!--     <span slot="activator">{{ reakcia.neziaduca_reakcia.poznamka }}</span> -->
      <!--     <span>{{ $vuetify.t("Poznámka") }}</span> -->
      <!--   </v-tooltip> -->
      <!-- </v-flex> -->
      <v-flex xs2>
        <EhealthRecord
          v-model="reakcia.ehealth_record"
          :objectID="reakcia.id"
          :statusTextPrefix="`$vuetify.enum.ehealthRecord.versionStatus.${
            reakcia.ehealth_record ? reakcia.ehealth_record.version_status : ''
          }`"
          table
          compact
        ></EhealthRecord>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import EhealthRecord from "@/components/EhealthRecord.vue"
export default {
  name: "NeziaducaReakciaRow",
  components: { EhealthRecord },
  props: {
    reakcia: {
      type: Object,
      required: true,
    },
  },
}
</script>
