<template>
  <CiselnikTemplate
    cisItemsUrl="/api/codebook/zdrpomocky"
    :headers="headers"
    @click:item="clickedItem"
    sortBy="Nazov"
    title="Zdravotnícke pomôcky"
  >
    <template slot="dialog">
      <zdr-pom-item-dialog ref="zdrPomItemDialog"></zdr-pom-item-dialog>
    </template>
    <template slot="item" slot-scope="props">
      <td>{{ props.item.Kod }}</td>
      <td>{{ props.item.Nazov }}</td>
    </template>
  </CiselnikTemplate>
</template>

<script>
import CiselnikTemplate from "../../components/ciselnikComponents/CiselnikTemplate"
import ZdrPomItemDialog from "../../components/ciselnikComponents/ZdrPomItemDialog"

export default {
  components: { ZdrPomItemDialog, CiselnikTemplate },
  data() {
    return {
      headers: [
        {
          text: "Kód",
          align: "left",
          sortable: true,
          value: "Kod",
          width: "10%",
        },
        {
          text: "Názov",
          value: "Nazov",
          align: "left",
          sortable: true,
        },
      ],
    }
  },
  methods: {
    clickedItem: function (item) {
      this.$refs.zdrPomItemDialog.show(item)
    },
  },
}
</script>

<style scoped></style>
