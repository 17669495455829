<!-- v-select, do kt. sa naplnia položky z enumu v SK.js -->
<template>
  <div :data-vv-as="label">
    <v-select
      ref="field"
      :label="label"
      :items="getEnum"
      :disabled="disabled"
      :value="value"
      :name="`new-${name}`"
      :error-messages="errorMessages || errors.collect(name)"
      @input="updateValue"
      clearable
    >
      <template v-slot:selection="{ item }">
        <slot name="selection" :item="item">
          <span>{{ item.value }} - {{ item.text }}</span>
        </slot>
      </template>
      <template v-slot:item="{ item }">
        <slot name="item" :item="item">
          <v-list-tile-content>
            <v-list-tile-title v-html="item.value"></v-list-tile-title>
            <v-list-tile-sub-title v-html="item.text"></v-list-tile-sub-title>
          </v-list-tile-content>
        </slot>
      </template>
    </v-select>
  </div>
</template>

<script type="text/babel">
import * as SK from "../../plugins/sk.js"
export default {
  name: "SelectEnum",
  inject: ["$validator"],
  props: {
    enumName: {
      type: [String],
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    errorMessages: {
      type: [String, Array],
      required: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getEnum: function () {
      let enumObj = SK.enum[this.enumName]
      let enumArray = Array()
      for (const [key, value] of Object.entries(enumObj)) {
        console.log(`${key}: ${value}`)
        enumArray.push({
          value: key,
          text: value,
        })
      }

      return enumArray
    },
  },
  methods: {
    focus: function () {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.textField.$refs.input.focus()
        }, 0)
      })
    },
    updateValue: function (value) {
      this.$emit("input", value)
    },
  },
}
</script>
