import * as Miscella from "../../miscella.js"

export default {
  computed: {},
  methods: {
    _getThingType: function (dekurz) {
      if (Miscella.isSet(dekurz.vysetrenie)) {
        return "ThingVysetrenie"
      }
      if (Miscella.isSet(dekurz.neziaduca_reakcia)) {
        return "ThingNeziaducaReakcia"
      }
      if (Miscella.isSet(dekurz.diagnoza)) {
        return "ThingDiagnoza"
      }
      if (Miscella.isSet(dekurz.ockovanie)) {
        return "ThingOckovanie"
      }
      if (Miscella.isSet(dekurz.odporucanie)) {
        return "ThingOdporucanie"
      }
      if (Miscella.isSet(dekurz.recept)) {
        return "ThingRecept"
      }
      if (Miscella.isSet(dekurz.medikacia)) {
        return "ThingMedikacia"
      }
      if (Miscella.isSet(dekurz.zdravotnicka_pomocka)) {
        return "ThingZdravotnickaPomocka"
      }
      if (Miscella.isSet(dekurz.krvny_tlak)) {
        return "ThingKrvnyTlak"
      }
      if (Miscella.isSet(dekurz.krvna_skupina)) {
        return "ThingKrvnaSkupina"
      }
      if (Miscella.isSet(dekurz.vit_antrop_hodnoty)) {
        return "ThingVitAntropHodnoty"
      }
      if (Miscella.isSet(dekurz.chirurgicky_vykon)) {
        return "ThingChirurgickyVykon"
      }
      if (Miscella.isSet(dekurz.binary)) {
        return "ThingBinary"
      }
      if (Miscella.isSet(dekurz.restrikcia)) {
        return "ThingRestrikcia"
      }
      if (Miscella.isSet(dekurz.form_data)) {
        return "ThingForm"
      }
      if (Miscella.isSet(dekurz.vykon)) {
        return "ThingVykon"
      }
      return "unknown"
    },
    _deleteThing: async function (thing) {
      var type = this._getThingType(thing)
      var res = await this.$confirm(
        this.$vuetify.t(`$vuetify.thing.type.${type}.delete.confirm`)
      )
      if (!res) {
        return false
      }
      try {
        var result = await this.$api.thingService.deleteThing({
          thing_id: thing.id,
        })
        this.notifySuccess(`$vuetify.thing.type.${type}.delete.success`)
        return result
      } catch (err) {
        this.processError(err, `$vuetify.thing.type.${type}.delete.error`)
        return false
      }
    },
  },
}
