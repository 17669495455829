<template>
  <v-layout row wrap>
    <v-tooltip top v-if="!expanded">
      <span slot="activator" style="cursor: pointer; width: 24px">
        <v-icon :color="!isValid ? 'red' : ''">{{ iconName }}</v-icon>
      </span>
      <span
        style="cursor: pointer"
        v-html="[description, valueText].join(' - ')"
      >
      </span>
    </v-tooltip>
    <v-tooltip top v-if="expanded">
      <span style="cursor: pointer" slot="activator">
        <v-icon :color="!isValid ? 'red' : ''" style="width: 24px">{{
          iconName
        }}</v-icon>
        <span v-if="html" v-html="valueText" style="font-weight: bold"> </span>
        <span v-else style="font-weight: bold"> {{ valueText }} </span>
      </span>
      <span style="cursor: pointer" v-html="description"> </span>
    </v-tooltip>
    <slot name="extraText"></slot>
  </v-layout>
</template>

<script>
import * as Miscella from "../miscella.js"

export default {
  props: {
    iconName: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: false,
    },
    expanded: {
      type: Boolean,
      required: false,
      default: false,
    },
    html: {
      type: Boolean,
      required: false,
      default: false,
    },
    valid: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data: function () {
    return {}
  },
  computed: {
    isValid: function () {
      return this.valid && !Miscella.isEmpty(this.value)
    },
    valueText: function () {
      return Miscella.isEmpty(this.value) ? "Nezadané" : this.value
    },
  },
  methods: {},
}
</script>
