<template>
  <v-list dense class="elevation-1">
    <template v-for="(item, index) in items">
      <v-subheader :key="'title' + index" v-if="index === 0">
        <span class="caption">{{ $vuetify.t("Medikácie") }}</span>
      </v-subheader>
      <v-list-tile :key="'list' + index" @click="$emit('edit', item)">
        <v-list-tile-content>
          <v-container fluid class="px-2">
            <v-layout row>
              <v-flex xs2>
                <v-tooltip top>
                  <span slot="activator">{{ item.date | formatDatum }}</span>
                  <span>{{ $vuetify.t("Dátum") }}</span>
                </v-tooltip>
              </v-flex>
              <v-flex xs10 class="text-truncate">
                <v-tooltip top>
                  <span slot="activator">{{ item.medikacia.popis }}</span>
                  <span>{{ $vuetify.t("Popis") }}</span>
                </v-tooltip>
              </v-flex>
            </v-layout>
          </v-container>
        </v-list-tile-content>
        <v-list-tile-action
          d-flex
          style="flex-direction: row; justify-content: flex-end"
        >
          <template
            v-for="(action, index) in [
              {
                text: 'Odstrániť medikáciu',
                icon: 'delete',
                event: 'delete',
                disabled: disabled,
              },
            ]"
          >
            <v-tooltip left :key="'action' + index">
              <v-icon
                slot="activator"
                @click.stop="$emit(action.event, item)"
                :disabled="action.disabled"
                >{{ action.icon }}</v-icon
              >
              <span>{{ $vuetify.t(action.text) }}</span>
            </v-tooltip>
          </template>
        </v-list-tile-action>
      </v-list-tile>
      <v-divider
        :key="'divider' + index"
        v-if="index !== items.length - 1"
      ></v-divider>
    </template>
  </v-list>
</template>

<script>
export default {
  name: "ListMedikacie",
  props: {
    items: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>
