<template>
  <span>
    {{ myTimeShow }}
  </span>
</template>
<script>
import moment from "moment"
export default {
  props: {
    myTime: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      myTimeShow: "",
    }
  },
  methods: {
    //ukazuje rozdiel ako 14:59:59, ale max 24 hodin
    updateMyTimeShow: function () {
      let createdTime = moment(this.myTime)
      let now = moment()
      let diff = now.diff(createdTime)
      this.myTimeShow = moment.utc(diff).format("HH:mm:ss")
    },
    //ukazuje rozdiel ako "pred par sekundami", "pred hodinou", atd.
    updateMyTimeShowAgo: function () {
      let createdTime = moment(this.myTime)
      this.myTimeShow = createdTime.fromNow()
    },
  },
  mounted: function () {
    this.updateMyTimeShowAgo()
    setInterval(this.updateMyTimeShowAgo.bind(this), 1000)
  },
}
</script>
