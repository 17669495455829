<template>
  <v-container fluid grid-list-md class="pa-0">
    <v-layout row wrap>
      <v-flex xs12 v-if="hasAnamneza">
        <titled-text
          title="Anamnéza"
          :text="vysetrenie.Anamneza.originalText"
        />
      </v-flex>
      <v-flex xs12 v-if="hasEpikriza">
        <titled-text
          title="Epikríza"
          :text="vysetrenie.Epikriza.originalText"
        />
      </v-flex>
      <v-flex xs12 v-if="hasObjektivnyNalez">
        <titled-text
          title="Objektívny nález"
          :text="vysetrenie.ObjektivnyNalez.originalText"
        />
      </v-flex>
      <v-flex xs12 v-if="hasOdporucanie">
        <titled-text
          title="Odporúčanie"
          :text="vysetrenie.Odporucanie.originalText"
        />
      </v-flex>
      <v-flex xs12 v-if="hasTerajsieOchorenie">
        <titled-text
          title="Terajšie ochorenie"
          :text="vysetrenie.TerajsieOchorenie.originalText"
        />
      </v-flex>
      <v-flex xs12>
        <ezdravie-textovy-popis
          title="Výsledok vykonaného vyšetrenia"
          v-model="vysetrenie.VysledokVykonanehoVysetrenia"
        />
      </v-flex>
      <v-flex xs12 v-if="hasZaverPriPrijati">
        <titled-text
          title="Záver pri prijatí"
          :text="vysetrenie.ZaverPriPrijati.originalText"
        />
      </v-flex>
      <v-flex xs12 v-if="vysetrenie.OperacneVykony">
        <v-subheader class="caption">
          {{ $vuetify.t("Operačné výkony") }}
        </v-subheader>
        <v-data-table
          :headers="[
            {
              text: 'Popis',
              value: 'popis',
              sortable: false,
            },
          ]"
          :items="vysetrenie.OperacneVykony"
          class="elevation-4"
          hide-actions
        >
          <template v-slot:items="{ item }">
            <td>{{ item.Popis.originalText }}</td>
          </template>
        </v-data-table>
      </v-flex>
      <v-flex xs12 v-if="vysetrenie.OdbornyZastupca">
        <v-subheader class="caption">{{
          $vuetify.t("Odborný zástupca")
        }}</v-subheader>
        <v-container fluid grid-list-xs class="pa-0">
          <v-layout row wrap>
            <v-flex xs6>
              <titled-text title="Meno">
                <ezdravie-zdravotnicky-pracovnik
                  :ii="vysetrenie.OdbornyZastupca.IdentifikaciaZPrac"
                />
              </titled-text>
            </v-flex>
            <v-flex xs12 md6>
              <titled-text
                title="Špecializácia"
                :text="
                  vysetrenie.OdbornyZastupca.SpecializaciaLekara.DisplayName
                "
              />
            </v-flex>
            <v-flex xs12 md6>
              <titled-text title="OÚ PZS">
                <ezdravie-ou-pzs
                  :ii="vysetrenie.OdbornyZastupca.IdentifikaciaOupzs"
                />
              </titled-text>
            </v-flex>
            <v-flex xs12 md6>
              <titled-text
                title="Nezmluvný lekár"
                :text="
                  $vuetify.t(
                    vysetrenie.OdbornyZastupca.NezmluvnyLekar ? 'Áno' : 'Nie'
                  )
                "
              />
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import TitledText from "./TitledText.vue"
import EzdravieTextovyPopis from "./EzdravieTextovyPopis.vue"
import * as Miscella from "./../../miscella.js"
import EzdravieZdravotnickyPracovnik from "../ezdravie/EZdravieZdravotnickyPracovnik.vue"
import EzdravieOuPzs from "../ezdravie/EZdravieOuPzs.vue"

export default {
  props: {
    vysetrenie: {
      type: Object,
      required: true,
    },
  },
  components: {
    TitledText,
    EzdravieTextovyPopis,
    EzdravieZdravotnickyPracovnik,
    EzdravieOuPzs,
  },
  data: function () {
    return {}
  },
  computed: {
    hasAnamneza: function () {
      return Miscella.isSet(this.vysetrenie.Anamneza)
    },
    hasZaverPriPrijati: function () {
      return Miscella.isSet(this.vysetrenie.ZaverPriPrijati)
    },
    hasEpikriza: function () {
      return Miscella.isSet(this.vysetrenie.Epikriza)
    },
    hasObjektivnyNalez: function () {
      return Miscella.isSet(this.vysetrenie.ObjektivnyNalez)
    },
    hasOdporucanie: function () {
      return Miscella.isSet(this.vysetrenie.Odporucanie)
    },
    hasTerajsieOchorenie: function () {
      return Miscella.isSet(this.vysetrenie.TerajsieOchorenie)
    },
  },
}
</script>
