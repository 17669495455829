import moment from "moment"
import * as Miscella from "../miscella.js"
import Utils from "./utils.js"

export class VysetrenieFactoryV3 {
  constructor(ouPzs, lekar, vysetrenie) {
    this.ouPzs = ouPzs
    this.lekar = lekar
    this.vysetrenie = vysetrenie
    this.diagnostickyZaver = []
    this.diagnozaPriOdoslani = []
    this.odporucaneVysetrenie = []
    this.timeCreated = moment().format()
    this.dispenzarizacia = false
    this.ehrSystemExtension =
      "AXON_ORDINA_v_1_0_D5620EB156F047BFA1D04F96E16E58C4"

    if (Miscella.isSet(vysetrenie.Things)) {
      for (let thing of vysetrenie.Things) {
        this.processThing(thing)
      }
    }
  }

  processThing(thing) {
    switch (thing.ThingType) {
      case "ThingDiagnoza":
        this.diagnostickyZaver.push(
          this.asDiagnostickyZaver(
            thing.Thing,
            this.vysetrenie.HlavnaDiagnozaID
          )
        )
        if (thing.Thing.Dispenzarna) {
          this.dispenzarizacia = true
        }
        break
      case "ThingOdporucanie":
        this.odporucaneVysetrenie.push(
          this.asOdporucanieNaVysetrenie(thing.Thing)
        )
        break
      case "ThingEZdravieOdborneVysetrenie":
        if (Miscella.isSet(thing.Thing.OdporucaneVysetrenie)) {
          for (let ov of thing.Thing.OdporucaneVysetrenie) {
            this.diagnozaPriOdoslani.push(ov.Diagnoza)
          }
        }
        break
    }
  }

  get sensitivity() {
    switch (this.ouPzs.zameranie.Skratka) {
      case "144":
      case "005":
      case "074":
      case "105":
      case "223":
      case "352":
      case "073":
      case "367":
        return 5
      default:
        return 3
    }
  }

  get zaznamOVysetreniBase() {
    return {
      Typ: this.vysetrenie.Typ,
      EhrSystemExtension: this.ehrSystemExtension,
      TimeCreated: this.timeCreated,
      RcIdExtension: this.vysetrenie.EVysetrenie.ExtID,
      Sensitivity: this.sensitivity,
      FeederAudit: {
        Ehr_system: {
          Extension: this.ehrSystemExtension,
          Root: "",
        },
        Time_committed: this.timeCreated,
        Committer: Utils.asII(this.lekar.jruzId, "40.90"),
        Version_status: "",
      },
      InfoProvider: {
        Function: Utils.asCV(this.ouPzs.zameranie, "39"),
        Performer: Utils.asII(this.lekar.jruzId, "40.90"),
        HealthcareFacillity: Utils.asII(this.ouPzs.jruzId, "40.70"),
      },
      DiagnostickyZaver:
        this.diagnostickyZaver.length > 0 ? this.diagnostickyZaver : null,
      DatumACasOdoslania: this.timeCreated,
      OdosielajuciLekar: {
        IdentifikaciaZPrac: Utils.asII(this.lekar.jruzId, "40.90"),
        IdentifikaciaOupzs: Utils.asII(this.ouPzs.jruzId, "40.70"),
        SpecializaciaLekara: Utils.asCV(this.ouPzs.zameranie, "10.39", 1),
        NezmluvnyLekar: true,
      },
      UrgentnostVysetrenia: Utils.asCV(
        this.vysetrenie.UrgentnostVysetrenia,
        "123"
      ),
      PoziadavkaNaVysetrenie: Utils.asII(
        this.vysetrenie.EVysetrenie.PoziadavkaNaVysetrenie,
        "60.110"
      ),
    }
  }

  asDiagnostickyZaver(thingDiagnoza, hlavnaDiagnozaID) {
    return {
      HlavnaDiagnoza: thingDiagnoza.ID === hlavnaDiagnozaID,
      Diagnoza: Utils.asDiagnoza(thingDiagnoza),
    }
  }

  asOdporucanieNaVysetrenie(thingOdporucanie) {
    return {
      DruhSpecializovanehoUtvaru: Utils.asCV(
        thingOdporucanie.DruhSpecializovanehoUtvaru,
        "37"
      ),
      OdborneZameranie: Utils.asCV(thingOdporucanie.OdborneZameranie, "39"),
      PozadovaneVysetrenie: thingOdporucanie.PozadovaneVysetrenie,
      InformaciaPrePacienta: thingOdporucanie.InformaciaPrePacienta,
      UrgentnostVymennehoListku: Utils.asCV(thingOdporucanie.Urgentnost, "123"),
      IdentifikatorVymennehoListku: Utils.asII(
        thingOdporucanie.ExtID,
        "60.110"
      ),
      Platnost: moment(this.timeCreated)
        .add(1, "y")
        .subtract(1, "d")
        .endOf("day")
        .format(),
      Vysledok: null,
      Extract: null,
      // Extract: [{
      //   // DatumVznikuZaznamu: moment(odporucanie.Created).format(),
      //   DatumVznikuZaznamu: timeCreated,
      //   Oid: '1.3.158.00165387.100.60.90'
      // }]
    }
  }

  get zaznamOOdbornomVysetreni() {
    var paramsVysetrenie = this.zaznamOVysetreniBase
    paramsVysetrenie.Popis = this.vysetrenie.Popis
    paramsVysetrenie.DatumACasVysetrenia = this.timeCreated

    paramsVysetrenie.Dispenzarizacia = this.dispenzarizacia
    paramsVysetrenie.DiagnozaPriOdoslani =
      this.diagnozaPriOdoslani.length > 0 ? this.diagnozaPriOdoslani : null
    paramsVysetrenie.OdporucaneVysetrenie =
      this.odporucaneVysetrenie.length > 0 ? this.odporucaneVysetrenie : null
    return paramsVysetrenie
  }

  get zaznamOZobrazovacomVysetreni() {
    var paramsVysetrenie = this.zaznamOVysetreniBase
    paramsVysetrenie.Popis = this.vysetrenie.Popis
    paramsVysetrenie.DatumACasVysetrenia = this.timeCreated
    paramsVysetrenie.DiagnozaPriOdoslani =
      this.diagnozaPriOdoslani.length > 0 ? this.diagnozaPriOdoslani : null
    paramsVysetrenie.ModalitaZobrazovaciehoVysetrenia =
      this.vysetrenie.EVysetrenie.ModalitaZobrazovaciehoVysetrenia
    return paramsVysetrenie
  }
}

export class VysetrenieFactoryV5 extends VysetrenieFactoryV3 {
  constructor(ouPzs, lekar, vysetrenie) {
    super(ouPzs, lekar, vysetrenie)
    this.alergen = []
  }

  processThing(thing) {
    switch (thing.ThingType) {
      case "ThingRecept":
        // TODO dorobit medikacie
        break
      case "ThingAlergia":
        this.alergen.push({
          // TODO prerobit aj v ThingAlergia?
          // Liecivo: Utils.asCV(thing.Thing., '83'),
          Latka: Utils.asCV(thing.Thing.Alergen, "158"),
        })
        break
    }
  }

  asDiagnostickyZaver(thingDiagnoza, hlavnaDiagnozaID) {
    return {
      ...super.asDiagnostickyZaver(thingDiagnoza, hlavnaDiagnozaID),
      ZivotOvplyvnujucaDiagnoza: thingDiagnoza.ZivotOvplyvnujucaDiagnoza,
    }
  }

  asOdporucanieNaVysetrenie(thingOdporucanie) {
    return {
      ...super.asOdporucanieNaVysetrenie(thingOdporucanie),
      Diagnoza: Utils.asCV(thingOdporucanie.Diagnoza.Diagnoza, "25"),
      VysetrovanyOrgan: "",
      ModalitaZobrazovaciehoVysetrenia: "",
      PotrebaSedacie: false,
    }
  }

  get zaznamOVysetreniBase() {
    return {
      ...super.zaznamOVysetreniBase,
      NazovVysetrenia: this.vysetrenie.EVysetrenie.NazovVysetrenia,
      InternaPoznamka: this.vysetrenie.EVysetrenie.InternaPoznamka,
    }
  }

  get zaznamOOdbornomVysetreni() {
    return {
      ...super.zaznamOOdbornomVysetreni,
      PredpokladanyDatumPorodu:
        this.vysetrenie.EVysetrenie.PredpokladanyDatumPorodu,
    }
  }

  get zaznamOZobrazovacomVysetreni() {
    return {
      ...super.zaznamOZobrazovacomVysetreni,
      ModalitaZobrazovaciehoVysetrenia:
        this.vysetrenie.EVysetrenie.ModalitaZobrazovaciehoVysetrenia,
    }
  }
}
