import moment from "moment"

export var mixins = {
  data: function () {
    return {
      diagnozaDialogTitle: "",
    }
  },
  methods: {
    addDiagnoza: function () {
      this.$refs.diagnozaForm.new()
      this.diagnozaDialogTitle = "Nová diagnóza"
      this.$refs.diagnozaDialog.open()
    },
    editDiagnoza: function (aDiagnoza) {
      this.$refs.diagnozaForm.edit(aDiagnoza)
      this.diagnozaDialogTitle = "Úprava diagnózy"
      this.$refs.diagnozaDialog.open()
    },
    savedDiagnoza: function () {
      this.fetchData()
    },
    deletedDiagnoza: function () {
      console.log("deleted dg 1")
    },
    endDiagnoza: function (dg) {
      if (dg.diagnoza.datum_ukoncenia !== null) {
        return Promise.resolve()
      } else {
        let datumVytvorenia = moment(dg.date)
        let datumUkoncenia = moment()
        if (datumUkoncenia.isBefore(datumVytvorenia, "day")) {
          this.$store.commit(
            "setError",
            this.$vuetify.t(
              "Dátum ukončenia nemôže predchádzať dátumu vytvorenia."
            )
          )
          return Promise.resolve()
        }
      }
      return this.$confirm(
        this.$vuetify.t("Naozaj si želáte ukončiť diagnózu?")
      ).then((res) => {
        if (res) {
          var params = { thing: dg }
          params.thing.diagnoza.datum_ukoncenia = moment().format()
          this.$api.thingService
            .saveThing(params)
            .then((result) => {
              this.$store.commit(
                "setSuccess",
                this.$vuetify.t("Diagnóza bola úspešne ukončená")
              )
              this.fetchData()
            })
            .catch((err) => {
              this.$store.commit(
                "setError",
                this.$vuetify.t("Pri ukončovaní diagnózy vznikla chyba ") +
                  " (" +
                  err +
                  ")."
              )
            })
        }
      })
    },
  },
}
