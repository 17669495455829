import Vue from "vue"
import Vuetify from "vuetify"
import sk from "./sk.js"
import "@/stylus/main.styl"
// import "vuetify/dist/vuetify.min.css"

Vue.use(Vuetify, {
  customProperties: true,
  iconfont: "md",
  lang: {
    locales: { sk: sk },
    current: "sk",
  },
  /*,
   */
})
