import moment from "moment"

export default class EZdravieLog {
  static logOffline(
    api,
    service,
    request,
    { objectId = "", patientId = "" } = {}
  ) {
    var now = moment().format()
    var params = {
      Created: now,
      ObjectID: objectId,
      PatientID: patientId,
      Service: service,
      Request: request,
    }
    return api.post("/api/eZdravieLog", params)
  }

  static logSent(
    api,
    service,
    request,
    { objectId = "", patientId = "" } = {}
  ) {
    var now = moment().format()
    var params = {
      Created: now,
      Sent: now,
      ObjectID: objectId,
      PatientID: patientId,
      Service: service,
      Request: request,
    }
    return api.post("/api/eZdravieLog", params)
  }

  static logReceived(api, logID, response, isError = false) {
    var now = moment().format()
    var params = {
      ID: logID,
      Received: now,
      Response: response,
      IsError: isError,
    }
    return api.post("/api/eZdravieLog", params)
  }
}
