<template>
  <v-container fluid @drop="uploadFile">
    <ApiTable
      :headers="headers"
      ref="table"
      :url="$api.thingService.listThingsUrl"
      :customParams="getParams()"
      :title="$vuetify.t('Súbory')"
      v-bind:style="{
        opacity: dragging ? '0.6' : '1',
      }"
      @drop.native="uploadFile"
      @dragenter.native="dragEnter"
      @dragover.native="dragEnter"
      @dragleave.native="dragLeave"
    >
      <template slot="toolbarItems">
        <v-tooltip top>
          <v-btn icon slot="activator" @click="addSubor">
            <v-icon>add</v-icon>
          </v-btn>
          <span>{{ $vuetify.t("Pridať") }}</span>
        </v-tooltip>
      </template>
      <template slot="items" slot-scope="props">
        <tr @click="openWindow(props.item.binary.url)">
          <!-- <td class="text-md-left">
            <v-icon small class="mr-2">{{ getFileIcon(props.item) }}</v-icon>
            <span>{{ props.item.binary.name }}</span>
          </td> -->
          <td class="text-md-left">
            {{ props.item.binary.name }}
          </td>
          <td class="text-md-left">
            {{ props.item.date | formatDatum }}
          </td>
          <td class="text-md-right text-no-wrap">
            {{ humanFileSize(props.item.binary.size) }}
          </td>
          <td class="text-md-left">
            {{ props.item.binary.poznamka }}
          </td>
          <!-- <td class="text-md-left">
            <template v-if="editIndex == props.item.id">
              <div class="v-text-field" style="margin: 0; padding: 0">
                <input
                  type="text"
                  style="margin: 0; padding: 0"
                  v-model="editItem.Desc"
                  @keydown.enter="updateItem"
                  @keydown.esc="cancelEditingItem"
                  :id="'item-' + props.item.id"
                  maxlength="255"
                  @blur="cancelEditingItem"
                />
              </div>
            </template>
            <template v-else>
              <v-tooltip bottom>
                <div
                  class="v-text-field"
                  style="margin: 0; padding: 0"
                  slot="activator"
                >
                  <input
                    type="text"
                    style="margin: 0; padding: 0"
                    v-model="props.item.Desc"
                    maxlength="255"
                    readonly
                    @click.stop="startEditing(props.item)"
                  />
                </div>
                <span>{{ props.item.Desc }}</span>
              </v-tooltip>
            </template>
          </td> -->
          <td class="text-md-right">
            <v-tooltip left>
              <v-icon
                slot="activator"
                @click.stop="
                  downloadSubor(props.item.binary.name, props.item.binary.url)
                "
                >get_app</v-icon
              >
              <span>{{ $vuetify.t("Stiahnuť") }}</span>
            </v-tooltip>
            <v-tooltip left>
              <v-icon slot="activator" @click.stop="deleteSubor(props.item)"
                >delete</v-icon
              >
              <span>{{ $vuetify.t("Vymazať") }}</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </ApiTable>
    <BaseDialog
      ref="dialog"
      :dialogTitle="dialogTitle"
      @save="$refs.form.saveForm()"
    >
      <FormSubor ref="form" :patientId="id" @saved="savedSubor"></FormSubor>
    </BaseDialog>
  </v-container>
</template>

<script>
import ApiTable from "../../components/ApiTable.vue"
import BaseDialog from "./../../components/BaseDialog.vue"
import FormSubor from "../../components/forms/FormSubor.vue"
import { getClassNameForExtension } from "font-awesome-filetypes"
import * as subory from "../../components/mixins/subory.js"
import thingMixin from "@/components/mixins/thing.js"

export default {
  components: {
    ApiTable,
    BaseDialog,
    FormSubor,
  },
  mixins: [subory.mixins, thingMixin],
  props: ["id"],
  computed: {
    binariesUrl() {
      return "/api/patient/" + this.id + "/binaries"
    },
  },
  methods: {
    getParams: function () {
      return {
        patient_id: this.id,
        binary: {},
      }
    },
    addSubor: function () {
      this.$refs.form.newSubor()
      this.dialogTitle = "Nový súbor"
      this.$refs.dialog.open()
    },
    savedSubor: function () {
      this.$refs.dialog.close()
      this.$refs.table.reload()
    },
    dragEnter: function (e) {
      e.preventDefault()
      e.stopPropagation()
      this.dragging = true
    },
    dragLeave: function (e) {
      e.preventDefault()
      e.stopPropagation()
      this.dragging = false
    },
    uploadFile: function (e) {
      this.dragging = false
      e.preventDefault()
      e.stopPropagation()

      var files = e.dataTransfer.files

      var formData = new FormData()
      formData.append("ID", "")
      formData.append("desc", "")
      formData.append("patientid", this.id)
      formData.append("file", files[0])

      this.$api
        .postBinary("/api/patient/" + this.id + "/binary", formData)
        .then(this.uploadOK)
        .catch(this.uploadError)
    },
    uploadOK: function () {
      this.$refs.table.reload()
    },
    uploadError: function () {
      this.$refs.table.reload()
      this.$store.commit(
        "setError",
        this.$vuetify.t("Nastala chyba pri nahrávaní súboru.")
      )
    },
    getFileIcon: function (binary) {
      return getClassNameForExtension(binary.split(".").pop())
    },
    deleteSubor: async function (binary) {
      try {
        console.log(binary)
        await this._deleteThing(binary)
        await this.$api.deleteBinary(binary.binary.url)
        this.notifySuccess("Súbor bol vymazaný")
        this.$refs.table.reload()
      } catch (err) {
        this.processError(err, "Pri odstraňovaní súboru vznikla chyba")
      }
      // await this._deleteThing(binary) // from thing mixin
      // await this.$api.delete(binary.url)

      // try {
      //   await this.$api.delete(

      //   )
      //   this.$refs.table.reload()

      //   this.$store.commit("setSuccess", this.$vuetify.t("Súbor bol vymazaný."))
      // } catch (error) {
      //   this.$store.commit(
      //     "setError",
      //     this.$vuetify.t("Nastala chyba pri výmaze súboru.")
      //   )
      // }
    },
    // startEditing(item) {
    //   this.editIndex = item.id
    //   this.editItem = item
    //   this.editItemOri = JSON.parse(JSON.stringify(this.editItem))
    //   this.$nextTick(
    //     function () {
    //       console.log("item-" + this.editIndex)
    //       document.getElementById("item-" + this.editIndex).focus()
    //     }.bind(this)
    //   )
    // },
    // updateItem() {
    //   this.saveSubor().then(
    //     function () {
    //       console.log("003")
    //       this.editIndex = ""
    //       this.editItemOri = {}
    //       this.editItem = {}
    //     }.bind(this)
    //   )
    // },
    // cancelEditingItem() {
    //   if (this.editIndex !== "") {
    //     this.$refs.table.reload()
    //     this.editIndex = ""
    //     this.editItemOri = {}
    //     this.editItem = {}
    //   }
    // },
    // saveSubor: async function () {
    //   try {
    //     let params = {
    //       ID: this.editItem.id,
    //       Desc: this.editItem.Desc,
    //     }
    //     console.log("001")
    //     return this.$api.post(
    //       "/api/patient/" +
    //         this.id +
    //         "/binary:" +
    //         this.editItem.id +
    //         "/updateDesc",
    //       params
    //     )
    //   } catch (err) {}
    // },
    // saveOK: function () {
    //   console.log("002")
    //   this.$store.commit(
    //     "setSuccess",
    //     this.$vuetify.t("Poznámka bola úspešne uložená.")
    //   )
    // },
    // saveError: function () {
    //   console.log("002b")
    //   this.$refs.table.reload()
    //   this.$store.commit(
    //     "setError",
    //     this.$vuetify.t("Pri vytváraní súboru vznikla chyba.")
    //   )
    // },
    humanFileSize: function (size) {
      if (size == 0) {
        return "0"
      }
      var i = Math.floor(Math.log(size) / Math.log(1024))
      return (
        (size / Math.pow(1024, i)).toFixed(2) * 1 +
        " " +
        ["B", "kB", "MB", "GB", "TB"][i]
      )
    },
  },
  data: function () {
    return {
      dialogTitle: "",
      showInfo: false,
      dragging: false,
      headers: [
        {
          text: "Súbor",
          value: "FileName",
          sortable: false,
          width: "300px",
        },
        {
          text: "Dátum",
          value: "Created",
          sortable: false,
          width: "10rem",
        },
        {
          text: "Veľkosť",
          value: "LengthStr",
          sortable: false,
          width: "10rem",
        },
        {
          text: "Poznámka",
          value: "Desc",
          sortable: false,
          width: "auto",
        },
        {
          text: "",
          value: "",
          sortable: false,
          width: "100px",
        },
      ],
      editIndex: -1,
      editItem: {},
      editItemOri: {},
    }
  },
}
</script>
