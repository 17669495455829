<template>
  <v-form>
    <v-container fluid>
      <v-layout row wrap>
        <v-flex xs12>
          <TextField
            v-model="rodina.PribuzenskyStav"
            :label="$vuetify.t('Rodinný vzťah')"
            name="stav"
            v-validate="'required'"
            :error-messages="errors.collect('stav')"
          ></TextField>
        </v-flex>
        <v-flex xs12>
          <v-textarea
            v-model="rodina.Desc"
            :label="$vuetify.t('Anamnéza')"
            v-validate="'required'"
            data-vv-name="anamneza"
            :data-vv-as="$vuetify.t('Anamnéza')"
            :error-messages="errors.collect('anamneza')"
            auto-grow
          ></v-textarea>
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
import TextField from "./../TextField.vue"
import * as Miscella from "./../../miscella.js"

export default {
  $_veeValidate: {
    validator: "new",
  },
  components: {
    TextField,
  },
  props: {
    pacientId: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      rodina: null,
      itemIndex: -1,
    }
  },
  computed: {
    saveUrl: function () {
      return (
        "/api/patient/" + this.pacientId + "/rodina?index=" + this.itemIndex
      )
    },
  },
  watch: {
    diagnozySearch: function () {
      this.fetchDiagnozy()
    },
  },
  methods: {
    init: function () {
      this.itemIndex = -1
      this.rodina = {
        PribuzenskyStav: "",
        Desc: "",
      }
      this.$validator.reset()
    },
    new: function () {
      this.init()
    },
    edit: function (item, index) {
      this.rodina = Miscella.cloneObject(item)
      this.itemIndex = index
    },
    save: function () {
      return this.$validator.validateAll().then((valid) => {
        if (!valid) {
          return
        }
        var params = Miscella.cloneObject(this.rodina)

        return this.$api
          .post(this.saveUrl, params)
          .then((result) => {
            this.$store.commit(
              "setSuccess",
              this.$vuetify.t("Rodinná anamnéza bola úspešne uložená")
            )
            this.$emit("saved", result)
          })
          .catch((err) => {
            this.$store.commit(
              "setError",
              this.$vuetify.t("Pri ukladaní rodinnej anamnézy vznikla chyba ") +
                " (" +
                err +
                ")."
            )
          })
      })
    },
  },
  created: function () {
    this.init()
  },
}
</script>
