<template>
  <BaseDialog
    :dialogTitle="$vuetify.t('Zdravotnícka pomôcka')"
    ref="dialog"
    :showSaveButton="false"
    @close="closeDialog"
  >
    <v-container grid-list-md>
      <v-layout column wrap>
        <v-text-field
          readonly
          label="Názov"
          v-model="chosen.Nazov"
        ></v-text-field>
        <v-text-field readonly label="Kód:" v-model="chosen.Kod"></v-text-field>
        <v-text-field
          readonly
          label="Doplnok Názvu"
          v-model="chosen.Doplnok"
        ></v-text-field>
        <v-text-field
          readonly
          label="Určenie"
          v-model="chosen.UcelUrcenia"
        ></v-text-field>
        <v-text-field
          readonly
          label="Počet kusov v balení"
          v-model="chosen.PocetKusovVBaleni"
        ></v-text-field>
      </v-layout>
    </v-container>
  </BaseDialog>
</template>

<script>
import BaseDialog from "./../BaseDialog.vue"

export default {
  components: {
    BaseDialog,
  },
  data: function () {
    return {
      chosen: {
        Nazov: "",
      },
    }
  },
  methods: {
    show: function (item) {
      this.chosen = item
      this.$refs.dialog.open()
    },
    closeDialog: function () {
      this.$emit("close", this.chosen)
    },
  },
}
</script>

<style scoped></style>
