<template>
  <v-card
    class="ma-0 pb-0"
    :class="[fullToolbar ? 'elevation-5' : 'elevation-0']"
  >
    <v-toolbar v-if="fullToolbar">
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field
        v-if="hasFilter"
        v-model="filter"
        class="mx-0"
        flat
        hide-details
        prepend-inner-icon="search"
        solo-inverted
        clearable
      >
      </v-text-field>
      <slot name="toolbarItems" />
    </v-toolbar>
    <v-text-field
      v-if="!fullToolbar && hasFilter"
      v-model="filter"
      class="mx-0"
      flat
      hide-details
      append-icon="search"
      clearable
      :label="title"
    >
    </v-text-field>
    <v-card-text>
      <v-chip
        close
        v-for="item in value"
        :key="'selected' + item.ID"
        @input="selectItem(item)"
      >
        <slot name="selectedItems" :item="item">
          {{ item[headers[0].value] }}
        </slot>
      </v-chip>
    </v-card-text>
    <v-data-table
      :headers="
        selectable
          ? [{ text: '', value: 'select-all-header' }].concat(headers)
          : headers
      "
      :items="data.tableData"
      :total-items="totalItems"
      :pagination.sync="pagination"
      :no-data-text="$vuetify.t('Žiadne záznamy')"
      :no-results-text="$vuetify.t('Žiadne záznamy')"
      must-sort
      hide-actions
      @update:pagination="reload"
      :loading="loading"
    >
      <!-- <v-progress-linear v-slot:progress indeterminate></v-progress-linear> -->
      <template v-slot:headers="props">
        <tr>
          <th v-if="selectable">
            <v-checkbox
              v-model="selectedAll"
              primary
              hide-details
              @change="selectAllItems"
            />
          </th>
          <th
            v-for="header in selectable
              ? props.headers.slice(1)
              : props.headers"
            :key="header.text"
            class="column text-xs-left"
          >
            {{ header.text }}
          </th>
        </tr>
      </template>
      <template v-slot:items="props">
        <v-hover>
          <template v-slot="{ hover }">
            <tr
              :active="props.item.selected"
              @click="$emit('click:item', props.item)"
            >
              <td @click.stop="selectItem(props.item)">
                <v-checkbox
                  :input-value="props.item.selected"
                  primary
                  hide-details
                ></v-checkbox>
              </td>
              <slot name="items" :item="props.item" :hover="hover">
                <td
                  class="text-md-left"
                  v-for="header in headers"
                  :key="header.value"
                >
                  {{ props.item[header.value] }}
                </td>
              </slot>
            </tr>
          </template>
        </v-hover>
      </template>
    </v-data-table>
    <div v-if="hasPagination" class="text-xs-center pt-2">
      <v-pagination
        v-model="data.tableInfo.Page"
        :length="data.tableInfo.PageCount"
        circle
        color="secondary"
        @input="reload"
        total-visible="7"
      ></v-pagination>
    </div>
  </v-card>
</template>

<script>
import * as Miscella from "../miscella.js"
import Vue from "vue"

export default {
  props: {
    defaultSortBy: {
      type: String,
      required: false,
      default: "ID",
    },
    defaultSortDesc: {
      type: Boolean,
      required: false,
      default: true,
    },
    url: {
      type: String,
      required: true,
    },
    hasFilter: {
      type: Boolean,
      required: false,
      default: true,
    },
    fullToolbar: {
      type: Boolean,
      required: false,
      default: true,
    },
    customParams: {
      type: Object,
      required: false,
      default: function () {
        return {}
      },
    },
    headers: {
      type: Array,
      required: false,
      default: function () {
        return [
          {
            text: "ID",
            value: "ID",
            align: "left",
            sortable: true,
          },
          {
            text: "Dátum vytvorenia",
            value: "Created",
            align: "left",
            sortable: true,
          },
        ]
      },
    },
    title: {
      type: String,
      required: false,
    },
    selectable: {
      type: Boolean,
      required: false,
      default: false,
    },
    pageSize: {
      type: Number,
      required: false,
      default: 5,
    },
  },
  data: function () {
    return {
      value: [],
      loading: false,
      data: {
        tableInfo: null,
        tableData: [],
      },
      filter: "",
      pagination: {
        sortBy: this.defaultSortBy,
        descending: this.defaultSortDesc,
      },
      selectedAll: false,
    }
  },
  computed: {
    items: function () {
      return this.data.tableData
    },
    hasPagination: function () {
      return (
        Miscella.isSet(this.data.tableInfo) &&
        this.data.tableData.length > 0 &&
        this.data.tableInfo.PageCount > 1
      )
    },
    totalItems: function () {
      return this.hasPagination ? this.data.tableInfo.Count : -1
    },
  },
  watch: {
    filter: function (val) {
      if (!Miscella.isSet(val)) {
        this.filter = ""
      }
      Miscella.myDelay(() => {
        this.reload()
      }, 500)
    },
    customParams: function () {
      this.reload()
    },
  },
  methods: {
    init: function (selectedItems) {
      this.value = []
      this.filter = ""
      this.pagination = {
        sortBy: this.defaultSortBy,
        descending: this.defaultSortDesc,
      }
      this.data.tableInfo.Page = 1
      for (let item of selectedItems) {
        item.selected = true
        this.value.push(Miscella.cloneObject(item))
      }
      this.reload()
    },
    reload: function () {
      this.selectedAll = false
      var params = this.customParams
      params.sortBy = this.pagination.sortBy
      params.sortAsc = !this.pagination.descending
      params.filter = this.filter
      if (this.hasPagination) {
        params.page = this.data.tableInfo.Page
      }
      params.pageSize = this.pageSize
      this.loading = true
      return this.$api
        .get(this.url, params)
        .then(this.setItems)
        .finally(() => {
          Vue.nextTick(() => {
            this.loading = false
          })
          this.$emit("reloaded", this.data.tableData)
          this.reloaded(this.data.tableData)
        })
    },
    setItems: function (data) {
      if (Miscella.isSet(data.tableInfo)) {
        this.data = data
      } else {
        this.data.tableData = data
      }
    },
    updateValue: function (value) {
      this.$emit("input", value)
    },
    isSelectedAll: function () {
      var selectedAll = true
      for (let item of this.items) {
        if (!item.selected) {
          selectedAll = false
        }
      }
      return selectedAll
    },
    selectItem: function (item) {
      item.selected = !item.selected
      if (item.selected) {
        this.value.push(item)
        this.selectedAll = this.isSelectedAll()
      } else {
        this.value.splice(this.value.indexOf(item), 1)
        this.selectedAll = this.isSelectedAll()
      }
      this.$emit("input", this.value)
    },
    selectAllItems: function (checked) {
      for (let item of this.data.tableData) {
        if (checked) {
          if (!item.selected) {
            item.selected = true
            this.value.push(item)
          }
        } else {
          if (item.selected) {
            item.selected = false
            this.value.splice(this.value.indexOf(item), 1)
            this.$emit("input", this.value)
          }
        }
      }
    },
    reloaded: function (items) {
      for (let item of items) {
        var index = this.value.findIndex((i) => i.ID === item.ID)
        item.selected = index >= 0
        if (item.selected) {
          this.value.splice(index, 1, item)
        }
      }
      this.selectedAll = this.isSelectedAll()
      this.$emit("input", this.value)
    },
  },
}
</script>
