<template>
  <div class="fill-height">
    <!-- <v-app id="inspire" :dark="isThemeDark" :light="!isThemeDark"> -->
    <!--   <message-bar></message-bar> -->
    <v-toolbar app clipped-left clipped-right>
      <v-toolbar-title style="padding-right: 10px">
        <router-link to="/home">
          <v-img
            :src="require('../assets/logo.png')"
            class="logo"
            width="210"
            height="34"
          />
        </router-link>
      </v-toolbar-title>
      <!-- TODO zatial zakomentovane, nedokoncena funkcionalita -->
      <!-- <v-btn flat to="/dashboard">{{ $vuetify.t("Ambulancia") }}</v-btn> -->
      <!-- <v-btn flat to="/calendar">{{ $vuetify.t("Kalendár") }}</v-btn> -->
      <v-btn flat to="/patients" v-if="showPatients">{{
        $vuetify.t("Pacienti")
      }}</v-btn>
      <v-btn flat to="/admin" v-if="showAdministration">{{
        $vuetify.t("Administrácia")
      }}</v-btn>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <v-btn flat slot="activator" to="/dashboardUser">
          <span
            style="
              font-size: small;
              max-width: 30em;
              text-overflow: ellipsis;
              overflow: hidden;
            "
          >
            {{ clinicName }} - {{ ambulanceName }}
          </span>
        </v-btn>
        <span>{{ $vuetify.t("Zmena aktuálne vybratej ambulancie") }}</span>
      </v-tooltip>
      <!-- v-menu open-on-hover bottom offset-y>
        <v-btn flat small slot="activator">
          {{ ambulanceName }}
          <v-icon>arrow_drop_down</v-icon>
        </v-btn>
        <v-list>
          <v-list-tile
            v-for="(ambulance, index) in ambulances"
            :key="index"
            @click="ambulanceClicked(ambulance)"
          >
            <v-list-tile-title>{{ ambulance.name }}</v-list-tile-title>
          </v-list-tile>
        </v-list>
      </-->
      <v-menu open-on-hover bottom offset-y>
        <v-btn flat small slot="activator">
          {{ userName }}
          <v-icon>arrow_drop_down</v-icon>
        </v-btn>

        <v-card>
          <v-list>
            <v-list-tile to="/admin/me">
              <v-list-tile-title>{{
                $vuetify.t("Moje info")
              }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile @click="logout">
              <v-list-tile-title>{{
                $vuetify.t("Odhlásenie")
              }}</v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-card>
      </v-menu>
    </v-toolbar>
    <router-view></router-view>
    <v-footer app height="auto">
      <v-layout align-center row wrap>
        <v-expand-transition>
          <v-flex
            xs12
            :dark="isThemeDark"
            :light="!isThemeDark"
            class="my-footer"
            v-if="showCakaren && cakarenItemsLength > 0"
          >
            <draggable
              :list="cakaren.items"
              group="people"
              @start="dragging = true"
              @change="cakarenDragAndDropEnd"
            >
              <transition-group>
                <template v-for="pacient in cakaren.items">
                  <v-chip
                    :key="pacient.patient_id"
                    close
                    @input="cakarenRemove(pacient.patient_id)"
                    v-if="pacient.patient_name"
                  >
                    <v-avatar>
                      <img
                        :src="
                          defaultText(
                            pacient.imageLink, //TODO: doplnit real patient's avatar
                            '//www.gravatar.com/avatar/xxx?d=mp'
                          )
                        "
                        alt=""
                      />
                    </v-avatar>
                    <span @click="pacientClicked(pacient)" class="clicker">
                      {{ pacient.patient_name }} <br />
                      <show-time-ago :myTime="pacient.created_at" />
                    </span>
                  </v-chip>
                </template>
              </transition-group>
            </draggable>
          </v-flex>
        </v-expand-transition>
        <v-flex xs12>
          <v-toolbar dense flat>
            {{ $vuetify.t("Ordinácia") }} &copy;
            {{ new Date().getFullYear() }},&nbsp;verzia 1.0
            <v-tooltip top class="pl-2">
              <span slot="activator">{{ version }}</span>
              <span>{{ $vuetify.t("Verzia") }}</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-tooltip top class="pr-2">
              <template v-slot:activator="{ on }">
                <v-icon small v-on="on" :color="serverStatus ? 'green' : 'red'"
                  >fas {{ serverStatus ? "fa-signal" : "fa-signal" }}</v-icon
                >
              </template>
              <span>{{
                $vuetify.t(
                  "Server " + (serverStatus ? "dostupný" : "nedostupný")
                )
              }}</span>
            </v-tooltip>
            <v-menu top offset-y :close-on-content-click="false">
              <template #activator="{ on: menu }">
                <v-tooltip top>
                  <template #activator="{ on: tooltip }">
                    <v-btn flat icon v-on="{ ...tooltip, ...menu }">
                      <v-icon
                        :color="isEhealthReady ? 'green' : 'red'"
                        v-if="fetchingEHealthStatus !== true"
                        >favorite</v-icon
                      >
                      <v-progress-circular
                        v-else
                        indeterminate
                      ></v-progress-circular>
                    </v-btn>
                  </template>
                  <span>{{ $vuetify.t("ezdravie") }}</span>
                </v-tooltip>
              </template>
              <v-card class="pa-0">
                <v-card-text class="pa-0">
                  <form-ezdravie-status></form-ezdravie-status>
                </v-card-text>
              </v-card>
            </v-menu>
            <v-tooltip top>
              <v-btn
                flat
                icon
                slot="activator"
                @click="theme = isThemeDark ? 'light' : 'dark'"
              >
                <v-icon>{{
                  isThemeDark ? "brightness_5" : "brightness_6"
                }}</v-icon>
              </v-btn>
              <span>{{
                $vuetify.t(
                  isThemeDark ? "Prepnúť svetlú tému" : "Prepnúť tmavú tému"
                )
              }}</span>
            </v-tooltip>
            <v-badge right overlap>
              <template v-slot:badge>
                <span class="caption">{{ cakarenItemsLength }}</span>
              </template>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn flat icon v-on="on">
                    <v-icon @click="setShowCakaren"
                      >airline_seat_recline_normal</v-icon
                    >
                  </v-btn>
                </template>
                <span>{{ $vuetify.t("Čakáreň") }}</span>
              </v-tooltip>
            </v-badge>
          </v-toolbar>
        </v-flex>
      </v-layout>
    </v-footer>
    <!-- </v-app> -->
  </div>
</template>

<script>
import draggable from "vuedraggable"
import FormEzdravieStatus from "../components/forms/FormEzdravieStatus.vue"
import FormHelpDesk from "../components/forms/FormHelpDesk.vue"
import * as Miscella from "../miscella.js"
import { mapState, mapGetters } from "vuex"
import { Timeline } from "vue-tweet-embed"
import moment from "moment"
import ShowTimeAgo from "@/components/ShowTimeAgo.vue"
//import { Tweet, Moment, Timeline } from "vue-tweet-embed"

export default {
  components: {
    FormEzdravieStatus,
    // FormHelpDesk,
    draggable,
    ShowTimeAgo,
    // Timeline,
  },
  data: function () {
    return {
      ezdravieDialog: false,
      twitterKey: 0,
      dragging: false,
    }
  },
  computed: {
    showPatients: function () {
      return Miscella.isSet(this.ambulance)
    },
    showAdministration: function () {
      return Miscella.isSet(this.clinic)
    },
    theme: {
      get: function () {
        let ret = this.$store.state.theme
        if (ret === null || ret === "") {
          ret = localStorage.getItem("theme")
        }
        return ret
      },
      set: function (val) {
        if (val !== "") {
          this.$store.commit("setTheme", val)
        }
      },
    },
    ...mapState({
      version: (state) => state.version,
      clinic: (state) => state.me.clinic,
      ambulance: (state) => state.me.ambulance,
      userName: (state) =>
        Miscella.isSet(state.me.user) ? state.me.user.name : "",
      ambulances: (state) =>
        Miscella.isSet(state.me.user) ? state.me.user.ambulances : [],
      ambulanceName: function (state) {
        return Miscella.isSet(state.me.ambulance)
          ? state.me.ambulance.name
          : this.$vuetify.t("Žiadna ambulancia")
      },
      clinicName: (state) =>
        Miscella.isSet(state.me.clinic)
          ? state.me.clinic.name || state.me.clinic.Name
          : "",
      ambulanceId: (state) =>
        Miscella.isSet(state.me.ambulance) ? state.me.ambulance.id : "",
      ehealthStatus: (state) => state.ehealth.status,
      ehealthEnabled: (state) => state.ehealth.enabled,
      showCakaren: (state) => state.showCakaren,
      fetchingEHealthStatus: (state) => state.fetchingEHealthStatus,
      serverStatus: (state) => state.serverClinicStatus,
    }),
    ...mapGetters(["isEhealthReady", "isThemeDark"]),
    twitterOptions: function () {
      let o = {
        tweetLimit: "5",
        chrome: "", //noheader nofooter
        theme: this.isThemeDark ? "dark" : "light",
      }
      console.log("twitterOptions", o)
      return o
    },
    cakaren: {
      get: function () {
        return Miscella.isSet(this.ambulance) ? this.ambulance.cakaren : null
      },
      set: function () {
        // this.cakarenSave()
      },
    },
    cakarenItemsLength: function () {
      let ret = 0
      if (this.cakaren) {
        if (this.cakaren.items) {
          ret = this.cakaren.items.length
        }
      }
      return ret
    },
    isHelpDeskAllowed: function () {
      let ret = false
      /*
      let ret = false
      if (Miscella.isSet(this.$store.state.me)) {
        if (Miscella.isSet(this.$store.state.me.clinic)) {
          ret = this.$store.state.me.clinic.isVerified
        }
      }*/
      return ret
    },
  },
  watch: {
    $route: function (to) {
      if (to.matched[2].name !== "pacient") {
        this.$store.commit("setPacient", null)
        this.$store.commit("setPacientPromise", null)
      }
    },
    twitterOptions: function () {
      this.twitterKey += 1 //to sposobi rerender componentu, napr. pri zmene theme, co sa inak neudeje
      console.log("twitterOptions changed:" + this.twitterKey)
    },
  },
  methods: {
    setShowCakaren: function () {
      this.$store.commit("setShowCakaren", !this.showCakaren)
    },
    cakarenDragAndDropEnd: function () {
      this.dragging = false
      this.cakarenSave()
    },
    cakarenSave: async function () {
      let ambulance = this.$store.state.me.ambulance
      try {
        let result = await Miscella.cakarenUpdate(
          this.cakaren,
          ambulance.id,
          this.$api
        )
        this.$store.commit(
          "setSuccess",
          this.$vuetify.t("Čakáreň bola úspešne uložená.")
        )
        console.log("cakaren saved", result)
      } catch (err) {
        this.$store.commit(
          "setError",
          this.$vuetify.t(
            "Pri ukladaní pacienta do čakárne vznikla chyba ({message}).",
            {
              message: err,
            }
          )
        )
      }
      this.$store.dispatch("reloadMe", this.$api)
    },
    cakarenRemove: function (pacientId) {
      this.cakaren = Miscella.cakarenRemove(this.cakaren, pacientId)
      this.cakarenSave()
    },
    showTimeAgo: function (time) {
      let ret = moment(time).fromNow()
      return ret
    },
    logout: function () {
      console.log("APP.VUE, logout")
      localStorage.removeItem("token")
      this.$store.commit("logout")
      window.location = "/"
    },
    pacientClicked: function (p) {
      this.$router.push({ name: "pacient", params: { id: p.patient_id } })
    },
    ezdravieClicked: function () {
      let ret = prompt("IP adresa servera", this.$store.state.ehealth.IP)
      if (ret !== null) {
        ret = ret === "" ? "localhost" : ret
        this.$store.commit("setEhealthIP", ret)
        localStorage.setItem("eZdravieCardIP", ret)
        this.$store.dispatch("fetchEhealthStatus", this.$api)
      }
    },
    getStatusColor: function (aBool) {
      return {
        "red--text": !aBool,
      }
    },
  },
  mounted: function () {},
}
</script>
<style>
span.status--span {
  font-weight: bold;
}

/*
nav.v-toolbar {
  xz-index: 4;
}
*/

.my-footer.dark {
  background-color: #424242;
  border-top: #212121 solid 1px;
}
.my-footer.light {
  background-color: #eeeeee;
  border-top: #bdbdbd solid 1px;
}
</style>
