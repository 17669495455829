import moment from "moment"
import * as Miscella from "../../miscella.js"

export var mixins = {
  computed: {
    pacientData: function () {
      if (Miscella.isSet(this.$store.state.pacient)) {
        return this.$store.state.pacient
      }
      return {}
    },
    jePacientKapitovany: function () {
      return this.pacientData.kapitaciaAktualna != null
    },
    kontrolaText: function () {
      let ret = ""
      if (
        Miscella.isSet(this.pacientData.kontrola) &&
        !Miscella.isEmpty(this.pacientData.kontrola.Datum)
      ) {
        ret = Miscella.kontrolaFormat(this.pacientData.kontrola.Datum)
      }
      return ret
    },
    jeDovera: function () {
      return this.pacient.poistovna === "DOVERA"
    },
    prihlasenieTokenomUrl: function () {
      return (
        "/blo/prihlasenieTokenom/" +
        this.$store.state.me.user.id +
        "/" +
        this.pacientId
      )
    },
  },
  methods: {
    getAge: function (aBirthdate) {
      let ret = ""
      if (!moment(aBirthdate).isValid()) {
        return ret
      }
      let months = moment().diff(aBirthdate, "months")
      if (months > 24) {
        let years = moment().diff(moment(aBirthdate), "years")
        // let years = Math.round(months / 12)
        if (years < 5) {
          ret = years + " " + this.$vuetify.t("roky")
        } else {
          ret = years // + ' ' + this.$vuetify.t('rokov')
        }
      } else {
        if (months === 1) {
          ret = months + " " + this.$vuetify.t("mesiac")
        } else if (months < 5) {
          ret = months + " " + this.$vuetify.t("mesiace")
        } else {
          ret = months + " " + this.$vuetify.t("mesiacov")
        }
      }
      return ret
    },
    getAgeFromRC: function (rodneCislo) {
      let datum = this.getDatumFromRC(rodneCislo)
      return this.getAge(datum)
    },
    getDatumFromRC: function (rodneCislo) {
      let datum = null
      try {
        let yy = Number(rodneCislo.substr(0, 2))
        if (rodneCislo.length === 10 && yy < 54) {
          yy = yy + 2000
        } else {
          yy = yy + 1900
        }
        let mm = Number(rodneCislo.substr(2, 2))
        if (mm > 50) {
          mm = mm - 50
        }
        let dd = Number(rodneCislo.substr(4, 2))
        datum = moment(yy + "." + mm + "." + dd, "YYYY.MM.DD")
      } catch (error) {
        console.log("getDatumFromRC ERROR: ", error)
      }
      return datum
    },
    getSexFromRC: function (rodneCislo) {
      let sex = Miscella.SEX.UNKNOWN
      try {
        let mm = Number(rodneCislo.substr(2, 2))
        if (mm > 50) {
          sex = Miscella.SEX.FEMALE
        } else {
          sex = Miscella.SEX.MALE
        }
      } catch (error) {
        console.log("getSexFromRC ERROR: ", error)
      }
      return sex
    },
    getPacientDatum: function (aWhat) {
      let ambId = ""
      if (this.$store.state.me.ambulance !== null) {
        ambId = this.$store.state.me.ambulance.id
      }
      if (this.pacient.Ambulance !== undefined) {
        let datumy = this.pacient.Ambulance[ambId]
        if (Miscella.isSet(datumy)) {
          if (aWhat === "Z") {
            return datumy.PoslednaZmena
          }
          if (aWhat === "V") {
            return datumy.PosledneVysetrenie
          }
        }
      }
      return ""
    },
    rcSanitize: function (rc) {
      if (!Miscella.isEmpty(rc)) {
        return rc.replace(/\D/g, "")
      }
      return ""
    },
    rcIsValid: function (rc) {
      if (!Miscella.isSet(rc)) {
        return false
      }
      let mesiac = parseInt(rc.substring(2, 4))
      let den = parseInt(rc.substring(4, 6))
      let rok = parseInt(rc.substring(0, 2))
      let rcDlzka
      if (rok >= 54) {
        rcDlzka = rc.length == 10
      } else {
        rcDlzka = rc.length == 9
      }
      let rcObsahujeCislice = /^\d+$/.test(rc) // <= kontrola, ci obsahuje len cislice
      let rcMesiacFormat =
        (mesiac >= 1 && mesiac <= 12) || (mesiac >= 51 && mesiac <= 62)
      let rcDenFormat = den >= 1 && den <= 31
      return (
        rcDlzka &&
        rcObsahujeCislice &&
        rcMesiacFormat &&
        rcDenFormat &&
        this.rcChecksumIsValid(rc)
      )
    },
    rcChecksumIsValid: function (rc) {
      // pri 10 miestnych rc sa overuje ci je cislo delitelne cislom 11
      if (rc.length == 10) {
        let rcNumber = parseInt(rc)
        return !isNaN(rcNumber) && rcNumber % 11 == 0
      }
      return true
    },
    openDovera: async function () {
      let urlZP = await this.$api.get(this.prihlasenieTokenomUrl)
      console.log("openDovera", urlZP)
      window.open(urlZP)
    },
  },
  filters: {
    addIfNotNull: function (val, ret) {
      if (val) {
        return val + ret
      }
      return ""
    },
  },
}
