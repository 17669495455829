<template>
  <v-text-field
    ref="textField"
    :value="value"
    @input="updateValue"
    :append-icon="showPassword ? 'visibility' : 'visibility_off'"
    :type="showPassword ? 'text' : 'password'"
    @click:append="showPassword = !showPassword"
    :id="name"
    :prepend-icon="prependIcon"
    :name="name"
    :label="label"
    :placeholder="placeholder"
    :autocomplete="autocomplete"
    :error-messages="errorMessages || errors.collect(name)"
    @keyup.enter="keyUpEnter"
    data-test="password"
    :counter="counter"
  ></v-text-field>
</template>

<script type="text/babel">
export default {
  inject: ["$validator"],
  props: {
    value: {
      default: null,
    },
    counter: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    prependInnerIcon: {
      type: String,
      default: "",
    },
    prependIcon: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    autocomplete: {
      type: String,
      default: "",
    },
    errorMessages: {
      type: [String, Array],
      required: false,
    },
    keyUpEnter: {
      type: Function,
      default: function () {},
    },
  },
  data: function () {
    return {
      showPassword: false,
    }
  },
  methods: {
    updateValue: function (value) {
      this.$emit("input", value)
    },
    focus: function () {
      this.$refs.textField.focus()
    },
  },
}
</script>
