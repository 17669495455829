<template>
  <v-form>
    <v-container fluid>
      <v-layout row wrap>
        <v-flex xs12>
          <v-autocomplete
            v-model="model.Odbornosti"
            :label="$vuetify.t('Odbornosti')"
            :items="odbornosti"
            item-text="DisplayName"
            item-value="ID"
            hide-no-data
            clearable
            multiple
            chips
            deletable-chips
            small-chips
          >
          </v-autocomplete>
        </v-flex>
        <v-flex xs12>
          <v-autocomplete
            v-model="model.TypHodnoty"
            :label="$vuetify.t('Typ hodnoty')"
            :items="typyHodnot"
            :item-text="typHodnotyText"
            v-validate="'required'"
            data-vv-name="TypHodnoty"
            :data-vv-as="$vuetify.t('Typ hodnoty')"
            :error-messages="errors.collect('TypHodnoty')"
            item-value="Name"
            hide-no-data
            clearable
          >
          </v-autocomplete>
        </v-flex>
        <v-flex xs12>
          <TextField
            v-model="model.Hodnota"
            type="number"
            :label="$vuetify.t('Hodnota')"
            name="hodnota"
            v-validate="{ required: true, decimal: true }"
            data-vv-name="hodnota"
            :data-vv-as="$vuetify.t('Hodnota')"
            :error-messages="errors.collect('hodnota')"
          ></TextField>
        </v-flex>

        <v-flex xs6>
          <TextField
            v-model="model.VekOd"
            type="number"
            :label="$vuetify.t('Vek od')"
            name="VekOd"
            v-validate="{ integer: true }"
          ></TextField>
        </v-flex>
        <v-flex xs6>
          <TextField
            v-model="model.VekDo"
            type="number"
            :label="$vuetify.t('Vek do')"
            name="VekDo"
            v-validate="{ integer: true }"
          ></TextField>
        </v-flex>
        <v-flex xs12>
          <v-textarea
            v-model="model.Popis"
            :label="$vuetify.t('Popis')"
          ></v-textarea>
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>
<script>
import TextField from "./../TextField.vue"
import * as Miscella from "../../miscella.js"

export default {
  $_veeValidate: {
    validator: "new",
  },
  components: {
    TextField,
  },
  props: {
    poistovna: {
      type: String,
      required: true,
    },
  },

  data: function () {
    return {
      model: null,
      odbornosti: [],
      typyHodnot: [],
      odbornostiUrl: "/api/odbornostiPZS",
    }
  },

  methods: {
    init: function () {
      this.model = {
        ID: "",
        Odbornosti: [],
        TypHodnoty: null,
        Poistovna: this.poistovna,
        JeCena: true,
        VekOd: null,
        VekDo: null,
        Popis: "",
        Hodnota: null,
      }
      this.odbornosti = []
      this.$validator.reset()
    },
    new: function () {
      this.init()
      this.fetchOdbornosti()
      this.fetchTypyHodnot()
    },
    edit: function (item) {
      this.init()
      this.model = Miscella.cloneObject(item)
      this.fetchOdbornosti()
      this.fetchTypyHodnot()
    },

    fetchOdbornosti: async function () {
      this.odbornosti = await this.$api.get(this.odbornostiUrl)
    },
    fetchTypyHodnot: async function () {
      let params = {
        cennikID: this.model.ID,
      }
      this.typyHodnot = await this.$api.get(
        "/api/poistovna/" + this.poistovna + "/cennik/cennikKodyForSelect",
        params
      )
    },
    fetchData: function () {
      this.fetchOdbornosti()
      this.fetchTypyHodnot()
    },
    save: async function () {
      var valid = await this.$validator.validateAll()
      if (!valid) {
        return
      }
      await this.$api.post("/api/poistovnaCennikSave", this.model)
      this.$store.commit(
        "setSuccess",
        this.$vuetify.t("Cenník bol úspešne uložený")
      )
      this.$emit("saved", this.model)
    },
    typHodnotyText: function (item) {
      return this.$vuetify.t("$vuetify.enum.cennikKod." + item.Name)
    },
  },
  mounted: function () {
    this.fetchData()
  },
  created: function () {
    this.init()
  },
}
</script>
