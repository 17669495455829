<template>
  <v-layout row wrap align-center justify-center>
    <v-flex xs12 sm10 md8>
      <v-card>
        <v-toolbar flat dense card>
          <v-toolbar-title class="headline text-xs-center"
            >{{ $vuetify.t("Registrácia PZS") }}<br
          /></v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <div v-if="!registered">
            <v-form>
              <v-card flat>
                <v-card-text>
                  <v-container grid-list-md class="pa-0">
                    <v-layout row wrap>
                      <v-flex xs12>
                        <codebook-autocomplete
                          ref="poskytovatelField"
                          codebook-id="100007"
                          name="poskytovatel"
                          v-model="poskytovatel"
                          :label="$vuetify.t('Výber PZS')"
                          :hint="
                            $vuetify.t(
                              !isPoskytovatel
                                ? 'Začnite vyhľadávať PZS podľa názvu alebo IČO. Po zvolení PZS z číselníka, budú údaje PZS vyplnené automaticky. Ak chcete zadať údaje PZS manuálne, môžete toto pole preskočiť.'
                                : 'Údaje PZS budú vyplnené automaticky z číselníka (je však možné ich neskôr zmeniť). Ak nechcete použiť údaje z číselníka, toto pole vymažte.'
                            )
                          "
                          :fetch-on-empty="false"
                        >
                        </codebook-autocomplete>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card-text>
              </v-card>
              <v-card flat
                ><v-toolbar flat dense>
                  <v-toolbar-title>{{
                    $vuetify.t("Údaje PZS")
                  }}</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-container grid-list-md class="pa-0">
                    <v-layout row wrap>
                      <v-flex xs12>
                        <TextField
                          v-model="form.clinic_name"
                          ref="clinicNameField"
                          name="form.clinicName"
                          v-validate="'required'"
                          :maxlength="255"
                          :hint="
                            $vuetify.t(
                              'Pod týmto názvom Vás nájdu pacienti, spolupracujúci lekári a laboratóriá, názov je možné kedykoľvek zmeniť'
                            )
                          "
                          :label="$vuetify.t('Názov PZS alebo firmy')"
                          :keyUpEnter="send"
                          :disabled="isPoskytovatel"
                        ></TextField>
                      </v-flex>
                      <v-flex xs12 md3 v-if="isPoskytovatel">
                        <TextField
                          v-model="poskytovatel.skratka"
                          name="form.ico"
                          :label="$vuetify.t('IČO')"
                          disabled
                        ></TextField>
                      </v-flex>
                      <v-flex xs12 md9 v-if="isPoskytovatel">
                        <TextField
                          v-model="poskytovatel.pzs.address"
                          name="form.adresaPZS"
                          :label="$vuetify.t('Adresa')"
                          disabled
                        ></TextField>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card-text>
              </v-card>
              <v-card flat
                ><v-toolbar flat dense>
                  <v-toolbar-title>{{
                    $vuetify.t("Údaje používateľa")
                  }}</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-container grid-list-md class="pa-0">
                    <v-layout row wrap>
                      <v-flex xs12 md6>
                        <TextField
                          ref="nameField"
                          v-model="form.name"
                          name="form.name"
                          v-validate="'required'"
                          :maxlength="255"
                          :label="$vuetify.t('Meno a priezvisko')"
                          :keyUpEnter="send"
                        ></TextField>
                      </v-flex>
                      <v-flex xs12 md6>
                        <TextField
                          v-model="form.email"
                          name="form.email"
                          v-validate="'required|email'"
                          :maxlength="100"
                          :label="$vuetify.t('E-mail')"
                          autocomplete="username"
                          :keyUpEnter="send"
                        ></TextField>
                      </v-flex>
                      <v-flex xs12 md6>
                        <text-field-password
                          v-model="form.password"
                          name="form.password"
                          v-validate="'required|min:12|max:255'"
                          :error-messages="errors.collect('password1')"
                          data-vv-name="password1"
                          :data-vv-as="$vuetify.t('heslo')"
                          :label="$vuetify.t('Heslo')"
                          :hint="$vuetify.t('Heslo musí mať aspoň 12 znakov')"
                          ref="password1"
                          counter="255"
                          autocomplete="new-password"
                          :keyUpEnter="send"
                        ></text-field-password>
                      </v-flex>
                      <v-flex xs12 md6>
                        <text-field-password
                          v-model="form.password2"
                          name="form.password2"
                          v-validate="'required|confirmed:password1'"
                          :error-messages="errors.collect('password2')"
                          data-vv-name="password2"
                          :data-vv-as="$vuetify.t('heslo')"
                          :label="$vuetify.t('Overenie hesla')"
                          :hint="
                            $vuetify.t('Pre overenie zadajte heslo ešte raz')
                          "
                          counter
                          autocomplete="new-password"
                          :keyUpEnter="send"
                        ></text-field-password>
                      </v-flex>
                      <v-flex xs12 md6>
                        <vue-tel-input-vuetify
                          v-model="form.phone"
                          clearable
                          name="form.phone"
                          :label="$vuetify.t('Telefónne číslo')"
                          :placeholder="$vuetify.t('Telefónne číslo')"
                          :maxLen="255"
                          defaultCountry="SK"
                          :preferredCountries="[
                            'SK',
                            'CZ',
                            'AT',
                            'PL',
                            'HU',
                            'DE',
                            'UA',
                          ]"
                          @input="onPhoneInput"
                          :error-messages="errors.collect('form.phone')"
                        >
                        </vue-tel-input-vuetify>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-form>
          </div>
          <div v-else class="text-md-left px-4">
            <h3 class="headline">
              {{
                $vuetify.t(
                  "Ďakujeme, že ste sa zaregistrovali v aplikácii Ordinácia"
                )
              }}
            </h3>
            <p>
              {{
                $vuetify.t(
                  "Na úplné dokončenie registrácie je ešte potrebné overiť Vašu e-mailovú adresu. Nasledujte inštrukcie, ktoré sme Vám zaslali do Vašej e-mailovej schránky."
                )
              }}
            </p>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn flat @click="goToLogin">{{
            $vuetify.t("Späť na prihlásenie")
          }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn flat color="primary" v-if="!registered" @click="send">{{
            $vuetify.t("Odoslať")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import * as Miscella from "./../miscella.js"
import loginMixin from "@/components/mixins/login.js"
import TextField from "@/components/TextField.vue"
import textFieldPassword from "@/components/TextFieldPassword.vue"
import VueTelInputVuetify from "@/components/vue-tel-input-vuetify/vue-tel-input-vuetify.vue"
import CodebookAutocomplete from "@/components/ciselnikComponents/CodebookAutocomplete.vue"

export default {
  name: "Registration",
  $_veeValidate: {
    validator: "new",
  },
  mixins: [loginMixin],
  components: {
    TextField,
    textFieldPassword,
    VueTelInputVuetify,
    CodebookAutocomplete,
  },
  data() {
    return {
      registered: false,
      dialog: false,
      form: {
        pzs_id: 0,
        clinic_name: "",
        name: "",
        email: "",
        password: "",
        password2: "",
        phone: "",
      },
      poskytovatel: null,
      phone: {
        number: "",
        valid: false,
        country: undefined,
      },
    }
  },
  computed: {
    isPoskytovatel: function () {
      return Miscella.isSet(this.poskytovatel)
    },
  },
  watch: {
    poskytovatel: function (poskytovatel) {
      if (Miscella.isSet(poskytovatel)) {
        this.form.pzs_id = poskytovatel.id
        this.form.clinic_name = poskytovatel.display_name
      }
    },
  },
  methods: {
    onPhoneInput: function (formattedNumber, { number, valid, country }) {
      this.phone.number = number.e164
      this.phone.valid = valid
      this.phone.country = country && country.name
    },
    send: async function () {
      this.errors.clear()
      var valid = await this.$validator.validateAll()
      if (this.phone.number && !this.phone.valid) {
        valid = false
        this.errors.add({
          field: "form.phone",
          msg: this.$vuetify.t("Nesprávny formát telefónneho čísla."),
        })
      }
      if (!valid) {
        return
      }
      try {
        this.form.phone = this.phone.number
        await this.$api.registrationService.register(this.form)
        this.registered = true
        this.notifySuccess("Vaše údaje boli úspešne zaregistrované")
      } catch (err) {
        this.processError(err, "Pri ukladaní diagnózy vznikla chyba")
      }
    },
    goToDashboard: async function () {
      await this.login(this.form.email, this.form.password)
    },
    goToLogin: function () {
      this.$router.replace({ name: "login" })
    },
  },
  mounted: function () {
    this.errors.clear()
    console.log("REF", this.$refs.poskytovatelField)
    this.$refs.poskytovatelField.focus()
  },
}
</script>
