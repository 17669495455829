<template>
  <v-form>
    <v-container fluid>
      <v-layout row wrap>
        <v-flex xs12 md6>
          <DatePicker
            v-model="model.date"
            :label="$vuetify.t('Dátum')"
            name="datum"
            v-validate="'required'"
          ></DatePicker>
        </v-flex>
        <v-flex xs12 md6>
          <DatePicker
            v-model="model.diagnoza.datum_ukoncenia"
            :label="$vuetify.t('Dátum ukončenia')"
            name="datumUkoncenia"
          ></DatePicker>
        </v-flex>
        <v-flex xs12>
          <codebook-autocomplete
            codebook-id="25"
            name="diagnoza"
            v-model="diagnoza"
            :label="$vuetify.t('Diagnóza')"
            v-validate="'required'"
          >
            <template v-slot:selection="{ item }"
              ><span
                >{{ item.skratka }} - {{ item.display_name }}</span
              ></template
            >
            <template v-slot:item="{ item }">
              <v-list-tile-content>
                <v-list-tile-title v-html="item.skratka"></v-list-tile-title>
                <v-list-tile-sub-title
                  v-html="item.display_name"
                ></v-list-tile-sub-title>
              </v-list-tile-content>
            </template>
          </codebook-autocomplete>
        </v-flex>
        <v-flex xs12 md6>
          <v-checkbox
            v-model="model.diagnoza.dispenzarna"
            :label="$vuetify.t('Dispenzárna')"
          ></v-checkbox>
        </v-flex>
        <v-flex xs12 md6>
          <v-checkbox
            v-model="model.diagnoza.zivot_ovplyvnujuca"
            :label="$vuetify.t('Život ovplyvňujúca')"
            :disabled="isZivotOvplyvnujucaDisabled"
          ></v-checkbox>
        </v-flex>
        <v-flex xs12>
          <v-textarea
            v-model="model.diagnoza.poznamka"
            :label="$vuetify.t('Poznámka')"
          ></v-textarea>
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
import BaseForm from "./../BaseForm.vue"
import * as Miscella from "./../../miscella.js"
import DatePicker from "./../DatePicker.vue"
import moment from "moment"
import CodebookAutocomplete from "./../ciselnikComponents/CodebookAutocomplete.vue"

export default {
  name: "FormDiagnoza",
  extends: BaseForm,
  $_veeValidate: {
    validator: "new",
  },
  components: {
    DatePicker,
    CodebookAutocomplete,
  },
  props: {
    pacientId: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      model: null,
      diagnoza: null,
    }
  },
  computed: {
    ambulanceId: function () {
      let id = null
      if (Miscella.isSet(this.$store.state.me.ambulance)) {
        id = this.$store.state.me.ambulance.id
      }
      return id
    },
    isZivotOvplyvnujucaDisabled: function () {
      return (
        Miscella.isSet(this.diagnoza) &&
        this.diagnoza.atributy.map["11"] === "áno"
      )
    },
  },
  watch: {
    diagnoza: function (dg) {
      this.model.diagnoza.zivot_ovplyvnujuca = false
      if (Miscella.isSet(dg)) {
        this.model.diagnoza.diagnoza_id = dg.id
        this.model.diagnoza.zivot_ovplyvnujuca = dg.atributy.map["11"] === "áno"
      }
      this.isDirty = true
    },
  },
  methods: {
    init: function () {
      this.model = {
        id: "",
        patient_id: this.pacientId,
        ambulance_id: this.ambulanceId,
        date: moment().format(),
        parent_id: "",
        diagnoza: {
          diagnoza_id: 0,
          dispenzarna: false,
          datum_ukoncenia: null,
          poznamka: "",
          zivot_ovplyvnujuca: false,
        },
      }

      this.diagnoza = null
      this.$validator.reset()
      this.errors.clear()
    },
    new: function () {
      this.init()
      this.$emit("activated")
    },
    edit: function (thingDiagnoza) {
      this.init()
      this.diagnoza = thingDiagnoza.diagnoza.diagnoza
      this.$nextTick(() => {
        this.model = Miscella.cloneObject(
          thingDiagnoza,
          Object.keys(this.model)
        )
      })
      this.$emit("activated")
    },
    validate: async function () {
      var valid = await this.$validator.validateAll()
      if (Miscella.isSet(this.model.diagnoza.datum_ukoncenia)) {
        let datumVytvorenia = moment(this.model.date)
        let datumUkoncenia = moment(this.model.diagnoza.datum_ukoncenia)
        if (datumUkoncenia.isBefore(datumVytvorenia, "day")) {
          valid = false
          this.errors.add({
            field: "datumUkoncenia",
            msg: this.$vuetify.t(
              "Dátum ukončenia nemôže predchádzať dátum vytvorenia."
            ),
          })
        }
      }
      return valid
    },
    save: async function () {
      try {
        var params = { thing: Miscella.cloneObject(this.model) }

        var result = await this.$api.thingService.saveThing(params)
        this.notifySuccess("Diagnóza bola úspešne uložená")
        this.$emit("saved", result.thing)
        this.model = result.thing
      } catch (err) {
        this.processError(err, "Pri ukladaní diagnózy vznikla chyba")
      }
    },
  },
  created: function () {
    this.init()
  },
}
</script>
