<template>
  <div style="position: relative; min-height: 50px">
    <slot> </slot>
    <v-container
      fill-height
      v-show="value"
      class="pa-0"
      style="
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      "
    >
      <v-layout justify-center align-center column>
        <v-progress-circular indeterminate color="primary">
        </v-progress-circular>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "LoadingContainer",
  components: {},
  data: function () {
    return {}
  },
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  computed: {},
  watch: {},
  methods: {},
  mounted: function () {},
}
</script>
