import Vue from "vue"
import Vuex from "vuex"
import moduleEvents from "./store-events.js"
import EVysetrenie from "./ehealth/evysetrenie.js"
// import EZdravie from "./ehealth/ezdravie.js"
import * as Miscella from "./miscella.js"
import config from "../app.config.js"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    version: "",
    demo: !!config.demo, // zatial takto, aby sme zarucili default hodnotu true
    message: null,
    theme: "",
    showCakaren: false,
    me: {
      clinic: null,
      ambulance: null,
      user: null,
    },
    pacient: null,
    reloadPacient: false,
    ehealth: {
      enabled: true,
      demo: !!config.demo,
      status: null,
    },
    promises: {
      me: null,
      pacient: null,
    },
    serverClinicStatus: false,
    loading: {
      app: false,
      save: false,
      ezdraviePacientJruzID: false,
      ezdraviePacientZP: false,
      message: "",
    },
    pacientInfoExpanded: null,
    calendar: {
      type: "month",
      range: [6, 18],
    },
    drawer: {
      pacient: false,
      vysetrenie: false,
    },
    fetchingEhealthStatus: null,
    thing: null,
  },
  mutations: {
    setVersion(state, value) {
      state.version = value
    },
    setTheme(state, value) {
      state.theme = value
      localStorage.setItem("theme", value)
    },
    setShowCakaren(state, value) {
      state.showCakaren = value
      //localStorage.setItem("showCakaren", value)
    },
    setSuccess(state, text) {
      this.commit("setMessage", { text: text, type: "success" })
    },
    setInfo(state, text) {
      this.commit("setMessage", { text: text, type: "info" })
    },
    setError(state, text) {
      this.commit("setMessage", { text: text, type: "error" })
    },
    nextMessage() {
      this.commit("setMessage", null)
    },
    setMessage(state, msg) {
      if (!Miscella.isEmpty(msg)) {
        state.message = msg
      }
    },
    setClinic(state, clinic) {
      state.me.clinic = clinic
      localStorage.setItem("clinicId", clinic ? clinic.id : null)
    },
    setUser(state, user) {
      state.me.user = user
      // if (Miscella.isSet(window.$zopim.livechat)) {
      //   if (Miscella.isSet(user)) {
      //     window.$zopim.livechat.setName(user.name)
      //     window.$zopim.livechat.setEmail(user.email)
      //   } else {
      //     window.$zopim.livechat.setName('meno')
      //     window.$zopim.livechat.setEmail('meno@adresa.sk')
      //   }
      // }
    },
    setAmbulance(state, ambulance) {
      state.me.ambulance = ambulance
      localStorage.setItem("ambId", ambulance ? ambulance.id : null)
    },
    logout(state) {
      state.me.user = null
      state.me.ambulance = null
    },
    setPacient(state, newPacient) {
      state.pacient = newPacient
    },
    setReloadPacient(state, newReloadPacient) {
      state.reloadPacient = newReloadPacient
    },
    setEhealth(state, value) {
      state.ehealth.status = value.status
    },
    setEhealthDemo(state, value) {
      state.ehealth.demo = value
    },
    setEhealthIP(state, value) {
      state.ehealth.IP = value
    },
    setMePromise(state, value) {
      state.promises.me = value
    },
    setPacientPromise(state, value) {
      state.promises.pacient = value
    },
    setServerClinicStatus(state, value) {
      state.serverClinicStatus = value
    },
    setLoading(state, { key, value }) {
      state.loading[key] = value
    },
    setLoading(state, { key, value }) {
      state.loading[key] = value
    },
    setPacientInfoExpanded(state, value) {
      state.pacientInfoExpanded = value
    },
    startLoading(state, { key, message = "" }) {
      state.loading.message = message
      this.commit("setLoading", { key: key, value: true })
    },
    stopLoading(state, key) {
      state.loading.message = ""
      this.commit("setLoading", { key: key, value: false })
    },
    setCalendar(state, value) {
      state.calendar = value
    },
    setCalendarType(state, value) {
      state.calendar.type = value
    },
    setCalendarRange(state, value) {
      state.calendar.range = value
    },
    toggleDrawer(state, name) {
      state.drawer[name] = !state.drawer[name]
    },
    setFetchingEhealthStatus(state, value) {
      state.fetchingEhealthStatus = value
    },
    setPacientJruzID(state, value) {
      if (Miscella.isSet(state.pacient)) {
        state.pacient.jruz_id = value
      }
    },
    setPacientInfo(state, value) {
      if (Miscella.isSet(state.pacient)) {
        state.pacient.person_unique_id = value.person_unique_id
        state.pacient.jruz_id = value.jruz_id
        state.pacient.person_computer_number = value.person_computer_number
        state.pacient.person_meaningless_identifier =
          value.person_meaningless_identifier
      }
    },
    setThing(state, newThing) {
      state.thing = newThing
    },
  },
  getters: {
    isThemeDark: function (state) {
      return state.theme === "dark"
    },
    isUserAdmin: function (state) {
      return (
        Miscella.isSet(state.me.clinic) &&
        state.me.clinic.user_role === "USER_ROLE_ADMIN"
      )
    },
    isEhealthEnabled: function (state) {
      return state.ehealth.enabled
    },
    isEhealthDemo: function (state) {
      return state.ehealth.demo
    },
    isEhealthModuleConnected: function (state) {
      return Miscella.isSet(state.ehealth.status)
    },
    isEhealthCardConnected: (state, getters) => {
      return (
        getters.isEhealthModuleConnected &&
        state.ehealth.status === "CARD_STATUS_CONNECTED"
      )
    },
    hasPatientPersonUniqueID: function (state) {
      return (
        Miscella.isSet(state.pacient) &&
        !Miscella.isEmpty(state.pacient.person_unique_id)
      )
    },
    hasPatientJruzID: function (state) {
      return (
        Miscella.isSet(state.pacient) &&
        !Miscella.isEmpty(state.pacient.jruz_id)
      )
    },
    // ehealth is online
    isEhealthOnline: function (state, getters) {
      return getters.isEhealthCardConnected
      //state.ehealth.status.ServerDostupny
    },
    // ehealth is set up, all prerequisites for ambulance are fullfilled
    isEhealthSetUpForAmbulance: function (state, getters) {
      return (
        getters.isEhealthDemo ||
        (getters.isEhealthEnabled &&
          Miscella.isSet(state.me.ambulance) &&
          !Miscella.isEmpty(state.me.ambulance.jruz_id) &&
          Miscella.isSet(state.me.ambulance.zameranie))
      )
    },
    // ehealth is set up, all prerequisites are fullfilled, including patient id
    isEhealthSetUp: function (state, getters) {
      return (
        getters.isEhealthDemo ||
        (getters.isEhealthSetUpForAmbulance &&
          (Miscella.isSet(state.pacient) ? getters.hasPatientJruzID : true))
      )
    },
    // ehealth is set up for ambulance and also online
    isEhealthReadyForAmbulance: function (state, getters) {
      return (
        getters.isEhealthDemo ||
        (getters.isEhealthSetUpForAmbulance && getters.isEhealthOnline)
      )
    },
    // ehealth is set up and also online
    isEhealthReady: function (state, getters) {
      return (
        getters.isEhealthDemo ||
        (getters.isEhealthSetUp && getters.isEhealthOnline)
      )
    },
  },
  actions: {
    async fetchVersion({ commit }, api) {
      var data = await api.post("/v1/proto.Info/Version", null)
      commit("setVersion", data.version)
    },
    fetchMe({ commit, state }, api) {
      if (!Miscella.isSet(state.promises.me)) {
        let mePromise = Promise.all([api.userService.me()])
          .then(async ([meInfoData]) => {
            commit("setUser", meInfoData.user)
            let ambId = localStorage.getItem("ambId")
            let clinicId = localStorage.getItem("clinicId")
            for (let clinic of meInfoData.clinics) {
              if (clinic.id === clinicId) {
                commit("setClinic", clinic)
              }
              if (Miscella.isSet(clinic.ambulances)) {
                for (let amb of clinic.ambulances) {
                  if (amb.id === ambId) {
                    var data = await api.ambulanceService.getAmbulance({
                      ambulance_id: amb.id,
                    })
                    // TODO co spravit, ked nastane chyba pri nacitani ambulancie
                    commit("setAmbulance", data.ambulance)
                  }
                }
              }
            }
            // nastavenie prvej kliniky a ambulancie, ak nebolo nastavene predtym
            if (
              !Miscella.isSet(state.me.clinic) &&
              meInfoData.clinics.length > 0
            ) {
              let clinic = meInfoData.clinics[0]
              commit("setClinic", clinic)
              if (
                !Miscella.isSet(state.me.ambulance) &&
                clinic.ambulances.length > 0
              ) {
                let amb = clinic.ambulances[0]
                var data = await api.ambulanceService.getAmbulance({
                  ambulance_id: amb.id,
                })
                // TODO co spravit, ked nastane chyba pri nacitani ambulancie
                commit("setAmbulance", data.ambulance)
              }
            }
            commit("setMePromise", Promise.resolve(state.me))
            return state.me
          })
          .catch((e) => {
            console.log("ERROR in fetchMe ", e)
            localStorage.removeItem("token")
            commit("logout")
            window.location = "/"
            commit("setMePromise", null)
          })
        commit("setMePromise", mePromise)
      }
      return state.promises.me
    },
    reloadMe: async function ({ state, commit, dispatch }, api) {
      commit("setMePromise", null)
      await dispatch("fetchMe", api)
      dispatch("updateAmbulanceEvents", api)
    },
    async fetchEhealthStatus({ commit, state, dispatch }, api) {
      if (state.fetchingEhealthStatus !== true) {
        commit("setFetchingEhealthStatus", true)
        if (state.ehealth.enabled) {
          // var ehealth = new EZdravie(this, api, state.me.ambulance)
          try {
            let cardInfo = await api.ehealthService.smartcardStatus()
            // let cardInfo = await ehealth.dajCardInfo()
            commit("setFetchingEhealthStatus", false)
            commit("setEhealth", cardInfo)
            // await dispatch("fetchEhealthStatus", api) // reconnect
          } catch (e) {
            // if (e && e.status && (e.status === 408 || e.status === 502)) {
            //   console.log("Timeout, reconnecting ...", e)
            //   await dispatch("fetchEhealthStatus", api) // reconnect
            // } else {
            commit("setEhealth", { status: null })
            console.log("Error getting ehealth status: ", e)
            // TODO tiez znova pripojit pri chybe? mozno po nejakom case, napr. 30 sec
            // }
            commit("setFetchingEhealthStatus", false)
          }
        }
      } else {
        console.log(
          "FetchingEhealthStatus in progress, duplicate call suspended."
        )
      }
    },
    fetchPacient: function ({ commit, state }, { api, pacientId }) {
      if (
        !Miscella.isSet(state.promises.pacient) ||
        (Miscella.isSet(state.pacient.id) && state.pacient.id !== pacientId)
      ) {
        let promise = api
          .post("/v1/proto.PatientService/GetPatient", {
            patient_id: pacientId,
          })
          .then(async (json) => {
            try {
              let pacient = json.patient
              function compare(a, b) {
                if (a.Datum < b.Datum) {
                  return -1
                } else if (a.Datum > b.Datum) {
                  return 1
                }
                return 0
              }
              //Krvná skupina
              let krvnaSkupinaZoznam = await api.thingService.listThings({
                patient_id: pacientId,
                table_request: {
                  limit: 1,
                  sort_by: "Date",
                  sort_desc: true,
                },
                krvna_skupina: {},
              })
              if (krvnaSkupinaZoznam && krvnaSkupinaZoznam?.items?.length > 0) {
                pacient.blood_type =
                  krvnaSkupinaZoznam.items[0].krvna_skupina.nazov_krvnej_skupiny.display_name
              }
              //Dispenzarne diagnozy pre pouzitie v hlavicke
              let diagnozyZoznam = await api.thingService.listThings({
                patient_id: pacientId,
                table_request: {
                  sort_by: "Date",
                  sort_desc: true,
                },
                diagnoza: {
                  active: true,
                  dispenzarna: true,
                },
              })
              let diagnozyText = ""
              if (diagnozyZoznam?.items?.length > 0) {
                diagnozyZoznam.items.map((dg) => {
                  diagnozyText +=
                    "<span title='" +
                    dg.diagnoza.diagnoza.popis +
                    "'>" +
                    dg.diagnoza.diagnoza.skratka +
                    "</span>, "
                })
                if (diagnozyText.length > 0) {
                  diagnozyText = diagnozyText.substring(
                    0,
                    diagnozyText.length - 2
                  )
                }
                pacient.dispenzarne_diagnozy_txt = diagnozyText
              }
              //Posledne vysetrenie pre pouzitie v hlavicke
              let posledneVysetrenie = await api.thingService.listThings({
                patient_id: pacientId,
                table_request: {
                  limit: 1,
                  sort_by: "Date",
                  sort_desc: true,
                },
                vysetrenie: {},
              })
              if (posledneVysetrenie?.items?.length > 0) {
                pacient.posledne_vysetrenie = posledneVysetrenie.items[0]
              }
              commit("setPacient", pacient)
              commit("setPacientPromise", Promise.resolve(state.pacient))
            } catch (error) {
              console.error("Chyba pri nacitani pacienta", error)
            }
            return state.pacient
          })
          .catch(() => {
            commit("setPacientPromise", null)
          })
        commit("setPacientPromise", promise)
      }
      return state.promises.pacient
    },

    reloadPacient: function ({ commit, dispatch }, payload) {
      commit("setPacientPromise", null)
      dispatch("fetchPacient", payload)
      commit("setReloadPacient", false)
    },
    fetchServerClinicStatus({ commit, state, dispatch }, api) {
      return api
        .post("/v1/proto.Info/Ping", null)
        .then(() => {
          commit("setServerClinicStatus", true)
          if (state.ehealth.status === null) {
            // ak ehealth status je null, to znamena, ze long requesty na ehealth status este neboli spustene alebo skoncili po chybe (napr. odpojenie servera),
            // preto ich treba spustis, ak je server uz online
            dispatch("fetchEhealthStatus", api)
          }
          dispatch("fetchEvents", api)
        })
        .catch(() => {
          if (state.serverClinicStatus) {
            commit("setError", "Nepodarilo sa naviazať spojenie so serverom!")
            commit("setServerClinicStatus", false)
          }
          commit("setEhealth", { status: null })
        })
    },
  },
  modules: {
    events: moduleEvents,
  },
})
