<template>
  <v-layout row wrap align-content-center fill-height justify-center>
    <v-container ma-0 pa-0>
      <v-layout row>
        <v-layout :row="!expanded" :column="expanded">
          <!-- Rodne cislo  -->
          <v-flex>
            <info-icon
              iconName="cake"
              :description="
                $vuetify.t(
                  rcIsValid(pacient.person_unique_id)
                    ? 'Rodné číslo'
                    : 'Rodné číslo - nesprávne zadané!'
                )
              "
              :value="pacient.person_unique_id"
              @click.native.stop="goToPacientInfo('0', 'editInfo')"
              :expanded="expanded"
              :valid="rcIsValid(pacient.person_unique_id)"
            >
              <template slot="extraText">
                <span v-if="jeDovera && pacient.RC !== ''" class="pl-2 pt-1">
                  <a @click.stop="openDovera()"><b>On-line</b></a>
                </span>
              </template>
            </info-icon>
          </v-flex>
          <!-- Krvna skupina -->
          <v-flex>
            <info-icon
              iconName="fas fa-tint"
              :description="$vuetify.t('Krvná skupina')"
              :value="pacient.blood_type"
              @click.native.stop="goToKrvnaSkupina()"
              :expanded="expanded"
            ></info-icon>
          </v-flex>
          <v-flex>
            <info-icon
              iconName="fas fa-briefcase-medical"
              :description="$vuetify.t('Poisťovňa')"
              :value="
                pacient.zdravotna_poistovna
                  ? pacient.zdravotna_poistovna.display_name
                  : ''
              "
              @click.native.stop="goToKrvnaSkupina()"
              :expanded="expanded"
            ></info-icon>
          </v-flex>
          <!-- <v-flex>
            <info-icon
              iconName="credit_card"
              :description="$vuetify.t('Poisťovňa')"
              :value="poistovnaText"
              @click.native.stop="goToPoistovne()"
              :expanded="expanded"
            ></info-icon>
          </v-flex> -->
          <!-- <v-flex>
            <info-icon
              iconName="credit_card"
              :description="$vuetify.t('Charakter poistenca')"
              :value="charakterPoistencaText"
              @click.native.stop="goToPoistovne()"
              :expanded="expanded"
            ></info-icon>
          </v-flex> -->
        </v-layout>
        <v-layout :row="!expanded" :column="expanded">
          <!-- Dispenzarne diagnozy -->
          <v-flex>
            <info-icon
              iconName="accessible"
              :description="$vuetify.t('Dispenzárne diagnózy')"
              :value="pacient.dispenzarne_diagnozy_txt"
              @click.native.stop="goToDiagnozy()"
              :expanded="expanded"
              html
            ></info-icon>
          </v-flex>
          <!-- <v-flex>
            <info-icon
              :iconName="$vuetify.t('$vuetify.thing.type.ThingAlergia.icon')"
              @click.native.stop="goToAlergie()"
              :description="
                $vuetify.t(
                  alergiaText
                    ? 'Alergie'
                    : 'Alergie neboli zadané - zadajte aspoň jednu alergiu pacienta alebo nastavte príznak Žiadna alergia'
                )
              "
              :value="alergiaText"
              :expanded="expanded"
            ></info-icon>
          </v-flex> -->
          <v-flex>
            <info-icon
              iconName="calendar_today"
              @click.native.stop="goToPoslVysetrenie()"
              :description="$vuetify.t('Posledné vyšetrenie')"
              :value="
                pacient.posledne_vysetrenie
                  ? pacient.posledne_vysetrenie.date
                  : '' | formatDatumACas
              "
              :expanded="expanded"
            ></info-icon>
          </v-flex>
          <!-- <v-flex>
            <info-icon
              iconName="calendar_today"
              @click.native.stop="goToPacientInfo('3', 'addObjednanie')"
              :description="$vuetify.t('Objednanie')"
              :value="objednanieText"
              :expanded="expanded"
            ></info-icon>
          </v-flex> -->
        </v-layout>
        <v-layout :row="!expanded" :column="expanded" wrap>
          <v-flex>
            <info-icon
              iconName="phone"
              :description="$vuetify.t('Telefónne číslo')"
              :value="pacient.phone"
              @click.native.stop="goToPacientInfo('1', 'editInfoAddress')"
              :expanded="expanded"
            ></info-icon>
          </v-flex>
          <v-flex>
            <info-icon
              iconName="email"
              :description="$vuetify.t('Email')"
              :value="pacient.email"
              @click.native.stop="openMail(pacient.email)"
              :expanded="expanded"
            ></info-icon>
          </v-flex>
          <v-flex>
            <info-icon
              iconName="place"
              :description="$vuetify.t('Adresa')"
              :value="adresaText"
              @click.native.stop="goToPacientInfo('1', 'editInfoAddress')"
              :expanded="expanded"
            ></info-icon>
          </v-flex>
          <!-- <v-flex>
            <base-popup
              v-if="pacient.kontrola"
              :title="$vuetify.t('Aktuálny termín kontroly: ') + kontrolaText"
            >
              <template v-slot:activator="{ toggle }">
                <info-icon
                  iconName="calendar_today"
                  @click.native.stop="toggle"
                  :description="$vuetify.t('Kontrola')"
                  :value="kontrolaText"
                  :expanded="expanded"
                ></info-icon>
              </template>
              <template v-slot="{ toggle }">
                <form-kontrola
                  :pacientId="pacientId"
                  :kontrola="pacient.kontrola"
                  @saved="
                    toggle()
                    readPacient()
                  "
                />
              </template>
            </base-popup>
          </v-flex> -->
        </v-layout>
      </v-layout>
      <v-layout row v-if="expanded">
        <!-- <tags-list @saved="readPacient()" /> -->
      </v-layout>
    </v-container>
  </v-layout>
</template>

<script>
import * as Miscella from "../miscella.js"
import InfoIcon from "../components/InfoIcon.vue"
import FormKontrola from "../components/forms/FormKontrola.vue"
import BasePopup from "../components/BasePopup.vue"
import TagsList from "../components/TagsList.vue"
import moment from "moment"
import * as mixPatient from "../components/mixins/patient.js"
import BaseDialog from "../components/BaseDialog.vue"
import FormKrvnaSkupina from "../components/forms/FormKrvnaSkupina.vue"
import { pacient } from "../plugins/sk.js"

export default {
  components: {
    InfoIcon,
    //BaseDialog,
    //FormKontrola,
    //BasePopup,
    //TagsList,
  },
  props: {
    expanded: {
      type: Boolean,
      required: true,
    },
    pacientId: {
      type: String,
      required: true,
    },
  },
  mixins: [mixPatient.mixins],
  data: function () {
    return {
      dialogTitle: "",
    }
  },
  computed: {
    pacient: function () {
      if (Miscella.isSet(this.$store.state.pacient)) {
        return this.$store.state.pacient
      }
      return {}
    },
    missingValue: function () {
      return Miscella.isEmpty(this.value)
    },
    valueText: function () {
      return Miscella.isEmpty(this.value) ? "Nezadané" : this.value
    },
    adresaText: function () {
      if (!Miscella.isSet(this.pacient.address)) {
        return ""
      }

      let addr = this.pacient.address
      let fmt = ""

      // set "street street_number"
      if (Miscella.isSet(addr.street)) {
        fmt += addr.street
      }
      if (Miscella.isSet(addr.street_number)) {
        if (Miscella.isSet(addr.street) && addr.street !== "") {
          fmt += " "
        }
        fmt += addr.street_number
      }

      // add sep "street street_number, "
      if (
        fmt !== "" &&
        ((Miscella.isSet(addr.city) && addr.city !== "") ||
          (Miscella.isSet(addr.postal_code) && addr.postal_code !== ""))
      ) {
        fmt += ", "
      }
      if (Miscella.isSet(addr.city)) {
        fmt += addr.city
      }
      if (Miscella.isSet(addr.postal_code)) {
        if (Miscella.isSet(addr.city) && addr.city !== "") {
          fmt += " "
        }
        fmt += addr.postal_code
      }

      // set country if not empty and not Slovensko
      if (
        Miscella.isSet(addr.country) &&
        addr.country !== "" &&
        addr.country !== "Slovensko"
      ) {
        // add sep "street street_number, city postal_code, "
        if (fmt !== "") {
          fmt += ", "
        }
        fmt += this.pacient.address.country
      }

      return fmt
    },
    alergiaText: function () {
      var pocet = 0
      if (
        Miscella.isSet(this.pacient) &&
        Miscella.isSet(this.pacient.alergie)
      ) {
        for (let al of this.pacient.alergie) {
          if (al.DatumUkoncenia == null) {
            pocet = pocet + 1
          }
        }
      }
      if (pocet > 0) {
        return pocet.toString()
      }
      if (this.pacient.no_allergy === true) {
        return this.$vuetify.t("Žiadna alergia")
      }
      return undefined
    },
    poistovnaText: function () {
      return Miscella.isEmpty(this.pacient.poistovna)
        ? ""
        : this.$vuetify.t("$vuetify.enum.poistovna." + this.pacient.poistovna)
    },
    charakterPoistencaText: function () {
      if (
        Miscella.isSet(this.pacient) &&
        Miscella.isSet(this.pacient.poistenie)
      ) {
        return this.$vuetify.t(
          "$vuetify.enum.charakterPoistenca." +
            this.pacient.poistenie.CharakterPoistenca
        )
      }
      return ""
    },
    objednanieText: function () {
      if (
        Miscella.isSet(this.pacient) &&
        Miscella.isSet(this.pacient.objednanie)
      ) {
        return (
          moment(this.pacient.objednanie.Datum, "YYYY-MM-DD").format(
            "DD.MM.YYYY"
          ) +
          " " +
          this.pacient.objednanie.CasOd
        )
      }
      return ""
    },
  },
  methods: {
    goToPoistovne: function () {
      this.$router.push({ name: "poistovne", params: { id: this.pacientId } })
    },
    goToPacientInfo: function (tab, action) {
      this.$router.push({
        name: "info",
        params: { id: this.pacientId, tab: tab },
        query: { action: action },
      })
    },
    goToAlergie: function () {
      this.$router.push({ name: "alergie", params: { id: this.pacientId } })
    },
    goToDiagnozy: function () {
      this.$router.push({ name: "diagnozy", params: { id: this.pacientId } })
    },
    goToPoslVysetrenie: function () {
      this.$router.push({
        name: "vysetrenie",
        params: { id: this.pacientId, vysetrenieID: this.pacient.poslNavsteva },
      })
    },
    goToKrvnaSkupina: function () {
      this.$router.push({ name: "skupina", params: { id: this.pacientId } })
    },
    openMail: function (aMail) {
      Miscella.openMail(aMail)
    },
    editKontrola: function () {
      let k = {}
      if (Miscella.isSet(this.pacient)) {
        if (Miscella.isSet(this.pacient.kontrola)) {
          k = this.pacient.kontrola
        }
      }
      this.$refs.dialogKontrola.edit(k)
    },
    readPacient: function () {
      this.$store.commit("setReloadPacient", true)
    },
    addItemKrvnaSkupina: function () {
      this.$refs.formKrvnaSkupina.new()
    },
    editItemKrvnaSkupina: function (item) {
      this.$refs.formKrvnaSkupina.edit(item)
    },
  },
}
</script>
