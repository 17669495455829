<template>
  <div>
    <slot name="dialog">
      <polozka-ciselnika-dialog
        ref="polozkaCiselnikaDialog"
      ></polozka-ciselnika-dialog>
    </slot>
    <ApiTable
      ref="apiTable"
      :headers="codebookHeaders"
      :url="cisItemsUrl"
      :title="title"
      :defaultSortBy="sortBy"
      :customParams="customParams"
    >
      <template v-slot:items="{ item }">
        <tr @click="viewItem(item)" v-if="isCodebookLiek">
          <slot name="item" :item="item">
            <td>{{ item.liek == null ? "" : item.liek.kod_sukl }}</td>
            <td>{{ item.liek == null ? "" : item.liek.atc.skratka }}</td>
            <td>
              {{ item.liek == null ? "" : item.liek.nazov }}
              {{ item.liek == null ? "" : item.liek.doplnok_nazvu }}
            </td>
            <td>
              {{
                item.liek == null ? "" : item.liek.kategorizacia ? "Áno" : "Nie"
              }}
            </td>
          </slot>
        </tr>
        <tr @click="viewItem(item)" v-else>
          <slot name="item" :item="item">
            <td class="text-md-left">{{ item.skratka }}</td>
            <td class="text-md-left">{{ item.display_name }}</td>
          </slot>
        </tr>
      </template>
      <template v-slot:title v-if="titles.length">
        <v-autocomplete
          v-model="currentCodebook"
          :items="titles"
          item-text="name"
          label=""
          @change="titleChange(item)"
          class="page-size dense"
          flat
          solo-inverted
          hide-details
          return-object
        >
        </v-autocomplete>
      </template>
      <template v-slot:toolbarItems>
        <v-tooltip top>
          <v-btn
            icon
            slot="activator"
            @click="overVerziuCiselnika()"
            :disabled="!isEhealthReady"
          >
            <v-icon>favorite</v-icon>
          </v-btn>
          <span>{{ $vuetify.t("Over verziu číselníka v ezdraví") }}</span>
        </v-tooltip>
      </template>
    </ApiTable>
    <liek-item-dialog ref="liekItemDialog"></liek-item-dialog>
    <BaseDialog
      :dialogTitle="$vuetify.t('Overenie verzie číselníka v ezdraví')"
      ref="overenieDialog"
      :showSaveButton="false"
    >
      <v-list v-if="overenieData">
        <v-list-tile>
          <v-list-tile-content>
            <v-list-tile-title>{{ $vuetify.t("Názov") }}</v-list-tile-title>
            <v-list-tile-sub-title>{{
              overenieData.Data.ZahlavieCiselnika.codingSchemeName
            }}</v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile>
          <v-list-tile-content>
            <v-list-tile-title>{{
              $vuetify.t("Aktuálna verzia")
            }}</v-list-tile-title>
            <v-list-tile-sub-title>{{
              overenieData.Data.ZahlavieCiselnika.codingSchemeVersion
            }}</v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile>
          <v-list-tile-content>
            <v-list-tile-title>{{
              $vuetify.t("Dátum poslednej zmeny")
            }}</v-list-tile-title>
            <v-list-tile-sub-title>{{
              overenieData.Data.ZahlavieCiselnika.DatumPoslednejZmeny
                | formatDatum
            }}</v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile>
          <v-list-tile-content>
            <v-list-tile-title>{{
              $vuetify.t("Platnosť od")
            }}</v-list-tile-title>
            <v-list-tile-sub-title>{{
              overenieData.Data.ZahlavieCiselnika.Platnost.DatumOd | formatDatum
            }}</v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile>
          <v-list-tile-content>
            <v-list-tile-title>{{
              $vuetify.t("Platnosť do")
            }}</v-list-tile-title>
            <v-list-tile-sub-title>{{
              overenieData.Data.ZahlavieCiselnika.Platnost.DatumDo | formatDatum
            }}</v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
      <!-- <h4>Request:</h4> -->
      <!-- <p>{{ chosen.request }}</p> -->
      <!-- <h4>Response:</h4> -->
      <!-- <p>{{ chosen.response }}</p> -->
    </BaseDialog>
  </div>
</template>

<script>
import * as enums from "@/plugins/enums.js"
import * as Miscella from "./../../miscella.js"
import ApiTable from "./../ApiTable.vue"
import BaseDialog from "@/components/BaseDialog.vue"
import PolozkaCiselnikaDialog from "./PolozkaCiselnikaDialog"
import LiekItemDialog from "@/components/ciselnikComponents/LiekItemDialog"
import { mapState, mapGetters } from "vuex"

export default {
  components: { ApiTable, BaseDialog, LiekItemDialog, PolozkaCiselnikaDialog },
  props: {
    sortBy: {
      type: String,
      required: false,
      default: "Skratka",
    },
    cisItemsUrl: {
      type: String,
      required: true,
    },
    customParams: {
      type: Object,
      required: false,
      default: function () {
        return {}
      },
    },
    headers: {
      type: Array,
      required: false,
      default: function () {
        return [
          {
            text: "Kód",
            align: "left",
            sortable: true,
            value: "Skratka",
            width: "10%",
          },
          {
            text: "Názov",
            value: "DisplayName",
            align: "left",
            sortable: true,
          },
        ]
      },
    },
    title: {
      type: String,
      required: true,
    },
    titles: {
      type: Array,
      required: false,
      default: function () {
        return []
      },
    },
  },
  data() {
    return {
      error: "",
      currentCodebook: null,
      selected: [],
      overenieData: null,
    }
  },
  computed: {
    ...mapState({
      ambulanceID: (state) => state.me?.ambulance?.id,
    }),
    ...mapGetters(["isEhealthReady"]),
    isCodebookLiek: function () {
      return this.currentCodebook?.id === enums.CodebookIDLiek
    },
    codebookHeaders: function () {
      let headers = this.headers
      if (this.isCodebookLiek) {
        headers = [
          {
            text: "Kód ŠÚKL",
            align: "left",
            sortable: true,
            value: "sukl",
            width: "10%",
          },
          {
            text: "ATC Kód",
            value: "atc",
            align: "left",
            sortable: true,
            width: "10%",
          },
          {
            text: "Názov",
            value: "nazov",
            align: "left",
            sortable: true,
          },
          {
            text: "Kategorizácia",
            align: "left",
          },
        ]
      }
      return headers
    },
  },
  methods: {
    viewItem: function (item) {
      if (this.isCodebookLiek) {
        this.$refs.liekItemDialog.show(item)
      } else {
        if (Miscella.isSet(this.$refs.polozkaCiselnikaDialog)) {
          this.$refs.polozkaCiselnikaDialog.show(item)
        }
      }
      this.$emit("click:item", item)
    },
    titleChange: function (item) {
      if (item === undefined) {
        item = this.currentCodebook
      }
      this.currentCodebook = item
      this.customParams.codebook_ids = [this.currentCodebook.id]
      this.$refs.apiTable.reload()
      console.log("Title change", this.currentCodebook)
    },
    overVerziuCiselnika: async function () {
      this.startAppLoading("Overuje sa verzia číselníka")
      try {
        var response = await this.$api.ehealthService.overVerziuCiselnikov({
          ambulance_id: this.ambulanceID,
          codebook_ids: [this.currentCodebook.id],
        })
        this.overenieData = JSON.parse(response.data)
        console.log(this.$refs)
        this.$refs.overenieDialog.open()
      } catch (error) {
        this.processEhealthError(
          error,
          "Pri overovaní verzie číselníka vznikla chyba"
        )
      } finally {
        this.stopAppLoading()
      }
    },
  },
}
</script>

<style scoped></style>
