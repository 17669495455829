<template>
  <titled-text :title="title">
    <span v-if="value.TextHTML" v-html="value.TextHTML.originalText"></span>
    <span v-if="value.Text">{{ value.Text.originalText }}</span>
  </titled-text>
</template>

<script>
import TitledText from "./TitledText.vue"

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  components: {
    TitledText,
  },
}
</script>
