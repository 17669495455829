<template>
  <v-form>
    <v-container fluid>
      <v-layout column>
        <div style="min-height: 450px">
          <v-container fluid grid-list-md class="pt-1 pb-1">
            <v-layout row>
              <v-flex xs12 md6>
                <DatePicker
                  v-model="model.date"
                  :label="$vuetify.t('Dátum')"
                  name="datum"
                  v-validate="'required'"
                ></DatePicker>
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs12 md6>
                <codebook-autocomplete
                  codebook-id="26"
                  name="vykonCodebook"
                  v-model="model.vykon.vykon_codebook"
                  :label="$vuetify.t('Výkon')"
                  :disabled="isSetKodVykonu"
                >
                  <template v-slot:selection="{ item }"
                    ><span
                      >{{ item.skratka }} - {{ item.display_name }}</span
                    ></template
                  >
                  <template v-slot:item="{ item }">
                    <v-list-tile-content>
                      <v-list-tile-title
                        v-html="item.skratka"
                      ></v-list-tile-title>
                      <v-list-tile-sub-title
                        v-html="item.display_name"
                      ></v-list-tile-sub-title>
                    </v-list-tile-content>
                  </template>
                </codebook-autocomplete>
              </v-flex>
              <v-flex xs12 md6>
                <TextField
                  v-model="model.vykon.kod_vykonu"
                  :label="$vuetify.t('Kód výkonu')"
                  name="kodVykonu"
                  :disabled="isSetVykonCodebook"
                ></TextField>
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs12 md6>
                <codebook-autocomplete
                  codebook-id="25"
                  name="diagnoza"
                  v-model="model.vykon.diagnoza"
                  :label="$vuetify.t('Diagnóza')"
                  v-validate="'required'"
                >
                  <template v-slot:selection="{ item }"
                    ><span
                      >{{ item.skratka }} - {{ item.display_name }}</span
                    ></template
                  >
                  <template v-slot:item="{ item }">
                    <v-list-tile-content>
                      <v-list-tile-title
                        v-html="item.skratka"
                      ></v-list-tile-title>
                      <v-list-tile-sub-title
                        v-html="item.display_name"
                      ></v-list-tile-sub-title>
                    </v-list-tile-content>
                  </template>
                </codebook-autocomplete>
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs12 md3>
                <TextField
                  v-model="model.vykon.pocet"
                  type="number"
                  :label="$vuetify.t('Početnosť výkonu')"
                  name="pocet"
                  v-validate="('numeric', 'required')"
                ></TextField>
              </v-flex>
              <v-flex xs12 md3>
                <TextField
                  v-model="model.vykon.body"
                  type="number"
                  :label="$vuetify.t('Počet bodov')"
                  name="body"
                  v-validate="'numeric'"
                ></TextField>
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs12 md3>
                <SelectEnum
                  enumName="typPoistenca"
                  v-model="model.vykon.typ_poistenca"
                  :label="$vuetify.t('Typ poistenca')"
                  name="typPoistenca"
                >
                </SelectEnum>
              </v-flex>
              <v-flex xs12 md3>
                <SelectEnum
                  enumName="typZariadenia"
                  v-model="model.vykon.pohyb_poistenca"
                  :label="$vuetify.t('Pohyb poistenca')"
                  name="pohybPoistenca"
                >
                </SelectEnum>
              </v-flex>
              <v-flex xs12 md3>
                <codebook-autocomplete
                  codebook-id="39"
                  name="pohybPoistencaOdbornost"
                  v-model="model.vykon.pohyb_poistenca_odbornost"
                  :label="
                    $vuetify.t(
                      'Pohyb poistenca - odborné zameranie v zariadení'
                    )
                  "
                >
                </codebook-autocomplete>
              </v-flex>
              <v-flex xs12 md3>
                <SelectEnum
                  enumName="nahrada"
                  v-model="model.vykon.nahrada"
                  :label="$vuetify.t('Náhrada')"
                  name="nahrada"
                >
                </SelectEnum>
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs12>
                <v-textarea
                  v-model="model.vykon.poznamka"
                  :label="$vuetify.t('Poznámka')"
                ></v-textarea>
              </v-flex>
            </v-layout>
            <template
              v-for="(polozka, index) in this.model.vykon
                .pripocitatelne_polozky"
            >
              <v-layout row :key="index">
                <v-flex md4 xs6>
                  <v-combobox
                    v-model="polozka.kod"
                    :label="$vuetify.t('Kód položky')"
                    :data-vv-name="'pripocitatelnaPolozkaKod' + index"
                    :disabled="isSetPolozkaLiek(polozka)"
                    :items="items"
                    :return-object="false"
                    :error-messages="
                      errors.collect('pripocitatelnaPolozkaKod' + index)
                    "
                    clearable
                  >
                  </v-combobox>
                </v-flex>

                <v-flex md4 xs6>
                  <codebook-autocomplete
                    codebook-id="100001"
                    v-model="model.vykon.pripocitatelne_polozky[index].liek"
                    :label="$vuetify.t('Liek')"
                    :name="'pripocitatelnaPolozkaLiek' + index"
                    :disabled="isSetPolozkaKod(polozka)"
                  >
                  </codebook-autocomplete>
                </v-flex>
                <v-flex md2 xs6>
                  <TextField
                    type="number"
                    v-model="model.vykon.pripocitatelne_polozky[index].mnozstvo"
                    :label="$vuetify.t('množstvo')"
                    :name="'pripocitatelnaPolozkaMnozstvo' + index"
                  >
                  </TextField>
                </v-flex>
                <v-flex md2 xs6>
                  <TextField
                    type="number"
                    v-model="model.vykon.pripocitatelne_polozky[index].cena"
                    :label="$vuetify.t('cena')"
                    :name="'pripocitatelnaPolozkaCena' + index"
                  >
                  </TextField>
                </v-flex>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon @click="removePolozka(index)" v-on="on">
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $vuetify.t("Odstrániť") }}</span>
                </v-tooltip>
              </v-layout>
            </template>
            <div class="text-xs-center" v-if="isSetModel">
              <v-btn
                @click="
                  model.vykon.pripocitatelne_polozky.push({
                    kod: null,
                    liek: null,
                    mnozstvo: 0,
                    cena: 0,
                  })
                "
                >{{ $vuetify.t("Pridať pripočítateľnú položku")
                }}<v-icon right>add</v-icon></v-btn
              >
            </div>
          </v-container>
        </div>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
import BaseForm from "./../BaseForm.vue"
import moment from "moment"
import * as Miscella from "./../../miscella.js"
import TextField from "./../TextField.vue"
import DatePicker from "./../DatePicker.vue"
import CodebookAutocomplete from "./../ciselnikComponents/CodebookAutocomplete.vue"
import SelectEnum from "./../ciselnikComponents/SelectEnum.vue"
import * as SK from "../../plugins/sk.js"
import { mapState } from "vuex"

export default {
  name: "FormPacientVykon",
  extends: BaseForm,
  components: {
    DatePicker,
    CodebookAutocomplete,
    TextField,
    SelectEnum,
  },
  props: {
    pacientId: {
      type: String,
      required: true,
    },
  },
  $_veeValidate: {
    validator: "new", // give me my own validator scope.
  },
  data: function () {
    return {
      model: null,
      items: Array(),
    }
  },
  computed: {
    isSetModel: function () {
      return Miscella.isSet(this.model)
    },

    isSetVykonCodebook: function () {
      return Miscella.isSet(this.model?.vykon?.vykon_codebook)
    },
    isSetKodVykonu: function () {
      return !Miscella.isEmpty(this.model?.vykon?.kod_vykonu)
    },
    ...mapState({
      ambulanceId: (state) => state.me?.ambulance?.id,
    }),
  },
  watch: {},
  methods: {
    isSetPolozkaKod: function (polozka) {
      return Miscella.isSet(polozka.kod)
    },
    isSetPolozkaLiek: function (polozka) {
      return Miscella.isSet(polozka.liek)
    },
    new: function () {
      this.init()
      this.$emit("activated")
    },
    edit: function (item) {
      this.init()
      this.model = Miscella.cloneObject(item)
      this.$emit("activated")
    },
    init: function () {
      console.log("init")
      this.model = {
        id: "",
        patient_id: this.pacientId,
        ambulance_id: this.ambulanceId,
        date: moment().format(),
        parent_id: "",
        vykon: {
          vykon_codebook: null,
          kod_vykonu: null,
          typ_poistenca: null,
          pohyb_poistenca: null,
          pohyb_poistenca_odbornost: null,
          nahrada: null,
          pocet: 1,
          body: null,
          cena_bodu: null,
          cena: null,
          diagnoza: null,
          poznamka: null,
          pripocitatelne_polozky: [],
        },
      }
      for (const [key, value] of Object.entries(
        SK.enum["pripocitatelnaPolozkaKod"]
      )) {
        console.log(`${key}: ${value}`)
        this.items.push({
          value: key,
          text: value,
        })
      }
      this.$validator.reset()
      this.errors.clear()
    },
    removePolozka: function (index) {
      this.model.vykon.pripocitatelne_polozky.splice(index, 1)
    },
    validate: async function () {
      var valid = await this.$validator.validateAll()
      for (let index in this.model.vykon.pripocitatelne_polozky) {
        if (
          !Miscella.isSet(this.model.vykon.pripocitatelne_polozky[index].kod) &&
          !Miscella.isSet(this.model.vykon.pripocitatelne_polozky[index].liek)
        ) {
          valid = false
          this.errors.add({
            field: "pripocitatelnaPolozkaLiek" + index,
            msg: this.$vuetify.t(
              "Musí byť vyplnený kód položky alebo kód lieku"
            ),
          })
          this.errors.add({
            field: "pripocitatelnaPolozkaKod" + index,
            msg: this.$vuetify.t(
              "Musí byť vyplnený kód položky alebo kód lieku"
            ),
          })
        }
      }
      return valid
    },
    save: async function () {
      try {
        var params = { thing: Miscella.cloneObject(this.model) }
        var result = await this.$api.thingService.saveThing(params)
        this.notifySuccess("Výkon bol úspešne uložený")
        this.$emit("saved", result.thing)
        this.model = result.thing
        console.log(this.model)
      } catch (err) {
        this.processError(err, "Pri ukladaní výkonu vznikla chyba")
      }
    },
  },
  mounted: function () {},
  created: function () {
    this.init()
  },
}
</script>
