<template>
  <v-layout row wrap class="ockovania">
    <v-flex xs12 class="mb-3">
      <v-subheader v-if="title">{{ title }}</v-subheader>
      <v-list subheader dense>
        <template v-for="(item, index) in items">
          <v-card :key="'list' + index" class="mb-2">
            <v-hover v-slot="{ hover }">
              <v-list-tile class="ockovanie-list-row" @click="editItem(item)">
                <v-list-tile-content>
                  <OckovanieRow :item="item" />
                </v-list-tile-content>
                <v-list-tile-action
                  d-flex
                  style="
                    flex-direction: row;
                    justify-content: flex-end;
                    width: 66px;
                  "
                >
                  <v-tooltip left v-show="hover">
                    <v-icon
                      slot="activator"
                      v-if="isVykonane(item)"
                      @click.stop="addReakciaNaOckovanie(item)"
                      >add</v-icon
                    >
                    <span>{{ $vuetify.t("Pridať reakciu na očkovanie") }}</span>
                  </v-tooltip>
                  <!-- <v-tooltip left> -->
                  <!--   <v-icon -->
                  <!--     slot="activator" -->
                  <!--     v-if="showSendToEhealth(item)" -->
                  <!--     :disabled="!canSendToEhealth(item)" -->
                  <!--     @click.stop="sendToEhealth(item, 'ThingOckovanie')" -->
                  <!--     >upload</v-icon -->
                  <!--   > -->
                  <!--   <span>{{ $vuetify.t("Odoslať do ezdravia") }}</span> -->
                  <!-- </v-tooltip> -->
                  <v-tooltip left v-show="hover">
                    <v-icon
                      slot="activator"
                      :disabled="!isEditable(item)"
                      @click.stop="deleteItem(item)"
                      >delete</v-icon
                    >
                    <span>{{ $vuetify.t("Odstrániť") }}</span>
                  </v-tooltip>
                  <v-menu open-on-hover bottom offset-y v-show="hover">
                    <!-- <v-btn flat icon slot="activator"> -->
                    <v-icon slot="activator">favorite_border</v-icon>
                    <!-- </v-btn> -->

                    <v-card>
                      <v-list dense>
                        <template
                          v-for="menuitem in [
                            {
                              title: 'Uložiť do ezdravia',
                              icon: 'cloud_upload',
                              handler: sendToEhealth,
                              disabled: !canSendToEhealth(item),
                            },
                            /*{
                            title: 'Storno v ezdraví',
                            icon: 'delete',
                            handler: deleteInEhealth,
                            disabled: !canDeleteInEhealth,
                          },*/
                          ]"
                        >
                          <v-list-tile
                            :key="menuitem.title"
                            @click="menuitem.handler(item)"
                            :disabled="menuitem.disabled"
                          >
                            <!-- TODO zobrazit aj ikonu? -->
                            <!-- <v-list-tile-action> -->
                            <!--   <v-tooltip left> -->
                            <!--     <template v-slot:activator="{ on }"> -->
                            <!--       <v-icon small v-on="on" color="primary">{{ -->
                            <!--         item.icon -->
                            <!--       }}</v-icon> -->
                            <!--     </template> -->
                            <!--     <span>{{ $vuetify.t(item.title) }}</span> -->
                            <!--   </v-tooltip> -->
                            <!-- </v-list-tile-action> -->

                            <v-list-tile-content>
                              <v-list-tile-title>{{
                                menuitem.title
                              }}</v-list-tile-title>
                            </v-list-tile-content>
                          </v-list-tile>
                        </template>
                      </v-list>
                    </v-card>
                  </v-menu>
                </v-list-tile-action>
              </v-list-tile>
            </v-hover>
            <v-subheader
              v-if="
                item.ockovanie.neziaduce_reakcie &&
                item.ockovanie.neziaduce_reakcie.length > 0
              "
              :key="'reakcia-title' + index"
              class="reakcia-list-row pt-1 pl-2"
            >
              <span class="caption">{{ $vuetify.t("Nežiadúce reakcie") }}</span>
            </v-subheader>
            <template
              v-for="(reakcia, reakciaIndex) in item.ockovanie
                .neziaduce_reakcie"
            >
              <v-hover
                v-slot="{ hover }"
                :key="'reakcia-list-row' + index + reakciaIndex"
              >
                <v-list-tile
                  class="reakcia-list-row"
                  @click="editReakciaNaOckovanie(reakcia)"
                >
                  <v-list-tile-content>
                    <NeziaducaReakciaRow :reakcia="reakcia" />
                  </v-list-tile-content>
                  <v-list-tile-action
                    d-flex
                    style="
                      flex-direction: row;
                      justify-content: flex-end;
                      width: 66px;
                    "
                  >
                    <!-- <v-tooltip left> -->
                    <!--   <v-icon -->
                    <!--     slot="activator" -->
                    <!--     v-if="showSendReakciaToEhealth(item)" -->
                    <!--     :disabled="!canSendToEhealth(reakcia)" -->
                    <!--     @click.stop="sendToEhealth(reakcia)" -->
                    <!--     >upload</v-icon -->
                    <!--   > -->
                    <!--   <span>{{ $vuetify.t("Odoslať do ezdravia") }}</span> -->
                    <!-- </v-tooltip> -->
                    <v-tooltip left v-show="hover">
                      <v-icon
                        slot="activator"
                        :disabled="!isEditable(reakcia)"
                        @click.stop="$emit('deleteReakciaNaOckovanie', reakcia)"
                        >delete</v-icon
                      >
                      <span>{{ $vuetify.t("Odstrániť") }}</span>
                    </v-tooltip>
                    <v-menu open-on-hover bottom offset-y v-show="hover">
                      <!-- <v-btn flat icon slot="activator"> -->
                      <v-icon slot="activator">favorite_border</v-icon>
                      <!-- </v-btn> -->

                      <v-card>
                        <v-list dense>
                          <template
                            v-for="menuitem in [
                              {
                                title: 'Uložiť do ezdravia',
                                icon: 'cloud_upload',
                                handler: sendToEhealth,
                                disabled: !canSendReakciaToEhealth(
                                  item,
                                  reakcia
                                ),
                              },
                              /*{
                            title: 'Storno v ezdraví',
                            icon: 'delete',
                            handler: deleteInEhealth,
                            disabled: !canDeleteInEhealth,
                          },*/
                            ]"
                          >
                            <v-list-tile
                              :key="menuitem.title"
                              @click="menuitem.handler(reakcia)"
                              :disabled="menuitem.disabled"
                            >
                              <!-- TODO zobrazit aj ikonu? -->
                              <!-- <v-list-tile-action> -->
                              <!--   <v-tooltip left> -->
                              <!--     <template v-slot:activator="{ on }"> -->
                              <!--       <v-icon small v-on="on" color="primary">{{ -->
                              <!--         item.icon -->
                              <!--       }}</v-icon> -->
                              <!--     </template> -->
                              <!--     <span>{{ $vuetify.t(item.title) }}</span> -->
                              <!--   </v-tooltip> -->
                              <!-- </v-list-tile-action> -->

                              <v-list-tile-content>
                                <v-list-tile-title>{{
                                  menuitem.title
                                }}</v-list-tile-title>
                              </v-list-tile-content>
                            </v-list-tile>
                          </template>
                        </v-list>
                      </v-card>
                    </v-menu>
                  </v-list-tile-action>
                </v-list-tile>
              </v-hover>
            </template>
            <!-- <v-divider -->
            <!--   :key="'divider' + index" -->
            <!--   v-if="index !== item.length - 1" -->
            <!-- ></v-divider> -->
          </v-card>
        </template>
      </v-list>
    </v-flex>
  </v-layout>
</template>

<script>
import * as enums from "@/plugins/enums.js"
import { mapState, mapGetters } from "vuex"
import * as Miscella from "./../miscella.js"
import OckovanieRow from "@/components/rows/OckovanieRow.vue"
import NeziaducaReakciaRow from "@/components/rows/NeziaducaReakciaRow.vue"
import thingMixin from "@/components/mixins/thing.js"

export default {
  components: { NeziaducaReakciaRow, OckovanieRow },
  mixins: [thingMixin],
  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  data: function () {
    return {
      enums: enums,
    }
  },
  computed: {
    ...mapState({
      ambulance: (state) => state.me.ambulance,
      ambulanceID: (state) => state.me?.ambulance?.id,
    }),
    ...mapGetters(["isEhealthSetUp", "isEhealthReady"]),
  },
  methods: {
    editItem: function (item) {
      this.$emit("editItem", {
        ockovanie: item,
        readonly: !this.isEditable(item),
      })
    },
    deleteItem: function (item) {
      this.$emit("deleteItem", item)
    },
    addReakciaNaOckovanie: function (item) {
      this.$emit("addReakciaNaOckovanie", item)
    },
    editReakciaNaOckovanie: function (reakcia) {
      this.$emit("reakcia:edit", {
        reakcia: reakcia,
        readonly: !this.isEditable(reakcia),
      })
    },
    showSendToEhealth: function (item) {
      return this.isVykonane(item)
    },
    isVykonane: function (item) {
      return item.ockovanie.stav === enums.StavOckovania.Vykonane
    },
    hasEhealthRecord: function (item) {
      return Miscella.isSet(item.ehealth_record)
    },
    isEditable: function (item) {
      return (
        !this.hasEhealthRecord(item) ||
        (item.ehealth_record.version_status !==
          enums.EhrVersionStatus.Deleted &&
          (item.ehealth_record.status === enums.EhealthRecordStatus.NA ||
            item.ehealth_record.status === enums.EhealthRecordStatus.Error))
      )
      /*return (
        !Miscella.isSet(item.ockovanie.EZdravieZaznam) ||
        item.ockovanie.EZdravieZaznam.stav === enums.EZdravieStav.new ||
        item.ockovanie.EZdravieZaznam.stav === enums.EZdravieStav.error
      )*/
    },
    isEhrError: function (item) {
      return (
        this.hasEhealthRecord(item) &&
        item.ehealth_record.status === enums.EhealthRecordStatus.Error
      )
    },
    isEhrSuccess: function (item) {
      return (
        this.hasEhealthRecord(item) &&
        item.ehealth_record.status === enums.EhealthRecordStatus.Success
      )
    },
    isEhrVersionFinal: function (item) {
      return (
        this.hasEhealthRecord(item) &&
        item.ehealth_record.version_status === enums.EhrVersionStatus.Final
      )
    },
    canSendToEhealth: function (item) {
      return (
        this.isEhealthSetUp &&
        (this.isEditable(item) ||
          (this.isEhrVersionFinal(item) && this.isEhrError(item)))
      )
      /*return (
        this.ehealthEnabled &&
        (!Miscella.isSet(item.ockovanie.EZdravieZaznam) ||
          item.ockovanie.EZdravieZaznam.stav === enums.EZdravieStav.new ||
          item.ockovanie.EZdravieZaznam.stav === enums.EZdravieStav.error)
      )*/
    },
    canSendReakciaToEhealth: function (ockovanie, reakcia) {
      return this.isEhrSuccess(ockovanie) && this.canSendToEhealth(reakcia)
    },
    showSendReakciaToEhealth: function (ockovanie) {
      return this.showSendToEhealth(ockovanie) && this.isEhrSuccess(ockovanie)
    },
    sendToEhealth: async function (item) {
      try {
        var params = {
          ambulance_id: this.ambulanceID,
          patient_id: this.id,
          object_id: item.id,
          version_status: enums.EhrVersionStatus.Final,
        }
        switch (this._getThingType(item)) {
          case "ThingOckovanie":
            params.object_type = "ZAPIS_OCKOVANIE"
            break
          case "ThingNeziaducaReakcia":
            params.object_type = "ZAPIS_REAKCIE_NA_OCKOVANIE"
            break
          default:
            throw "Nesprávny typ objektu"
        }
        var result = await this.$api.ehealthService.sendToEhealth(params)
      } catch (e) {
        console.log("ERROR saveToEhealth: ", e)
        this.processError(e, "Pri odosielaní objektu do ezdravia vznikla chyba")
      }
    },
  },
  mounted: function () {},
}
</script>
