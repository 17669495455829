var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-content',[_c('ApiTable',{ref:"table",attrs:{"headers":_vm.headers,"url":"/v1/proto.PatientService/ListPatients","title":_vm.$vuetify.t('Zoznam pacientov'),"filterTooltip":"Vyhľadávanie v položkách Rodné číslo, Meno, Priezvisko, Telefón, Email","defaultSortBy":"","customParams":{
      clinic_id: this.$store.state.me.clinic
        ? this.$store.state.me.clinic.id
        : null,
      ambulance_id: this.$store.state.me.ambulance
        ? this.$store.state.me.ambulance.id
        : null,
    },"responseItems":"patients"},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('tr',{on:{"click":function($event){return _vm.itemClicked(props.item)}}},[_c('td',{staticClass:"text-md-left"},[_c('v-avatar',{attrs:{"color":"red","size":"32"}},[_c('img',{attrs:{"alt":"","src":_vm.defaultText(
                  props.item.imageLink,
                  '//www.gravatar.com/avatar/xxx?d=mp'
                )}})])],1),_c('td',{staticClass:"text-md-left"},[_vm._v(_vm._s(props.item.person_unique_id))]),_c('td',{staticClass:"text-md-left"},[_vm._v(_vm._s(props.item.name))]),_c('td',{staticClass:"text-md-left"},[_vm._v(_vm._s(props.item.surname))]),_c('td',{staticClass:"text-md-left"},[_vm._v(_vm._s(props.item.phone))]),_c('td',{staticClass:"text-md-left"},[_vm._v(_vm._s(props.item.email))]),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatDatum")(props.item.posledne_vysetrenie_na_ambulancii))+" ")]),_c('td',[_c('v-tooltip',{attrs:{"top":""}},[_c('span',{attrs:{"slot":"activator"},slot:"activator"},[_c('v-icon',{attrs:{"color":_vm.jePacientVCakarni(props.item) ? 'success' : 'none'},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.setPacientVCakarni(props.item)}}},[_vm._v("airline_seat_recline_normal ")])],1),_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.t( "$vuetify.pacient.cakaren." + _vm.jePacientVCakarni(props.item) ))+" ")])])],1)])]}}])},[_c('template',{slot:"toolbarItems"},[_c('v-tooltip',{attrs:{"top":""}},[_c('v-btn',{attrs:{"slot":"activator","icon":""},on:{"click":_vm.addPacient},slot:"activator"},[_c('v-icon',[_vm._v("add")])],1),_c('span',[_vm._v(_vm._s(_vm.$vuetify.t("Pridať")))])],1)],1)],2),_c('BaseDialog',{ref:"dialog",attrs:{"dialogTitle":_vm.$vuetify.t('Nový pacient')}},[_c('FormPacient',{ref:"form",attrs:{"width":"75%"},on:{"saved":_vm.savedPacient}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }