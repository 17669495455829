<template>
  <v-content app>
    <v-card height="95%" class="dashboard-panel ma-3 elevation-5">
      <v-toolbar dense flat card>
        <v-toolbar-side-icon
          @click="navigation = !navigation"
        ></v-toolbar-side-icon>
        <v-toolbar-title>{{ currentDateLabel }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items style="align-items: center">
          <calendar-navigation
            v-model="type"
            @prev="$refs.calendar.prev()"
            @next="$refs.calendar.next()"
          />
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text style="display: flex">
        <v-navigation-drawer permanent v-if="navigation" class="px-2">
          <v-date-picker
            v-if="$refs.calendar && $refs.calendar.events !== null"
            v-model="eventDate"
            :events="eventColor"
            full-width
            locale="sk"
            :first-day-of-week="1"
            :day-format="dayFormat"
            :no-title="true"
            flat
          ></v-date-picker>
          <v-layout column v-if="type !== 'month'">
            <v-subheader class="pl-0">{{
              $vuetify.t("Rozsah zobrazených hodín")
            }}</v-subheader>
            <v-range-slider
              v-model="range"
              :max="24"
              :min="0"
              thumb-label="always"
              :thumb-size="24"
              ticks
            ></v-range-slider>
          </v-layout>
          <v-layout row>
            <v-list dense subheader>
              <v-subheader>{{ $vuetify.t("Legenda") }}</v-subheader>
              <v-list-tile v-for="item in legend" :key="item.type" class="px-0">
                <v-list-tile-avatar>
                  <div
                    class="legend-color"
                    :class="getColorOfEventDay(item.type)"
                  ></div>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  {{ $vuetify.t(item.title) }}
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
          </v-layout>
        </v-navigation-drawer>
        <v-layout row fill-height>
          <calendar ref="calendar" :type="type" v-model="eventDate" />
        </v-layout>
      </v-card-text>
    </v-card>
  </v-content>
</template>

<script>
import Calendar from "./../components/Calendar.vue"
import CalendarNavigation from "./../components/CalendarNavigation.vue"
import * as enums from "@/plugins/enums.js"
import moment from "moment"
import * as Miscella from "./../miscella.js"
import calendarMixin from "@/components/mixins/calendar.js"

const DATE_FORMAT = "YYYY-MM-DD"
const CURRENT_DATE_LABEL_FORMAT = "MMMM YYYY"

export default {
  name: "calendarPage",
  mixins: [calendarMixin],
  components: { Calendar, CalendarNavigation },
  data() {
    return {
      eventDate: moment().format(DATE_FORMAT),
      navigation: true,
      events: [],
      morePopup: {},
    }
  },
  computed: {
    legend: function () {
      return [
        {
          type: enums.TypEventu.TypEventuKalendar,
          title: "udalosti ambulancie",
        },
        {
          type: enums.TypEventu.TypEventuObjednanie,
          title: "udalosti pacienta",
        },
        {
          type: enums.TypEventu.TypEventuKontrola,
          title: "kontroly pacienta",
        },
        {
          type: enums.TypEventu.TypEventuObjednanieOrders,
          title: "objednávací systém",
        },
      ]
    },
    currentDateLabel: function () {
      return moment(this.eventDate).format(CURRENT_DATE_LABEL_FORMAT)
    },
    type: {
      get: function () {
        return this.$store.state.calendar.type
      },
      set: function (newValue) {
        this.$store.commit("setCalendarType", newValue)
        localStorage.setItem(
          "calendar",
          JSON.stringify(this.$store.state.calendar)
        )
      },
    },
    range: {
      get: function () {
        return this.$store.state.calendar.range
      },
      set: function (newValue) {
        this.$store.commit("setCalendarRange", newValue)
        localStorage.setItem(
          "calendar",
          JSON.stringify(this.$store.state.calendar)
        )
      },
    },
  },
  methods: {
    dayFormat: function (value) {
      var d = moment(value, DATE_FORMAT)
      return d.date()
    },
    intervalFormat: function (interval) {
      return interval.time
    },
    eventColor: function (date) {
      let arr = []
      if (this.$refs.calendar) {
        var eventsMap = this.$refs.calendar.eventsMap
        if (eventsMap[date]) {
          for (let event of eventsMap[date]) {
            if (event.Typ === "OBJEDNANIE_ORDERS") {
              arr.push(
                this.getColorOfEventDay(
                  enums.TypEventu.TypEventuObjednanieOrders
                )
              )
            } else if (!Miscella.isEmpty(event.PatientId)) {
              arr.push(
                this.getColorOfEventDay(enums.TypEventu.TypEventuObjednanie)
              )
            } else {
              arr.push(
                this.getColorOfEventDay(enums.TypEventu.TypEventuKalendar)
              )
            }
          }
        }
      }
      // vrati pole s bez duplicit
      return Array.from(new Set(arr))
    },
  },
  mounted: function () {
    var calendar = localStorage.getItem("calendar")
    if (Miscella.isSet(calendar)) {
      this.$store.commit("setCalendar", JSON.parse(calendar))
    }
  },
}
</script>

<style>
.legend-color {
  border-radius: 50%;
  display: inline-block;
  height: 16px;
  width: 16px;
}
</style>
