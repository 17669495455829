import BaseApiService from "./baseApiService.js"

export default class AmbulanceService extends BaseApiService {
  constructor(api) {
    super(
      api,
      "/v1/proto.AmbulanceService/GetAmbulance",
      "/v1/proto.AmbulanceService/CreateAmbulance",
      "/v1/proto.AmbulanceService/UpdateAmbulance",
      "/v1/proto.AmbulanceChatService/ListChats",
      "/v1/proto.AmbulanceChatService/ListMessages",
      "/v1/proto.AmbulanceChatService/SendMessage",
      "/v1/proto.AmbulanceChatService/MarkChatAsRead"
    )
  }
}
