var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-layout',{attrs:{"row":"","wrap":"","align-content-center":"","fill-height":"","justify-center":""}},[_c('v-container',{attrs:{"ma-0":"","pa-0":""}},[_c('v-layout',{attrs:{"row":""}},[_c('v-layout',{attrs:{"row":!_vm.expanded,"column":_vm.expanded}},[_c('v-flex',[_c('info-icon',{attrs:{"iconName":"cake","description":_vm.$vuetify.t(
                _vm.rcIsValid(_vm.pacient.person_unique_id)
                  ? 'Rodné číslo'
                  : 'Rodné číslo - nesprávne zadané!'
              ),"value":_vm.pacient.person_unique_id,"expanded":_vm.expanded,"valid":_vm.rcIsValid(_vm.pacient.person_unique_id)},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.goToPacientInfo('0', 'editInfo')}}},[_c('template',{slot:"extraText"},[(_vm.jeDovera && _vm.pacient.RC !== '')?_c('span',{staticClass:"pl-2 pt-1"},[_c('a',{on:{"click":function($event){$event.stopPropagation();return _vm.openDovera()}}},[_c('b',[_vm._v("On-line")])])]):_vm._e()])],2)],1),_c('v-flex',[_c('info-icon',{attrs:{"iconName":"fas fa-tint","description":_vm.$vuetify.t('Krvná skupina'),"value":_vm.pacient.blood_type,"expanded":_vm.expanded},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.goToKrvnaSkupina()}}})],1),_c('v-flex',[_c('info-icon',{attrs:{"iconName":"fas fa-briefcase-medical","description":_vm.$vuetify.t('Poisťovňa'),"value":_vm.pacient.zdravotna_poistovna
                ? _vm.pacient.zdravotna_poistovna.display_name
                : '',"expanded":_vm.expanded},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.goToKrvnaSkupina()}}})],1)],1),_c('v-layout',{attrs:{"row":!_vm.expanded,"column":_vm.expanded}},[_c('v-flex',[_c('info-icon',{attrs:{"iconName":"accessible","description":_vm.$vuetify.t('Dispenzárne diagnózy'),"value":_vm.pacient.dispenzarne_diagnozy_txt,"expanded":_vm.expanded,"html":""},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.goToDiagnozy()}}})],1),_c('v-flex',[_c('info-icon',{attrs:{"iconName":"calendar_today","description":_vm.$vuetify.t('Posledné vyšetrenie'),"value":_vm._f("formatDatumACas")(_vm.pacient.posledne_vysetrenie
                ? _vm.pacient.posledne_vysetrenie.date
                : ''),"expanded":_vm.expanded},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.goToPoslVysetrenie()}}})],1)],1),_c('v-layout',{attrs:{"row":!_vm.expanded,"column":_vm.expanded,"wrap":""}},[_c('v-flex',[_c('info-icon',{attrs:{"iconName":"phone","description":_vm.$vuetify.t('Telefónne číslo'),"value":_vm.pacient.phone,"expanded":_vm.expanded},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.goToPacientInfo('1', 'editInfoAddress')}}})],1),_c('v-flex',[_c('info-icon',{attrs:{"iconName":"email","description":_vm.$vuetify.t('Email'),"value":_vm.pacient.email,"expanded":_vm.expanded},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.openMail(_vm.pacient.email)}}})],1),_c('v-flex',[_c('info-icon',{attrs:{"iconName":"place","description":_vm.$vuetify.t('Adresa'),"value":_vm.adresaText,"expanded":_vm.expanded},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.goToPacientInfo('1', 'editInfoAddress')}}})],1)],1)],1),(_vm.expanded)?_c('v-layout',{attrs:{"row":""}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }