export default class Utils {
  static getUserContext(ouPzs) {
    return {
      CodingSchemeVersion: this.getCodebookVersion(ouPzs.zameranie),
      CodeValue: ouPzs.zameranie.ID,
      Extension: ouPzs.jruzId,
      RootOID: Utils.asOid("40.70"),
    }
  }

  static getCodebookVersion(codebook) {
    var cbKeys = Object.keys(codebook.DisplayNames)
    return Number(cbKeys[cbKeys.length - 1])
  }

  static asCS(codebook, codebookOidSuffix, version) {
    if (codebook !== null) {
      return {
        CodingScheme: this.asOid("10." + codebookOidSuffix),
        CodingSchemeVersion:
          version !== undefined ? version : this.getCodebookVersion(codebook),
        CodeValue: codebook.ID,
      }
    }
    return null
  }

  static asCV(codebook, codebookOidSuffix, version) {
    if (codebook !== null) {
      return {
        CodingScheme: this.asOid("10." + codebookOidSuffix),
        CodingSchemeVersion:
          version !== undefined ? version : this.getCodebookVersion(codebook),
        CodeValue: codebook.ID,
        DisplayName: codebook.DisplayName,
      }
    }
    return null
  }

  static asOid(oidSuffix) {
    return "1.3.158.00165387.100." + oidSuffix
  }

  static asII(extId, oidSuffix) {
    return { Extension: extId, Root: this.asOid(oidSuffix) }
  }

  static asID(extId, oidSuffix) {
    if (extId && extId.length !== 0) {
      return { ID_EX: extId, ID_RT: this.asOid(oidSuffix) }
    }
    return null
  }

  static asPQ(value, units) {
    return {
      value: value,
      units: units,
    }
  }

  static asDiagnoza(thingDiagnoza) {
    return {
      KodDiagnozy: Utils.asCV(thingDiagnoza.Diagnoza, "25"),
      Upresnenie: thingDiagnoza.Poznamka,
    }
  }
}
