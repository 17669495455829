<template>
  <v-container fluid>
    <v-layout row>
      <v-flex class="text-xs-right" xs12>
        <v-btn color="primary" flat @click="addItem" align-self-end>{{
          $vuetify.t("Pridať")
        }}</v-btn>
      </v-flex>
    </v-layout>
    <LiekyList
      ref="listOpakovane"
      :items="items1"
      :title="$vuetify.t('Opakované aktuálne recepty')"
      @addItem="addItem"
      @editItem="editItem"
    />
    <LiekyList
      ref="listAktualne"
      :items="items2"
      :title="$vuetify.t('Aktuálne recepty')"
      @addItem="addItem"
      @editItem="editItem"
    />
    <LiekyList
      ref="listNeaktualne"
      :items="items3"
      :title="$vuetify.t('Neaktuálne recepty')"
      @addItem="addItem"
      @editItem="editItem"
    />
    <base-dialog
      :dialogTitle="$vuetify.t('Recept')"
      ref="dialog"
      :closeOnSave="false"
      width="90%"
    >
      <FormRecept
        ref="form"
        :pacientId="id"
        @saved="savedItem"
        @deleted="savedItem"
      ></FormRecept>
      <template v-slot:buttons>
        <buttons-form-recept
          @close="$refs.dialog.close()"
          @form-save="$refs.dialog.save()"
          :form="$refs.form"
        ></buttons-form-recept>
      </template>
    </base-dialog>
  </v-container>
</template>

<script>
import LiekyList from "./../../components/LiekyList.vue"
import BaseDialog from "./../../components/BaseDialog.vue"
import FormRecept from "./../../components/forms/FormRecept.vue"
import ButtonsFormRecept from "./../../components/forms/ButtonsFormRecept.vue"
import moment from "moment"
import * as Miscella from "@/miscella.js"

export default {
  components: {
    LiekyList,
    BaseDialog,
    FormRecept,
    ButtonsFormRecept,
  },
  props: ["id"],
  data: function () {
    return {
      items1: [],
      items2: [],
      items3: [],
    }
  },
  methods: {
    addItem: function () {
      let datumTeraz = moment().utc().startOf("day").toDate()
      this.$refs.form.new(datumTeraz)
      this.$refs.dialog.open()
    },
    editItem: function (item) {
      this.$refs.form.edit(item)
      this.$refs.dialog.open()
    },
    savedItem: function () {
      // this.$refs.dialog.close()
      this.fetchData()
    },
    fetchData: function () {
      console.log("LIEKY.VUE, fetchData")
      this.items1 = []
      this.$api.thingService
        .listThings({
          patient_id: this.id,
          //ambulance_id: this.id,
          recept: {},
        })
        .then(this.setItems)
      this.$store.commit("setReloadPacient", true)
    },
    setItems: function (data) {
      let arr = data.items
      this.items1 = []
      this.items2 = []
      this.items3 = []
      if (arr !== null) {
        for (let i = 0; i < arr.length; i++) {
          let item = arr[i]
          if (Miscella.isSet(item.recept)) {
            // najskor musim zistit ci je opakovany
            if (item.recept.platnost_receptu !== null) {
              let datumPlatnosti = moment(item.recept.platnost_receptu)
              if (moment().isBefore(datumPlatnosti)) {
                this.items1.push(item) // Opakované aktuálne recepty
              } else {
                this.items3.push(item) // Neaktuálne recepty
              }
            } else {
              let dobaLiecby = isNaN(Number(item.recept.doba_liecby))
                ? 0
                : Number(item.recept.doba_liecby)
              let datumKoniecLiecby = moment(item.date).add(dobaLiecby, "days")
              if (moment().isBefore(datumKoniecLiecby)) {
                this.items2.push(item) // Aktuálne recepty
              } else {
                this.items3.push(item) // Neaktuálne recepty
              }
            }
          }
        }
      }
      this.sortItems()
    },
    sortItems: function () {
      function compare(a, b) {
        if (a.date < b.date) {
          return -1
        } else if (a.date > b.date) {
          return 1
        }
        return 0
      }
      this.items1.sort(compare).reverse()
      this.items2.sort(compare).reverse()
      this.items3.sort(compare).reverse()
    },
  },
  mounted: function () {
    this.fetchData()
  },
}
</script>
