import "@babel/polyfill"
import Api from "./plugins/api.js"
import EhealthService from "./plugins/api/ehealthService.js"
import EhealthMockService from "./plugins/api/mock/ehealthMockService.js"
import App from "./App.vue"
import Filters from "./filters.js"
import Vue from "vue"
import VuetifyConfirm from "vuetify-confirm"
import VeeValidate, { Validator } from "vee-validate"
import store from "./store"
import sk from "vee-validate/dist/locale/sk"
import skCustom from "./plugins/sk-custom.js"
import "./plugins/vuetify"
import { router } from "./router.js"
import VueAffix from "vue-affix"
import moment from "moment"
import * as Miscella from "./miscella.js"
//import { getObjectValueByPath } from "vuetify/es5/util/helpers"
import { ulid } from "ulid"

Vue.use(VuetifyConfirm, {
  buttonTrueText: "Áno",
  buttonFalseText: "Nie",
  color: "warning",
  icon: "warning",
  title: "Potvrdenie",
  width: 300,
  property: "$confirm",
})

Vue.use(VeeValidate, { inject: false, events: "" })
Validator.localize("sk", sk)
Validator.localize("sk", skCustom)
Validator.extend("dummy", () => {
  // Return a Boolean or a Promise that resolves to a boolean.
  return true
})

Vue.config.productionTip = false

Vue.use(Api, {
  router: router,
  tokenName: "token",
  store: store,
})

Vue.use(Filters)

// var cookieToken = localStorage.getItem('token')
// var ambId = localStorage.getItem('ambId')

Vue.use(VueAffix)

// globalny mixin
Vue.mixin({
  data: function () {
    return {
      myDelayTimer: 0,
    }
  },
  methods: {
    ehealthDemoChanged: function (isDemo) {
      this.$api.ehealthService = isDemo
        ? new EhealthMockService(this.$api)
        : new EhealthService(this.$api)
    },
    myDelay: function (fn, ms) {
      clearTimeout(this.myDelayTimer)
      this.myDelayTimer = setTimeout(fn, ms)
    },
    sleep: function (ms) {
      return new Promise((res) => setTimeout(res, ms))
    },
    notifyInfo: function (msg) {
      this.$store.commit("setInfo", this.$vuetify.t(msg))
    },
    notifySuccess: function (msg) {
      this.$store.commit("setSuccess", this.$vuetify.t(msg))
    },
    notifyError: function (err) {
      this.$store.commit("setError", this.$vuetify.t(err))
    },
    // metoda pre standardizovane spracovavanie (api) errors
    processError: async function (err, msg = "Chyba") {
      if (err.body !== undefined) {
        let body = await err.json()
        if (Miscella.isSet(body.msg)) {
          err = body.msg
        } else if (Miscella.isSet(body.code)) {
          err = body.code
        }
      }
      if (Miscella.isEmpty(err)) {
        err = "Nastala serverová chyba, prosím kontaktujte administrátora"
      } else {
        // pokus o detekciu, ci ide o lokalizacny string
        //let ret = err.toUpperCase()
        let ret = err.toString()
        let retError = this.$vuetify.t(`$vuetify.error.${ret}`)
        if (retError !== `$vuetify.error.${ret}`) {
          //ak sa nieco naslo v resourcoch
          err = retError
        }
      }
      this.$store.commit(
        "setError",
        this.$vuetify.t(msg) + ": " + this.$vuetify.t(err)
      )
    },
    defaultText: function (value, defaultValue) {
      let ret = value
      if (Miscella.isEmpty(value)) {
        ret = defaultValue
      }
      return ret
    },
    startAppLoading: function (message = "") {
      console.log("startAppLoading", message)
      this.$store.commit("startLoading", { key: "app", message: message })
    },
    stopAppLoading: function () {
      console.log("stopAppLoading")
      this.$store.commit("stopLoading", "app")
    },
    processEhealthError: async function (err, msg = "Ezdravie chyba") {
      try {
        if (err.body !== undefined) {
          let body = await err.json()
          if (Miscella.isSet(body.msg)) {
            var e = JSON.parse(body.msg)
            console.log("EHEALTH ERROR", e)
            if (Miscella.isSet(e.Result)) {
              this.notifyError(`${msg}: ${e.Result.Message}`)
              return
            }
          }
        }
      } catch {
        this.processError(
          `${msg}. Zápis chyby nájdete v logoch ezdravia. V prípade pretrvávajúcich problémov kontaktujte administrátora`
        )
      }
    },
    ulid: function () {
      return ulid()
    },
  },
})

let vm = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app")

let ret = "dark"
if (localStorage.getItem("theme") !== null) {
  ret = localStorage.getItem("theme")
}
vm.$store.commit("setTheme", ret)

moment.updateLocale("sk", {
  relativeTime: {
    future: "o %s",
  },
})
