<template>
  <v-container fluid class="pa-0">
    <v-layout row wrap>
      <v-flex xs12>
        <v-form>
          <v-container fluid>
            <v-layout row wrap>
              <v-flex xs6>
                <TextField
                  v-model="model.id_vl"
                  name="id_vl"
                  :label="$vuetify.t('ID výmenného lístka')"
                ></TextField>
              </v-flex>
              <v-flex xs6>
                <v-select
                  v-model="model.odporucanie_odpoved"
                  :items="[
                    {
                      value: 'VYMENNYLISTOK',
                      text: $vuetify.t('Vyhľadať VL'),
                    },
                    {
                      value: 'VYSLEDOK',
                      text: $vuetify.t('Vyhľadať výsledok'),
                    },
                    {
                      value: 'VYMENNYLISTOK_S_EXTRAKTOM',
                      text: $vuetify.t('Vyhľadať VL s extraktom'),
                    },
                    // { value: 'VYMENNYLISTOK_VYSLEDOK', text: $vuetify.t('Vyhľadať VL výsledok') }
                  ]"
                ></v-select>
              </v-flex>
            </v-layout>
            <v-layout row wrap v-if="vysetrenia != null">
              <v-flex xs12>
                <v-subheader class="caption">
                  {{ $vuetify.t("Zoznam vyšetrení") }}
                </v-subheader>
                <v-data-table
                  :headers="[
                    {
                      text: 'ID',
                      value: 'id',
                      sortable: false,
                    },
                    {
                      text: 'Ošetrujúci lekár',
                      value: 'osetrujuciLekar',
                      sortable: false,
                    },
                    {
                      text: 'OÚ PZS',
                      value: 'oupzs',
                      sortable: false,
                    },
                    {
                      text: 'Hlavná diagnóza',
                      value: 'diagnoza',
                      sortable: false,
                    },
                    {
                      text: 'Typ',
                      value: 'typ',
                      sortable: false,
                    },
                    {
                      text: 'Dátum a čas vyšetrenia',
                      value: 'datum',
                      sortable: false,
                    },
                  ]"
                  :items="vysetrenia"
                  class="elevation-1"
                  hide-actions
                >
                  <template v-slot:no-data>
                    {{ noDataMessage }}
                  </template>
                  <template v-slot:items="{ item }">
                    <tr @click="onClickVysetrenieItem(item)">
                      <td>{{ item.RcIdExtension }}</td>
                      <td>
                        <ezdravie-zdravotnicky-pracovnik
                          :ii="item.InfoProvider.Performer"
                        />
                      </td>
                      <td>
                        <ezdravie-ou-pzs
                          :ii="item.InfoProvider.HealthcareFacillity"
                        />
                      </td>
                      <td>
                        {{ getHlavnaDiagnoza(item) }}
                      </td>
                      <td>
                        {{
                          $vuetify.t(
                            "$vuetify.enum.evysetrenie.typ." + getTyp(item)
                          )
                        }}
                      </td>
                      <td>{{ item.DatumACasVysetrenia | formatDatumACas }}</td>
                    </tr>
                  </template>
                </v-data-table>
              </v-flex>
              <v-flex xs12>
                <span>{{
                  $vuetify.t(
                    "$vuetify.ezdravie.pagination",
                    zobrazeneZaznamyOd,
                    zobrazeneZaznamyDo
                  )
                }}</span>
                <v-tooltip top>
                  <v-btn
                    flat
                    icon
                    slot="activator"
                    @click.stop="prevPage"
                    :loading="$store.state.loading.save"
                    :disabled="model.strankovanie.index === 1"
                    ><v-icon>$vuetify.icons.prev</v-icon></v-btn
                  >
                  <span>{{ $vuetify.t("Predchádzajúca stránka") }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <v-btn
                    flat
                    icon
                    slot="activator"
                    @click.stop="nextPage"
                    :loading="$store.state.loading.save"
                    :disabled="
                      vysetrenia.length < model.strankovanie.velkost_stranky
                    "
                    ><v-icon>$vuetify.icons.next</v-icon></v-btn
                  >
                  <span>{{ $vuetify.t("Nasledujúca stránka") }}</span>
                </v-tooltip>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-flex>
    </v-layout>
    <BaseDialog
      :dialogTitle="$vuetify.t('ezdravie: vyšetrenie')"
      width="75%"
      ref="ezdravieVysetrenieDialog"
    >
      <EZdravieVysetrenie
        ref="ezdravieVysetrenieForm"
        :pacientId="pacientId"
        :vysetrenie="vysetrenie"
        @prevzatieVymennehoListka="prevezmiVymennyListok"
        @vytvorenieVysledku="vytvorVysledok"
        @saved="savedEzdravieVysetrenie"
        showPrevezmiVymennyListokButton
        showVytvorenieVysledkuButton
      ></EZdravieVysetrenie>
      <template v-slot:buttons>
        <v-btn flat @click.native="$refs.ezdravieVysetrenieDialog.close()">{{
          $vuetify.t("$vuetify.app.button.close")
        }}</v-btn>
        <v-btn
          flat
          @click="dajDetailVysetrenia(vysetrenie.RcIdExtension)"
          :loading="$store.state.loading.save"
          >{{ $vuetify.t("Načítať kompletné vyšetrenie") }}</v-btn
        >
        <!-- <v-btn -->
        <!--   color="primary" -->
        <!--   flat -->
        <!--   @click="$refs.ezdravieVysetrenieForm.save()" -->
        <!--   :loading="$store.state.loading.save" -->
        <!--   >{{ $vuetify.t("Uložiť") }}</v-btn -->
        <!-- > -->
      </template>
    </BaseDialog>
  </v-container>
</template>

<script>
import BaseDialog from "./../../components/BaseDialog.vue"
import TextField from "./../TextField.vue"
import EVysetrenie from "./../../ehealth/evysetrenie.js"
import EZdravieVysetrenie from "./../ezdravie/EZdravieVysetrenie.vue"
import { mapState, mapGetters, mapMutations } from "vuex"
import * as Miscella from "./../../miscella.js"
import EzdravieZdravotnickyPracovnik from "../ezdravie/EZdravieZdravotnickyPracovnik.vue"
import EzdravieOuPzs from "../ezdravie/EZdravieOuPzs.vue"

export default {
  $_veeValidate: {
    validator: "new", // give me my own validator scope.
  },
  components: {
    BaseDialog,
    TextField,
    EZdravieVysetrenie,
    EzdravieZdravotnickyPracovnik,
    EzdravieOuPzs,
  },
  data: function () {
    return {
      typ: null,
      vysetrenie: null,
      vyhladavanie: true,
      odporucanie: null,
      vysetrenia: [],
      model: null,
      noDataMessage: "Vyhľadávanie ešte neprebehlo.",
    }
  },
  computed: {
    zobrazeneZaznamyOd: function () {
      return this.model.strankovanie.index
    },
    zobrazeneZaznamyDo: function () {
      return this.model.strankovanie.index + this.vysetrenia.length - 1
    },
    ...mapState({
      pacientId: (state) => state.pacient?.id,
      ambulanceID: (state) => state.me?.ambulance?.id,
    }),
    ...mapGetters(["isEhealthReady"]),
  },
  methods: {
    init: function () {
      this.vyhladavanie = true
      this.vysetrenie = null
      this.vysetrenia = []
      this.model = {
        id_vl: "",
        odporucanie_odpoved: "VYMENNYLISTOK",

        strankovanie: {
          index: 1,
          velkost_stranky: 10,
        },
        //TriedenieMetaData: null,
        // TriedenieMetaData: {
        //   Pole: ''
        // }
      }
    },
    checkEhealthStatus: async function () {
      if (!this.isEhealthReady) {
        notifyError("Ezdravie je momentálne nedostupné.")
        return false
      }
      return true
    },
    search: async function () {
      var valid = await this.$validator.validateAll()
      if (!Miscella.isEmpty(this.model.id_vl) && this.model.id_vl.length < 6) {
        this.errors.add({
          field: "id_vl",
          msg: this.$vuetify.t(
            "Zadané ID výmenného lístka musí mať minimálne 6 znakov."
          ),
        })
        valid = false
      }
      if (!valid) {
        return
      }
      this.$validator.reset()
      this.errors.clear()
      this.startAppLoading("Vyhľadáva sa výmenný lístok")
      var online = await this.checkEhealthStatus()
      if (!online) {
        this.stopAppLoading()
        return
      }
      this.vysetrenie = null
      this.vysetrenia = null
      try {
        var params = Miscella.cloneObject(this.model)
        params.patient_id = this.pacientId
        params.ambulance_id = this.ambulanceID
        var response = await this.$api.ehealthService.vyhladajVL(params)
        var data = JSON.parse(response.data)
        this.vysetrenia = data.Data.AllCompositions
        this.noDataMessage = "Neboli nájdené žiadne vyšetrenia."
      } catch (error) {
        this.processEhealthError(
          error,
          "Pri vyhľadávaní výmenného lístka vznikla chyba"
        )
      } finally {
        this.stopAppLoading()
      }
    },
    prevezmiVymennyListok: async function (rcID) {
      this.startAppLoading("Preberá sa výmenný lístok")
      var online = await this.checkEhealthStatus()
      if (!online) {
        this.stopAppLoading()
        return
      }
      try {
        var response = await this.$api.ehealthService.prevezmiVymennyListok({
          patient_id: this.pacientId,
          ambulance_id: this.ambulanceID,
          id_vymenneho_listku: rcID,
        })
        var data = JSON.parse(response.data)
        console.log(data.Data.DatumDo)
        this.notifySuccess(
          "Prevzatie výmenného lístka bolo úspešné. Boli sprístupnené údaje pacienta do: " +
            this.$options.filters.formatDatumACas(data.Data.DatumDo)
        )
      } catch (error) {
        this.processEhealthError(
          error,
          "Pri načítavaní vyšetrenia vznikla chyba"
        )
      } finally {
        this.stopAppLoading()
      }
    },
    dajDetailVysetrenia: async function (extID) {
      this.startAppLoading("Načítava sa vyšetrenie")
      var online = await this.checkEhealthStatus()
      if (!online) {
        this.stopAppLoading()
        return
      }
      try {
        var response = await this.$api.ehealthService.dajZaznamOVysetreni({
          patient_id: this.pacientId,
          ambulance_id: this.ambulanceID,
          id_zaznamu_vysetrenia: extID,
        })
        var data = JSON.parse(response.data)
        console.log(data.Data.AllCompositions)
        this.setVysetrenie(data.Data.AllCompositions)
      } catch (error) {
        this.processEhealthError(
          error,
          "Pri načítavaní vyšetrenia vznikla chyba"
        )
      } finally {
        this.stopAppLoading()
      }
    },
    setVysetrenie: function (data) {
      this.vysetrenie = data
      this.showDetailVysetrenie = true
    },
    backToList: function () {
      this.showDetailVysetrenie = false
    },
    vytvorVysledok: function (params) {
      console.log("VYTVOR VYSLEDOK PARAMS: ", params)
      this.$refs.ezdravieVysetrenieDialog.close()
      this.$emit("vytvorenieVysledku", params)
    },
    savedEzdravieVysetrenie: function (ezdravieVysetrenie) {
      this.$refs.ezdravieVysetrenieDialog.close()
      let odporucanieID = ""
      for (let vl of ezdravieVysetrenie.OdporucanieNaVysetrenie) {
        if (
          vl.IdentifikatorVymennehoListku !== null &&
          vl.IdentifikatorVymennehoListku.extension.search(
            this.odporucanieID
          ) >= 0
        ) {
          odporucanieID = vl.IdentifikatorVymennehoListku.extension
          break
        }
      }
      this.$emit("saved", {
        ezdravieVysetrenie: ezdravieVysetrenie,
        odporucanieID: odporucanieID,
      })
    },
    onClickVysetrenieItem: function (item) {
      this.vysetrenie = item
      this.$refs.ezdravieVysetrenieDialog.open()
    },
    prevPage: function () {
      this.model.strankovanie.index -= this.model.strankovanie.velkost_stranky
      this.vyhladajVysetrenia()
    },
    nextPage: function () {
      this.model.strankovanie.index += this.model.strankovanie.velkost_stranky
      this.vyhladajVysetrenia()
    },
    getHlavnaDiagnoza: function (item) {
      if (Miscella.isSet(item.DiagnostickyZaver)) {
        for (let dg of item.DiagnostickyZaver) {
          if (dg.HlavnaDiagnoza.value === true) {
            return dg.Diagnoza.KodDiagnozy.DisplayName
          }
        }
      }
      return ""
    },
    getTyp: function (item) {
      switch (item.RcIdOid) {
        case "1.3.158.00165387.100.60.90":
          return "ZAZNAM_O_ODBORNOM_VYSETRENI"
          break
        case "1.3.158.00165387.100.60.80":
          return "ZAZNAM_O_ZOBRAZOVACOM_VYSETRENI"
          break
        case "1.3.158.00165387.100.60.100":
          return "ZAZNAM_PREPUSTACIA_SPRAVA"
          break
      }
      return "ZAZNAM_O_ODBORNOM_VYSETRENI"
      //throw "Neznámy typ vyšetrenia"
    },
  },
  created: function () {
    this.init()
  },
}
</script>
