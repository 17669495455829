import moment from "moment"
import Http from "../plugins/http.js"
import Utils from "./utils.js"
import EZdravieLog from "./log.js"
import * as Miscella from "../miscella.js"

export default class EZdravie {
  constructor(store, api, ouPzs) {
    this.store = store
    this.api = api
    this.ouPzs = ouPzs
    this.serverUrl = "http://" + store.state.ehealth.IP + ":1331"
    // this.serverUrl = 'http://200.200.200.149:1331' // url ehealth komunikacneho servera - nepovinny
    // this.serverUrl = 'http://200.200.200.202:1331' // url ehealth komunikacneho servera - nepovinny
    // this.serverUrl = 'http://200.200.200.117:1331' // url ehealth komunikacneho servera - nepovinny
    // this.serverUrl = 'http://200.200.200.149:1331' // url ehealth komunikacneho servera - nepovinny
    // this.serverUrl = 'http://10.9.8.86:1331' // url ehealth komunikacneho servera - nepovinny
  }

  // getUrl(service) {
  //   return this.serverUrl + service
  // }

  request(service, params, logParams = {}) {
    var logId = ""
    return EZdravieLog.logSent(this.api, service, params, logParams).then(
      (log) => {
        logId = log.ID
        return Http.post(this.serverUrl + "/" + service, params)
          .then((res) => {
            return res.json().then((value) => {
              EZdravieLog.logReceived(this.api, logId, value)
              return value
            })
          })
          .catch((res) => {
            return res.json().then((error) => {
              EZdravieLog.logReceived(this.api, logId, error, true)
              return Promise.reject(error)
            })
          })
      }
    )
  }

  dajCardInfo() {
    var params = {}
    return Http.post(this.serverUrl + "/DAJ_CARDS_INFO", params).then((res) =>
      res.json()
    )
  }

  dajJruzIdentifikator(params) {
    return this.request("DAJ_JRUZ_IDENTIFIKATOR_GW", params)
  }

  dajPacientInfo(params = {}) {
    return this.request("DAJ_PACIENT_INFO", params)
  }

  podpisPreskripcnyZaznamDoveraXML(params = {}) {
    return Http.post(
      this.serverUrl + "/" + "PODPIS_PRESKRIPCNYZAZNAM_DOVERA_XML",
      params,
      {
        headers: {
          "Content-Type": "text/xml",
        },
      }
    ).then((res) => res.text())
  }

  dajZpPrZs(jruzId) {
    return this.getUserContext().then((userContext) => {
      var params = {
        PatientJruzId: jruzId,
        UserContext: userContext,
        Data: {
          DatumOd: moment().format(),
          DatumDo: moment().format(),
          AktualnostMetadata: moment().format(),
        },
      }
      return this.request("DAJ_ZP_PR_ZS_GW", params)
    })
  }

  dajRodneCisloPrZS(jruzId) {
    return this.getUserContext().then((userContext) => {
      var params = {
        PatientJruzId: jruzId,
        UserContext: userContext,
        Data: {
          AktualnostMetadata: moment().format(),
        },
      }
      return this.request("DAJ_RODNE_CISLO_PR_ZS_GW_V2", params)
    })
  }

  async getUserContext() {
    if (Miscella.isEmpty(this.ouPzs.jruzId)) {
      if (Miscella.isEmpty(this.ouPzs.code)) {
        throw "Nie je zadaný kód odborného útvaru PZS (ambulancie). Prosím doplňte údaje PZS a ambulancie v časti Administrácia"
      }
      throw "Nie je zadané JRUZ ID odborného útvaru PZS (ambulancie)"
    }
    return {
      CodingSchemeVersion: Utils.getCodebookVersion(this.ouPzs.zameranie),
      CodeValue: this.ouPzs.zameranie.ID,
      Extension: this.ouPzs.jruzId,
      RootOID: Utils.asOid("40.70"),
    }
  }

  fetchPatientJruzId(rodneCislo) {
    return this.getUserContext().then((userContext) => {
      var params = {
        PatientJruzId: null,
        UserContext: userContext,
        Data: {
          ZadaneId: rodneCislo,
          TypHladanehoId: {
            Oid: Utils.asOid("40.100"),
          },
          TypZadanehoId: {
            CodeValue: "00000000182",
            CodingScheme: Utils.asOid("10.53"),
            CodingSchemeVersion: 4,
          },
        },
      }
      return this.dajJruzIdentifikator(params)
    })
  }

  fetchLekarJruzId(kodLekara) {
    return this.getUserContext().then((userContext) => {
      var params = {
        PatientJruzId: null,
        UserContext: userContext,
        Data: {
          ZadaneId: kodLekara,
          TypHladanehoId: {
            Oid: Utils.asOid("40.90"),
          },
          TypZadanehoId: {
            CodeValue: "00000358069",
            CodingScheme: Utils.asOid("10.53"),
            CodingSchemeVersion: 4,
          },
        },
      }
      return this.dajJruzIdentifikator(params)
    })
  }

  async getLekar() {
    var lekar = this.store.state.me.user
    if (Miscella.isEmpty(lekar.jruzId)) {
      await this.fetchLekarJruzId(lekar.code[0], function (data) {
        lekar.jruzId = data.Id
      })
    }
    return lekar
  }

  fetchPacient(patient) {
    this.store.commit("setInfo", "Prebieha identifikácia pacienta")
    var pacientMethod = Miscella.isEmpty(patient.RC)
      ? this.dajPacientInfo()
      : this.fetchPatientJruzId(patient.RC)
    return pacientMethod
      .then((data) => {
        this.store.commit("setSuccess", "Identifikácia pacienta úspešná")
        patient.JruzId = data.Id
        if (Miscella.isEmpty(patient.RC)) {
          return this.dajRodneCisloPrZS(patient.JruzId).then((data) => {
            patient.RC = data.ID.IdentifierValue
            return patient
          })
        }
        return patient
      })
      .then((patient) => {
        var params = {
          jruzId: patient.JruzId,
          rc: patient.RC,
        }
        return this.api
          .post("/api/patient/" + patient.ID + "/updateJruz", params)
          .then(() => {
            if (patient.ID === this.store.state.pacient.ID) {
              this.store.commit("setReloadPacient", true)
            }
            return patient
          })
      })
  }
}
