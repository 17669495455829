import * as Miscella from "../../miscella.js"

export default {
  computed: {},
  methods: {
    loginSuccess: async function (data) {
      localStorage.setItem("refresh_token", data.refresh_token)
      localStorage.setItem("token", data.token)

      // TODO dat do store, naviazat na ping?
      setTimeout(() => {
        this.fetchNewToken()
      }, 60 * 60 * 1000)

      this.$store.dispatch("fetchMe", this.$api)
      this.$router.push("dashboardUser")
    },
    login: async function (username, password) {
      var valid = await this.$validator.validateAll()
      if (!valid) {
        return
      }
      var data = {
        username: username,
        password: password,
      }
      return this.$api.authService
        .auth(data)
        .then(this.loginSuccess)
        .catch(this.processError)
    },
    // TODO dat do store, naviazat na ping?
    fetchNewToken: async function () {
      console.log("STORE, fetchNewToken started")
      let rToken = localStorage.getItem("refresh_token")
      if (Miscella.isSet(rToken)) {
        var data = await this.$api.authService.refresh({
          refresh_token: rToken,
        })
        localStorage.setItem("token", data.token)
      }
      setTimeout(() => {
        this.fetchNewToken()
      }, 60 * 60 * 1000)
    },
  },
  filters: {},
}
