<template>
  <v-container fluid>
    <!-- <v-checkbox -->
    <!--   v-if="maPacientAktivnuAlergiu" -->
    <!--   :label="$vuetify.t('Žiadna alergia')" -->
    <!--   :input-value="pacient.no_allergy" -->
    <!--   @change="updateZiadnaAlergia" -->
    <!--   primary -->
    <!--   hide-details -->
    <!-- ></v-checkbox> -->
    <ApiList
      ref="list"
      :url="url"
      :customParams="getParams()"
      :title="$vuetify.t('Nežiadúce reakcie')"
      :actions="[
        {
          text: $vuetify.t('Ukončiť reakciu'),
          icon: 'update',
          handler: endAlergia,
        },
        {
          text: $vuetify.t('Odstrániť reakciu'),
          icon: 'delete',
          handler: removeReakcia,
        },
      ]"
      @addItem="addItem"
      @editItem="editItem"
    >
      <template slot="items" slot-scope="{ item }">
        <NeziaducaReakciaRow :reakcia="item" />
      </template>
    </ApiList>
    <BaseDialog
      :dialogTitle="$vuetify.t(dialogTitle)"
      ref="dialog"
      @save="$refs.form.save()"
    >
      <FormNeziaducaReakcia
        ref="form"
        :pacientId="id"
        @saved="fetchData"
      ></FormNeziaducaReakcia>
    </BaseDialog>
  </v-container>
</template>

<script>
import ApiList from "./../../components/ApiList.vue"
import BaseDialog from "./../../components/BaseDialog.vue"
import FormNeziaducaReakcia from "./../../components/forms/FormNeziaducaReakcia.vue"
import * as Miscella from "./../../miscella.js"
import moment from "moment"
import thingMixin from "@/components/mixins/thing.js"
import NeziaducaReakciaRow from "@/components/rows/NeziaducaReakciaRow.vue"

export default {
  name: "NeziaduceReakcie",
  mixins: [thingMixin],
  components: {
    ApiList,
    BaseDialog,
    FormNeziaducaReakcia,
    NeziaducaReakciaRow,
  },
  props: ["id"],
  data: function () {
    return {
      dialogTitle: "",
      maPacientAktivnuAlergiu: true,
    }
  },
  computed: {
    url() {
      return "/v1/proto.ThingService/ListThings"
    },
    pacient: function () {
      return this.$store.state.pacient !== null ? this.$store.state.pacient : {}
    },
  },
  methods: {
    getParams: function () {
      return {
        patient_id: this.id,
        neziaduca_reakcia: {},
        table_request: {
          sort_by: "Date",
          sort_desc: true,
        },
      }
    },
    readPacient: function () {
      this.$store.commit("setReloadPacient", true)
    },
    fetchData: function () {
      this.$refs.list.reload()
      this.readPacient()
    },
    addItem: function () {
      this.$refs.form.new()
    },
    editItem: function (item) {
      this.$refs.form.edit(item)
    },
    removeReakcia: async function (reakcia) {
      var result = await this._deleteThing(reakcia) // from thing mixin
      if (result) {
        this.fetchData()
      }
    },
    endAlergia: async function (reakcia) {
      if (reakcia.neziaduca_reakcia.datum_ukoncenia !== null) {
        return Promise.resolve()
      } else {
        let datumVytvorenia = moment(reakcia.date)
        let datumUkoncenia = moment()
        if (datumUkoncenia.isBefore(datumVytvorenia, "day")) {
          this.$store.commit(
            "setError",
            this.$vuetify.t(
              "Dátum ukončenia nemôže predchádzať dátumu vytvorenia."
            )
          )
          return Promise.resolve()
        }
      }
      var res = await this.$confirm(
        this.$vuetify.t("Naozaj chcete ukončiť nežiadúcu reakciu?")
      )
      if (res) {
        var params = { thing: reakcia }
        params.thing.neziaduca_reakcia.datum_ukoncenia = moment().format()
        this.$api.thingService
          .saveThing(params)
          .then((result) => {
            this.$store.commit(
              "setSuccess",
              this.$vuetify.t("Nežiadúca reakcia bola úspešne ukončená")
            )
            this.fetchData()
          })
          .catch((err) => {
            this.$store.commit(
              "setError",
              this.$vuetify.t(
                "Pri ukončovaní nežiadúcej reakcie vznikla chyba "
              ) +
                " (" +
                err +
                ")."
            )
          })
      }
    },
    listAktivneReloaded: function () {
      let maPacientAktivnuAlergiu = false
      if (this.pacient) {
        if (this.pacient.id !== "") {
          if (this.$refs.listAktivne) {
            if (this.$refs.listAktivne.items) {
              if (this.$refs.listAktivne.items.length === 0) {
                maPacientAktivnuAlergiu = true
              }
            }
          }
        }
      }
      this.maPacientAktivnuAlergiu = maPacientAktivnuAlergiu
    },
    updateZiadnaAlergia: async function (ziadnaAlergia) {
      try {
        let pacient = Miscella.cloneObject(this.pacient)
        pacient.no_allergy = ziadnaAlergia
        await this.$api.post("/v1/proto.PatientService/UpdatePatient", {
          patient: pacient,
        })
        this.fetchData()
      } catch (err) {
        this.$store.commit(
          "setError",
          this.$vuetify.t(
            "Pri ukladaní príznaku 'Žiadna alergia' vznikla chyba "
          ) +
            " (" +
            err +
            ")."
        )
      }
    },
  },
}
</script>
