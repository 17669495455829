import moment from "moment"
import EZdravie from "./ezdravie.js"
import Utils from "./utils.js"
import * as VysetrenieFactory from "./vysetrenieFactory.js"
import * as Miscella from "../miscella.js"

export default class EVysetrenie extends EZdravie {
  constructor(patient, store, api, ouPzs) {
    super(store, api, ouPzs)
    this.patient = patient
  }

  getPacient() {
    if (Miscella.isEmpty(this.patient.JruzId)) {
      return this.fetchPacient(this.patient).then((patient) => {
        this.patient = patient
        return this.patient
      })
    }
    return Promise.resolve(this.patient)
  }

  getZpPacienta() {
    if (this.store.getters.isEhealthOnline) {
      return this.getPacient().then((patient) => {
        this.store.commit("setInfo", "Overuje sa ZP pacienta")
        return this.dajZpPrZs(patient.JruzId).then((data) => {
          if (data.ZP_PrZS !== undefined && data.ZP_PrZS.length > 0) {
            this.store.commit("setSuccess", "ZP pacienta úspešne overená")
            return data.ZP_PrZS[0].IdZp.Extension
          }
          return ""
        })
      })
    }
    return Promise.resolve("")
  }

  zapisEVysetrenie(vysetrenie, stav = "NEW") {
    return this.getPacient().then((patient) => {
      var params = {
        ID: vysetrenie.ID,
        PatientID: patient.ID,
        Stav: stav,
      }
      return this.api.post("/api/eVysetrenie", params)
    })
  }

  zapisZaznamOVysetreni(vysetrenie, diagnozy, odporucania) {
    return Promise.all([
      this.getLekar(),
      this.getUserContext(),
      this.getPacient(),
      this.zapisEVysetrenie(vysetrenie),
    ]).then(([lekar, userContext, patient, eVysetrenie]) => {
      vysetrenie.EVysetrenie = eVysetrenie
      var factory = new VysetrenieFactory.VysetrenieFactoryV3(
        this.ouPzs,
        lekar,
        vysetrenie,
        diagnozy,
        odporucania
      )
      if (vysetrenie !== null) {
        var params = {
          PatientJruzId: patient.JruzId,
          UserContext: userContext,
          OdborneVysetrenie:
            vysetrenie.Typ === "ZAZNAM_O_ODBORNOM_VYSETRENI"
              ? factory.zaznamOOdbornomVysetreni
              : null,
          LekarskaPrepustaciaSprava: null,
          ZobrazovacieVysetrenie:
            vysetrenie.Typ === "ZAZNAM_O_ZOBRAZOVACOM_VYSETRENI"
              ? factory.zaznamOZobrazovacomVysetreni
              : null,
        }
      }
      vysetrenie.EVysetrenie.Stav = "SENT"
      return this.request("ZAPIS_ZAZNAM_O_VYSETRENI", params, {
        objectId: vysetrenie.ID,
        patientId: patient.ID,
      }).then(() => {
        vysetrenie.EVysetrenie.Stav = "SUCCESS"
      })
    })
  }

  vyhladajZaznamOVysetreniPreZiadatela(odporucanieID, odporucanieOdpoved) {
    return Promise.all([this.getPacient(), this.getUserContext()]).then(
      ([patient, userContext]) => {
        var params = {
          PatientJruzId: patient.JruzId,
          UserContext: userContext,
          Data: {
            ExternyIDVymennehoListku: odporucanieID,
            Odporucanie_Odpoved: odporucanieOdpoved,
          },
        }
        return this.request(
          "VYHLADAJ_ZAZNAM_O_VYSETRENIACH_PRE_ZIADATELA",
          params
        )
      }
    )
  }

  zapisSuhlasOsobyPrePZS(typSuhlasu) {
    return Promise.all([
      this.getPacient(),
      this.getLekar(),
      this.getUserContext(),
    ]).then(([patient, lekar, userContext]) => {
      var params = {
        PatientJruzId: patient.JruzId,
        UserContext: userContext,
        Data: {
          Citlivost: 3,
          PlatnyOd: moment().format(),
          PlatnyDo: null,
          Poznamka: "",
          PodpisanaDohoda: false,
          TypSuhlasu: "O",
          PovolenyPristup: {
            TypStrankyEZK: typSuhlasu,
          },
          Subjekt: {
            SeidSubjektu: lekar.jruzId,
            TypOidSubjektu: Utils.asOid("40.90"),
          },
        },
      }
      return this.request("ZAPIS_SUHLAS_OSOBY_PRE_PZS", params)
    })
  }

  static newZaznamyOVysetreniachCriteria() {
    return {
      TypLekarskehoVysetrenia: [],
      DatumOd: null,
      DatumDo: null,
      OdborneZameranie: null,
      PoskytovatelZS: null,
      IDZaznamuVysetrenia: null,
      StrankovanieMetaData: {
        Index: 1,
        VelkostStranky: 5,
      },
      TriedenieMetaData: null,
      // TriedenieMetaData: {
      //   Pole: ''
      // }
    }
  }

  vyhladajZaznamyOVysetreniach(criteria) {
    return Promise.all([this.getPacient(), this.getUserContext()]).then(
      ([patient, userContext]) => {
        var params = {
          PatientJruzId: patient.JruzId,
          UserContext: userContext,
          Data: {
            TypLekarskehoVysetrenia: criteria.TypLekarskehoVysetrenia,
            DatumOd:
              criteria.DatumOd !== null
                ? moment(criteria.DatumOd).startOf("day").format()
                : null,
            DatumDo:
              criteria.DatumDo !== null
                ? moment(criteria.DatumDo).endOf("day").format()
                : null,
            OdborneZameranie: Utils.asCV(criteria.OdborneZameranie, "39"),
            PoskytovatelZS: Utils.asII(criteria.PoskytovatelZS, "40.80"),
            IDZaznamuVysetrenia: !Miscella.isEmpty(criteria.IDZaznamuVysetrenia)
              ? Utils.asID(criteria.IDZaznamuVysetrenia, "60.90")
              : null,
            strankovaniemetadata: criteria.StrankovanieMetaData,
            TriedenieMetaData: criteria.TriedenieMetaData,
          },
        }
        return this.request("VYHLADAJ_ZAZNAMY_O_VYSETRENIACH", params)
      }
    )
  }

  dajZaznamOVysetreni(extID) {
    return Promise.all([this.getPacient(), this.getUserContext()]).then(
      ([patient, userContext]) => {
        var params = {
          PatientJruzId: patient.JruzId,
          UserContext: userContext,
          Data: {
            IdZaznamuVysetrenia: Utils.asII(extID, "60.90"),
          },
        }
        return this.request("DAJ_ZAZNAM_O_VYSETRENI", params)
      }
    )
  }
}
