<template>
  <router-view></router-view>
</template>

<script>
export default {
  components: {},
  data() {
    return {}
  },
  computed: {},
  methods: {},
  mounted: function () {
    console.log("Print.vue mounted")
  },
}
</script>
