<template>
  <v-container fluid class="pa-0">
    <v-layout row>
      <v-flex xs3>
        <v-card class="ma-3 elevation-0">
          <v-toolbar flat dense>
            <v-toolbar-title>{{
              $vuetify.t("$vuetify.form.addItems")
            }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text class="pa-0">
            <v-list dense class="pt-0">
              <draggable
                v-model="types"
                :options="{
                  group: { name: 'polozky', pull: 'clone', put: false },
                }"
                :clone="(original) => defaultComponent(original.name)"
              >
                <v-list-tile
                  v-for="item in types"
                  :key="item.name"
                  @click="addComponent(item.name)"
                >
                  <v-list-tile-action>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-tile-action>

                  <v-list-tile-content>
                    <v-list-tile-title>{{
                      typeTitle(item.name)
                    }}</v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
              </draggable>
            </v-list>
          </v-card-text>
        </v-card>
        <v-card
          v-if="activeComponent !== null && componentIsEditable"
          class="ma-3 elevation-0"
        >
          <v-toolbar flat dense>
            <v-toolbar-title>{{
              typeTitle(activeComponent.typ_polozka)
            }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text class="pa-0">
            <v-form>
              <v-container fluid grid-list-xs>
                <v-layout row wrap>
                  <v-flex xs12>
                    <v-text-field
                      :label="$vuetify.t('$vuetify.form.label')"
                      v-model="activeComponent.label"
                      autofocus
                    ></v-text-field>
                  </v-flex>
                  <template v-if="componentIsInputEditor">
                    <v-flex xs12>
                      <v-text-field
                        :label="$vuetify.t('$vuetify.form.hint')"
                        v-model="activeComponent.hint"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-textarea
                        label="Markdown"
                        v-model="activeComponent.markdown"
                        rows="3"
                        auto-grow
                      ></v-textarea>
                    </v-flex>
                    <v-flex xs12>
                      <v-checkbox
                        :label="$vuetify.t('$vuetify.form.required')"
                        v-model="activeComponent.required"
                      ></v-checkbox>
                    </v-flex>
                  </template>
                </v-layout>
                <component
                  v-if="componentEditor !== null"
                  :is="componentEditor"
                  :params="activeComponent.params"
                  :values="activeComponent.values"
                  ref="componentEditor"
                ></component>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex xs9>
        <v-card class="ma-3 elevation-0">
          <v-toolbar flat dense>
            <v-toolbar-title>{{
              this.$vuetify.t("$vuetify.form.text")
            }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text class="pa-0">
            <v-container fluid>
              <v-form ref="form">
                <v-layout row wrap>
                  <v-flex xs12>
                    <v-text-field
                      :label="$vuetify.t('$vuetify.form.title')"
                      v-model="formDefinition.title"
                      :rules="[
                        (value) =>
                          !!value || $vuetify.t('$vuetify.required.title'),
                      ]"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-textarea
                      :label="$vuetify.t('$vuetify.form.desc')"
                      v-model="formDefinition.description"
                      rows="1"
                      auto-grow
                    ></v-textarea>
                  </v-flex>
                  <v-flex xs12>
                    <v-combobox
                      v-model="formDefinition.trees"
                      :items="trees"
                      chips
                      multiple
                      :label="$vuetify.t('$vuetify.form.tree')"
                    ></v-combobox>
                  </v-flex>
                  <v-flex xs12>
                    <v-combobox
                      v-model="formDefinition.tags"
                      :items="tags"
                      chips
                      multiple
                      :label="$vuetify.t('$vuetify.form.tags')"
                    ></v-combobox>
                  </v-flex>
                </v-layout>
              </v-form>
              <v-form>
                <draggable
                  v-model="formDefinition.polozky"
                  :options="{ group: 'polozky' }"
                  @change="onChangeDraggable"
                >
                  <v-layout
                    row
                    wrap
                    v-for="(polozka, index) in formDefinition.polozky"
                    :key="index"
                  >
                    <v-flex xs12 :key="'polozka' + index">
                      <v-card
                        class="mb-2 elevation-0"
                        @click="activeComponent = polozka"
                      >
                        <v-toolbar
                          :class="{
                            primary: polozka === activeComponent,
                            clicker: true,
                          }"
                          flat
                          dense
                          card
                        >
                          <v-toolbar-title
                            class="body-2"
                            :class="{
                              'white--text': polozka === activeComponent,
                              'grey--text': polozka !== activeComponent,
                              'text--darken-2': polozka !== activeComponent,
                            }"
                            >{{
                              typeTitle(polozka.typ_polozka)
                            }}</v-toolbar-title
                          >
                          <v-spacer></v-spacer>
                          <v-tooltip top>
                            <v-btn
                              flat
                              icon
                              slot="activator"
                              @click.stop="deleteComponent(polozka, index)"
                              :class="{
                                'white--text': polozka === activeComponent,
                                'grey--text': polozka !== activeComponent,
                                'text--darken-2': polozka !== activeComponent,
                              }"
                            >
                              <v-icon>delete</v-icon>
                            </v-btn>
                            <span>{{
                              $vuetify.t("$vuetify.form.delete")
                            }}</span>
                          </v-tooltip>
                          <v-icon
                            :class="{
                              'white--text': polozka === activeComponent,
                              'grey--text': polozka !== activeComponent,
                              'text--darken-2': polozka !== activeComponent,
                              dragable: true,
                            }"
                          >
                            drag_indicator</v-icon
                          >
                        </v-toolbar>
                        <v-card-text class="">
                          <form-component
                            :form-item-definition="polozka"
                          ></form-component>
                        </v-card-text>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </draggable>
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Vue from "vue"
import EditorText from "./editors/EditorText.vue"
import EditorTextarea from "./editors/EditorTextarea.vue"
import EditorNumber from "./editors/EditorNumber.vue"
import EditorSlider from "./editors/EditorSlider.vue"
import EditorChoice from "./editors/EditorChoice.vue"
import FormComponent from "./FormComponent.vue"
import draggable from "vuedraggable"

export default {
  components: {
    EditorText,
    EditorTextarea,
    EditorNumber,
    EditorSlider,
    EditorChoice,
    FormComponent,
    draggable,
  },
  data: function () {
    return {
      types: [
        { name: "text", icon: "add" },
        { name: "textarea", icon: "add" },
        { name: "number", icon: "add" },
        { name: "slider", icon: "add" },
        { name: "checkbox", icon: "add" },
        { name: "radio", icon: "add" },
        { name: "buttongroup", icon: "add" },
        { name: "combobox", icon: "add" },
        { name: "subheader", icon: "add" },
        { name: "separator", icon: "add" },
      ],
      activeComponent: null,
      newComponent: false,
    }
  },
  props: {
    formDefinition: {
      type: Object,
      required: false,
      default: function () {
        return {
          title: "",
          description: "",
          trees: [],
          tags: [],
          polozky: [],
        }
      },
    },
    trees: {
      type: Array,
      required: false,
      default: function () {
        return []
      },
    },
    tags: {
      type: Array,
      required: false,
      default: function () {
        return []
      },
    },
  },
  computed: {
    componentEditor: function () {
      if (this.activeComponent !== null && this.componentIsInputEditor) {
        switch (this.activeComponent.typ_polozka) {
          case "checkbox":
          case "combobox":
          case "radio":
          case "buttongroup":
            return "editor-choice"
          default:
            return "editor-" + this.activeComponent.typ_polozka
        }
      }
      return null
    },
    componentIsInputEditor: function () {
      let ret = true
      if (this.activeComponent !== null) {
        if (this.activeComponent.typ_polozka === "subheader") {
          ret = false
        }
        if (this.activeComponent.typ_polozka === "separator") {
          ret = false
        }
      }
      return ret
    },
    componentIsEditable: function () {
      let ret = true
      if (this.activeComponent.typ_polozka === "separator") {
        ret = false
      }
      return ret
    },
  },
  methods: {
    typeTitle: function (type) {
      return this.$vuetify.t(`$vuetify.form.item.${type}`)
      /* switch (type) {
        case "text":
          return this.$vuetify.t("$vuetify.form.item.text")
        case "number":
          return this.$vuetify.t("$vuetify.form.item.number")
        case "choice":
          return this.$vuetify.t("$vuetify.form.item.choice")
        case "subheader":
          return this.$vuetify.t("$vuetify.form.item.subheader")
        case "separator":
          return this.$vuetify.t("$vuetify.form.item.separator")
        default:
          return ""
      } */
    },
    defaultComponent: function (type) {
      return {
        typ_polozka: type,
        label: "",
        hint: "",
        markdown: "",
        required: false,
        params: {},
      }
    },
    addComponent: function (type) {
      this.activeComponent = this.defaultComponent(type)
      this.formDefinition.polozky.push(this.activeComponent)
      this.afterAdd()
    },
    afterAdd: function () {
      Vue.nextTick(() => {
        if (this.$refs.componentEditor) {
          this.activeComponent.params =
            this.$refs.componentEditor.defaultParams()
          this.activeComponent.values =
            this.$refs.componentEditor.defaultValues()
        }
      })
    },
    onChangeDraggable: function (ev) {
      if (ev.added !== undefined) {
        this.activeComponent = ev.added.element
        this.afterAdd()
      }
      if (ev.moved !== undefined) {
        this.activeComponent = ev.moved.element
      }
    },
    deleteComponent: function (component, index) {
      this.formDefinition.polozky.splice(index, 1)
      if (this.formDefinition.polozky.length == 0) {
        this.activeComponent = null
      } else {
        if (component === this.activeComponent) {
          this.activeComponent =
            this.formDefinition.polozky[this.formDefinition.polozky.length - 1]
        }
      }
    },
    validate: function () {
      return this.$refs.form.validate()
    },
  },
}
</script>

<style scoped>
.clicker {
  cursor: pointer;
}
.dragable {
  cursor: -webkit-grab;
}
</style>
