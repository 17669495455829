<template>
  <BaseDialog
    :dialogTitle="$vuetify.t('$vuetify.form.subjekt.title.' + action)"
    ref="dialog"
    @save="save"
  >
    <v-container fluid grid-list-lg>
      <v-form>
        <v-layout row>
          <v-flex xs6>
            <v-select
              v-model="subjekt.typSubjektu"
              :items="typy"
              item-value="id"
              item-text="text"
              :label="$vuetify.t('Typ subjektu')"
              hide-details
            ></v-select>
            <v-text-field
              v-model="subjekt.kod"
              :label="$vuetify.t('Kód')"
              maxlength="50"
              required
              hide-details
              v-validate="'required'"
              :error-messages="errors.collect('kod')"
              data-vv-name="kod"
              :data-vv-as="$vuetify.t('Kód')"
            ></v-text-field>
            <v-text-field
              v-model="subjekt.pobocka"
              :label="$vuetify.t('Pobočka')"
              maxlength="50"
              hide-details
            ></v-text-field>
            <v-text-field
              v-model="subjekt.nazov"
              :label="$vuetify.t('Názov')"
              maxlength="50"
              required
              hide-details
              v-validate="'required'"
              :error-messages="errors.collect('nazov')"
              data-vv-name="nazov"
              :data-vv-as="$vuetify.t('Názov')"
            ></v-text-field>
            <v-text-field
              v-model="subjekt.ulica"
              :label="$vuetify.t('Ulica')"
              maxlength="50"
              hide-details
            ></v-text-field>
            <v-text-field
              v-model="subjekt.psc"
              :label="$vuetify.t('PSČ')"
              maxlength="50"
              hide-details
            ></v-text-field>
            <v-text-field
              v-model="subjekt.obec"
              :label="$vuetify.t('Obec')"
              maxlength="50"
              hide-details
            ></v-text-field>
            <v-text-field
              v-model="subjekt.ico"
              :label="$vuetify.t('IČO')"
              maxlength="50"
              required
              hide-details
              v-validate="'numeric'"
              :error-messages="errors.collect('ico')"
              data-vv-name="ico"
              :data-vv-as="$vuetify.t('IČO')"
            ></v-text-field>
            <v-text-field
              v-model="subjekt.dic"
              :label="$vuetify.t('DIČ')"
              maxlength="50"
              hide-details
            ></v-text-field>
            <v-text-field
              v-model="subjekt.icdph"
              :label="$vuetify.t('IČ DPH')"
              maxlength="50"
              hide-details
            ></v-text-field>
          </v-flex>
          <v-flex xs6>
            <v-text-field
              v-model="subjekt.menoKontakt"
              :label="$vuetify.t('Kontakt meno')"
              maxlength="50"
              hide-details
            ></v-text-field>
            <v-text-field
              v-model="subjekt.telefonKontakt"
              :label="$vuetify.t('Kontakt telefón')"
              maxlength="50"
              hide-details
            ></v-text-field>
            <v-text-field
              v-model="subjekt.emailKontakt"
              :label="$vuetify.t('Kontakt email')"
              maxlength="50"
              hide-details
            ></v-text-field>
            <v-text-field
              v-model="subjekt.web"
              :label="$vuetify.t('Web')"
              maxlength="50"
              hide-details
            ></v-text-field>
            <v-checkbox
              v-model="subjekt.elpodatelna"
              :label="$vuetify.t('Elektronická podateľňa')"
              hide-details
              height="28"
            ></v-checkbox>
            <v-text-field
              v-model="subjekt.prihlasovacieMeno"
              :label="$vuetify.t('Prihlasovacie meno')"
              maxlength="50"
              hide-details
              v-validate="{ rules: { required: this.isElPodatelna } }"
              :error-messages="errors.collect('prihlasovacieMeno')"
              data-vv-name="prihlasovacieMeno"
              :data-vv-as="$vuetify.t('Prihlasovacie meno')"
            ></v-text-field>
            <v-text-field
              v-model="subjekt.heslo"
              :label="$vuetify.t('Heslo')"
              maxlength="50"
              type="password"
              hide-details
              v-validate="{ rules: { required: this.isElPodatelna } }"
              :error-messages="errors.collect('heslo')"
              data-vv-name="heslo"
              :data-vv-as="$vuetify.t('Prihlasovacie meno')"
            ></v-text-field>
            <v-text-field
              v-model="subjekt.notifikacnyEmail"
              :label="$vuetify.t('Notifikačný email')"
              maxlength="50"
              type="password"
              hide-details
              v-validate="{
                rules: { required: this.isElPodatelna, email: true },
              }"
              :error-messages="errors.collect('notifikacnyEmail')"
              data-vv-name="notifikacnyEmail"
              :data-vv-as="$vuetify.t('Notifikačný email')"
            ></v-text-field>
            <v-text-field
              v-model="subjekt.cisloZmluvy"
              :label="$vuetify.t('Číslo zmluvy')"
              maxlength="50"
              hide-details
            ></v-text-field>
            <v-menu
              v-model="subjektDatumZmluvyMenu"
              :close-on-content-click="false"
              full-width
              max-width="290"
            >
              <v-text-field
                slot="activator"
                :value="computedSubjektDatumZmluvy"
                clearable
                :label="$vuetify.t('Dátum zmluvy')"
                readonly
              ></v-text-field>
              <v-date-picker
                v-model="subjekt.datumZmluvy"
                @change="subjektDatumZmluvyMenu = false"
                no-title
              ></v-date-picker>
            </v-menu>
          </v-flex>
        </v-layout>
      </v-form>
    </v-container>
  </BaseDialog>
</template>

<script>
import * as Miscella from "./../../miscella.js"
import BaseDialog from "./../BaseDialog.vue"
import moment from "moment"

export default {
  inject: ["$validator"],
  components: {
    BaseDialog,
  },
  data: function () {
    return {
      action: "add",
      subjekt: {},
      subjektDatumZmluvyMenu: false,
      typy: [
        { id: 0, text: this.$vuetify.t("$vuetify.enum.typSubjektu.ZP") },
        { id: 1, text: this.$vuetify.t("$vuetify.enum.typSubjektu.SP") },
        { id: 2, text: this.$vuetify.t("$vuetify.enum.typSubjektu.NCZI") },
        { id: 3, text: this.$vuetify.t("$vuetify.enum.typSubjektu.VUC") },
        { id: 4, text: this.$vuetify.t("$vuetify.enum.typSubjektu.LAB") },
        { id: 5, text: this.$vuetify.t("$vuetify.enum.typSubjektu.INY") },
      ],
    }
  },
  props: {},
  computed: {
    isElPodatelna: function () {
      if (this.subjekt.elpodatelna) {
        return true
      }
      return false
    },
    computedSubjektDatumZmluvy() {
      if (this.subjekt.datumZmluvy === "0001-01-01T00:00:00Z") {
        return ""
      }
      return this.subjekt.datumZmluvy
        ? moment(this.subjekt.datumZmluvy).format("DD.MM.YYYY")
        : ""
    },
  },
  watch: {},
  methods: {
    show: function (s) {
      this.action = "edit"
      this.subjekt = Miscella.cloneObject(s)
      this.$validator.reset()
      this.$refs.dialog.open()
    },
    add: function () {
      this.action = "add"
      this.subjekt = {}
      this.subjekt.typSubjektu = 0
      this.subjekt.datumZmluvy = moment().startOf("day").toDate()
      this.$validator.reset()
      this.$refs.dialog.open()
    },
    save: function () {
      this.$validator.validateAll().then(
        function () {
          let isOK = true
          if (this.errors.any() || !isOK) {
            event.stopPropagation()
          } else {
            console.log("this.subjekt.datumZmluvy", this.subjekt.datumZmluvy)
            this.subjekt.datumZmluvy = moment(this.subjekt.datumZmluvy)
              .startOf("day")
              .format()
            console.log("this.subjekt.datumZmluvy", this.subjekt.datumZmluvy)
            this.$api
              .post("/api/subject", this.subjekt)
              .then(
                function () {
                  this.$store.commit(
                    "setSuccess",
                    this.$vuetify.t("Subjekt bol úspešne uložený.")
                  )
                  this.$emit("save")
                }.bind(this)
              )
              .catch(
                function (err) {
                  this.$store.commit(
                    "setError",
                    this.$vuetify.t(
                      "Pri ukladaní subjektu vznikla chyba ({message}).",
                      { message: err }
                    )
                  )
                }.bind(this)
              )
          }
        }.bind(this)
      )
    },
    fetchData: function () {},
  },
  mounted: function () {
    this.fetchData()
  },
}
</script>

<style scoped></style>
