<template>
  <div>
    <v-tooltip top v-for="(tag, index) in pacient.Tags" :key="index">
      <span slot="activator">
        <v-chip
          :color="tag.Color"
          close
          small
          class="my-0 py-0 clicker editTagActivator"
          text-color="white"
          @click="editTag(index)"
          @input="deleteTag(index)"
          >{{ tag.Name }}</v-chip
        >
      </span>
      <span>
        {{ tag.Description }}
      </span>
    </v-tooltip>
    <v-tooltip top>
      <span slot="activator">
        <v-icon
          class="clicker editTagActivator"
          @click="addTag()"
          style="position: relative; top: 4px"
          >add</v-icon
        >
      </span>
      <span> Pridať značku </span>
    </v-tooltip>
    <base-popup :title="$vuetify.t('Značka')" activator=".editTagActivator">
      <template v-slot="{ toggle }">
        <form-tag ref="formTag" :toggle="toggle" @changed="saveTags()" />
      </template>
    </base-popup>
  </div>
</template>
<script>
import BasePopup from "@/components/BasePopup.vue"
import FormTag from "@/components/forms/FormTag.vue"
import * as Miscella from "@/miscella.js"

export default {
  components: {
    FormTag,
    BasePopup,
  },
  props: {},
  computed: {
    pacient: function () {
      if (Miscella.isSet(this.$store.state.pacient)) {
        return this.$store.state.pacient
      }
      return {}
    },
    pacientId: function () {
      return this.pacient.ID
    },
  },
  methods: {
    addTag: function () {
      console.log("TagsList, addTag")
      this.$refs.formTag.new()
    },
    editTag: function (aIndex) {
      console.log("TagsList, editTag")
      this.$refs.formTag.edit(aIndex)
    },
    deleteTag: function (aIndex) {
      this.pacient.Tags.splice(aIndex, 1)
      this.saveTags()
    },
    saveTags: async function () {
      try {
        var params = {
          Tags: this.pacient.Tags,
        }
        var result = await this.$api.post(
          `/api/patient/${this.pacientId}/tags`,
          params
        )
        this.$store.commit(
          "setSuccess",
          this.$vuetify.t("Zmena bola úspešne uložená.")
        )
        this.$emit("saved", result)
      } catch (err) {
        this.$store.commit(
          "setError",
          this.$vuetify.t("Pri ukladaní zmeny vznikla chyba ({message}).", {
            message: err,
          })
        )
      }
    },
  },
}
</script>
