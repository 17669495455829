<template>
  <v-card class="dashboard-panel ma-2 elevation-5">
    <v-toolbar dense flat card>
      <v-toolbar-title>{{ $vuetify.t("Pacienti") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="pacientiFilter"
        class="dense mx-0"
        flat
        hide-details
        prepend-inner-icon="search"
        solo-inverted
        clearable
      >
      </v-text-field>
      <v-tooltip top>
        <v-btn icon slot="activator" @click="addPacient">
          <v-icon>add</v-icon>
        </v-btn>
        <span>{{ $vuetify.t("Pridať") }}</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text class="px-1">
      <template v-for="(pacient, index) in pacienti">
        <v-sheet
          :key="'list' + index"
          class="highlight-on-hover overflow-hidden"
        >
          <v-container
            @click="clickedPacient(pacient)"
            class="clicker px-0 py-2"
          >
            <v-layout row wrap>
              <v-flex md12 lg2 overflow-hidden>
                <v-avatar color="red" size="64">
                  <img
                    alt=""
                    :src="
                      defaultText(
                        pacient.imageLink,
                        '//www.gravatar.com/avatar/xxx?d=mp'
                      )
                    "
                  />
                </v-avatar>
              </v-flex>
              <v-flex md12 lg10>
                <v-layout row wrap>
                  <v-flex xs12>
                    <v-layout row>
                      <v-flex grow>
                        <v-tooltip top>
                          <span
                            slot="activator"
                            class="subheading font-weight-medium"
                          >
                            {{ pacient.name }}
                            <span class="font-weight-regular">
                              (
                              {{
                                $vuetify.t(
                                  "$vuetify.enum.sex.skratka." + pacient.sex
                                )
                              }}
                              | {{ getAge(pacient.birthdate) }} |
                              {{ pacient.birthdate | formatDatum }}
                              )
                            </span>
                          </span>
                          <span>{{ $vuetify.t("Meno") }}</span>
                        </v-tooltip>
                      </v-flex>
                      <v-flex shrink>
                        <v-tooltip top>
                          <span slot="activator">
                            <v-icon
                              :color="
                                jePacientVCakarni(pacient) ? 'success' : 'none'
                              "
                              @click.native.stop="setPacientVCakarni(pacient)"
                              >airline_seat_recline_normal
                            </v-icon>
                          </span>
                          <span>
                            {{
                              $vuetify.t(
                                "$vuetify.pacient.cakaren." +
                                  jePacientVCakarni(pacient)
                              )
                            }}
                          </span>
                        </v-tooltip>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 class="font-weight-light entries">
                    <v-tooltip top v-if="pacient.rc">
                      <span slot="activator" :class="patientEntryClasses">
                        <v-icon small>cake</v-icon>
                        {{ pacient.rc }}
                      </span>
                      <span>{{ $vuetify.t("Rodné číslo") }}</span>
                    </v-tooltip>
                    <v-tooltip top v-if="pacient.phone">
                      <span slot="activator" :class="patientEntryClasses">
                        <v-icon small>phone</v-icon>
                        {{ pacient.phone }}
                      </span>
                      <span>{{ $vuetify.t("Telefón") }}</span>
                    </v-tooltip>
                    <v-tooltip top v-if="pacient.email">
                      <span slot="activator" :class="patientEntryClasses">
                        <v-icon small>email</v-icon>
                        {{ pacient.email }}
                      </span>
                      <span>{{ $vuetify.t("Email") }}</span>
                    </v-tooltip>
                    <v-tooltip top v-if="pacient.posledneVysetrenie">
                      <span slot="activator" :class="patientEntryClasses">
                        <v-icon small>calendar_today</v-icon>
                        {{ pacient.posledneVysetrenie | formatDatum }}
                      </span>
                      <span>{{ $vuetify.t("Posledné vyšetrenie") }}</span>
                    </v-tooltip>
                    <!-- <v-tooltip top v-if="pacient.nextAppointment">
                      <span slot="activator" :class="patientEntryClasses">
                        <v-icon small>fast_forward</v-icon>
                        <span>{{ pacient.nextAppointment | formatDatum }}</span>
                      </span>
                      <span>{{ $vuetify.t("Dátum objednania") }}</span>
                    </v-tooltip> -->
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-container>
        </v-sheet>
        <v-divider
          :key="'divider' + index"
          v-if="index !== pacienti.length - 1"
        ></v-divider>
      </template>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <span
        >{{ $vuetify.t("Počet nájdených pacientov ") + pacientiCount }}
        <router-link tag="a" :to="{ name: 'pacienti' }">{{
          $vuetify.t("Zobraziť")
        }}</router-link></span
      >
    </v-card-actions>
    <BaseDialog
      :dialogTitle="$vuetify.t('Nový pacient')"
      ref="dialog"
      @save="$refs.form.save()"
      width="75%"
    >
      <FormPacient ref="form" @saved="savedPacient"></FormPacient>
    </BaseDialog>
  </v-card>
</template>

<script>
import BaseDialog from "./../BaseDialog.vue"
import FormPacient from "./../forms/FormPacient.vue"
import * as Miscella from "./../../miscella.js"
import * as mixPatient from "./../../components/mixins/patient.js"

export default {
  components: {
    BaseDialog,
    FormPacient,
  },
  mixins: [mixPatient.mixins],
  data() {
    return {
      pacientiFilter: "",
      pacientiPageSize: 20,
      pacientiCount: 0,
      pacienti: [],
    }
  },
  computed: {
    patientEntryClasses: function () {
      return "entry mr-2"
    },
    ambulanceId: function () {
      let id = null
      if (Miscella.isSet(this.$store.state.me.ambulance)) {
        id = this.$store.state.me.ambulance.id
      }
      return id
    },
  },
  watch: {
    pacientiFilter: function () {
      Miscella.myDelay(this.fetchData, 1000)
    },
    ambulanceId: function () {
      this.fetchData()
    },
  },
  methods: {
    fetchData: function () {
      let params = {
        pageSize: this.pacientiPageSize,
      }
      if (!Miscella.isEmpty(this.pacientiFilter)) {
        params.filter = this.pacientiFilter
      }
      return this.$api
        .post("/v1/proto.PatientService/ListPatients", params)
        .then((data) => {
          this.pacienti = data.tableData
          this.pacientiCount = data.tableInfo.Count
        })
    },
    addPacient: function () {
      this.$refs.form.new()
    },
    savedPacient: function () {
      this.$refs.dialog.close()
      this.fetchData()
    },
    clickedPacient: function (item) {
      this.$router.push({ name: "pacient", params: { id: item.id } })
    },
    jePacientVCakarni: function (pacient) {
      let pacientId = pacient.id
      let ambulance = this.$store.state.me.ambulance
      let cakaren = ambulance !== null ? ambulance.cakaren : null
      let ret = false
      if (!Miscella.isEmpty(cakaren.items)) {
        for (let i = 0; i < cakaren.items.length; i++) {
          let p = cakaren.items[i]
          if (p.id === pacientId) {
            ret = true
          }
        }
      }
      return ret
    },
    setPacientVCakarni: async function (pacient) {
      let pacientId = pacient.id
      let ambulance = this.$store.state.me.ambulance
      let cakaren = ambulance !== null ? ambulance.cakaren : null
      if (this.jePacientVCakarni(pacient)) {
        cakaren = Miscella.cakarenRemove(cakaren, pacientId)
      } else {
        let p = { id: pacientId }
        cakaren.items.push(p)
      }
      try {
        let result = await Miscella.cakarenUpdate(
          cakaren,
          ambulance.id,
          this.$api
        )
        this.$store.commit(
          "setSuccess",
          this.$vuetify.t("Čakáreň bola úspešne uložená.")
        )
        this.$store.dispatch("reloadMe", this.$api)
        console.log("cakaren saved", result)
      } catch (err) {
        this.$store.commit(
          "setError",
          this.$vuetify.t(
            "Pri ukladaní pacienta do čakárne vznikla chyba ({message}).",
            {
              message: err,
            }
          )
        )
      }
    },
  },
  mounted: function () {
    this.fetchData()
  },
}
</script>

<style scoped lang="stylus">
.entry {
  display: inline-block;
}
</style>
