<template>
  <v-container fluid>
    <ApiTable
      :headers="headers"
      :hasFilter="false"
      ref="table"
      :url="itemsUrl"
      :title="$vuetify.t('Dávky za obdobie')"
    >
      <template slot="toolbarItems">
        <v-tooltip top>
          <v-btn icon slot="activator" @click="createProtokol">
            <v-icon>add</v-icon>
          </v-btn>
          <span>{{ $vuetify.t("Vytvoriť protokol") }}</span>
        </v-tooltip>
      </template>
      <template slot="items" slot-scope="obsah">
        <tr>
          <td>
            {{
              obsah.item.Ambulancia == null || obsah.item.Ambulancia.ID == ""
                ? ""
                : obsah.item.Ambulancia.Name !== ""
                ? obsah.item.Ambulancia.Name
                : obsah.item.Ambulancia.Odbornosti[0]
            }}
          </td>
          <td>{{ obsah.item.NazovDavky }}</td>
          <td>{{ obsah.item.TypDokladu }}</td>
          <td>
            {{
              $vuetify.t(
                "$vuetify.enum.charakterPoistenca." +
                  obsah.item.CharakterPoistenca
              )
            }}
          </td>
          <td>{{ obsah.item.RiadkyDavky }}</td>
          <td>{{ obsah.item.Velkost }}</td>
          <td>{{ obsah.item.DatumVytvorenia | formatDatum }}</td>
          <td>{{ obsah.item.DatumOdoslania | formatDatum }}</td>
          <td>
            <v-tooltip left>
              <v-icon
                v-if="obsah.item.BinaryID !== ''"
                slot="activator"
                @click.stop="downloadDavka(obsah.item)"
                >get_app</v-icon
              >
              <span>{{ $vuetify.t("Stiahnuť dávku") }}</span>
            </v-tooltip>
            <v-tooltip left>
              <v-icon
                v-if="obsah.item.DatumOdoslania === '0001-01-01T00:00:00Z'"
                slot="activator"
                @click.stop="deleteDavka(obsah.item)"
                >delete</v-icon
              >
              <span>{{ $vuetify.t("Vymazať dávku") }}</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </ApiTable>
  </v-container>
</template>
<script>
import ApiTable from "../../components/ApiTable.vue"
import * as Codebook from "./../codebook/codebook.js"
import * as subory from "../../components/mixins/subory.js"

export default {
  props: ["obdobieId"],
  components: {
    ApiTable,
  },
  data: function () {
    return {
      loading: true,
      itemsUrl: "/api/poistovne/" + this.obdobieId + "/davky",
      sortBy: "NazovDavky",
      sortAsc: false,
      davky: [],
      chosen: "",
      headers: [
        {
          text: "Ambulancia",
          value: "Ambulancia",
          sortable: false,
        },
        {
          text: "Názov dávky",
          value: "NazovDavky",
          sortable: false,
        },
        {
          text: "Typ dokladu",
          value: "TypDokladu",
          sortable: false,
        },
        {
          text: "Charakter poistenca",
          value: "CharakterPoistenca",
          sortable: false,
        },
        {
          text: "Počet riadkov",
          value: "PocetRiadkov",
          sortable: false,
        },
        {
          text: "Veľkosť",
          value: "Size",
          sortable: false,
        },
        {
          text: "Dátum vytvorenia",
          value: "DatumVytvorenia",
          sortable: false,
        },
        {
          text: "Dátum odoslania",
          value: "DatumOdoslania",
          sortable: false,
        },
        {
          text: "",
          value: "",
          sortable: false,
        },
      ],
    }
  },
  mixins: [Codebook.mixins, subory.mixins],
  methods: {
    fetchData: async function () {
      let url = this.itemsUrl
      url += "?sortBy=" + this.sortBy
      url += "&sortAsc=" + this.sortAsc
      this.davky = await this.$api.get(url, null)
    },
    handleError: function (error) {
      console.log(error)
    },
    itemClicked: function (item) {
      this.chosen = item
      this.errors.clear()
    },
    deleteDavka: async function (item) {
      console.log("Item ID = " + item.ID)
      console.log("Item Binary ID = " + item.BinaryID)
      try {
        if (item.NazovDavky === "protokol") {
          await this.$api.delete(
            "/api/poistovne/protokol/" +
              item.ID +
              "/binary/" +
              item.BinaryID +
              "/delete",
            null
          )
          this.deleteOK("Protokol bol úspešne vymazaný")
        } else {
          await this.$api.delete(
            "/api/poistovne/davka/" + item.ID + "/delete",
            null
          )
          this.deleteOK("Dávka bola úspešne vymazaná")
        }
      } catch (err) {
        this.deleteError()
        return
      }
    },
    deleteOK: function (message) {
      this.$store.commit("setSuccess", this.$vuetify.t(message))
      this.$refs.table.reload()
    },
    deleteError: function () {
      this.$store.commit(
        "setError",
        this.$vuetify.t("Pri mazaní dávky nastala chyba")
      )
    },
    downloadDavka: function (item) {
      this.downloadSubor(item.NazovDavky, item.BinaryID)
    },
    createProtokol: async function () {
      var param = {
        ObdobieId: this.obdobieId,
      }
      await this.$api.post("/api/poistovne/protokol", param)
      this.$store.commit(
        "setSuccess",
        this.$vuetify.t("Protokol bol úspešne vytvorený")
      )
      this.$refs.table.reload()
    },
  },
  mounted: function () {
    this.fetchData()
  },
}
</script>
