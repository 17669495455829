<template>
  <v-container fluid px-0>
    <v-layout row>
      <v-flex xs1>
        <v-tooltip top>
          <span slot="activator">{{ krvnyTlak.date | formatDatum }}</span>
          <span>{{ $vuetify.t("Dátum merania") }}</span>
        </v-tooltip>
      </v-flex>
      <v-flex xs1>
        <v-tooltip top v-if="krvnyTlak.krvny_tlak.miesto_merania !== null">
          <span slot="activator">{{
            krvnyTlak.krvny_tlak.miesto_merania.display_name
          }}</span>
          <span>{{ $vuetify.t("Miesto merania") }}</span>
        </v-tooltip>
      </v-flex>
      <v-flex xs2>
        <v-tooltip top v-if="krvnyTlak.krvny_tlak.metoda_merania !== null">
          <span slot="activator">{{
            krvnyTlak.krvny_tlak.metoda_merania.display_name
          }}</span>
          <span>{{ $vuetify.t("Metóda merania") }}</span>
        </v-tooltip>
      </v-flex>
      <v-flex xs2>
        <v-tooltip top v-if="krvnyTlak.krvny_tlak.okolnosti_merania !== null">
          <span slot="activator">{{
            krvnyTlak.krvny_tlak.okolnosti_merania.display_name
          }}</span>
          <span>{{ $vuetify.t("Okolnosti merania") }}</span>
        </v-tooltip>
      </v-flex>
      <v-flex xs1>
        <v-tooltip top v-if="krvnyTlak.krvny_tlak.tlak_sys !== null">
          <span slot="activator">{{ krvnyTlak.krvny_tlak.tlak_sys }}</span>
          <span>{{ $vuetify.t("SYSTOLICKÝ krvný tlak") }}</span>
        </v-tooltip>
      </v-flex>
      <v-flex xs1>
        <v-tooltip top v-if="krvnyTlak.krvny_tlak.tlak_dia !== null">
          <span slot="activator">{{ krvnyTlak.krvny_tlak.tlak_dia }}</span>
          <span>{{ $vuetify.t("DIASTOLICKÝ krvný tlak") }}</span>
        </v-tooltip>
      </v-flex>
      <v-flex xs1>
        <v-tooltip top v-if="krvnyTlak.krvny_tlak.tlak_arterial !== null">
          <span slot="activator">{{ krvnyTlak.krvny_tlak.tlak_arterial }}</span>
          <span>{{ $vuetify.t("ARTERIÁLNY krvný tlak") }}</span>
        </v-tooltip>
      </v-flex>
      <v-flex xs1>
        <v-tooltip top v-if="krvnyTlak.krvny_tlak.pulz !== null">
          <span slot="activator">{{ krvnyTlak.krvny_tlak.pulz }}</span>
          <span>{{ $vuetify.t("Pulz") }}</span>
        </v-tooltip>
      </v-flex>
      <v-flex xs2 class="text-truncate">
        <v-tooltip top>
          <span slot="activator">{{ krvnyTlak.krvny_tlak.poznamka }}</span>
          <span>{{ $vuetify.t("Poznámka") }}</span>
        </v-tooltip>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  name: "KrvnyTlakRow",
  props: {
    krvnyTlak: {
      type: Object,
      required: true,
    },
  },
}
</script>
