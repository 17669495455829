<template>
  <v-menu
    v-model="menu"
    offset-y
    offset-overflow
    :close-on-content-click="false"
    :activator="activator"
  >
    <template v-slot:activator="{ on }">
      <slot name="activator" :on="on" :toggle="toggle" :active="menu"> </slot>
    </template>
    <v-card>
      <v-system-bar window class="elevation-0">
        <span class="subheading">{{ title }}</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <v-icon slot="activator" @click.stop="menu = false">close</v-icon>
          <span>{{ $vuetify.t("Zavrieť") }}</span>
        </v-tooltip>
      </v-system-bar>
      <v-card-text>
        <slot :toggle="toggle"></slot>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  components: {},
  data: function () {
    return {
      menu: false,
    }
  },
  props: {
    title: {
      type: String,
      required: false,
    },
    activator: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  methods: {
    toggle: function () {
      this.menu = !this.menu
    },
    save: function () {
      this.$emit("save")
    },
  },
}
</script>

<style scoped lang="stylus">
.v-menu__content {
  margin-top: 2px;
  margin-bottom: 2px;
}
</style>
