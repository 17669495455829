<template>
  <v-container fluid grid-list-sm>
    <v-layout row>
      <v-flex xs10>
        <Dekurz
          id="dekurzy"
          ref="dekurzy"
          :search="search"
          :patientID="id"
          :totalCount="totalCount"
          @changed="fetchData"
          :limit="25"
        ></Dekurz>
      </v-flex>
      <v-flex xs2>
        <affix
          :offset="{ top: 60, bottom: 40 }"
          class="my-btn-group"
          relative-element-selector="#dekurzy"
          style="width: 200px"
        >
          <v-list dense style="backgroundcolor: transparent !important">
            <v-list-tile>
              <v-text-field
                v-model="search"
                @keyup="onSearch"
                @click:clear="onSearch"
                maxlength="20"
                class="mx-0"
                flat
                :placeholder="$vuetify.t('Hladať ...')"
                hide-details
                prepend-inner-icon="search"
                clearable
              ></v-text-field>
            </v-list-tile>
            <loading-container v-model="loading">
              <v-list dense style="backgroundcolor: transparent !important">
                <v-list-tile>
                  <div>
                    {{ $vuetify.t("Počet záznamov: ") + totalCount }}
                  </div>
                </v-list-tile>
                <v-list-tile
                  v-for="(tp, index) in timeLine"
                  :key="tp.year + tp.month"
                >
                  <v-btn
                    flat
                    small
                    :class="{ 'v-btn--active': index === activeTimePoint }"
                    @click="selectTimePoint(index)"
                    >{{ timePointToDate(tp) | formatDatumACas("MMM YYYY") }} ({{
                      tp.count
                    }})</v-btn
                  >
                </v-list-tile>
              </v-list>
            </loading-container>
          </v-list>
        </affix>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import moment from "moment"
import Dekurz from "./Dekurz.vue"
import * as Miscella from "../../miscella.js"
import LoadingContainer from "@/components/LoadingContainer.vue"

export default {
  name: "Karta",
  props: ["id"],
  components: {
    Dekurz,
    LoadingContainer,
  },
  data: function () {
    return {
      loading: false,
      search: "",
      timeLine: [],
      activeTimePoint: 0,
    }
  },
  computed: {
    totalCount: function () {
      var count = 0
      for (var tp of this.timeLine) {
        count += tp.count
      }
      return count
    },
  },
  methods: {
    init: function () {
      this.activeTimePoint = 0
      this.$refs.dekurzy.init(moment().toDate())
    },
    fetchData: async function () {
      try {
        this.loading = true
        await this.sleep(500) // pol sekundy sleep aby sme videli aspoň nejake nacitavacie koliesko

        // see: https://stackoverflow.com/a/22625076
        const tzid = Intl.DateTimeFormat().resolvedOptions().timeZone
        var params = {
          patient_id: this.id,
          parent_request: {
            parent_id: "",
          },
          table_request: {
            offset: 0,
            limit: 1,
            fulltext_search: Miscella.isEmpty(this.search) ? "" : this.search,
          },
          with_timeline: true,
          timeline_tz: tzid,
        }
        /*if (!Miscella.isEmpty(this.search)) {
        params.search = this.search
      }*/
        this.$api.thingService.listThings(params).then((data) => {
          this.timeLine = data.timeline
          if (this.activeTimePoint > this.timeLine.length - 1) {
            this.selectTimePoint(this.timeLine.length - 1)
          }
        })
      } catch (err) {
        this.processError(err)
      } finally {
        this.loading = false
      }
    },
    onSearch: function () {
      this.myDelay(this.fetchData, 1000)
    },
    timePointToDate: function (timePoint) {
      return moment([timePoint.year, timePoint.month - 1])
    },
    selectTimePoint: function (index) {
      var timePoint = this.timeLine[index]
      this.activeTimePoint = index
      this.$refs.dekurzy.init(
        this.timePointToDate(timePoint).endOf("month").toDate()
      )
    },
  },
  mounted() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.init()
    this.fetchData()
  },
  watch: {
    // call again the method if the route changes
    $route: "fetchData",
  },
}
</script>
